import { Box, useTheme } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { filterOutNoMatch, matchBarcodes } from 'common/functions/barcodes/matchBarcode.util';
import { getStatusColorChip } from 'common/functions/barcodes/getStatusColorChip.util';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { muiColorToTheme } from 'common/functions/issues/issueColorFunctions';
import { barcodeStatusEnum } from '../barcodes/models/BarcodeRow.model';
import { BarcodesCount } from './BarcodesCount';
import { LastWmsUpdate } from './LastWmsUpdate';
import { LastScan } from './LastScan';
import { getIsVerityDataStale } from '../utils/getIsVerityDataStale';

export const LocationModalSubHeader = ({
  locationData,
  facilitySettings,
}: {
  locationData: ILocationData;
  facilitySettings: IFacilitySettingsST;
}) => {
  const { rowData } = locationData;
  const theme = useTheme();

  const isVerityDataStale = getIsVerityDataStale(rowData);

  const barcodes = matchBarcodes({
    expected: filterOutNoMatch(locationData.rowData?.contentExpected),
    found: filterOutNoMatch(locationData.rowData?.contentFound),
  });

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    locationData,
    facilitySettings,
  );
  const missingColor = muiColorToTheme(
    getStatusColorChip(barcodeStatusEnum.Missing, barcodeMatchLogic, issueLogic) || 'default',
    theme,
  );
  const unexpectedColor = muiColorToTheme(
    getStatusColorChip(barcodeStatusEnum.Unexpected, barcodeMatchLogic, issueLogic) || 'default',
    theme,
  );
  const matchColor = muiColorToTheme(
    getStatusColorChip(barcodeStatusEnum.Match, barcodeMatchLogic, issueLogic) || 'default',
    theme,
  );
  const staleColor = {
    main: 'GrayText',
  };

  const colors = {
    missing: isVerityDataStale ? staleColor.main : missingColor.main,
    unexpected: isVerityDataStale ? staleColor.main : unexpectedColor.main,
    match: isVerityDataStale ? staleColor.main : matchColor.main,
  };

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      paddingBottom={2.5}
      paddingLeft={1}
      textAlign="left"
      gap={7}
      sx={{
        cursor: 'default',
      }}
    >
      <LastWmsUpdate wmsDate={rowData.wmsDate} />
      <LastScan contentFoundDate={rowData.contentFoundDate} />
      <BarcodesCount
        wmsState={locationData.wmsState}
        verityState={locationData.verityState}
        barcodes={{
          missing: barcodes.missing.length,
          unexpected: barcodes.unexpected.length,
          match: barcodes.match.length,
        }}
        colors={colors}
      />
    </Box>
  );
};
