import { initFlightAreasAPI } from './flightAreas.api';

type GetFlightAreasParams = {
  systemId: string;
  flightDomainId: string;
  signal?: AbortSignal;
};

export const getFlightAreas = ({ systemId, flightDomainId, signal }: GetFlightAreasParams) =>
  initFlightAreasAPI().getFlightAreas(systemId, flightDomainId, { signal });
