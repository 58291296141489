import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { OpenFlyZone } from 'shared/map/model/open-fly-zone.model';

export enum FlowState {
  LOADING = 'LOADING',
  LIST = 'LIST',
  DRAW = 'DRAW',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export type DroneZonePageState = {
  flowState: FlowState;
  detailsZones: ControlledZone[];
  selectedZone: null | ControlledZone;
  currentlyEditingZone: null | ControlledZone;
  currentlyDeletingZone: null | ControlledZone;
  controlledZones: Record<string, ControlledZone>;
  openFlyZones: Record<string, OpenFlyZone>;
};
