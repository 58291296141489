import { DroneZoneMapLegendItemMapped } from '../../DroneZoneMapLegend.model';
import { DroneZoneMapLegendDivider } from '../drone-zone-map-legend-divider/DroneZoneMapLegendDivider';
import { DroneZoneMapLegendZoneGroup } from '../drone-zone-map-legend-zone-group/DroneZoneMapLegendZoneGroup';
import { DroneZoneMapLegendZoneType } from '../drone-zone-map-legend-zone-type/DroneZoneMapLegendZoneType';

export const DroneZoneMapLegendEntries = ({ entry }: { entry: DroneZoneMapLegendItemMapped }) => (
  <>
    {entry.type === 'divider' && <DroneZoneMapLegendDivider />}

    {entry.type === 'drone-zone' && (
      <DroneZoneMapLegendZoneType subType={entry.subType} label={entry.label} />
    )}

    {entry.type === 'drone-group' && <DroneZoneMapLegendZoneGroup label={entry.label} />}
  </>
);
