import * as tables from 'common/Tables';
import { HeadCellProp } from 'components/EnhancedTable/types/cell';
import { ILocationReportData } from 'pages/WarehouseStatus/warehouseStatusFunctions';
import { useSettingsStore } from 'store/Settings/settingsStore';

type RedirectTo = '' | 'locationModal';
export type CurrentTab = {
  tableFor: string;
  tableTitle: string;
  tableSubtitle: string;
  headCells: HeadCellProp[];
  rows: ILocationReportData[];
  redirectTo: RedirectTo;
};
/**
 * Default value for currentTab
 * tableFor is used for displaying spinner and text when there is no data in table
 */
export const currentTabDefault = {
  tableFor: '',
  tableTitle: '',
  tableSubtitle: '',
  headCells: [],
  rows: [],
  redirectTo: '' as RedirectTo,
  toolbarAction: null,
};

type GetTabData = {
  archivedReportNote: string;
  activeTab: number;
  issues: ILocationReportData[];
  amended: ILocationReportData[];
  snoozed: ILocationReportData[];
  potential: ILocationReportData[];
  inconclusive: ILocationReportData[];
  invalid: ILocationReportData[];
  fullReport: ILocationReportData[];
  locationsToReview: ILocationReportData[];
  canReviewReport: boolean;
  reportNeedsReview: boolean;
};

/**
 * This functions returns tab data based on current tab
 * @param param0 GetTabData params
 * @returns tab data
 */
export const useGetTabData = ({
  activeTab,
  archivedReportNote,
  locationsToReview,
  issues,
  amended,
  snoozed,
  potential,
  inconclusive,
  invalid,
  fullReport,
  canReviewReport,
  reportNeedsReview,
}: GetTabData): CurrentTab => {
  const {
    REPORT_ISSUES_COLUMNS,
    REPORT_AMENDED_COLUMNS,
    REPORT_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
    REPORT_INVALID_COLUMNS,
    REPORT_FULL_REPORT_COLUMNS,
    REPORT_REVIEW_COLUMNS,
    REPORT_TABLE_TABS,
    REPORTS_FOR_REVIEW_TABLE_TABS,
  } = useSettingsStore();

  const {
    issuesTab,
    amendedTab,
    issuesSnoozedTab,
    issuesPotentialTab,
    issuesInconclusiveTab,
    issuesInvalidTab,
    fullReportTab,
  } = tables.reportTabsMappings(REPORT_TABLE_TABS);

  const { reviewLocationsTab, reviewOtherLocationsTab } = tables.reviewReportTabsMappings(
    REPORTS_FOR_REVIEW_TABLE_TABS,
  );

  let tabData = {
    [issuesTab]: {
      tableFor: 'issues',
      tableTitle: 'Issues',
      tableSubtitle: `Issues found on this report. ${archivedReportNote}`,
      headCells: REPORT_ISSUES_COLUMNS,
      rows: issues,
      redirectTo: 'locationModal' as RedirectTo,
    },
    [amendedTab]: {
      tableFor: 'amended',
      tableTitle: 'Amended',
      tableSubtitle: `Locations where the verity data was amended by the user. ${archivedReportNote}`,
      headCells: REPORT_AMENDED_COLUMNS,
      rows: amended,
      redirectTo: 'locationModal' as RedirectTo,
    },
    [issuesSnoozedTab]: {
      tableFor: 'snoozed issues',
      tableTitle: 'Snoozed issues',
      tableSubtitle: `Issues that have been snoozed. They will be ignored until they are either solved or unsnoozed. ${archivedReportNote}`,
      headCells: REPORT_ISSUES_COLUMNS,
      rows: snoozed,
      redirectTo: 'locationModal' as RedirectTo,
    },
    [issuesPotentialTab]: {
      tableFor: 'potential issues',
      tableTitle: 'Potential issues',
      tableSubtitle: `Locations with an incomplete match of expected barcodes. ${archivedReportNote}`,
      headCells: REPORT_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
      rows: potential,
      redirectTo: 'locationModal' as RedirectTo,
    },
    [issuesInconclusiveTab]: {
      tableFor: 'inconclusive issues',
      tableTitle: 'Inconclusive issues',
      tableSubtitle: `Locations for which Verity data is inconclusive. ${archivedReportNote}`,
      headCells: REPORT_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
      rows: inconclusive,
      redirectTo: 'locationModal' as RedirectTo,
    },
    [issuesInvalidTab]: {
      tableFor: 'invalid issues',
      tableTitle: 'Invalid WMS data',
      tableSubtitle: `Locations for which the WMS data uploaded is invalid. ${archivedReportNote}`,
      headCells: REPORT_INVALID_COLUMNS,
      rows: invalid,
      redirectTo: 'locationModal' as RedirectTo,
    },
    [fullReportTab]: {
      tableFor: 'report',
      tableTitle: 'Full report',
      tableSubtitle: `Details about all the locations included on this report. ${archivedReportNote}`,
      headCells: REPORT_FULL_REPORT_COLUMNS,
      rows: fullReport,
      redirectTo: 'locationModal' as RedirectTo,
    },
  };

  if (canReviewReport && reportNeedsReview) {
    tabData = {
      [reviewLocationsTab]: {
        tableFor: 'locations to review',
        tableTitle: 'Locations to review',
        tableSubtitle:
          'Locations that need to be reviewed before the report becomes available to the client',
        headCells: REPORT_REVIEW_COLUMNS,
        rows: locationsToReview,
        redirectTo: '' as RedirectTo,
      },
      [reviewOtherLocationsTab]: {
        tableFor: 'report',
        tableTitle: 'Other Locations',
        tableSubtitle: 'Locations that do not need reviewing',
        headCells: REPORT_REVIEW_COLUMNS,
        rows: fullReport,
        redirectTo: '' as RedirectTo,
      },
    };
  }

  return tabData[activeTab] || currentTabDefault;
};
