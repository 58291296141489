export const TABLE_DATETIME_FORMAT = 'ddd, YYYY/MM/DD [at] HH:mm (Z zz)';
export const TABLE_DATETIME_FORMAT_SERVICES_VERSION = 'YYYY/MM/DD [at] HH:mm (Z zz) (dddd)';
export const RELATIVE_DATETIME_FORMAT = {
  sameDay: '[today at] HH:mm (Z zz)',
  nextDay: '[tomorrow at] HH:mm (Z zz)',
  nextWeek: 'Do MMMM YYYY',
  lastDay: '[yesterday at ] HH:mm (Z zz)',
  lastWeek: 'Do MMMM YYYY [at] HH:mm (Z zz)',
  sameElse: 'Do MMMM YYYY [at] HH:mm (Z zz)',
};
export const LONG_DATETIME_FORMAT = 'dddd DD MMMM yyyy - HH:mm';
export const PICKER_DATETIME_FORMAT = 'yyyy/MM/DD HH:mm';
export const TIME_FIRST_DATETIME_FORMAT = 'HH:mm - DD/MM/yyyy ';
export const PICKER_SHORT_DATETIME_FORMAT = 'yyyy/MM/DD';
export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATETIME_FORMAT_WITH_WEEKDAY = 'ddd, YYYY/MM/DD [at] HH:mm (Z zz)';
export const TABLE_DATETIME_FORMAT_SHORT_WITH_WEEKDAY = 'ddd YYYY/MM/DD - HH:mm';
export const DATETIME_FORMAT_FOOTER = 'YYYY/MM/DD HH:mm (Z zz)';
export const REPORT_ISSUES_DATETIME_FORMAT = 'ddd, YYYY/MM/DD [at] HH:mm (Z zz)';
export const HOURS_AND_MINUTES_FORMAT = 'HH:mm';

export const FLIGHT_DOMAIN_DRONE_SHIFT_FORMAT = {
  sameDay: 'HH:mm',
  nextDay: '[tomorrow]',
  nextWeek: 'Do MMMM',
  lastDay: '[yesterday at] HH:mm',
  lastWeek: 'Do MMMM [at] HH:mm',
  sameElse: 'Do MMMM [at] HH:mm',
};

/**
 * Short names of days of the week
 */
export type WeekDayShort = 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa' | 'Su';

const weekDayShortArray: WeekDayShort[] = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const getWeekDayByIndex = (index: number): WeekDayShort => {
  const weekIndex = index % weekDayShortArray.length;
  return weekDayShortArray[weekIndex];
};

export const getWeekIndexByDay = (day: WeekDayShort): number => weekDayShortArray.indexOf(day);
/**
 * This enum is used to get the difference
 * between two dates in declared units of measurement
 */
export const INTERVAL_OPTIONS = {
  hourly: 'hours',
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  yearly: 'years',
};

/**
 * This function take count and interval string value
 * and pluralize it if the count is bigger than 1
 *
 * ex: [weekly, monthly, yearly] => [week/weeks, month/months...]
 */
export const pluralizeInterval = (count: number, interval: string): string => {
  const value = interval.split('');
  value.splice(value.length - 2, value.length);
  const str = value.join('');

  return count > 1 ? `${str}s` : str;
};
