import { IExclusionRequestResponseST, ILocationDataST } from 'codegen/warehouse_status';

export enum LocationsActionNames {
  LOCATIONS_RECEIVED = 'LOCATIONS_RECEIVED',
  SET_SELECTED_IDS = 'SET_SELECTED_IDS',
  SET_UPLOADED_FILE = 'SET_UPLOADED_FILE',
  SET_UPDATE_LOCATIONS = 'SET_UPDATE_LOCATIONS',
  SET_ID_FOR_RESET = 'SET_ID_FOR_RESET',
  SET_ID_FOR_DETAILS = 'SET_ID_FOR_DETAILS',
  SET_LOCATION_EXCLUSION_HISTORY = 'SET_LOCATION_EXCLUSION_HISTORY',
  LOADING = 'LOADING',
}

export type LocationsActions =
  | { type: LocationsActionNames.LOCATIONS_RECEIVED; payload: { [key: string]: ILocationDataST } }
  | { type: LocationsActionNames.SET_SELECTED_IDS; payload: string[] }
  | { type: LocationsActionNames.SET_ID_FOR_RESET; payload: string }
  | { type: LocationsActionNames.SET_ID_FOR_DETAILS; payload: string }
  | { type: LocationsActionNames.SET_UPLOADED_FILE; payload: File | null }
  | {
      type: LocationsActionNames.SET_LOCATION_EXCLUSION_HISTORY;
      payload: IExclusionRequestResponseST | null;
    }
  | {
      type: LocationsActionNames.SET_UPDATE_LOCATIONS;
      payload: IExclusionRequestResponseST;
    };
