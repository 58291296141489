/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useMemo } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Drone } from './Drone';
import { DroneData, MapFleet } from './liveMap.model';

const lp = getLogPrefixForType('COMPONENT', '3D Fleet');

const DronesOnMap = memo(
  (props: { drones: DroneData[] }) => {
    const { drones } = props;
    console.debug(lp, `Drones On Map rerendering ${drones.length} drone/s`);

    return (
      <group>
        {drones.map((ds) => (
          <Drone key={ds.drone_name} droneStatus={{ ...ds }} />
        ))}
      </group>
    );
  },
  (prev, next) => {
    const shouldRenderDrones =
      prev.drones.length === 0 || prev.drones.length !== next.drones.length;
    console.debug(lp, `drones WILL ${shouldRenderDrones ? '' : 'NOT'} render`, prev, next);
    return !shouldRenderDrones;
  },
);

export const Fleet = (props: { namesOfDronesToShow?: string[]; fleet: MapFleet }) => {
  const { namesOfDronesToShow, fleet } = props;
  const droneStatuses = useMemo(() => {
    const drones = Object.values(fleet) as DroneData[];
    const filteredDrones = namesOfDronesToShow
      ? drones.filter((d) => namesOfDronesToShow.includes(d.drone_name))
      : drones;
    return filteredDrones;
  }, [fleet]);

  return <DronesOnMap drones={droneStatuses} />;
};
