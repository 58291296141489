import TableCell from '@mui/material/TableCell';
import Highlighter from 'react-highlight-words';
import isArray from 'lodash/isArray';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
type EnhancedTableCellItemListProps = EnhancedTableCellProp<any> & {
  showSearch: boolean;
  searchTerm: string;
  classes: {
    noWordBreakNoBullets: string;
  };
};

export const EnhancedTableCellItemList = (props: EnhancedTableCellItemListProps) => {
  const { cell, row, searchTerm, showSearch, onClick, classes } = props;

  if (cell === undefined) {
    console.debug(
      'EnhancedTableCellItemList',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} onClick={onClick}>
      {
        // All cells of this type must contain an array of data
        isArray(row[cell.id]) && (
          <ul className={classes.noWordBreakNoBullets}>
            {row[cell.id]?.map((item: any, idx: number) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={`li-${row.id}-${cell.id}-${idx}`}
                style={cell.style && row && cell.style(row, idx)}
              >
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  // Note: if showSearch is disabled then we do not
                  // highlight the search text
                  searchWords={showSearch ? [searchTerm] : []}
                  autoEscape={true}
                  textToHighlight={
                    cell.format
                      ? cell.format(item?.toString() || '')
                      : (item?.toString() || '') +
                        (row.contentFoundChangedByTheClient && cell.id === 'contentFound'
                          ? ' *'
                          : '')
                  }
                />
              </li>
            ))}
          </ul>
        )
      }
    </TableCell>
  );
};
