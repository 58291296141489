import some from 'lodash/some';

// functions
import { IChangePasswordPostRequestST } from 'codegen/authentication';
import { getUserDataFromAccessToken } from './tokenFunctions';

// store
import AuthStore from '../../store/AuthStore';
import { UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';
import { ClientModalsActionTypes } from '../../store/Modals/types';
import { IClientModalsAction } from '../../store/Modals/clientModals/IClientModalsActions';

// variables
import { USER_GROUPS } from '../userGroups';
import { IRequestController } from '../../hooks';
import { AUTH_PAGES_URLS } from '../pages';

/**
 * Interface for the sign-in function
 */
export interface ISignIn {
  /**
   * User name
   */
  username: string;
  /**
   * Password
   */
  password: string;
  /**
   * Spinner function
   */
  setSpinner: any;
  /**
   * Dispatcher (Auth)
   */
  dispatchUserLevelStore: any;
  /**
   * Navigation function
   */
  navigate: any;
  /**
   * Snackbar (for notifications)
   */
  enqueueSnackbar: any;
  /**
   * Request Controller
   */
  requestController: IRequestController;
}

/**
 * Sign in function.
 *
 * Note: The network request does not get cancelled on unmounting, but the callbacks won't be executed.
 * @param param0 ISignIn parameters
 */
export const signIn = ({
  username,
  password,
  setSpinner,
  dispatchUserLevelStore,
  navigate,
  enqueueSnackbar,
  requestController,
}: ISignIn) => {
  const sanitizedUsername = username.replace(/\s+/g, '');

  requestController.doRequest({
    request: AuthStore.signIn,
    requestParams: [sanitizedUsername, password],
    callbackBeforeSend: () => setSpinner((repliesPending: number) => repliesPending + 1),
    hideNotifications: true,
    callbackSuccess: (r: {
      authData: { challenge_name?: any; access_token?: any };
      isNewUser: any;
      isMfaAuth: any;
      redirectRoute: any;
    }) => {
      const { access_token: userAccessToken } = r.authData;

      const userHasRolesDefined = checkIfUserHasRolesDefined(userAccessToken);

      // Do not allow redirection for the user without user roles defined,
      // (except in the case of a new user) because all the routes depend
      // on user having at least one role
      if (userHasRolesDefined || r.isNewUser || r.isMfaAuth) {
        dispatchUserLevelStore({ type: UserLevelActionNames.AUTH_PROCESS, payload: true });
        dispatchUserLevelStore({
          type: UserLevelActionNames.AUTH_DETAILS,
          payload: {
            username,
            challenge_name: r.authData.challenge_name ? r.authData.challenge_name : '',
          },
        });
        navigate(r.redirectRoute, { state: r.authData });
      } else {
        enqueueSnackbar("User doesn't have a role defined", { variant: 'error' });
        navigate(AUTH_PAGES_URLS.SIGNOUT);
      }
    },
    callbackError: (e: { response: { status: number; data: { message: any } }; code: string }) => {
      if (e.response.status === 400 && e.code === 'ERR_BAD_REQUEST') {
        enqueueSnackbar('E-mail and/or password are incorrect.', { variant: 'error' });
      }
      if (e.response.status === 403) {
        enqueueSnackbar(e.response.data.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to sign in', { variant: 'error' });
      }
    },
    callbackFinally: () => setSpinner((repliesPending: number) => repliesPending - 1),
  });
};

export const checkIfUserHasRolesDefined = (accessToken: any) => {
  const { userGroups } = getUserDataFromAccessToken(accessToken);

  return some(userGroups, Boolean);
};

export const belongsToGroup = (userGroup: USER_GROUPS) => {
  const accessToken = localStorage.getItem('accessToken');
  const { userGroups } = getUserDataFromAccessToken(accessToken);
  return userGroups[userGroup];
};

export const isVerityUser = () => belongsToGroup(USER_GROUPS.VERITY_USER);

/**
 * Interface for the change password function
 */
export interface IChangePassword {
  /**
   * Change password data
   */
  data: IChangePasswordPostRequestST;
  /**
   * Dispatcher
   */
  dispatchClientModals: (params: IClientModalsAction) => void;
  /**
   * Spinner function
   */
  setSpinner: (isLoading: boolean) => void;
  /**
   * Request Controller
   */
  requestController: IRequestController;
}
/**
 * Change password function
 * @param param0 IChangePassword parameters
 */
export const handleChangePassword = ({
  data,
  requestController,
  setSpinner,
  dispatchClientModals,
}: IChangePassword) => {
  requestController.doRequest({
    request: AuthStore.changePassword,
    requestParams: [data],
    callbackBeforeSend: () => setSpinner(true),
    messageSuccess: 'Password updated successfully.',
    messageErrorFallback: 'Password could not be updated.',
    callbackSuccess: () =>
      dispatchClientModals({ type: ClientModalsActionTypes.CHANGE_PASSWORD_MODAL }),
    callbackFinally: () => setSpinner(false),
  });
};
