import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { COLOR_PALETTE } from 'common/colors';

export const useStyles = makeStyles()(() => ({
  dropzone: {
    borderStyle: 'none !important',
    backgroundColor: 'transparent !important',
    padding: '0 !important',
  },
  filesUploaded: {
    backgroundColor: alpha(COLOR_PALETTE.YELLOW, 0.12),
  },
}));
