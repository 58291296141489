import { IRequestController } from '../../../hooks';
import { SupersetService } from '../../../services/Superset';

export interface ISupersetSettings {
  /**
   * SuperSet host URL.
   */
  base_url: string;
  /**
   * Facility specific dashboard ID.

   */
  facility_dashboard_id: string;
  /**
   * Client dashboard ID.
   */
  client_dashboard_id: string;
}

interface IGetSuperSetSettings {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSupersetSettings: React.Dispatch<React.SetStateAction<ISupersetSettings | null>>;
  requestController: IRequestController;
}

export const getSuperSetSettings = ({
  setSupersetSettings,
  setIsLoading,
  requestController,
}: IGetSuperSetSettings) => {
  const { signal } = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: SupersetService.getSuperSetSettings,
    requestParams: [{ signal }],
    callbackBeforeSend: () => setIsLoading(true),
    callbackSuccess: ({ data }: { data: ISupersetSettings }) => setSupersetSettings(data),
    callbackFinally: () => setIsLoading(false),
  });
};
