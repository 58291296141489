import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const locationStyles = makeStyles()((theme: Theme) => ({
  modalTitle: {
    fontWeight: 'bold',
    marginLeft: '16px',
  },
  subtitleAndNavigation: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  navigationButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  imageContainer: {
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  imageContainerText: {
    width: '80%',
    marginLeft: '10%',
  },
  accordionTitle: {
    fontSize: '1rem',
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  formWrapper: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    maxWidth: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  shortcutHint: {
    fontWeight: 200,
    fontSize: '11px',
    position: 'absolute',
  },
  actionButton: {
    flex: '1',
    margin: '8px 0',
    lineHeight: '1.3',
    minWidth: 120,
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  feedbackDrawerPaper: {
    width: '100%',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.palette.grey[50],
  },
  spinnerLoadingImages: {
    position: 'relative',
    minHeight: '400px',
  },
}));
