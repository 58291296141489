import { Dispatch } from 'react';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { requestQueueHandler } from 'common/requestHelpers';
import { ILocationDataST } from 'codegen/warehouse_status';
import { IRequestController, ReservedSlot } from 'hooks';
import { WarehouseService } from 'services/Warehouse';

import {
  LocationsActionNames,
  LocationsActions,
} from 'udb/inventory/locations-management/reducer/LocationsActions';
import { NetworkRequest } from 'ts-types/NetworkRequest';
import { getExclusionStatusHistory } from './GetExclusionStatusHistory';

type GetLocationsParams = {
  dispatch: Dispatch<LocationsActions>;
  slots: string[];
  systemId: string;
  requestController: IRequestController;
};

/**
 * Triggers api call for loading of locations with pagination
 * @param params GetLocationsParams
 */
export const getLocations: (params: GetLocationsParams) => string[] = ({
  dispatch,
  slots,
  systemId,
  requestController,
}) => {
  const requestPageSize: number = NETWORK_REQUEST_SETTINGS.WAREHOUSE_PAGE_SIZE_FOR_FULL_REPORT_TAB;

  const allRequests: NetworkRequest[] = [];

  const pageCount = Math.ceil(slots && slots.length / requestPageSize);
  for (let i: number = 0; i < pageCount; i += 1) {
    const reservation: ReservedSlot = requestController.reserveSlotForRequest();
    const networkRequest: NetworkRequest = {
      requestId: reservation.requestId,
      request: () =>
        requestController.doRequest(
          {
            request: WarehouseService.getLocationsData,
            requestParams: [
              systemId,
              slots[i * requestPageSize],
              requestPageSize,
              reservation.signal,
            ],
            messageErrorFallback: 'Locations could not be loaded.',
            callbackSuccess: ({ data }: { data: { [key: string]: ILocationDataST } }) => {
              dispatch({ type: LocationsActionNames.LOCATIONS_RECEIVED, payload: data });
              getExclusionStatusHistory({ locations: data, dispatch, requestController, systemId });
            },
          },
          reservation.requestId,
        ),
    };
    allRequests.push(networkRequest);
  }

  requestQueueHandler(
    allRequests,
    NETWORK_REQUEST_SETTINGS.MAX_CONCURRENT_REQUESTS,
    requestController,
  );

  return allRequests.map((r: NetworkRequest) => r.requestId);
};
