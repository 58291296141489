import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';

export const BatteryStateMetric = ({
  state,
  detailedState,
}: {
  state: string;
  detailedState: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      onClick={() => {
        navigator.clipboard.writeText(`${state} -> ${detailedState}`);
        enqueueSnackbar('Copied to clipboard', { variant: 'success' });
      }}
    >
      {state}
      {' -> '}
      {detailedState}
    </Box>
  );
};
