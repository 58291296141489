import { IUserGetAllResponseST, IUserResponseST } from 'codegen/user_admin';
import { AxiosResponse } from 'axios';
import { User } from './user.model';

export const transformUsersFromDTO: (
  usersResponse: AxiosResponse<IUserGetAllResponseST, any>,
) => User[] = (usersResponse) =>
  usersResponse.data.users.map((u: IUserResponseST) => ({
    id: u.username,
    email: u.email,
    usernameHashed: u.username,
    firstName: u.given_name,
    lastName: u.family_name,
    phone: u.phone_number,
    systemIds: u.system_ids,
    userGroups: u.user_groups ?? [],
    status: u.status,
  }));
