import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { DroneZoneVisibilityToggle } from 'shared/map-container/features/DroneZonesVisibilityToggle/DroneZoneVisibilityToggle';
import { ClientDroneZoneTypes } from '../../model/clientDroneZones.model';
import { useStyles } from './DroneZoneVisibilityToggles.style';
import { VisibleZones } from '../../defaults/visibleZones.default';

export const DroneZoneVisibilityToggles = ({
  visibleZones,
  onChange,
}: {
  visibleZones: VisibleZones;
  onChange: (droneZoneType: ClientDroneZoneTypes, isVisible: boolean) => void;
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.droneZoneVisilityToggles}>
      <DroneZoneVisibilityToggle
        isChecked={visibleZones.controlledZone}
        type={DroneZoneTypes.controlledZone}
        label="Controlled zones"
        onChange={(_event, checked) => {
          onChange(DroneZoneTypes.controlledZone, checked);
        }}
      />

      <DroneZoneVisibilityToggle
        isChecked={visibleZones.noFlyZone}
        type={DroneZoneTypes.noFlyZone}
        label="No-fly zones"
        onChange={(_event, checked) => {
          onChange(DroneZoneTypes.noFlyZone, checked);
        }}
      />

      <DroneZoneVisibilityToggle
        isChecked={visibleZones.openFlyZone}
        type={DroneZoneTypes.openFlyZone}
        label="Open-fly zones"
        onChange={(_event, checked) => {
          onChange(DroneZoneTypes.openFlyZone, checked);
        }}
      />
    </div>
  );
};
