import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { find, findIndex } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';

import Spinner from 'components/common/Spinner';
import ReportStore from 'udb/inventory/reports/reducer/report-store/ReportStore';
import { singleRequestHandler } from 'common/requestHelpers';
import { getMostRecent } from 'common/functions/dateTimeFunctions';
import { IIssueSlotStatusST, IIssueST } from 'codegen/report';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import WarehouseStore from '../../../store/WarehouseStore';
import { useRequestController } from '../../../hooks';

import ModalBase from '../ModalBase';
import {
  IFacilityModalsState,
  ILocationData,
} from '../../../store/Modals/facilityModals/IFacilityModalsStore';
import { LocationModalHelp } from './LocationModalHelp';
import { FeedbackReasonType } from './ShareFeedback/feedbackReason.model';
import { LocationModalHeader } from './header/LocationModalHeader';
import { ImageGrid } from './image-grid/ImageGrid';
import { FeedbackDrawer } from './FeedbackDrawer/FeedbackDrawer';
import { FeedbackDrawerToggler } from './FeedbackDrawer/FeedbackDrawerToggler';
import { LocationModalSubHeader } from './header/LocationModalSubHeader';
import { BarcodesInformation } from './barcodes/BarcodesInformation';
import { LocationHistory } from './location-history/LocationHistory';
import { WMSRawData } from './wms-raw-data/WMSRawData';
import { initialLocationData } from './initialLocationData';

export const LocationModal = ({
  opened,
  closeModal,
  refreshDataOnClose,
  locations,
  parentPage,
  reportId,
}: {
  opened: boolean;
  closeModal: () => void;
  refreshDataOnClose: IFacilityModalsState['refreshDataOnClose'];
  locations: any[]; // This needs to be fixed
  parentPage: IFacilityModalsState['parentPage'];
  reportId: string | null | undefined;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [locationsData] = useState<ILocationData[]>(locations);
  const [locationData, setLocationData] = useState<ILocationData>(initialLocationData);
  const [currentLocationData, setCurrentLocationData] = useState(
    find(locations, (location) => location.selected) as ILocationData,
  );
  const [currentLocationIndex, setCurrentLocationIndex] = useState(
    findIndex(locations, (location) => location.selected),
  );

  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false);

  const [didUserOverwrite, setDidUserOverwrite] = useState(false);

  const { currentSystemId: systemId, facilitySettings } =
    useFacilityLevelStore().stateFacilityLevel;

  const { requestController } = useRequestController(`${parentPage} - LocationModal`);

  // FIX-ME::TR::2021-08-11:: Re-instate when re-instating the download images function
  // const { name: facilityName } = state.facilityData;

  // update the modal's internal data structures
  // - the list of locations to navigate through
  // - the location currently being displayed
  const updateCurrentLocationAndLocationSet = useCallback(
    (loc: IIssueSlotStatusST, issues: IIssueST[]) => {
      const rowDataAux = getRowForFullReportTable(loc.slot_label!, loc, issues);
      const locData = rowDataAux.actions.data;

      setCurrentLocationData(locData);
    },
    [],
  );

  const refreshLocationData = useCallback(
    (locationLabel: string) => {
      switch (parentPage) {
        case 'WarehouseStatus':
          requestController.doRequest({
            request: WarehouseStore.getLocationsData,
            requestParams: [systemId, locationLabel, 1],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.locationsData[locationLabel],
                r.locationsData[locationLabel].issues,
              ),
            messageErrorFallback: 'Location Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        case 'Report':
          requestController.doRequest({
            request: ReportStore.getReportData,
            requestParams: [
              systemId,
              reportId,
              locationLabel,
              locationLabel,
              requestController.signal,
            ],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.reportData.locations_data[locationLabel],
                r.reportData.issues[locationLabel],
              ),
            messageErrorFallback: 'Report Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        default:
          break;
      }
    },
    [parentPage, reportId, requestController, systemId, updateCurrentLocationAndLocationSet],
  );

  const handleCloseModal = () => {
    if (didUserOverwrite) {
      refreshDataOnClose.refreshData();
    }
    searchParams.delete('location');
    setSearchParams(searchParams);
    closeModal();
  };

  const sendLocationInfo = (feedbackCategories: FeedbackReasonType[], message: string) => {
    const slotStatusVersion =
      currentLocationData.slotStatusVersion === '-' ? null : currentLocationData.slotStatusVersion;
    // Get the current URL and pass it as link to get back to this modal.
    // NOTE: as the location feedback can be done only form here, this is sufficient.
    const linkToTheCurrentLocation = window.location.href;

    return singleRequestHandler({
      request: WarehouseStore.sendLocationInfoEmail,
      requestParams: [
        systemId,
        currentLocationData.location,
        slotStatusVersion,
        currentLocationData?.slotStatusVersion || null,
        parentPage,
        reportId || null,
        feedbackCategories,
        message,
        linkToTheCurrentLocation,
      ],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => setSpinnerLoading(true),
      callbackFinally: () => setSpinnerLoading(false),
      messageSuccess: `Feedback on location ${currentLocationData.location} was submitted to Verity.`,
    });
  };

  // Question: why is this needed? locationData == currentLocationData?
  // update current location data
  useEffect(() => {
    const ld: ILocationData = {
      id: currentLocationData.id,
      imageIds: currentLocationData.imageIds,
      location: currentLocationData.location,
      issueType: currentLocationData.issueType,

      wmsValue: currentLocationData.wmsValue,
      wmsState: currentLocationData.wmsState,
      state: currentLocationData.state,

      verityValue: currentLocationData.verityValue,
      verityState: currentLocationData.verityState,

      userOverride: currentLocationData.userOverride,
      userOverrideValue: currentLocationData.userOverrideValue,

      // Use version if only if it exists.
      // A version does not exist when there is no verity_slot_status for a given location
      version:
        currentLocationData.slotStatusVersion !== '-'
          ? currentLocationData.slotStatusVersion
          : null,

      length: currentLocationData.length,
      rowData: currentLocationData.rowData,

      isReview: currentLocationData.isReview,
      slotStatusVersion: currentLocationData.slotStatusVersion,
    };

    setLocationData(() => ld);
  }, [currentLocationData]);

  const historyLimitTimePoint =
    parentPage === 'Report'
      ? getMostRecent(
          currentLocationData.rowData.wmsDate,
          currentLocationData.rowData.contentFoundDate,
        )
      : undefined;

  return (
    <ModalBase
      help={LocationModalHelp}
      testId="c-location-modal"
      opened={opened}
      maxWidth={false}
      disableBackdropClick={true}
      fullScreen
      title={
        <LocationModalHeader
          parentPage={parentPage || 'WarehouseStatus'}
          locationData={locationData}
          allLocations={locationsData}
          currentLocationData={currentLocationData}
          setCurrentLocationData={setCurrentLocationData}
          refreshLocationData={refreshLocationData}
          currentLocationIndex={currentLocationIndex}
          setCurrentLocationIndex={setCurrentLocationIndex}
        />
      }
      handleClose={handleCloseModal}
    >
      {spinnerLoading && <Spinner />}

      {locationData && (
        <LocationModalSubHeader locationData={locationData} facilitySettings={facilitySettings} />
      )}

      <Grid container>
        <Grid item lg={feedbackDrawerOpen ? 9 : 12} sm={feedbackDrawerOpen ? 8 : 12}>
          <Grid spacing={1} container sx={{ overflowX: 'auto' }}>
            <Grid lg={12} md={12} sm={12} item sx={{ minWidth: 1200 }}>
              <BarcodesInformation
                locationData={locationData}
                facilitySettings={facilitySettings}
              />
            </Grid>
          </Grid>
          <Grid lg={12} md={12} sm={12} item>
            <ImageGrid
              systemId={systemId || ''}
              verityState={locationData.verityState}
              locationData={currentLocationData}
            />
          </Grid>
          <FeedbackDrawerToggler
            feedbackDrawerOpen={feedbackDrawerOpen}
            setFeedbackDrawerOpen={setFeedbackDrawerOpen}
          />
          <Grid spacing={1} container paddingTop={3}>
            <Grid lg={6} sm={12} item>
              {locationData.location && systemId && (
                <WMSRawData
                  slotLabel={locationData.location}
                  systemId={systemId}
                  key={locationData.location}
                />
              )}
            </Grid>
            <Grid lg={6} sm={12} item>
              {locationData.location && systemId && (
                <LocationHistory
                  slotLabel={locationData.location}
                  systemId={systemId}
                  timePoint={historyLimitTimePoint}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {feedbackDrawerOpen && (
          <Grid item>
            <FeedbackDrawer
              isOpen={feedbackDrawerOpen}
              locationData={locationData}
              setSpinnerLoading={setSpinnerLoading}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
              currentLocationIndex={currentLocationIndex}
              sendLocationInfo={sendLocationInfo}
            />
          </Grid>
        )}
      </Grid>
    </ModalBase>
  );
};
