import { Box } from '@mui/material';
import { IReportST } from 'codegen/report';
import { isReportFinished } from 'common/functions/reportFunctions';
import { ReportSummaryDataSectionItem } from '../ReportStore.model';

export const getReportSummarySections = (reportSummary: IReportST) => {
  const items: ReportSummaryDataSectionItem[] = [];

  const includedLocations =
    reportSummary.num_locations - (reportSummary.location_data_count.EXCLUDED || 0);

  items.push({
    id: 'included-locations',
    label: (
      <Box component="div" fontWeight="bold">
        Included locations
      </Box>
    ),
    value: (
      <Box component="div" fontWeight="bold">
        {includedLocations}
      </Box>
    ),
  });

  const completed = progressInPercent(reportSummary.location_data_count.SCANNED, includedLocations);

  items.push({
    id: 'completed-locations',
    label: `- result available (${completed}%)`,
    value: reportSummary.location_data_count.SCANNED,
  });

  const unreachable = progressInPercent(reportSummary.num_unreachable_locations, includedLocations);

  items.push({
    id: 'unreachable-locations',
    label: `- unreachable (${unreachable}%)`,
    value: reportSummary.num_unreachable_locations,
    alertBox: {
      visible: true,
      status: 'info',
      text: <span>Locations that could not be accessed due to obstacles or No-Fly Zones.</span>,
    },
  });

  if (isReportFinished(reportSummary.state)) {
    const outOfTime = progressInPercent(
      reportSummary.location_data_count.ABORTED,
      includedLocations,
    );

    items.push({
      id: 'out-of-time-locations',
      label: `- out of time (${outOfTime}%)`,
      value: reportSummary.location_data_count.ABORTED,
      alertBox: {
        visible: reportSummary.location_data_count.ABORTED > 0,
        status: 'info',
        text: (
          <span>
            Locations that could not be scanned before the system was locked or the report deadline
            passed.
          </span>
        ),
      },
    });
  } else {
    const remainingLocations =
      reportSummary.location_data_count.TO_BE_EXECUTED +
      reportSummary.location_data_count.PENDING_BACKUP;
    const remaining = progressInPercent(remainingLocations, includedLocations);

    items.push({
      id: 'remaining-locations',
      label: `- remaining (${remaining}%)`,
      value: remainingLocations,
    });
  }

  items.push(
    {
      id: 'separator-summary-section-1',
      label: String.fromCharCode(160),
      value: '',
    },
    {
      id: 'excluded-locations',
      label: (
        <Box component="div" fontWeight="bold">
          Excluded Locations
        </Box>
      ),
      value: (
        <Box component="div" fontWeight="bold">
          {reportSummary.location_data_count.EXCLUDED ?? 0}
        </Box>
      ),
      alertBox: {
        visible: true,
        status: 'info',
        text: (
          <span>
            Locations intentionally omitted from scanning by the user via{' '}
            <i>
              <b>Locations Management</b>
            </i>
            .
          </span>
        ),
      },
    },
    {
      id: 'total-locations',
      label: (
        <Box component="div" fontWeight="bold">
          Total Locations
        </Box>
      ),
      value: (
        <Box component="div" fontWeight="bold">
          {reportSummary.num_locations}
        </Box>
      ),
      alertBox: {
        visible: true,
        status: 'info',
        text: <span>Locations in the area covered by the report (Included + Excluded).</span>,
      },
    },
  );
  return [
    {
      id: 'report-summary-section',
      sectionItems: [...items],
    },
  ];
};

export const progressInPercent = (numerator: number, denominator: number) => {
  if (!numerator) {
    return (0).toFixed(1);
  }
  return ((100 * numerator) / denominator).toFixed(1);
};
