import { EnhancedTableHeaderCell } from 'components/EnhancedTable/types/header';

/**
 * Populate header cells based on wms header
 * @param hasWmsHeader boolean
 * @returns array of header cells
 */
export const locationsHeaderCells = (hasWmsHeader: boolean): EnhancedTableHeaderCell[] => {
  const headerCells: EnhancedTableHeaderCell[] = [
    {
      id: 'id',
      label: '',
      show: true,
      type: 'checkbox',
      searchable: false,
      height: '30px',
    },
    {
      id: 'slotLabel',
      label: 'Location',
      show: true,
      type: 'string',
      searchable: true,
      align: 'left',
      height: '30px',
    },
    {
      id: 'wmsExclusionStatus',
      label: 'WMS Scan Permission',
      align: 'left',
      show: hasWmsHeader,
      type: 'icon',
      searchable: false,
      height: '30px',
    },
    {
      id: 'udbExclusionStatus',
      label: hasWmsHeader ? 'Manual Override' : 'Scan Permission',
      tooltipInfo: hasWmsHeader ? 'Overrides the WMS Scan Permissions' : '',
      align: 'left',
      show: true,
      type: 'icon',
      searchable: false,
      height: '30px',
    },
    {
      id: 'reason',
      label: 'Reason',
      align: 'left',
      show: true,
      truncate: true,
      type: 'string',
      searchable: false,
      height: '30px',
    },
    {
      id: 'createdAt',
      label: 'Update Date',
      align: 'left',
      show: true,
      type: 'string',
      searchable: false,
      height: '30px',
    },
    {
      id: 'updatedBy',
      label: 'Update User',
      align: 'left',
      show: true,
      type: 'string',
      searchable: false,
      height: '30px',
    },
    {
      id: 'action',
      label: '',
      type: 'custom',
      show: hasWmsHeader,
      searchable: false,
      align: 'right',
      height: '30px',
    },
  ];

  return headerCells;
};
