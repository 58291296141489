import { WebSocketSubscription } from 'shared/web-socket/web-socket.model';

export type CommissioningPageSubscribersOptions = 'estimatedObstacles';

export const commissioningPageSubscribers: Record<
  CommissioningPageSubscribersOptions,
  WebSocketSubscription
> = {
  estimatedObstacles: {
    channel: 'ground-control/estimated-obstacles/status-update',
    delegate: 'CommissioningPageEstimatedObstacles',
  },
};
