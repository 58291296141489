/* eslint-disable react/no-unknown-property */
import { GroupProps, MeshProps } from '@react-three/fiber';
import { BoxGeometry, CylinderGeometry, Euler, MeshBasicMaterial, Vector3 } from 'three';

const Propeller = (props: MeshProps) => (
  <mesh
    geometry={new CylinderGeometry(0.15, 0.15, 0.01, 32)}
    rotation={new Euler(Math.PI / 2, 0, 0)}
    material={new MeshBasicMaterial({ color: 0x993456 })}
    {...props}
  />
);

const Ribs = (props: GroupProps) => (
  <group {...props}>
    <mesh
      geometry={new BoxGeometry(0.06, 0.6, 0.03)}
      material={new MeshBasicMaterial({ color: 0x000000 })}
    />
    <mesh
      geometry={new BoxGeometry(0.06, 0.6, 0.03)}
      rotation={new Euler(0, 0, Math.PI / 2)}
      material={new MeshBasicMaterial({ color: 0x000000 })}
    />
  </group>
);

const Nose = (props: MeshProps) => (
  <mesh
    {...props}
    geometry={new CylinderGeometry(0.00001, 0.08, 0.3)}
    material={new MeshBasicMaterial({ color: 0xff1122 })}
    rotation={new Euler(0, 0, Math.PI + Math.PI / 4)}
    position={new Vector3(0.15, -0.15, 0)}
  />
);

const rotationOffset = new Euler(0, 0, Math.PI / 4);

export const DroneMesh = (props: GroupProps) => (
  <group {...props}>
    <group rotation={rotationOffset}>
      <Nose />
      <Ribs />
      <Propeller position={[0.3, 0, 0.03]} />
      <Propeller position={[0, 0.3, 0.03]} />
      <Propeller position={[-0.3, 0, 0.03]} />
      <Propeller position={[0, -0.3, 0.03]} />
    </group>
  </group>
);
