import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { makeStyles } from 'tss-react/mui';
import { ImageList, ImageListItem, Theme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';
import { BarcodeVisualization } from './BarcodeVisualization';
import { useFacilityLevelStore } from '../../../../store/FacilityLevelStore/facilityLevelStore';
import { FullSizeImageModal } from './full-size-image-modal/FullSizeImageModal';

const NUMBER_OF_IMAGES_TO_DISPLAY = 3;

const useStyles = makeStyles()((theme: Theme) => ({
  imageListContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    margin: 'auto 0',
    position: 'relative',
    width: '100%',
    paddingBottom: 16,

    '&::-webkit-scrollbar': {
      height: 9,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#6359D1',
      borderRadius: 200,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#D9D9D9',
      borderRadius: 10,
    },
  },

  imageListItem: {
    width: `calc((100% / ${NUMBER_OF_IMAGES_TO_DISPLAY}) - ${theme.spacing(1)})`,
    minWidth: `calc((100% / ${NUMBER_OF_IMAGES_TO_DISPLAY}) - ${theme.spacing(1)})`,
    aspectRatio: '4 / 3',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    cursor: 'default',

    '&.alt-pressed': {
      cursor: 'pointer',
    },
  },
}));

export const ImageGridComponent = ({
  imageUrls,
  resultId,
  systemId,
}: {
  imageUrls: string[];
  resultId: string;
  systemId: string;
}) => {
  const { classes } = useStyles();

  const [imagePointerVisible, setImagePointerVisible] = useState(false);
  const imageListContainer = useRef<HTMLUListElement>(null);
  const imageListItemRefs = useRef<HTMLLIElement[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isFullSizeImageModalOpen, setIsFullSizeImageModalOpen] = useState(false);

  useEffect(() => {
    const imageToScrollTo = imageListItemRefs.current[selectedImageIndex];
    imageListContainer?.current?.scrollTo({
      left: imageToScrollTo?.offsetLeft,
      behavior: 'smooth',
    });
  }, [selectedImageIndex]);

  const scrollToPrevImage = useCallback(() => {
    const prev = Math.max(selectedImageIndex - 1, 0);
    setSelectedImageIndex(prev);
  }, [selectedImageIndex]);

  const scrollToNextImage = useCallback(() => {
    const next = Math.min(
      selectedImageIndex + 1,
      isFullSizeImageModalOpen
        ? imageUrls.length - 1
        : imageUrls.length - NUMBER_OF_IMAGES_TO_DISPLAY,
    );
    setSelectedImageIndex(next);
  }, [selectedImageIndex, isFullSizeImageModalOpen, imageUrls.length]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      if (event.code === 'ArrowUp') scrollToPrevImage();
      if (event.code === 'ArrowDown') scrollToNextImage();
      if (event.code === 'Period' || event.code === 'NumpadDecimal') {
        setSelectedImageIndex(0);
        setIsFullSizeImageModalOpen(true);
      }

      if (event.altKey) {
        setImagePointerVisible(true);
      }
    },
    [scrollToPrevImage, scrollToNextImage],
  );

  const handleKeyUp = useCallback(() => {
    setImagePointerVisible(false);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    setSelectedImageIndex(0);
  }, [imageUrls]);

  const { stateFacilityLevel } = useFacilityLevelStore();

  const handleImageClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> & { altKey: boolean },
    index: number,
  ) => {
    if (e.altKey) {
      setSelectedImageIndex(index);
      setIsFullSizeImageModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    if (selectedImageIndex > imageUrls.length - NUMBER_OF_IMAGES_TO_DISPLAY) {
      setSelectedImageIndex(imageUrls.length - NUMBER_OF_IMAGES_TO_DISPLAY);
    }
    setIsFullSizeImageModalOpen(false);
  };

  return (
    <>
      <ImageList className={classes.imageListContainer} gap={8} ref={imageListContainer}>
        {imageUrls.map((imageURL, index) => (
          <ImageListItem
            className={`${classes.imageListItem} ${imagePointerVisible ? 'alt-pressed' : ''}`}
            key={imageURL}
            ref={(el: any) => (imageListItemRefs.current[index] = el as HTMLLIElement)}
            onClick={(e: any) => handleImageClick(e, index)}
          >
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={imageURL}
                  alt={`DronePic - ${index}`}
                  loading="lazy"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
                {stateFacilityLevel.facilitySettings?.enable_barcode_highlighting && (
                  <BarcodeVisualization
                    systemId={systemId}
                    resultId={resultId}
                    imageURL={imageURL}
                    key={imageURL}
                  />
                )}
              </TransformComponent>
            </TransformWrapper>
          </ImageListItem>
        ))}
      </ImageList>
      <FullSizeImageModal
        imageUrls={imageUrls}
        isModalOpen={isFullSizeImageModalOpen}
        selectedImageIndex={selectedImageIndex}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
