import { ListItemIcon, ListItemText, SelectChangeEvent } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Dispatch } from 'react';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import { Box } from 'components/common/Box';
import { ScheduleFormAction } from '../reducers/ScheduleFormReducer';
import { ReportPriority } from '../models/reportPriority.model';

const REPORT_PRIORITY = {
  '-1': {
    label: (
      <Box display="flex" alignItems="center">
        <ListItemIcon sx={{ minWidth: 32 }}>
          <ArrowDownwardIcon color="success" />
        </ListItemIcon>
        <ListItemText>Low</ListItemText>
      </Box>
    ),
    value: -1,
  },
  0: {
    label: (
      <Box display="flex" alignItems="center">
        <ListItemIcon sx={{ minWidth: 32 }}>
          <HorizontalRuleIcon />
        </ListItemIcon>
        <ListItemText>Normal</ListItemText>
      </Box>
    ),
    value: 0,
  },
  1: {
    label: (
      <Box display="flex" alignItems="center">
        <ListItemIcon sx={{ minWidth: 32 }}>
          <ArrowUpwardIcon color="error" />
        </ListItemIcon>
        <ListItemText>High</ListItemText>
      </Box>
    ),
    value: 1,
  },
};

type ReportPriorityPickerProps = {
  priority: ReportPriority;
  dispatch: Dispatch<ScheduleFormAction>;
};

/**
 * Report priority picker
 * @param props ReportPriorityPickerProps
 * @returns component
 */
export const ReportPriorityPicker = ({ priority, dispatch }: ReportPriorityPickerProps) => {
  const handleChange = (e: SelectChangeEvent<string>) =>
    dispatch({ type: 'SET_PRIORITY', payload: Number(e.target.value) as ReportPriority });

  return (
    <CustomSelect
      testId="c-schedule-report-priority"
      variant="outlined"
      margin="dense"
      sx={{ marginBottom: 1.5 }}
      disabled={false}
      id="report-priority"
      label="Priority"
      name="report-priority"
      value={priority.toString()}
      defaultValue=""
      valueOptions={Object.values(REPORT_PRIORITY)}
      onChange={handleChange}
      error={false}
      errorMessage=""
    />
  );
};
