import { Vec6 } from 'shared/map-container/MapContainer.model';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { RecOfStrings } from 'ts-types/Utility';
import { BottomLeftDimensions } from '../../../model/NoFlyZoneGeometry.model';
import { validateSizeAndPositionFitsWithinWorldBox } from './validateSizeAndPositionFitsWithinWorldBox';

export const validateNFZForm = (worldBox: Vec6) => (values: NoFlyZone) => {
  let errors: RecOfStrings<NoFlyZone> = {};

  if (!values.name.trim().length) {
    errors = {
      ...errors,
      name: 'A name is required to create a no-fly zone.',
    };
  }

  Object.keys(values.sizeAndPosition).forEach((key) => {
    const validationMessage: string | null = validateSizeAndPositionFitsWithinWorldBox({
      zoneVectors: values.sizeAndPosition,
      facilityVectors: worldBox,
      fieldKey: key as keyof BottomLeftDimensions,
    });

    if (validationMessage) {
      errors = {
        ...errors,
        sizeAndPosition: {
          ...errors.sizeAndPosition,
          [key]: validationMessage,
        },
      };
    }
  });

  return errors;
};
