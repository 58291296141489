import { useState, useEffect, useCallback } from 'react';

import { areAccessAndIdTokensExpired } from 'common/functions/tokenFunctions';
import { asyncGetUploadedFiles, IProcessedFile } from 'common/functions/otherFunctions';
import * as tables from 'common/Tables';
import { getLogPrefixForType } from 'common/functions/logFunctions';

import { LocalStore } from 'common/functions/storageFunctions';
import { useSettingsStore } from '../../store/Settings/settingsStore';
import { useRequestController } from '../../hooks';

import './style.css';

import { AutomaticUploadsCard } from './AutomaticUploadsCard';
import { WarehouseStatusCard } from './WarehouseStatusCard';
import { UPLOADED_FILES_TYPES } from '../../interfaces';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

// Note 1: issueTab are not dependent on the user / user role and, hence, they
// can be calculated as a constant when the home page is instantiated for the
// first time (that is, unlike report tabs, the do not become stale).
// Note 2: it seems that deconstructing a function, automatically invokes it.

const logPrefix = getLogPrefixForType('COMPONENT', 'HomeWarehouseStatGrid');

export const HomeWarehouseStatGrid = (props: { systemId: string }) => {
  const { HOME_SCREEN_SETTINGS, WAREHOUSE_UPLOADS_TABLE_TABS } = useSettingsStore();

  // tabs that will be active when navigating to
  // Reports/Report/WHS and WHS Uploads pages
  const { wmsSnapshotFilesTab } = tables.warehouseUploadsMappings(WAREHOUSE_UPLOADS_TABLE_TABS);

  const [latestWMSSnapshotFile, setLatestWMSSnapshotFile] = useState<IProcessedFile>();
  const [latestStockCheckFile, setLatestStockCheckFile] = useState<IProcessedFile>();
  const [latestMovedLocationsFile, setLatestMovedLocationsFile] = useState<IProcessedFile>();

  const { systemId } = props;

  const { requestController } = useRequestController(logPrefix);
  const isAutomaticUploadVisible =
    HOME_SCREEN_SETTINGS.SHOW_AUTOMATIC_UPLOADS_SUMMARY_CARD &&
    userHasPermission(PERMISSION.WMS_MANAGEMENT);

  const timezone = LocalStore.getTimezone();

  const getFiles = useCallback(
    async (
      fileType: UPLOADED_FILES_TYPES,
      systemId: string,
      setFiles: (lastProcessedFile: IProcessedFile) => void,
    ) => {
      console.debug(logPrefix, `getFiles(${fileType}, ${systemId}, ${setFiles})`);
      const filesRequestReservation = requestController.reserveSlotForRequest();
      const { lastProcessedFile } = await asyncGetUploadedFiles({
        systemId,
        fileType,
        params: { latest: true },
        timezone,
        requestController,
        requestId: filesRequestReservation.requestId,
        signal: filesRequestReservation.signal,
      });

      // NOTE: this is needed to avoid memory leaks when the component has been unmounted
      // while the asyncGetUploadedFiles request was running.
      if (!requestController.isRequestCancelled(filesRequestReservation.requestId)) {
        setFiles(lastProcessedFile);
      }
    },
    [requestController, timezone],
  );

  useEffect(() => {
    if (!systemId) {
      // It has been observed on occasion that (on live/production) systems the requests
      // are being fired even though there is no System ID in localStorage yet
      // see UD-3272 (https://verity-ag.atlassian.net/browse/UD-3272)
      console.debug(logPrefix, 'invoked with null/undefined systemId');
      return;
    }

    if (!areAccessAndIdTokensExpired()) {
      Promise.all([
        getFiles(UPLOADED_FILES_TYPES.WMSSnapshot, systemId, setLatestWMSSnapshotFile),
        getFiles(UPLOADED_FILES_TYPES.StockCheck, systemId, setLatestStockCheckFile),
        getFiles(UPLOADED_FILES_TYPES.MovedLocations, systemId, setLatestMovedLocationsFile),
      ]);
    }
  }, [getFiles, systemId]);

  return (
    <>
      {userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS) && (
        <WarehouseStatusCard systemId={systemId} />
      )}

      {isAutomaticUploadVisible && (
        <AutomaticUploadsCard
          systemId={systemId}
          latestWMSSnapshotFile={latestWMSSnapshotFile}
          latestStockCheckFile={latestStockCheckFile}
          latestMovedLocationsFile={latestMovedLocationsFile}
          wmsSnapshotFilesTab={wmsSnapshotFilesTab}
        />
      )}
    </>
  );
};
