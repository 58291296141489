import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2),
  },
  flightHoursItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: theme.spacing(2),
    border: '1px solid #e0e0e0',
  },
  itemText: {
    flex: '1 1 auto',
    minWidth: 0,
  },
  cancelEditButton: {
    marginRight: theme.spacing(4),
    textTransform: 'none',
    color: theme.palette.text.disabled,
  },
  wideDash: {
    padding: theme.spacing(0, 2),
    display: 'inline-block',
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: theme.spacing(1, 8),
  },
  helpfulMessage: {
    textAlign: 'left',
    color: 'red',
  },
  timePicker: {
    marginLeft: '4px',
    '& .MuiInputBase-formControl': {
      borderRadius: '0 !important',
    },
    '& .MuiInputBase-formControl input': {
      padding: '18px 0 7px',
    },
    '& .MuiOutlinedInput-root fieldset': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiOutlinedInput-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd button': {
      padding: '6px',
    },
    '& .MuiInputLabel-formControl': {
      marginLeft: '-50px',
    },
  },
}));
