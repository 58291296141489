import { WebSocketSubscriber } from 'shared/web-socket/web-socket.model';

export const subscribers: Record<
  'noFlyZone' | 'controlledZone' | 'openFlyZone' | 'flightAreas',
  Pick<WebSocketSubscriber, 'channel' | 'delegate'>
> = {
  noFlyZone: {
    channel: 'ground-control/no-fly-zone/status-update',
    delegate: 'NoFlyZones',
  },
  controlledZone: {
    channel: 'ground-control/controlled-zone/status-update',
    delegate: 'ControlledZones',
  },
  openFlyZone: {
    channel: 'ground-control/open-fly-zone/status-update',
    delegate: 'OpenFlyZones',
  },
  flightAreas: {
    channel: 'ground-control/flight-areas/update',
    delegate: 'FlightArea',
  },
};
