import { IUserLevelData } from './IUserLevelStore';
import { UserLevelAction } from './userLevelActions';

/**
 * Initial state for user level data
 */
export const initialUserLevelState: IUserLevelData = {
  isAuth: false,
  username: '',
  usernameHashed: '',
  isDataLoaded: false,
  isWebSocketAuthorized: false,
  authProcess: false,
  isMfaAuth: false,
  details: {
    attrName: '',
    challengeName: null,
  },
};

/**
 * Initial state for user context store
 */
export const initialUserStateContext = {
  stateUserLevel: initialUserLevelState,
  dispatchUserLevelStore: (action: UserLevelAction) => {},
  getUserData: () => {},
  isDataReady: () => false,
};
