import { notEver } from 'common/functions/otherFunctions';
import { LocationsActionNames, LocationsActions } from './LocationsActions';
import { LocationsState } from './LocationsState';

export const locationsReducer = (
  state: LocationsState,
  action: LocationsActions,
): LocationsState => {
  switch (action.type) {
    case LocationsActionNames.LOCATIONS_RECEIVED: {
      return {
        ...state,
        locations: {
          ...state.locations,
          ...action.payload,
        },
      };
    }
    case LocationsActionNames.SET_SELECTED_IDS: {
      return {
        ...state,
        selectedIds: action.payload,
      };
    }
    case LocationsActionNames.SET_ID_FOR_RESET: {
      return {
        ...state,
        idSelectedForReset: action.payload,
      };
    }
    case LocationsActionNames.SET_ID_FOR_DETAILS: {
      return {
        ...state,
        idSelectedForDetails: action.payload,
      };
    }
    case LocationsActionNames.SET_UPLOADED_FILE: {
      return {
        ...state,
        uploadedFile: action.payload,
      };
    }
    case LocationsActionNames.SET_LOCATION_EXCLUSION_HISTORY: {
      const exclusionHistory = action.payload
        ? {
            ...state.exclusionHistory,
            [action.payload.request_id as string]: action.payload,
          }
        : null;

      return {
        ...state,
        exclusionHistory,
      };
    }
    case LocationsActionNames.SET_UPDATE_LOCATIONS: {
      const { locations: payloadLocations, request_id, reason, email, created_at } = action.payload;
      const locations = payloadLocations?.reduce(
        (acc, val) => ({
          ...acc,
          [val.label]: {
            ...state.locations[val.label],
            slot_settings: {
              exclusion_status: val.exclusion,
              exclusion_request_id: request_id,
            },
          },
        }),
        {},
      );

      return {
        ...state,
        locations: {
          ...state.locations,
          ...locations,
        },
        exclusionHistory: {
          ...state.exclusionHistory,
          [request_id as string]: {
            request_id,
            reason,
            email,
            created_at,
          },
        },
        idSelectedForReset: '',
        uploadedFile: null,
      };
    }
    default:
      notEver(action);
      return state;
  }
};
