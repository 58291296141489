import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// layouts
import { CLIENT_PAGES_URLS, WARNING_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import ClientPage from '../pages/layouts/ClientPage';

// pages
import SelectFacility from '../pages/SelectFacility/SelectFacility';
import { NotFound } from '../pages/Error/NotFound';

// facility routes
import FacilityRoutes from './Facility/FacilityRoutes';

const SelectFacilityComponent: () => JSX.Element =
  typeof SelectFacility === 'function' ? (SelectFacility as () => JSX.Element) : () => <NotFound />;

const ClientRoutes = () => {
  const location = useLocation();
  const logLocationPrefix = getLogPrefixForType('PAGE', location.pathname);
  const logPrefix = getLogPrefixForType('COMPONENT', 'ClientRoutes', logLocationPrefix);
  console.debug(logPrefix, 'render route');

  return (
    <ClientPage>
      <Routes>
        <Route path="/" element={<Navigate to={CLIENT_PAGES_URLS.SELECT_FACILITY} />} />
        <Route path="select-facility" element={<SelectFacilityComponent />} />
        {/* Facility pages */}
        <Route path="/:systemId/*" element={<FacilityRoutes />} />
        {/* Not found pages */}
        <Route path={WARNING_PAGES_URLS.NOT_FOUND} element={<NotFound />} />

        <Route path="*" element={<Navigate to={WARNING_PAGES_URLS.NOT_FOUND} />} />
      </Routes>
    </ClientPage>
  );
};

export default React.memo(ClientRoutes);
