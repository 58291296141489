// libraries
import moment from 'moment-timezone';

// mui core
import Link from '@mui/material/Link';

// variables, functions, configurations
import { RELATIVE_DATETIME_FORMAT } from 'common/datetimeFormats';

// components
import BaseCard from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem from 'components/BaseCard/BaseCardSimpleItem';
import { IFacilityMapInfoST } from 'codegen/facility';
import { FacilityDetailsFields, FacilityMapDataFields } from './FacilityDetails';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';

/**
 * Props for FacilityDetailsCard
 */
interface IFacilityMapDetailsCardProp {
  /**
   * Facility details fields and map data
   */
  facilityData: FacilityDetailsFields & FacilityMapDataFields;
  /**
   * Facility Map Info data.
   */
  facilityMapInfo: IFacilityMapInfoST;
  /**
   * Downloads map file
   */
  downloadMapFile: () => void;
  /**
   * Downloads map processing log
   */
  downloadMapProcessingLog: () => void;
}

/**
 * Facility map details card
 * @param props: IFacilityMapDetailsCardProp
 * @returns Flight Domains card component
 */
const MapDetailsCard = ({
  facilityData,
  facilityMapInfo,
  downloadMapFile,
  downloadMapProcessingLog,
}: IFacilityMapDetailsCardProp) => {
  const { stateFacilityLevel } = useFacilityLevelStore();

  const facilityMapDetailsData = {
    title: 'Map Details',
    subtitle: 'Facility map details',
    sections: [
      {
        testId: 'c-facility-map-details-section',
        isWidget: false,
        sectionItems: [
          {
            id: 'facility-map-name',
            label: 'Name',
            value: facilityMapInfo?.facility_map_name || 'not available',
            testId: 'c-facility-map-name',
          },
          {
            id: 'facility-map-version',
            label: 'Version',
            value: facilityMapInfo?.facility_map_version || 'not available',
            testId: 'c-facility-map-version',
          },
          {
            id: 'facility-map-created-at',
            label: 'Created',
            value: facilityMapInfo?.facility_map_created_at
              ? facilityData?.timezone &&
                moment(facilityMapInfo?.facility_map_created_at)
                  .tz(facilityData?.timezone)
                  .calendar(null, RELATIVE_DATETIME_FORMAT)
              : 'not available',
            testId: 'c-facility-map-created-at',
          },
          {
            id: 'facility-map-file-name',
            label: 'File',
            value: facilityMapInfo?.facility_map_file_name ? (
              <Link onClick={() => downloadMapFile()} style={{ cursor: 'pointer' }}>
                {facilityMapInfo.facility_map_file_name}
              </Link>
            ) : (
              'not available'
            ),
            testId: 'c-facility-map-file-name',
          },
          {
            id: 'facility-map-processing-log',
            label: 'Processing log',
            value: facilityMapInfo?.facility_map_processing_log_bucket_key ? (
              <Link onClick={() => downloadMapProcessingLog()} style={{ cursor: 'pointer' }}>
                processing log
              </Link>
            ) : (
              'not available'
            ),
            testId: 'c-facility-map-processing-log',
          },
          {
            id: 'facility-map-valid',
            label: 'Valid',
            value: facilityMapInfo?.facility_map_valid?.toString() || 'not available',
            testId: 'c-facility-map-valid',
          },
          {
            id: 'facility-map-nodes',
            label: 'Nodes',
            value: facilityMapInfo?.facility_map_num_nodes || 'not available',
            testId: 'c-facility-map-num-nodes',
          },
          {
            id: 'facility-map-locations',
            label: 'Locations',
            value: facilityMapInfo?.facility_map_num_slots || 'not available',
            testId: 'c-facility-map-num-slots',
          },
        ],
      },
    ],
  };

  return (
    <BaseCard
      cardFor="facility map"
      dataTestId="c-facility-map-details-card"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      isLoading={!stateFacilityLevel.facilityMapInfoLoaded}
      title={facilityMapDetailsData.title}
      subtitle={facilityMapDetailsData.subtitle}
      spinnerLabel="Loading facility map info data..."
    >
      {facilityMapDetailsData &&
        facilityMapDetailsData.sections.map((section, i) => (
          <BaseCardSimpleItem
            key={section.testId}
            testId={`c-flight-domain-list-item-${i}`}
            isWidget={section.isWidget}
            sectionItems={section.sectionItems}
          />
        ))}
    </BaseCard>
  );
};

export default MapDetailsCard;
