import { CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { Box } from 'components/common/Box';
import { CommissioningTaskState } from '../model/commissioningTask.model';

const progressIndicatorColorMap = new Map<
  CommissioningTaskState,
  'disabled' | 'primary' | 'success' | 'error'
>([
  [CommissioningTaskState.CREATED, 'disabled'],
  [CommissioningTaskState.ONGOING, 'primary'],
  [CommissioningTaskState.COMPLETED, 'success'],
  [CommissioningTaskState.ABORTED, 'error'],
]);

export const CommissioningTaskProgressIndicator = (
  props: CircularProgressProps & { value: number; state: CommissioningTaskState },
) =>
  props.state === CommissioningTaskState.ONGOING ? (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box sx={{ left: 10, position: 'relative', display: 'inline-flex' }}>
      <TripOriginIcon fontSize="small" color={progressIndicatorColorMap.get(props.state)} />
    </Box>
  );
