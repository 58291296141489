import { useState } from 'react';
import { useRequestController } from 'hooks';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { NoFlyZone, NoFlyZones } from 'shared/map/model/no-fly-zone.model';
import { LoadingSwitch } from 'components/common/LoadingSwitch';
import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import GroundControlServices from '../../../../../../../services/GroundControlServices';
import { ZonesListProps } from '../../../ZonesList.model';
import { DroneZoneListItem } from '../../../DroneZonesList/components/DroneZoneListItem/DroneZoneListItem';

const LOG_PREFIX: string = getLogPrefixForType('PAGE', 'NoFlyZonesItem');

export const NoFlyZonesListItem = ({
  droneZone,
  systemId,
  flightDomainId,
  isSystemLocked,
  areActionsEnabled,
  onClone,
  onDelete,
  onEdit,
  onSelect,
}: Pick<
  ZonesListProps<NoFlyZones, NoFlyZone>,
  'isSystemLocked' | 'onSelect' | 'systemId' | 'flightDomainId'
> & {
  areActionsEnabled: boolean;
  droneZone: NoFlyZone;
  onClone: (zone: NoFlyZone) => void;
  onEdit: (zone: NoFlyZone) => void;
  onDelete: (zone: NoFlyZone) => void;
}) => {
  const { requestController } = useRequestController(LOG_PREFIX);

  const [isDisabled, setIsDisabled] = useState(false);

  const isToggleDisabled = isDisabled || !isSystemLocked;
  const isToggleChecked = droneZone.isActive;

  const menuOptions = areActionsEnabled
    ? [
        { label: 'Edit', clickHandler: () => onEdit(droneZone) },
        {
          label: 'Clone',
          clickHandler: () =>
            onClone({ ...droneZone, id: NEW_DRONE_ZONE_ID, name: `${droneZone.name} (Copy)` }),
        },
        { label: 'Delete', clickHandler: () => onDelete(droneZone) },
      ]
    : [];

  const handleOnSelect = () => onSelect(droneZone);

  const handleSetZoneOnChange = () => {
    const zoneId = droneZone.id;

    requestController.doRequest({
      request: GroundControlServices.setNoFlyZoneActive,
      callbackBeforeSend: () => setIsDisabled(true),
      callbackFinally: () => setIsDisabled(false),
      requestParams: [systemId, flightDomainId, zoneId, isToggleChecked],
    });
  };

  return (
    <DroneZoneListItem
      id={droneZone.id}
      name={droneZone.name}
      options={menuOptions}
      isSelected={droneZone.isSelected}
      onClick={handleOnSelect}
      actionArea={
        <LoadingSwitch
          aria-label={
            isToggleChecked
              ? `Deactivate drone no-fly zone "${droneZone.name}"`
              : `Activate drone no-fly zone "${droneZone.name}"`
          }
          isLoading={isDisabled}
          isDisabled={isToggleDisabled}
          isChecked={isToggleChecked}
          onChange={handleSetZoneOnChange}
        />
      }
    />
  );
};
