import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

/**
 * Check whether the given location can be snoozed
 * @param locationData Location Data
 * @returns true if snoozable
 */

export const getIsSnoozable = (locationData: ILocationData) =>
  (locationData.rowData?.actions?.actions as { label: string; disabled: boolean }[])?.find(
    (l) => l.label === 'Snooze',
  )?.disabled === false;
