import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  headerControls: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
  },
  headerControlsMapViewToggle: {
    flex: '0 0 auto',
  },
  headerControlsMapZoneVisibilityToggle: {
    flex: '1 1 auto',
    minWidth: '100px',
  },
}));
