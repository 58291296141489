import React from 'react';

// material-ui components
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

// material-ui icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// components
import { Box } from 'components/common/Box';
import TooltipedIcon from 'components/common/TooltipedIcon';

const PasswordHelper = () => {
  const specialCharacters = '= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & /  , > < \' : ; | _ ~ `';

  return (
    <Alert severity="info" style={{ width: 'auto' }}>
      <Box display="flex" flexDirection="column">
        <Typography variant="body1">Minimum length of 10 characters</Typography>
        <Typography variant="body1">Uppercase letters and lowercase letters</Typography>
        <Typography variant="body1">At least one number</Typography>
        <Box display="flex">
          <Typography variant="body1">At least one special character</Typography>
          <TooltipedIcon tooltip={specialCharacters} icon={<InfoOutlinedIcon fontSize="small" />} />
        </Box>
      </Box>
    </Alert>
  );
};

export default PasswordHelper;
