export enum QueryNames {
  SSO_SIGN_IN = 'SSO_SIGN_IN',
  LOCATION_MODAL_GET_IMAGES = 'LOCATION_MODAL_GET_IMAGES',
  LOCATION_MODAL_GET_LOCATION_HISTORY = 'LOCATION_MODAL_GET_LOCATION_HISTORY',
  LOCATION_MODAL_GET_RAW_WMS_DATA = 'LOCATION_MODAL_GET_RAW_WMS_DATA',
  LOCATION_MODAL_GET_LOCATION_METADATA = 'LOCATION_MODAL_GET_LOCATION_METADATA',
  LOCATION_HISTORY_GET_REPORT_FROM_LOCATION = 'LOCATION_HISTORY_GET_REPORT_FROM_LOCATION',
  FETCH_FACILITIES = 'FETCH_FACILITIES',
  FETCH_USERS = 'FETCH_USERS',
}
