import { Vector3 } from 'three';
import { DroneZoneGroupCountBadge } from 'shared/map/features/drone-zone-overlap-mesh/drone-zone-group-count-badge';
import { OverlappingZone } from 'shared/map/utils/zone-overlap/overlapping-zone.model';
import { findOverlapForZones } from 'shared/map/utils/zone-overlap/find-overlap-for-zones';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { MapOptions } from 'shared/map-container/reducer/MapOptionsState';
import { meshHeightOrderOffset } from 'shared/map/defaults/mesh-render-order';

export const groupCountBadgesFromControlledZones = ({
  zones,
  mapOptions,
  minZOffset,
  onDroneZoneGroupClick,
}: {
  zones: ControlledZones;
  mapOptions: MapOptions;
  minZOffset: number;
  onDroneZoneGroupClick: (zones: ControlledZone[]) => void;
}) => {
  const overlappingDroneZones: [string, OverlappingZone][] = Object.entries(
    findOverlapForZones(zones),
  );

  return overlappingDroneZones.map(([id, overlap]) => (
    <DroneZoneGroupCountBadge
      key={`${id}-zone-count-label`}
      zones={overlap.zones}
      options={mapOptions}
      position={
        new Vector3(
          overlap.centerPosition.x,
          overlap.centerPosition.y,
          minZOffset + meshHeightOrderOffset.groups,
        )
      }
      onClick={() => onDroneZoneGroupClick(overlap.zones)}
    />
  ));
};
