import { IFacilitySettingsST } from 'codegen/facility_settings';
import {
  getClientAPIService,
  getCustomIssueLogicService,
  getFacilitySettingsApiService,
  getFlightDomainApiService,
  getWMSDataService,
} from './services';
import { WarehouseService } from './Warehouse';

/**
 * Get the Settings for the given Facility
 * @param systemId System ID (of the facility)
 * @returns Facility Settings
 */
const getFacilitySettings = (systemId: string) =>
  getFacilitySettingsApiService().getFacilitySettings(systemId);

/**
 * Set/Update a facility setting
 * @param systemId System ID (of the facility)
 * @param data Facility Settings to be updated
 * @returns a Promise which resolves if the operation is successful
 */
const setFacilitySettings = (systemId: string, data: IFacilitySettingsST) =>
  getFacilitySettingsApiService().setFacilitySettings(systemId, data);

/**
 * Get the list of all WMS Parsers for the given facility
 * @param systemId System ID of the Facility
 * @returns Array of WMS Parsers
 */
const getWmsParsers = (systemId: string) => getWMSDataService().getWmsParsers(systemId);

/**
 * Get the list of Client API
 * @param systemId System ID of the Facility
 * @returns Array of client APIs
 */
const getWmsClientApi = (systemId: string) => getClientAPIService().getWmsHandlers(systemId);

/**
 * Retrieve the list of Barcode Matches
 * @param systemId System ID of the Facility
 * @returns list of Barcode Match
 */
const getBcMatchLogics = (systemId: string) =>
  getCustomIssueLogicService().getBarcodeMatchLogics(systemId);

/**
 * Retrieve the list of Filters
 * @param systemId System ID of the Facility
 * @returns list of filters
 */
const getIssueLogicFilters = (systemId: string) =>
  getCustomIssueLogicService().getIssueLogicFilters(systemId);

/**
 * Fetch facility flight domains
 * @param systemId systemId of the Facility
 * @param signal Abort Signal
 */
const getFlightDomains = (systemId: string, signal: AbortSignal) =>
  getFlightDomainApiService().getFlightDomains(systemId, { signal });

/**
 * Fetch facility flight domains info
 * @param systemId systemId of the Facility
 * @param signal Abort Signal
 * The response contains a map { id, name }
 * NOTE: This is only used to create the user profile and should be removed as soon as
 * the BE provides a user profile endpoint
 */
const getFlightDomainsInfo = (systemId: string, signal: AbortSignal) =>
  getFlightDomainApiService().getFlightDomainsInfo(systemId, { signal });

const warehouseServices = {
  ...WarehouseService,
  getFacilitySettings,
  setFacilitySettings,
  getWmsParsers,
  getWmsClientApi,
  getBcMatchLogics,
  getFlightDomains,
  getFlightDomainsInfo,
  getIssueLogicFilters,
};

export default warehouseServices;
