import { WebSocketSubscriber } from 'shared/web-socket/web-socket.model';

export const subscribers: Record<
  'controlledZone' | 'openFlyZone',
  Pick<WebSocketSubscriber, 'channel' | 'delegate'>
> = {
  controlledZone: {
    channel: 'ground-control/controlled-zone/status-update',
    delegate: 'ControlledZonesDelivery',
  },
  openFlyZone: {
    channel: 'ground-control/open-fly-zone/status-update',
    delegate: 'OpenFlyZonesDelivery',
  },
};
