import { useState } from 'react';

import { Tabs, Tab, TextField, Slider, Stack } from '@mui/material';

import { singleRequestHandler } from 'common/requestHelpers';
import { Box } from 'components/common/Box';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import {
  IBox3DST,
  ICreateEstimatedObstacleRequestST,
  ICreateEstimatedObstacleRequestSTTypeEnum,
  ICreateSpaceReservationRequestST,
} from 'codegen/nav_simulation';
import { DeveloperDrawerButton } from './DeveloperDrawerButton';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import DeveloperServices from '../../services/DeveloperServices';

interface IOperationsSimulator {
  addToOutput: (messages: string[]) => void;
  systemId: string;
}

/**
 *
 * @returns Operations Simulator Component
 */
export const OperationsSimulator = (props: IOperationsSimulator) => {
  const { systemId, addToOutput } = props;

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [flightDomainId, setFlightDomainId] = useState('');
  const { stateFacilityLevel } = useFacilityLevelStore();

  const flightDomainsList = stateFacilityLevel.flightDomains;

  const optionsValue = flightDomainsList.map((flightDomain) => ({
    value: flightDomain.flight_domain_id,
    label: flightDomain.flight_domain_name,
  }));

  return (
    <Box>
      <Tabs value={tabValue} onChange={handleChange} centered>
        <Tab label="Estimated Obstacle" />
        <Tab label="Space Reservation" />
      </Tabs>
      <CustomSelect
        id="rawTaskflightDomainIdSelector"
        variant="outlined"
        name="Flight Domain"
        value={flightDomainId}
        valueOptions={optionsValue}
        size="small"
        style={{ marginBottom: '16px' }}
        onChange={(e) => {
          setFlightDomainId(e.target.value);
        }}
        label="Select Flight Domain"
        error={false}
        errorMessage=""
        defaultValue=""
        disabled={flightDomainsList.length === 0}
        testId="flightDomainId"
      />
      {tabValue === 0 ? (
        <SimulatorEstimatedObstacles
          systemId={systemId}
          flight_domain_id={flightDomainId}
          addToOutput={addToOutput}
        />
      ) : (
        <SimulatorSpaceReservations
          systemId={systemId}
          flight_domain_id={flightDomainId}
          addToOutput={addToOutput}
        />
      )}
    </Box>
  );
};

interface IOperationsSimulator {
  addToOutput: (messages: string[]) => void;
  systemId: string;
}

interface ISimulatorChildrenProps {
  systemId: string;
  flight_domain_id: string;
  addToOutput: (messages: string[]) => void;
}

const SimulatorEstimatedObstacles = ({
  systemId,
  flight_domain_id,
  addToOutput,
}: ISimulatorChildrenProps) => {
  const [droneId, setDroneId] = useState(0);
  const [valueX, setValueX] = useState<number[]>([20, 37]);
  const [valueY, setValueY] = useState<number[]>([20, 37]);

  return (
    <>
      <DeveloperDrawerButton
        testId="c-dev-tools-task-executor"
        disabled={!flight_domain_id}
        buttonLabel="Create estimated obstacle"
        clickHandler={(e) => {
          const geometry: IBox3DST = {
            min: { x: valueX[0], y: valueY[0], z: 1 },
            max: { x: valueX[1], y: valueY[1], z: 1 },
          };

          const requestBody: ICreateEstimatedObstacleRequestST = {
            discovered_by_drone: `Drone ${droneId}`,
            geometry,
            type: ICreateEstimatedObstacleRequestSTTypeEnum.EstimatedFromCollisionAvoidanceSensors,
          };

          singleRequestHandler({
            request: DeveloperServices.postCreateEstimatedObstacle,
            requestParams: [systemId, flight_domain_id, requestBody],
            callbackSuccess: (reply: { data: any }) => {
              addToOutput(['::: Estimated Obstacle created with success']);
            },
            messageSuccess: 'Operations simulator invoked successfully',
            callbackError: (e) => {
              addToOutput(['Error creating estimated obstacle']);
            },
          });
        }}
      />
      <Box width="100%" display="flex" flexDirection="column" my={2}>
        <TextField
          type="number"
          size="small"
          disabled={!flight_domain_id}
          InputProps={{
            inputProps: {
              min: 0,
              max: 9,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Found by Drones Id"
          value={droneId}
          onChange={(e) => setDroneId(Number(e.target.value))}
        />
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          Min X
          <RangeSlider value={valueX} setValue={setValueX} />
          Max X
        </Stack>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          Min Y
          <RangeSlider value={valueY} setValue={setValueY} />
          Max Y
        </Stack>
      </Box>
      <DeveloperDrawerButton
        testId="c-dev-tools-task-executor"
        buttonLabel="Delete all estimated obstacles"
        disabled={!flight_domain_id}
        clickHandler={(e) => {
          singleRequestHandler({
            request: DeveloperServices.deleteEstimatedObstacles,
            requestParams: [systemId, flight_domain_id],
            callbackSuccess: (reply: { data: any }) => {
              addToOutput(['::: All estimated obstacles deleted']);
            },
            messageSuccess: 'Operations simulator invoked successfully',
            callbackError: (e) => {
              addToOutput(['Error deleting estimated obstacles']);
            },
          });
        }}
      />
    </>
  );
};

const SimulatorSpaceReservations = ({
  systemId,
  flight_domain_id,
  addToOutput,
}: ISimulatorChildrenProps) => {
  const [droneId, setDroneId] = useState(0);
  const [valueX, setValueX] = useState<number[]>([20, 37]);
  const [valueY, setValueY] = useState<number[]>([20, 37]);

  return (
    <>
      <DeveloperDrawerButton
        buttonLabel="Create space reservation"
        disabled={!flight_domain_id}
        clickHandler={(e) => {
          const geometry: IBox3DST = {
            min: { x: valueX[0], y: valueY[0], z: 1 },
            max: { x: valueX[1], y: valueY[1], z: 1 },
          };

          const requestBody: ICreateSpaceReservationRequestST = {
            drone_id: `Drone ${droneId}`,
            geometry,
          };

          singleRequestHandler({
            request: DeveloperServices.postCreateSpaceReservation,
            requestParams: [systemId, flight_domain_id, requestBody],
            callbackSuccess: (reply: { data: any }) => {
              addToOutput(['::: Space reservation created with success']);
            },
            messageSuccess: 'Operations simulator invoked successfully',
            callbackError: (e) => {
              addToOutput(['Error creating space reservation']);
            },
          });
        }}
      />
      <Box width="100%" display="flex" flexDirection="column" my={2}>
        <TextField
          type="number"
          size="small"
          disabled={!flight_domain_id}
          InputProps={{
            inputProps: {
              min: 0,
              max: 9,
            },
          }}
          sx={{ marginBottom: 2 }}
          label="Drones Id"
          value={droneId}
          onChange={(e) => setDroneId(Number(e.target.value))}
        />
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          Min X
          <RangeSlider value={valueX} setValue={setValueX} />
          Max X
        </Stack>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          Min Y
          <RangeSlider value={valueY} setValue={setValueY} />
          Max Y
        </Stack>
      </Box>
      <DeveloperDrawerButton
        buttonLabel="Delete all space reservations"
        disabled={!flight_domain_id}
        clickHandler={(e) => {
          singleRequestHandler({
            request: DeveloperServices.deleteSpaceReservations,
            requestParams: [systemId, flight_domain_id],
            callbackSuccess: (reply: { data: any }) => {
              addToOutput(['::: All Space reservations deleted']);
            },
            messageSuccess: 'Operations simulator invoked successfully',
            callbackError: (e) => {
              addToOutput(['Error deleting space reservations']);
            },
          });
        }}
      />
    </>
  );
};

const RangeSlider = ({ value, setValue }: any) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  return (
    <Box sx={{ width: 600 }}>
      <Slider value={value} onChange={handleChange} valueLabelDisplay="auto" min={-50} />
    </Box>
  );
};
