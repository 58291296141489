import { Box } from '@mui/material';
import { IRulesST } from 'codegen/facility_settings';
import { RulePhrase } from './RulePhrase';

export const RulesText = ({ rules }: { rules: IRulesST[] }) => (
  <Box component="div">
    {rules.map((r, i, allRules) => {
      const condition = r.conditions![0];
      const key = `${condition.field}-${condition.operator?.type}-${condition.operator?.value}`;
      return (
        <RulePhrase
          key={key}
          rule={r}
          isNotFirstRule={!!i}
          isLastRule={i === allRules.length - 1}
        />
      );
    })}
  </Box>
);
