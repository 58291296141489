import { IClientModalsState } from './IClientModalsStore';

/**
 * Initial state of the client  modal store
 */
export const initialClientModalsState: IClientModalsState = {
  selectFacilityModalOpened: false,
  signinModalOpened: false,
  changePasswordModalOpened: false,
  confirmModalOpened: false,
  modalConfirmData: {
    title: '',
    message: '',
    onConfirm: () => {},
  },
};
