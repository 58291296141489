// libraries
import { lowerCase } from 'lodash';
import { useState } from 'react';

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// components
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import ModalBase from 'components/ModalsAndPopups/ModalBase';
import Spinner from 'components/common/Spinner';
import { Box } from 'components/common/Box';
import { ILocationDataST } from 'codegen/warehouse_status';
import { useRequestController } from '../../../hooks';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import IssuesStore from '../../../store/IssuesStore';

interface ISnoozeMultipleIssuesModalProps {
  locations: ILocationDataST[];
  action: ISSUE_ACTIONS;
  onClose: () => void;
  refreshData: () => void;
}

const SnoozeMultipleIssuesModal = (props: ISnoozeMultipleIssuesModalProps) => {
  const { locations, action, onClose, refreshData } = props;

  const { requestController } = useRequestController('SnoozeMultipleIssuesModal');

  const [loading, setLoading] = useState(false);
  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const toggleSnoozeState = (
    systemId: string | null | undefined,
    locations: ILocationDataST[],
    action: ISSUE_ACTIONS,
  ) => {
    setLoading(true);

    // filter out locations which have no issues or have solved issue
    const filteredLocations = locations.filter(
      (location) => location.issues.length > 0 && location.issues[0].state !== 'SOLVED',
    );

    if (filteredLocations.length === 0) onClose();

    filteredLocations.forEach((location, i) => {
      requestController.doRequest({
        request: IssuesStore.toggleSnoozeIssue,
        requestParams: [systemId, location.issues[0].issue_id, action],
        callbackSuccess: () => {},
        messageSuccess: `Issue ${lowerCase(action)}d!`,
        messageErrorFallback: `Issue could not be ${lowerCase(action)}d!`,
        callbackFinally: () => {
          if (i === filteredLocations.length - 1) {
            refreshData();
            setLoading(false);
            onClose();
          }
        },
      });
    });
  };

  return (
    <ModalBase
      testId="c-snooze-issue-modal"
      opened
      title={
        <Box data-testid="c-snooze-issue-modal-title">
          <Box textAlign="left" p={2} mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
              {action === ISSUE_ACTIONS.SNOOZE
                ? 'Snooze selected Issues?'
                : 'Unsnooze selected Issues?'}
            </Typography>
          </Box>
        </Box>
      }
      handleClose={onClose}
      actionButtons={
        <>
          <Button
            onClick={() => toggleSnoozeState(systemId, locations, action)}
            variant="outlined"
            fullWidth
            color="primary"
            data-testid="c-snooze-button"
          >
            {action}
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            data-testid="c-cancel-button"
            fullWidth
            color="primary"
          >
            Cancel
          </Button>
        </>
      }
    >
      <Typography style={{ margin: '20px 0px' }} align="left">
        {`Are you sure you want to
          ${lowerCase(action)}
          this issue?`}
      </Typography>
      {loading && <Spinner />}
    </ModalBase>
  );
};

export default SnoozeMultipleIssuesModal;
