import moment from 'moment/moment';
import { OperatingHoursRange } from '../model/operatingHours.model';

export const operatingHoursRangeDefault = (): OperatingHoursRange => {
  const now = moment();
  const tomorrow = moment().add(1, 'day');
  return {
    start: {
      weekDay: now.weekday() + 1,
      timePoint: now,
    },
    end: {
      weekDay: tomorrow.weekday() + 1,
      timePoint: tomorrow,
    },
  };
};
