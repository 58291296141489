import { RecurrenceState } from './recurrence.model';

export const defaultRecurrence = (): RecurrenceState => ({
  intervalOptions: [],
  recurrenceMessage: null,
  isLoading: false,
  rText: '',
  nextOccurrence: null,
  occurrencesCount: 0,
  countCapped: false,
});
