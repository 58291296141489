import { capitalize } from '@mui/material';
import { FormInput } from 'components/FormFields/FormInput';
import { FormSwitch } from 'components/FormFields/FormSwitch';
import { ValidationErrors, advancedInputs } from './helpers';

export const StaticAssignment = ({
  errors,
  useGatewayDns,
}: {
  errors: ValidationErrors;
  useGatewayDns: boolean;
}) => (
  <>
    {advancedInputs.map((i: string) => (
      <FormInput
        key={i}
        id={i}
        name={i}
        label={capitalize(i)}
        margin="dense"
        inputProps={{
          'data-testid': `c-${i}`,
        }}
        error={!!errors[i as keyof typeof errors]}
        helperText={errors[i as keyof typeof errors]}
        fullWidth
      />
    ))}
    <FormSwitch name="useGatewayDns" label="Use Gateway as DNS server" />
    {!useGatewayDns && (
      <>
        <FormInput
          name="preferredDnsServer"
          label="Preferred DNS server"
          margin="dense"
          inputProps={{
            'data-testid': 'c-preferredDnsServer',
          }}
          error={!!errors['preferredDnsServer' as keyof typeof errors]}
          helperText={errors['preferredDnsServer' as keyof typeof errors]}
          fullWidth
        />
        <FormInput
          name="alternateDnsServer"
          label="Alternate DNS server"
          margin="dense"
          inputProps={{
            'data-testid': 'c-alternateDnsServer',
          }}
          error={!!errors['alternateDnsServer' as keyof typeof errors]}
          helperText={errors['alternateDnsServer' as keyof typeof errors]}
          fullWidth
        />
      </>
    )}
  </>
);
