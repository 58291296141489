import { initControlledZoneService } from './service';

export const deleteControlledZone = ({
  systemId,
  flightDomainId,
  controlledZoneId,
  signal,
}: {
  systemId: string;
  flightDomainId: string;
  controlledZoneId: string;
  signal: AbortSignal;
}) =>
  initControlledZoneService().controlledZoneDeleteRequest(
    systemId,
    flightDomainId,
    controlledZoneId,
    {
      signal,
    },
  );
