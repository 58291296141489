import { GridFilterItem, GridLogicOperator } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

/**
 * A function that sets a filter on multiple columns
 * @param gridApiRef reference to the grid API of the table the filter are to be set for
 * @param searchString the string to be searched
 * @param columnName the list of the columns the search will be applied to
 */
export const setFilterOnColumns = (
  gridApiRef: React.MutableRefObject<GridApiPremium>,
  searchString: string,
  columnNames: string[],
) => {
  const filterItems: GridFilterItem[] = columnNames.map((columnName) => ({
    id: columnName,
    columnField: columnName,
    operator: 'contains',
    value: searchString,
    field: columnName,
  }));

  gridApiRef.current.setFilterModel({
    items: filterItems,
    logicOperator: GridLogicOperator.Or,
  });
};
