import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import { createElement } from 'react';
import { IReportST } from 'codegen/report';
import {
  DATETIME_FORMAT_WITH_WEEKDAY,
  TABLE_DATETIME_FORMAT_SHORT_WITH_WEEKDAY,
} from 'common/datetimeFormats';
import { convertRruleToUIComponents, getRecurrenceText } from 'common/functionsReportSpec';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { IconColor } from 'ts-types/IconColor';
import { LocalStore } from 'common/functions/storageFunctions';
import { getReportPriorityIcons } from 'udb/inventory/reports/utils/getReportPriorityIcons';
import { ReportSummaryDataSectionItem } from '../ReportStore.model';

export const getReportExecutionSection = (requestParams: any, reportSummary: IReportST) => {
  const priority = getReportPriorityIcons(reportSummary.request.priority);

  const PriorityIcon = createElement(priority.icon, { color: priority.color as IconColor });

  const { isRecurring } = convertRruleToUIComponents(reportSummary.request.rrule);

  // NOTE: we are expecting request params to contain only one value and that value
  // to be the name or list of scheduled locations

  const locationLabel = requestParams.length >= 1 ? requestParams[0].label : '';
  const locationValue = requestParams.length >= 1 ? requestParams[0].value : '';

  const items: ReportSummaryDataSectionItem[] = [
    {
      id: 'scheduled-locations',
      label: locationLabel,
      value: (
        <Tooltip title={locationValue}>
          <Box
            overflow="hidden"
            component="div"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxWidth="10em"
          >
            {locationValue}
          </Box>
        </Tooltip>
      ),
    },
    {
      id: 'priority',
      label: 'Priority',
      value:
        userHasPermission(PERMISSION.PRIORITY_PICKER_INTERNAL) &&
        reportSummary.request.priority > 1 ? (
          reportSummary.request.priority
        ) : (
          <Box component="div" display="flex" alignItems="center">
            {PriorityIcon} {priority.label}
          </Box>
        ),
    },
    {
      id: 'recurrence',
      label: 'Recurrence',
      value: getRecurrenceText(
        reportSummary.request.report_spec.report_spec_name,
        isRecurring,
        reportSummary.request.recurrence_description,
      ),
    },
    {
      id: 'scheduled-for',
      label: 'Scheduled for',
      value: <ReportExecutionDateTime dateTime={reportSummary.scheduled_for_utc} />,
    },
    {
      id: 'started-at',
      label: 'Started',
      value: <ReportExecutionDateTime dateTime={reportSummary.started_at} />,
    },
    {
      id: 'finished-at',
      label: 'Finished',
      value: <ReportExecutionDateTime dateTime={reportSummary.finished_at} />,
    },
  ];

  return [
    {
      id: 'report-summary-schedule-info',
      sectionItems: items,
    },
  ];
};

const ReportExecutionDateTime = ({ dateTime }: { dateTime: string | null }) => {
  if (!dateTime) {
    return <Box component="div">-</Box>;
  }
  const timezone = LocalStore.getTimezone();
  return (
    <Tooltip title={moment(dateTime).tz(timezone).format(DATETIME_FORMAT_WITH_WEEKDAY).toString()}>
      <Box sx={{ cursor: 'default' }} component="div">
        {moment(dateTime).tz(timezone).format(TABLE_DATETIME_FORMAT_SHORT_WITH_WEEKDAY)}
      </Box>
    </Tooltip>
  );
};
