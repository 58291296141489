import { isNil } from 'lodash';
import { IFleetStatusSummaryST } from 'codegen/flight_domain';

export const numberOfDronesFlying = (
  fleet_status_summary: IFleetStatusSummaryST | null | undefined,
) =>
  isNil(fleet_status_summary?.num_drones_flying) ? -1 : fleet_status_summary?.num_drones_flying;

export const numberOfDronesOnCharger = (
  fleet_status_summary: IFleetStatusSummaryST | null | undefined,
) =>
  isNil(fleet_status_summary?.num_drones_on_charger)
    ? -1
    : fleet_status_summary?.num_drones_on_charger;

export const numberOfDronesLandedInPlace = (
  fleet_status_summary: IFleetStatusSummaryST | null | undefined,
) =>
  isNil(fleet_status_summary?.num_drones_landed_in_error)
    ? -1
    : fleet_status_summary?.num_drones_landed_in_error;
