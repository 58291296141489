import {
  ILocationHistoryResponseST,
  ISlotSettingsST,
  IWMSSlotStatusST,
  IVeritySlotStatusST,
  ISlotSettingsSTExclusionStatusEnum,
  IVeritySlotStatusStateST,
  IWMSSlotStatusSTStateEnum,
  ILocationHistoryItemST,
} from 'codegen/location_information';
import { LocationHistoryEventTypes } from '../models/LocationHistoryEventTypes.model';
import { extractSnapshotFileName } from './extractSnapshotFileName';

export type LocationHistoryItem = {
  type: LocationHistoryEventTypes;
  timestamp: string;
  updatedAt: string;
  exclusion_status: ISlotSettingsSTExclusionStatusEnum | null;
  state: IVeritySlotStatusStateST | IWMSSlotStatusSTStateEnum | null;
  barcodes: Array<string>;
  version?: number;
  snapshotId?: string;
};

export type TransformedHistoryData = {
  items: LocationHistoryItem[];
  next_timepoint: string | null;
};

export const transformLocationHistory = (
  locationHistory: ILocationHistoryResponseST,
): { items: LocationHistoryItem[]; next_timepoint: string | null } => {
  // Provide a default value of null if timepoint is undefined
  const { data, next_timepoint = null } = locationHistory;

  const items: LocationHistoryItem[] = data.map((item: ILocationHistoryItemST) => {
    let state = null;
    let exclusion_status = null;
    let timestamp: string = '';
    let updatedAt: string = '';
    let barcodes: string[] = [];
    let version;
    let snapshotId;

    switch (item.item_type) {
      case LocationHistoryEventTypes.DRONE_REPORT:
        state = (item.data as IVeritySlotStatusST).state;
        barcodes = (item.data as IVeritySlotStatusST).barcodes;
        timestamp = (item.data as IVeritySlotStatusST).collected_at;
        updatedAt = (item.data as IWMSSlotStatusST).updated_at;
        version = (item.data as IVeritySlotStatusST).version;

        break;
      case LocationHistoryEventTypes.WMS_UPDATE:
        state = (item.data as IWMSSlotStatusST).state;
        barcodes = (item.data as IWMSSlotStatusST).barcodes;
        timestamp = (item.data as IWMSSlotStatusST).changed_at;
        updatedAt = (item.data as IWMSSlotStatusST).updated_at;
        snapshotId = extractSnapshotFileName((item.data as IWMSSlotStatusST).snapshot_id);
        break;
      case LocationHistoryEventTypes.EXCLUSION_STATUS_UPDATE:
        exclusion_status = (item.data as ISlotSettingsST).exclusion_status;
        if ((item.data as ISlotSettingsST).updated_at) {
          timestamp = (item.data as ISlotSettingsST).updated_at as string;
        }
        updatedAt = (item.data as IWMSSlotStatusST).updated_at;
        break;
      default:
        console.warn(`Unhandled type: ${item.item_type}`);
    }

    return {
      type: item.item_type as LocationHistoryEventTypes,
      state,
      barcodes,
      exclusion_status,
      timestamp,
      updatedAt,
      version,
      snapshotId,
    };
  });

  return { items, next_timepoint };
};
