import { Button, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { LONG_DATETIME_FORMAT } from 'common/datetimeFormats';
import { ActionsAlert } from 'components/common/ActionsAlert';
import { Box } from 'components/common/Box';
import { BackDropLoader } from 'components/BackDropLoader';
import { useDeleteScheduleLock } from './api/useDeleteScheduleLockAPI';

interface IScheduleLockDeletionProps {
  scheduleLock: string;
}

export const ScheduleLockDeletion = ({ scheduleLock }: IScheduleLockDeletionProps) => {
  const { deleteScheduleLock } = useDeleteScheduleLock();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleDeleteScheduleLock = () =>
    deleteScheduleLock({
      setLoading: setIsLoading,
      setError,
    });

  return (
    <>
      <BackDropLoader isVisible={isLoading} />
      <div data-testid="c-schedule-lock-card-content">
        <Box mb={2}>
          <ActionsAlert
            isActive={!!error}
            message={error}
            handleClose={() => setError('')}
            status="warning"
            handleClick={handleDeleteScheduleLock}
            handleClickLabel="Retry"
          />
        </Box>

        <TextField
          label="A lock is set at:"
          disabled
          fullWidth
          value={moment(scheduleLock).format(LONG_DATETIME_FORMAT)}
        />
        <Typography mt={2} color="textSecondary">
          Drones will stop flying at the time of the scheduled lock.
        </Typography>
      </div>

      <div style={{ marginTop: '24px' }}>
        <Button
          data-testid="c-add-schedule-lock-button"
          variant="outlined"
          color="error"
          size="medium"
          fullWidth
          onClick={handleDeleteScheduleLock}
        >
          REMOVE
        </Button>
      </div>
    </>
  );
};
