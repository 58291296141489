import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { Box } from '../../../common/Box';
import { locationStyles } from '../styles';
import { useHandleShortcut } from './useHandleShortcut';

export const OtherFeedbackReason = ({
  isChecked,
  onCheckedChange,
  message,
  onMessageChange,
  isShareFeedbackAccordionOpen,
}: {
  isChecked: boolean;
  onCheckedChange: (isChecked: boolean) => void;
  message: string | undefined;
  onMessageChange: (message: string) => void;
  isShareFeedbackAccordionOpen: boolean;
}) => {
  const { classes } = locationStyles();
  const { isShortcutPressed } = useHandleShortcut([
    {
      key: '0',
      onShortcutPress: isShareFeedbackAccordionOpen ? () => onCheckedChange(!isChecked) : undefined,
    },
  ]);

  return (
    <Grid item xs={12}>
      <Box className={classes.formWrapper}>
        <FormControlLabel
          control={
            <>
              {isShortcutPressed('Alt') ? <div className={classes.shortcutHint}>0</div> : null}
              <Checkbox
                checked={isChecked}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  onCheckedChange((event.target as HTMLInputElement).checked)
                }
              />
            </>
          }
          label="Other, please specify"
          data-testid={`c-issue-feedback-checkbox-${'OTHER'}`}
        />
        {isChecked ? (
          <TextField
            id="c-location-feedback-other-message"
            autoFocus
            disabled={false}
            label="Message"
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(e) => onMessageChange(e.target.value)}
            inputProps={{
              'data-testid': 'c-issue-feedback-message-input',
            }}
          />
        ) : null}
      </Box>
    </Grid>
  );
};
