import MicrosoftIcon from '@mui/icons-material/Microsoft';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import EnhancedTable from 'components/EnhancedTable';
import { IUserResponseSTStatusEnum } from 'codegen/user_admin';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { QueryNames } from 'ts-types/QueryNames';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../../store/UserLevelStore/userLevelStore';
import { transformUsersFromDTO } from './model/transformUsersFromDTO';
import { getUsersWithGroups } from './api/getUsers.api';
import { getFacilities } from './api/getFacilities.api';
import { transformFacilitiesFromDTO } from './model/transformFacilitiesFromDTO';
import { usersColumnStructure } from './defaults/usersColumnStructure.defaults';
import {
  User as UserType,
  Users as UsersType,
  UserTableRow,
  UserWithFacilities,
} from './model/user.model';
import { Facilities, Facility } from './model/facility.model';
import AddEditUserModal from '../../../components/ModalsAndPopups/AddEditUserModal/AddEditUserModal';
import { DeleteUserModal } from './features/DeleteUserModal/DeleteUserModal';
import { emailFromUsers } from './utils/emailsFromUsers.util';

const Users = () => {
  const { systemId } = useParams();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateUserLevel } = useUserLevelStore();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editUserId, setEditUserId] = useState<undefined | string>(undefined);
  const [deleteUser, setDeleteUser] = useState<undefined | UserWithFacilities>(undefined);

  const {
    isLoading: isLoadingFacilities,
    isRefetching: isRefetchingFacilities,
    data: facilities,
    refetch: refetchFacilities,
  } = useQuery({
    queryKey: [QueryNames.FETCH_FACILITIES, systemId],
    queryFn: getFacilities,
    select: useCallback(transformFacilitiesFromDTO, []),
  });

  const {
    isLoading: isLoadingUsers,
    isRefetching: isRefetchingUsers,
    data: users,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: [QueryNames.FETCH_USERS, systemId],
    queryFn: getUsersWithGroups(systemId!),
    select: useCallback(transformUsersFromDTO, []),
  });

  const isLoading = () =>
    isLoadingFacilities || isRefetchingFacilities || isLoadingUsers || isRefetchingUsers;

  const refetchData = async () => {
    await refetchUsers();
    await refetchFacilities();
  };

  const usersWithFacilitiesNames: (
    users: UsersType,
    facilities: Facilities,
  ) => UserWithFacilities[] = (users, facilities) =>
    users.map((u: UserType) => ({
      ...u,
      email: u.email ? u.email : 'NOT PROVIDED',
      facilities: u.systemIds.map((systemId: number) => {
        const facility = facilities.find((f: Facility) => f.id === systemId);
        return facility ? facility.name : `${systemId}`;
      }),
    }));

  const usersWithActions: (users: Array<UserWithFacilities>) => Array<UserTableRow> = (users) =>
    users?.map((user: UserWithFacilities) => ({
      ...user,
      statusIcon:
        user.status === IUserResponseSTStatusEnum.ExternalProvider ? <MicrosoftIcon /> : '',
      actions: {
        actions: [
          {
            label: 'Edit User',
            disabled: false,
            onClick: () => handleEditDeleteUser(user),
          },
          {
            label: 'Delete User',
            disabled: stateUserLevel.usernameHashed === user.usernameHashed,
            onClick: () => handleDeleteUser(user),
          },
        ],
      },
    }));

  const handleEditDeleteUser = (userRow: UserWithFacilities) => {
    setEditUserId(userRow.id);
    setIsEditModalOpen(true);
  };
  const handleDeleteUser = (user: UserWithFacilities) => {
    setDeleteUser(user);
    setIsDeleteModalOpen(true);
  };

  const onCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditUserId(undefined);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteUser(undefined);
  };

  return (
    <>
      <PageHeaderSection title="Administration" showMenuIcon={false} showLoadedSince={false} />
      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <Grid direction="column" container className="c-page-content">
          <EnhancedTable
            tableFor="Users"
            tableTitle="Users"
            tableSubtitle={`Here is a list of all the users associated with ${
              stateFacilityLevel.facilityData?.client ?? ''
            }`}
            headCells={usersColumnStructure}
            rows={
              users && facilities
                ? usersWithActions(usersWithFacilitiesNames(users, facilities))
                : []
            }
            isLoading={isLoading()}
            refreshData={{ refreshData: () => refetchData }}
            currentActiveSpinner={{ Users: isLoading() }}
            headerButton={
              <Button
                data-testid="AddUserButton"
                variant="contained"
                color="primary"
                onClick={() => setIsEditModalOpen(true)}
              >
                Add user
              </Button>
            }
          />
        </Grid>
      </Container>
      {isEditModalOpen && (
        <AddEditUserModal
          allUserEmails={users ? emailFromUsers(users) : []}
          userId={editUserId}
          onClose={onCloseEditModal}
          onAdded={refetchData}
          onEdited={refetchData}
        />
      )}
      {isDeleteModalOpen && deleteUser && (
        <DeleteUserModal user={deleteUser} onClose={onCloseDeleteModal} onDeleted={refetchData} />
      )}
    </>
  );
};

export default Users;
