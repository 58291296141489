import { useTheme } from '@mui/material';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { calendarTemplate, calendarTheme, createCalendars } from 'common/Calendar/CalendarSettings';
import { Calendar } from 'components/common/Calendar';
import { LocalStore } from 'common/functions/storageFunctions';
import BaseCard from 'components/BaseCard/BaseCard';
import { OperatingHoursEventST } from '../droneFlightHours/model/operatingHours.model';
import { createOperatingHoursByWeekDayFromSchedule } from './utils/createOperatingHoursByWeekDayFromSchedule';

const useStyles = makeStyles()(() => ({
  cardContent: {
    '& .toastui-calendar-timegrid': {
      height: '99%', // 99% is important to prevent unnecessary scrollbars
      minHeight: '500px',
    },
    '& .toastui-calendar-timegrid-now-indicator': {
      display: 'none',
    },
    '& .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time': {
      visibility: 'visible',
    },
    '& .toastui-calendar-day-name__date': {
      display: 'none',
    },
    '& .toastui-calendar-day-name__name': {
      fontSize: 14,
    },
  },
}));

interface IFlightHoursCalendarProps {
  schedule: OperatingHoursEventST[];
}
/**
 * Flight hours calendar
 * @param props IFlightHoursCalendarProps
 * @returns component
 */
export const FlightHoursCalendar = ({ schedule }: IFlightHoursCalendarProps) => {
  const { classes } = useStyles();

  const theme = useTheme();

  const timezone = LocalStore.getTimezone();

  const events = createOperatingHoursByWeekDayFromSchedule(schedule).map((i, index) => ({
    id: index,
    calendarId: 'fhc',
    title: 'Drones flight hours',
    category: 'time',
    start: moment().tz(timezone).set({ day: i.weekDay, hour: i.fromHours, minutes: i.fromMinutes }),
    end: moment().tz(timezone).set({ day: i.weekDay, hour: i.toHours, minutes: i.toMinutes }),
  }));

  return (
    <BaseCard
      cardFor="drone-flight-hours-calendar"
      title="Drones flight hours calendar"
      subtitle="Weekly schedule of drone flying hours"
    >
      <div className={classes.cardContent}>
        <Calendar
          view="week"
          height="750px"
          week={{ taskView: false, eventView: ['time'], startDayOfWeek: 1 }}
          timezone={{ zones: [{ timezoneName: timezone }] }}
          calendars={createCalendars(theme)}
          events={events}
          template={calendarTemplate}
          theme={calendarTheme}
          usageStatistics={false}
          isReadOnly
        />
      </div>
    </BaseCard>
  );
};
