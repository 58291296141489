// EnhancedBox component
//  props:
//
//  - icon          = [element]
//  - description   = [str]
//  - actionButtons = [arr] Array of objects that will represent buttons

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// components
import { Box } from 'components/common/Box';

const EnhancedBox = (props: {
  icon: any;
  description: string;
  actionButtons: any;
  classes: any;
  children: any;
}) => {
  const { icon, description, actionButtons, classes, children } = props;

  return (
    <>
      {icon}
      <Typography className={classes.description} variant="subtitle1" color="textSecondary">
        {description}
      </Typography>
      <Box>{children}</Box>
      <Box width="100%" className={classes.actionButtons} display="flex">
        {actionButtons &&
          actionButtons.map((button: any) => (
            <Button
              key={`${button.label}-${button.testid}`}
              data-testid={button.testid}
              className={button.class && button.class}
              variant={button.variant}
              fullWidth
              disabled={button.disabled}
              color="primary"
              onClick={button.action}
            >
              {button.label}
            </Button>
          ))}
      </Box>
    </>
  );
};

export default EnhancedBox;
