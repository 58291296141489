import { Tooltip } from '@mui/material';

export const TooltipedCell = (params: { value: string }) => (
  <Tooltip
    className="c-data-grid-cell"
    title={params.value !== '-' ? params.value : 'No data'}
    sx={{ cursor: 'default' }}
  >
    <span style={{ cursor: 'default' }}>{params.value}</span>
  </Tooltip>
);
