import { useEffect, useRef } from 'react';
import useWebSocket from 'react-use-websocket';

// functions, stores
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { TokenManager } from 'common/tokenManager';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import {
  InventoryActionNames,
  FacilitySettingsActionNames,
} from 'store/FacilityLevelStore/facilityLevelActions';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { GroundControlActionNames } from 'store/GroundControl/groundControlLevelActions';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { UserLevelActionNames } from 'store/UserLevelStore/userLevelActions';

const tokenManager = TokenManager.getInstance();

const webSocketUrl = new URL(import.meta.env.VITE_APP_WS_API_ENDPOINT as string);

const logPrefix = getLogPrefixForType('COMPONENT', 'WebSocketComponent');

/**
 * Old WebComponent implementation, please use webSocket.ts
 * @deprecated
 */
const WebSocketComponent = () => {
  const { dispatchUserLevelStore, stateUserLevel } = useUserLevelStore();
  const { dispatchFacilityLevel, updateFlightDomainList } = useFacilityLevelStore();
  const { dispatchGroundControlLevel, stateGroundControl } = useGroundControlStore();
  const didUnmount = useRef(false);

  const { sendJsonMessage } = useWebSocket(webSocketUrl.toString(), {
    share: true,
    onOpen: () => {
      const accessToken = tokenManager.getAccessToken();

      if (accessToken) {
        console.debug(logPrefix, 'WebSocket connection opened, sending access token');
        sendJsonMessage({
          topic: 'authorization',
          data: { access_token: accessToken },
        });
      } else {
        console.error(logPrefix, 'Can not open connection - No access token found');
      }
    },
    onMessage: (event) => {
      let message = null;
      try {
        message = JSON.parse(event.data);
      } catch {
        console.error('Error loading message data');
        return;
      }

      switch (message.topic) {
        case 'authorization':
          console.debug(logPrefix, 'Received authorization message', message.data.status);
          if (message.data.status === 'AUTHORIZED' && !stateUserLevel.isWebSocketAuthorized) {
            dispatchUserLevelStore({
              type: UserLevelActionNames.SET_WEB_SOCKET_AUTHORIZED,
              payload: true,
            });
          }
          break;

        case 'facility/map/update':
          dispatchFacilityLevel({
            type: FacilitySettingsActionNames.WS_FACILITY_MAP_UPDATE,
            payload: { data: { ...message.data, systemId: message.system_id } },
          });
          break;

        case 'warehouse/export':
          dispatchFacilityLevel({
            type: InventoryActionNames.WS_WAREHOUSE_EXPORT,
            payload: { data: { ...message.data, systemId: message.system_id } },
          });
          break;

        case 'report/export':
          dispatchFacilityLevel({
            type: InventoryActionNames.WS_REPORT_EXPORT,
            payload: { data: { ...message.data, systemId: message.system_id } },
          });
          break;

        case 'report/status/update':
          dispatchFacilityLevel({
            type: InventoryActionNames.WS_REPORT_STATUS_UPDATE,
            payload: { data: { ...message.data, systemId: message.system_id } },
          });
          break;

        case 'image/status':
          dispatchFacilityLevel({
            type: InventoryActionNames.WS_IMAGE_STATUS,
            payload: { data: message.data },
          });
          break;

        // Flight domain
        case 'ground-control/fleet/status-update':
          dispatchGroundControlLevel({
            type: GroundControlActionNames.UPDATE_FLEET_OVERVIEW,
            payload: message.data,
          });
          break;

        case 'ground-control/flight-domain/status-update':
          // set flight domain data
          if (stateGroundControl.flightDomain.flight_domain_id === message.data.flight_domain_id) {
            dispatchGroundControlLevel({
              type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
              payload: message.data,
            });

            // Since we handle flight domain status summary card notifications
            // by manually locking and unlocking Flight domain, this will take care
            // off displaying the notification info when the FD is locked by the system.
            if (
              message.data.flight_domain_status?.locked &&
              message.data.flight_domain_status?.triggered_by === 'System'
            ) {
              dispatchGroundControlLevel({
                type: GroundControlActionNames.SET_ALERT_INFO,
                payload: {
                  key: 'statusCard',
                  variant: stateGroundControl.activeAlertVariant,
                  message: 'System has been locked.',
                },
              });
            }
          }
          updateFlightDomainList(message.data);
          break;

        case 'ground-control/fleet/summary-status-update':
          // set flight domain data
          if (stateGroundControl.flightDomain.flight_domain_id === message.data.flight_domain_id) {
            dispatchGroundControlLevel({
              type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
              payload: message.data,
            });
          }
          updateFlightDomainList(message.data);
          break;

        default:
          break;
      }
    },
    onClose: () => {
      console.debug(logPrefix, 'WebSocket connection closed');
      dispatchUserLevelStore({
        type: UserLevelActionNames.SET_WEB_SOCKET_AUTHORIZED,
        payload: false,
      });
    },
    onError: () => {
      console.debug(logPrefix, 'System has been disconnected.');
    },
    shouldReconnect: () => true,
    onReconnectStop: () => {
      console.debug(
        logPrefix,
        'WebSocket stopped reconnecting. Attempt to reconnect to websocket failed.',
      );
    },
    reconnectAttempts: 24,
    reconnectInterval: 5000,
  });

  // rule skipped to facilitate the reading of the useEffect cleanup function (return)
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    console.debug(logPrefix, 'WebSocket component mounted');
    return () => {
      console.debug(logPrefix, 'WebSocket component unmounted');
      didUnmount.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default WebSocketComponent;
