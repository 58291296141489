import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { FACILITY_SETTINGS_PAGES_URLS } from 'common/pages';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { downloadBase64FromJSON } from 'common/functions/fileFunctions';
import { useSettingsStore } from 'store/Settings/settingsStore';

export const SettingsPageHeader = ({
  systemId,
  isFacilitySettingsLoaded,
  onSaveButtonClick,
}: {
  systemId: string;
  isFacilitySettingsLoaded: boolean;
  onSaveButtonClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const location = useLocation();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { FACILITY_SETTINGS_BASE64_FILE_EXTENSION } = useSettingsStore();

  const downloadSettingsFile = useCallback(() => {
    const fileName = !isEmpty(stateFacilityLevel.facilityData.name)
      ? stateFacilityLevel.facilityData.name
      : stateFacilityLevel.facilityData.store_id;
    const facilitySettingsTemp = stateFacilityLevel.facilitySettings;
    downloadBase64FromJSON(facilitySettingsTemp, fileName, FACILITY_SETTINGS_BASE64_FILE_EXTENSION);
  }, [
    FACILITY_SETTINGS_BASE64_FILE_EXTENSION,
    stateFacilityLevel.facilityData.name,
    stateFacilityLevel.facilityData.store_id,
    stateFacilityLevel.facilitySettings,
  ]);

  return (
    <PageHeaderSection
      title="Administration"
      showMenuIcon={false}
      showLoadedSince={false}
      showDownloadBtn={
        isFacilitySettingsLoaded &&
        location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS}`
      }
      onClickDownload={downloadSettingsFile}
      customBtn={
        isFacilitySettingsLoaded &&
        location.pathname === `/${systemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS}` ? (
          <Button
            data-testid="c-save-settings-changes-btn"
            endIcon={<SaveIcon />}
            variant="contained"
            color="primary"
            onClick={onSaveButtonClick}
          >
            Save changes
          </Button>
        ) : null
      }
    />
  );
};
