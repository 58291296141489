// libraries
import { NavigateFunction } from 'react-router-dom';

interface IChangeTab {
  tabValue: number;
  state: {
    activeTab?: string;
  };
  setActiveTab?: (tab: number) => void;
  navigate: NavigateFunction;
}

export const changeTab = ({ tabValue, state, setActiveTab, navigate }: IChangeTab) => {
  setActiveTab && setActiveTab(tabValue);
  /**
   * Since first parameter is required to be an url
   * by setting the current pathname as url will not re-render the page
   * but will update the state
   *
   * NOTE: Router v6 doesn't have this documented,
   * neither the case when we want just to update router state
   */
  navigate(window.location.pathname, {
    replace: true,
    state: {
      ...state,
      activeTab: tabValue,
    },
  });
};
