import { MapLegendDroneGroup } from '../../models/DroneZoneLegend.model';
import { DroneZoneMapLegendItem } from '../drone-zone-map-legend-item/DroneZoneMapLegendItem';
import { useStyles } from './DroneZoneMapLegendZoneGroup.styles';

type DroneZoneMapLegendZoneGroupProps = Pick<MapLegendDroneGroup, 'label'>;

export const DroneZoneMapLegendZoneGroup = ({ label }: DroneZoneMapLegendZoneGroupProps) => {
  const { classes } = useStyles();

  return (
    <DroneZoneMapLegendItem
      icon={
        <div className={classes.legendIcon}>
          <span className={classes.legendIconContent}>2</span>
        </div>
      }
      label={label}
    />
  );
};
