import { PERMISSION } from './permissions.model';
import { ROLE } from './role.model';
import {
  groundControlOfficerPermissions,
  reviewerPermissions,
  verityUserPermissions,
  inventoryManagerPermissions,
  safetyControlsPermissions,
  analyticsPermissions,
  superAdminPermissions,
} from './permissions';

export const roles: {
  [key in ROLE]: PERMISSION[];
} = {
  GROUND_CONTROL_OFFICER: groundControlOfficerPermissions,
  REVIEWER: reviewerPermissions,
  VERITY_USER: verityUserPermissions,
  INVENTORY_MANAGER: inventoryManagerPermissions,
  SAFETY_CONTROLS: safetyControlsPermissions,
  ANALYTICS: analyticsPermissions,
  SUPER_ADMIN: superAdminPermissions,
  UNKNOWN: [],
};
