import { useNavigate, useParams } from 'react-router-dom';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import BaseCard, { IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import BaseCardReportItem from 'components/BaseCard/BaseCardReportItem';
import { ReportInCard } from 'udb/inventory/reports/reducer/report-store/reportInCard.model';

interface IOngoingReportsCardProps {
  activeTab: number;
  reports: ReportInCard[];
  reportsNoInfo: { numberOfReports: number; mostRecentStart: string };
  isLoading: boolean;
}

export const OngoingReportsCard = (props: IOngoingReportsCardProps) => {
  const { activeTab, reports, reportsNoInfo, isLoading } = props;

  const navigate = useNavigate();
  const { systemId = '' } = useParams();

  const actionButtons: IBaseCardActionButton[] = [
    {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      label: 'See all ongoing reports',
      clickHandler: () => {
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`, {
          state: { activeTab },
        });
      },
    },
  ];

  const ongoingReportsData = {
    id: 'ongoing-reports',
    title: 'Ongoing reports',
    subtitle: reportsNoInfo.mostRecentStart
      ? `Most recent start: ${reportsNoInfo.mostRecentStart}`
      : '',
    counter: reportsNoInfo.numberOfReports,
    actionButtons,
    sections: reports,
  };

  return (
    <BaseCard
      cardFor="ongoing reports"
      dataTestId="c-ongoing-reports-card"
      showHeader={true}
      showContent={true}
      showActionButtons={true}
      showHeaderDivider={true}
      isLoading={isLoading}
      title={ongoingReportsData.title}
      subtitle={ongoingReportsData.subtitle}
      counter={ongoingReportsData.counter}
      actionButtons={ongoingReportsData.actionButtons}
      sx={{ order: 3 }}
    >
      {ongoingReportsData.sections &&
        ongoingReportsData.sections.map((section) => (
          <BaseCardReportItem
            key={section.reportId}
            reportId={section.reportId}
            reportType={section.reportType}
            date={section.date}
            recurrence={section.recurrence}
            recurrenceDescription={section.recurrenceDescription}
            reportName={section.reportName}
            completion={section.completion}
            reportStatus={section.reportStatus}
            totalLocations={section.totalLocations}
            showBottomDivider={true}
            actionButtons={section.actionButtons}
          />
        ))}
    </BaseCard>
  );
};
