import * as tables from 'common/Tables';
import { useSettingsStore } from '../../../../store/Settings/settingsStore';
import { GetTabDataParams } from './getTabDataParams.model';
import { ActiveTabData } from './activeTabData.model';

const defaultTab: ActiveTabData = {
  tableFor: '',
  headCells: [],
  rows: [],
};

export const useGetActiveTabData = ({
  wmsSnapshotFiles,
  stockCheckFiles,
  movedLocationsFiles,
  activeTab,
}: GetTabDataParams): ActiveTabData => {
  const { WAREHOUSE_UPLOADS_TABLE_TABS, WAREHOUSE_DATA_UPLOADS_COLUMNS } = useSettingsStore();
  const { wmsSnapshotFilesTab, stockCheckFilesTab, movedLocationsFilesTab } =
    tables.warehouseUploadsMappings(WAREHOUSE_UPLOADS_TABLE_TABS);

  const tabData = {
    [wmsSnapshotFilesTab]: {
      tableFor: 'uploaded WMS snapshot files',
      headCells: WAREHOUSE_DATA_UPLOADS_COLUMNS,
      rows: wmsSnapshotFiles,
    },
    [stockCheckFilesTab]: {
      tableFor: 'uploaded Stock check files',
      headCells: WAREHOUSE_DATA_UPLOADS_COLUMNS,
      rows: stockCheckFiles,
    },
    [movedLocationsFilesTab]: {
      tableFor: 'uploaded Moved locations files',
      headCells: WAREHOUSE_DATA_UPLOADS_COLUMNS,
      rows: movedLocationsFiles,
    },
  };

  return tabData[activeTab] || defaultTab;
};
