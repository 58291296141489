import { DroneZoneCommandType, DroneZoneSubmitCommand } from '../droneZoneCommand.type';
import { ZoneStatusActions } from '../../../reducer/droneZonePage.actions';
import { ControlledZone } from '../../../../../../shared/map/model/controlled-zone.model';

export const createDroneZoneSubmitCommand: (zone: ControlledZone) => DroneZoneSubmitCommand = (
  zone,
) => ({
  type: DroneZoneCommandType.Submit,
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => {
    dispatchZoneStatus({ type: 'END_CREATING_DRONE_ZONE', payload: null });
  },
});
