import { BoxGeometry, EdgesGeometry } from 'three';
import { bottomLeftDimensionsToSizeAndPos } from 'shared/map-container/utils/creation.util';
import { meshRenderOrder } from 'shared/map/defaults/mesh-render-order';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { FlightArea } from '../../../../model/flightAreas.model';

export const FlightAreaMesh = ({ flightArea }: { flightArea: FlightArea }) => {
  const { w, l, h, positionVector } = bottomLeftDimensionsToSizeAndPos(flightArea.sizeAndPosition);

  const box = new BoxGeometry(w, l, h);
  const geometry = new EdgesGeometry(box);

  return (
    <>
      <mesh position={positionVector} geometry={box} renderOrder={meshRenderOrder.open_fly_zones}>
        <meshBasicMaterial
          color={mapStyle.flightAreas.active.backgroundColor.primary}
          transparent
        />
      </mesh>

      <lineSegments
        geometry={geometry}
        renderOrder={meshRenderOrder.zones}
        position={positionVector}
      >
        <meshBasicMaterial
          color={mapStyle.flightAreas.active.backgroundColor.primary}
          transparent
        />
      </lineSegments>
    </>
  );
};
