import { CircularProgress, Switch, SwitchProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ palette, shadows }: Theme) => ({
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: palette.background.default,
    boxShadow: shadows[1],
  },
  active: {
    backgroundColor: palette.primary.main,
  },
}));

interface ILoadingSwitchProps
  extends Omit<SwitchProps, 'classes' | 'color' | 'checked' | 'disabled'> {
  'aria-label': string;
  isDisabled: boolean;
  isLoading: boolean;
  isChecked: boolean;
}

export const LoadingSwitch = ({
  'aria-label': ariaLabel,
  isDisabled,
  isLoading,
  isChecked,
  ...rest
}: ILoadingSwitchProps) => {
  const { classes } = useStyles();

  const isActive = isChecked && !isLoading;

  const Icon = (
    <div className={`${classes.circle} ${isActive ? classes.active : ''}`}>
      {isLoading && <CircularProgress size={14} color="secondary" thickness={6} />}
    </div>
  );

  return (
    <Switch
      checkedIcon={Icon}
      icon={Icon}
      checked={isChecked}
      disabled={isDisabled}
      value="loading-switch"
      aria-busy={isLoading}
      inputProps={{
        'aria-label': isLoading ? 'Updating...' : ariaLabel,
      }}
      {...rest}
    />
  );
};
