import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { isProductionEnvironment } from './functions/environments';

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://a7ff9e286ea54c9784838477c4825e81@o4504365836271616.ingest.sentry.io/4504367082635264',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    release: 'udb@2.9',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,

    enabled: isProductionEnvironment(),
  });
};
