/* tslint:disable */
/* eslint-disable */
/**
 * Version
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IAppVersionGetResponseST
 */
export interface IAppVersionGetResponseST {
  /**
   *
   * @type {string}
   * @memberof IAppVersionGetResponseST
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof IAppVersionGetResponseST
   */
  last_updated: string;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IServiceVersionGetResponseST
 */
export interface IServiceVersionGetResponseST {
  /**
   *
   * @type {Array<ISwVersionWithNameST>}
   * @memberof IServiceVersionGetResponseST
   */
  apps?: Array<ISwVersionWithNameST>;
}
/**
 *
 * @export
 * @interface ISwVersionWithNameST
 */
export interface ISwVersionWithNameST {
  /**
   *
   * @type {string}
   * @memberof ISwVersionWithNameST
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ISwVersionWithNameST
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof ISwVersionWithNameST
   */
  last_updated: string;
}

/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns the app version for all the services.
     * @summary Get app version for all the services.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicesVersion: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getServicesVersion', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/services-version`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns backend app version.
     * @summary Get backend app version.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/app/version`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns the app version for all the services.
     * @summary Get app version for all the services.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServicesVersion(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IServiceVersionGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServicesVersion(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns backend app version.
     * @summary Get backend app version.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVersion(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAppVersionGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VersionApiFp(configuration);
  return {
    /**
     * Returns the app version for all the services.
     * @summary Get app version for all the services.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicesVersion(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IServiceVersionGetResponseST> {
      return localVarFp
        .getServicesVersion(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns backend app version.
     * @summary Get backend app version.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion(options?: any): AxiosPromise<IAppVersionGetResponseST> {
      return localVarFp.getVersion(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
  /**
   * Returns the app version for all the services.
   * @summary Get app version for all the services.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionApi
   */
  public getServicesVersion(systemIdStr: string, options?: AxiosRequestConfig) {
    return VersionApiFp(this.configuration)
      .getServicesVersion(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns backend app version.
   * @summary Get backend app version.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionApi
   */
  public getVersion(options?: AxiosRequestConfig) {
    return VersionApiFp(this.configuration)
      .getVersion(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
