import { GROUND_CONTROL_PAGES_URLS, INVENTORY_PAGES_URLS, WARNING_PAGES_URLS } from 'common/pages';
import { singleRequestHandler } from 'common/requestHelpers';
import {
  userHasPermission,
  userHasSomePermissions,
} from '../../features/permissions/userHasPermission';
import {
  PERMISSION,
  overviewPagePermissions,
  reportsPermissions,
  safetyPermissions,
} from '../../features/permissions/permissions.model';
import warehouseServices from '../../services/WarehouseServices';

export const getHomeRouteBasedOnPermissions = async (systemId: string) => {
  const canAccessOverviewPage = userHasSomePermissions(overviewPagePermissions);
  const canAccessAnalyticsPage = userHasPermission(PERMISSION.VIEW_ANALYTICS);
  const canAccessReportsPage = userHasSomePermissions(reportsPermissions);
  const canAccessFlightDomain = userHasSomePermissions(safetyPermissions);

  if (canAccessOverviewPage) {
    return `/${systemId}${INVENTORY_PAGES_URLS.OVERVIEW}`;
  }
  if (canAccessAnalyticsPage) {
    return `/${systemId}${INVENTORY_PAGES_URLS.ANALYTICS}`;
  }
  if (canAccessReportsPage) {
    return `/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`;
  }
  if (canAccessFlightDomain) {
    const { data } = await singleRequestHandler({
      request: warehouseServices.getFlightDomainsInfo,
      requestParams: [systemId],
      messageErrorFallback:
        'The flight domains could not be fetched when populating the user profile.',
    });
    const flightDomain = Object.keys(data.flight_domains)[0];
    return `/${systemId}${GROUND_CONTROL_PAGES_URLS(flightDomain).OVERVIEW}`;
  }
  return WARNING_PAGES_URLS.NOT_FOUND;
};
