import { Box, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { muiColorToTheme } from 'common/functions/issues/issueColorFunctions';
import { IRulesActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { BarcodeRow } from '../models/BarcodeRow.model';
import {
  BarcodeMatchLogic,
  getStatusColorChip,
} from '../../../../../common/functions/barcodes/getStatusColorChip.util';

export const BarcodeText = ({
  params,
  barcodeMatchLogic,
  issueLogic,
  isStale,
}: {
  params: GridRenderCellParams<BarcodeRow>;
  barcodeMatchLogic?: BarcodeMatchLogic | null;
  issueLogic?: IRulesActionSTIssueLogicEnum | null;
  isStale?: boolean;
}) => {
  const theme = useTheme();

  const statusColor =
    getStatusColorChip(params.row.barcodeStatus, barcodeMatchLogic, issueLogic) || 'default';
  const themeColor = isStale
    ? {
        main: 'GrayText',
      }
    : muiColorToTheme(statusColor, theme);

  return (
    <Box color={themeColor.main} component="div">
      {params.value}
    </Box>
  );
};
