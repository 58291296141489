import BlockIcon from '@mui/icons-material/Block';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import {
  IIssueSlotStatusST,
  IVeritySlotStatusStateST,
  IVeritySlotStatusUserOverrideST,
  IWMSSlotStatusSTSlotUsageEnum,
  IWMSSlotStatusSTStateEnum,
} from 'codegen/warehouse_status';
import { ILocationDataSTStateEnum } from 'codegen/report';
import { DISPLAY_VERITY_STATES, DISPLAY_WMS_STATES } from '../../slotStates';
import { getMatchArray, replaceArrayItems } from '../arrayFunctions';
import IssuesStore from '../../../store/IssuesStore';
import { REPORT_ENTRY_ACTIONS } from '../../Actions/actionTypes';
import { userHasPermission } from '../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../features/permissions/permissions.model';

/**
 * Gets the latest client user overwrite value if it exists for
 * a particular location (overwrites are ordered in the array)
 * @param slotStatus Slot Status
 * @returns the most recent verity user overwrite, if it exists null, if there is no verity user overwrite
 */
export const getClientUserOverwriteFromSlotStatus = (slotStatus: IIssueSlotStatusST) => {
  let clientUserOverwrite: IVeritySlotStatusUserOverrideST | undefined;
  // Check for all user overwrites
  if (slotStatus?.verity_status?.user_overrides?.length) {
    // Check for client user overwrites
    slotStatus.verity_status.user_overrides.forEach((overwrite) => {
      if (overwrite.review === false) {
        clientUserOverwrite = overwrite;
      }
    });
  }

  return clientUserOverwrite;
};

/**
 * On the report page, the amended tab shall only contain locations
 * that were amended by the client user
 * @param slotStatus slot status
 * @returns
 */
export const policyForAmendedLocationsInReport = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.verity_status?.user_overrides?.length &&
  getClientUserOverwriteFromSlotStatus(slotStatus);

/**
 * For the time being we use the same policy for the warehouse status page
 * @param slotStatus Slot Status
 * @returns
 */
export const policyForAmendedLocationsInWarehouseStatus = (slotStatus: IIssueSlotStatusST) =>
  policyForAmendedLocationsInReport(slotStatus);

/**
 * Get the "content found" from location data (to render on the screen)
 * Content found returns a value and a date for:
 * @param slotStatus
 * @returns data introduced by the client user
 * data introduced by the verity user (when the one above does not exist)
 * data introduced  by the verity drones (when the ones above do not exist)
 */
export const getContentFoundFromSlotStatus = (
  slotStatus: IIssueSlotStatusST,
  scanState?: ILocationDataSTStateEnum,
) => {
  let contentFoundValues: string[] = [];
  let contentFoundState: IVeritySlotStatusStateST | ILocationDataSTStateEnum | undefined;
  let contentFoundDateString = '-';
  let contentFoundChangedByTheClient: boolean = false;

  // Store latest overwrite, regardless of it being from a verity oor client user
  // removed variable latestUserOverwrite because clientUserOverwrite is also returning the latest changes
  // in response to bug: https://verity-ag.atlassian.net/browse/UD-3348
  const clientUserOverwrite = getClientUserOverwriteFromSlotStatus(slotStatus);

  contentFoundChangedByTheClient = clientUserOverwrite !== undefined;

  if (slotStatus.verity_status) {
    // the date of interest for content found is always the verity date
    contentFoundDateString = slotStatus.verity_status
      ? slotStatus.verity_status.collected_at
      : contentFoundDateString;

    // replaced variable latestUserOverwrite with clientUserOverwrite because clientUserOverwrite is also returning the latest changes
    // in response to bug: https://verity-ag.atlassian.net/browse/UD-3348
    if (clientUserOverwrite) {
      // parse user overwrite
      contentFoundState = clientUserOverwrite.state;
      if (clientUserOverwrite.state === IVeritySlotStatusStateST.Barcode) {
        contentFoundValues = clientUserOverwrite.barcodes;
      }
    } else {
      // parse original verity data overwrite
      contentFoundState = slotStatus.verity_status.state;
      if (slotStatus.verity_status.state === IVeritySlotStatusStateST.Barcode) {
        contentFoundValues = slotStatus.verity_status.barcodes;
      }
    }
  } else if (scanState && scanState !== ILocationDataSTStateEnum.Scanned) {
    contentFoundState = scanState;
  }

  return {
    contentValue: contentFoundValues,
    contentFoundState,
    contentDate: contentFoundDateString,
    contentFoundChangedByTheClient,
  };
};

/**
 * Get the verity value from location data (to render on the screen)
 * The verity value should become the verity user value in case there is one
 * This is done so that the client user sees the human reviewed value as a system value
 * @param slotStatus
 * @returns the verity user overwrite, if it exists
 * the verity value, if it exists and there is no verity user overwrite
 * "-" , in case none of the above apply
 */
export const getVerityValueFromSlotStatus = (slotStatus: IIssueSlotStatusST) => {
  const verityUserOverwrite = getVerityUserOverwriteFromSlotStatus(slotStatus);
  let valueArray: string[] = [];

  if (slotStatus.verity_status) {
    if (verityUserOverwrite) {
      if (verityUserOverwrite.state === IVeritySlotStatusStateST.Barcode) {
        valueArray = verityUserOverwrite.barcodes;
      } else {
        valueArray = [DISPLAY_VERITY_STATES[verityUserOverwrite.state]];
      }
    } else if (slotStatus.verity_status.state === IVeritySlotStatusStateST.Barcode) {
      valueArray = slotStatus.verity_status.barcodes;
    } else {
      valueArray = [DISPLAY_VERITY_STATES[slotStatus.verity_status.state]];
    }
  }

  return valueArray;
};

/**
 * Get the user name from location data (to render on the screen)
 * The user name should only be displayed when the user value was created by a client user
 * @param slotStatus
 * @returns the client user name, if it exists or "-", in case there is no client user overwrite
 */
export const getUserNameFromSlotStatus = (slotStatus: IIssueSlotStatusST) => {
  const clientUserOverwrite = getClientUserOverwriteFromSlotStatus(slotStatus);
  return clientUserOverwrite ? clientUserOverwrite.user_name : '-';
};

/**
 * Gets the latest verity user overwrite values, if it exists for
 * a particular location (overwrites are ordered in the array)
 * @param slotStatus Slot Status
 * @returns the most recent verity user overwrite, if it exists
 * null, if there is no verity user overwrite
 */
export const getVerityUserOverwriteFromSlotStatus = (slotStatus: IIssueSlotStatusST) => {
  let verityUserOverwrite: IVeritySlotStatusUserOverrideST | undefined;

  // Check for all user overwrites
  if (slotStatus?.verity_status?.user_overrides?.length) {
    // Check for verity user overwrites
    slotStatus.verity_status.user_overrides.forEach((overwrite) => {
      if (overwrite.review === true) {
        verityUserOverwrite = overwrite;
      }
    });
  }
  return verityUserOverwrite;
};

/**
 * Returns verity user overwrite state, if available
 * @param slotStatus Slot Status
 * @returns overwrite state or "-"
 */
export const getVerityStateFromSlotStatus = (slotStatus: IIssueSlotStatusST) => {
  const verityUserOverwrite = getVerityUserOverwriteFromSlotStatus(slotStatus);

  if (verityUserOverwrite) {
    return verityUserOverwrite.state;
  }
  if (slotStatus.verity_status) {
    return slotStatus.verity_status.state;
  }
  return '-';
};

/**
 * Returns wms state from location data
 * @param slotStatus Slot Status
 * @returns wms state or "-"
 */
export const getWMSStateFromSlotStatus = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.wms_status ? slotStatus.wms_status.state : '-';

/**
 * Returns the WMSDate from location data
 * @param slotStatus Slot Status
 * @returns WMSDate or "-"
 */
export const getWMSDateFromSlotStatus = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.wms_status ? slotStatus.wms_status.changed_at : '-';

/**
 * Returns the WMSArticleNos from location data
 * @param slotStatus Slot Status
 * @returns WMSArticleNos or "-"
 */
export const getWMSArticleNosFromSlotStatus = (slotStatus: IIssueSlotStatusST): string[] =>
  isArray(slotStatus?.wms_status?.article_nos) && !isEmpty(slotStatus?.wms_status?.article_nos)
    ? replaceArrayItems(slotStatus.wms_status?.article_nos, null, '-')!
    : ['-'];

/**
 * Returns the WMSQty from location data
 * @param slotStatus Slot Status
 * @returns WMSQty or "-"
 */
export const getWMSQtyFromSlotStatus = (slotStatus: IIssueSlotStatusST): string[] =>
  isArray(slotStatus?.wms_status?.qtys) && !isEmpty(slotStatus?.wms_status?.qtys)
    ? replaceArrayItems(slotStatus.wms_status?.qtys, null, '-')!
    : ['-'];

export const getCustomersFromSlotStatus = (slotStatus: IIssueSlotStatusST): string[] =>
  isArray(slotStatus?.wms_status?.customers) && !isEmpty(slotStatus?.wms_status?.customers)
    ? replaceArrayItems(slotStatus.wms_status?.customers, null, '-')!
    : ['-'];

/**
 * Returns the VerityDate from location data
 * @param slotStatus Slot Status
 * @returns VerityDate or "-"
 */
export const getVerityDateFromSlotStatus = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.verity_status ? slotStatus.verity_status.collected_at : '-';

/**
 * Returns the verity slot status version from location data
 * @param slotStatus Slot Status
 * @returns verity status or "-"
 */
export const getVeritySlotStatusVersionFromSlotStatus = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.verity_status ? slotStatus.verity_status.version : '-';

/**
 * Returns the WMS slot status version from location data
 * @param slotStatus Slot Status
 * @returns WMS Status or null
 */
export const getWMSSlotStatusVersionFromSlotStatus = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.wms_status ? slotStatus.wms_status.version : null;

/**
 * Returns the wms slot_usage status from location data
 * @param slotStatus Slot Status
 * @returns JSX Element
 */
export const getSlotUsageStatus = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.wms_status?.slot_usage === IWMSSlotStatusSTSlotUsageEnum.Blocked ? (
    <BlockIcon fontSize="small" />
  ) : (
    '-'
  );

/**
 * Returns the image ids from location data
 * @param slotStatus slot status
 * @returns array of IDs (or [])
 */
export const getImageIdsFromSlotStatus = (slotStatus: IIssueSlotStatusST) =>
  slotStatus.verity_status ? slotStatus.verity_status.image_ids : [];

/**
 * Used to automatically amend locations to review, if needed.
 * Automatic amendment is needed in case Verity user doesn't amend
 * all locations to review and SAVE_ALL_REVIEWS_FOR_VERITY_USER is
 * set to true (in settings.js)
 */
export const amendLocations = (systemId: string, locations: IIssueSlotStatusST[]) => {
  let promiseSet: any[] = [];

  locations.forEach((location) => {
    const { barcodes, state, version } = {
      barcodes: location.verity_status?.barcodes,
      state: location.verity_status?.state,
      version: location.verity_status?.version,
    };
    const { slot_label } = location;

    const overwritingItem = {
      verity_correct: true,
      set_to_expected: false,
      barcodes,
      state,
      comment: '',
    };

    promiseSet = [
      ...promiseSet,
      IssuesStore.userOverride({
        systemId,
        data: overwritingItem,
        slot_label: slot_label!,
        version: version!,
        isReview: true,
      })
        .then((r: any) => r)
        .catch((e: any) => {
          console.error('Error => ', e);
        }),
    ];
  });

  return Promise.all(promiseSet).then((r) => r);
};

// Get the wms value from location data (to render on the screen)
// Returns a string with:
//    the wms value, if it exists
//    - , in case the wms value does not exist
export const getWMSValueFromSlotStatus = (slotStatus: IIssueSlotStatusST) => {
  let contentExpectedValues: string[] = [];
  let contentExpectedState: IWMSSlotStatusSTStateEnum | undefined;

  if (slotStatus.wms_status) {
    contentExpectedState = slotStatus.wms_status.state;

    if (slotStatus.wms_status.state === IWMSSlotStatusSTStateEnum.Barcode) {
      contentExpectedValues = slotStatus.wms_status.barcodes;
    }
  }

  return { contentExpectedValues, contentExpectedState };
};

// Get original Verity value
export const getOriginalVerityValue = (slotStatus: IIssueSlotStatusST) => {
  let processedVerityValue: string[] = [];

  if (slotStatus.verity_status) {
    if (slotStatus.verity_status.state === IVeritySlotStatusStateST.Barcode) {
      processedVerityValue = slotStatus.verity_status.barcodes;
    } else {
      processedVerityValue = [DISPLAY_VERITY_STATES[slotStatus.verity_status.state]];
    }
  }

  return processedVerityValue;
};

// Get the user value from location data (to render on the screen)
// Process user value
// The user value should only be displayed when it was created by a client user
// Returns a string with:
//    the client user overwrite, if it exists
//    - , in case there is no client user overwrite
export const getUserValueFromSlotStatus = (slotStatus: IIssueSlotStatusST) => {
  const clientUserOverwrite = getClientUserOverwriteFromSlotStatus(slotStatus);
  let processedUserValue: string[] = [];

  if (slotStatus.verity_status && clientUserOverwrite) {
    if (clientUserOverwrite.state === IVeritySlotStatusStateST.Barcode) {
      processedUserValue = clientUserOverwrite.barcodes;
    } else {
      processedUserValue = [DISPLAY_VERITY_STATES[clientUserOverwrite.state]];
    }
  }

  return processedUserValue;
};

// Get original Verity state
export const getOriginalVerityState = (slotStatus: IIssueSlotStatusST) => {
  let processedVerityState = '-';

  if (slotStatus.verity_status) {
    processedVerityState = slotStatus.verity_status.state;
  }

  return processedVerityState;
};

/**
 * Get the actions for the given slot
 * @param location ID of the location
 * @slotStatus Slot (Location) status
 * @returns data structure with the definitions of the actions for the given slot / location
 */
export const getSlotActions = (location: string, slotStatus: IIssueSlotStatusST) => {
  const actions = [];

  if (userHasPermission(PERMISSION.AMEND_LOCATION)) {
    actions.push({
      label: REPORT_ENTRY_ACTIONS.AMEND,
      disabled: isEmpty(slotStatus.verity_status),
    });
  }

  return {
    data: {
      isReview: true,
      version: getVeritySlotStatusVersionFromSlotStatus(slotStatus),
      location,
      wmsValue: getWMSValueFromSlotStatus(slotStatus).contentExpectedValues.join(', '),
      wmsState: getWMSStateFromSlotStatus(slotStatus),
      userOverride: getVerityUserOverwriteFromSlotStatus(slotStatus),
      userOverrideValue: getVerityValueFromSlotStatus(slotStatus),
      userOverrideUserName:
        getVerityUserOverwriteFromSlotStatus(slotStatus) &&
        getVerityUserOverwriteFromSlotStatus(slotStatus)?.user_name,
      verityValue: getVerityValueFromSlotStatus(slotStatus),
      verityState: getVerityStateFromSlotStatus(slotStatus),
      imageIds: getImageIdsFromSlotStatus(slotStatus),
      originalVerityValue: getOriginalVerityValue(slotStatus),
      originalVerityState: getOriginalVerityState(slotStatus),
    },
    actions,
  };
};

/**
 * This function takes care of padding the data with "No match" for easier reading
 * @slotStatus Slot (Location) status
 */
export const getPaddedAndMatchArrays = (
  slotStatus: IIssueSlotStatusST,
  scanState?: ILocationDataSTStateEnum,
) => {
  // WMS data
  const contentExpectedObject = getWMSValueFromSlotStatus(slotStatus);
  const { contentExpectedState } = contentExpectedObject;
  // Expanding the contents of an array into a new array
  // in order to guarantee that we operate on a copy
  let paddedContentExpected = [...contentExpectedObject.contentExpectedValues];
  const wmsArticle = getWMSArticleNosFromSlotStatus(slotStatus);
  const paddedArticleNumber = isArray(wmsArticle) ? [...wmsArticle] : [];
  const wmsQty = getWMSQtyFromSlotStatus(slotStatus);
  const paddedQuantity = isArray(wmsQty) ? [...wmsQty] : [];
  const customers = getCustomersFromSlotStatus(slotStatus);
  const paddedCustomer = isArray(customers) ? [...customers] : [];

  // Verity data
  const contentFoundObject = getContentFoundFromSlotStatus(slotStatus, scanState);
  // Expanding the contents of an array into a new array
  // in order to guarantee that we operate on a copy
  const contentFound = [...contentFoundObject.contentValue];
  const {
    contentDate: contentFoundDate,
    contentFoundState,
    contentFoundChangedByTheClient,
  } = contentFoundObject;
  let paddedContentFound: string[] = [];

  // Get padded arrays. We only get padded arrays if either the wms data or verity data contain barcodes
  if (
    contentExpectedState === IWMSSlotStatusSTStateEnum.Barcode ||
    contentFoundState === IVeritySlotStatusStateST.Barcode
  ) {
    paddedContentExpected.forEach((el) => {
      // Check if content expected item exists in content found array
      const ind = contentFound.indexOf(el);
      if (el !== null && ind > -1) {
        // Add content found item to content found padded array
        paddedContentFound.push(contentFound[ind]);

        // Remove content found item from content found array
        contentFound.splice(ind, 1);
      } else {
        // Add no match to content found padded array
        paddedContentFound.push('No match');
      }
    });

    // Add the items that are left on the content found array to the padded content found array
    // add "No match" to the content expected padded array
    contentFound.forEach((el) => {
      paddedContentFound.push(el);

      // Pad the wms data only if it contains barcodes
      if (contentExpectedState === IWMSSlotStatusSTStateEnum.Barcode) {
        paddedContentExpected.push('No match');
        paddedArticleNumber.push('-');
        paddedQuantity.push('-');
        paddedCustomer.push('-');
      }
    });
  }

  // If any of the result sets is not a barcode set - add the corresponding user facing string
  if (contentExpectedState !== IWMSSlotStatusSTStateEnum.Barcode) {
    paddedContentExpected = [
      contentExpectedState !== null ? DISPLAY_WMS_STATES[contentExpectedState!] : '-',
    ];
  }

  if (contentFoundState !== IVeritySlotStatusStateST.Barcode) {
    paddedContentFound = [
      contentFoundState !== null ? DISPLAY_VERITY_STATES[contentFoundState!] : '-',
    ];
  }

  // Match array
  let matchArray = [];
  matchArray = getMatchArray(paddedContentExpected, paddedContentFound);

  let colorizeContentFound = true;
  let colorizeContentExpected = true;

  // If there is no verity data, we should not color it
  if (contentFoundState === null && contentExpectedState !== IWMSSlotStatusSTStateEnum.Invalid) {
    colorizeContentFound = false;
  }

  if (['UNREACHABLE', 'ABORTED', 'EXCLUDED'].includes(contentFoundState ?? '')) {
    colorizeContentExpected = false;
    colorizeContentFound = false;
  }

  // If there is no wms data, we should not color it
  // similarly, if wms data is available and is not "Invalid" and content found is not available, we should not color content expected
  if (
    contentExpectedState === null ||
    (contentExpectedState !== null &&
      contentExpectedState !== IWMSSlotStatusSTStateEnum.Invalid &&
      contentFoundState === null)
  ) {
    colorizeContentExpected = false;
  }

  return {
    paddedContentExpected,
    paddedArticleNumber,
    paddedQuantity,
    paddedCustomer,
    colorizeContentExpected,

    paddedContentFound,
    contentFoundDate,
    contentFoundChangedByTheClient,
    colorizeContentFound,
    matchArray,
  };
};
