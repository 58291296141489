import { Vector3 } from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LOD } from '../reducer/3DmapState';
import { MutableState } from '../reducer/MutableState';
import { MapCameraOptions } from '../reducer/MapOptionsState';
import { between } from './3DmapFunctions';

/**
 * Zooms the camera in or out. Holds current zoom level.
 */
export class Zoomer {
  private logPrefix = getLogPrefixForType('CLASS', '3D Zoomer');

  public currentZoomStep = 0;

  /**
   * The constructor.
   * @param options MapCameraOptions
   */
  constructor(private options: MapCameraOptions, private canvasSize: [number, number]) {
    this.currentZoomStep = options.numberOfZoomSteps;
    console.debug(this.logPrefix, `created with current zoom step: ${options.numberOfZoomSteps}`);
  }

  /**
   * Calculates new zoom step. Calls appropriate zoom function.
   * @param wheelDelta raw mouse wheel delta from event
   */
  public zoom(wheelDelta: number, manual?: boolean): void {
    console.debug(this.logPrefix, 'zoom', 'wheelDelta', wheelDelta);

    const mutableState = MutableState.getState();
    const { blockCameraMovement } = mutableState.cameraState;
    const { numberOfZoomSteps, invertZoom } = this.options;

    if (blockCameraMovement) {
      console.debug(this.logPrefix, 'zoom', 'blockCameraMovement => no change in zoom');
      return;
    }

    const directionFromWheelDelta = wheelDelta > 0 ? -1 : 1;
    const direction = manual ? wheelDelta : directionFromWheelDelta;
    const newZoomStep = this.currentZoomStep + (invertZoom ? -direction : direction);
    this.currentZoomStep = between(newZoomStep, 0, numberOfZoomSteps);

    const newFOV = this.getOrthoCamFOV();
    mutableState.cameraState.orthographicFOV = newFOV;

    const newLOD = this.calculateLOD(newFOV);

    console.debug(this.logPrefix, `zoom step ${this.currentZoomStep} => setting new LOD ${newLOD}`);

    mutableState.cameraState.lod = newLOD;
  }

  public setCurrentZoomStepFromFOV(fov: number): void {
    const { maxFOV, minFOV } = this.options.orthographic;

    const fovSpan = maxFOV - minFOV;
    const currentZoomStep = Math.ceil(fovSpan / fov) * this.options.numberOfZoomSteps;

    this.currentZoomStep = currentZoomStep;
    const newFOV = this.getOrthoCamFOV();
    const mutableState = MutableState.getState();

    mutableState.cameraState.orthographicFOV = newFOV;

    console.debug(this.logPrefix, `zoom step set by FOV ${fov} to ${currentZoomStep}`);
  }

  /**
   * Sets zoom to initial position
   */
  public resetZoom = () => {
    // this.setCurrentZoomStepFromFOV(this.options.orthographic.maxFOV);
    console.debug(this.logPrefix, 'reset zoom');

    const { numberOfZoomSteps } = this.options;
    const mutableState = MutableState.getState();

    this.currentZoomStep = numberOfZoomSteps;

    const newFOV = this.getOrthoCamFOV();
    mutableState.cameraState.orthographicFOV = newFOV;

    const newLOD = this.calculateLOD(newFOV);

    const wb = mutableState.interaction.worldBox;
    const newCameraPosition = new Vector3(
      (wb[0] + wb[3]) / 2,
      (wb[1] + wb[4]) / 2,
      mutableState.cameraState.position.z,
    );

    console.debug(this.logPrefix, `zoom step ${this.currentZoomStep} => setting new LOD ${newLOD}`);

    mutableState.cameraState.targetPosition = newCameraPosition;
    mutableState.cameraState.lod = newLOD;
  };

  /**
   * Calculates new LOD based on current zoom step.
   * @returns LOD based on current zoom step
   */
  private calculateLOD = (fov: number): LOD => {
    const [canvasWidth, canvasHeight] = this.canvasSize;
    const lodThreshold = this.options.lodThresholdMetersSquare;

    const mPerPx = fov / (canvasHeight / 2);

    const metersSquareInCanvas = canvasWidth * canvasHeight * mPerPx ** 2;

    const lod = metersSquareInCanvas > lodThreshold ? 'low' : 'high';

    console.debug(this.logPrefix, 'calculateLOD', 'LOD => ', lod);

    return lod;
  };

  /**
   * Calculates orthographic camera FOV from currentZoomStep
   * @returns FOV
   */
  private getOrthoCamFOV = (): number => {
    console.debug(
      this.logPrefix,
      `orthoZoom() invoked with currentStep=${this.currentZoomStep} and options: `,
      this.options,
    );
    const { orthographic, numberOfZoomSteps } = this.options;
    const { minFOV, maxFOV } = orthographic;

    const fovStep = (maxFOV - minFOV) / numberOfZoomSteps;
    const fovChange = this.currentZoomStep * fovStep;
    const FOV = minFOV + fovChange;

    return FOV;
  };
}
