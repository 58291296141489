import TableCell, { SortDirection } from '@mui/material/TableCell';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { HeadCellProp } from '../../types/cell';

type EnhancedTableHeadCellDefaultProps = {
  headCell: HeadCellProp;
  classes: {
    tableHeaderCell: string;
  };
  orderBy?: string;
  order?: SortDirection;
};

export const EnhancedTableHeadCellDefault = (props: EnhancedTableHeadCellDefaultProps) => {
  const { headCell, classes, orderBy, order } = props;

  if (headCell === undefined) {
    console.debug(
      'EnhancedTableHeadCellDefault',
      'headCell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  let sx = {};

  if (headCell.tooltipInfo) {
    sx = { display: 'flex', justifyContent: 'center', alignItems: 'center' };
  }

  return (
    <TableCell
      data-testid="c-table-column-head"
      className={classes.tableHeaderCell}
      key={headCell.id}
      sortDirection={orderBy === headCell.id ? order : false}
      sx={sx}
    >
      {headCell.label}
      {headCell.tooltipInfo && (
        <TooltipedIcon
          tooltip={headCell.tooltipInfo}
          icon={<InfoOutlinedIcon fontSize="medium" />}
        />
      )}
    </TableCell>
  );
};
