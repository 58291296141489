import moment from 'moment';
import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/report';

/**
 * Function that return the color for a Content (Expected or Found) Cell
 * @param hasWmsData flag indicating whether the row has WMS data
 * @param hasIssues flag indicating whether the row contains issues
 * @param isDataStale flag indicating whether the verity data is stale
 * @returns a string containing the (MUI) color
 */
export const getContentCellColor = (
  hasWmsData: boolean,
  hasIssues: boolean,
  isDataStale: boolean = false,
) => {
  if (isDataStale) return 'text.secondary';
  if (!hasWmsData) return 'text.primary';
  if (hasIssues) return 'error.main';
  return 'success.main';
};

/**
 * Checks whether the given WMS status contains data
 * @param wmsStatus WMS Status
 * @returns true if it has WMS data
 */
export const hasWmsData = (wmsStatus: IWMSSlotStatusST) =>
  !!wmsStatus && typeof wmsStatus !== 'string';

export const hasVerityData = (verityStatus: IVeritySlotStatusST) =>
  !!verityStatus && typeof verityStatus !== 'string';

/**
 * Verify whether the given verity data is stale (i.e. is older than the WMS data).
 * If no WMS data is available, then the verity data is never considered stale
 * @param verityStatus Verity Status (data)
 * @param wmsStatus WMS Status (data)
 * @returns flag indicating whether the Verity data is stale
 */
export const isDataStale = (locationData: ILocationDataST) => {
  const verityStatus = locationData.verity_status as IVeritySlotStatusST;
  const wmsStatus = locationData.wms_status as IWMSSlotStatusST;

  if (!hasWmsData(wmsStatus)) {
    // wms Data is not available hence verity data cannot be stale
    return false;
  }
  if (!hasVerityData(verityStatus)) {
    // no verity data, data cannot be stale
    return false;
  }
  const verityDate = verityStatus.updated_at;
  const wmsDate = wmsStatus.updated_at;

  return moment(verityDate).isBefore(wmsDate);
};
