import TableCell from '@mui/material/TableCell';
import Switch from '@mui/material/Switch';
import { Box } from 'components/common/Box';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
type EnhancedTableCellSwitchProps = EnhancedTableCellProp<any> & {
  labelId: string;
};

export const EnhancedTableCellSwitch = (props: EnhancedTableCellSwitchProps) => {
  const { cell, row, onClick, labelId } = props;

  if (cell === undefined) {
    console.debug(
      'EnhancedTableCellSwitch',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} padding="checkbox">
      <Box style={cell.style()} component="div">
        <Switch
          data-testid={`c-${cell.id}-switch`}
          checked={row.canFly}
          inputProps={{
            'aria-labelledby': labelId,
          }}
          onClick={onClick}
        />
      </Box>
    </TableCell>
  );
};
