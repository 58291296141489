export enum ROLE {
  INVENTORY_MANAGER = 'INVENTORY_MANAGER',
  VERITY_USER = 'VERITY_USER',
  REVIEWER = 'REVIEWER',
  GROUND_CONTROL_OFFICER = 'GROUND_CONTROL_OFFICER',
  ANALYTICS = 'ANALYTICS',
  SAFETY_CONTROLS = 'SAFETY_CONTROLS',
  SUPER_ADMIN = 'SUPER_ADMIN',
  UNKNOWN = 'UNKNOWN',
}

export type Role = `${ROLE}`;
