import { Geometry } from 'shared/map/model/geometry.model';
import { initControlledZoneAPI } from './api';

export const updateControlledZone = (
  systemId: string,
  flightDomainId: string,
  controlledZoneId: string,
  name: string,
  description: string,
  geometry: Geometry,
) =>
  initControlledZoneAPI().updateControlledZoneProperties(
    systemId,
    flightDomainId,
    controlledZoneId,
    {
      name,
      description,
      geometry,
    },
  );
