import { initSupersetAPIService } from './service';

interface IGetSuperSetSettings {
  signal?: AbortSignal;
}

/**
 * Get superset settings
 * @param param
 * @returns {object} <IGetSuperSetConfigResponseST>
 */
export const getSuperSetSettings = ({ signal }: IGetSuperSetSettings) =>
  initSupersetAPIService().getSuperSetSettings({ signal });
