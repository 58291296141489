import { WebSocketSubscription } from 'shared/web-socket/web-socket.model';

export type FleetPageSubscribersOptions = 'estimatedObstacles' | 'spaceReservations';

export const fleetPageSubscribers: Record<FleetPageSubscribersOptions, WebSocketSubscription> = {
  estimatedObstacles: {
    channel: 'ground-control/estimated-obstacles/status-update',
    delegate: 'FleetPageEstimatedObstacles',
  },
  spaceReservations: {
    channel: 'ground-control/space-reservation/status-update',
    delegate: 'FleetPageSpaceReservation',
  },
};
