import { useMemo } from 'react';
import { DroneZones, DroneZoneTypes } from '../../../../../../model/drone-zones.model';
import { ZoneSideViewControlledZone } from '../zone-side-view-controlled-zone/ZoneSideViewControlledZone';
import { ZoneSideViewNoFlyZone } from '../zone-side-view-no-fly-zone/ZoneSideViewNoFlyZone';
import { ZoneSideViewOpenFlyZone } from '../zone-side-view-open-fly-zone/ZoneSideViewOpenFlyZone';

const ZONE_COMPONENT_MAP = {
  [DroneZoneTypes.controlledZone]: ZoneSideViewControlledZone,
  [DroneZoneTypes.openFlyZone]: ZoneSideViewOpenFlyZone,
  [DroneZoneTypes.noFlyZone]: ZoneSideViewNoFlyZone,
};

export const ZoneSideViewType = ({
  ...props
}: {
  zone: DroneZones;
  areaHeight: number;
  isSelected: boolean;
  isDisabled: boolean;
  onClick: (zone: DroneZones) => void;
}) => {
  const zoneType = props.zone.type;

  const ZoneComponent = useMemo(() => ZONE_COMPONENT_MAP[zoneType], [zoneType]);

  return <ZoneComponent {...props} />;
};
