// mui core
import TextField from '@mui/material/TextField';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete';
import { SyntheticEvent } from 'react';

interface ICustomSearchesbleSelectProps {
  label: string;
  testId: string;
  options: string[];
  value: string;
  error: boolean;
  disabled: boolean;
  helperText: string;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string>,
  ) => void;
  rest?: React.MouseEvent<HTMLAnchorElement>;
}

const CustomSearchableSelect = (props: ICustomSearchesbleSelectProps): JSX.Element => {
  const { label, testId, value, options, onChange, error, helperText, ...rest } = props;

  return (
    <Autocomplete
      {...rest}
      options={options || []}
      disablePortal
      value={value}
      onChange={onChange}
      disableClearable
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          data-testid={testId}
          error={error}
          helperText={helperText}
          margin="normal"
          label={label}
        />
      )}
    />
  );
};

export default CustomSearchableSelect;
