import { createControlledZone } from './createControlledZone';
import { getControlledZones } from './getControlledZones';
import { updateControlledZone } from './updateControlledZone';
import { deleteControlledZone } from './deleteControlledZone';
import { setControlledZoneActive } from './setControlledZoneActive';

export const ControlledZoneAPI = {
  getControlledZones,
  createControlledZone,
  updateControlledZone,
  deleteControlledZone,
  setControlledZoneActive,
};
