import { IChangeControlledZonePermissionRequestST } from 'codegen/controlled_zone';
import { initControlledZoneAPI } from './api';

export const setControlledZoneActive = (
  systemId: string,
  flightDomainId: string,
  controlledZoneId: string,
  controlledZone: IChangeControlledZonePermissionRequestST,
) =>
  initControlledZoneAPI().setControlledZoneActive(
    systemId,
    flightDomainId,
    controlledZoneId,
    controlledZone,
  );
