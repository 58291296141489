/**
 * reports list - inventory requests
 */
export enum INVENTORY_REQUEST_ACTIONS {
  EDIT = 'Edit',
  DELETE = 'Delete',
  ENABLE = 'Enable',
  DISABLE = 'Disable',
}
export enum DISPATCHED_AND_ONGOING_REPORT_ACTIONS {
  ABORT = 'Abort',
}
export enum ONGOING_REPORT_ACTIONS {
  UPDATE = 'Update',
}

export enum REPORT_ACTIONS {
  ARCHIVE = 'Archive',
  RESTORE = 'Restore',
}

/**
 * report data tables - all entries
 */
export enum REPORT_ENTRY_ACTIONS {
  AMEND = 'Amend',
}
// report data tables - issues entries
export enum ISSUE_ACTIONS {
  SNOOZE = 'Snooze',
  UNSNOOZE = 'Unsnooze',
}

export const COMMISSIONING_TASK_ACTIONS = {
  ABORT: {
    label: 'Abort ',
    action: 'Abort Task',
  },
};

// Uploaded files table
export enum UPLOADED_FILE_ACTIONS {
  DOWNLOAD_WMS_PARSER_OUTPUT = 'Download parser output',
  DOWNLOAD_CLIENT_FILE = 'Download uploaded file',
}
