import { useState } from 'react';

// material-ui
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';

import { singleRequestHandler } from 'common/requestHelpers';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';

import { DeveloperDrawerButton } from './DeveloperDrawerButton';
import DeveloperServices from '../../services/DeveloperServices';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

interface RawTaskInputsProps {
  objectivesNumber: number;
  setObjectivesNumber: any;
  flightDomainId: string;
  setFlightDomainId: any;
}

interface IScriptedFrontendSimulator {
  addToOutput: (messages: string[]) => void;
  systemId: string;
}

const RawTaskInputs = (props: RawTaskInputsProps) => {
  const { objectivesNumber, flightDomainId, setFlightDomainId } = props;

  const { stateFacilityLevel } = useFacilityLevelStore();

  const flightDomainsList = stateFacilityLevel.flightDomains;

  const optionsValue = flightDomainsList.map((flightDomain) => ({
    value: flightDomain.flight_domain_id,
    label: flightDomain.flight_domain_name,
  }));

  return (
    <>
      <TextField
        data-testid="c-dev-tools-raw-task-objectives-number"
        id="objectivesNumber"
        style={{ width: '180px' }}
        type="number"
        size="small"
        label="Objectives Number"
        value={objectivesNumber}
        onChange={(e) => props.setObjectivesNumber(Number(e.target.value))}
      />
      <CustomSelect
        id="rawTaskflightDomainIdSelector"
        variant="outlined"
        name="Flight Domain"
        value={flightDomainId}
        valueOptions={optionsValue}
        size="small"
        style={{ marginBottom: '16px' }}
        onChange={(e) => {
          setFlightDomainId(e.target.value);
        }}
        label="Select Flight Domain"
        error={false}
        errorMessage=""
        defaultValue=""
        disabled={flightDomainsList.length === 0}
        testId="flightDomainId"
      />
    </>
  );
};

/**
 *
 * @returns Scripted Frontend Simulator Component
 */
export function ScriptedFrontendSimulator(props: IScriptedFrontendSimulator) {
  const [objectivesNumber, setObjectivesNumber] = useState(5);
  const [flightDomainId, setFlightDomainId] = useState('');

  const { systemId, addToOutput } = props;

  // snackbar hook
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateRawTaskOnClick = () => {
    singleRequestHandler({
      request: DeveloperServices.postSFESimulator,
      requestParams: [systemId, flightDomainId, objectivesNumber],
      dispatcher: enqueueSnackbar,
      callbackSuccess: (reply: { data: any }) => {
        addToOutput([`::: Raw task uploaded with success to s3: ${JSON.stringify(reply.data)}`]);
      },
      messageSuccess: 'Raw task create simulator invoked successfully',
      callbackError: (e) => {
        addToOutput([`Error invoking sfe simulator: ${e}`]);
      },
    });
  };

  return (
    <DeveloperDrawerButton
      testId="c-dev-tools-task-executor"
      buttonLabel="Create raw task"
      clickHandler={handleCreateRawTaskOnClick}
      content={
        <RawTaskInputs
          objectivesNumber={objectivesNumber}
          flightDomainId={flightDomainId}
          setObjectivesNumber={setObjectivesNumber}
          setFlightDomainId={setFlightDomainId}
        />
      }
    />
  );
}
