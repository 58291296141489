/* tslint:disable */
/* eslint-disable */
/**
 * Issue Snooze
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
}
/**
 *
 * @export
 * @interface IIssueSnoozePutResponseST
 */
export interface IIssueSnoozePutResponseST {
  /**
   * Message
   * @type {string}
   * @memberof IIssueSnoozePutResponseST
   */
  message: string;
}
/**
 *
 * @export
 * @interface IIssueUnsnoozePutResponseST
 */
export interface IIssueUnsnoozePutResponseST {
  /**
   * Message
   * @type {string}
   * @memberof IIssueUnsnoozePutResponseST
   */
  message: string;
}

/**
 * IssueSnoozeApi - axios parameter creator
 * @export
 */
export const IssueSnoozeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Snoozes the issue with issue_id
     * @summary Snoozes the issue
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    snoozeIssue: async (
      systemIdStr: string,
      issueId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('snoozeIssue', 'systemIdStr', systemIdStr);
      // verify required parameter 'issueId' is not null or undefined
      assertParamExists('snoozeIssue', 'issueId', issueId);
      const localVarPath = `/{system_id_str}/issue/{issue_id}/snooze`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'issue_id'}}`, encodeURIComponent(String(issueId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Un-snoozes the issue with issue_id
     * @summary Un-snoozes the issue
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsnoozeIssue: async (
      systemIdStr: string,
      issueId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('unsnoozeIssue', 'systemIdStr', systemIdStr);
      // verify required parameter 'issueId' is not null or undefined
      assertParamExists('unsnoozeIssue', 'issueId', issueId);
      const localVarPath = `/{system_id_str}/issue/{issue_id}/unsnooze`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'issue_id'}}`, encodeURIComponent(String(issueId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IssueSnoozeApi - functional programming interface
 * @export
 */
export const IssueSnoozeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IssueSnoozeApiAxiosParamCreator(configuration);
  return {
    /**
     * Snoozes the issue with issue_id
     * @summary Snoozes the issue
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async snoozeIssue(
      systemIdStr: string,
      issueId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IIssueSnoozePutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.snoozeIssue(
        systemIdStr,
        issueId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Un-snoozes the issue with issue_id
     * @summary Un-snoozes the issue
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unsnoozeIssue(
      systemIdStr: string,
      issueId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IIssueUnsnoozePutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unsnoozeIssue(
        systemIdStr,
        issueId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * IssueSnoozeApi - factory interface
 * @export
 */
export const IssueSnoozeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = IssueSnoozeApiFp(configuration);
  return {
    /**
     * Snoozes the issue with issue_id
     * @summary Snoozes the issue
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    snoozeIssue(
      systemIdStr: string,
      issueId: string,
      options?: any,
    ): AxiosPromise<IIssueSnoozePutResponseST> {
      return localVarFp
        .snoozeIssue(systemIdStr, issueId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Un-snoozes the issue with issue_id
     * @summary Un-snoozes the issue
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsnoozeIssue(
      systemIdStr: string,
      issueId: string,
      options?: any,
    ): AxiosPromise<IIssueUnsnoozePutResponseST> {
      return localVarFp
        .unsnoozeIssue(systemIdStr, issueId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * IssueSnoozeApi - object-oriented interface
 * @export
 * @class IssueSnoozeApi
 * @extends {BaseAPI}
 */
export class IssueSnoozeApi extends BaseAPI {
  /**
   * Snoozes the issue with issue_id
   * @summary Snoozes the issue
   * @param {string} systemIdStr
   * @param {string} issueId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IssueSnoozeApi
   */
  public snoozeIssue(systemIdStr: string, issueId: string, options?: AxiosRequestConfig) {
    return IssueSnoozeApiFp(this.configuration)
      .snoozeIssue(systemIdStr, issueId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Un-snoozes the issue with issue_id
   * @summary Un-snoozes the issue
   * @param {string} systemIdStr
   * @param {string} issueId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IssueSnoozeApi
   */
  public unsnoozeIssue(systemIdStr: string, issueId: string, options?: AxiosRequestConfig) {
    return IssueSnoozeApiFp(this.configuration)
      .unsnoozeIssue(systemIdStr, issueId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
