import { DroneZones } from '../../model/drone-zones.model';
import { sortDroneZonesByName } from '../sort-drone-zones-by-name/sortDroneZonesByName.util';

export const mapSortDetailsZones = (
  originalZones: Record<string, DroneZones>,
  detailsZones: DroneZones[],
) => {
  const idSet = new Set(detailsZones.map((z) => z.id));
  const mappedDetailsZones = Object.values(originalZones).filter((item) => idSet.has(item.id));

  return sortDroneZonesByName(mappedDetailsZones);
};
