import isEmpty from 'lodash/isEmpty';
import { IIssueSlotStatusST, IIssueST, IWMSSlotStatusSTStateEnum } from 'codegen/report';
import { sort } from '../otherFunctions';
import {
  getWMSDateFromSlotStatus,
  getWMSSlotStatusVersionFromSlotStatus,
  getVerityValueFromSlotStatus,
  getVerityDateFromSlotStatus,
  getUserNameFromSlotStatus,
  getVeritySlotStatusVersionFromSlotStatus,
  getWMSArticleNosFromSlotStatus,
  getWMSQtyFromSlotStatus,
  getVerityUserOverwriteFromSlotStatus,
  getWMSValueFromSlotStatus,
  getUserValueFromSlotStatus,
  getSlotActions,
} from '../slot/slotFunctions';
import { getIssueTypeFromSingleIssueForReviewTable } from '../issues/issueFunctions';

export type RowDataForReview = {
  id: string;
  location: string;
  wmsValue: {
    contentExpectedValues: string[];
    contentExpectedState: IWMSSlotStatusSTStateEnum | undefined;
  };
  wmsDate: string;
  verityValue: string | string[];
  verityDate: string;
  version: number | '-';
  userValue: string[];
  userName: string;
  wmsArticleNo: string[];
  wmsQty: string[];
  wmsSlotStatusVersion: number | null;
  issue: string | null;
  actions: {
    data: any;
    actions: {
      label: string;
      disabled: boolean;
    }[];
  };
  allowRedirection: boolean;
};

/**
 * Get row for locations to review table
 * @param location location
 * @param slotStatus slot status
 * @param issuesForLocation issues related to the location
 * @returns
 */
export const getRowForLocationsToReviewTable = (
  location: string,
  slotStatus: IIssueSlotStatusST,
  issuesForLocation: IIssueST[],
) => {
  const rowData: RowDataForReview = {} as RowDataForReview;
  let locationIssue: IIssueST | null = null;

  if (!isEmpty(issuesForLocation)) {
    const sortedIssuesData = sort({
      array: issuesForLocation,
      sortingOrder: 'desc',
      sortBy: 'updated_at',
    }) as IIssueST[];
    [locationIssue] = sortedIssuesData;
  }

  rowData.id =
    locationIssue && isEmpty(locationIssue)
      ? `${locationIssue.issue_id}?version=${locationIssue.version}`
      : location;

  rowData.location = slotStatus.slot_label!;
  rowData.wmsValue = getWMSValueFromSlotStatus(slotStatus);
  rowData.wmsDate = getWMSDateFromSlotStatus(slotStatus);

  // During the review stage, on locations to review
  // mark the verity value with a "*", in case it already has been reviewed by a verity user
  rowData.verityValue = getVerityUserOverwriteFromSlotStatus(slotStatus)
    ? `${getVerityValueFromSlotStatus(slotStatus)} *`
    : getVerityValueFromSlotStatus(slotStatus);

  rowData.verityDate = getVerityDateFromSlotStatus(slotStatus);
  rowData.version = getVeritySlotStatusVersionFromSlotStatus(slotStatus);
  rowData.userValue = getUserValueFromSlotStatus(slotStatus);
  rowData.userName = getUserNameFromSlotStatus(slotStatus);
  rowData.wmsArticleNo = getWMSArticleNosFromSlotStatus(slotStatus);

  rowData.wmsQty = getWMSQtyFromSlotStatus(slotStatus);
  rowData.wmsSlotStatusVersion = getWMSSlotStatusVersionFromSlotStatus(slotStatus);
  rowData.issue = locationIssue ? getIssueTypeFromSingleIssueForReviewTable(locationIssue) : null;
  rowData.actions = getSlotActions(location, slotStatus);

  // TR::2020-01-08:: FIX-ME: Allow redirection to open the location amendment modal
  rowData.allowRedirection = false;

  return rowData;
};
