import { allNumbersToFixed2 } from 'shared/numberFormatting';
import { MapState } from '../reducer/3DmapState';

export const mapFacilityVectors = (
  facilityBox: NonNullable<MapState['map']>['box'] | undefined,
) => {
  const [minX, minY, minZ, maxX, maxY, maxZ] = facilityBox ?? [0, 0, 0, 0, 0, 0];

  return allNumbersToFixed2({
    minX,
    minY,
    minZ,
    maxX,
    maxY,
    maxZ,
  });
};
