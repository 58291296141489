import { IBarcodeST } from '../../../../codegen/location_information';
import { calculateBarcodeFrameDimensions } from './utils/calculateBarcodeFrameDimensions';

export const BarcodeLabel = ({ barcode }: { barcode: IBarcodeST }) => {
  const dimensions = calculateBarcodeFrameDimensions(barcode);
  return (
    <div
      style={{
        position: 'absolute',
        // Add top+height here, so the label is placed underneath the respective barcode frame
        top: `${dimensions.topPc + dimensions.heightPc}%`,
        left: `${dimensions.leftPc}%`,
        color: '#FFFFFF',
        fontSize: '8px',
        background: ((): string => {
          if (barcode.status === 'BARCODE_RECOGNIZED') return '#2196F3';
          if (barcode.status === 'BARCODE_PATTERN_MISMATCH') return '#FF8000';
          return 'rgba(255,0,0,0.5)';
        })(),
        padding: '1px 4px 0px 4px',
      }}
    >
      {barcode.udb_value}
    </div>
  );
};
