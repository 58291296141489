import { WebSocketClient } from 'shared/web-socket/web-socket.model';
import { IGetSpaceReservationsResponseST } from 'codegen/nav_simulation';
import { fleetPageSubscribers } from './fleet-page.subscribers';

export const subscribeToSpaceReservations = ({
  socket,
  flightDomainId,
  onSubscribe,
}: {
  socket: WebSocketClient;
  flightDomainId: string;
  onSubscribe: (data: IGetSpaceReservationsResponseST['space_reservations']) => void;
}) => {
  socket.subscribe({
    onMessage(data: IGetSpaceReservationsResponseST): void {
      if (flightDomainId === data.flight_domain_id) {
        onSubscribe(data.space_reservations);
      }
    },
    channel: fleetPageSubscribers.spaceReservations.channel,
    delegate: fleetPageSubscribers.spaceReservations.delegate,
  });
};
