import { Box } from '@mui/material';
import { IRulesST } from 'codegen/facility_settings';
import { ActionPhrase } from './ActionPhrase';
import { ConditionsPhrase } from './ConditionsPhrase';

export const RulePhrase = ({
  rule,
  isNotFirstRule,
  isLastRule,
}: {
  rule: IRulesST;
  isNotFirstRule: boolean;
  isLastRule: boolean;
}) => (
  <Box component="div">
    <ConditionsPhrase conditions={rule.conditions!} isNotFirstRule={isNotFirstRule} />
    <ActionPhrase action={rule.action!} isLastRule={isLastRule} />
  </Box>
);
