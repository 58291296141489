import type { TZDate } from '@toast-ui/calendar';
import { Moment } from 'moment';

interface IHoursMinutesRange {
  fromHours: string;
  fromMinutes: string;
  toHours: string;
  toMinutes: string;
}

type DateType = TZDate | Moment;

/**
 * Converts passed dates to hours and minutes range
 * @param fromDate DateType
 * @param toDate DateType
 * @returns IHoursMinutesRange object
 */
export const formatDatesToHoursMinutesRange = (
  fromDate: DateType,
  toDate: DateType,
): IHoursMinutesRange => ({
  fromHours: `0${new Date(fromDate.toDate()).getHours()}`.slice(-2),
  fromMinutes: `0${new Date(fromDate.toDate()).getMinutes()}`.slice(-2),
  toHours: `0${new Date(toDate.toDate()).getHours()}`.slice(-2),
  toMinutes: `0${new Date(toDate.toDate()).getMinutes()}`.slice(-2),
});
