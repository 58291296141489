import { OpenFlyZone } from 'shared/map/model/open-fly-zone.model';
import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { DroneZones, DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { openFlyZonesFromDTO } from 'shared/map/model/transform/open-fly-zone-from-dto';
import { controlledZonesFromDTO } from 'shared/map/model/transform/controlled-zone-from-dto';

import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import { DroneZonePageState, FlowState } from './droneZonePage.model';
import { ZoneStatusActions } from './droneZonePage.actions';

export const droneZonePageReducer = (
  state: DroneZonePageState,
  action: ZoneStatusActions,
): DroneZonePageState => {
  switch (action.type) {
    case 'SET_FLOW_STATE':
      return {
        ...state,
        flowState: action.payload,
      };
    case 'SET_OPEN_FLY_ZONES':
      return {
        ...state,
        openFlyZones: openFlyZonesFromDTO(action.payload),
      };
    case 'SET_CONTROLLED_ZONES':
      return {
        ...state,
        controlledZones: controlledZonesFromDTO(action.payload),
      };
    case 'SELECT_SINGLE_DRONE_ZONE': {
      const isCurrentlySelected = action.payload?.id === state.selectedZone?.id;
      const zone = isCurrentlySelected ? null : action.payload;
      const isEditing = [FlowState.CREATE, FlowState.DRAW].includes(state.flowState);

      if (action.payload && !isEditing) {
        return {
          ...state,
          detailsZones: zone ? [zone] : [],
          selectedZone: zone,
        };
      }

      return {
        ...state,
        detailsZones: [],
        selectedZone: null,
      };
    }
    case 'SELECT_SINGLE_FROM_DRONE_ZONE_GROUP': {
      return {
        ...state,
        selectedZone: action.payload,
      };
    }
    case 'SELECT_MULTIPLE_DRONE_ZONE': {
      const isEditing = [FlowState.CREATE, FlowState.DRAW].includes(state.flowState);

      return {
        ...state,
        detailsZones: isEditing ? [] : action.payload,
        selectedZone: null,
      };
    }
    case 'EDIT_DRONE_ZONE': {
      return {
        ...state,
        selectedZone: null,
        detailsZones: [],
        flowState: FlowState.CREATE,
        currentlyEditingZone: action.payload,
      };
    }
    case 'START_DRAWING_CONTROLLED_ZONE': {
      return {
        ...state,
        selectedZone: null,
        detailsZones: [],
        flowState: FlowState.DRAW,
      };
    }
    case 'CREATE_DRONE_ZONE': {
      const controlledZones = { ...state.controlledZones };

      controlledZones[action.payload.id] = action.payload;

      return {
        ...state,
        controlledZones,
        selectedZone: action.payload,
        detailsZones: [],
        currentlyEditingZone: action.payload,
        flowState: FlowState.CREATE,
      };
    }
    case 'CANCEL_CREATING_DRONE_ZONE':
    case 'END_CREATING_DRONE_ZONE': {
      return {
        ...state,
        currentlyEditingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'CANCEL_EDITING_DRONE_ZONE': {
      const droneZones = {
        ...state.openFlyZones,
        ...state.controlledZones,
      };

      if (action.payload.id === NEW_DRONE_ZONE_ID) {
        delete droneZones[action.payload.id];
      } else {
        droneZones[action.payload.id] = action.payload;
      }

      return {
        ...state,
        controlledZones: controlledZonesFromDroneZones(droneZones),
        openFlyZones: openFlyZonesFromDroneZones(droneZones),
        currentlyEditingZone: null,
        flowState: FlowState.LIST,
      };
    }

    case 'END_DRAGGING_DRONE_ZONE': {
      const controlledZones = { ...state.controlledZones };
      controlledZones[action.payload.id] = action.payload;
      return {
        ...state,
        controlledZones,
        currentlyEditingZone: action.payload,
      };
    }

    case 'UPDATE_DRONE_ZONE': {
      const droneZones = { ...state.openFlyZones, ...state.controlledZones };
      droneZones[action.payload.id] = action.payload;
      return {
        ...state,
        controlledZones: controlledZonesFromDroneZones(droneZones),
        openFlyZones: openFlyZonesFromDroneZones(droneZones),
      };
    }
    case 'DELETE_DRONE_ZONE': {
      const droneZones = { ...state.openFlyZones, ...state.controlledZones };
      delete droneZones[action.payload.id];
      return {
        ...state,
        controlledZones: controlledZonesFromDroneZones(droneZones),
        openFlyZones: openFlyZonesFromDroneZones(droneZones),
        detailsZones: state.detailsZones.filter((dz) => dz.id !== action.payload.id),
        currentlyDeletingZone: null,
      };
    }
    case 'CANCEL_DELETING_DRONE_ZONE': {
      return {
        ...state,
        currentlyDeletingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'SHOW_DELETION_MODAL':
      return {
        ...state,
        currentlyDeletingZone: action.payload,
        flowState: FlowState.DELETE,
      };
    default:
      return { ...state };
  }
};

const openFlyZonesFromDroneZones: (
  droneZones: Record<string, DroneZones>,
) => Record<string, OpenFlyZone> = (droneZones) =>
  Object.entries(droneZones).reduce(
    (openFlyZones, [key, droneZone]) =>
      droneZone.type === DroneZoneTypes.openFlyZone
        ? { ...openFlyZones, [key]: droneZone }
        : openFlyZones,
    {},
  );

const controlledZonesFromDroneZones: (
  droneZones: Record<string, DroneZones>,
) => Record<string, ControlledZone> = (droneZones) =>
  Object.entries(droneZones).reduce(
    (controlledZones, [key, droneZone]) =>
      droneZone.type === DroneZoneTypes.controlledZone
        ? { ...controlledZones, [key]: droneZone }
        : controlledZones,
    {},
  );
