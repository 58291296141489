import { IReportST } from 'codegen/report';
import { GetReportData } from 'udb/inventory/reports/reducer/report-store/ReportStore';
import { ReportSummaryData } from 'udb/inventory/reports/reducer/report-store/ReportStore.model';

export enum ReportContainerActionNames {
  CLEAR_STATE = 'CLEAR_STATE',
  SET_ANY = 'SET_ANY',
  ALL_REQUESTS_FINISHED = 'ALL_REQUESTS_FINISHED',
  UPDATE_REPORT_PROGRESS = 'UPDATE_REPORT_PROGRESS',
  GET_REPORT_SUMMARY_CALLBACK_BEFORE_SEND = 'GET_REPORT_SUMMARY_CALLBACK_BEFORE_SEND',
  GET_REPORT_SUMMARY_CALLBACK_SUCCESS = 'GET_REPORT_SUMMARY_CALLBACK_SUCCESS',
  GET_REPORT_EXECUTION_CALLBACK_SUCCESS = 'GET_REPORT_EXECUTION_CALLBACK_SUCCESS',
  REPORT_LOCATIONS_EMPTY = 'REPORT_LOCATIONS_EMPTY',
  GET_REPORT_CALLBACK_SUCCESS = 'GET_REPORT_CALLBACK_SUCCESS',
  GET_REPORT_CALLBACK_FINALLY = 'GET_REPORT_CALLBACK_FINALLY',
}

type FieldName =
  | 'fullReportSpinner'
  | 'tableSpinner'
  | 'issuesSpinner'
  | 'summarySpinner'
  | 'spinner'
  | 'issues'
  | 'amended'
  | 'snoozed'
  | 'potential'
  | 'inconclusive'
  | 'invalid'
  | 'fullReport'
  | 'pageSubtitle'
  | 'displayRefreshOption'
  | 'summaryLoaded'
  | 'reportLoaded'
  | 'issuesSummary'
  | 'summaryData'
  | 'reportState'
  | 'reportName'
  | 'locationsToReview'
  | 'reportNeedsReview'
  | 'reportNumLocations'
  | 'rawReportData'
  | 'isDownloading'
  | 'requestsToSend'
  | 'repliesReceived'
  | 'allRequestsFinished';

type UpdateReportProgress = {
  reportId: string;
  reportUpdateId: string;
  reportStatus: string;
  reportProgress: number;
};

export type ReportContainerActions =
  | { type: ReportContainerActionNames.CLEAR_STATE }
  | {
      type: ReportContainerActionNames.SET_ANY;
      payload: { fieldName: FieldName; value: boolean | string | number };
    }
  | { type: ReportContainerActionNames.ALL_REQUESTS_FINISHED }
  | { type: ReportContainerActionNames.UPDATE_REPORT_PROGRESS; payload: UpdateReportProgress }
  | { type: ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_BEFORE_SEND }
  | {
      type: ReportContainerActionNames.GET_REPORT_SUMMARY_CALLBACK_SUCCESS;
      payload: ReportSummaryData;
    }
  | {
      type: ReportContainerActionNames.GET_REPORT_EXECUTION_CALLBACK_SUCCESS;
      payload: ReportSummaryData;
    }
  | { type: ReportContainerActionNames.REPORT_LOCATIONS_EMPTY; payload: IReportST }
  | {
      type: ReportContainerActionNames.GET_REPORT_CALLBACK_SUCCESS;
      payload: GetReportData;
    }
  | { type: ReportContainerActionNames.GET_REPORT_CALLBACK_FINALLY };
