import { IControlledZoneUpdatePropertiesRequestST } from 'codegen/controlled_zone';
import { initControlledZoneService } from './service';

export const updateControlledZone = ({
  systemId,
  flightDomainId,
  controlledZoneId,
  controlledZone,
  signal,
}: {
  systemId: string;
  flightDomainId: string;
  controlledZoneId: string;
  controlledZone: IControlledZoneUpdatePropertiesRequestST;
  signal: AbortSignal;
}) =>
  initControlledZoneService().updateControlledZoneProperties(
    systemId,
    flightDomainId,
    controlledZoneId,
    controlledZone,
    { signal },
  );
