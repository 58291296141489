import { OpenFlyZones } from 'shared/map/model/open-fly-zone.model';
import { ControlledZones } from 'shared/map/model/controlled-zone.model';
import { NoFlyZones } from 'shared/map/model/no-fly-zone.model';

export const mapZoneIsSelectedAndIsEditing = ({
  zones,
  selectedZoneId,
  editingZoneId,
}: {
  zones: NoFlyZones | OpenFlyZones | ControlledZones;
  selectedZoneId: string | undefined;
  editingZoneId?: string | undefined;
}) =>
  Object.values(zones).reduce(
    (acc, val) => ({
      ...acc,
      [val.id]: {
        ...val,
        isSelected: val.id === selectedZoneId,
        isEditing: val.id === editingZoneId,
      },
    }),
    {},
  );
