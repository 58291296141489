import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export const cleanGridState = (currentState: GridInitialStatePremium) => {
  let cleanState: any = {};
  if (
    currentState.columns?.columnVisibilityModel &&
    Object.keys(currentState.columns.columnVisibilityModel).length
  ) {
    cleanState = { ...cleanState, columns: { ...currentState.columns } };
  }
  if (
    currentState.filter?.filterModel?.items.length ||
    currentState.filter?.filterModel?.quickFilterValues?.length
  ) {
    cleanState = { ...cleanState, filter: { ...currentState.filter } };
  }
  if (currentState.aggregation?.model) {
    cleanState = { ...cleanState, aggregation: { ...currentState.aggregation } };
  }
  if (currentState.pinnedColumns?.left || currentState.pinnedColumns?.right) {
    cleanState = { ...cleanState, pinnedColumns: { ...currentState.pinnedColumns } };
  }
  if (currentState.rowGrouping?.model?.length) {
    cleanState = { ...cleanState, rowGrouping: { ...currentState.rowGrouping } };
  }
  if (currentState.sorting?.sortModel?.length) {
    cleanState = { ...cleanState, sorting: { ...currentState.sorting } };
  }
  if (currentState.cellSelection) {
    cleanState = { ...cleanState, cellSelection: { ...currentState.cellSelection } };
  }
  if (currentState.detailPanel?.expandedRowIds) {
    cleanState = { ...cleanState, detailPanel: { ...currentState.detailPanel } };
  }
  if (currentState.preferencePanel?.open) {
    cleanState = { ...cleanState, preferencePanel: { ...currentState.preferencePanel } };
  }
  return cleanState;
};
