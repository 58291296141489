/* tslint:disable */
/* eslint-disable */
/**
 * Dev Tools
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IImageUploadPostResponseST
 */
export interface IImageUploadPostResponseST {
  /**
   *
   * @type {string}
   * @memberof IImageUploadPostResponseST
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof IImageUploadPostResponseST
   */
  processed_items: number;
  /**
   *
   * @type {boolean}
   * @memberof IImageUploadPostResponseST
   */
  more_items_available: boolean;
}
/**
 *
 * @export
 * @interface ITaskExecutorCSFAPostResponseST
 */
export interface ITaskExecutorCSFAPostResponseST {
  /**
   *
   * @type {string}
   * @memberof ITaskExecutorCSFAPostResponseST
   */
  Response: string;
}
/**
 *
 * @export
 * @interface ITaskExecutorNonCSFAPostResponseST
 */
export interface ITaskExecutorNonCSFAPostResponseST {
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorNonCSFAPostResponseST
   */
  num_executed_tasks?: number;
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorNonCSFAPostResponseST
   */
  remaining_tasks?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ITaskExecutorNonCSFAPostResponseST
   */
  results?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorNonCSFAPostResponseST
   */
  system_id: number;
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorNonCSFAPostResponseST
   */
  total_num_task_requests: number;
}
/**
 *
 * @export
 * @interface ITaskExecutorPostRequestParamsST
 */
export interface ITaskExecutorPostRequestParamsST {
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorPostRequestParamsST
   */
  desired_num_task_results?: number;
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorPostRequestParamsST
   */
  issue_rate?: number;
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorPostRequestParamsST
   */
  unreachable_rate?: number;
  /**
   *
   * @type {number}
   * @memberof ITaskExecutorPostRequestParamsST
   */
  num_locations_to_simulate?: number;
}
/**
 *
 * @export
 * @interface ITaskExecutorPostRequestST
 */
export interface ITaskExecutorPostRequestST {
  /**
   *
   * @type {ITaskExecutorPostRequestParamsST}
   * @memberof ITaskExecutorPostRequestST
   */
  params?: ITaskExecutorPostRequestParamsST;
}
/**
 * @type ITaskExecutorPostResponseST
 * @export
 */
export type ITaskExecutorPostResponseST =
  | ITaskExecutorCSFAPostResponseST
  | ITaskExecutorNonCSFAPostResponseST;

/**
 * DevToolsApi - axios parameter creator
 * @export
 */
export const DevToolsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Triggers the mock image uploader.
     * @summary Triggers the mock image uploader.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imagesUpload: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('imagesUpload', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/image-upload`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Run task executor.
     * @summary Run task executor.
     * @param {string} systemIdStr
     * @param {ITaskExecutorPostRequestST} iTaskExecutorPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runTaskExecutor: async (
      systemIdStr: string,
      iTaskExecutorPostRequestST: ITaskExecutorPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('runTaskExecutor', 'systemIdStr', systemIdStr);
      // verify required parameter 'iTaskExecutorPostRequestST' is not null or undefined
      assertParamExists(
        'runTaskExecutor',
        'iTaskExecutorPostRequestST',
        iTaskExecutorPostRequestST,
      );
      const localVarPath = `/{system_id_str}/mockups/task-executor`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iTaskExecutorPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DevToolsApi - functional programming interface
 * @export
 */
export const DevToolsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DevToolsApiAxiosParamCreator(configuration);
  return {
    /**
     * Triggers the mock image uploader.
     * @summary Triggers the mock image uploader.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async imagesUpload(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IImageUploadPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.imagesUpload(systemIdStr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Run task executor.
     * @summary Run task executor.
     * @param {string} systemIdStr
     * @param {ITaskExecutorPostRequestST} iTaskExecutorPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async runTaskExecutor(
      systemIdStr: string,
      iTaskExecutorPostRequestST: ITaskExecutorPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ITaskExecutorPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.runTaskExecutor(
        systemIdStr,
        iTaskExecutorPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DevToolsApi - factory interface
 * @export
 */
export const DevToolsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DevToolsApiFp(configuration);
  return {
    /**
     * Triggers the mock image uploader.
     * @summary Triggers the mock image uploader.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imagesUpload(systemIdStr: string, options?: any): AxiosPromise<IImageUploadPostResponseST> {
      return localVarFp
        .imagesUpload(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Run task executor.
     * @summary Run task executor.
     * @param {string} systemIdStr
     * @param {ITaskExecutorPostRequestST} iTaskExecutorPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runTaskExecutor(
      systemIdStr: string,
      iTaskExecutorPostRequestST: ITaskExecutorPostRequestST,
      options?: any,
    ): AxiosPromise<ITaskExecutorPostResponseST> {
      return localVarFp
        .runTaskExecutor(systemIdStr, iTaskExecutorPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DevToolsApi - object-oriented interface
 * @export
 * @class DevToolsApi
 * @extends {BaseAPI}
 */
export class DevToolsApi extends BaseAPI {
  /**
   * Triggers the mock image uploader.
   * @summary Triggers the mock image uploader.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevToolsApi
   */
  public imagesUpload(systemIdStr: string, options?: AxiosRequestConfig) {
    return DevToolsApiFp(this.configuration)
      .imagesUpload(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Run task executor.
   * @summary Run task executor.
   * @param {string} systemIdStr
   * @param {ITaskExecutorPostRequestST} iTaskExecutorPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DevToolsApi
   */
  public runTaskExecutor(
    systemIdStr: string,
    iTaskExecutorPostRequestST: ITaskExecutorPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return DevToolsApiFp(this.configuration)
      .runTaskExecutor(systemIdStr, iTaskExecutorPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
