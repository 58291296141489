import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { FlowState } from '../../reducer/droneZonePage.model';
import { DroneZonesList } from './features/drone-zones-list/droneZonesList';
import { DroneZoneCreator } from './features/drone-zone-creator/droneZoneCreator';
import { DroneZoneForm } from './features/drone-zone-form/DroneZoneForm';
import { DroneZoneControlsProps } from './droneZoneControls.model';

import { droneZoneCommandFactory } from '../drone-zone-command/droneZoneCommandFactory';
import { createDroneZoneObject } from './features/drone-zone-form/utils/createDroneZoneObject.util';

export const DroneZoneControls = ({
  systemId,
  flightDomainId,
  flowState,
  visibleZones,
  controlledZones,
  currentlyEditingZone,
  worldBox,
  onDroneZoneCommand,
}: DroneZoneControlsProps) => {
  const editedDroneZone =
    currentlyEditingZone ?? createDroneZoneObject({ type: DroneZoneTypes.controlledZone });

  switch (flowState) {
    case FlowState.LOADING:
    case FlowState.LIST:
    case FlowState.DELETE:
      return (
        <DroneZonesList
          visibleZones={visibleZones}
          controlledZones={controlledZones}
          isLoading={flowState === FlowState.LOADING}
          onDroneZoneCommand={onDroneZoneCommand}
        />
      );
    case FlowState.DRAW:
      return (
        <DroneZoneCreator
          onCancel={() => onDroneZoneCommand(droneZoneCommandFactory.createCancelCreatingCommand())}
        />
      );
    case FlowState.CREATE:
      return (
        <DroneZoneForm
          flightDomainId={flightDomainId}
          systemId={systemId}
          droneZone={editedDroneZone}
          worldBox={worldBox}
          onUpdate={(droneZone) =>
            onDroneZoneCommand(droneZoneCommandFactory.createUpdateCommand(droneZone))
          }
          onCancel={(droneZone) =>
            onDroneZoneCommand(droneZoneCommandFactory.createCancelEditingCommand(droneZone))
          }
          onSubmit={(droneZone) =>
            onDroneZoneCommand(droneZoneCommandFactory.createSubmitCommand(droneZone))
          }
        />
      );
    default:
      return null;
  }
};
