// initial state and actions
import { IUserLevelData } from './IUserLevelStore';
import { UserLevelAction, UserLevelActionNames } from './userLevelActions';
import { initialUserLevelState } from './userLevelInitialState';

export const UserLevelReducer = (
  state: IUserLevelData = initialUserLevelState,
  action: UserLevelAction,
): IUserLevelData => {
  switch (action.type) {
    case UserLevelActionNames.SET_USER_DATA:
      return {
        ...state,
        isAuth: Boolean(action.payload.isAuth),
        username: action.payload.username!,
        usernameHashed: action.payload.usernameHashed!,
        isMfaAuth: action.payload.isMfaAuth!,
        isDataLoaded: true,
      };

    case UserLevelActionNames.SIGNOUT:
      return initialUserLevelState;

    case UserLevelActionNames.AUTH_PROCESS:
      return {
        ...state,
        authProcess: action.payload,
      };

    case UserLevelActionNames.AUTH_DETAILS:
      return {
        ...state,
        username: action.payload.username!,
        details: {
          attrName: action.payload.attrName!,
          challengeName: action.payload.challenge_name!,
        },
      };

    case UserLevelActionNames.CLEAR_AUTH_PROCESS_DETAILS:
      return {
        ...state,
        authProcess: false,
        details: {
          attrName: '',
          challengeName: null,
        },
      };

    case UserLevelActionNames.SET_WEB_SOCKET_AUTHORIZED:
      return {
        ...state,
        isWebSocketAuthorized: action.payload,
      };

    default:
      return state;
  }
};
