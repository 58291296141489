import { WebSocketClient } from 'shared/web-socket/web-socket.model';
import { IGetFlightAreasResponseST } from 'codegen/flight_areas';
import { FlightAreasDTO } from '../model/flightAreas.model';
import { subscribers } from './droneZonesSubscribers';

export const subscribeToFlightAreas = (
  flightDomainId: string,
  addFlightAreas: (flightAreaDTO: FlightAreasDTO) => void,
  socket: WebSocketClient,
) => {
  socket.subscribe({
    channel: subscribers.flightAreas.channel,
    delegate: subscribers.flightAreas.delegate,
    onMessage(data: IGetFlightAreasResponseST): void {
      if (flightDomainId === data.flight_domain_id) {
        addFlightAreas(data.flight_areas);
      }
    },
  });
};
