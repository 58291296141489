import { notEver } from 'common/functions/otherFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { MapState } from './3DmapState';
import { getInitialMapOptions, initialMapOptions, MapOptions } from './MapOptionsState';
import { MapRoot, NodeST } from '../MapContainer.model';

export type MapAction =
  // map
  | {
      type: 'setMap';
      payload: { mapData: MapRoot | NodeST | null; flightDomainName?: string; mapVersion?: string };
    }
  | {
      type: 'removeMap';
    }
  // options
  | { type: 'toggleOptionsModal' }
  | { type: 'setOptions'; payload: MapOptions }
  // camera
  | { type: 'setCanvasProportion'; payload: [number, number] };

const lp = getLogPrefixForType('STORE', 'mapReducer');

export const mapReducer = (state: MapState, action: MapAction): MapState => {
  console.debug(lp, 'received action', action);

  switch (action.type) {
    case 'setMap': {
      const { mapData, mapVersion, flightDomainName } = action.payload;
      return {
        ...state,
        map: mapData,
        mapVersion,
        flightDomainName,
        options: mapData
          ? getInitialMapOptions([mapData.box[0], mapData.box[3], mapData.box[1], mapData.box[4]])
          : state.options,
      };
    }
    case 'removeMap': {
      return {
        ...state,
        map: null,
        mapVersion: undefined,
        flightDomainName: undefined,
        options: initialMapOptions,
      };
    }
    case 'toggleOptionsModal': {
      return {
        ...state,
        showOptionsModal: !state.showOptionsModal,
      };
    }
    case 'setOptions': {
      return { ...state, options: { ...state.options, ...action.payload } };
    }
    case 'setCanvasProportion': {
      const [width, height] = action.payload;
      const proportion = width / height;
      return { ...state, canvasSize: action.payload, canvasProportion: proportion };
    }
    default:
      notEver(action);
      return state;
  }
};
