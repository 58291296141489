import { createSvgIcon } from '@mui/material/utils';
import { Box } from 'components/common/Box';

interface IIconProps {
  theme: any;
  color?: any;
  active: any;
  fontSize?: any;
}

const DroneArrowDownIcon = ({ theme, color, active, fontSize }: IIconProps) => {
  const Icon = createSvgIcon(
    <svg width="40" height="70" viewBox="0 0 55 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6464 24.5217C22.8416 24.3264 22.8416 24.0099 22.6464 23.8146L22.1194 23.2876C21.9243 23.0925 21.6079 23.0924 21.4126 23.2873L17.7282 26.9652C17.413 27.2797 16.8749 27.0566 16.8749 26.6113V19.168C16.8749 18.8918 16.6511 18.668 16.3749 18.668H15.6249C15.3488 18.668 15.1249 18.8918 15.1249 19.168V26.6083C15.1249 27.0539 14.5859 27.2769 14.2711 26.9615L10.596 23.2798C10.4008 23.0843 10.0839 23.0841 9.88855 23.2795L9.35348 23.8146C9.15822 24.0099 9.15822 24.3264 9.35348 24.5217L15.6464 30.8146C15.8416 31.0099 16.1582 31.0099 16.3535 30.8146L22.6464 24.5217Z"
        fill={color || (active ? theme.palette.primary.light : theme.palette.grey[600])}
      />
      <path
        d="M13.3333 1.14667V1.51468C13.3333 1.78868 13.1257 2.0181 12.8531 2.04537L6.71974 2.6587C6.68444 2.66223 6.64889 2.66223 6.6136 2.6587L0.480265 2.04537C0.207624 2.0181 0 1.78868 0 1.51468V1.14667C0 0.872666 0.207625 0.643244 0.480266 0.61598L6.6136 0.00264683C6.64889 -0.000882285 6.68444 -0.000882271 6.71974 0.00264684L12.8531 0.61598C13.1257 0.643244 13.3333 0.872666 13.3333 1.14667Z"
        fill={color || (active ? theme.palette.primary.light : theme.palette.grey[600])}
      />
      <path
        d="M32 1.14667V1.51468C32 1.78868 31.7924 2.0181 31.5197 2.04537L25.3864 2.6587C25.3511 2.66223 25.3156 2.66223 25.2803 2.6587L19.1469 2.04537C18.8743 2.0181 18.6667 1.78868 18.6667 1.51468V1.14667C18.6667 0.872666 18.8743 0.643244 19.1469 0.61598L25.2803 0.00264683C25.3156 -0.000882285 25.3511 -0.000882271 25.3864 0.00264684L31.5197 0.61598C31.7924 0.643244 32 0.872666 32 1.14667Z"
        fill={color || (active ? theme.palette.primary.light : theme.palette.grey[600])}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3.99731C5.63181 3.99731 5.33333 4.29579 5.33333 4.66398V6.66398C4.59695 6.66398 4 7.26093 4 7.99731C4 8.73369 4.59695 9.33065 5.33333 9.33065H8.33919C8.67347 9.80989 9.05157 10.2563 9.46769 10.664C8.03773 12.0653 7.05671 13.923 6.76034 16.0013C6.65638 16.7303 7.26334 17.3306 7.99972 17.3306C8.7361 17.3306 9.31914 16.7277 9.46469 16.0058C9.76412 14.5207 10.5571 13.2144 11.6652 12.2653C12.9604 12.9457 14.435 13.3306 15.9996 13.3306C17.5642 13.3306 19.0389 12.9456 20.3342 12.2652C21.4423 13.2143 22.2353 14.5207 22.5347 16.0058C22.6803 16.7277 23.2633 17.3306 23.9997 17.3306C24.7361 17.3306 25.3431 16.7303 25.2391 16.0013C24.9427 13.9229 23.9616 12.0652 22.5316 10.6639C22.9477 10.2562 23.3258 9.80984 23.66 9.33065H26.6667C27.403 9.33065 28 8.73369 28 7.99731C28 7.26093 27.403 6.66398 26.6667 6.66398V4.66398C26.6667 4.29579 26.3682 3.99731 26 3.99731H24.6667C24.2985 3.99731 24 4.29579 24 4.66398V6.66398H8V4.66398C8 4.29579 7.70152 3.99731 7.33333 3.99731H6Z"
        fill={color || (active ? theme.palette.primary.light : theme.palette.grey[600])}
      />
    </svg>,
    'DroneArrowDownIcon',
  );
  return (
    <Box>
      <Icon sx={{ fontSize }} />
    </Box>
  );
};

export default DroneArrowDownIcon;
