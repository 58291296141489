import globalAxios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import * as rax from 'retry-axios';
import pako from 'pako';
import isFunction from 'lodash/isFunction';
import { TokenManager } from 'common/tokenManager';

// helpers and functions
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { AUTH_PAGES_URLS } from 'common/pages';
import {
  tokenExpiryRequestInterceptor,
  badTokenResponseInterceptor,
} from './interceptorsFunctions';

export interface IAxiosRequestConfig extends InternalAxiosRequestConfig<any> {
  raxConfig?: rax.RetryConfig & { retryRequest?: boolean };
}

const tokenManager = TokenManager.getInstance();

const getTransformRequest = () => {
  if (Array.isArray(globalAxios.defaults.transformRequest)) {
    return globalAxios.defaults.transformRequest.concat((data: any, headers: any) => {
      if (typeof data === 'string' && data.length > Infinity) {
        headers['Content-Encoding'] = 'gzip';
        return pako.gzip(data);
      }
      return data;
    });
  }
  if (globalAxios.defaults.transformRequest !== undefined) {
    return globalAxios.defaults.transformRequest;
  }
  return undefined;
};

export const config = {
  baseURL: `${import.meta.env.VITE_APP_API_ENDPOINT}/api/1.0/`,
  transformRequest: getTransformRequest(),
};

/**
 * Custom axios instance
 */
export const instance: AxiosInstance = globalAxios.create(config);

const idToken = tokenManager.getIdToken();
if (idToken) {
  instance.defaults.headers.common.Authorization = idToken;
}

/**
 * Refresh token function invoked with default globalAxios to prevent
 * infinite loop using
 * @returns promise
 */
export const refreshToken = (interceptedRequestUrl?: string) => {
  const lp = getLogPrefixForType('FUNCTION', 'refreshToken');
  const idToken = tokenManager.getIdToken();
  const refresh_token = tokenManager.getRefreshToken();
  const username = tokenManager.hashedUsernameFromAccessToken();

  console.debug(lp, 'refresh token request invoked on request:', {
    url: interceptedRequestUrl,
    route: window.location.pathname,
  });

  if (!username || !refresh_token) {
    console.trace(lp, 'username or refresh_token are undefined', {
      username,
      refresh_token,
      route: window.location.pathname,
    });
    window.location.pathname = AUTH_PAGES_URLS.SIGNOUT;
    return null;
  }

  return globalAxios
    .post(
      `${config.baseURL}refresh-token`,
      { username, refresh_token },
      { headers: { Authorization: idToken } },
    )
    .then((r) => {
      if (r.data.access_token) {
        tokenManager.setNewAccessAndIdToken(r.data);
      }
      console.debug(lp, 'refresh token request finished');
      return r;
    });
};

/**
 * Request interceptor will check for for token expiration before sending
 * the request to the server. Refresh the token if its expired and recall
 * sent request
 */
instance.interceptors.request.use(tokenExpiryRequestInterceptor);

/**
 * Response interceptor will check if response has error 401
 * which indicate to token expiration before it get to client
 * with .then() and .catch(). It will refresh the token, and
 * resend request
 */
instance.interceptors.response.use(
  async (response: AxiosResponse) => {
    // Here we intercept signin and auth-challenge (ex: mfa authentication) request
    // and override instance headers with fresh token
    if (response.data.access_token && response.data.id_token) {
      instance.defaults.headers.common.Authorization = response.data.id_token;
    }
    return response;
  },
  async (error) => badTokenResponseInterceptor(error),
);

const apiServices = (raxConfig?: rax.RetryConfig | undefined) => {
  instance.defaults.raxConfig = {
    // These are the default values which are applied to network requests if none
    // of the below configurations are explicitly defined:
    //
    // retry: 3,
    // noResponseRetries: 2,
    // retryDelay: 100,
    // httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT'],
    // statusCodesToRetry: [[100, 199], [429, 429], [500, 599]],
    // backoffType: 'exponential',
    //
    // Below, we are setting some new default values (retry, noResponseRetries, statusCodesToRetry)
    // for cases when no raxConfig is passed to an axios instance in services functions.
    // For example, retry for a service with no raxConfig passed will fallback to 0,
    // meaning it will never retry once it fails

    // How many times to retry failed network request
    retry: raxConfig?.retry || 0,

    // How many times to retry failed network request that don't return a response (ENOTFOUND, ETIMEDOUT, etc)
    noResponseRetries: raxConfig?.noResponseRetries || 0,

    // Milliseconds to delay at first. Only considered when backoffType is 'static'
    retryDelay: raxConfig?.retryDelay || 100,

    // HTTP methods to automatically retry
    httpMethodsToRetry: raxConfig?.httpMethodsToRetry || [
      'GET',
      'HEAD',
      'OPTIONS',
      'DELETE',
      'PUT',
    ],

    // The response status codes to retry
    statusCodesToRetry: raxConfig?.statusCodesToRetry || [
      [100, 199],
      [408, 408],
      [429, 429],
      [500, 599],
    ],

    // If you are using a non static instance of Axios you need
    // to pass that instance here (in our case it is literally called instance)
    instance,

    // Set the backoff type. Options are 'exponential' (default), 'static' or 'linear'
    backoffType: raxConfig?.backoffType || 'exponential',

    // A callback function invoked when a retry is happening
    onRetryAttempt: (err) => {
      isFunction(raxConfig?.onRetryAttempt) && raxConfig?.onRetryAttempt(err);
    },
  };

  rax.attach(instance);

  return instance;
};

export default apiServices;
