export type WebSocketSubscriber<T = any> = {
  channel: string;
  delegate: string;
  onMessage: (data: T, channel: string) => void;
};

export type WebSocketSubscription = Pick<WebSocketSubscriber, 'channel' | 'delegate'>;

export type WebSocketClient = {
  connect: () => void;
  disconnect: () => void;
  subscribe: (subscriber: WebSocketSubscriber) => void;
  unsubscribe: (unsubscriber: WebSocketSubscription) => void;
  unsubscribeAll: <SubscriberGeneric extends string>(
    unsubscriber: Record<SubscriberGeneric, WebSocketSubscription>,
  ) => void;
};

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
export const enum WebSocketReadyState {
  Open = 1,
}
