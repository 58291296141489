import { loadSinceInterval } from 'common/functions/dateTimeFunctions';
import { intervalSelectorType } from './selectTimeInterval.model';

export const selectTimeIntervalOptions: {
  value: loadSinceInterval | intervalSelectorType;
  label: string;
}[] = [
  { value: 'last week', label: 'last week' },
  { value: 'last two weeks', label: 'last two weeks' },
  { value: 'last month', label: 'last month' },
  {
    value: 'all',
    label: 'all',
  },
  { value: 'by date', label: 'by date' },
  { value: 'by interval', label: 'by interval' },
];
