/* tslint:disable */
/* eslint-disable */
/**
 * SuperSet
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ICreateSystemSpecificSuperSetGuestToken500ResponseAllOfST
 */
export interface ICreateSystemSpecificSuperSetGuestToken500ResponseAllOfST {
  /**
   *
   * @type {object}
   * @memberof ICreateSystemSpecificSuperSetGuestToken500ResponseAllOfST
   */
  errors?: object;
}
/**
 *
 * @export
 * @interface ICreateSystemSpecificSuperSetGuestToken500ResponseST
 */
export interface ICreateSystemSpecificSuperSetGuestToken500ResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof ICreateSystemSpecificSuperSetGuestToken500ResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof ICreateSystemSpecificSuperSetGuestToken500ResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof ICreateSystemSpecificSuperSetGuestToken500ResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof ICreateSystemSpecificSuperSetGuestToken500ResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof ICreateSystemSpecificSuperSetGuestToken500ResponseST
   */
  report_url: string | null;
  /**
   *
   * @type {object}
   * @memberof ICreateSystemSpecificSuperSetGuestToken500ResponseST
   */
  errors: object;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IGetSuperSetConfigResponseST
 */
export interface IGetSuperSetConfigResponseST {
  /**
   * SuperSet host URL.
   * @type {string}
   * @memberof IGetSuperSetConfigResponseST
   */
  base_url?: string;
  /**
   * Facility specific dashboard ID.
   * @type {string}
   * @memberof IGetSuperSetConfigResponseST
   */
  facility_dashboard_id?: string;
  /**
   * Client dashboard ID.
   * @type {string}
   * @memberof IGetSuperSetConfigResponseST
   */
  client_dashboard_id?: string;
}
/**
 *
 * @export
 * @interface ISuperSetGuestTokenResponseST
 */
export interface ISuperSetGuestTokenResponseST {
  /**
   * SuperSet guest token, used for embeded dashboard.
   * @type {string}
   * @memberof ISuperSetGuestTokenResponseST
   */
  guest_token: string;
}

/**
 * SuperSetApi - axios parameter creator
 * @export
 */
export const SuperSetApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create SuperSet guest token for client dashboard, this token has access to all facilities that the current user has access to.
     * @summary Create SuperSet guest token for client dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSuperSetGuestToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/superset/guest-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create SuperSet guest token, the guest token has access to only the system_id.
     * @summary Create SuperSet guest token for facility dashboard.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSystemSpecificSuperSetGuestToken: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createSystemSpecificSuperSetGuestToken', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/superset/guest-token`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get SuperSet configurations to embed dashboard.
     * @summary Get SuperSet configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuperSetSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/superset/config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SuperSetApi - functional programming interface
 * @export
 */
export const SuperSetApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SuperSetApiAxiosParamCreator(configuration);
  return {
    /**
     * Create SuperSet guest token for client dashboard, this token has access to all facilities that the current user has access to.
     * @summary Create SuperSet guest token for client dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSuperSetGuestToken(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISuperSetGuestTokenResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSuperSetGuestToken(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create SuperSet guest token, the guest token has access to only the system_id.
     * @summary Create SuperSet guest token for facility dashboard.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSystemSpecificSuperSetGuestToken(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISuperSetGuestTokenResponseST>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createSystemSpecificSuperSetGuestToken(
          systemIdStr,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get SuperSet configurations to embed dashboard.
     * @summary Get SuperSet configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSuperSetSettings(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetSuperSetConfigResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSuperSetSettings(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SuperSetApi - factory interface
 * @export
 */
export const SuperSetApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SuperSetApiFp(configuration);
  return {
    /**
     * Create SuperSet guest token for client dashboard, this token has access to all facilities that the current user has access to.
     * @summary Create SuperSet guest token for client dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSuperSetGuestToken(options?: any): AxiosPromise<ISuperSetGuestTokenResponseST> {
      return localVarFp
        .createSuperSetGuestToken(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create SuperSet guest token, the guest token has access to only the system_id.
     * @summary Create SuperSet guest token for facility dashboard.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSystemSpecificSuperSetGuestToken(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<ISuperSetGuestTokenResponseST> {
      return localVarFp
        .createSystemSpecificSuperSetGuestToken(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get SuperSet configurations to embed dashboard.
     * @summary Get SuperSet configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuperSetSettings(options?: any): AxiosPromise<IGetSuperSetConfigResponseST> {
      return localVarFp.getSuperSetSettings(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * SuperSetApi - object-oriented interface
 * @export
 * @class SuperSetApi
 * @extends {BaseAPI}
 */
export class SuperSetApi extends BaseAPI {
  /**
   * Create SuperSet guest token for client dashboard, this token has access to all facilities that the current user has access to.
   * @summary Create SuperSet guest token for client dashboard.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuperSetApi
   */
  public createSuperSetGuestToken(options?: AxiosRequestConfig) {
    return SuperSetApiFp(this.configuration)
      .createSuperSetGuestToken(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create SuperSet guest token, the guest token has access to only the system_id.
   * @summary Create SuperSet guest token for facility dashboard.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuperSetApi
   */
  public createSystemSpecificSuperSetGuestToken(systemIdStr: string, options?: AxiosRequestConfig) {
    return SuperSetApiFp(this.configuration)
      .createSystemSpecificSuperSetGuestToken(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get SuperSet configurations to embed dashboard.
   * @summary Get SuperSet configurations.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuperSetApi
   */
  public getSuperSetSettings(options?: AxiosRequestConfig) {
    return SuperSetApiFp(this.configuration)
      .getSuperSetSettings(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
