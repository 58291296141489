import { Typography } from '@mui/material';
import { pageHeaderStyles } from '../styles';

export const PageHeaderSectionTitle = ({
  title,
  currentFacilityName,
  isMultiFacility,
  showFacilityName,
}: {
  title: string;
  currentFacilityName: string;
  isMultiFacility: boolean;
  showFacilityName: boolean;
}) => {
  const { classes } = pageHeaderStyles();

  return (
    <Typography
      data-testid="c-page-header-title"
      className={classes.bold}
      color="secondary"
      variant="h4"
    >
      {showFacilityName && isMultiFacility && `${currentFacilityName} - `}
      {title}
    </Typography>
  );
};
