import { DroneZoneCommandFactory } from './droneZoneCommandFactory.type';
import { createDroneZoneCloneCommand } from './commands/createDroneZoneCloneCommand';
import { createDroneZoneSelectCommand } from './commands/createDroneZoneSelectCommand';
import { createDroneZoneEditCommand } from './commands/createDroneZoneEditCommand';
import { createDroneZoneDeleteCommand } from './commands/createDroneZoneDeleteCommand';
import { createDroneZoneCreateCommand } from './commands/createDroneZoneCreateCommand';
import { createDroneZoneUpdateCommand } from './commands/createDroneZoneUpdateCommand';
import { createDroneZoneCancelCreatingCommand } from './commands/createDroneZoneCancelCreatingCommand';
import { createDroneZoneCancelEditingCommand } from './commands/createDroneZoneCancelEditingCommand';
import { createDroneZoneCancelDeletingCommand } from './commands/createDroneZoneCancelDeletingCommand';
import { createDroneZoneSubmitCommand } from './commands/createDroneZoneSubmitCommand';
import { createDroneZoneDeletedCommand } from './commands/createDroneZoneDeletedCommand';

export const droneZoneCommandFactory: DroneZoneCommandFactory = {
  createCloneCommand: createDroneZoneCloneCommand,
  createSelectCommand: createDroneZoneSelectCommand,
  createEditCommand: createDroneZoneEditCommand,
  createDeleteCommand: createDroneZoneDeleteCommand,
  createDeletedCommand: createDroneZoneDeletedCommand,
  createCreateCommand: createDroneZoneCreateCommand,
  createUpdateCommand: createDroneZoneUpdateCommand,
  createCancelCreatingCommand: createDroneZoneCancelCreatingCommand,
  createCancelEditingCommand: createDroneZoneCancelEditingCommand,
  createCancelDeletingCommand: createDroneZoneCancelDeletingCommand,
  createSubmitCommand: createDroneZoneSubmitCommand,
};
