/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';

const lp = getLogPrefixForType('HOOK', 'useEffectOnce');

export const useEffectOnce = (name: string, effect: () => any) => {
  const effectFn = useRef(effect);
  const destroyFn = useRef<() => any>();
  const effectCalled = useRef<false | string>(false);
  const rendered = useRef(false);
  const [count, refresh] = useState(0);

  if (effectCalled.current) {
    rendered.current = true;
  }

  console.debug(
    lp,
    `called for ${name} which is ${
      rendered.current ? 'already' : 'not'
    } rendered and have its useEffect called ${count}`,
  );

  useEffect(() => {
    if (effectCalled.current !== name) {
      destroyFn.current = effectFn.current();
      effectCalled.current = name;
    }

    refresh(count + 1);

    console.debug(lp, `effect made for ${name}, ${count} times`);

    return () => {
      if (rendered.current === false) {
        console.debug(lp, `NOT running effect destroyer for ${name} as it is not yet rendered`);
        return;
      }
      if (destroyFn.current) {
        destroyFn.current();
        effectCalled.current = false;
        rendered.current = false;
        console.debug(lp, `effect destroyer done for ${name}`);
      }
    };
  }, []);
};
