import { LocalStore } from 'common/functions/storageFunctions';
import { OpenFlyZonesApi, Configuration } from 'codegen/open_fly_zone';

export const initOpenFlyZoneService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new Configuration({ apiKey: idToken });
  const apiService = new OpenFlyZonesApi(config);

  return apiService;
};
