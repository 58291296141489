import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import EnhancedTable from 'components/EnhancedTable';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { ToolbarAction } from 'components/EnhancedTable/types/toolbar';
import { changeTab } from 'common/functions/navigationFunctions';
import { ILocationReportData } from 'pages/WarehouseStatus/warehouseStatusFunctions';
import { useSettingsStore } from 'store/Settings/settingsStore';
import { TableTabData } from '../../utils/get-tab-data/TabData';
import { LocationProps } from '../../Reports.model';
import { getTabData } from './utils/getTabData';
import { currentTabDefault } from '../report/utils/GetTabData';

export const ReportsGrid = ({
  systemId,
  allTabData,
  isLoading,
  currentActiveSpinner,
  refreshData,
  activeTab,
  setActiveTab,
}: {
  systemId: string;
  allTabData: { [key: string]: TableTabData };
  isLoading: boolean | number;
  currentActiveSpinner: {
    [x: string]: boolean;
  };
  refreshData?: { refreshData: () => void };
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation() as LocationProps;

  const currentTabData = allTabData[activeTab] ? allTabData[activeTab] : currentTabDefault;

  const { REPORTS_TABLE_TABS } = useSettingsStore();

  const onRowClick = useCallback(
    (e: KeyboardEvent, row: ILocationReportData) => {
      const openInNewTab = e.ctrlKey || e.metaKey;

      if (openInNewTab && row.allowRedirection) {
        // used to open pages in new tab
        window.open(`/${systemId}${INVENTORY_PAGES_URLS.REPORT}/${row.id}`, '_blank');
      } else if (row.allowRedirection) {
        // used to open pages in the same tab
        navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORT}/${row.id}`);
      }
    },
    [navigate, systemId],
  );

  /**
   * Tab switch handler
   * @param activeTab active tab index
   * @returns changeTab function execution
   */
  const tabSwitchHandler = (activeTab: number) =>
    changeTab({
      tabValue: activeTab,
      navigate,
      state: location.state,
      setActiveTab,
    });

  const tabs = getTabData(allTabData, REPORTS_TABLE_TABS);

  return (
    <EnhancedTable
      tableFor={currentTabData.tableFor}
      tableTitle={currentTabData.tableTitle}
      tableSubtitle={currentTabData.tableSubtitle}
      headCells={currentTabData.headCells}
      rows={currentTabData.rows}
      refreshData={refreshData}
      tabs={tabs}
      tabStatus={true}
      changeTab={tabSwitchHandler}
      startingTab={activeTab}
      currentActiveSpinner={currentActiveSpinner}
      initialOrder={tabs ? tabs[activeTab]?.order : undefined}
      initialOrderBy={tabs ? tabs[activeTab]?.orderBy : undefined}
      toolbarAction={currentTabData.toolbarAction as ToolbarAction}
      isLoading={isLoading}
      onRowClick={onRowClick}
    />
  );
};
