import { Moment } from 'moment';

export enum CommissioningTaskState {
  CREATED = 'CREATED',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  ABORTED = 'ABORTED',
}

export type CommissioningTask = {
  id: string;
  state: CommissioningTaskState;
  name: string;
  startedAt?: Moment;
  totalLocations: number;
  finishedLocations: number;
  finishedAt?: Moment;
  createdAt?: Moment;
  updatedAt?: Moment;
};
