import { DroneZoneMapLegendItemEntries } from 'shared/map/features/drone-zone-map-legend/models/DroneZoneLegend.model';

export const mapLegendPlanning: DroneZoneMapLegendItemEntries = [
  [
    {
      type: 'drone-zone',
      subType: 'controlled-zone-active',
      label: 'Controlled zone (flight permitted)',
    },
    {
      type: 'drone-zone',
      subType: 'controlled-zone-inactive',
      label: 'Controlled zone (flight not permitted)',
    },
    {
      type: 'drone-zone',
      subType: 'no-fly-zone-active',
      label: 'No-fly zone (flight prohibited)',
    },
    {
      type: 'drone-zone',
      subType: 'no-fly-zone-inactive',
      label: 'No-fly zone (inactive)',
    },
    {
      type: 'drone-zone',
      subType: 'open-fly-zone',
      label: 'Open-fly zone',
    },
  ],
  [
    {
      type: 'drone-group',
      label: 'Overlapping controlled zones',
    },
  ],
];

export const mapLegendFlightArea: DroneZoneMapLegendItemEntries = [
  [
    {
      type: 'drone-zone',
      subType: 'facility-flight-area-flight-permitted',
      label: 'Flight permitted',
    },
    {
      type: 'drone-zone',
      subType: 'facility-flight-area-flight-not-permitted',
      label: 'Flight not permitted',
    },
  ],
];
