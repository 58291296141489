import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { setFilterOnColumns } from './setFilterOnColumns';
import { WAREHOUSE_STATUS_TABS } from '../models/WarehouseStatusXTabs.model';

export const SearchField = ({
  gridApiRef,
  columns,
  activeTab,
}: {
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  columns: string[];
  activeTab: number;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState('');

  const resetFilters = useCallback(() => {
    const { gridState } = WAREHOUSE_STATUS_TABS[activeTab];
    gridApiRef.current.restoreState({ ...gridState });
  }, [activeTab, gridApiRef]);

  useEffect(() => {
    setValue('');
    resetFilters();
  }, [activeTab, resetFilters]);

  const handleClear = () => {
    setValue('');
    resetFilters();
  };

  const updateTerm = (term: string) => {
    debounce(() => {
      if (term === '') {
        resetFilters();
      } else {
        setFilterOnColumns(gridApiRef, term, columns);
      }
    }, 200)();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setValue(term);
    updateTerm(term);
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      color="primary"
      aria-label="filter"
      data-testid="c-search-filter"
      onChange={handleChange}
      value={value}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="c-clear-search-filter"
              aria-label="clear filter"
              onClick={handleClear}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
