import { Navigate, useSearchParams } from 'react-router-dom';
import React from 'react';

const ARTFederatedWrapper = React.lazy(() =>
  import('remote_art/ARTFederatedWrapper').catch((e) => ({
    default: () => <div>ERROR LOADING REMOTE</div>,
  })),
);

export const ArticleInsightsRoute = () => {
  const [searchParams] = useSearchParams();
  const isARTEnabled: boolean = Boolean(
    sessionStorage.getItem('isARTEnabled') ?? searchParams.get('enable-art'),
  );
  if (isARTEnabled) {
    sessionStorage.setItem('isARTEnabled', 'true');
  }

  return isARTEnabled ? (
    <React.Suspense fallback={<div>Loading...</div>}>
      <ARTFederatedWrapper />
    </React.Suspense>
  ) : (
    <Navigate to="/not-found" />
  );
};
