import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { noFlyZoneOpacity } from 'shared/map/defaults/noFlyZoneOpacity.default';

export const useStyles = makeStyles()(() => ({
  legendIcon: {
    width: '1em',
    height: '1em',
  },
  typeControlledZoneActive: {
    backgroundColor: mapStyle.controlledZones.active.backgroundColor.primary,
  },
  typeControlledZoneInactive: {
    backgroundColor: mapStyle.controlledZones.inactive.backgroundColor.primary,
    border: `1px solid ${mapStyle.controlledZones.inactive.borderColor.secondary}`,
  },
  typeNoFlyZoneActive: {
    backgroundColor: alpha(
      mapStyle.noFlyZone.active.backgroundColor.primary,
      noFlyZoneOpacity.active,
    ),
  },
  typeNoFlyZoneInactive: {
    backgroundColor: alpha(
      mapStyle.noFlyZone.active.backgroundColor.primary,
      noFlyZoneOpacity.inactive,
    ),
  },
  typeOpenFlyZone: {
    backgroundColor: mapStyle.openFlyZones.active.backgroundColor.primary,
  },
  typeFlightAreaFlightNotPermitted: {
    backgroundColor: mapStyle.flightAreas.inactive.backgroundColor.primary,
    border: `1px solid ${mapStyle.flightAreas.inactive.borderColor.primary}`,
  },
  typeFlightAreaFlightPermitted: {
    backgroundColor: mapStyle.flightAreas.active.backgroundColor.primary,
  },
}));
