import { IDroneStatusST, IFleetVersionResponseST } from 'codegen/flight_domain';
import { IFlightDomainData, IGroundControlState } from './IGroundControlStore';

export const initialFleetOverview = {
  flight_domain_id: '',
  fleet_overview_img_url: '-',
  fleet_status: [] as IDroneStatusST[],
};

export const initialFleetVersions: IFleetVersionResponseST = {
  flight_domain_id: '',
  drone_versions: {},
};

export const initialNoFlyZones = {
  flight_domain_id: '',
  no_fly_zones_img_url: '',
  no_fly_zones: {},
};

export const initialFlightDomain: IFlightDomainData = {
  flight_domain_id: '',
  flight_domain_name: '',
  flight_domain_status: null,
  fleet_status_summary: null,
  isLoadingFleetData: false,
};

// Initial state
export const initialGroundControlState: IGroundControlState = {
  flightDomainLoaded: false,
  flightDomain: initialFlightDomain,
  fleetOverview: initialFleetOverview,
  fleetVersions: initialFleetVersions,
  isExecutingEmergencyLanding: false,
  isExecutingReturnHome: false,
  dronesFlying: false,
  webSocketError: false, // <= TBD
  flightDomainSchedule: {
    drone_operating_hours: {
      schedule: [],
    },
  },
  alertInfo: {
    main: {
      isOpened: false,
      variant: 'warning',
      message: '',
    },
    fleetCard: {
      isOpened: false,
      variant: 'warning',
      message: '',
    },
    statusCard: {
      isOpened: false,
      variant: 'warning',
      message: '',
    },
    flightHoursCard: {
      isOpened: false,
      variant: 'warning',
      message: '',
    },
  },
  activeAlertVariant: '',
};
