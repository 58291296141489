import Icon from '@mui/material/Icon';

export const MapIcon = (props: { disabled?: boolean } & JSX.IntrinsicAttributes) => (
  <Icon {...props}>
    <svg
      style={{ filter: props.disabled ? 'brightness(0.7)' : '' }}
      width="15"
      height="32"
      viewBox="0 0 15 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5833 0.5L14.45 0.525L10 2.25L5 0.5L0.3 2.08333C0.125 2.14167 0 2.29167 0 2.48333V15.0833C0 15.3167 0.183333 15.5 0.416667 15.5L0.55 15.475L5 13.75L10 15.5L14.7 13.9167C14.875 13.8583 15 13.7083 15 13.5167V0.916667C15 0.683333 14.8167 0.5 14.5833 0.5ZM10 13.8333L5 12.075V2.16667L10 3.925V13.8333Z"
        fill="white"
      />
    </svg>
  </Icon>
);
