import { includes, inRange, isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { getSanitizedInputValues } from 'common/functions/otherFunctions';
import { IVeritySlotStatusStateST } from 'codegen/location_information';

export const validateFormData = ({
  barcodeMinLength,
  barcodeMaxLength,
  barcodeInvalidLengths,
  overwriteContentType,
  overwriteBarcodes,
}: {
  barcodeMinLength?: number;
  barcodeMaxLength?: number;
  barcodeInvalidLengths?: number[];
  overwriteContentType: IVeritySlotStatusStateST;
  overwriteBarcodes: string;
}) => {
  if (overwriteContentType === IVeritySlotStatusStateST.Barcode) {
    const barcodeValid = getSanitizedInputValues(overwriteBarcodes).reduce(
      (acc, barcode) =>
        acc &&
        !includes(barcodeInvalidLengths, barcode.length) &&
        barcodeMinLength &&
        barcodeMaxLength
          ? inRange(barcode.length, barcodeMinLength, barcodeMaxLength + 1)
          : false,
      true,
    );

    if (!barcodeValid) {
      const invalidLengthWarning = !isEmpty(barcodeInvalidLengths)
        ? `Barcodes of length ${barcodeInvalidLengths} are not allowed.`
        : '';
      let snackbarMessage = '';

      if (barcodeMinLength === barcodeMaxLength) {
        snackbarMessage = `The entered barcodes must be ${barcodeMinLength} digits long. ${invalidLengthWarning}`;
      } else {
        snackbarMessage = `The entered barcodes must be between ${barcodeMinLength} and ${barcodeMaxLength} digits long. ${invalidLengthWarning}`;
      }

      enqueueSnackbar(snackbarMessage, { variant: 'error', preventDuplicate: true });

      return false;
    }
  }

  return true;
};
