export const transformApiDroneZone = <ApiZone, TransformedZone>(
  zones: { [key: string]: ApiZone },
  zoneMapperCallback: (zone: ApiZone & { id: string }) => TransformedZone,
): { [key: string]: TransformedZone } =>
  Object.keys(zones).reduce(
    (acc, zoneId) => ({
      ...acc,
      [zoneId]: zoneMapperCallback({ id: zoneId, ...zones[zoneId] }),
    }),
    {},
  );
