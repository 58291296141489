import WarehouseStore from 'store/WarehouseStore';
import { IRequestController } from 'hooks';
import { IActiveIssueCount, IWarehouseStatus } from 'interfaces';

/**
 * Get active issues count for Warehouse status card
 */
export const getActiveIssueCount = (
  systemId: string,
  requestController: IRequestController,
  setSpinner: React.Dispatch<React.SetStateAction<number>>,
  setData: React.Dispatch<React.SetStateAction<IWarehouseStatus>>,
) => {
  const { signal } = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: WarehouseStore.getActiveIssueCount,
    requestParams: [systemId, signal],
    callbackBeforeSend: () => setSpinner((repliesPending) => repliesPending + 1),
    callbackSuccess: (r: IActiveIssueCount) => setData(() => r.warehouseStatus),
    messageErrorFallback: 'The Active Issue count could not be fetched.',
    callbackFinally: () => setSpinner(0),
  });
};
