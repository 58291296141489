import LockIcon from '@mui/icons-material/Lock';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { NoAccessIcon } from '../Icons/NoAccessIcon';

type GroundControlSideIconProps = {
  isLocked: boolean;
  isInCoexistence: boolean;
  isDroneOnly: boolean;
};

export const GroundControlSideIcon = ({
  isLocked,
  isInCoexistence,
  isDroneOnly,
}: GroundControlSideIconProps) => {
  if (isLocked) {
    return (
      <LockIcon
        fontSize="small"
        sx={{
          color: 'white',
        }}
      />
    );
  }

  if (!isInCoexistence || isDroneOnly) {
    return (
      <TooltipedIcon
        data-testid="c-icon-drone-only"
        icon={<NoAccessIcon color="white" fontSize="30px" />}
        tooltip="Do not enter"
      />
    );
  }

  return (
    <TooltipedIcon
      data-testid="c-icon-coexistence"
      icon={<DirectionsWalkIcon sx={{ color: 'white', fontSize: '26px' }} />}
      tooltip="Drones are mixed with people"
    />
  );
};
