import { Typography } from '@mui/material';
import { zoneValueToLocaleFixedDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { DroneZones } from '../../../../model/drone-zones.model';
import { useStyles } from './DroneZoneDetailsPanelZoneProperties.styles';

export const DroneZoneDetailsPanelZoneProperties = ({ zone }: { zone: DroneZones }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.detailsProperties}>
      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Name:</span>
        <span>{zone.name}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Description:</span>
        <span>{zone.description}</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Bottom:</span>
        <span>{zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.minZ)}m</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Top:</span>
        <span>{zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.maxZ)}m</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Width:</span>
        <span>{zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.w)}m</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Length:</span>
        <span>{zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.l)}m</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>X-Position:</span>
        <span>{zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.minX)}m</span>
      </Typography>

      <Typography variant="body1" className={classes.detailsPropertiesItem}>
        <span>Y-Position:</span>
        <span>{zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.minY)}m</span>
      </Typography>
    </div>
  );
};
