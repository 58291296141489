import moment from 'moment';
import { IRowData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const getIsVerityDataStale = (rowData: IRowData) => {
  if (!rowData.wmsDate) {
    return false;
  }

  if (!rowData.contentFoundDate) {
    return false;
  }

  return moment(rowData.wmsDate).isAfter(moment(rowData.contentFoundDate));
};
