import { TimeRange } from '../../../../../../../model/timeRange.model';

export interface IMinutesRange {
  fromMinutes: number;
  toMinutes: number;
}

export const getMinutesFromTimeRangesAccordingToHour = (
  timeRanges: TimeRange[],
  hour: number,
): IMinutesRange[] =>
  timeRanges
    .filter((t: TimeRange) => t.fromHours === hour || t.toHours === hour)
    .map((tr: TimeRange) => {
      const minutesRange = { fromMinutes: 0, toMinutes: 60 };
      if (tr.fromHours === hour) {
        minutesRange.fromMinutes = tr.fromMinutes;
      }
      if (tr.toHours === hour) {
        minutesRange.toMinutes = tr.toMinutes;
      }

      return minutesRange;
    });
