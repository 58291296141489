import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  inputWrapper: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 0,
    },
  },
  inputField: {
    width: '49%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
  },
  phoneInputHolder: {
    '& .flag-dropdown': {
      display: 'none',
    },
    '& .special-label': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '0.8rem',
      marginLeft: '-14px',
      lineHeight: '1.4375em',
    },
  },
  phoneInput: {
    width: '100% !important',
    padding: '18px 14px !important',
  },
  phoneInputError: {
    color: '#DB4437',
    fontFamily:
      'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
  },
}));
