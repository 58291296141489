import React, { forwardRef, useImperativeHandle } from 'react';
import { FormHelperText, FormLabel, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// material-ui core
import Switch from '@mui/material/Switch';

// variables, functions, configurations
import { ICustomSwitchProps } from '../../../interfaces/inputs';

const useStyles = makeStyles()((theme: Theme) => ({
  switchContainer: {
    flex: '0 1 auto',
    minWidth: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const CustomSwitch = forwardRef((props: ICustomSwitchProps, ref: React.ForwardedRef<void>) => {
  // props
  const {
    id,
    label,
    name,
    checked,
    onChange,
    style,
    disabled = false,
    testId = '',
    sx,
    helperText = '',
  } = props;

  // styles
  const { classes } = useStyles();

  // This is needed so that this component can
  // expose methods to its parent components
  useImperativeHandle(ref, () => ({}));

  // Handler for switch change
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className={classes.switchContainer}>
      <FormLabel id={`label-for-${id}`} style={{ fontSize: '0.75rem' }}>
        {label}
      </FormLabel>
      {helperText.length > 0 && <FormHelperText sx={{ mt: 0 }}>{helperText}</FormHelperText>}
      <Switch
        id={id}
        name={name}
        checked={checked}
        onChange={handleSwitchChange}
        disabled={disabled}
        data-testid={testId}
        sx={sx}
        style={style}
      />
    </div>
  );
});

export default CustomSwitch;
