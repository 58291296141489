import { ISlotSettingsSTExclusionStatusEnum } from 'codegen/warehouse_status';

export const EXCLUSION_STATUS = {
  EXCLUDE: 'EXCLUDE',
  INCLUDE: 'INCLUDE',
};

export const EXCLUSION_STATUS_STATES = {
  [EXCLUSION_STATUS.EXCLUDE]: {
    action: 'Exclude',
    placeholder: 'exclusion',
    exclusionStatus: EXCLUSION_STATUS.EXCLUDE as ISlotSettingsSTExclusionStatusEnum,
  },
  [EXCLUSION_STATUS.INCLUDE]: {
    action: 'Include',
    placeholder: 'inclusion',
    exclusionStatus: EXCLUSION_STATUS.INCLUDE as ISlotSettingsSTExclusionStatusEnum,
  },
};
