import { Theme } from '@mui/material';

export const baseCardStyle = (theme: Theme) => ({
  card: {
    height: '100%',
    marginBottom: theme.spacing(3) as string,
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
  },
  cardSection: {
    padding: theme.spacing(2),
  },
  cardContentSection: {
    position: 'relative' as 'relative',
    flex: '1',
    padding: 0,
  },
  emptyCard: {
    textAlign: 'center' as 'center',
    margin: theme.spacing(5, 0),
    minHeight: theme.spacing(4),
  },
  cardContentList: {
    paddingTop: theme.spacing(1),
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
  subheader: {
    fontSize: '1rem',
    wordBreak: 'break-all' as 'break-all',
    marginRight: theme.spacing(4),
  },
  actionContainer: {
    marginRight: '0.5rem',
  },
  action: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
  widthLarger: {
    '& textarea': {
      width: '320px',
      fontSize: '15px',
      textAlign: 'left' as 'left',
    },
    '& input': {
      width: '320px',
      fontSize: '15px',
      textAlign: 'right' as 'right',
    },
  },
  widthLarge: {
    '& textarea': {
      width: '280px',
      fontSize: '15px',
      textAlign: 'left' as 'left',
    },
    '& input': {
      width: '280px',
      fontSize: '15px',
      textAlign: 'right' as 'right',
    },
  },
  widthMedium: {
    '& textarea': {
      width: '190px',
      fontSize: '17px',
      textAlign: 'left' as 'left',
    },
    '& input': {
      width: '190px',
      fontSize: '17px',
      textAlign: 'right' as 'right',
    },
  },
  widthSmall: {
    '& textarea': {
      width: '100px',
      fontSize: '17px',
      textAlign: 'left' as 'left',
    },
    '& input': {
      width: '100px',
      fontSize: '17px',
      textAlign: 'right' as 'right',
    },
  },
});

export const uploadCardsStyle = () => ({
  ...baseCardStyle,
  card: {
    height: '200px',
  },
});

export const reportItemStyle = (theme: Theme) => ({
  listItem: {
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    display: 'flex',
  },
  listItemText: {
    flex: '0 1 65%',
  },
  primaryText: {
    lineHeight: '0.80',
  },
  secondaryText: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
    marginTop: theme.spacing(0.8),
    lineHeight: '1.3',
  },
  reportName: {},
  reportStatusSection: {
    marginBottom: theme.spacing(0.5) as string,
    alignItems: 'baseline',
  },
  reportStatus: {
    margin: theme.spacing(0, 0.5),
  },
  reportStatusMarginRight: {
    marginRight: theme.spacing(1),
  },
  reportStatusDisabled: {
    color: theme.palette.disabled,
  },
  recurrence: {
    marginRight: 0,
  },
  progress: {
    margin: theme.spacing(0, 0.5),
  },
});

export const simpleItemStyle = (theme: Theme) => ({
  listItem: {
    padding: theme.spacing(2),
    display: 'block',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
    marginRight: theme.spacing(4),
  },
  itemValue: {
    marginLeft: theme.spacing(15),
  },
  fileLink: {
    wordBreak: 'break-all',
  },
});

export const timePickerStyle = (width: string) => ({
  marginLeft: '4px',
  width,
  '& .MuiInputBase-formControl': {
    borderRadius: '0 !important',
  },
  '& .MuiInputBase-formControl input': {
    padding: '18px 0 7px',
  },
  '& .MuiOutlinedInput-root fieldset': {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  '& .MuiOutlinedInput-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd button': {
    padding: '6px',
    marginTop: '4px',
  },
  '& .MuiInputLabel-formControl': {
    marginLeft: '-50px',
  },
  '& .Mui-error fieldset': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
  },
});
