import React, { ReactElement } from 'react';

// material-ui core
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    minWidth: '100%',
  },
  buttons: {
    padding: theme.spacing(2),
  },
}));

/**
 * Interface for the props of the ModalConfirm component
 */
interface IModalConfirmProps {
  /**
   * Flag indicating whether the Modal is opened
   */
  opened: boolean;
  /**
   * Title of the dialog
   */
  title: string;
  /**
   * Message of the dialog
   */
  message: string | ReactElement;
  /**
   * Call-back executed on closing the modal
   */
  handleClose: () => void;
  /**
   * Call-back executed on clicking on "confirm"
   */
  onConfirm: () => void;
  /**
   * Confirm label
   */
  confirmLabel?: string;
  /**
   * Cancel label
   */
  cancelLabel?: string;
}

/**
 * Functional component managing a confirmation modal
 * @param props props of the component
 * @returns the rendered component
 */
export const ModalConfirm = (props: IModalConfirmProps) => {
  // props
  const {
    opened,
    title,
    message,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    handleClose,
    onConfirm,
  } = props;

  // styles
  const { classes } = useStyles();

  return (
    <Dialog
      data-testid="c-modal-confirm"
      className={classes.root}
      open={opened}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title" data-testid="c-confirm-dialog-title">
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          id="confirm-dialog-description"
          data-testid="c-confirm-dialog-description"
        >
          <Typography style={{ margin: '16px 0px' }} component="span">
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button
          data-testid="c-confirm-button"
          fullWidth
          onClick={() => {
            onConfirm();
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          {confirmLabel}
        </Button>
        <Button
          data-testid="c-cancel-button"
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
