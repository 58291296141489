import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  legend: {
    overflow: 'hidden',
    maxWidth: '100%',
    background: 'white',
    borderRadius: theme.spacing(1.2),
    boxShadow: '0 2px 8px -2px rgba(0,0,0,0.1), 0 2px 12px -4px rgba(0,0,0,0.2)',
  },
  legendBody: {
    width: '290px',
  },
  legendBodyMinimised: {
    display: 'none',
  },
  legendToggleButton: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    border: 0,
    paddingInline: '6px 8px',
    paddingBlock: '2px',
    cursor: 'pointer',
    width: '100%',
    color: '#878787',
    backgroundColor: '#fafafa',

    '&:focus': {
      outline: 0,
      backgroundColor: '#f6f6f6',
    },

    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
  },
  legendToggleButtonLabel: {
    flex: '0 1 auto',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  legendUnorderedList: {
    display: 'grid',
    gridTemplateColumns: ' minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(1),
    margin: 0,
    padding: theme.spacing(1.2),
  },
}));
