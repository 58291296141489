/* eslint-disable no-plusplus */

export const circularRangeForWeekDays = (start: number, end: number) => {
  const result = [];
  if (start <= end) {
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
  } else {
    for (let i = start; i <= 7; i++) {
      result.push(i);
    }
    for (let i = 1; i <= end; i++) {
      result.push(i);
    }
  }
  return result;
};
