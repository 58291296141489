import { getQrCodeApiService } from './services';

export type AssignmentType = 'DHCP' | 'STATIC';
export interface IGenerateQRCode {
  /**
   * System id
   */
  systemId: string;
  /**
   * Flight domain id
   */
  flightDomainId: string;
  /**
   * The SSID of the network
   */
  ssid: string;
  /**
   * The passphrase of the network
   */
  passphrase?: string;
  /**
   * How addresses are assigned in the network. Choices are "DHCP" and "STATIC".
        For "static", an ip, gateway and netmask have to be defined.
   */
  assignment?: AssignmentType;
  /**
   * 'The IP to use if "assignment" is static. Format: x.x.x.x'
   */
  ip?: string;
  /**
   * 'The netmask to use if "assignment" is static. Format: x.x.x.x'
   */
  netmask?: string;
  /**
   * 'The gateway to use if "assignment" is static. Format: x.x.x.x'
   */
  gateway?: string;
  useGatewayDns?: boolean;
  preferredDnsServer?: string;
  alternateDnsServer?: string;
}

/**
 * Generate QR code
 * @param param0 IGenerateQRCode params
 * @returns generated QR code
 */
const generateQRCode = ({
  systemId,
  flightDomainId,
  ssid,
  assignment = 'DHCP',
  passphrase,
  ip,
  netmask,
  gateway,
  preferredDnsServer,
  alternateDnsServer,
}: IGenerateQRCode) =>
  getQrCodeApiService().getQrCode(
    systemId,
    flightDomainId,
    ssid,
    assignment,
    passphrase,
    ip,
    netmask,
    gateway,
    preferredDnsServer,
    alternateDnsServer,
  );

export const DroneServices = {
  generateQRCode,
};
