/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { Vector3 } from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { mapEntries } from 'common/functions/otherFunctions';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { Floor } from 'shared/map-container/features/mesh-floor/MeshFloor';
import { MutableState, initialCameraState } from 'shared/map-container/reducer/MutableState';
import { OrthoCamera } from 'shared/map-container/features/map-orthographic-camera/OrthoCamera';
import { ORTHO_CAMERA_Z } from 'shared/map-container/defaults/orthographicCameraZ.default';
import { MapCanvas } from 'shared/map-container/features/map-canvas/MapCanvas';
import { Zoomer } from 'shared/map-container/utils/Zoomer';
import { IDroneStatusST } from 'codegen/flight_domain';
import {
  IGetEstimatedObstaclesResponseST,
  IGetSpaceReservationsResponseST,
} from 'codegen/nav_simulation';
import { EstimatedObstacle } from 'delivery/features/commissioning/features/EstimatedObstaclesMap/EstimatedObstacle';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import { Fleet } from './Fleet';
import { ReservedSpace3d } from './features/SpaceReservationsMap/ReservedSpace3d';
import { LiveMapMutable, MapFleet } from './liveMap.model';

const lp = getLogPrefixForType('COMPONENT', 'Live Map');

export const wrapperId = 'LiveMapWrapper';

export const LiveMap = ({
  namesOfDronesToShow,
  estimatedObstacles,
  spaceReservations,
}: {
  namesOfDronesToShow?: string[];
  estimatedObstacles: IGetEstimatedObstaclesResponseST['estimated_obstacles'];
  spaceReservations: IGetSpaceReservationsResponseST['space_reservations'];
}) => {
  const { mapState, dispatchMapStore: mapDispatch } = useMapStore();
  const { options } = mapState;
  const { fleet_status } = useGroundControlStore().stateGroundControl.fleetOverview;
  const { drone_versions } = useGroundControlStore().stateGroundControl.fleetVersions;

  const mapFleet: MapFleet = Object.values(
    fleet_status as unknown as { [key: string]: IDroneStatusST },
  ).reduce((acc, ds) => {
    acc = {
      ...acc,
      [ds.drone_name]: { ...(ds as IDroneStatusST), ...drone_versions[ds.drone_name] },
    };
    return acc;
  }, {});

  const worldBox = mapState?.map?.box || [0, 0, 0, 0, 0, 0];
  // eslint-disable-next-line
  const [minX, minY, _minZ, maxX, maxY, _maxZ] = worldBox;
  const [width, length] = [maxX - minX, maxY - minY];

  const mutableState = useMemo(() => {
    MutableState.destroyInstance();
    return MutableState.constructState<LiveMapMutable>(
      'Live Map',
      {
        ...initialCameraState,
        position: new Vector3((maxX - minX) / 2, (maxY - minY) / 2, ORTHO_CAMERA_Z),
        orthographicFOV: options.camera.orthographic.maxFOV,
        currentOrthographicFOV: options.camera.orthographic.maxFOV,
      },
      { worldBox, guidePosition: new Vector3(0, 0, 0), isFloorHeld: false },
      {
        type: 'LiveMap',
        fleet: mapFleet,
      },
    );
  }, [mapState.map]);

  mutableState.mapData.fleet = mapFleet;

  const zoomer = useMemo(
    () => new Zoomer(options.camera, mapState.canvasSize),
    [options.camera, mapState.map, mapState.canvasSize],
  );
  const camera = useMemo(
    () => (
      <OrthoCamera
        worldBounds={[minX, maxX, minY, maxY]}
        options={options.camera}
        fovAxisRatio={mapState.canvasProportion}
        zoomer={zoomer}
      />
    ),
    [options.camera, mapState.canvasProportion, mapState.map],
  );

  /**
   * Handles mouse down on the floor.
   */
  const onFloorDown = (): void => {
    const state = MutableState.getState();
    state.interaction.isFloorHeld = true;
  };

  /**
   * Handles mouse up on the floor.
   */
  const onFloorUp = (): void => {
    const state = MutableState.getState();
    state.interaction.isFloorHeld = false;
  };

  console.debug(
    lp,
    `rendering ${width} by ${length} [${[
      minX,
      maxX,
      minY,
      maxY,
    ]}] for ${namesOfDronesToShow} drone names.`,
  );

  if (Object.keys(mapFleet).length === 0) {
    console.debug(lp, '--> Rendering without fleet_status data. Map will show no drones. <--');
  }

  const spaceReservationsElements = useMemo(
    () =>
      Object.values(spaceReservations)
        .filter((reservedSpace) => namesOfDronesToShow?.includes(reservedSpace.drone_id))
        .map((reservedSpace) => (
          <ReservedSpace3d key={reservedSpace.drone_id} reservedSpaceState={reservedSpace} />
        )),
    [spaceReservations],
  );

  const estimatedObstaclesElements = useMemo(
    () =>
      mapEntries(estimatedObstacles, (key, obstacle) => (
        <EstimatedObstacle key={key as string} obstacleState={obstacle} options={options} />
      )),
    [estimatedObstacles],
  );

  const mapElements = [
    <Fleet key="mapElementFleet" namesOfDronesToShow={namesOfDronesToShow} fleet={mapFleet} />,
    ...spaceReservationsElements,
    ...estimatedObstaclesElements,
  ];

  return (
    <MapCanvas
      mapState={mapState}
      mapDispatch={mapDispatch}
      mutableState={mutableState}
      wrapperId={wrapperId}
      canvasSize={{ width: 0, height: 630 }}
      camera={camera}
      variant="secondary"
      floor={
        <Floor
          mapState={mapState}
          zoomer={zoomer}
          variant="secondary"
          onFloorUp={onFloorUp}
          onFloorDown={onFloorDown}
        />
      }
      mapElements={mapElements}
    />
  );
};
