import { makeStyles } from 'tss-react/mui';

export const multipleBarcode = makeStyles()(() => ({
  verticalCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
}));
