import { makeStyles } from 'tss-react/mui';
import { alpha, darken, Theme } from '@mui/material';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { noFlyZoneOpacity } from 'shared/map/defaults/noFlyZoneOpacity.default';

export const useStyles = makeStyles()((theme: Theme) => ({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    margin: 0,

    '> .MuiCheckbox-root': {
      flex: '0 0 auto',
    },

    '> .MuiFormControlLabel-label': {
      flex: '1 1 auto',
      minWidth: 0,
      color: '#787878',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  checkbox: {
    borderRadius: '4px',
    height: '30px',
    width: '30px',
    padding: '2px',
    fontSize: '20px',
    color: '#ffffff',
    backgroundColor: '#d3d3d3',
    transition: 'color 220ms, background-color 220ms',

    svg: {
      fontSize: '1em',
    },
  },
  checkboxTypeNoFlyZone: {
    '&:hover, &:focus-within': {
      color: '#fff',
      backgroundColor: alpha(mapStyle.noFlyZone.active.backgroundColor.primary, 0.4),
    },

    '&.Mui-checked': {
      color: '#fff',
      backgroundColor: alpha(
        mapStyle.noFlyZone.active.backgroundColor.primary,
        noFlyZoneOpacity.active,
      ),

      '&:hover, &:focus-within': {
        color: '#fff',
        backgroundColor: alpha(
          mapStyle.noFlyZone.active.backgroundColor.primary,
          noFlyZoneOpacity.activeHover,
        ),
      },
    },
  },
  checkboxTypeControlledZone: {
    '&:hover, &:focus-within': {
      color: '#fff',
      backgroundColor: alpha(mapStyle.controlledZones.active.backgroundColor.primary, 0.4),
    },

    '&.Mui-checked': {
      color: '#fff',
      backgroundColor: mapStyle.controlledZones.active.backgroundColor.primary,

      '&:hover, &:focus-within': {
        color: '#fff',
        backgroundColor: darken(mapStyle.controlledZones.active.backgroundColor.primary, 0.1),
      },
    },
  },
  checkboxTypeOpenFlyZone: {
    '&:hover, &:focus-within': {
      color: '#fff',
      backgroundColor: alpha(mapStyle.openFlyZones.active.backgroundColor.primary, 0.4),
    },

    '&.Mui-checked': {
      color: '#fff',
      backgroundColor: mapStyle.openFlyZones.active.backgroundColor.primary,

      '&:hover, &:focus-within': {
        color: '#fff',
        backgroundColor: darken(mapStyle.openFlyZones.active.backgroundColor.primary, 0.1),
      },
    },
  },
}));
