import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Bounds2D } from '../MapContainer.model';
import { mapStyle } from '../features/map-canvas/MapCanvas.style';

/**
 * Basic structure for the map options.
 * Required for form creation.
 */
export type OptionsObject = {
  [key: string]: number | boolean | string | OptionsObject;
};

type MapStyleType = typeof mapStyle;

export const cameraTypes = ['perspective', 'orthographic'] as const;
type CameraType = typeof cameraTypes[number];
export const isCameraType = (x: unknown): x is CameraType =>
  typeof x === 'string' && (x === 'perspective' || x === 'orthographic');

/**
 * Set of map module settings exposed to the user.
 */
export interface MapOptions extends OptionsObject {
  camera: MapCameraOptions;
  facility: MapFacilityOptions;
  styles: MapStyleType;
}

export type MapCameraOptions = {
  numberOfZoomSteps: number;
  invertZoom: boolean;
  lodThresholdMetersSquare: number;
  orthographic: {
    minFOV: number;
    maxFOV: number;
  };
};

const initialMapCameraOptions: MapCameraOptions = {
  numberOfZoomSteps: 20,
  invertZoom: false,
  lodThresholdMetersSquare: 2000,
  orthographic: {
    minFOV: 2,
    maxFOV: 75,
  },
};

type MapFacilityOptions = {
  showWorldBoxWireframe: boolean;
  showPerformanceMonitor: boolean;
};

const initialMapFacilityOptions: MapFacilityOptions = {
  showWorldBoxWireframe: false,
  showPerformanceMonitor: false,
};

export const initialMapOptions: MapOptions = {
  camera: initialMapCameraOptions,
  facility: initialMapFacilityOptions,
  styles: mapStyle,
};

const logPrefixFOVFromBounds = getLogPrefixForType('FUNCTION', '3D getMaxFOVFromWorldBounds');
/**
 * Gets the maximum FOV for the orthographic camera.
 * @param worldBounds the world bounds
 * @returns the maximum FOV
 */
const getMaxFOVFromWorldBounds = (worldBounds: Bounds2D, minFOV: number): number => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_minX, _maxX, minY, maxY] = worldBounds;
  let fov = Math.ceil((maxY - minY) / 2);
  fov = fov > minFOV ? fov : minFOV + 2;
  console.debug(
    `${logPrefixFOVFromBounds} calculated max FOV: ${fov} from world bounds: ${worldBounds}`,
  );
  return fov;
};

const getInitialMapOptionsLP = getLogPrefixForType('FUNCTION', 'getInitialMapOptions');

export const getInitialMapOptions = (worldBounds: Bounds2D): MapOptions => {
  console.debug(getInitialMapOptionsLP, `from args -> worldBounds:${worldBounds}`);
  return {
    ...initialMapOptions,
    camera: {
      ...initialMapOptions.camera,
      orthographic: {
        ...initialMapOptions.camera.orthographic,
        maxFOV: getMaxFOVFromWorldBounds(worldBounds, initialMapOptions.camera.orthographic.minFOV),
      },
    },
  };
};
