import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonGroup,
  IconButton,
  List,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { SettingsItem } from '../types';

export const SettingsListItemJsonObject = ({
  classes,
  item,
  children,
  testId,
}: {
  classes: { cardContentList?: string };
  item: SettingsItem;
  children?: any;
  testId: string;
}) => {
  const [json, setJson] = useState(JSON.stringify(item.values, null, 2) || undefined);

  const handleCopy: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    navigator.clipboard.writeText(json!);
    enqueueSnackbar('Content copy to the clipboard', { variant: 'success' });
  };

  const handlePaste: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    navigator.clipboard.readText().then((t: string) => {
      try {
        const json = JSON.parse(t);
        item.action(json);
        enqueueSnackbar('Content copy from the clipboard', { variant: 'success' });
        setJson(t);
      } catch (error) {
        enqueueSnackbar('The content of the clipboard is not a valid json', { variant: 'error' });
      }
    });
  };

  return (
    <List
      className={classes.cardContentList}
      component="ul"
      data-testid={`settings-item-container-${testId}`}
    >
      <Box component="div" flexDirection="column" display="flex" px={2}>
        <Box component="div" className="v-flex-center">
          <Box component="div" className="v-flex-center">
            <Typography variant="subtitle1" color="textSecondary" component="p">
              {item.label}
            </Typography>
            {item.description && (
              <TooltipedIcon
                tooltip={item.description}
                icon={<InfoOutlinedIcon fontSize="small" />}
              />
            )}
          </Box>
          <ButtonGroup>
            <IconButton onClick={handleCopy} disabled={!json}>
              <Tooltip title="Copy json content to the clipboard">
                <ContentCopyIcon />
              </Tooltip>
            </IconButton>
            <IconButton onClick={handlePaste}>
              <Tooltip title="Paste json content from the clipboard">
                <ContentPasteIcon />
              </Tooltip>
            </IconButton>
          </ButtonGroup>
        </Box>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          data-testid={`settings-item-content-${testId}`}
        >
          <Accordion className="customAccordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                sx={{
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                }}
              >
                JSON Code
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextareaAutosize
                data-testid={item.testId}
                disabled={true}
                value={json}
                style={{ width: '100%' }}
              />
            </AccordionDetails>
          </Accordion>
          {children}
        </Box>
      </Box>
    </List>
  );
};
