import { capitalize } from 'lodash';
import { IReportST } from 'codegen/report';
import { getReportSummarySections } from './getReportSummarySection';
import { ReportSummaryData } from '../ReportStore.model';

export const getReportSummaryData = (reportSummary: IReportST): ReportSummaryData => {
  const subtitle = capitalize(reportSummary.state as string);
  return {
    title: 'Report summary',
    counter: reportSummary.num_locations - (reportSummary.location_data_count.EXCLUDED || 0),
    counterSubject: 'locations',
    subtitle,
    sections: getReportSummarySections(reportSummary),
  };
};
