import { IClientModalsAction } from './IClientModalsActions';
import { ClientModalsActionTypes } from './clientModalsActionTypes';
import { IClientModalsState } from './IClientModalsStore';
import { initialClientModalsState } from './clientModalInitialState';

export const modalsReducer = (
  state: IClientModalsState,
  action: IClientModalsAction,
): IClientModalsState => {
  switch (action.type) {
    case ClientModalsActionTypes.RESET:
      return {
        ...initialClientModalsState,
      };
    case ClientModalsActionTypes.SELECT_FACILITY_MODAL:
      return {
        ...state,
        selectFacilityModalOpened: !state.selectFacilityModalOpened,
      };
    case ClientModalsActionTypes.CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        changePasswordModalOpened: !state.changePasswordModalOpened,
      };
    case ClientModalsActionTypes.TOGGLE_SIGNIN_MODAL:
      return {
        ...state,
        signinModalOpened: action.payload.open,
      };
    case ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModalOpened: !state.confirmModalOpened,
        modalConfirmData: action.payload,
      };

    default:
      return state;
  }
};
