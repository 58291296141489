import React from 'react';
import Slide from '@mui/material/Slide';
import { useHideOnScroll } from '../../hooks/useHideOnScroll';

interface IHideOnScrollProps {
  children: JSX.Element;
  threshold: number;
  staticContext?: any;
  scroller?: any;
}

/**
 * Wrapper that will hide children element when trashold satisfied scroll position
 */
const HideOnScroll = (props: IHideOnScrollProps): JSX.Element => {
  const { children, staticContext, threshold, scroller, ...other } = props;

  const hide = useHideOnScroll({ threshold, scroller });

  return (
    <Slide appear={false} direction="down" in={!hide} {...other}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
