import { Vector3 } from 'three';
import { ControlledZone } from './controlled-zone.model';
import { OpenFlyZone } from './open-fly-zone.model';
import { NoFlyZone } from './no-fly-zone.model';

export enum DroneZoneTypes {
  controlledZone = 'controlledZone',
  noFlyZone = 'noFlyZone',
  openFlyZone = 'openFlyZone',
}

export type DroneZones = ControlledZone | OpenFlyZone | NoFlyZone;

export type DroneZoneType = `${DroneZoneTypes}`;

export type DroneZoneGeometry = {
  start: Vector3 | undefined;
  end: Vector3 | undefined;
  height: number;
};
