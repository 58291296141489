import { IBarcodeST } from '../../../../codegen/location_information';
import { calculateBarcodeFrameDimensions } from './utils/calculateBarcodeFrameDimensions';

export const BarcodeFrame = ({ barcode }: { barcode: IBarcodeST }) => {
  const dimensions = calculateBarcodeFrameDimensions(barcode);
  return (
    <div
      style={{
        position: 'absolute',
        top: `${dimensions.topPc}%`,
        left: `${dimensions.leftPc}%`,
        height: `${dimensions.heightPc}%`,
        width: `${dimensions.widthPc}%`,
        border: ((): string => {
          if (barcode.status === 'BARCODE_RECOGNIZED') return '0.5px solid #2196F3';
          if (barcode.status === 'BARCODE_PATTERN_MISMATCH') return '0.5px solid #FF8000';
          return '0.5px solid rgba(255,0,0,0.5)';
        })(),
      }}
    />
  );
};
