/* tslint:disable */
/* eslint-disable */
/**
 * open fly zones
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IBox3DST
 */
export interface IBox3DST {
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  min: IMapPointST;
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  max: IMapPointST;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IGetOpenFlyZones404ResponseST
 */
export interface IGetOpenFlyZones404ResponseST {
  /**
   * error message zone ID
   * @type {string}
   * @memberof IGetOpenFlyZones404ResponseST
   */
  message: string;
}
/**
 *
 * @export
 * @interface IGetOpenFlyZonesResponseST
 */
export interface IGetOpenFlyZonesResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IGetOpenFlyZonesResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the open fly zones
   * @type {{ [key: string]: IOpenFlyZoneStatus1ST; }}
   * @memberof IGetOpenFlyZonesResponseST
   */
  open_fly_zones: { [key: string]: IOpenFlyZoneStatus1ST };
}
/**
 *
 * @export
 * @interface IMapPointST
 */
export interface IMapPointST {
  /**
   * The value of the X coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  x: number;
  /**
   * The value of the Y coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  y: number;
  /**
   * The value of the Z coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  z: number;
}
/**
 *
 * @export
 * @interface IOpenFlyZonePostRequestST
 */
export interface IOpenFlyZonePostRequestST {
  /**
   * Name of the open fly zone
   * @type {string}
   * @memberof IOpenFlyZonePostRequestST
   */
  name: string;
  /**
   * open fly zone description
   * @type {string}
   * @memberof IOpenFlyZonePostRequestST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IOpenFlyZonePostRequestST
   */
  geometry: IBox3DST;
}
/**
 *
 * @export
 * @interface IOpenFlyZoneStatus1ST
 */
export interface IOpenFlyZoneStatus1ST {
  /**
   * The open fly zone name
   * @type {string}
   * @memberof IOpenFlyZoneStatus1ST
   */
  name: string;
  /**
   * The open fly zone description
   * @type {string}
   * @memberof IOpenFlyZoneStatus1ST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IOpenFlyZoneStatus1ST
   */
  geometry: IBox3DST;
}
/**
 *
 * @export
 * @interface IOpenFlyZoneStatusST
 */
export interface IOpenFlyZoneStatusST {
  /**
   * The open fly zone name
   * @type {string}
   * @memberof IOpenFlyZoneStatusST
   */
  name: string;
  /**
   * The open fly zone description
   * @type {string}
   * @memberof IOpenFlyZoneStatusST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IOpenFlyZoneStatusST
   */
  geometry: IBox3DST;
}
/**
 *
 * @export
 * @interface IOpenFlyZoneUpdatePropertiesRequestST
 */
export interface IOpenFlyZoneUpdatePropertiesRequestST {
  /**
   * Name of the open fly zone
   * @type {string}
   * @memberof IOpenFlyZoneUpdatePropertiesRequestST
   */
  name: string;
  /**
   * open fly zone description
   * @type {string}
   * @memberof IOpenFlyZoneUpdatePropertiesRequestST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IOpenFlyZoneUpdatePropertiesRequestST
   */
  geometry: IBox3DST;
}

/**
 * OpenFlyZonesApi - axios parameter creator
 * @export
 */
export const OpenFlyZonesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a new open fly zones.
     * @summary Create a new open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IOpenFlyZonePostRequestST} iOpenFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOpenFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      iOpenFlyZonePostRequestST: IOpenFlyZonePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createOpenFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('createOpenFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'iOpenFlyZonePostRequestST' is not null or undefined
      assertParamExists(
        'createOpenFlyZone',
        'iOpenFlyZonePostRequestST',
        iOpenFlyZonePostRequestST,
      );
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/open-fly-zone`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iOpenFlyZonePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the status of the flight domain open fly zones.
     * @summary Returns the status of the flight domain open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenFlyZones: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getOpenFlyZones', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getOpenFlyZones', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/open-fly-zone`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to delete a specific open fly zones.
     * @summary Delete a specific open fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openFlyZoneDeleteRequest: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('openFlyZoneDeleteRequest', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('openFlyZoneDeleteRequest', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('openFlyZoneDeleteRequest', 'id', id);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/open-fly-zone/{id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to update properties of a specific open fly zones.
     * @summary Update properties of a specific open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IOpenFlyZoneUpdatePropertiesRequestST} iOpenFlyZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOpenFlyZoneProperties: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iOpenFlyZoneUpdatePropertiesRequestST: IOpenFlyZoneUpdatePropertiesRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateOpenFlyZoneProperties', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateOpenFlyZoneProperties', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateOpenFlyZoneProperties', 'id', id);
      // verify required parameter 'iOpenFlyZoneUpdatePropertiesRequestST' is not null or undefined
      assertParamExists(
        'updateOpenFlyZoneProperties',
        'iOpenFlyZoneUpdatePropertiesRequestST',
        iOpenFlyZoneUpdatePropertiesRequestST,
      );
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/open-fly-zone/{id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iOpenFlyZoneUpdatePropertiesRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OpenFlyZonesApi - functional programming interface
 * @export
 */
export const OpenFlyZonesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OpenFlyZonesApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a new open fly zones.
     * @summary Create a new open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IOpenFlyZonePostRequestST} iOpenFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOpenFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iOpenFlyZonePostRequestST: IOpenFlyZonePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOpenFlyZone(
        systemIdStr,
        flightDomainId,
        iOpenFlyZonePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the status of the flight domain open fly zones.
     * @summary Returns the status of the flight domain open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpenFlyZones(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetOpenFlyZonesResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenFlyZones(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to delete a specific open fly zones.
     * @summary Delete a specific open fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async openFlyZoneDeleteRequest(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.openFlyZoneDeleteRequest(
        systemIdStr,
        flightDomainId,
        id,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to update properties of a specific open fly zones.
     * @summary Update properties of a specific open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IOpenFlyZoneUpdatePropertiesRequestST} iOpenFlyZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOpenFlyZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iOpenFlyZoneUpdatePropertiesRequestST: IOpenFlyZoneUpdatePropertiesRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOpenFlyZoneProperties(
        systemIdStr,
        flightDomainId,
        id,
        iOpenFlyZoneUpdatePropertiesRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OpenFlyZonesApi - factory interface
 * @export
 */
export const OpenFlyZonesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OpenFlyZonesApiFp(configuration);
  return {
    /**
     * Create a new open fly zones.
     * @summary Create a new open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IOpenFlyZonePostRequestST} iOpenFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOpenFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iOpenFlyZonePostRequestST: IOpenFlyZonePostRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createOpenFlyZone(systemIdStr, flightDomainId, iOpenFlyZonePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the status of the flight domain open fly zones.
     * @summary Returns the status of the flight domain open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenFlyZones(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IGetOpenFlyZonesResponseST> {
      return localVarFp
        .getOpenFlyZones(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to delete a specific open fly zones.
     * @summary Delete a specific open fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openFlyZoneDeleteRequest(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .openFlyZoneDeleteRequest(systemIdStr, flightDomainId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to update properties of a specific open fly zones.
     * @summary Update properties of a specific open fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IOpenFlyZoneUpdatePropertiesRequestST} iOpenFlyZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOpenFlyZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iOpenFlyZoneUpdatePropertiesRequestST: IOpenFlyZoneUpdatePropertiesRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateOpenFlyZoneProperties(
          systemIdStr,
          flightDomainId,
          id,
          iOpenFlyZoneUpdatePropertiesRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OpenFlyZonesApi - object-oriented interface
 * @export
 * @class OpenFlyZonesApi
 * @extends {BaseAPI}
 */
export class OpenFlyZonesApi extends BaseAPI {
  /**
   * Create a new open fly zones.
   * @summary Create a new open fly zones.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IOpenFlyZonePostRequestST} iOpenFlyZonePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenFlyZonesApi
   */
  public createOpenFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    iOpenFlyZonePostRequestST: IOpenFlyZonePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return OpenFlyZonesApiFp(this.configuration)
      .createOpenFlyZone(systemIdStr, flightDomainId, iOpenFlyZonePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the status of the flight domain open fly zones.
   * @summary Returns the status of the flight domain open fly zones.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenFlyZonesApi
   */
  public getOpenFlyZones(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return OpenFlyZonesApiFp(this.configuration)
      .getOpenFlyZones(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to delete a specific open fly zones.
   * @summary Delete a specific open fly zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenFlyZonesApi
   */
  public openFlyZoneDeleteRequest(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return OpenFlyZonesApiFp(this.configuration)
      .openFlyZoneDeleteRequest(systemIdStr, flightDomainId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to update properties of a specific open fly zones.
   * @summary Update properties of a specific open fly zones.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {IOpenFlyZoneUpdatePropertiesRequestST} iOpenFlyZoneUpdatePropertiesRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpenFlyZonesApi
   */
  public updateOpenFlyZoneProperties(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    iOpenFlyZoneUpdatePropertiesRequestST: IOpenFlyZoneUpdatePropertiesRequestST,
    options?: AxiosRequestConfig,
  ) {
    return OpenFlyZonesApiFp(this.configuration)
      .updateOpenFlyZoneProperties(
        systemIdStr,
        flightDomainId,
        id,
        iOpenFlyZoneUpdatePropertiesRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
