import { useEffect, useState } from 'react';
import BaseCard from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem from 'components/BaseCard/BaseCardSimpleItem';
import { useRequestController } from 'hooks';
import { IWarehouseStatus } from 'interfaces';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Section, defaultOccupancyData } from '../warehouseStatusFunctions';
import { getOccupancyData } from './utils/getOccupancyData';

const logPrefix = getLogPrefixForType('COMPONENT', 'WarehouseOccupancyCard');

export const WarehouseOccupancyCard = ({ systemId }: { systemId: string }) => {
  const [occupancySpinner, setOccupancySpinner] = useState(0);
  const [occupancyData, setOccupancyData] = useState<IWarehouseStatus>(defaultOccupancyData);
  const { requestController } = useRequestController(logPrefix);

  useEffect(() => {
    if (systemId) {
      getOccupancyData(systemId, requestController, setOccupancySpinner, setOccupancyData);
    }
  }, [requestController, systemId]);

  return (
    <BaseCard
      cardFor="warehouse occupancy"
      dataTestId="c-warehouse-occupancy-card"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      isLoading={occupancySpinner}
      spinnerLabel="Loading occupancy..."
      title={occupancyData.title}
      subtitle={occupancyData.subtitle}
      counter={occupancyData.counter}
      counterSubject={occupancyData.counterSubject}
      actionButtons={occupancyData.actionButtons}
    >
      {occupancyData.sections &&
        occupancyData.sections.map((section: Section) => (
          <BaseCardSimpleItem
            key={section.id}
            title={section.title}
            sectionItems={section.sectionItems}
          />
        ))}
    </BaseCard>
  );
};
