import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  tooltipedIcon: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0.5),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  noMargin: {
    margin: 0,
  },
}));

const TooltipedIcon = ({
  tooltip,
  icon,
  text,
  noMargin,
  placement,
  ...props
}: {
  tooltip: any;
  icon: any;
  text?: string;
  noMargin?: boolean;
  placement?: TooltipProps['placement'];
}) => {
  const { classes, cx } = useStyles();

  return (
    <Tooltip title={tooltip} placement={placement} {...props}>
      <div className={cx(classes.tooltipedIcon, { [classes.noMargin]: noMargin })}>
        <div className={classes.icon}>{icon}</div>
        {text}
      </div>
    </Tooltip>
  );
};

export default TooltipedIcon;
