import { DroneZones } from 'shared/map/model/drone-zones.model';
import { RecOfStrings } from 'ts-types/Utility';
import { BottomLeftDimensions, Vec6 } from 'shared/map/model/geometry.model';
import { validateSizeAndPositionFitsWithinWorldBox } from './validateSizeAndPositionFitsWithinWorldBox';

export const validateDroneZoneForm = (worldBox: Vec6) => (values: DroneZones) => {
  let errors: RecOfStrings<DroneZones> = {};

  if (!values.name.trim().length) {
    errors = {
      ...errors,
      name: 'Zone name is required',
    };
  }

  Object.keys(values.sizeAndPosition).forEach((key) => {
    const validationMessage = validateSizeAndPositionFitsWithinWorldBox({
      zoneVectors: values.sizeAndPosition,
      facilityVectors: worldBox,
      fieldKey: key as keyof BottomLeftDimensions,
    });

    if (validationMessage) {
      errors = {
        ...errors,
        sizeAndPosition: {
          ...errors.sizeAndPosition,
          [key]: validationMessage,
        },
      };
    }
  });

  return errors;
};
