import { Typography } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium';
import DroneIcon from 'components/common/FacilityMenu/Icons/DroneIcon';
import PackageIcon from 'components/common/FacilityMenu/Icons/PackageIcon';
import { Box } from 'components/common/Box';
import { BarcodeRow } from '../models/BarcodeRow.model';

export const HeaderCell = (params: GridColumnHeaderParams<BarcodeRow>) => (
  <Box marginBottom="4px">
    <Box display="flex" alignItems="center" marginTop="4px">
      {['barcodeStatus', 'contentFound', 'foundAt'].includes(params.field) ? (
        <>
          <DroneIcon fontSize="24px" color="black" />
          <Typography fontSize={12} fontWeight={400} sx={{ marginLeft: 0.5 }}>
            Drone Report
          </Typography>
        </>
      ) : (
        <>
          <PackageIcon fontSize="24px" color="black" />
          <Typography fontSize={12} fontWeight={400} sx={{ marginLeft: 0.5 }}>
            WMS
          </Typography>
        </>
      )}
    </Box>
    <Typography fontSize={16}>{params.colDef.headerName}</Typography>
  </Box>
);
