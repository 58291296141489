import { ISystemIDsGetResponseValueST } from 'codegen/system';
import { AxiosResponse } from 'axios';

export const transformFacilitiesFromDTO = (
  facilitiesResponse: AxiosResponse<{ [p: string]: ISystemIDsGetResponseValueST }>,
) =>
  Object.entries(facilitiesResponse.data).map(([key, value]) => ({
    id: Number(key),
    name: value?.facility ?? key,
  }));
