import { useEffect, useState } from 'react';

export type KeyboardShortcut = {
  key: string;
  onShortcutPress?: () => void;
  onShortcutRelease?: () => void;
};
export const useHandleShortcut = (shortcuts: KeyboardShortcut[]) => {
  const [isShortcutPressed, setIsShortcutPressed] = useState<Record<string, boolean>>(
    shortcuts
      .map((s: KeyboardShortcut) => s.key)
      .reduce((shortcuts, shortcut) => ({ ...shortcuts, [shortcut]: false }), {}),
  );

  useEffect(() => {
    const isNotTextArea = (target: HTMLElement) => target.tagName !== 'TEXTAREA';
    const handleHotKeyPress = (event: KeyboardEvent) => {
      const shortcut: KeyboardShortcut | undefined = shortcuts.find((s) => s.key === event.key);
      setIsShortcutPressed({ ...isShortcutPressed, [event.key]: true });

      if (shortcut && shortcut.onShortcutPress && isNotTextArea(event.target as HTMLElement)) {
        event.preventDefault();
        shortcut.onShortcutPress();
      }
    };
    const handleHotKeyRelease = (event: KeyboardEvent) => {
      const shortcut: KeyboardShortcut | undefined = shortcuts.find((s) => s.key === event.key);
      setIsShortcutPressed({ ...isShortcutPressed, [event.key]: false });

      if (shortcut && shortcut.onShortcutRelease && isNotTextArea(event.target as HTMLElement)) {
        event.preventDefault();
        shortcut.onShortcutRelease();
      }
    };
    document.addEventListener('keydown', handleHotKeyPress);
    document.addEventListener('keyup', handleHotKeyRelease);
    return () => {
      document.removeEventListener('keydown', handleHotKeyPress);
      document.removeEventListener('keyup', handleHotKeyRelease);
    };
  }, [shortcuts, isShortcutPressed]);

  return {
    isShortcutPressed: (key: string) => isShortcutPressed[key],
  };
};
