import { useFrame } from '@react-three/fiber';
import { useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { meshRenderOrder } from 'shared/map/defaults/mesh-render-order';
import { MutableState } from 'shared/map-container/reducer/MutableState';
import { bottomLeftDimensionsToSizeAndPos } from 'shared/map-container/utils/creation.util';
import { isCursorInFacility } from 'shared/map-container/utils/3DmapFunctions';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { makeNoFlyZone } from './makeNoFlyZone';
import { droneZoneDrawMaterial } from './helpers/droneZoneDrawMaterial';

const inProgressNFZ: NoFlyZone = {
  id: 'creation-in-progress',
  name: 'no name',
  type: DroneZoneTypes.noFlyZone,
  description: '',
  sizeAndPosition: { w: 0, l: 0, h: 0, minX: 0, minY: 0, minZ: 0, maxZ: 0 },
  isActive: false,
  isSelected: false,
};

const logPrefix = getLogPrefixForType('COMPONENT', '3D NFZ Creator');

export const NoFlyZoneDrawer = (props: { worldBox: Vec6; drawPositionStart?: Vector3 }) => {
  const { worldBox, drawPositionStart } = props;
  const { interaction } = MutableState.getState();
  const { guidePosition } = interaction;

  const [state, setState] = useState<NoFlyZone>({
    ...inProgressNFZ,
  });

  const height = 0.5;

  useFrame(() => {
    if (!drawPositionStart) return;

    if (isCursorInFacility(worldBox, guidePosition)) {
      setState(makeNoFlyZone(state.name, { start: drawPositionStart, end: guidePosition }, height));
    }
  });

  const { w, l, h, x, y, z } = bottomLeftDimensionsToSizeAndPos(state.sizeAndPosition);

  const drawMaterial = useMemo(() => droneZoneDrawMaterial(w, l), [l, w]);

  if (!drawPositionStart) return null;

  console.debug(logPrefix, `created [${w}, ${l}, ${h}] at [${x}, ${y}, ${z}]`);

  return (
    <mesh position={[x, y, z]} material={drawMaterial} renderOrder={meshRenderOrder.creation}>
      <boxGeometry args={[w, l, h]} />
    </mesh>
  );
};
