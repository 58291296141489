import { DroneZones } from '../../../../model/drone-zones.model';
import { useStyles } from './zone-side-view.styles';
import { getHeightRangeValues } from './utils/getHeightRangeValues';
import { DroneZone } from '../../../../model/drone-zone.model';
import { ZoneSideViewType } from './features/zone-side-view-type/ZoneSideViewType';

export const ZoneSideView = ({
  zones,
  areaHeight,
  selectedZone,
  onSelect,
}: {
  zones: DroneZones[];
  areaHeight: number;
  selectedZone: DroneZone | null;
  onSelect: (zone: DroneZones) => void;
}) => {
  const { classes } = useStyles();

  const sortedLegendRange = getHeightRangeValues({ min: 0, zones, max: areaHeight });
  const sortedZones = zones.sort(
    (zoneA, zoneB) => zoneB.sizeAndPosition.maxZ - zoneA.sizeAndPosition.maxZ,
  );

  const isSelectionDisabled = sortedZones.length === 1;

  return (
    <div className={classes.areaGraph}>
      <div className={classes.areaGraphLegend}>
        {sortedLegendRange.map((legend) => (
          <span
            key={legend.id}
            className={classes.areaGraphLegendItem}
            style={{ insetBlockEnd: legend.position }}
          >
            {legend.value}
          </span>
        ))}
      </div>

      <div className={classes.areaGraphData}>
        {sortedZones.map((zone) => (
          <ZoneSideViewType
            key={zone.id}
            zone={zone}
            areaHeight={areaHeight}
            isSelected={selectedZone?.id === zone.id}
            isDisabled={isSelectionDisabled}
            onClick={onSelect}
          />
        ))}
      </div>
    </div>
  );
};
