import { GridRowHeightParams } from '@mui/x-data-grid-premium';

export const getGridRowHeight = ({ model, densityFactor }: GridRowHeightParams) => {
  const verityStatusNrOfBarcodes = model.verity_status?.barcodes.length ?? 0;
  const wmsStatusNrOfBarcodes = model.wms_status?.barcodes.length ?? 0;
  const nrOfBarcodes =
    wmsStatusNrOfBarcodes > verityStatusNrOfBarcodes
      ? wmsStatusNrOfBarcodes
      : verityStatusNrOfBarcodes;

  if (nrOfBarcodes > 1) {
    return nrOfBarcodes * 20 + 30 * densityFactor;
  }

  return null;
};
