import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { ClassNameMap } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import { openImageInNewTab } from 'common/functions/imageFunctions';
import { Box } from '../Box';
import Spinner from '../Spinner';
import TooltipedIcon from '../TooltipedIcon';

interface IPictureCounterProps {
  systemId: string;
  imageUrls: string | any[];
  classes: ClassNameMap<'root' | 'hidden' | 'spinner' | 'imageTextBox' | 'downloadButton'>;
  activeStep: number;
  location: string;
  imagesDownloading: boolean;
  setImagesDownloading: { (value: React.SetStateAction<boolean>): void; (download: boolean): any };
  slotStatusVersion: any;
  isReview: boolean;
}
/**
 * Picture counter
 * @param props IPictureCounterProps
 * @returns component
 */
export const PictureCounter = ({
  systemId,
  imageUrls,
  classes,
  activeStep,
  location,
  imagesDownloading,
  setImagesDownloading,
  slotStatusVersion,
  isReview,
}: IPictureCounterProps) => {
  if (!isEmpty(imageUrls)) {
    return (
      <Box className={classes.imageTextBox} mb={1}>
        <Typography align="left" color="textSecondary" variant="subtitle1">
          Showing {imageUrls.length} picture(s) for this location.
        </Typography>
        <TooltipedIcon
          tooltip="Download location images"
          icon={
            <IconButton
              onClick={() => {
                // FIX-ME::TR::2021-08-11:: For now, we just allow the download of one image,
                // by opening it in a new tab. Once we understand the CORS issue generated
                // by the zip download, we shall
                openImageInNewTab(
                  systemId,
                  location,
                  setImagesDownloading,
                  slotStatusVersion,
                  isReview,
                  activeStep,
                );
                // downloadImages(systemId, location, setImagesDownloading, facilityName, reportId, verityDate, slotStatusVersion, isReview);
              }}
              size="large"
            >
              <GetAppIcon className={classes.downloadButton} fontSize="small" />
              {imagesDownloading && (
                <Spinner whiteSpinner={true} transparentBackground={true} size={30} />
              )}
            </IconButton>
          }
        />
      </Box>
    );
  }
  return null;
};
