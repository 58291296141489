export const SYSTEM_DETAILS_COLUMNS: any[] = [
  {
    id: 'name',
    searchable: true,
    show: true,
    label: 'Service Name',
    type: 'string',
    align: 'left',
  },
  {
    id: 'version',
    searchable: true,
    show: true,
    label: 'Version',
    type: 'string',
    align: 'center',
  },
  {
    id: 'last_updated',
    searchable: false,
    show: true,
    label: 'Last Updated',
    type: 'string',
    align: 'center',
  },
];
