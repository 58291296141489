import Card from '@mui/material/Card';
import { Button, Typography } from '@mui/material';
import { noFlyZoneCreatorPlaceholderStyle } from './noFlyZoneCreatorPlaceholderStyle';

export const NoFlyZoneCreator = ({ onCancelCreateNFZ }: { onCancelCreateNFZ: () => void }) => {
  const { classes } = noFlyZoneCreatorPlaceholderStyle();

  return (
    <Card sx={{ height: '100%' }}>
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <Typography mb={7}>Draw a new no-fly zone on the map.</Typography>

          <Button
            variant="outlined"
            onClick={onCancelCreateNFZ}
            color="primary"
            fullWidth
            size="medium"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Card>
  );
};
