import { GridColDef } from '@mui/x-data-grid-premium';
import { Chip } from '@mui/material';
import { IRulesActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { BarcodeRow } from './BarcodeRow.model';
import { BarcodeText } from '../components/BarcodeText';
import {
  BarcodeMatchLogic,
  getStatusColorChip,
} from '../../../../../common/functions/barcodes/getStatusColorChip.util';
import { HeaderCell } from '../components/HeaderCell';
import { LinkCell } from '../components/LinkCell';
import { IssueHighlightedContent } from '../components/IssueHighlightedContent';
import { getIsVerityDataStale } from '../../utils/getIsVerityDataStale';

export const barcodeColumns = ({
  barcodeMatchLogic,
  issueLogic,
}: {
  barcodeMatchLogic?: BarcodeMatchLogic | null;
  issueLogic?: IRulesActionSTIssueLogicEnum;
}): GridColDef<BarcodeRow>[] => [
  {
    field: 'barcodeStatus',
    headerName: 'Barcode status',
    renderHeader: HeaderCell,
    groupable: true,
    renderCell: (params: any) => {
      if (!params.value) {
        return null;
      }
      const row = params.row as BarcodeRow;
      const isGrouped = params.rowNode?.type === 'group';
      const rowData = row.locationData?.rowData;
      const isVerityDataStale = rowData && getIsVerityDataStale(rowData);
      const color = isVerityDataStale
        ? undefined
        : getStatusColorChip(params.value, barcodeMatchLogic, issueLogic);

      if (isGrouped && params.value) {
        return (
          <Chip
            label={params.value}
            variant="outlined"
            color={color}
            sx={{
              marginRight: '5px',
            }}
          />
        );
      }

      return row.locationData?.wmsState === 'BARCODE' ||
        row.locationData?.verityState === 'BARCODE' ? (
        <Chip
          label={params.value}
          variant="outlined"
          color={color}
          sx={{
            marginRight: '5px',
          }}
        />
      ) : null;
    },
    flex: 1,
  },
  {
    field: 'expectedContent',
    headerName: 'Expected content',
    renderHeader: HeaderCell,
    renderCell: (params: any) => {
      if (!params.row.barcodeStatus) {
        return params.value;
      }

      const isStale = getIsVerityDataStale(params.row.locationData.rowData);

      const row = params.row as BarcodeRow;
      if (row.locationData?.wmsState === 'BARCODE') {
        return (
          <BarcodeText
            params={params}
            barcodeMatchLogic={barcodeMatchLogic}
            issueLogic={issueLogic}
            isStale={isStale}
          />
        );
      }

      return (
        <IssueHighlightedContent
          value={params.value}
          locationData={row.locationData}
          isStale={isStale}
        />
      );
    },
    flex: 2,
  },
  {
    field: 'contentFound',
    headerName: 'Content found',
    renderHeader: HeaderCell,
    flex: 2,
    renderCell: (params: any) => {
      if (!params.row.barcodeStatus) {
        return params.vale;
      }

      const isStale = getIsVerityDataStale(params.row.locationData.rowData);

      const row = params.row as BarcodeRow;
      if (row.locationData?.verityState === 'BARCODE') {
        return (
          <BarcodeText
            params={params}
            barcodeMatchLogic={barcodeMatchLogic}
            issueLogic={issueLogic}
            isStale={isStale}
          />
        );
      }

      return (
        <IssueHighlightedContent
          value={params.value}
          locationData={row.locationData}
          isStale={isStale}
        />
      );
    },
  },
  {
    field: 'foundAt',
    headerName: 'Found at',
    renderHeader: HeaderCell,
    renderCell: (params) => <LinkCell location={params.value} />,
    flex: 1,
  },
  {
    field: 'shouldBeAt',
    headerName: 'Should be at',
    renderCell: (params) => <LinkCell location={params.value} />,
    renderHeader: HeaderCell,
    flex: 1,
  },
  { field: 'wmsQuantity', headerName: 'WMS quantity', renderHeader: HeaderCell, flex: 1 },
  { field: 'articleNumber', headerName: 'Article number', renderHeader: HeaderCell, flex: 1 },
  {
    field: 'customer',
    headerName: 'Customer',
    renderHeader: HeaderCell,
    flex: 1,
  },
];
