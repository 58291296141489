import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { BarcodeRow, barcodeStatusEnum } from '../models/BarcodeRow.model';
import { matchBarcode } from '../../../../../common/functions/barcodes/matchBarcode.util';

export const transformBarcodeRows = (locationData: ILocationData): BarcodeRow[] => {
  const result = locationData.rowData.contentExpected.map((expected: string, i: number) => {
    const barcodeStatus = matchBarcode(
      locationData.rowData.contentExpected[i],
      locationData.rowData.contentFound[i],
      locationData.rowData.matchArray[i],
    );
    return {
      id: i + 1,
      locationData,
      barcodeStatus,
      expectedContent: expected && expected !== 'No match' ? expected : '',
      contentFound:
        locationData.rowData.contentFound[i] && locationData.rowData.contentFound[i] !== 'No match'
          ? locationData.rowData.contentFound[i]
          : '',
      foundAt:
        barcodeStatus === barcodeStatusEnum.Missing &&
        locationData.rowData.barcodeExpectedFoundOn !== '-'
          ? locationData.rowData.barcodeExpectedFoundOn
          : '',
      shouldBeAt:
        barcodeStatus === barcodeStatusEnum.Unexpected &&
        locationData.rowData.barcodeFoundShouldBeOn !== '-'
          ? locationData.rowData.barcodeFoundShouldBeOn
          : '',
      wmsQuantity:
        locationData.rowData.wmsQty[i] && locationData.rowData.wmsQty[i] !== '-'
          ? locationData.rowData.wmsQty[i]
          : '',
      articleNumber:
        locationData.rowData.wmsArticleNo[i] && locationData.rowData.wmsArticleNo[i] !== '-'
          ? locationData.rowData.wmsArticleNo[i]
          : '',
      customer:
        locationData.rowData.wmsCustomer[i] && locationData.rowData.wmsCustomer[i] !== '-'
          ? locationData.rowData.wmsCustomer[i]
          : '',
    };
  });

  const priority = {
    [barcodeStatusEnum.Missing]: 1,
    [barcodeStatusEnum.Unexpected]: 2,
    [barcodeStatusEnum.Match]: 3,
  };

  return result.sort((a, b) => priority[a.barcodeStatus] - priority[b.barcodeStatus]);
};
