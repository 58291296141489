import { DroneZoneCommandType, DroneZoneDeleteCommand } from '../droneZoneCommand.type';
import { ZoneStatusActions } from '../../../reducer/droneZonePage.actions';
import { ControlledZone } from '../../../../../../shared/map/model/controlled-zone.model';

export const createDroneZoneDeleteCommand: (zone: ControlledZone) => DroneZoneDeleteCommand = (
  zone,
) => ({
  type: DroneZoneCommandType.Delete,
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => {
    dispatchZoneStatus({
      type: 'SHOW_DELETION_MODAL',
      payload: zone,
    });
  },
});
