import { useTheme } from '@mui/material/styles';
import { Hidden, Divider } from '@mui/material';

export const VerticalDivider = () => {
  const theme = useTheme();
  return (
    <Hidden mdDown>
      <Divider
        sx={{
          height: '35px',
          background: '#fff',
          position: 'absolute',
          right: 0,
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
        orientation="vertical"
        flexItem
      />
    </Hidden>
  );
};
