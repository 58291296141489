import TableCell from '@mui/material/TableCell';
import Switch from '@mui/material/Switch';
import Highlighter from 'react-highlight-words';
import isNil from 'lodash/isNil';
import { Box } from 'components/common/Box';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
type EnhancedTableCellSwitchAndStringProps = EnhancedTableCellProp<any> & {
  labelId: string;
  isInputDisabled?: () => boolean;
  showSearch: boolean;
  searchTerm: string;
};

export const EnhancedTableCellSwitchAndString = (props: EnhancedTableCellSwitchAndStringProps) => {
  const { cell, row, onClick, labelId, isInputDisabled, showSearch, searchTerm } = props;

  if (cell === undefined) {
    console.debug(
      'EnhancedTableCellSwitchAndString',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} padding="checkbox">
      <Box style={cell.style()} component="div">
        <Switch
          disabled={isInputDisabled && isInputDisabled()}
          data-testid={`c-${cell.id}-switch`}
          checked={row.canFly}
          inputProps={{
            'aria-labelledby': labelId,
          }}
          onClick={onClick}
        />
        {/* SG-2020-11-24-EXPLANATION: Wrap cell in Highlighter component (used to highlight searched terms)
      only if cell data type is string */}
        {!isNil(row[cell.id]) && (
          <Highlighter
            style={{ marginLeft: '10px' }}
            highlightClassName="YourHighlightClass"
            // If showSearch is disabled then we do not highlight search terms
            searchWords={showSearch ? [searchTerm] : []}
            autoEscape={true}
            textToHighlight={
              cell.format ? cell.format(row[cell.id].toString()) : row[cell.id].toString()
            }
          />
        )}
      </Box>
    </TableCell>
  );
};
