import { extractVersionNumber } from './extractVersionNumber';

/**
 * Extract the Major . Minor version of the SW
 * e.g.: udb-rc-2.4.0 => 2.4
 * @param version full version
 * @returns major.minor version
 */
export const extractMajorMinorVersion = (version: string) => {
  const regex = /^(\d+)\.(\d+)/;
  const fullVersion = extractVersionNumber(version);
  if (!fullVersion) return null;

  const versionNumber = regex.exec(fullVersion);
  return versionNumber !== null ? versionNumber[0] : null;
};
