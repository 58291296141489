import { WebSocketClient } from 'shared/web-socket/web-socket.model';
import { IGetEstimatedObstaclesResponseST } from 'codegen/nav_simulation';
import { commissioningPageSubscribers } from './commissioning-page.subscribers';

export const subscribeToEstimatedObstacles = ({
  socket,
  flightDomainId,
  onSubscribe,
}: {
  socket: WebSocketClient;
  flightDomainId: string;
  onSubscribe: (data: IGetEstimatedObstaclesResponseST['estimated_obstacles']) => void;
}) => {
  socket.subscribe({
    onMessage(data: IGetEstimatedObstaclesResponseST): void {
      if (flightDomainId === data.flight_domain_id) {
        onSubscribe(data.estimated_obstacles);
      }
    },
    channel: commissioningPageSubscribers.estimatedObstacles.channel,
    delegate: commissioningPageSubscribers.estimatedObstacles.delegate,
  });
};
