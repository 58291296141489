import { ImgHTMLAttributes } from 'react';
import { TransformComponent, TransformWrapper, ReactZoomPanPinchProps } from 'react-zoom-pan-pinch';

export interface IZoomPanPinchProps {
  imageProps: ImgHTMLAttributes<HTMLImageElement>;
  transformWrapperProps: ReactZoomPanPinchProps;
}
/**
 * Zoom pan pinch
 * @param props IZoomPanPinchProps
 * @returns component
 */
export const ZoomPanPinch = ({ imageProps, transformWrapperProps }: IZoomPanPinchProps) => (
  <TransformWrapper {...transformWrapperProps}>
    <TransformComponent>
      <img alt={imageProps.alt} {...imageProps} />
    </TransformComponent>
  </TransformWrapper>
);
