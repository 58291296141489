import isEmpty from 'lodash/isEmpty';

import { useSnackbar, VariantType } from 'notistack';

import { useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// notifications
import { reportNotifications, mapUpdateNotifications } from 'common/notifications';

// stores
import ReportStore from 'udb/inventory/reports/reducer/report-store/ReportStore';
import { LocalStore } from 'common/functions/storageFunctions';
import { ReportSummary } from 'udb/inventory/reports/reducer/report-store/ReportStore.model';
import { useFacilityLevelStore } from '../store/FacilityLevelStore/facilityLevelStore';
import { useClientLevelStore } from '../store/ClientLevelStore/clientLevelStore';
import { useUserLevelStore } from '../store/UserLevelStore/userLevelStore';

// hooks
import { useRequestController } from './requestController/useRequestController';
import {
  displayNotification,
  getFacilityFromNotification,
} from './webSocketNotificationsFunctions';
import { InventoryActionNames } from '../store/FacilityLevelStore/facilityLevelActions';

/**
 * Handle display notification interface
 */
interface IHandleDisplayNotification {
  variant: VariantType;
  text: string;
  linkText?: string;
  url?: string;
  target?: string;
}

/**
 * use web socket notifications hooks
 */
export const useWebSocketNotifications = () => {
  // state - global
  const { stateFacilityLevel, dispatchFacilityLevel } = useFacilityLevelStore();
  const { stateUserLevel } = useUserLevelStore();
  const { stateClientLevel } = useClientLevelStore();
  const { requestController } = useRequestController('useWebSocketNotifications');

  // location
  const location = useLocation();
  const currentUrlPath = location.pathname;

  const { enqueueSnackbar } = useSnackbar();

  const { systemId } = useParams();

  const handleDisplayNotification = useCallback(
    ({ variant, text, linkText = '', url = '', target = '' }: IHandleDisplayNotification) =>
      displayNotification({ variant, text, linkText, url, target, enqueueSnackbar }),
    [enqueueSnackbar],
  );

  /**
   * Notifications
   * Show snackbar when a report is updated
   * done
   */
  useEffect(() => {
    let notificationReportUpdate = stateFacilityLevel.inventory?.reportUpdate?.data;
    const facilitySystemId = String(stateFacilityLevel.inventory.reportUpdate?.data?.systemId);
    const timezone = LocalStore.getTimezone();

    // Prevent loading report summaries if:
    // - the data received from the websocket is empty
    // - the report belongs to a facility that is not the one the user has currently loaded
    // - the timezone of the facility is not yet available on the FE (when loading set MFA page, for example)
    const shouldMakeRequest =
      !isEmpty(notificationReportUpdate) && timezone && systemId === facilitySystemId;

    if (shouldMakeRequest) {
      requestController.doRequest({
        request: ReportStore.getReportSummary,
        requestParams: [systemId, notificationReportUpdate.report_id, requestController.signal],
        callbackSuccess: (r: ReportSummary) => {
          notificationReportUpdate = {
            ...notificationReportUpdate,
            report_name: r.fullResponse.request.report_name,
          };

          dispatchFacilityLevel({
            type: InventoryActionNames.WS_REPORT_STATUS_UPDATE,
            payload: { data: r },
          });

          reportNotifications({
            systemId: systemId!,
            data: notificationReportUpdate,
            facility: getFacilityFromNotification(
              notificationReportUpdate,
              stateClientLevel.facilityList,
            ),
            enqueueSnackbar,
            dispatch: dispatchFacilityLevel,
          });
        },
        messageErrorFallback: 'An error occurred when loading the report summary',
      });
    }
  }, [
    enqueueSnackbar,
    stateClientLevel.facilityList,
    stateFacilityLevel.inventory.reportUpdate,
    dispatchFacilityLevel,
    requestController,
    systemId,
  ]);

  /**
   * Show snackbar when the map is updated
   * done
   */
  useEffect(() => {
    const notificationFacilityMapUpdate = stateFacilityLevel.facilityMapUpdate?.data;
    if (!isEmpty(notificationFacilityMapUpdate)) {
      mapUpdateNotifications(
        systemId!,
        getFacilityFromNotification(notificationFacilityMapUpdate, stateClientLevel.facilityList),
        notificationFacilityMapUpdate,
        enqueueSnackbar,
        dispatchFacilityLevel,
        currentUrlPath,
        stateUserLevel.username,
      );
    }
  }, [
    stateFacilityLevel.facilityMapUpdate,
    stateClientLevel.facilityList,
    dispatchFacilityLevel,
    enqueueSnackbar,
    currentUrlPath,
    stateUserLevel.username,
    systemId,
  ]);

  /**
   * Show snackbar when warehouse export is available
   * done
   */
  useEffect(() => {
    const notificationWarehouseExportAvailable =
      stateFacilityLevel.inventory.warehouseExportAvailable?.data;

    if (!isEmpty(notificationWarehouseExportAvailable)) {
      const facilityName = getFacilityFromNotification(
        notificationWarehouseExportAvailable,
        stateClientLevel.facilityList,
      )?.name;

      const presignedLink = notificationWarehouseExportAvailable.presigned_url;
      if (!isEmpty(presignedLink)) {
        handleDisplayNotification({
          variant: 'info',
          text: `The warehouse export you requested for "${facilityName}" is now available `,
          linkText: 'here',
          url: presignedLink,
          target: '_blank',
        });

        // Clean up the store variable that stores the WebSocket message
        dispatchFacilityLevel({ type: InventoryActionNames.WS_WAREHOUSE_EXPORT, payload: null });
      }
    }
  }, [
    stateFacilityLevel.inventory.warehouseExportAvailable,
    stateClientLevel.facilityList,
    dispatchFacilityLevel,
    handleDisplayNotification,
  ]);

  /**
   * Show snackbar when report export is available
   * ongoing
   */
  useEffect(() => {
    const notificationReportExportAvailable =
      stateFacilityLevel.inventory.reportExportAvailable?.data;

    if (!isEmpty(notificationReportExportAvailable)) {
      const facilityName = getFacilityFromNotification(
        notificationReportExportAvailable,
        stateClientLevel.facilityList,
      )?.name;

      const presignedLink = notificationReportExportAvailable.presigned_url;
      const reportName = notificationReportExportAvailable.report_name;

      if (!isEmpty(presignedLink)) {
        handleDisplayNotification({
          variant: 'info',
          text: `The export you requested for the report "${reportName}" from "${facilityName}" is now
          available `,
          linkText: 'here',
          url: presignedLink,
          target: '_blank',
        });
        dispatchFacilityLevel({ type: InventoryActionNames.WS_REPORT_EXPORT, payload: null });
      }
    }
  }, [
    stateFacilityLevel.inventory.reportExportAvailable,
    stateClientLevel.facilityList,
    dispatchFacilityLevel,
    handleDisplayNotification,
  ]);
};
