import { TimeRange } from '../model/timeRange.model';
import { OperatingHoursEventST } from '../features/droneFlightHours/model/operatingHours.model';

const createDefaultTimeRange = (): TimeRange => ({
  fromHours: 0,
  fromMinutes: 0,
  toHours: 24,
  toMinutes: 0,
});

export const convertOperatingHoursToTimeRanges = (
  operatingHours: OperatingHoursEventST[],
  weekDay: number,
  defaultTimeRange?: TimeRange,
): TimeRange[] =>
  operatingHours
    .filter(
      (operatingHours: OperatingHoursEventST) =>
        operatingHours.start.week_day === weekDay || operatingHours.end.week_day === weekDay,
    )
    .map((operatingHours: OperatingHoursEventST) => {
      const timeRange: TimeRange = defaultTimeRange || createDefaultTimeRange();
      if (operatingHours.start.week_day === weekDay) {
        const fromTime = operatingHours.start.timepoint.split(':');
        timeRange.fromHours = Number(fromTime[0]);
        timeRange.fromMinutes = Number(fromTime[1]);
      }
      if (operatingHours.end.week_day === weekDay) {
        const toTime = operatingHours.end.timepoint.split(':');
        timeRange.toHours = Number(toTime[0]);
        timeRange.toMinutes = Number(toTime[1]);
      }

      return timeRange;
    })
    .sort(
      (timeRangeA: TimeRange, timeRangeB: TimeRange) => timeRangeA.fromHours - timeRangeB.fromHours,
    );
