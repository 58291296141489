import { MapLegendDroneZone } from '../../models/DroneZoneLegend.model';
import { DroneZoneMapLegendItem } from '../drone-zone-map-legend-item/DroneZoneMapLegendItem';
import { useStyles } from './DroneZoneMapLegendZoneType.styles';

type DroneZoneMapLegendZoneTypeProps = Pick<MapLegendDroneZone, 'label' | 'subType'>;

export const DroneZoneMapLegendZoneType = ({ subType, label }: DroneZoneMapLegendZoneTypeProps) => {
  const { classes, cx } = useStyles();

  const getTypeColour = () => {
    switch (subType) {
      case 'controlled-zone-active':
        return classes.typeControlledZoneActive;
      case 'controlled-zone-inactive':
        return classes.typeControlledZoneInactive;
      case 'no-fly-zone-active':
        return classes.typeNoFlyZoneActive;
      case 'no-fly-zone-inactive':
        return classes.typeNoFlyZoneInactive;
      case 'open-fly-zone':
        return classes.typeOpenFlyZone;
      case 'facility-flight-area-flight-permitted':
        return classes.typeFlightAreaFlightPermitted;
      case 'facility-flight-area-flight-not-permitted':
      default:
        return classes.typeFlightAreaFlightNotPermitted;
    }
  };

  const typeThemeClassName = getTypeColour();

  return (
    <DroneZoneMapLegendItem
      icon={<div className={cx(classes.legendIcon, typeThemeClassName)} />}
      label={label}
    />
  );
};
