import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useEffect, useRef } from 'react';
import { Box } from 'components/common/Box';
import { useStyles } from './styles';

interface IDashboardProps {
  fetchGuestToken: () => Promise<string>;
  dashboardId: string;
  supersetDomain: string;
}

/**
 * Analytics dashboard
 * @param props IDashboardProps
 * @returns component
 */
export const Dashboard = ({ fetchGuestToken, dashboardId, supersetDomain }: IDashboardProps) => {
  const { classes } = useStyles();
  const mountPointRef = useRef(null);

  useEffect(() => {
    mountPointRef.current &&
      embedDashboard({
        id: dashboardId,
        supersetDomain,
        mountPoint: mountPointRef.current,
        fetchGuestToken,
        dashboardUiConfig: {
          hideChartControls: false,
          hideTitle: true,
          filters: {
            expanded: true,
          },
        },
      });
  }, [mountPointRef, fetchGuestToken, supersetDomain, dashboardId]);

  return <Box ref={mountPointRef} className={classes.wrapper} />;
};
