import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarKey, SnackbarProvider, closeSnackbar } from 'notistack';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FacilityLevelStoreProvider } from './store/FacilityLevelStore/facilityLevelStore';
import { UserLevelStoreProvider } from './store/UserLevelStore/userLevelStore';
import { ClientLevelStoreProvider } from './store/ClientLevelStore/clientLevelStore';
import { ClientModalsStoreProvider } from './store/Modals/clientModals/clientModalsStore';
import { FacilityMenuStoreProvider } from './store/FacilityMenuStore/facilityMenuStore';
import { FacilityModalsStoreProvider } from './store/Modals/facilityModals/facilityModalsStore';
import { GroundControlStoreProvider } from './store/GroundControl/groundControlLevelStore';
import { GroundControlModalsStoreProvider } from './store/Modals/groundControlModals/groundControlModalsStore';
import { SettingsContextProvider } from './store/Settings/settingsStore';
import { MapStoreProvider } from './shared/map-container/reducer/3DmapStore';
import { customTheme } from './customTheme';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const NotistackCloseButton = ({ snackbarId }: { snackbarId: SnackbarKey }) => (
  <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
    <ClearIcon style={{ color: customTheme.palette.secondary.light }} />
  </IconButton>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const Providers = ({ children }: { children: ReactNode }) => (
  <SnackbarProvider
    maxSnack={3}
    preventDuplicate
    action={(snackbarId) => NotistackCloseButton({ snackbarId })}
  >
    <BrowserRouter>
      <CacheProvider value={muiCache}>
        <MuiThemeProvider theme={customTheme}>
          <QueryClientProvider client={queryClient}>
            <UserLevelStoreProvider>
              <ClientLevelStoreProvider>
                <FacilityLevelStoreProvider>
                  <SettingsContextProvider>
                    <ClientModalsStoreProvider>
                      <FacilityMenuStoreProvider>
                        <FacilityModalsStoreProvider>
                          <MapStoreProvider>
                            <GroundControlStoreProvider>
                              <GroundControlModalsStoreProvider>
                                {children}
                              </GroundControlModalsStoreProvider>
                            </GroundControlStoreProvider>
                          </MapStoreProvider>
                        </FacilityModalsStoreProvider>
                      </FacilityMenuStoreProvider>
                    </ClientModalsStoreProvider>
                  </SettingsContextProvider>
                </FacilityLevelStoreProvider>
              </ClientLevelStoreProvider>
            </UserLevelStoreProvider>
          </QueryClientProvider>
        </MuiThemeProvider>
      </CacheProvider>
    </BrowserRouter>
  </SnackbarProvider>
);

export default Providers;
