import { IIssue1STStateEnum } from 'codegen/report';
import { DISPLAY_ISSUE_TYPES_KEYS } from 'common/issueTypesAndStates';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const initialLocationData: ILocationData = {
  userOverride: '',
  verityState: '-',
  location: '',
  version: '-',
  verityValue: '',
  wmsState: '-',
  wmsValue: '',
  slotStatusVersion: '',
  isReview: false,
  userOverrideValue: '',
  length: 0,
  issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
  id: '',
  imageIds: [],
  rowData: {
    actions: null,
    state: IIssue1STStateEnum.New,
    barcodeExpectedFoundOn: '',
    barcodeFoundShouldBeOn: '',
    contentExpected: [],
    contentFound: [],
    contentFoundDate: '',
    issueId: '',
    LocationName: '',
    matchArray: [],
    wmsArticleNo: [],
    wmsCustomer: [],
    wmsDate: '',
    wmsQty: [],
    customer: '',
    slotStatus: {
      verity_status: {
        result_id: '',
      },
    },
  },
};
