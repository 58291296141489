import { USER_GROUPS } from 'common/userGroups';
import { TokenManager } from 'common/tokenManager';
import { isPermissionWithinRoles } from './isPermissionWithinRole';
import { Permission } from './permissions.model';
import { Role } from './role.model';
import { rolesFromUserGroups } from './roleFromUserGroups';

export const userHasPermission = (permission: Permission): boolean => {
  const tokenManager = TokenManager.getInstance();

  const userGroups: USER_GROUPS[] | undefined = tokenManager.userGroupsFromAccessToken();
  const roles: Role[] = rolesFromUserGroups(userGroups!);

  return isPermissionWithinRoles(permission, roles);
};

export const userHasSomePermissions = (permissions: Permission[]): boolean =>
  permissions.some((permission: Permission) => userHasPermission(permission));
