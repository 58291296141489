import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Typography } from '@mui/material';
import { IVeritySlotStatusStateST, IWMSSlotStatusSTStateEnum } from 'codegen/location_information';
import TooltipedIcon from 'components/common/TooltipedIcon';

export const BarcodesCount = ({
  wmsState,
  verityState,
  barcodes,
  colors,
}: {
  wmsState: '-' | IWMSSlotStatusSTStateEnum;
  verityState: '-' | IVeritySlotStatusStateST;
  barcodes: { missing: number; unexpected: number; match: number };
  colors: { missing: string; unexpected: string; match: string };
}) => (
  <Box component="div" display="flex" flexDirection="row" gap={4} alignItems="center">
    {wmsState === 'BARCODE' && (
      <TooltipedIcon
        noMargin
        tooltip="Barcodes which were expected but have not been found"
        icon={
          <Box component="div" display="flex" flexDirection="row" gap={1}>
            <FiberManualRecordIcon sx={{ color: colors.missing }} />
            <Typography data-testid="c-loc-mod-missing-label">Missing:</Typography>
            <Typography data-testid="c-loc-mod-missing-count" style={{ fontWeight: 600 }}>
              {barcodes.missing}
            </Typography>
          </Box>
        }
      />
    )}

    {verityState === 'BARCODE' && (
      <TooltipedIcon
        noMargin
        tooltip="Barcodes which have been found but weren't expected"
        icon={
          <Box component="div" display="flex" flexDirection="row" gap={1}>
            <FiberManualRecordIcon sx={{ color: colors.unexpected }} />
            <Typography data-testid="c-loc-mod-unexpected-label">Unexpected:</Typography>
            <Typography data-testid="c-loc-mod-unexpected-count" style={{ fontWeight: 600 }}>
              {barcodes.unexpected}
            </Typography>
          </Box>
        }
      />
    )}
    {verityState === 'BARCODE' && wmsState === 'BARCODE' && (
      <TooltipedIcon
        noMargin
        tooltip="Barcodes which were expected and have been found"
        icon={
          <Box component="div" display="flex" flexDirection="row" gap={1}>
            <FiberManualRecordIcon sx={{ color: colors.match }} />
            <Typography data-testid="c-loc-mod-match-label">Match:</Typography>
            <Typography data-testid="c-loc-mod-match-count" style={{ fontWeight: 600 }}>
              {barcodes.match}
            </Typography>
          </Box>
        }
      />
    )}
  </Box>
);
