import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// material-ui core
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

// components
import { Box } from '../Box';

// variables, functions, configurations
import { ICustomMultiSelectProps, IOption, IMenuProps } from '../../../interfaces/inputs';

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  formInput: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectOkWrapper: {
    height: 40,
    position: 'sticky',
    zIndex: 1000,
    bottom: 0,
    background: '#FFF',
  },
  selectOkButton: {
    background: theme.palette.primary.main,
    color: '#FFF',
    marginRight: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

const MenuProps: IMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
    },
  },
  variant: 'menu',
  getcontentanchorel: null,
};

const CustomMultiSelect = forwardRef(
  (props: ICustomMultiSelectProps, ref: React.ForwardedRef<void>) => {
    // props
    const {
      variant,
      margin,
      id,
      label,
      name,
      onChange,
      onBlur,
      onClear,
      values,
      valueOptions,
      error = false,
      errorMessage = '',
      disabled,
      testId = '',
      style = {},
    } = props;

    // state variables
    const [internalValues, setInternalValues] = useState(values);
    const [openSelect, setOpenSelect] = useState(false);

    // styles
    const { classes } = useStyles();

    // Clear all selected values
    const clear = () => {
      setInternalValues(() => []);
    };

    // This is needed so that this component can
    // expose methods to its parent components
    useImperativeHandle(ref, () => ({
      clear,
    }));

    useEffect(() => {
      setInternalValues(() => values);
    }, [values]);

    return (
      <FormControl variant={variant} margin={margin} className={classes.formInput}>
        <InputLabel id={`label-for-${id}`}>{label}</InputLabel>
        <Select
          labelId={`label-for-${id}`}
          id={`multi-select-${id}`}
          data-testid={testId}
          name={name}
          multiple
          value={internalValues}
          onChange={(e) => {
            setInternalValues(e.target.value);
            if (onChange) {
              // Call the onChange method received
              // on the props, if it was defined
              onChange(e);
            }
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
          open={openSelect}
          onOpen={() => setOpenSelect(true)}
          onClose={() => setOpenSelect(false)}
          error={error}
          input={<Select label={label} />}
          disabled={disabled}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map(
                (value: string, index: number) =>
                  // Short circuit  the rendering of the chip if the value does not exist
                  // this prevents a bug that occurs when clicking the empty space next to
                  // the buttons "ok" and "clear"
                  valueOptions?.find((el: IOption) => el.value === value) && (
                    <Chip
                      key={`chip-for-${value}`}
                      label={valueOptions?.find((el: IOption) => el.value === value)!.label || ''}
                      className={classes.chip}
                      data-testid={`${testId}-item-${index}`}
                    />
                  ),
              )}
            </div>
          )}
          MenuProps={MenuProps}
          style={style}
        >
          {Array.isArray(valueOptions) &&
            valueOptions.map((option: any, index: number) => (
              <MenuItem
                key={`menu-item-${option.label}-${option.value}`}
                value={option.value}
                data-testid={`${testId}-menu-item-${index}`}
              >
                {option.label}
              </MenuItem>
            ))}
          <Box p={1} display="flex" className={classes.selectOkWrapper}>
            <Button
              data-testid={`${testId}-button-ok`}
              className={classes.selectOkButton}
              fullWidth
              color="primary"
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                setOpenSelect(false);
              }}
            >
              Ok
            </Button>
            <Button
              data-testid={`${testId}-button-clear`}
              color="primary"
              fullWidth
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setOpenSelect(false);
                setInternalValues(() => []);
                if (onClear) {
                  onClear();
                }
              }}
            >
              Clear
            </Button>
          </Box>
        </Select>
        <Box>
          <FormHelperText className={classes.errorText} data-testid={`${testId}-helper-text`}>
            {errorMessage}
          </FormHelperText>
        </Box>
      </FormControl>
    );
  },
);

export default CustomMultiSelect;
