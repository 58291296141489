import { useCallback, useState } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';

const createNewObject = (): Record<string, never> => ({});

/**
 * Forces a re-render of the component.
 * @returns forceUpdate function
 */
export const useForceUpdate = (componentName: string): VoidFunction => {
  const [, setValue] = useState<Record<string, never>>(createNewObject);
  const logPrefix = getLogPrefixForType('HOOK', 'useForceUpdate', componentName);

  return useCallback(
    (caller?: string): void => {
      const by = caller ? `by ${caller}` : '';
      console.debug(logPrefix, '=> force update called ', by);
      setValue(createNewObject());
    },
    [logPrefix],
  );
};
