import { useEffect, useRef } from 'react';

/**
 * Internal implementation of the hook used to mimic the "componentDidMount" functionality
 * @returns current mount status
 */
function useDidMount() {
  const didMountRef = useRef(true);

  useEffect(() => {
    didMountRef.current = false;
  }, []);
  return didMountRef.current;
}

/**
 * hook mimicking the functionality of "componentDidMount" on react class components
 * @param effectOnMount effect to be executed on mount
 */
export const useComponentDidMount = (effectOnMount: Function) => {
  const didMount = useDidMount();

  useEffect(() => {
    if (didMount) {
      effectOnMount();
    }
  }, [didMount, effectOnMount]);
};
