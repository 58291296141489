import { alpha, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

/**
 * Create connect drone styles
 * @param theme Theme param
 * @returns connect drone styles
 */
export const connectDroneStyles = makeStyles()((theme: Theme) => ({
  dot: {
    width: 13,
    height: 13,
    marginRight: theme.spacing(1.5),
  },
  dotActive: {
    backgroundColor: alpha(theme.palette.textSecondary.main, 0.7),
  },
  dots: {
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
  cardWrapper: {
    position: 'relative',
    height: '650px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  buttonBack: {
    marginRight: theme.spacing(1),
  },
  cardActions: {
    display: 'flex',
    padding: theme.spacing(1),
    width: '100%',
  },
  cardContentSection: {
    height: '100%',
    overflowY: 'auto',
    padding: 0,
  },
  backdrop: {
    position: 'absolute',
  },
}));
