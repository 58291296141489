import { IRequestController } from 'hooks';
import { IWarehouseStatus } from 'interfaces';
import WarehouseStore from 'store/WarehouseStore';

/**
 *  Get occupancy data for summary card
 */
export const getOccupancyData = (
  systemId: string,
  requestController: IRequestController,
  setSpinner: React.Dispatch<React.SetStateAction<number>>,
  setData: React.Dispatch<React.SetStateAction<IWarehouseStatus>>,
) => {
  const { signal } = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: WarehouseStore.getOccupancy,
    requestParams: [systemId, signal],
    callbackBeforeSend: () => setSpinner(1),
    callbackSuccess: setData,
    messageErrorFallback: 'The Occupancy could not be fetched.',
    callbackFinally: () => setSpinner(0),
  });
};
