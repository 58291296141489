import { Box, useTheme } from '@mui/material';
import {
  issueTypeEnumToColor,
  AllIssueTypes,
  muiColorToTheme,
} from 'common/functions/issues/issueColorFunctions';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export const IssueHighlightedContent = ({
  value,
  locationData,
  isStale,
}: {
  value: string;
  locationData: ILocationData;
  isStale?: boolean;
}) => {
  const theme = useTheme();

  let color = {
    main: 'GrayText',
  };

  if (locationData.issueType && !isStale) {
    const issueType = issueTypeEnumToColor[locationData.issueType as AllIssueTypes];
    color = muiColorToTheme(issueType, theme);
  }

  return (
    <Box component="div" color={color.main}>
      {value}
    </Box>
  );
};
