import { DroneZoneCancelEditingCommand, DroneZoneCommandType } from '../droneZoneCommand.type';
import { ZoneStatusActions } from '../../../reducer/droneZonePage.actions';
import { ControlledZone } from '../../../../../../shared/map/model/controlled-zone.model';

export const createDroneZoneCancelEditingCommand: (
  zone: ControlledZone,
) => DroneZoneCancelEditingCommand = (zone) => ({
  type: DroneZoneCommandType.CancelEditing,
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => {
    dispatchZoneStatus({ type: 'CANCEL_EDITING_DRONE_ZONE', payload: zone });
  },
});
