import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  openFlyZone: {
    color: '#ffffff',
    borderColor: '#EA7600',
    background: alpha('#EA7600', 0.4),

    '&::after': {
      borderColor: '#EA7600',
    },
  },
  openFlyZoneActive: {
    background: alpha('#EA7600', 0.94),
  },
}));
