import { Button } from '@mui/material';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { IVeritySlotStatusStateST } from 'codegen/location_information';
import { locationStyles } from '../../styles';
import { hasOverrideValue } from './utils/amendFunctions';

export const AmendValuesSaveButton = (props: {
  locationData: ILocationData;
  validateAndSubmitFormData: () => void;
  overwriteMode: string;
  overwriteContentType: string;
  overwriteVerityCorrect: string;
  overwriteBarcodes: string;
}) => {
  const {
    locationData,
    validateAndSubmitFormData,
    overwriteMode,
    overwriteContentType,
    overwriteVerityCorrect,
    overwriteBarcodes,
  } = props;
  const { classes } = locationStyles();

  const isOverwriteVerityCorrectMissing = overwriteVerityCorrect === '';
  const isOverwriteModeMissing = overwriteMode === '';
  const isOverwriteContentTypeMissing = overwriteContentType === '';
  const isBarcodeTypeWithMissingBarcodes =
    overwriteContentType === IVeritySlotStatusStateST.Barcode && overwriteBarcodes === '';

  return (
    <Button
      className={classes.actionButton}
      onClick={() => validateAndSubmitFormData()}
      disabled={
        (isOverwriteVerityCorrectMissing && !hasOverrideValue(locationData)) ||
        isOverwriteModeMissing ||
        isOverwriteContentTypeMissing ||
        isBarcodeTypeWithMissingBarcodes
      }
      variant="contained"
      color="primary"
    >
      Save changes for {locationData.location}
    </Button>
  );
};
