import { createControlledZone } from './createControlledZone';
import { getControlledZones } from './getControlledZones';
import { updateControlledZone } from './updateControlledZone';
import { deleteControlledZone } from './deleteControlledZone';
import { setControlledZoneActive } from './setControlledZoneActive';
import { toggleControlledZoneActiveState } from './toggleControlledZoneActiveState';

export const ControlledZoneService = {
  getControlledZones,
  createControlledZone,
  updateControlledZone,
  deleteControlledZone,
  setControlledZoneActive,
  toggleControlledZoneActiveState,
};
