import { Typography } from '@mui/material';
import YesNoModal from 'components/ModalsAndPopups/YesNoModal';

type RemoveExclusionModalProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

const bodyText = (
  <>
    <Typography color="textSecondary">
      By removing the override from this location, the permission value will be set to default.
    </Typography>
    <Typography color="textSecondary" mt={3}>
      Are you sure you want to remove this override?
    </Typography>
  </>
);

/**
 * Remove exclusion modal
 * @param props RemoveExclusionModalProps
 * @returns JSX.Element
 */
export const RemoveExclusionModal = ({ onSubmit, onCancel }: RemoveExclusionModalProps) => (
  <YesNoModal
    bodyText={bodyText}
    noLabel="Cancel"
    yesLabel="Remove"
    opened
    title="Remove override"
    subtitle={undefined}
    onYesFunction={onSubmit}
    loading={false}
    onNoFunction={onCancel}
    yesCTA
  />
);
