import { Box, Tooltip, Typography } from '@mui/material';
import { DATETIME_FORMAT_WITH_WEEKDAY } from 'common/datetimeFormats';
import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import TooltipedIcon from 'components/common/TooltipedIcon';
import moment from 'moment';
import { DroneOnChargerIcon } from 'pages/GroundControl/Icons/DroneOnChargerIcon';

export const LastScan = ({ contentFoundDate }: { contentFoundDate: string }) => {
  const timezone = LocalStore.getTimezone();
  return (
    <Box component="div" display="flex" flexDirection="row" gap={1} alignItems="center">
      <TooltipedIcon
        noMargin
        tooltip="Latest drone scan for this location"
        icon={
          <DroneOnChargerIcon isActive={true} colorActive="GrayText" colorInactive="GrayText" />
        }
      />
      {contentFoundDate && contentFoundDate !== '-' ? (
        <Tooltip title={formatDateInTimezone(contentFoundDate, DATETIME_FORMAT_WITH_WEEKDAY) || ''}>
          <Box component="div" display="flex" flexDirection="row" gap={1}>
            <Typography data-testid="c-loc-mod-last-scan-label" variant="body2" color="GrayText">
              Last scan
            </Typography>
            <Typography data-testid="c-loc-mod-last-scan-date" variant="body2" color="inherit">
              {moment(contentFoundDate).tz(timezone).fromNow()}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <Typography data-testid="c-loc-mod-last-scan-label" variant="body2" color="inherit">
          Never scanned
        </Typography>
      )}
    </Box>
  );
};
