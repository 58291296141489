import { geometryToBottomLeftDimensions } from 'shared/map-container/utils/creation.util';
import { IFlightAreaST } from 'codegen/flight_areas';
import { FlightArea } from './flightAreas.model';

export const transformIntoFlightArea = (route: IFlightAreaST & { id: string }): FlightArea => {
  const { w, l, h, minX, minY, minZ, maxZ } = geometryToBottomLeftDimensions(route.geometry);

  return {
    id: route.id,
    sizeAndPosition: { w, l, h, minX, minY, minZ, maxZ },
  };
};
