import { sortRows, filterRowsBySearchTerm } from 'common/Tables/tableFunctions';
import { HeadCellProp } from '../types/cell';
import { TableRows, OrderValue } from '../types/rows';

export const filterOrderTableRows = (
  rows: TableRows,
  searchTerm: string,
  orderBy: string,
  order: OrderValue,
  headCells: Array<HeadCellProp>,
): TableRows => sortRows(filterRowsBySearchTerm(rows, searchTerm, headCells), order, orderBy);
