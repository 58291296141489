// libraries
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// material-ui core
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

// variables and functions
import { getVersionIfAvailable } from 'common/functions/otherFunctions';
import { DATETIME_FORMAT_FOOTER } from 'common/datetimeFormats';
import { Box } from 'components/common/Box';

// images
import { LocalStore } from 'common/functions/storageFunctions';
import logoFooter from '../../assets/verity-logo.png';

// components

const useStyles = makeStyles()((theme: Theme) => ({
  footer: {
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.primary.contrastText,
    bottom: 0,
    right: 0,
    padding: theme.spacing(1, 2),
    lineHeight: '1',
    boxSizing: 'border-box',
    zIndex: '1200',
  },
  footerWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  footerElement: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  time: {
    marginRight: theme.spacing(1),
  },
}));

const Footer = () => {
  // styles
  const { classes } = useStyles();

  const [currentTime, setCurrentTime] = useState('');

  const timezone = LocalStore.getTimezone();
  const facilityName = localStorage.getItem('facilityName');

  // facility timezone and name
  moment.tz.setDefault(timezone);
  const tooltipText = facilityName ? `Current time at ${facilityName}` : 'Current time';

  // Extract version from string
  const renderVersion = () => {
    const latestVersion = getVersionIfAvailable().version;
    if (latestVersion !== 'not available') {
      const extractedVersion = latestVersion.match(/\d+/g); // ["1", "5", "0", "1"]
      const version = extractedVersion?.slice(0, 3).join('.'); // "1.5.0"
      const rcVersion = extractedVersion && extractedVersion[3] ? `-rc${extractedVersion[3]}` : ''; // rc1
      return (
        <Box alignItems="center" display="flex">
          <Typography sx={{ color: '#757575' }} variant="body2">
            v{version}
            {rcVersion}
          </Typography>
          <Divider sx={{ height: 16, mx: 1, borderColor: '#787878' }} orientation="vertical" />
        </Box>
      );
    }
    return undefined;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format(DATETIME_FORMAT_FOOTER));
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTime]);

  return (
    <Box className={classes.footer}>
      <Box className={classes.footerWrapper}>
        <Box display="flex">
          <Box pr={2} className={classes.logo}>
            <img height="20px" src={logoFooter} alt="Verity logo" />
          </Box>
        </Box>
        <Box className={classes.footerElement}>
          <Box alignItems="center" display="flex">
            {renderVersion()}
            <Tooltip title={tooltipText}>
              <Typography className={classes.time} variant="body2">
                {currentTime}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
