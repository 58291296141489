import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
type EnhancedTableCellCheckboxProps = EnhancedTableCellProp<any> & {
  isItemSelected: boolean;
  labelId: string;
};

export const EnhancedTableCellCheckbox = (props: EnhancedTableCellCheckboxProps) => {
  const { cell, isItemSelected, labelId, onClick } = props;

  if (cell === undefined) {
    console.debug(
      'EnhancedTableCellCheckbox',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} padding="checkbox">
      <Checkbox
        checked={isItemSelected}
        inputProps={{
          'aria-labelledby': labelId,
        }}
        onClick={onClick}
      />
    </TableCell>
  );
};
