import { IAuthProcessData, IUserLevelData } from './IUserLevelStore';

export enum UserLevelActionNames {
  /**
   * Saves received response from getUser function
   * with username, usernameHashed and userGroups
   */
  SET_USER_DATA,
  /**
   * Clear auth store and sets
   * isAuth to false
   */
  SIGNOUT,
  /**
   * Authentication process without accessToken
   *
   * NewPassword, MFA
   */
  AUTH_PROCESS,
  /**
   * Authentication process details that holds
   * authentication type and challengeName
   */
  AUTH_DETAILS,
  /**
   * Clear authentication process details and
   * sets authProcess to false
   */
  CLEAR_AUTH_PROCESS_DETAILS,
  /**
   * Set the Web Socket Authorized flag
   */
  SET_WEB_SOCKET_AUTHORIZED,
}

export type UserLevelAction =
  | { type: UserLevelActionNames.SET_USER_DATA; payload: Partial<IUserLevelData> }
  | { type: UserLevelActionNames.AUTH_PROCESS; payload: boolean }
  | { type: UserLevelActionNames.SIGNOUT }
  | { type: UserLevelActionNames.AUTH_DETAILS; payload: IAuthProcessData }
  | { type: UserLevelActionNames.CLEAR_AUTH_PROCESS_DETAILS }
  | { type: UserLevelActionNames.SET_WEB_SOCKET_AUTHORIZED; payload: boolean };
