import { uniq } from 'lodash';
import { getLocationExclusionHistory } from './GetLocationExclusionHistory';
import { GetExclusionStatusHistoryParams } from '../models/GetExclusionStatusHistoryParams.model';

export const getExclusionStatusHistory = ({
  locations,
  requestController,
  dispatch,
  systemId,
}: GetExclusionStatusHistoryParams) => {
  const exclusionRequestIds: string[] = Object.values(locations).map((l) => {
    const exclusionRequestId =
      typeof l?.slot_settings === 'string' ? '' : l?.slot_settings?.exclusion_request_id || '';

    return exclusionRequestId;
  });

  const uniqueExclusionRequestIds = uniq(exclusionRequestIds).filter(Boolean);

  getLocationExclusionHistory({
    exclusionRequestIds: uniqueExclusionRequestIds,
    requestController,
    dispatch,
    systemId,
  });
};
