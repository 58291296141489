import { createElement } from 'react';
import moment from 'moment-timezone';

import { DATETIME_FORMAT_WITH_WEEKDAY, RELATIVE_DATETIME_FORMAT } from 'common/datetimeFormats';
import { REQUEST_STATES } from 'common/requestStates';
import { INVENTORY_REQUEST_ACTIONS } from 'common/Actions/actionTypes';
import { INPUT_MODES } from 'common/reportSpecifications';
import { sort } from 'common/functions/otherFunctions';
import { convertRruleToUIComponents, getRecurrenceText } from 'common/functionsReportSpec';
import { getReqParamsStringForTablesFromInventoryRequest } from 'common/functions/requestParameterFunctions';
import { IInventoryRequestST } from 'codegen/inventory_request';
import { LocalStore } from 'common/functions/storageFunctions';
import { getReportPriorityIcons } from 'udb/inventory/reports/utils/getReportPriorityIcons';
import { IInventoryRequestInternal, IUpcomingReportsInternal } from '../../interfaces';
import { IAddUpdateRequestParams } from '../../interfaces/requestsInterfaces';
import { IconColor } from '../../ts-types/IconColor';
import RequestServices from '../../services/RequestServices';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

const REQUEST_STATE_ACTION = {
  [REQUEST_STATES.ENABLED]: {
    label: INVENTORY_REQUEST_ACTIONS.DISABLE,
    disabled: false,
  },
  [REQUEST_STATES.DISABLED]: {
    label: INVENTORY_REQUEST_ACTIONS.ENABLE,
    disabled: false,
  },
  [REQUEST_STATES.EXPIRED]: {
    label: INVENTORY_REQUEST_ACTIONS.DISABLE,
    disabled: true,
  },
};

type ReportsInfo = {
  upcomingReports: {
    numberOfReports: number;
    nextSchedulingDate: string;
  };
};

export type GetRequestsResponse = {
  fullResponse: IInventoryRequestST[];
  reportsScheduledAll: IInventoryRequestInternal[];
  reportsInfo: ReportsInfo;
  upcomingReports: IUpcomingReportsInternal[];
};

const getRequests = (systemId: string, signal: AbortSignal): Promise<GetRequestsResponse> => {
  const timezone = LocalStore.getTimezone();

  return RequestServices.getRequests(systemId, signal).then((r) => {
    const reportsScheduled: IInventoryRequestST[] = r.data.items;
    let reportsScheduledAll: IInventoryRequestInternal[] = [];

    reportsScheduled.forEach((request) => {
      const requestData: any = {};

      const inventoryOperations: any = {
        actions: [],
      };

      if (userHasPermission(PERMISSION.SCHEDULED_REPORTS_MANAGEMENT)) {
        inventoryOperations.data = request;
        inventoryOperations.actions.push(
          ...[
            {
              label: INVENTORY_REQUEST_ACTIONS.DELETE,
              disabled: false,
            },
            {
              label: INVENTORY_REQUEST_ACTIONS.EDIT,
              disabled: request.state === REQUEST_STATES.EXPIRED,
            },
            REQUEST_STATE_ACTION[request.state],
          ].filter(Boolean),
        );
      }

      const { isRecurring, until } = convertRruleToUIComponents(request.rrule);
      const priority = getReportPriorityIcons(request.priority);

      requestData.id = `${request.request_id}?version=${request.request_version}`;
      requestData.requestId = request.request_id;
      requestData.requestType = request.report_spec.report_spec_name;
      requestData.rrule = request.rrule;
      requestData.requestName = request.report_name ? request.report_name : '-';
      requestData.requestParams = getReqParamsStringForTablesFromInventoryRequest(request);
      requestData.createdAt = request.created_at ? request.created_at : '-';
      requestData.scheduledFor = request.next_scheduling_time_utc;
      requestData.scheduledBy = request.requesting_user_email
        ? request.requesting_user_email
        : 'n.a.';
      requestData.requestExpiresAt = until || '-';
      requestData.recurrence = getRecurrenceText(
        request.report_spec.report_spec_name,
        isRecurring,
        request.recurrence_description,
      );
      requestData.nextSchedulingDate = request.next_scheduling_time_utc
        ? request.next_scheduling_time_utc
        : '-';
      requestData.status = request.state;
      requestData.actions = inventoryOperations;
      requestData.reportSpec = request.report_spec;
      requestData.allowRedirection = false;
      requestData.priority = createElement(priority.icon, { color: priority.color as IconColor });

      // Add expired inventory request to the list of inventory requests only if allowed in the settings

      reportsScheduledAll = [...reportsScheduledAll, requestData];
    });

    reportsScheduledAll = sort({
      array: reportsScheduledAll,
      sortingOrder: 'asc',
      sortBy: 'scheduledFor',
    });

    // #SG-2020-11-26: Return only requests that are scheduled in a time slot from current
    // point in time, until 3 days after (in the future, the limit will come
    // from settings provided by the verity commissioning team)
    let upcomingReportsList = reportsScheduledAll.filter((request) => {
      const currentDate = moment().utc();
      const endDate = moment(currentDate).utc().add(3, 'days');

      return (
        request.status !== REQUEST_STATES.EXPIRED &&
        ((moment(request.nextSchedulingDate).isAfter(currentDate) &&
          moment(request.nextSchedulingDate).isBefore(endDate)) ||
          request.requestType.startsWith('Automatic'))
      );
    });

    upcomingReportsList = sort({
      array: upcomingReportsList,
      sortingOrder: 'asc',
      sortBy: 'scheduledFor',
    });

    const reportsInfo: ReportsInfo = {
      upcomingReports: {
        numberOfReports: upcomingReportsList.length,
        nextSchedulingDate: upcomingReportsList[0]
          ? moment(upcomingReportsList[0].nextSchedulingDate)
              .tz(timezone)
              .calendar(null, RELATIVE_DATETIME_FORMAT)
          : '',
      },
    };

    const upcomingReports: IUpcomingReportsInternal[] = upcomingReportsList.map((request) => ({
      requestId: request.requestId,
      requestType: request.requestType,
      date: `starts on ${moment(request.scheduledFor)
        .tz(timezone)
        .format(DATETIME_FORMAT_WITH_WEEKDAY)}`,
      recurrence: request.recurrence !== '-',
      recurrenceDescription: request.recurrence,
      requestStatus: request.status,
      totalLocations: null,
      reportName: request.requestName,
      locationsToScan: null,
      label:
        request.reportSpec?.params[0].input_mode === INPUT_MODES.AUTOMATIC
          ? 'starts on file upload'
          : null,
      actions: request.actions,
    }));

    return {
      fullResponse: reportsScheduled,
      reportsScheduledAll,
      reportsInfo,
      upcomingReports,
    };
  });
};

const getRequest = (systemId: string, id: string) =>
  RequestServices.getRequest(systemId, id).then((r) => r);

const addRequest = (systemId: string, data: IAddUpdateRequestParams) =>
  RequestServices.addRequest(systemId, data).then((r) => r);

const deleteRequest = (systemId: string, id: string) =>
  RequestServices.deleteRequest(systemId, id).then((r) => r);

const updateRequest = (systemId: string, id: string, data: IAddUpdateRequestParams) =>
  RequestServices.updateRequest(systemId, id, data).then((r) => r);

const toggleRequestState = (systemId: string, id: string, enable: boolean) =>
  RequestServices.toggleRequestState(systemId, id, enable).then((r) => r);

const countOccurrences = (systemId: string, rrule: string) =>
  RequestServices.countOccurrences(systemId, rrule).then((r) => r);

const getNextOccurrence = (systemId: string, rrule: string, after: string, include: any) =>
  RequestServices.getNextOccurrence(systemId, rrule, after, include).then((r) => r);

const requestStore = {
  getRequests,
  getRequest,
  addRequest,
  deleteRequest,
  updateRequest,
  countOccurrences,
  getNextOccurrence,
  toggleRequestState,
};

export default requestStore;
