import { ISlotSettingsSTExclusionStatusEnum } from 'codegen/report';

const EXCLUSION_STATUS = {
  EXCLUDE: 'excluded',
  INCLUDE: 'included',
  NOT_SET: '',
};

/**
 * create a snackbar message for a location exclusion state update
 * @param batchSize number of the locations in the batch being updated
 * @param batchCount number of the batch
 * @param totalBatches total batches
 * @param exclusionStatus INCLUDE / EXCLUDE
 * @returns message
 */
export const createExclusionSuccessMessage = (
  batchSize: number,
  batchCount: number,
  totalBatches: number,
  exclusionStatus: ISlotSettingsSTExclusionStatusEnum,
) => {
  const nounPlural = batchSize === 1 ? 'location' : 'locations';
  const verbPlural = batchSize === 1 ? 'was' : 'were';

  const message =
    exclusionStatus === 'NOT_SET'
      ? `Override for ${batchSize} ${nounPlural} removed successfully`
      : `${batchSize} ${nounPlural} ${verbPlural} ${EXCLUSION_STATUS[exclusionStatus]} successfully`;

  return `${message} (batch ${batchCount} of ${totalBatches})`;
};
