import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box } from '@mui/material';

export const MarkdownMetric = ({ message }: { message?: string | null }) => {
  const lines = message ? message.split('\n') : [];

  return (
    <Box component="div">
      {lines.map((l, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ReactMarkdown key={i} remarkPlugins={[remarkGfm]}>
          {l}
        </ReactMarkdown>
      ))}
    </Box>
  );
};
