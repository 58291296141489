import { makeDroneStateString } from 'pages/GroundControl/pages/FleetOverview/FleetOverviewFunctions';
import { IDroneStatusSTDroneStateEnum } from 'codegen/nav_simulation';
import { customTheme } from 'customTheme';
import { IFleetTableRows } from '../model/IFleetTableRows';
import { FleetColumn } from '../model/fleetColumn.model';
import { extractMajorMinorVersion } from '../utils/extractMajorMinorVersion';

const greyOutOfflineDrones = (row: IFleetTableRows) =>
  row.wifiStatus === 'Offline' ? { color: 'lightGray' } : {};

export const droneModelSerialPrefixes = {
  PUP: ['IR01', 'IR02'],
  SPUP: ['IP04', 'IR03', 'IR04'],
};

export const FLEET_COLUMNS: FleetColumn[] = [
  {
    id: 'chargerId',
    searchable: true,
    show: true,
    label: 'Charger ID',
    type: 'string',
    align: 'left',
  },
  {
    id: 'id',
    searchable: true,
    show: true,
    label: 'Drone ID',
    type: 'string',
    align: 'left',
  },
  {
    id: 'drone_name',
    searchable: true,
    show: true,
    label: 'Drone Name',
    type: 'string',
    align: 'left',
  },
  {
    id: 'chargerPosition',
    searchable: true,
    show: true,
    label: 'Charger Position',
    type: 'string',
    align: 'left',
  },
  {
    id: 'battery_voltage',
    searchable: true,
    show: true,
    label: 'Voltage',
    type: 'string',
    align: 'center',
    style: greyOutOfflineDrones,
  },
  {
    id: 'battery_level',
    searchable: true,
    show: true,
    label: 'Battery Level',
    type: 'string',
    align: 'center',
    style: greyOutOfflineDrones,
  },
  {
    id: 'battery_state',
    searchable: true,
    show: true,
    label: 'Battery State',
    type: 'string',
    align: 'left',
    style: greyOutOfflineDrones,
  },
  {
    id: 'wifiStatus',
    searchable: true,
    show: true,
    label: 'WiFi Status',
    type: 'string',
    align: 'center',
    style: (row: IFleetTableRows) => ({
      color:
        row.wifiStatus === 'Online'
          ? customTheme.palette.success.main
          : customTheme.palette.error.main,
    }),
  },
  {
    id: 'drone_state',
    searchable: true,
    show: true,
    label: 'Drone State',
    type: 'string',
    align: 'center',
    style: (row: IFleetTableRows) => {
      // highlight the field in red if there is an error
      // but greys it out (regardless of the state) if the
      // drone is not online
      let style = { color: customTheme.palette.success.main };
      if (row.wifiStatus === 'Offline') {
        style = { color: customTheme.palette.disabled };
      } else if (row.drone_state === 'LANDED_IN_ERROR') {
        style = { color: customTheme.palette.error.main };
      }
      return style;
    },
    format: (state: IDroneStatusSTDroneStateEnum) => makeDroneStateString(state),
  },
  {
    id: 'updatedAt',
    searchable: false,
    show: true,
    label: 'Updated At',
    type: 'string',
    align: 'center',
    style: greyOutOfflineDrones,
  },
  {
    id: 'version',
    searchable: true,
    show: true,
    label: 'SW version',
    type: 'string',
    align: 'center',
    style: (row: IFleetTableRows) => {
      if (!row.version) return {};
      // NOTE: when comparing the Drone version with the OC version we do
      // care only about the Major and Minor version of the SW
      // and not about the patch version
      const shortVersion = extractMajorMinorVersion(row.version);
      const shortExpectedVersion = row.expectedVersion
        ? extractMajorMinorVersion(row.expectedVersion)
        : null;
      if (shortVersion === shortExpectedVersion) {
        return { color: customTheme.palette.success.main };
      }
      return { color: customTheme.palette.error.main };
    },
  },
];
