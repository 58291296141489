import { useMemo } from 'react';

export const useLabelColor = ({
  isActive,
  isSelected,
  colors,
}: {
  isActive: boolean;
  isSelected: boolean;
  colors: {
    active: {
      textColor: {
        primary: string;
        secondary: string;
      };
    };
    inactive: {
      textColor: {
        primary: string;
        secondary: string;
      };
    };
  };
}) => {
  const color = useMemo(() => {
    if (isActive && isSelected) {
      return colors.active.textColor.primary;
    }

    if (isActive && !isSelected) {
      return colors.active.textColor.secondary;
    }

    if (!isActive && isSelected) {
      return colors.inactive.textColor.primary;
    }

    if (!isActive && !isSelected) {
      return colors.inactive.textColor.secondary;
    }

    return '#fff';
  }, [isSelected, isActive, colors]);

  return { color };
};
