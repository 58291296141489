import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { Box } from 'components/common/Box';
import ModalBase from 'components/ModalsAndPopups/ModalBase';
import Spinner from 'components/common/Spinner';
import GroundControlServices from '../../services/GroundControlServices';
import { GroundControlModalsActionNames } from '../../store/Modals/groundControlModals/groundControlModalsActions';
import { useRequestController } from '../../hooks';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { GroundControlActionNames } from '../../store/GroundControl/groundControlLevelActions';
import { useGroundControlModalsStore } from '../../store/Modals/groundControlModals/groundControlModalsStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { ModalMessageCoexistence } from './UnlockModalMessageCoexistence';
import { UnlockModalCheckbox } from './UnlockModalCheckbox';

interface IUnlockModalProps {
  opened: boolean;
  facilityName: string;
  flightDomainId: string;
  flightDomainName: string;
  numDronesFlying: number;
}
export const UnlockModal = (props: IUnlockModalProps) => {
  const { opened, flightDomainId, flightDomainName, facilityName, numDronesFlying } = props;

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateGroundControl, dispatchGroundControlLevel } = useGroundControlStore();
  const { dispatchGroundControlModals } = useGroundControlModalsStore();
  const [loading, setLoading] = useState(false);
  const [dualChecked, setDualChecked] = useState(false);
  const isInCoexistenceMode = stateFacilityLevel.facilitySettings?.show_coexistence_map;
  const [droneOnlyModeVerified, setDroneOnlyModeVerified] = useState(false);
  const [unlockMode, setUnlockMode] = useState<'coexistence' | 'droneOnly'>('coexistence');

  const theme = useTheme() as any;

  const systemId = useLocation().pathname.split('/')[1];

  const { requestController } = useRequestController('Unlock System Modal');

  const fullSpeedAllowed = useCallback(() => {
    if (isInCoexistenceMode && unlockMode === 'coexistence') {
      return false;
    }
    return true;
  }, [isInCoexistenceMode, unlockMode]);

  /**
   * Handle flight domain unlock
   */
  const handleSystemUnlock = () => {
    const lp = getLogPrefixForType('FUNCTION', 'handleSystemUnlock');
    const reservation = requestController.reserveSlotForRequest();

    const allowNormalFlightInControlledZones = fullSpeedAllowed();

    return requestController.doRequest({
      request: GroundControlServices.unlockFlightDomain,
      requestParams: [
        systemId,
        flightDomainId,
        allowNormalFlightInControlledZones,
        reservation.signal,
      ],
      callbackBeforeSend: () => {
        console.debug(lp, 'Unlocking the flight domain');
        setLoading(true);
      },
      messageErrorFallback: 'Error occured while unlocking the flight domain.',
      messageSuccess: 'Flight domain unlocked',
      callbackSuccess: async () => {
        console.debug(lp, 'Flight domain unlocked');
        dispatchGroundControlModals({
          type: GroundControlModalsActionNames.UNLOCK_MODAL,
        });
        dispatchGroundControlLevel({
          type: GroundControlActionNames.SET_ALERT_INFO,
          payload: {
            key: 'statusCard',
            variant: stateGroundControl.activeAlertVariant,
            message: 'System has been unlocked.',
          },
        });
      },
      callbackFinally: () => {
        setDualChecked(false);
        setLoading(false);
        setDroneOnlyModeVerified(false);
        setUnlockMode('coexistence');
      },
    });
  };

  const closeModal = () => {
    // NOTE: closing the modal doesn't actually un-mount the component but simply hides it
    // so, if we want the checkbox to be not checked next time the dialog is open
    // we need to reset here the "dualChecked" flag
    setDualChecked(false);
    dispatchGroundControlModals({
      type: GroundControlModalsActionNames.UNLOCK_MODAL,
    });
  };

  const isUnlockButtonDisabled = useMemo(() => {
    const needLegalDisclaimer = !!stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock;
    const isDisabledForLegalReasons = needLegalDisclaimer ? !dualChecked : false;

    const isDisabledForDroneOnlyVerify =
      isInCoexistenceMode && unlockMode === 'droneOnly' ? !droneOnlyModeVerified : false;

    return isDisabledForLegalReasons || isDisabledForDroneOnlyVerify;
  }, [
    droneOnlyModeVerified,
    dualChecked,
    isInCoexistenceMode,
    stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock,
    unlockMode,
  ]);

  return (
    <ModalBase
      testId="c-unlock-modal"
      closeButton={true}
      disableBackdropClick={true}
      opened={opened}
      handleClose={closeModal}
      title={
        <Box display="flex" p={2} alignItems="flex-start">
          <Box mt={0.6} mr={1}>
            <LockOpenIcon
              sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.grey[700] }}
            />
          </Box>
          <Box data-testid="c-unlock-modal-title" textAlign="left" mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="textPrimary.main" variant="h5">
              Unlock System
            </Typography>
            <Typography
              textTransform="uppercase"
              data-testid="c-unlock-modal-subtitle"
              color="secondary"
              variant="subtitle1"
            >
              {`${facilityName} - ${flightDomainName}`}
            </Typography>
          </Box>
        </Box>
      }
      actionButtons={
        <>
          <Button
            data-testid="c-unlock-modal-btn-cancel"
            onClick={closeModal}
            variant="outlined"
            fullWidth
            color="primary"
          >
            Cancel
          </Button>
          <Button
            data-testid="c-unlock-modal-btn-confirm"
            onClick={handleSystemUnlock}
            variant="contained"
            fullWidth
            disabled={isUnlockButtonDisabled}
            color={unlockMode === 'coexistence' ? 'primary' : 'warning'}
            startIcon={<LockOpenIcon />}
          >
            Unlock system
          </Button>
        </>
      }
    >
      {isInCoexistenceMode && (
        <>
          <FormControl fullWidth>
            <RadioGroup
              sx={{
                justifyContent: 'space-between',
              }}
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              value={unlockMode}
              onChange={(e) => setUnlockMode(e.target.value as 'coexistence' | 'droneOnly')}
            >
              <FormControlLabel
                value="coexistence"
                control={<Radio />}
                label="Collaboration Mode"
                sx={{
                  flexGrow: 1,
                  paddingLeft: '1em',
                  paddingRight: '2em',
                  backgroundColor:
                    unlockMode === 'coexistence' ? alpha(theme.palette.info.light, 0.2) : '',
                }}
              />
              <FormControlLabel
                value="droneOnly"
                control={<Radio sx={{ color: 'orange', '&.Mui-checked': { color: 'orange' } }} />}
                label="Drones Only"
                sx={{
                  flexGrow: 1,
                  paddingLeft: '1em',
                  paddingRight: '2em',
                  backgroundColor:
                    unlockMode === 'droneOnly' ? alpha(theme.palette.warning.light, 0.2) : '',
                }}
              />
            </RadioGroup>
          </FormControl>
          <ModalMessageCoexistence
            flightDomainName={flightDomainName}
            unlockMode={unlockMode}
            numDronesFlying={numDronesFlying}
          />
          {unlockMode === 'droneOnly' && (
            <UnlockModalCheckbox
              value={droneOnlyModeVerified}
              setValue={setDroneOnlyModeVerified}
              message={
                <Box component="div">
                  I confirm that I ensured that the facility is empty of people and available for
                  flights in all Controlled Zones using the special <b>Drones Only</b> mode
                </Box>
              }
              testId="c-verify-unlock-drone-only-mode"
            />
          )}
        </>
      )}
      {!isInCoexistenceMode && (
        <Box data-testid="c-lock-modal-content">
          {`
        Unlocking will allow drones to take off and perform their tasks. Please ensure that you have
        followed your internal safety protocols before you enable flying in ${flightDomainName}.`}
        </Box>
      )}

      {loading && <Spinner />}
      {stateFacilityLevel.facilitySettings?.enable_dual_check_on_unlock && (
        <UnlockModalCheckbox
          value={dualChecked}
          setValue={setDualChecked}
          message={stateFacilityLevel.facilitySettings?.dual_check_on_unlock_disclaimer!}
          testId="c-dual-check-on-unlock-disclaimer"
        />
      )}
    </ModalBase>
  );
};
