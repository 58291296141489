import { OpenFlyZone } from 'shared/map/model/open-fly-zone.model';
import { ControlledZones } from 'shared/map/model/controlled-zone.model';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { INoFlyZoneStatus1ST } from 'codegen/no_fly_zone';
import { IControlledZoneStatus1ST } from 'codegen/controlled_zone';
import { IOpenFlyZoneStatus1ST } from 'codegen/open_fly_zone';
import { ClientDroneZones } from '../model/clientDroneZones.model';
import { FlightAreas } from '../model/flightAreas.model';

export enum FlowState {
  LOADING = 'LOADING',
  LIST = 'LIST',
  DRAW = 'DRAW',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export type DroneZonesDTO = {
  noFlyZones: Record<string, INoFlyZoneStatus1ST>;
  controlledZones: Record<string, IControlledZoneStatus1ST>;
  openFlyZones: Record<string, IOpenFlyZoneStatus1ST>;
};

export type ZoneStatusState = {
  flowState: FlowState;
  currentlyEditingZone: null | NoFlyZone;
  currentlyDeletingZone: null | NoFlyZone;
  currentlySelectedZone: null | ClientDroneZones;
  controlledZones: ControlledZones;
  detailsZones: ClientDroneZones[];
  openFlyZones: Record<string, OpenFlyZone>;
  noFlyZones: Record<string, NoFlyZone>;
  flightAreas: FlightAreas;
};

export const zoneStatusInitialState: ZoneStatusState = {
  flowState: FlowState.LOADING,
  controlledZones: {},
  openFlyZones: {},
  noFlyZones: {},
  flightAreas: {},
  detailsZones: [],
  currentlyEditingZone: null,
  currentlyDeletingZone: null,
  currentlySelectedZone: null,
};
