import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { WarehouseOccupancyCard } from './WarehouseOccupancyCard';
import { WarehouseStatusCard } from './WarehouseStatusCard';

export const WarehouseStatusOverview = ({ systemId }: { systemId: string }) => (
  <>
    {userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS) && (
      <WarehouseStatusCard systemId={systemId} />
    )}

    <WarehouseOccupancyCard systemId={systemId} />
  </>
);
