import { REPORT_STATES } from 'common/reportStates';
import { Reports } from '../../Reports.model';
import { GetArchivedReportsParams } from './getArchivedReportsParams.model';
import reportStore from '../../reducer/report-store/ReportStore';

export const getArchivedReports = ({
  requestController,
  dates,
  systemId,
  setData,
  setSpinner,
}: GetArchivedReportsParams) => {
  const archivedReportsRequestParams = { ...dates, states: [REPORT_STATES.DELETED] };
  const archivedRequestReservation = requestController.reserveSlotForRequest();
  return requestController.doRequest({
    callbackBeforeSend: () => setSpinner(() => 1),
    request: reportStore.asyncGetReportSummariesRecursively,
    requestParams: [
      systemId,
      archivedReportsRequestParams,
      requestController,
      archivedRequestReservation.requestId,
      archivedRequestReservation.signal,
      false,
      (r: Reports) => {
        setData(r.reportsArchivedAll);
      },
    ],
    callbackFinally: () => setSpinner(() => 0),
    messageErrorFallback: 'Something went wrong while getting the summaries of archived reports',
  });
};
