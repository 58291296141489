/* eslint-disable prefer-destructuring */
import { Vec6ToSizeAndPos } from '../../utils/creation.util';

/**
 * Draws a black wireframe dox. Used for debugging, to show the world box.
 * @param props { box: Vec6 } 6 number array representing the world box
 * @returns JSX.Element
 */
export const MeshWorldBox = (props: { box: number[] }): JSX.Element => {
  const { box } = props;
  const { w, l, h, x, y, z } = Vec6ToSizeAndPos(box);

  return (
    <mesh position={[x, y, z]} userData={{ box }}>
      <boxGeometry args={[w, l, h]} />
      <meshBasicMaterial wireframe={true} color="black" />
    </mesh>
  );
};
