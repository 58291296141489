import isEmpty from 'lodash/isEmpty';

import { IFacilityModalsAction } from './IFacilityModalsActions';
import { FacilityModalsActionTypes } from './facilityModalsActionTypes';
import { IFacilityModalsState } from './IFacilityModalsStore';
import { initialFacilityModalsState } from './facilityModalInitialState';

export const modalsReducer = (state: IFacilityModalsState, action: IFacilityModalsAction) => {
  switch (action.type) {
    // RESET
    case FacilityModalsActionTypes.RESET:
      return {
        ...initialFacilityModalsState,
      };

    // INVENTORY REQUESTS
    case FacilityModalsActionTypes.TOGGLE_REQUEST_STATE:
      return {
        ...state,
        requestToEdit: action.payload,
        refreshData: action.refreshData,
        toggleRequestStateModalOpened: !isEmpty(action.payload),
      };
    case FacilityModalsActionTypes.EDIT_REQUEST:
      return {
        ...state,
        requestToEdit: action.payload,
        refreshData: action.refreshData,
        editRequestModalOpened: !isEmpty(action.payload),
      };
    case FacilityModalsActionTypes.DELETE_REQUEST:
      return {
        ...state,
        requestToEdit: action.payload,
        refreshData: action.refreshData,
        deleteRequestModalOpened: !isEmpty(action.payload),
      };
    case FacilityModalsActionTypes.AMEND_VERITY_VALUE:
      return {
        ...state,
        overwriteData: action.payload,
        refreshData: action.refreshData,
        amendModalOpened: !isEmpty(action.payload),
      };
    case FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL:
      return {
        ...state,
        locations: action.payload,
        locationInfo: action.locationInfo,
        refreshDataOnClose: action.refreshDataOnClose,
        parentPage: action.parentPage,
        reportId: action.reportId,
        locationModalOpened: !isEmpty(action.payload),
      };
    case FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE:
      return {
        ...state,
        requestToEdit: action.payload,
        refreshData: action.refreshData,
        toggleSnoozeStateModalOpened: !isEmpty(action.payload),
      };

    // REPORTS
    case FacilityModalsActionTypes.REPORT_ABORT:
      return {
        ...state,
        abortReportModalOpened: !isEmpty(action.payload),
        refreshData: action.refreshData,
        abortReportModalData: {
          reportId: action.payload.reportId,
          title: 'Abort report?',
          subtitle: '',
          bodyText: `Are you sure you want to abort "${action.payload.reportName}"?`,
          yesLabel: 'Yes',
          noLabel: 'No',
          onNoFunction: null,
          onCloseDispatch: { type: FacilityModalsActionTypes.REPORT_ABORT, payload: {} },
          yesCTA: false, // sets call to action color on no
        },
      };
    case FacilityModalsActionTypes.REPORT_UPDATE:
      return {
        ...state,
        updateReportModalOpened: !isEmpty(action.payload),
        refreshData: action.refreshData,
        updateReportModalData: {
          reportId: action.payload.reportId,
          title: 'Update report?',
          subtitle: '',
          bodyText: `Are you sure you want to update "${action.payload.reportName}"?`,
          yesLabel: 'Yes',
          noLabel: 'No',
          onNoFunction: null,
          onCloseDispatch: { type: FacilityModalsActionTypes.REPORT_UPDATE, payload: {} },
          yesCTA: true, // sets call to action color on yes
        },
      };
    case FacilityModalsActionTypes.REPORT_ARCHIVE: {
      const archiveReportName = action.payload.reportName
        ? `"${action.payload.reportName}"`
        : 'the selected reports';
      return {
        ...state,
        archiveReportModalOpened: !isEmpty(action.payload),
        refreshData: action.refreshData,
        archiveReportModalData: {
          reportIds: action.payload.reportIds,
          title: 'Archive report?',
          subtitle: '',
          bodyText: `Are you sure you want to archive ${archiveReportName}?`,
          yesLabel: 'Yes',
          noLabel: 'No',
          onNoFunction: null,
          onCloseDispatch: { type: FacilityModalsActionTypes.REPORT_ARCHIVE, payload: {} },
          yesCTA: false, // sets call to action color on no
        },
      };
    }
    case FacilityModalsActionTypes.REPORT_RESTORE: {
      const restoreReportName = action.payload.reportName
        ? action.payload.reportName
        : 'the selected reports';
      return {
        ...state,
        restoreReportModalOpened: !isEmpty(action.payload),
        refreshData: action.refreshData,
        restoreReportModalData: {
          reportIds: action.payload.reportIds,
          title: 'Restore report?',
          subtitle: '',
          bodyText: `Are you sure you want to restore "${restoreReportName}"?`,
          yesLabel: 'Yes',
          noLabel: 'No',
          onNoFunction: null,
          onCloseDispatch: { type: FacilityModalsActionTypes.REPORT_RESTORE, payload: {} },
          yesCTA: false, // sets call to action color on no
        },
      };
    }

    // SYSTEM AND FACILITY
    case FacilityModalsActionTypes.SYSTEM_RESET:
      return {
        ...state,
        resetSystemModalOpened: !isEmpty(action.payload),
        refreshData: action.refreshData,
        resetSystemData: {
          title: 'System Reset?',
          subtitle: `You are about to reset warehouse status data for ${action.payload?.facilityName}.`,
          bodyText: 'In order to reset the system, please type "RESET" in the field below.',
          confirmationWord: 'RESET',
          yesLabel: 'RESET',
          noLabel: 'Cancel',
          onNoFunction: null,
          onYesFunction: action.confirmFunction,
          onCloseDispatch: { type: FacilityModalsActionTypes.SYSTEM_RESET, payload: {} },
          yesCTA: true, // sets call to action color on yes
        },
      };
    case FacilityModalsActionTypes.DELETE_REPORT_SPECIFICATION:
      return {
        ...state,
        deleteReportSpecModalOpened: !isEmpty(action.payload),
        refreshData: action.refreshData,
        deleteReportSpecData: {
          id: action.payload.id,
          title: 'Delete report specification?',
          bodyText: `Are you sure you want to delete "${action.payload.reportSpecName}"?`,
          yesLabel: 'Yes',
          noLabel: 'No',
          onNoFunction: null,
          onYesFunction: action.confirmFunction,
          onCloseDispatch: {
            type: FacilityModalsActionTypes.DELETE_REPORT_SPECIFICATION,
            payload: {},
          },
          yesCTA: true, // sets call to action color on yes
        },
      };
    case FacilityModalsActionTypes.TOGGLE_DRONE_ERRORS_MODAL:
      return {
        ...state,
        droneErrorModalOpened: !isEmpty(action.payload),
        droneErrorData: action.payload,
      };

    default:
      return state;
  }
};
