import { Box, useTheme } from '@mui/material';
import { NoAccessIcon } from 'components/common/FacilityMenu/Icons/NoAccessIcon';

type ModalMessageCoexistenceProps = {
  flightDomainName: string;
  unlockMode: 'coexistence' | 'droneOnly';
  numDronesFlying: number;
};

export const ModalMessageCoexistence = ({
  flightDomainName,
  unlockMode,
  numDronesFlying,
}: ModalMessageCoexistenceProps) => {
  const theme = useTheme();

  return (
    <Box component="div" paddingTop={theme.spacing(2)}>
      {unlockMode === 'coexistence' && (
        <Box
          component="div"
          data-testid="c-lock-modal-content-coexistence"
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <Box component="div">
            Unlocking will allow drones to take off and perform their tasks according to the defined
            Drone Zones.
            <br />
            <br />
            Please ensure that you have followed your internal safety protocols before you enable
            flying in {flightDomainName}.
          </Box>
          {numDronesFlying > 0 && (
            <Box component="div" padding="2em" sx={{ backgroundColor: 'rgba(232, 172, 111, 0.2)' }}>
              {numDronesFlying} drones are still flying according to the Controlled Zones defined at
              their takeoff.
              <br />
              <br />
              Please wait for those drones to land before unlocking the system.
            </Box>
          )}
        </Box>
      )}
      {unlockMode === 'droneOnly' && (
        <Box component="div" data-testid="c-lock-modal-content-coexistence">
          <Box
            component="div"
            color="orange"
            flexDirection="row"
            display="flex"
            fontWeight="bold"
            paddingBottom="1em"
            gap={1}
            alignItems="center"
            fontSize="1.5em"
          >
            <NoAccessIcon color="orange" fontSize="2em" />
            No people allowed
          </Box>
          Unlocking will allow drones to take off and perform their tasks in{' '}
          <b>all Controlled Zones</b>. In this mode, drones operate at increased speeds and with
          reduced obstacle detection capabilities.
          <br />
          <br />
          Please ensure that you have followed your internal safety protocols before you enable
          flying in <b>{flightDomainName}</b>.
        </Box>
      )}
    </Box>
  );
};
