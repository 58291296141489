import { IFacilitySettingsST } from 'codegen/facility_settings';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';

export const isAmendForumShown = (facilitySettings: IFacilitySettingsST) =>
  facilitySettings.allow_user_amend || false;

export const isShareFeedbackOnLocationShown = (facilitySettings: IFacilitySettingsST) =>
  facilitySettings.support_email_recipients !== '' &&
  userHasPermission(PERMISSION.SHARE_FEEDBACK_ON_LOCATION);

export const isFeedbackShown = (facilitySettings: IFacilitySettingsST) =>
  isAmendForumShown(facilitySettings) || isShareFeedbackOnLocationShown(facilitySettings);
