/* tslint:disable */
/* eslint-disable */
/**
 * controlled zones
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IBox3DST
 */
export interface IBox3DST {
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  min: IMapPointST;
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  max: IMapPointST;
}
/**
 *
 * @export
 * @interface IChangeAllControlledZonePermission200ResponseST
 */
export interface IChangeAllControlledZonePermission200ResponseST {
  /**
   *
   * @type {Array<string>}
   * @memberof IChangeAllControlledZonePermission200ResponseST
   */
  failed_ids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof IChangeAllControlledZonePermission200ResponseST
   */
  pending_ids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof IChangeAllControlledZonePermission200ResponseST
   */
  successful_ids: Array<string>;
}
/**
 *
 * @export
 * @interface IChangeAllControlledZonePermissionRequestST
 */
export interface IChangeAllControlledZonePermissionRequestST {
  /**
   *
   * @type {Array<IIntervalST>}
   * @memberof IChangeAllControlledZonePermissionRequestST
   */
  enabled_interval_list: Array<IIntervalST>;
}
/**
 *
 * @export
 * @interface IChangeControlledZonePermissionRequestST
 */
export interface IChangeControlledZonePermissionRequestST {
  /**
   *
   * @type {Array<IIntervalST>}
   * @memberof IChangeControlledZonePermissionRequestST
   */
  enabled_interval_list: Array<IIntervalST>;
  /**
   * User defined reason for activation or deactivation
   * @type {string}
   * @memberof IChangeControlledZonePermissionRequestST
   */
  reason?: string | null;
}
/**
 *
 * @export
 * @interface IControlledZonePostRequestST
 */
export interface IControlledZonePostRequestST {
  /**
   * Name of the drone zone
   * @type {string}
   * @memberof IControlledZonePostRequestST
   */
  name: string;
  /**
   * drone zone description
   * @type {string}
   * @memberof IControlledZonePostRequestST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IControlledZonePostRequestST
   */
  geometry: IBox3DST;
}
/**
 *
 * @export
 * @interface IControlledZoneStatus1ST
 */
export interface IControlledZoneStatus1ST {
  /**
   * The drone zone name
   * @type {string}
   * @memberof IControlledZoneStatus1ST
   */
  name: string;
  /**
   * The drone zone description
   * @type {string}
   * @memberof IControlledZoneStatus1ST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IControlledZoneStatus1ST
   */
  geometry: IBox3DST;
  /**
   *
   * @type {Array<IIntervalST>}
   * @memberof IControlledZoneStatus1ST
   */
  enabled_interval_list?: Array<IIntervalST>;
  /**
   * Reason for WIL, not relevant in UDB at the moment
   * @type {string}
   * @memberof IControlledZoneStatus1ST
   */
  reason?: string | null;
  /**
   *
   * @type {IPendingActionST}
   * @memberof IControlledZoneStatus1ST
   */
  pending_action?: IPendingActionST;
  /**
   * The the drone zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof IControlledZoneStatus1ST
   */
  last_zone_permission_change_time: string;
  /**
   * The user that activated or deactivated the drone zone the last time
   * @type {string}
   * @memberof IControlledZoneStatus1ST
   */
  last_zone_permission_change_by: string;
  /**
   * Waiting for drone to come home before disabling the zone
   * @type {boolean}
   * @memberof IControlledZoneStatus1ST
   */
  waiting_for_drones_to_come_home?: boolean;
}

/**
 *
 * @export
 * @interface IControlledZoneStatusST
 */
export interface IControlledZoneStatusST {
  /**
   * The drone zone name
   * @type {string}
   * @memberof IControlledZoneStatusST
   */
  name: string;
  /**
   * The drone zone description
   * @type {string}
   * @memberof IControlledZoneStatusST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IControlledZoneStatusST
   */
  geometry: IBox3DST;
  /**
   *
   * @type {Array<IIntervalST>}
   * @memberof IControlledZoneStatusST
   */
  enabled_interval_list?: Array<IIntervalST>;
  /**
   * Reason for WIL, not relevant in UDB at the moment
   * @type {string}
   * @memberof IControlledZoneStatusST
   */
  reason?: string | null;
  /**
   *
   * @type {IPendingActionST}
   * @memberof IControlledZoneStatusST
   */
  pending_action?: IPendingActionST;
  /**
   * The the drone zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof IControlledZoneStatusST
   */
  last_zone_permission_change_time: string;
  /**
   * The user that activated or deactivated the drone zone the last time
   * @type {string}
   * @memberof IControlledZoneStatusST
   */
  last_zone_permission_change_by: string;
  /**
   * Waiting for drone to come home before disabling the zone
   * @type {boolean}
   * @memberof IControlledZoneStatusST
   */
  waiting_for_drones_to_come_home?: boolean;
}

/**
 *
 * @export
 * @interface IControlledZoneUpdatePropertiesRequestST
 */
export interface IControlledZoneUpdatePropertiesRequestST {
  /**
   * Name of the controlled zone
   * @type {string}
   * @memberof IControlledZoneUpdatePropertiesRequestST
   */
  name: string;
  /**
   * controlled zone description
   * @type {string}
   * @memberof IControlledZoneUpdatePropertiesRequestST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IControlledZoneUpdatePropertiesRequestST
   */
  geometry: IBox3DST;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IGetControlledZones404ResponseST
 */

export interface IGetControlledZones404ResponseST {
  /**
   * Error message
   * @type {string}
   * @memberof IGetControlledZones404ResponseST
   */
  message: string;
}
/**
 *
 * @export
 * @interface IGetControlledZonesResponseST
 */
export interface IGetControlledZonesResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IGetControlledZonesResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the controlled zones
   * @type {{ [key: string]: IControlledZoneStatus1ST; }}
   * @memberof IGetControlledZonesResponseST
   */
  controlled_zones: { [key: string]: IControlledZoneStatus1ST };
}
/**
 *
 * @export
 * @interface IIntervalST
 */
export interface IIntervalST {
  /**
   *
   * @type {string}
   * @memberof IIntervalST
   */
  start_time?: string | null;
  /**
   *
   * @type {string}
   * @memberof IIntervalST
   */
  end_time?: string | null;
}
/**
 *
 * @export
 * @interface IMapPointST
 */
export interface IMapPointST {
  /**
   * The value of the X coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  x: number;
  /**
   * The value of the Y coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  y: number;
  /**
   * The value of the Z coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  z: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const IPendingActionST = {
  None: 'NONE',
  AllowDronesInZone: 'ALLOW_DRONES_IN_ZONE',
  AcknowledgeDronesNotAllowedInZone: 'ACKNOWLEDGE_DRONES_NOT_ALLOWED_IN_ZONE',
} as const;

export type IPendingActionST = typeof IPendingActionST[keyof typeof IPendingActionST];

/**
 *
 * @export
 * @interface ISetControlledZoneActive202ResponseST
 */
export interface ISetControlledZoneActive202ResponseST {
  /**
   *
   * @type {string}
   * @memberof ISetControlledZoneActive202ResponseST
   */
  reason: string;
}

/**
 * ControlledZonesApi - axios parameter creator
 * @export
 */
export const ControlledZonesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * The endpoint allows the caller to activate or deactivate all controlled zone.
     * @summary Activate/Deactivate all controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IChangeAllControlledZonePermissionRequestST} iChangeAllControlledZonePermissionRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAllControlledZonePermission: async (
      systemIdStr: string,
      flightDomainId: string,
      iChangeAllControlledZonePermissionRequestST: IChangeAllControlledZonePermissionRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('changeAllControlledZonePermission', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('changeAllControlledZonePermission', 'flightDomainId', flightDomainId);
      // verify required parameter 'iChangeAllControlledZonePermissionRequestST' is not null or undefined
      assertParamExists(
        'changeAllControlledZonePermission',
        'iChangeAllControlledZonePermissionRequestST',
        iChangeAllControlledZonePermissionRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/controlled-zone/change-permission`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iChangeAllControlledZonePermissionRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to delete a specific controlled zone.
     * @summary Delete a specific drone zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    controlledZoneDeleteRequest: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('controlledZoneDeleteRequest', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('controlledZoneDeleteRequest', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('controlledZoneDeleteRequest', 'id', id);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/controlled-zone/{id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new controlled zone.
     * @summary Create a new controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IControlledZonePostRequestST} iControlledZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createControlledZone: async (
      systemIdStr: string,
      flightDomainId: string,
      iControlledZonePostRequestST: IControlledZonePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createControlledZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('createControlledZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'iControlledZonePostRequestST' is not null or undefined
      assertParamExists(
        'createControlledZone',
        'iControlledZonePostRequestST',
        iControlledZonePostRequestST,
      );
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/controlled-zone`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iControlledZonePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the status of the flight domain controlled zones.
     * @summary Returns the status of the flight domain controlled zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getControlledZones: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getControlledZones', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getControlledZones', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/controlled-zone`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific drone zone.
     * @summary Activate/Deactivate a specific controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IChangeControlledZonePermissionRequestST} iChangeControlledZonePermissionRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setControlledZoneActive: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iChangeControlledZonePermissionRequestST: IChangeControlledZonePermissionRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('setControlledZoneActive', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('setControlledZoneActive', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('setControlledZoneActive', 'id', id);
      // verify required parameter 'iChangeControlledZonePermissionRequestST' is not null or undefined
      assertParamExists(
        'setControlledZoneActive',
        'iChangeControlledZonePermissionRequestST',
        iChangeControlledZonePermissionRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/controlled-zone/{id}/change-permission`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
          .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iChangeControlledZonePermissionRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to update properties of a specific controlled zone.
     * @summary Update properties of a specific controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IControlledZoneUpdatePropertiesRequestST} iControlledZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateControlledZoneProperties: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iControlledZoneUpdatePropertiesRequestST: IControlledZoneUpdatePropertiesRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateControlledZoneProperties', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateControlledZoneProperties', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateControlledZoneProperties', 'id', id);
      // verify required parameter 'iControlledZoneUpdatePropertiesRequestST' is not null or undefined
      assertParamExists(
        'updateControlledZoneProperties',
        'iControlledZoneUpdatePropertiesRequestST',
        iControlledZoneUpdatePropertiesRequestST,
      );
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/controlled-zone/{id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iControlledZoneUpdatePropertiesRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ControlledZonesApi - functional programming interface
 * @export
 */
export const ControlledZonesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ControlledZonesApiAxiosParamCreator(configuration);
  return {
    /**
     * The endpoint allows the caller to activate or deactivate all controlled zone.
     * @summary Activate/Deactivate all controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IChangeAllControlledZonePermissionRequestST} iChangeAllControlledZonePermissionRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeAllControlledZonePermission(
      systemIdStr: string,
      flightDomainId: string,
      iChangeAllControlledZonePermissionRequestST: IChangeAllControlledZonePermissionRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IChangeAllControlledZonePermission200ResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeAllControlledZonePermission(
        systemIdStr,
        flightDomainId,
        iChangeAllControlledZonePermissionRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to delete a specific controlled zone.
     * @summary Delete a specific drone zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async controlledZoneDeleteRequest(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.controlledZoneDeleteRequest(
        systemIdStr,
        flightDomainId,
        id,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new controlled zone.
     * @summary Create a new controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IControlledZonePostRequestST} iControlledZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createControlledZone(
      systemIdStr: string,
      flightDomainId: string,
      iControlledZonePostRequestST: IControlledZonePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createControlledZone(
        systemIdStr,
        flightDomainId,
        iControlledZonePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the status of the flight domain controlled zones.
     * @summary Returns the status of the flight domain controlled zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getControlledZones(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetControlledZonesResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getControlledZones(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific drone zone.
     * @summary Activate/Deactivate a specific controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IChangeControlledZonePermissionRequestST} iChangeControlledZonePermissionRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setControlledZoneActive(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iChangeControlledZonePermissionRequestST: IChangeControlledZonePermissionRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ISetControlledZoneActive202ResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setControlledZoneActive(
        systemIdStr,
        flightDomainId,
        id,
        iChangeControlledZonePermissionRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to update properties of a specific controlled zone.
     * @summary Update properties of a specific controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IControlledZoneUpdatePropertiesRequestST} iControlledZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateControlledZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iControlledZoneUpdatePropertiesRequestST: IControlledZoneUpdatePropertiesRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateControlledZoneProperties(
        systemIdStr,
        flightDomainId,
        id,
        iControlledZoneUpdatePropertiesRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ControlledZonesApi - factory interface
 * @export
 */
export const ControlledZonesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ControlledZonesApiFp(configuration);
  return {
    /**
     * The endpoint allows the caller to activate or deactivate all controlled zone.
     * @summary Activate/Deactivate all controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IChangeAllControlledZonePermissionRequestST} iChangeAllControlledZonePermissionRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAllControlledZonePermission(
      systemIdStr: string,
      flightDomainId: string,
      iChangeAllControlledZonePermissionRequestST: IChangeAllControlledZonePermissionRequestST,
      options?: any,
    ): AxiosPromise<IChangeAllControlledZonePermission200ResponseST> {
      return localVarFp
        .changeAllControlledZonePermission(
          systemIdStr,
          flightDomainId,
          iChangeAllControlledZonePermissionRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to delete a specific controlled zone.
     * @summary Delete a specific drone zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    controlledZoneDeleteRequest(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .controlledZoneDeleteRequest(systemIdStr, flightDomainId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new controlled zone.
     * @summary Create a new controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IControlledZonePostRequestST} iControlledZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createControlledZone(
      systemIdStr: string,
      flightDomainId: string,
      iControlledZonePostRequestST: IControlledZonePostRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createControlledZone(systemIdStr, flightDomainId, iControlledZonePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the status of the flight domain controlled zones.
     * @summary Returns the status of the flight domain controlled zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getControlledZones(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IGetControlledZonesResponseST> {
      return localVarFp
        .getControlledZones(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific drone zone.
     * @summary Activate/Deactivate a specific controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IChangeControlledZonePermissionRequestST} iChangeControlledZonePermissionRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setControlledZoneActive(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iChangeControlledZonePermissionRequestST: IChangeControlledZonePermissionRequestST,
      options?: any,
    ): AxiosPromise<ISetControlledZoneActive202ResponseST> {
      return localVarFp
        .setControlledZoneActive(
          systemIdStr,
          flightDomainId,
          id,
          iChangeControlledZonePermissionRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to update properties of a specific controlled zone.
     * @summary Update properties of a specific controlled zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {IControlledZoneUpdatePropertiesRequestST} iControlledZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateControlledZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iControlledZoneUpdatePropertiesRequestST: IControlledZoneUpdatePropertiesRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateControlledZoneProperties(
          systemIdStr,
          flightDomainId,
          id,
          iControlledZoneUpdatePropertiesRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ControlledZonesApi - object-oriented interface
 * @export
 * @class ControlledZonesApi
 * @extends {BaseAPI}
 */
export class ControlledZonesApi extends BaseAPI {
  /**
   * The endpoint allows the caller to activate or deactivate all controlled zone.
   * @summary Activate/Deactivate all controlled zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IChangeAllControlledZonePermissionRequestST} iChangeAllControlledZonePermissionRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControlledZonesApi
   */
  public changeAllControlledZonePermission(
    systemIdStr: string,
    flightDomainId: string,
    iChangeAllControlledZonePermissionRequestST: IChangeAllControlledZonePermissionRequestST,
    options?: AxiosRequestConfig,
  ) {
    return ControlledZonesApiFp(this.configuration)
      .changeAllControlledZonePermission(
        systemIdStr,
        flightDomainId,
        iChangeAllControlledZonePermissionRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to delete a specific controlled zone.
   * @summary Delete a specific drone zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControlledZonesApi
   */
  public controlledZoneDeleteRequest(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return ControlledZonesApiFp(this.configuration)
      .controlledZoneDeleteRequest(systemIdStr, flightDomainId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new controlled zone.
   * @summary Create a new controlled zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IControlledZonePostRequestST} iControlledZonePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControlledZonesApi
   */
  public createControlledZone(
    systemIdStr: string,
    flightDomainId: string,
    iControlledZonePostRequestST: IControlledZonePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return ControlledZonesApiFp(this.configuration)
      .createControlledZone(systemIdStr, flightDomainId, iControlledZonePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the status of the flight domain controlled zones.
   * @summary Returns the status of the flight domain controlled zones.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControlledZonesApi
   */
  public getControlledZones(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return ControlledZonesApiFp(this.configuration)
      .getControlledZones(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to activate or deactivate a specific drone zone.
   * @summary Activate/Deactivate a specific controlled zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {IChangeControlledZonePermissionRequestST} iChangeControlledZonePermissionRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControlledZonesApi
   */
  public setControlledZoneActive(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    iChangeControlledZonePermissionRequestST: IChangeControlledZonePermissionRequestST,
    options?: AxiosRequestConfig,
  ) {
    return ControlledZonesApiFp(this.configuration)
      .setControlledZoneActive(
        systemIdStr,
        flightDomainId,
        id,
        iChangeControlledZonePermissionRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to update properties of a specific controlled zone.
   * @summary Update properties of a specific controlled zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {IControlledZoneUpdatePropertiesRequestST} iControlledZoneUpdatePropertiesRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControlledZonesApi
   */
  public updateControlledZoneProperties(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    iControlledZoneUpdatePropertiesRequestST: IControlledZoneUpdatePropertiesRequestST,
    options?: AxiosRequestConfig,
  ) {
    return ControlledZonesApiFp(this.configuration)
      .updateControlledZoneProperties(
        systemIdStr,
        flightDomainId,
        id,
        iControlledZoneUpdatePropertiesRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
