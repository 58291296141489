import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { Box } from 'components/common/Box';

type SwitchThumbProps = {
  disabled: boolean;
  cardSwitch: boolean;
  color: string;
  colorDisabled: string;
  isChecked: boolean;
};

export const SwitchThumb = ({
  disabled,
  cardSwitch,
  color,
  colorDisabled,
  isChecked,
}: SwitchThumbProps) => (
  <Box
    p={1}
    sx={{
      background: (cardSwitch && (!disabled ? color : colorDisabled)) || color,
    }}
    className="switch"
  >
    {isChecked ? (
      <LockOpenIcon data-testid="c-icon-lock-open" sx={{ color: '#fff', fontSize: '15px' }} />
    ) : (
      <LockIcon data-testid="c-icon-lock-closed" sx={{ color: '#fff', fontSize: '15px' }} />
    )}
  </Box>
);
