import { FacilityMenuAction, FacilityMenuActionNames } from './facilityMenuActions';

export const FacilityMenuReducer = (state: IFacilityMenuState, action: FacilityMenuAction) => {
  switch (action.type) {
    case FacilityMenuActionNames.RESET:
      return initialFacilityMenuState;

    case FacilityMenuActionNames.TOGGLE_MENU_OPENED:
      return {
        ...state,
        menuOpened: !state.menuOpened,
      };

    case FacilityMenuActionNames.SET_MENU_OPENED:
      return {
        ...state,
        menuOpened: action.payload,
      };

    case FacilityMenuActionNames.SET_EXPANDED_ITEMS:
      return {
        ...state,
        expandedItems: action.payload,
      };

    default:
      return state;
  }
};

/**
 * FacilityMenu state interaface
 */
export interface IFacilityMenuState {
  menuOpened: boolean;
  expandedItems: string[];
}

/**
 * Initial state of the facility menu
 */
export const initialFacilityMenuState = {
  // whether the side menu is open or not
  menuOpened: true,
  // list of items expanded in the menu
  expandedItems: ['inventory', 'groundControl'],
};
