import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MapViewOptions } from '../../model/DroneZonesPage.model';

const getMapView = ({ mapViewParam }: { mapViewParam: string | null }): MapViewOptions => {
  // Typecast string into mapView as we typeguard below.
  const currentParam = (mapViewParam as MapViewOptions) ?? '';

  if ([MapViewOptions.FLIGHT_AREA, MapViewOptions.PLANNING].includes(currentParam)) {
    return currentParam;
  }

  return MapViewOptions.PLANNING;
};

export const useMapViewParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentMapView = useMemo(() => {
    const currentParam = searchParams.get('mapView');

    return getMapView({ mapViewParam: currentParam });
  }, [searchParams]);

  const setMapViewParam = useCallback(
    (newMapView: MapViewOptions) => {
      if (newMapView) {
        searchParams.set('mapView', newMapView);

        setSearchParams(searchParams);
      }
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    const mapView = getMapView({ mapViewParam: currentMapView });
    setMapViewParam(mapView);
  }, [currentMapView, setMapViewParam]);

  return {
    currentMapView,
    setMapViewParam,
  };
};
