/* eslint-disable no-nested-ternary */
import { useRef } from 'react';
import { OrthographicCameraProps, useFrame } from '@react-three/fiber';
import { Vector2, Vector3 } from 'three';
import { OrthographicCamera } from '@react-three/drei';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { useMouseDiff } from '../../utils/UseMouseDiffHook';
import { MutableState } from '../../reducer/MutableState';
import { between } from '../../utils/3DmapFunctions';
import { MapCameraOptions } from '../../reducer/MapOptionsState';
import { Easing, Tween } from '../../utils/Tween';
import { Zoomer } from '../../utils/Zoomer';
import { ORTHO_CAMERA_Z } from '../../defaults/orthographicCameraZ.default';

const logPrefix = getLogPrefixForType('COMPONENT', '3D OrthoCamera');

/**
 * Orthographic camera.
 */
export const OrthoCamera = (
  props: OrthographicCameraProps & {
    worldBounds: [number, number, number, number];
    options: MapCameraOptions;
    fovAxisRatio: number;
    zoomer: Zoomer;
  },
) => {
  const { cameraState, interaction } = MutableState.getState();
  const { worldBounds, fovAxisRatio, zoomer } = props;
  const [minX, maxX, minY, maxY] = worldBounds;
  const worldCenter = new Vector2((minX + maxX) / 2, (minY + maxY) / 2);

  const camRef = useRef<THREE.OrthographicCamera>(null!);
  const FOV = useRef(cameraState.currentOrthographicFOV);
  const targetFOV = useRef(cameraState.orthographicFOV);
  const tweenFOV = useRef<Tween<number> | null>(null);
  const tweenPosition = useRef<Tween<Vector3> | null>(null);
  const mouseDiff = useMouseDiff();

  /**
   * Calculates the camera bounds from world bounds for given fov.
   * @param fov field of view
   * @returns [minCx, maxCx, minCy, maxCy]
   */
  const getCameraBounds = (fov: number) => {
    const justABitOfOffset = fov / 4;

    let minCx = minX + fov * fovAxisRatio - justABitOfOffset;
    let maxCx = maxX - fov * fovAxisRatio + justABitOfOffset;
    let minCy = minY + fov - justABitOfOffset;
    let maxCy = maxY - fov + justABitOfOffset;

    // when camera is zoomed out, force center to be in the middle of the map
    [minCx, maxCx] = maxCx < minCx ? [(maxX + minX) / 2, (maxX + minX) / 2] : [minCx, maxCx];
    [minCy, maxCy] = maxCy < minCy ? [(maxY + minY) / 2, (maxY + minY) / 2] : [minCy, maxCy];

    console.debug(
      `${logPrefix} camera bounds: [${minCx}, ${maxCx}, ${minCy}, ${maxCy}] from world bounds: [${minX}, ${maxX}, ${minY}, ${maxY}]`,
    );

    return [minCx, maxCx, minCy, maxCy];
  };

  /**
   * Force given position to be within the camera bounds
   * @param position position to fit
   */
  const fitPositionToBounds = (position: Vector3): Vector3 => {
    const [minCx, maxCx, minCy, maxCy] = getCameraBounds(FOV.current);

    const newX = between(position.x, minCx, maxCx);
    const newY = between(position.y, minCy, maxCy);

    return new Vector3(newX, newY, ORTHO_CAMERA_Z);
  };

  /**
   * Sets position in mutable state and camera.
   * @param position position to set
   */
  const setPosition = (position: Vector3) => {
    console.debug(
      `${logPrefix} position changed by ${cameraState.position
        .sub(position)
        .toArray()} to ${position.toArray()}`,
    );

    cameraState.position.copy(position);
    camRef.current.position.copy(position);
  };

  /**
   * User moved camera. Calculate new position from mouse movement and set it.
   * @param delta time since last frame
   */
  const userPanCamera = () => {
    const [mdx, mdy] = [mouseDiff.current.x, mouseDiff.current.y];
    const ratioY = FOV.current;
    const ratioX = ratioY * fovAxisRatio;

    const offset = new Vector3(-mdx * ratioX, -mdy * ratioY, 0);
    const newPosition = offset.add(cameraState.position);

    setPosition(fitPositionToBounds(newPosition));
  };

  /**
   * Calculates the four sides of the frustum from the given FOV.
   * @param fov field of view for the camera
   * @returns four sides of the frustum
   */
  const frustumSidesFromFOV = (fov: number) => ({
    left: -fov * fovAxisRatio,
    right: fov * fovAxisRatio,
    top: fov,
    bottom: -fov,
  });

  /**
   * Set the frustum sides from the current FOV.
   * @param fov fov to set
   */
  const setFrustumSidesFromFOV = (fov: number) => {
    const { left, right, top, bottom } = frustumSidesFromFOV(fov);
    Object.assign(camRef.current, { left, right, top, bottom });
  };

  /**
   * FOV changed. Set it, adjust frustum and position, update projection matrix.
   * @param fov new FOV
   */
  const fovChange = (mouse: Vector2, delta: number) => {
    const nextFov = tweenFOV.current?.getNextValue(delta) || targetFOV.current;
    const finalFOVDiff = FOV.current - nextFov;

    console.debug(
      logPrefix,
      `FOV anim calc: (in:${FOV.current}) -> ${nextFov} (diff: ${finalFOVDiff}, delta: ${delta}})`,
    );

    FOV.current = nextFov;
    cameraState.currentOrthographicFOV = FOV.current;

    setFrustumSidesFromFOV(FOV.current);

    const dx = mouse.x * finalFOVDiff * fovAxisRatio;
    const dy = mouse.y * finalFOVDiff;
    const move = new Vector3(dx, dy, 0);

    setPosition(fitPositionToBounds(cameraState.position.add(move)));

    camRef.current.updateProjectionMatrix();
  };

  /**
   * Checks if there is new desired FOV and sets animation to it.
   * @param mouse mouse position on canvas
   * @param delta time since last frame
   */
  const handleFOVUpdate = (mouse: Vector2, delta: number) => {
    if (targetFOV.current !== cameraState.orthographicFOV) {
      console.debug(
        logPrefix,
        `target FOV changed (from:${targetFOV.current} to:${cameraState.orthographicFOV}) creating/updating FOV Tween`,
      );
      targetFOV.current = cameraState.orthographicFOV;

      tweenFOV.current = new Tween(
        FOV.current,
        targetFOV.current,
        0.5,
        Easing.EaseOutQuad,
        () => (tweenFOV.current = null),
      );
    }

    if (tweenFOV.current && !tweenFOV.current.isDone) {
      fovChange(mouse, delta);
    }
  };

  const goToTargetPosition = (delta: number) => {
    const newPosition = tweenPosition.current?.getNextValue(delta) || cameraState.targetPosition;
    if (newPosition) {
      setPosition(newPosition);
    }

    if (!cameraState.targetPosition || tweenPosition.current) {
      return;
    }

    tweenPosition.current = new Tween(
      cameraState.position,
      cameraState.targetPosition,
      0.5,
      Easing.EaseOutQuad,
      () => {
        cameraState.targetPosition = null;
        tweenPosition.current = null;
      },
    );
  };

  /**
   * Check if position or fov needs to be changed.
   */
  useFrame(({ mouse }, delta) => {
    if (interaction.isFloorHeld && !cameraState.blockCameraMovement) {
      userPanCamera();
    }

    goToTargetPosition(delta);

    handleFOVUpdate(mouse, delta);
  });

  const leftRightTopBottom = frustumSidesFromFOV(FOV.current);
  const initialPosition = new Vector3(worldCenter.x, worldCenter.y, ORTHO_CAMERA_Z);

  zoomer.setCurrentZoomStepFromFOV(FOV.current);

  console.debug(
    logPrefix,
    `in: ${[...Object.values(leftRightTopBottom)]} at ${[...Object.values(cameraState.position)]}`,
  );

  return (
    <OrthographicCamera
      getObjectByProperty={undefined}
      ref={camRef}
      manual
      makeDefault
      {...props}
      position={initialPosition}
      rotation={[0, 0, 0]}
      near={0.1}
      far={1000}
      {...leftRightTopBottom}
    />
  );
};
