import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

type MetricProps = {
  tooltip?: string;
  value: number | string;
  unit?: string;
  unitLong?: string;
};

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    display: 'inline-block',
    verticalAlign: 'center',
    fontSize: '1em',

    svg: {
      display: 'block',
      fontSize: 'inherit',
    },
  },
}));

export const Metric = ({ tooltip, value, unit, unitLong }: MetricProps) => {
  const { classes } = useStyles();

  const t = tooltip || `${value} ${unitLong || unit || ''}`;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Tooltip title={t}>
      <div data-testid="metric-container">
        <span>
          {value}
          {unit ? ` ${unit}` : null}
        </span>{' '}
        <IconButton
          aria-label="Copy value to clipboard"
          className={classes.icon}
          onClick={() => {
            navigator.clipboard.writeText(t);
            enqueueSnackbar('Copied to clipboard', { variant: 'success' });
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};
