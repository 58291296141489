import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { useStyles } from './DownloadLink.styles';

export const DownloadLink = ({ label, url }: { label?: string; url?: string }) => {
  const { classes } = useStyles();

  return url ? (
    <Link className={classes.link} download href={url}>
      <DownloadIcon className={classes.icon} />
      {label ?? 'Unnamed file'}
    </Link>
  ) : (
    <p className={classes.fallback}>No file available</p>
  );
};
