export const formatShortDate = (date: Date): string => {
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return dateFormatter.format(date).replace(/\//g, '/');
};

export const formatShortDateTime = (date: Date): string => {
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const formattedDate = dateFormatter.format(date).replace(/\//g, '/');

  const formattedTime = timeFormatter.format(date);

  const formattedDateTime = `${formattedDate} - ${formattedTime}`;

  return formattedDateTime;
};
