import TableCell, { SortDirection } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { HeadCellProp } from '../../../types/cell';

type EnhancedTableHeadCellStringProps = {
  headCell: HeadCellProp;
  classes: {
    tableHeaderCell: string;
  };
  allowSorting?: boolean;
  orderBy?: string;
  order?: SortDirection;
  onClick: (event: any) => void;
};

export const EnhancedTableHeadCellString = (props: EnhancedTableHeadCellStringProps) => {
  const { headCell, classes, orderBy, allowSorting, order, onClick } = props;

  if (headCell === undefined) {
    console.debug(
      'EnhancedTableHeadCellString',
      'headCell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell
      data-testid="c-table-column-head"
      className={classes.tableHeaderCell}
      align={headCell.align}
      key={headCell.id}
      sortDirection={orderBy === headCell.id ? order : false}
    >
      {allowSorting ? (
        <TableSortLabel
          hideSortIcon={true}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id && order ? order : 'asc'}
          onClick={onClick}
        >
          {headCell.label}
        </TableSortLabel>
      ) : (
        headCell.label
      )}
    </TableCell>
  );
};
