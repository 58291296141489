import moment from 'moment';
import { Card, CardContent, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { createCalendars, calendarTemplate, calendarTheme } from 'common/Calendar/CalendarSettings';
import { WeekDayShort } from 'common/datetimeFormats';
import { Calendar } from 'components/common/Calendar';
import { baseCardStyle } from 'components/BaseCard/styles';

interface SchedulerCalendarProps {
  /**
   * Deadline / Duration in minutes
   */
  reportDeadlineTimeoutInMinutes: number | null;
  /**
   * Start date of the report
   */
  reportFromDate: moment.MomentInput;

  days?: WeekDayShort[];
}

const useStyles = makeStyles()(() => ({
  cardContent: {
    height: 600,
    '& .toastui-calendar-timegrid': {
      minHeight: 500,
    },
    '& .toastui-calendar-timegrid-now-indicator': {
      display: 'none',
    },
    '& .toastui-calendar-panel.toastui-calendar-time': {
      overflowY: 'inherit',
    },
    '& .toastui-calendar-week-view .toastui-calendar-panel:not(.toastui-calendar-time)': {
      overflowY: 'inherit',
    },
    '& .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time': {
      visibility: 'visible',
    },
    '& .toastui-calendar-day-name__date': {
      display: 'none',
    },
    '& .toastui-calendar-day-name__name': {
      fontSize: 14,
    },
  },
}));

export const SchedulerCalendar = (props: SchedulerCalendarProps) => {
  const { classes, cx } = useStyles();
  const { classes: cardStyles } = baseCardStyle();
  const theme = useTheme();
  const { timezone } = localStorage;
  const { reportDeadlineTimeoutInMinutes, reportFromDate, days } = props;

  const events = days
    ? days.map((d, i) => {
        const start = moment(reportFromDate).tz(timezone).isoWeekday(d);
        return {
          id: i,
          calendarId: 'fhc',
          title: 'Report Lifetime',
          category: 'time',
          start,
          end: moment(reportFromDate)
            .tz(timezone)
            .isoWeekday(d)
            .add('minutes', reportDeadlineTimeoutInMinutes || 0),
        };
      })
    : [];

  return (
    <Card elevation={3} className={cardStyles.card}>
      <CardContent className={cx(classes.cardContent)}>
        <Calendar
          height="200px"
          view="week"
          week={{ taskView: false, eventView: ['time'], startDayOfWeek: 1 }}
          timezone={{ zones: [{ timezoneName: timezone }] }}
          events={events}
          calendars={createCalendars(theme)}
          template={calendarTemplate}
          theme={calendarTheme}
          usageStatistics={false}
          isReadOnly
        />
      </CardContent>
    </Card>
  );
};
