import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { NoFlyZone, NoFlyZones } from 'shared/map/model/no-fly-zone.model';
import { IFlightDomainData } from 'store/GroundControl/IGroundControlStore';
import { DroneZoneAccordion } from './components/DroneZoneAccordion/DroneZoneAccordion';
import { NoFlyZonesList } from '../NoFlyZonesList/NoFlyZonesList';
import { ControlledZonesList } from '../ControlledZonesList/ControlledZonesList';
import { DroneZoneType, DroneZoneTypes, DroneZones } from '../../model/droneZones.model';
import { userHasPermission } from '../../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../../features/permissions/permissions.model';
import { ControlledZoneToggleAll } from '../ControlledZonesList/components/ControlledZoneToggleAll/ControlledZoneToggleAll';
import { useStyles } from './DroneZonesList.styles';
import { useDroneZoneContext } from '../../reducer/DroneZonePageContext';

const ACCORDION_ZONE_NONE_EXPANDED: undefined = undefined;

type DroneZonesListProps = {
  systemId: string;
  noFlyZones: NoFlyZones;
  controlledZones: ControlledZones;
  flightDomain: IFlightDomainData;
  isLoading: boolean;
  areActionsEnabled: boolean;
  currentlySelectedZone: null | DroneZones;
  onSelect: (zone: NoFlyZone | ControlledZone) => void;
};

export const DroneZonesList = ({
  noFlyZones,
  controlledZones,
  systemId,
  flightDomain,
  isLoading,
  areActionsEnabled,
  currentlySelectedZone,
  onSelect,
}: DroneZonesListProps) => {
  const { classes } = useStyles();
  const { dispatchDroneZone } = useDroneZoneContext();

  const [expandedAccordionZone, setExpandedAccordionZone] = useState<
    DroneZoneType | typeof ACCORDION_ZONE_NONE_EXPANDED
  >(currentlySelectedZone?.type ?? DroneZoneTypes.controlledZone);

  const flightDomainId = flightDomain.flight_domain_id;
  const isSystemLocked = !!flightDomain.flight_domain_status?.locked;
  const isDroneOnly = flightDomain.flight_domain_status?.allow_normal_flight_in_controlled_zone;
  const isUnlockedInDroneOnlyMode = !isSystemLocked && isDroneOnly;

  const isCreateNoFlyZoneEnabled = isSystemLocked && areActionsEnabled;
  const numberOfNoFlyZones = Object.keys(noFlyZones).length;
  const numberOfControlledZones = Object.keys(controlledZones).length;

  const handleExpandedChange = (newExpandedZone: DroneZoneType, expanded: boolean) =>
    setExpandedAccordionZone(expanded ? ACCORDION_ZONE_NONE_EXPANDED : newExpandedZone);

  const handleTriggerCreateMode = () =>
    dispatchDroneZone({ type: 'CREATION_OF_ZONE_DRAWING_START', payload: null });

  useEffect(() => {
    if (currentlySelectedZone) {
      setExpandedAccordionZone(currentlySelectedZone.type);
    }
  }, [currentlySelectedZone]);

  return (
    <>
      <DroneZoneAccordion
        title="Controlled zones"
        accordionSummaryAriaLabel={`Controlled zones (${numberOfControlledZones})`}
        isExpanded={expandedAccordionZone === DroneZoneTypes.controlledZone}
        onToggleExpanded={(expanded) =>
          handleExpandedChange(DroneZoneTypes.controlledZone, !expanded)
        }
      >
        {!isUnlockedInDroneOnlyMode && (
          <div className={classes.accordionBody}>
            <ControlledZoneToggleAll
              zones={controlledZones}
              flightDomainId={flightDomainId}
              systemId={systemId}
            />
            <ControlledZonesList
              isLoading={isLoading}
              zones={controlledZones}
              onSelect={onSelect}
              systemId={systemId}
              flightDomainId={flightDomainId}
            />
          </div>
        )}
        {isUnlockedInDroneOnlyMode && (
          <Box
            component="div"
            padding="3em"
            fontSize="large"
            data-testid="drone-only-mode-zones-message"
          >
            <b>Drones-only mode</b>: all controlled zones are enabled.
            <br />
            <br />
            Controlled zones cannot be modified.
          </Box>
        )}
      </DroneZoneAccordion>

      {userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT) && (
        <DroneZoneAccordion
          title="No-fly zones"
          accordionSummaryAriaLabel={`No-fly zones (${numberOfNoFlyZones})`}
          isExpanded={expandedAccordionZone === DroneZoneTypes.noFlyZone}
          isCreationEnabled={isCreateNoFlyZoneEnabled}
          onTriggerCreateMode={handleTriggerCreateMode}
          onToggleExpanded={(expanded) => handleExpandedChange(DroneZoneTypes.noFlyZone, !expanded)}
        >
          <NoFlyZonesList
            isSystemLocked={isSystemLocked}
            areActionsEnabled={areActionsEnabled}
            isLoading={isLoading}
            zones={noFlyZones}
            onSelect={onSelect}
            systemId={systemId}
            flightDomainId={flightDomainId}
          />
        </DroneZoneAccordion>
      )}
    </>
  );
};
