import { VisibleZones } from '../../defaults/visibleZones.default';
import { MapViewOptions } from '../../model/DroneZonesPage.model';
import { DroneZoneVisibilityToggles } from '../DroneZonesVisibilityToggles/DroneZoneVisibilityToggles';
import { DroneZoneMapViewToggles } from '../DroneZoneMapViewToggles/DroneZoneMapViewToggles';
import { ClientDroneZoneTypes } from '../../model/clientDroneZones.model';
import { useStyles } from './droneZonePageHeaderControls.styles';

export const DroneZonePageHeaderControls = ({
  mapView,
  visibleZones,
  isFlightAreaDisabled,
  showVisibilityToggles,
  onToggleVisibleZones,
  onMapViewChange,
}: {
  mapView: MapViewOptions;
  visibleZones: VisibleZones;
  isFlightAreaDisabled: boolean;
  showVisibilityToggles: boolean;
  onToggleVisibleZones: (droneZoneType: ClientDroneZoneTypes) => void;
  onMapViewChange: (newView: MapViewOptions) => void;
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.headerControls}>
      <div className={classes.headerControlsMapViewToggle}>
        <DroneZoneMapViewToggles
          currentMapView={mapView}
          isDisabled={isFlightAreaDisabled}
          onChange={onMapViewChange}
        />
      </div>

      {showVisibilityToggles && (
        <div className={classes.headerControlsMapZoneVisibilityToggle}>
          <DroneZoneVisibilityToggles visibleZones={visibleZones} onChange={onToggleVisibleZones} />
        </div>
      )}
    </div>
  );
};
