import Spinner from 'components/common/Spinner';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useLoadUserLevelData } from '../../hooks';
import { isNewSSOUser } from './utils/isNewSSOUser';
import { NoRolesPage } from './no-roles-page/NoRolesPage';

const AuthPage = ({ children }: { children: JSX.Element }) => {
  const { isDataReady: isUserDataReady } = useUserLevelStore();
  useLoadUserLevelData('AuthPage');

  if (isUserDataReady()) {
    if (isNewSSOUser()) {
      return <NoRolesPage />;
    }

    return children;
  }
  return <Spinner label="loading user data..." />;
};

export default AuthPage;
