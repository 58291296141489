import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { COLOR_PALETTE } from 'common/colors';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { REPORT_STATES } from 'common/reportStates';
import { NETWORK_REQUEST_SETTINGS, SAVE_ALL_REVIEWS_FOR_VERITY_USER } from 'common/settings';
import { amendLocations } from 'common/functions/slot/slotFunctions';
import { IIssueSlotStatusST, IReportST } from 'codegen/report';
import { ILocationReportData } from 'pages/WarehouseStatus/warehouseStatusFunctions';
import { useFacilityModalsStore, useClientModalsStore } from 'store/Modals';
import { FacilityModalsActionTypes, ClientModalsActionTypes } from 'store/Modals/types';
import {
  ReportContainerActionNames,
  ReportContainerActions,
} from './reducer/ReportContainerActions';

type CustomReportButtonProps = {
  reportState: string;
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  displayRefreshOption: boolean;
  allRequestsFinished: boolean;
  displayUpdateReportButton: boolean;
  displayAbortReportButton: boolean;
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  dispatch: Dispatch<ReportContainerActions>;
  rawReportData: IReportST;
  locationsToReview: ILocationReportData[];
  approveSlotsStatus: (param: string[][]) => void;
  reportName: string;
};

const logPrefix = getLogPrefixForType('COMPONENT', 'CustomReportButton');

/**
 * Custom report button
 * @param props CustomReportButtonProps
 * @returns component
 */
export const CustomReportButton = ({
  reportState,
  canReviewReport,
  reportNeedsReview,
  displayRefreshOption,
  allRequestsFinished,
  displayUpdateReportButton,
  getReportData,
  reportName,
  displayAbortReportButton,
  dispatch,
  rawReportData,
  locationsToReview,
  approveSlotsStatus,
}: CustomReportButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { reportId = '', systemId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { dispatchFacilityModals } = useFacilityModalsStore();
  const { dispatchClientModals } = useClientModalsStore();

  const refreshData = {
    getReportData: () =>
      getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB),
  };

  const handleAbortReport = () => {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.REPORT_ABORT,
      payload: {
        reportId,
        reportName,
      },
      refreshData,
    });
  };

  const handleUpdateReport = () => {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.REPORT_UPDATE,
      payload: {
        reportId,
        reportName,
      },
      refreshData,
    });
  };

  const submitReview = useCallback(() => {
    dispatch({
      type: ReportContainerActionNames.SET_ANY,
      payload: { fieldName: 'spinner', value: 1 },
    });

    navigate(location.pathname, {
      state: {
        ...location.state,
        page: 0,
      },
    });

    const rawLocationsToReviewData = rawReportData.locations_data_for_review as IIssueSlotStatusST;

    const locationsForAutomaticAmending =
      rawLocationsToReviewData &&
      Object.values(rawLocationsToReviewData).filter(
        (locationData) =>
          locationData.verity_status && isEmpty(locationData.verity_status.user_overrides),
      );

    const reviewLocations = locationsToReview.reduce(
      (acc: string[][], val: ILocationReportData) => [...acc, [val.location, val.version]],
      [],
    );

    if (SAVE_ALL_REVIEWS_FOR_VERITY_USER) {
      amendLocations(systemId, locationsForAutomaticAmending)
        .then(() => {
          // Note: this try-catch is a mitigation of the general issue that promises' call-back
          // are being executed after a component has been unmounted.
          // TODO: integrate cancellable promises.
          try {
            approveSlotsStatus(reviewLocations);
          } catch (error) {
            console.debug(logPrefix, error);
          }
        })
        .catch((e: any) => {
          console.error(logPrefix, 'Error => ', e);
          enqueueSnackbar(e.response ? e.response.data.message : 'Something went wrong', {
            variant: 'error',
          });
        });
    } else {
      approveSlotsStatus(reviewLocations);
    }
  }, [
    navigate,
    location,
    locationsToReview,
    rawReportData,
    systemId,
    enqueueSnackbar,
    approveSlotsStatus,
    dispatch,
  ]);

  // Handle the submission of a report review, showing a confirmation dialog
  // before actually submitting the review
  const handleSubmitReview = useCallback(() => {
    dispatchClientModals({
      type: ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL,
      payload: {
        title: 'Submit review',
        message:
          'Are you sure you want to submit the report review? This action cannot be undone, after submission all your amendments will be seen as client user amendments.',
        onConfirm: submitReview,
      },
    });
  }, [dispatchClientModals, submitReview]);

  if (reportState === REPORT_STATES.DELETED) return null;

  return (
    <>
      {canReviewReport && reportNeedsReview && !displayRefreshOption && (
        <Button
          data-testid="c-submit-review-btn"
          variant="contained"
          color="primary"
          sx={{
            lineHeight: 1.1,
            padding: ' 0.5rem 2rem',
            marginRight: '16px',
          }}
          onClick={handleSubmitReview}
          disabled={!allRequestsFinished}
        >
          Submit Review
        </Button>
      )}

      {canReviewReport && reportNeedsReview && displayRefreshOption && (
        <Button
          data-testid="c-refresh-review-btn"
          variant="contained"
          color="primary"
          sx={{
            lineHeight: 1.1,
            padding: ' 0.5rem 2rem',
            marginRight: '16px',
          }}
          onClick={() => navigate(0)}
          disabled={!allRequestsFinished}
        >
          Refresh
        </Button>
      )}

      {displayUpdateReportButton && reportState === REPORT_STATES.ONGOING && (
        <Button
          data-testid="c-update-review-btn"
          variant="contained"
          color="primary"
          sx={{
            lineHeight: 1.1,
            padding: ' 0.5rem 2rem',
            marginRight: '16px',
          }}
          onClick={handleUpdateReport}
        >
          Update
        </Button>
      )}
      {displayAbortReportButton &&
        (reportState === REPORT_STATES.ONGOING || reportState === REPORT_STATES.SCHEDULED) && (
          <Button
            data-testid="c-abort-review-btn"
            variant="contained"
            color="primary"
            sx={{
              lineHeight: 1.1,
              padding: ' 0.5rem 2rem',
              backgroundColor: COLOR_PALETTE.RED,
              marginRight: '6px',
            }}
            onClick={handleAbortReport}
          >
            Abort
          </Button>
        )}
    </>
  );
};
