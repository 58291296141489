import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { GridLogicOperator } from '@mui/x-data-grid-premium';
import { initialGridState } from './InitialGridState.model';

type TabObject = {
  label: string;
  show: boolean;
  gridState: GridInitialStatePremium;
};

export const WAREHOUSE_STATUS_TABS: TabObject[] = [
  {
    label: 'FULL REPORT',
    show: true,
    gridState: initialGridState,
  },
  {
    label: 'ISSUES',
    show: true,
    gridState: {
      ...initialGridState,

      filter: {
        filterModel: {
          items: [
            {
              field: 'issues.0.state',
              id: 'issues.0.state',
              operator: 'isAnyOf',
              value: ['new', 'known'],
            },
            {
              field: 'issues.0.type',
              id: 'issues.0.type',
              operator: 'isNotAnyOf',
              value: ['No information', 'No barcode', 'Invalid WMS data', 'Missing Barcode'],
            },
          ],
          logicOperator: GridLogicOperator.And,
        },
      },
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.customers.0': false,
          'issues.0.first_found_on': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
        },
      },
    },
  },
  {
    label: 'POTENTIAL',
    show: true,
    gridState: {
      ...initialGridState,
      filter: {
        filterModel: {
          items: [
            {
              field: 'issues.0.state',
              id: 'issues.0.state',
              operator: 'isAnyOf',
              value: ['new', 'known'],
            },
            {
              field: 'issues.0.type',
              id: 'issues.0.type',
              operator: 'isAnyOf',
              value: ['No barcode', 'Missing Barcode'],
            },
          ],
          logicOperator: GridLogicOperator.And,
        },
      },
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'wms_status.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.customers.0': false,
          'issues.0.first_found_on': false,
          shouldBeAt: false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
        },
      },
    },
  },
  {
    label: 'INCONCLUSIVE',
    show: true,
    gridState: {
      ...initialGridState,
      filter: {
        filterModel: {
          items: [
            {
              field: 'issues.0.state',
              id: 'issues.0.state',
              operator: 'isAnyOf',
              value: ['new', 'known'],
            },
            {
              field: 'issues.0.type',
              id: 'issues.0.type',
              operator: 'isAnyOf',
              value: ['No information'],
            },
          ],
          logicOperator: GridLogicOperator.And,
        },
      },
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          'wms_status.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.customers.0': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
        },
      },
    },
  },
  {
    label: 'SNOOZED',
    show: true,
    gridState: {
      ...initialGridState,
      filter: {
        filterModel: {
          items: [
            {
              field: 'issues.0.state',
              operator: 'contains',
              value: 'snoozed',
            },
          ],
        },
      },
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.customers.0': false,
          'wms_status.changed_at': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
        },
      },
    },
  },
  {
    label: 'EXCLUDED',
    show: true,
    gridState: {
      ...initialGridState,
      filter: {
        filterModel: {
          items: [
            {
              field: 'exclusion_status',
              id: 'exclusion_status',
              operator: 'is',
              value: 'false',
            },
          ],
        },
      },
      columns: {
        columnVisibilityModel: {
          'wms_status.state': false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.customers.0': false,
          'wms_status.changed_at': false,
          'verity_status.state': false,
          shouldBeAt: false,
          'verity_status.collected_at': false,
          'issues.0.first_found_on': false,
          'issues.0.type': false,
          'issues.0.state': false,
        },
        orderedFields: ['slot_label', 'exclusion_status'],
        dimensions: {
          slot_label: {
            maxWidth: -1,
            minWidth: 100,
            width: 178.5,
            flex: 0,
          },
        },
      },
    },
  },
  {
    label: 'INVALID',
    show: true,
    gridState: {
      ...initialGridState,
      filter: {
        filterModel: {
          items: [
            {
              field: 'issues.0.type',
              id: 'issues.0.type',
              operator: 'contains',
              value: 'Invalid WMS data',
            },
          ],
        },
      },
      columns: {
        columnVisibilityModel: {
          exclusion_status: false,
          wasFoundAt: false,
          'wms_status.article_nos.0': false,
          'wms_status.qtys.0': false,
          'wms_status.customers.0': false,
          'wms_status.changed_at': false,
          missing_barcodes: false,
          missing_barcodes_rate: false,
          unexpected_barcodes: false,
          unexpected_barcodes_rate: false,
          matching_barcodes: false,
          matching_barcodes_rate: false,
        },
      },
    },
  },
];
