import { ZoneStatusActions } from '../../reducer/droneZonePage.actions';

export enum DroneZoneCommandType {
  Select = 'Select',
  Edit = 'Edit',
  Delete = 'Delete',
  Deleted = 'Deleted',
  Clone = 'Clone',
  Create = 'Create',
  Update = 'Update',
  Submit = 'Submit',
  CancelCreating = 'CancelCreating',
  CancelEditing = 'CancelEditing',
  CancelDeleting = 'CancelDeleting',
}
export type DroneZoneCommand = {
  type: DroneZoneCommandType;
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => void;
};

export type DroneZoneCloneCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Clone;
};

export type DroneZoneSelectCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Select;
};

export type DroneZoneEditCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Edit;
};

export type DroneZoneDeleteCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Delete;
};

export type DroneZoneDeletedCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Deleted;
};

export type DroneZoneCreateCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Create;
};

export type DroneZoneUpdateCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Update;
};

export type DroneZoneCancelEditingCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.CancelEditing;
};

export type DroneZoneCancelCreatingCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.CancelCreating;
};

export type DroneZoneCancelDeletingCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.CancelDeleting;
};

export type DroneZoneSubmitCommand = DroneZoneCommand & {
  type: DroneZoneCommandType.Submit;
};
