import { SetStateAction } from 'react';
import { FormControl } from '@mui/material';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { IVeritySlotStatusStateST } from 'codegen/location_information';
import { OVERWRITE_MODES } from '../AmendValues.model';
import { locationStyles } from '../../styles';

export const SelectOverwriteContentType = (props: {
  locationData: ILocationData;
  overwriteMode: string;
  overwriteContentType: string;
  setOverwriteContentType: React.Dispatch<SetStateAction<string>>;
}) => {
  const { locationData, overwriteMode, overwriteContentType, setOverwriteContentType } = props;
  const { classes } = locationStyles();

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <CustomSelect
        id="SelectContentType"
        name="SelectContentType"
        testId="SelectContentType"
        error={false}
        errorMessage=""
        defaultValue=""
        variant="outlined"
        disabled={overwriteMode !== OVERWRITE_MODES.MANUAL}
        label={`Select the type for the content for ${locationData.location}`}
        value={overwriteContentType}
        valueOptions={[
          {
            value: IVeritySlotStatusStateST.Barcode,
            label: DISPLAY_VERITY_STATES.BARCODE,
          },
          {
            value: IVeritySlotStatusStateST.Empty,
            label: DISPLAY_VERITY_STATES.EMPTY,
          },
          {
            value: IVeritySlotStatusStateST.NotEmpty,
            label: DISPLAY_VERITY_STATES.NOT_EMPTY,
          },
        ]}
        onChange={(e) => setOverwriteContentType(e.target.value)}
      />
    </FormControl>
  );
};
