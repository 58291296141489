// libraires
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui core
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

// store
import { CLIENT_PAGES_URLS } from 'common/pages';
import { Box } from 'components/common/Box';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { AppActionNames } from '../../store/FacilityLevelStore/facilityLevelActions';

// helpers, variables, functions

// components

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '600px',
  },
  errorTitle: {
    fontWeight: 'bold',
    color: theme.palette.textPrimary.dark,
    marginBottom: theme.spacing(4),
  },
}));

export const NotFound = () => {
  // Context
  const { dispatchFacilityLevel } = useFacilityLevelStore();

  // styles
  const { classes, cx } = useStyles();

  // navigate
  const navigate = useNavigate();

  /**
   * This use effect intercepts the browser "navigate back" button and redirects the user to the "home".
   */
  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.location.href = '/';
    });
    window.history.pushState(
      { name: 'browserBack' },
      'on browser back click',
      window.location.href,
    );

    return () => {
      window.removeEventListener('popstate', () => {
        window.location.href = '/';
      });
    };
  }, []);

  return (
    <Grid
      className={cx(classes.root, 'c-page-wrapper')}
      alignItems="center"
      justifyContent="center"
      container
    >
      <Grid item xs={11} sm={8} md={4} className="c-page-content">
        <Typography data-testid="c-404-not-found-title" variant="h3" className={classes.errorTitle}>
          404 Not Found
        </Typography>
        <Box mb={4} display="flex">
          <Typography color="textSecondary" variant="h5">
            The requested URL was not found.
          </Typography>
        </Box>
        <Button
          style={{ marginRight: '8px' }}
          color="primary"
          variant="contained"
          onClick={() => {
            dispatchFacilityLevel({ type: AppActionNames.SET_NOT_FOUND, payload: false });
            navigate(CLIENT_PAGES_URLS.SELECT_FACILITY);
          }}
        >
          Go Home
        </Button>
      </Grid>
    </Grid>
  );
};
