import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { OpenFlyZones } from 'shared/map/model/open-fly-zone.model';
import { MapOptions } from 'shared/map-container/reducer/MapOptionsState';
import { groupCountBadgesFromControlledZones } from './groupCountBadgesFromControlledZones';
import { meshesFromControlledZones } from './meshesFromControlledZones';
import { meshesFromOpenFlyZones } from './meshesFromOpenFlyZones';
import { DeliveryDroneZoneType } from '../../models/deliveryDroneZones.model';

export const buildDroneZonesMeshes = ({
  visibleZones,
  controlledZones,
  openFlyZones,
  mapOptions,
  facilityHeight,
  onDroneZoneClick,
  onDroneZoneGroupClick,
  onDroneZoneDragEnd,
  onDroneZoneDragStart,
}: {
  visibleZones: Record<DeliveryDroneZoneType, boolean>;
  controlledZones: ControlledZones;
  openFlyZones: OpenFlyZones;
  mapOptions: MapOptions;
  facilityHeight: number;
  onDroneZoneClick: (zone: ControlledZone) => void;
  onDroneZoneGroupClick: (zones: ControlledZone[]) => void;
  onDroneZoneDragEnd: (zone: ControlledZone) => void;
  onDroneZoneDragStart: () => void;
}) => {
  const droneZoneGroupCountBadges: JSX.Element[] = visibleZones[DroneZoneTypes.controlledZone]
    ? groupCountBadgesFromControlledZones({
        zones: controlledZones,
        mapOptions,
        minZOffset: facilityHeight,
        onDroneZoneGroupClick,
      })
    : [];

  const controlledZoneMeshes: JSX.Element[] = visibleZones[DroneZoneTypes.controlledZone]
    ? meshesFromControlledZones({
        controlledZones,
        mapOptions,
        onDroneZoneClick,
        onDroneZoneDragEnd,
        onDroneZoneDragStart,
      })
    : [];

  const openFlyZoneMeshes: JSX.Element[] = visibleZones[DroneZoneTypes.openFlyZone]
    ? meshesFromOpenFlyZones(openFlyZones, mapOptions, onDroneZoneClick)
    : [];

  return [...openFlyZoneMeshes, ...controlledZoneMeshes, ...droneZoneGroupCountBadges];
};
