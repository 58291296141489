/* tslint:disable */
/* eslint-disable */
/**
 * WMS Data
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const INotAvailableValueST = {
  NA: 'n.a.',
} as const;

export type INotAvailableValueST = typeof INotAvailableValueST[keyof typeof INotAvailableValueST];

/**
 *
 * @export
 * @enum {string}
 */

export const INullableFieldST = {
  Null: 'null',
  None: 'None',
} as const;

export type INullableFieldST = typeof INullableFieldST[keyof typeof INullableFieldST];

/**
 * @type IWMSDataFilesGetResponseOneOf1ItemST
 * @export
 */
export type IWMSDataFilesGetResponseOneOf1ItemST = INullableFieldST | IWMSFilesST;

/**
 *
 * @export
 * @interface IWMSDataFilesGetResponseOneOf1ST
 */
export interface IWMSDataFilesGetResponseOneOf1ST {
  /**
   *
   * @type {IWMSDataFilesGetResponseOneOf1ItemST}
   * @memberof IWMSDataFilesGetResponseOneOf1ST
   */
  item: IWMSDataFilesGetResponseOneOf1ItemST | null;
}
/**
 *
 * @export
 * @interface IWMSDataFilesGetResponseOneOfST
 */
export interface IWMSDataFilesGetResponseOneOfST {
  /**
   *
   * @type {Array<IWMSFilesST>}
   * @memberof IWMSDataFilesGetResponseOneOfST
   */
  items: Array<IWMSFilesST>;
  /**
   * Token for next page iteration
   * @type {string}
   * @memberof IWMSDataFilesGetResponseOneOfST
   */
  page_token: string | null;
}
/**
 * @type IWMSDataFilesGetResponseST
 * @export
 */
export type IWMSDataFilesGetResponseST =
  | IWMSDataFilesGetResponseOneOf1ST
  | IWMSDataFilesGetResponseOneOfST;

/**
 *
 * @export
 * @interface IWMSDataUploadPostRequestST
 */
export interface IWMSDataUploadPostRequestST {
  /**
   * The upload file name
   * @type {string}
   * @memberof IWMSDataUploadPostRequestST
   */
  upload_filename: string;
}
/**
 * The form fields and respective values to use when submitting the post
 * @export
 * @interface IWMSDataUploadPostResponsePresignedPostFieldsST
 */
export interface IWMSDataUploadPostResponsePresignedPostFieldsST {
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  policy?: string;
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  'x-amz-algorithm'?: string;
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  'x-amz-credential'?: string;
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  'x-amz-date'?: string;
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  'x-amz-meta-upload-filename'?: string;
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  'x-amz-meta-user-email'?: string;
  /**
   *
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostFieldsST
   */
  'x-amz-signature'?: string;
}
/**
 *
 * @export
 * @interface IWMSDataUploadPostResponsePresignedPostST
 */
export interface IWMSDataUploadPostResponsePresignedPostST {
  /**
   *
   * @type {IWMSDataUploadPostResponsePresignedPostFieldsST}
   * @memberof IWMSDataUploadPostResponsePresignedPostST
   */
  fields?: IWMSDataUploadPostResponsePresignedPostFieldsST;
  /**
   * URL to post to
   * @type {string}
   * @memberof IWMSDataUploadPostResponsePresignedPostST
   */
  url?: string;
}
/**
 *
 * @export
 * @interface IWMSDataUploadPostResponseST
 */
export interface IWMSDataUploadPostResponseST {
  /**
   *
   * @type {IWMSDataUploadPostResponsePresignedPostST}
   * @memberof IWMSDataUploadPostResponseST
   */
  presigned_post: IWMSDataUploadPostResponsePresignedPostST;
}
/**
 * @type IWMSFilesClientFileUrlST
 * @export
 */
export type IWMSFilesClientFileUrlST = INullableFieldST | string;

/**
 * @type IWMSFilesLastModifiedST
 * @export
 */
export type IWMSFilesLastModifiedST = INotAvailableValueST | string;

/**
 * @type IWMSFilesLocationsWithUpdatedChangedAtST
 * @export
 */
export type IWMSFilesLocationsWithUpdatedChangedAtST = INotAvailableValueST | number;

/**
 * @type IWMSFilesMostRecentEntryUpdatedAtST
 * @export
 */
export type IWMSFilesMostRecentEntryUpdatedAtST = INotAvailableValueST | INullableFieldST | string;

/**
 * @type IWMSFilesRequestWarningsST
 * @export
 */
export type IWMSFilesRequestWarningsST = INotAvailableValueST | number;

/**
 *
 * @export
 * @interface IWMSFilesST
 */
export interface IWMSFilesST {
  /**
   * File name
   * @type {string}
   * @memberof IWMSFilesST
   */
  client_file_name: string;
  /**
   *
   * @type {IWMSFilesClientFileUrlST}
   * @memberof IWMSFilesST
   */
  client_file_url: IWMSFilesClientFileUrlST | null;
  /**
   *
   * @type {IWMSFilesMostRecentEntryUpdatedAtST}
   * @memberof IWMSFilesST
   */
  most_recent_entry_updated_at: IWMSFilesMostRecentEntryUpdatedAtST | null;
  /**
   *
   * @type {IWMSFilesUploaderST}
   * @memberof IWMSFilesST
   */
  uploader: IWMSFilesUploaderST;
  /**
   * Status of uploaded file
   * @type {string}
   * @memberof IWMSFilesST
   */
  status: IWMSFilesSTStatusEnum;
  /**
   *
   * @type {IWMSFilesLastModifiedST}
   * @memberof IWMSFilesST
   */
  last_modified: IWMSFilesLastModifiedST;
  /**
   *
   * @type {IWMSFilesTotalLocationsST}
   * @memberof IWMSFilesST
   */
  total_locations: IWMSFilesTotalLocationsST;
  /**
   *
   * @type {IWMSFilesSkippedLocationsST}
   * @memberof IWMSFilesST
   */
  skipped_locations: IWMSFilesSkippedLocationsST;
  /**
   *
   * @type {IWMSFilesUpdatedLocationsST}
   * @memberof IWMSFilesST
   */
  updated_locations: IWMSFilesUpdatedLocationsST;
  /**
   *
   * @type {IWMSFilesLocationsWithUpdatedChangedAtST}
   * @memberof IWMSFilesST
   */
  locations_with_updated_changed_at: IWMSFilesLocationsWithUpdatedChangedAtST;
  /**
   *
   * @type {IWMSFilesRequestWarningsST}
   * @memberof IWMSFilesST
   */
  request_warnings: IWMSFilesRequestWarningsST;
  /**
   *
   * @type {IWMSFilesRequestWarningsST}
   * @memberof IWMSFilesST
   */
  location_warnings: IWMSFilesRequestWarningsST;
  /**
   *
   * @type {IWMSFilesRequestWarningsST}
   * @memberof IWMSFilesST
   */
  num_chunks: IWMSFilesRequestWarningsST;
  /**
   *
   * @type {IWMSFilesRequestWarningsST}
   * @memberof IWMSFilesST
   */
  processed_chunks: IWMSFilesRequestWarningsST;
  /**
   *
   * @type {IWMSFilesWmsParserOutputUrlST}
   * @memberof IWMSFilesST
   */
  wms_parser_output_url?: IWMSFilesWmsParserOutputUrlST;
}

export const IWMSFilesSTStatusEnum = {
  Valid: 'valid',
  Invalid: 'invalid',
  NA: 'n.a.',
} as const;

export type IWMSFilesSTStatusEnum =
  typeof IWMSFilesSTStatusEnum[keyof typeof IWMSFilesSTStatusEnum];

/**
 * @type IWMSFilesSkippedLocationsST
 * @export
 */
export type IWMSFilesSkippedLocationsST = INotAvailableValueST | number;

/**
 * @type IWMSFilesTotalLocationsST
 * @export
 */
export type IWMSFilesTotalLocationsST = INotAvailableValueST | number;

/**
 * @type IWMSFilesUpdatedLocationsST
 * @export
 */
export type IWMSFilesUpdatedLocationsST = INotAvailableValueST | number;

/**
 * @type IWMSFilesUploaderST
 * @export
 */
export type IWMSFilesUploaderST = INotAvailableValueST | string;

/**
 * @type IWMSFilesWmsParserOutputUrlST
 * @export
 */
export type IWMSFilesWmsParserOutputUrlST = INotAvailableValueST | string;

/**
 *
 * @export
 * @interface IWMSMovedLocationsDataUploadGetResponseST
 */
export interface IWMSMovedLocationsDataUploadGetResponseST {
  /**
   *
   * @type {Array<IWMSFilesST>}
   * @memberof IWMSMovedLocationsDataUploadGetResponseST
   */
  items: Array<IWMSFilesST>;
  /**
   * Token for next page iteration
   * @type {string}
   * @memberof IWMSMovedLocationsDataUploadGetResponseST
   */
  page_token: string | null;
  /**
   *
   * @type {IWMSDataFilesGetResponseOneOf1ItemST}
   * @memberof IWMSMovedLocationsDataUploadGetResponseST
   */
  item: IWMSDataFilesGetResponseOneOf1ItemST | null;
}
/**
 *
 * @export
 * @interface IWMSMovedLocationsDataUploadPostRequestST
 */
export interface IWMSMovedLocationsDataUploadPostRequestST {
  /**
   * The upload file name
   * @type {string}
   * @memberof IWMSMovedLocationsDataUploadPostRequestST
   */
  upload_filename: string;
}
/**
 *
 * @export
 * @interface IWMSMovedLocationsDataUploadPostResponseST
 */
export interface IWMSMovedLocationsDataUploadPostResponseST {
  /**
   *
   * @type {IWMSDataUploadPostResponsePresignedPostST}
   * @memberof IWMSMovedLocationsDataUploadPostResponseST
   */
  presigned_post: IWMSDataUploadPostResponsePresignedPostST;
}
/**
 *
 * @export
 * @interface IWMSParsersGetResponseST
 */
export interface IWMSParsersGetResponseST {
  /**
   *
   * @type {Array<string>}
   * @memberof IWMSParsersGetResponseST
   */
  wms_parser_names: Array<string>;
}
/**
 *
 * @export
 * @interface IWMSStockCheckDataUploadGetResponseST
 */
export interface IWMSStockCheckDataUploadGetResponseST {
  /**
   *
   * @type {Array<IWMSFilesST>}
   * @memberof IWMSStockCheckDataUploadGetResponseST
   */
  items: Array<IWMSFilesST>;
  /**
   * Token for next page iteration
   * @type {string}
   * @memberof IWMSStockCheckDataUploadGetResponseST
   */
  page_token: string | null;
  /**
   *
   * @type {IWMSDataFilesGetResponseOneOf1ItemST}
   * @memberof IWMSStockCheckDataUploadGetResponseST
   */
  item: IWMSDataFilesGetResponseOneOf1ItemST | null;
}
/**
 *
 * @export
 * @interface IWMSStockCheckDataUploadPostRequestST
 */
export interface IWMSStockCheckDataUploadPostRequestST {
  /**
   * The upload file name
   * @type {string}
   * @memberof IWMSStockCheckDataUploadPostRequestST
   */
  upload_filename: string;
}
/**
 *
 * @export
 * @interface IWMSStockCheckDataUploadPostResponseST
 */
export interface IWMSStockCheckDataUploadPostResponseST {
  /**
   *
   * @type {IWMSDataUploadPostResponsePresignedPostST}
   * @memberof IWMSStockCheckDataUploadPostResponseST
   */
  presigned_post: IWMSDataUploadPostResponsePresignedPostST;
}

/**
 * WMSDataApi - axios parameter creator
 * @export
 */
export const WMSDataApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Return presigned post for WMS moved locations file upload.
     * @summary Get presigned post for WMS moved locations file upload.
     * @param {string} systemIdStr
     * @param {IWMSMovedLocationsDataUploadPostRequestST} iWMSMovedLocationsDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovedLocationsDataPresignedUrl: async (
      systemIdStr: string,
      iWMSMovedLocationsDataUploadPostRequestST: IWMSMovedLocationsDataUploadPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getMovedLocationsDataPresignedUrl', 'systemIdStr', systemIdStr);
      // verify required parameter 'iWMSMovedLocationsDataUploadPostRequestST' is not null or undefined
      assertParamExists(
        'getMovedLocationsDataPresignedUrl',
        'iWMSMovedLocationsDataUploadPostRequestST',
        iWMSMovedLocationsDataUploadPostRequestST,
      );
      const localVarPath = `/{system_id_str}/wms-moved-locations-data/upload`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iWMSMovedLocationsDataUploadPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return presigned post for WMS stock check file upload.
     * @summary Get presigned post for WMS stock check file upload.
     * @param {string} systemIdStr
     * @param {IWMSStockCheckDataUploadPostRequestST} iWMSStockCheckDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockCheckDataPresignedUrl: async (
      systemIdStr: string,
      iWMSStockCheckDataUploadPostRequestST: IWMSStockCheckDataUploadPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getStockCheckDataPresignedUrl', 'systemIdStr', systemIdStr);
      // verify required parameter 'iWMSStockCheckDataUploadPostRequestST' is not null or undefined
      assertParamExists(
        'getStockCheckDataPresignedUrl',
        'iWMSStockCheckDataUploadPostRequestST',
        iWMSStockCheckDataUploadPostRequestST,
      );
      const localVarPath = `/{system_id_str}/wms-stock-check-data/upload`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iWMSStockCheckDataUploadPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns uploaded moved locations file/s.
     * @summary Get uploaded moved locations file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadedMovedLocationsData: async (
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getUploadedMovedLocationsData', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/wms-moved-locations-data/files`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] =
          (until as any) instanceof Date ? (until as any).toISOString() : until;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['page_token'] = pageToken;
      }

      if (latest !== undefined) {
        localVarQueryParameter['latest'] = latest;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns uploaded stock check file/s.
     * @summary Get uploaded stock check file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadedStockCheckData: async (
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getUploadedStockCheckData', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/wms-stock-check-data/files`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] =
          (until as any) instanceof Date ? (until as any).toISOString() : until;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['page_token'] = pageToken;
      }

      if (latest !== undefined) {
        localVarQueryParameter['latest'] = latest;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns uploaded snapshot file/s.
     * @summary Get uploaded snapshot file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadedWMSData: async (
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getUploadedWMSData', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/wms-data/files`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] =
          (until as any) instanceof Date ? (until as any).toISOString() : until;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['page_token'] = pageToken;
      }

      if (latest !== undefined) {
        localVarQueryParameter['latest'] = latest;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return presigned post for WMS snapshot file upload.
     * @summary Get presigned post for WMS snapshot file upload.
     * @param {string} systemIdStr
     * @param {IWMSDataUploadPostRequestST} iWMSDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWMSDataPresignedUrl: async (
      systemIdStr: string,
      iWMSDataUploadPostRequestST: IWMSDataUploadPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getWMSDataPresignedUrl', 'systemIdStr', systemIdStr);
      // verify required parameter 'iWMSDataUploadPostRequestST' is not null or undefined
      assertParamExists(
        'getWMSDataPresignedUrl',
        'iWMSDataUploadPostRequestST',
        iWMSDataUploadPostRequestST,
      );
      const localVarPath = `/{system_id_str}/wms-data/upload`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iWMSDataUploadPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return available WMS parsers.
     * @summary Get available WMS parsers.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWmsParsers: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getWmsParsers', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/wms-parsers`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WMSDataApi - functional programming interface
 * @export
 */
export const WMSDataApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WMSDataApiAxiosParamCreator(configuration);
  return {
    /**
     * Return presigned post for WMS moved locations file upload.
     * @summary Get presigned post for WMS moved locations file upload.
     * @param {string} systemIdStr
     * @param {IWMSMovedLocationsDataUploadPostRequestST} iWMSMovedLocationsDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMovedLocationsDataPresignedUrl(
      systemIdStr: string,
      iWMSMovedLocationsDataUploadPostRequestST: IWMSMovedLocationsDataUploadPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IWMSMovedLocationsDataUploadPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMovedLocationsDataPresignedUrl(
        systemIdStr,
        iWMSMovedLocationsDataUploadPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return presigned post for WMS stock check file upload.
     * @summary Get presigned post for WMS stock check file upload.
     * @param {string} systemIdStr
     * @param {IWMSStockCheckDataUploadPostRequestST} iWMSStockCheckDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStockCheckDataPresignedUrl(
      systemIdStr: string,
      iWMSStockCheckDataUploadPostRequestST: IWMSStockCheckDataUploadPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IWMSStockCheckDataUploadPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStockCheckDataPresignedUrl(
        systemIdStr,
        iWMSStockCheckDataUploadPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns uploaded moved locations file/s.
     * @summary Get uploaded moved locations file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUploadedMovedLocationsData(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IWMSMovedLocationsDataUploadGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadedMovedLocationsData(
        systemIdStr,
        from,
        until,
        limit,
        pageToken,
        latest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns uploaded stock check file/s.
     * @summary Get uploaded stock check file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUploadedStockCheckData(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IWMSStockCheckDataUploadGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadedStockCheckData(
        systemIdStr,
        from,
        until,
        limit,
        pageToken,
        latest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns uploaded snapshot file/s.
     * @summary Get uploaded snapshot file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUploadedWMSData(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWMSDataFilesGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadedWMSData(
        systemIdStr,
        from,
        until,
        limit,
        pageToken,
        latest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return presigned post for WMS snapshot file upload.
     * @summary Get presigned post for WMS snapshot file upload.
     * @param {string} systemIdStr
     * @param {IWMSDataUploadPostRequestST} iWMSDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWMSDataPresignedUrl(
      systemIdStr: string,
      iWMSDataUploadPostRequestST: IWMSDataUploadPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWMSDataUploadPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWMSDataPresignedUrl(
        systemIdStr,
        iWMSDataUploadPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return available WMS parsers.
     * @summary Get available WMS parsers.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWmsParsers(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWMSParsersGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWmsParsers(systemIdStr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WMSDataApi - factory interface
 * @export
 */
export const WMSDataApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WMSDataApiFp(configuration);
  return {
    /**
     * Return presigned post for WMS moved locations file upload.
     * @summary Get presigned post for WMS moved locations file upload.
     * @param {string} systemIdStr
     * @param {IWMSMovedLocationsDataUploadPostRequestST} iWMSMovedLocationsDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMovedLocationsDataPresignedUrl(
      systemIdStr: string,
      iWMSMovedLocationsDataUploadPostRequestST: IWMSMovedLocationsDataUploadPostRequestST,
      options?: any,
    ): AxiosPromise<IWMSMovedLocationsDataUploadPostResponseST> {
      return localVarFp
        .getMovedLocationsDataPresignedUrl(
          systemIdStr,
          iWMSMovedLocationsDataUploadPostRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Return presigned post for WMS stock check file upload.
     * @summary Get presigned post for WMS stock check file upload.
     * @param {string} systemIdStr
     * @param {IWMSStockCheckDataUploadPostRequestST} iWMSStockCheckDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockCheckDataPresignedUrl(
      systemIdStr: string,
      iWMSStockCheckDataUploadPostRequestST: IWMSStockCheckDataUploadPostRequestST,
      options?: any,
    ): AxiosPromise<IWMSStockCheckDataUploadPostResponseST> {
      return localVarFp
        .getStockCheckDataPresignedUrl(systemIdStr, iWMSStockCheckDataUploadPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns uploaded moved locations file/s.
     * @summary Get uploaded moved locations file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadedMovedLocationsData(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options?: any,
    ): AxiosPromise<IWMSMovedLocationsDataUploadGetResponseST> {
      return localVarFp
        .getUploadedMovedLocationsData(systemIdStr, from, until, limit, pageToken, latest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns uploaded stock check file/s.
     * @summary Get uploaded stock check file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadedStockCheckData(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options?: any,
    ): AxiosPromise<IWMSStockCheckDataUploadGetResponseST> {
      return localVarFp
        .getUploadedStockCheckData(systemIdStr, from, until, limit, pageToken, latest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns uploaded snapshot file/s.
     * @summary Get uploaded snapshot file/s.
     * @param {string} systemIdStr
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {number} [limit] Page size
     * @param {string} [pageToken] Token used to iterate pages
     * @param {'true' | 'false'} [latest] Return latest uploaded file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadedWMSData(
      systemIdStr: string,
      from?: string,
      until?: string,
      limit?: number,
      pageToken?: string,
      latest?: 'true' | 'false',
      options?: any,
    ): AxiosPromise<IWMSDataFilesGetResponseST> {
      return localVarFp
        .getUploadedWMSData(systemIdStr, from, until, limit, pageToken, latest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return presigned post for WMS snapshot file upload.
     * @summary Get presigned post for WMS snapshot file upload.
     * @param {string} systemIdStr
     * @param {IWMSDataUploadPostRequestST} iWMSDataUploadPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWMSDataPresignedUrl(
      systemIdStr: string,
      iWMSDataUploadPostRequestST: IWMSDataUploadPostRequestST,
      options?: any,
    ): AxiosPromise<IWMSDataUploadPostResponseST> {
      return localVarFp
        .getWMSDataPresignedUrl(systemIdStr, iWMSDataUploadPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return available WMS parsers.
     * @summary Get available WMS parsers.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWmsParsers(systemIdStr: string, options?: any): AxiosPromise<IWMSParsersGetResponseST> {
      return localVarFp
        .getWmsParsers(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WMSDataApi - object-oriented interface
 * @export
 * @class WMSDataApi
 * @extends {BaseAPI}
 */
export class WMSDataApi extends BaseAPI {
  /**
   * Return presigned post for WMS moved locations file upload.
   * @summary Get presigned post for WMS moved locations file upload.
   * @param {string} systemIdStr
   * @param {IWMSMovedLocationsDataUploadPostRequestST} iWMSMovedLocationsDataUploadPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WMSDataApi
   */
  public getMovedLocationsDataPresignedUrl(
    systemIdStr: string,
    iWMSMovedLocationsDataUploadPostRequestST: IWMSMovedLocationsDataUploadPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return WMSDataApiFp(this.configuration)
      .getMovedLocationsDataPresignedUrl(
        systemIdStr,
        iWMSMovedLocationsDataUploadPostRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return presigned post for WMS stock check file upload.
   * @summary Get presigned post for WMS stock check file upload.
   * @param {string} systemIdStr
   * @param {IWMSStockCheckDataUploadPostRequestST} iWMSStockCheckDataUploadPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WMSDataApi
   */
  public getStockCheckDataPresignedUrl(
    systemIdStr: string,
    iWMSStockCheckDataUploadPostRequestST: IWMSStockCheckDataUploadPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return WMSDataApiFp(this.configuration)
      .getStockCheckDataPresignedUrl(systemIdStr, iWMSStockCheckDataUploadPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns uploaded moved locations file/s.
   * @summary Get uploaded moved locations file/s.
   * @param {string} systemIdStr
   * @param {string} [from] Start timestamp for filter
   * @param {string} [until] End timestamp for filter
   * @param {number} [limit] Page size
   * @param {string} [pageToken] Token used to iterate pages
   * @param {'true' | 'false'} [latest] Return latest uploaded file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WMSDataApi
   */
  public getUploadedMovedLocationsData(
    systemIdStr: string,
    from?: string,
    until?: string,
    limit?: number,
    pageToken?: string,
    latest?: 'true' | 'false',
    options?: AxiosRequestConfig,
  ) {
    return WMSDataApiFp(this.configuration)
      .getUploadedMovedLocationsData(systemIdStr, from, until, limit, pageToken, latest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns uploaded stock check file/s.
   * @summary Get uploaded stock check file/s.
   * @param {string} systemIdStr
   * @param {string} [from] Start timestamp for filter
   * @param {string} [until] End timestamp for filter
   * @param {number} [limit] Page size
   * @param {string} [pageToken] Token used to iterate pages
   * @param {'true' | 'false'} [latest] Return latest uploaded file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WMSDataApi
   */
  public getUploadedStockCheckData(
    systemIdStr: string,
    from?: string,
    until?: string,
    limit?: number,
    pageToken?: string,
    latest?: 'true' | 'false',
    options?: AxiosRequestConfig,
  ) {
    return WMSDataApiFp(this.configuration)
      .getUploadedStockCheckData(systemIdStr, from, until, limit, pageToken, latest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns uploaded snapshot file/s.
   * @summary Get uploaded snapshot file/s.
   * @param {string} systemIdStr
   * @param {string} [from] Start timestamp for filter
   * @param {string} [until] End timestamp for filter
   * @param {number} [limit] Page size
   * @param {string} [pageToken] Token used to iterate pages
   * @param {'true' | 'false'} [latest] Return latest uploaded file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WMSDataApi
   */
  public getUploadedWMSData(
    systemIdStr: string,
    from?: string,
    until?: string,
    limit?: number,
    pageToken?: string,
    latest?: 'true' | 'false',
    options?: AxiosRequestConfig,
  ) {
    return WMSDataApiFp(this.configuration)
      .getUploadedWMSData(systemIdStr, from, until, limit, pageToken, latest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return presigned post for WMS snapshot file upload.
   * @summary Get presigned post for WMS snapshot file upload.
   * @param {string} systemIdStr
   * @param {IWMSDataUploadPostRequestST} iWMSDataUploadPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WMSDataApi
   */
  public getWMSDataPresignedUrl(
    systemIdStr: string,
    iWMSDataUploadPostRequestST: IWMSDataUploadPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return WMSDataApiFp(this.configuration)
      .getWMSDataPresignedUrl(systemIdStr, iWMSDataUploadPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return available WMS parsers.
   * @summary Get available WMS parsers.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WMSDataApi
   */
  public getWmsParsers(systemIdStr: string, options?: AxiosRequestConfig) {
    return WMSDataApiFp(this.configuration)
      .getWmsParsers(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
