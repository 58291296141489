export const meshRenderOrder = {
  ground: 0,
  racks: 1,
  open_fly_zones: 2,
  chargers: 3,
  zones: 3,
  no_fly_zones: 4,
  groups: 5,
  highlight: 6,
  label: 7,
  map_overlay: 10,
  creation: 11,
};

export const meshHeightOrderOffset = {
  groups: 0.5,
  mapOverlay: 1,
};
