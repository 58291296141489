import { Typography, useTheme } from '@mui/material';
import { IRulesActionSTIssueLogicEnum } from 'codegen/facility_settings';
import {
  BarcodeMatchLogic,
  getStatusColorChip,
} from 'common/functions/barcodes/getStatusColorChip.util';
import { muiColorToTheme, MuiColor } from 'common/functions/issues/issueColorFunctions';
import { barcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';

const defaultColors: { [key: string]: MuiColor } = {
  [barcodeStatusEnum.Match]: 'success',
  [barcodeStatusEnum.Missing]: 'error',
  [barcodeStatusEnum.Unexpected]: 'error',
};

export const BarcodeLine = ({
  barcode,
  barcodeStatus,
  barcodeMatchLogic,
  issueLogic,
  isStale,
}: {
  barcode: string;
  barcodeStatus: barcodeStatusEnum;
  barcodeMatchLogic?: BarcodeMatchLogic;
  issueLogic?: IRulesActionSTIssueLogicEnum;
  isStale?: boolean;
}) => {
  const theme = useTheme();
  const statusColor = barcodeMatchLogic
    ? getStatusColorChip(barcodeStatus, barcodeMatchLogic, issueLogic) || 'success'
    : defaultColors[barcodeStatus];
  const color = isStale ? theme.palette.text.disabled : muiColorToTheme(statusColor, theme).main;
  return (
    <Typography
      className="c-barcode-line"
      component="span"
      variant="body2"
      sx={{ color, cursor: 'default' }}
    >
      {barcode}
    </Typography>
  );
};
