import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotInterested from '@mui/icons-material/NotInterested';
import EnhancedTable from 'components/EnhancedTable';
import { EnhancedTableTab } from 'components/EnhancedTable/types/tab';
import { userHasPermission } from '../../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../../features/permissions/permissions.model';
import { locationsHeaderCells } from '../../utils/LocationsHeaderCells';
import { locationsToRows } from '../../utils/LocationsToRows';
import { LocationRow } from '../../models/LocationsRow';
import { LocationsTableProps } from '../../models/LocationsTableProps';
import { LocationsToolbar } from '../toolbar/LocationsToolbar';

/**
 * Locations table
 * @returns JSX.Element
 */
export const LocationsTable = ({
  locations,
  numberOfSelectedRows,
  setIdForReset,
  isLoading,
  onUpdateExclusionStatus,
  selectedLocationIds,
  onRowClick,
  onSelectedIds,
  onUploadedFile,
  uploadedFile,
  exclusionHistory,
}: LocationsTableProps) => {
  const [searchParams] = useSearchParams();

  const activeTab = Number(searchParams.get('activeTab')) || 0;

  const { rows: allRows, rowsWithWmsData } = useMemo(
    () => locationsToRows(locations, setIdForReset, exclusionHistory),
    [locations, setIdForReset, exclusionHistory],
  );

  const excludedRows = useMemo(
    () => allRows.filter((i: LocationRow) => i.exclusionStatus === 'EXCLUDE'),
    [allRows],
  );

  const tabs: EnhancedTableTab[] = [
    { label: `All (${allRows.length})`, icon: <LocationOnIcon />, show: true },
    {
      label: `Excluded (${excludedRows.length})`,
      icon: <NotInterested />,
      show: true,
    },
  ];

  const rows = {
    0: allRows,
    1: excludedRows,
  };

  const activeTabRows = rows[activeTab as keyof typeof rows];

  const hasIncludeExcludeLocationPermission = userHasPermission(
    PERMISSION.EXCLUDE_INCLUDE_LOCATIONS,
  );

  return (
    <EnhancedTable
      hideToolbar
      tabs={tabs}
      onRowSelect={onSelectedIds}
      onRowClick={onRowClick}
      customToolbar={
        hasIncludeExcludeLocationPermission ? (
          <LocationsToolbar
            allRows={allRows}
            hasWmsHeader={rowsWithWmsData}
            onUpdateExclusionStatus={onUpdateExclusionStatus}
            onSelectedIds={onSelectedIds}
            selectedLocationIds={selectedLocationIds}
            onUploadedFile={onUploadedFile}
            numberOfSelectedRows={numberOfSelectedRows}
            uploadedFile={uploadedFile}
          />
        ) : null
      }
      isTableDisabled={!!uploadedFile}
      currentActiveSpinner={{}}
      headCells={locationsHeaderCells(rowsWithWmsData)}
      isLoading={isLoading}
      rows={activeTabRows}
      tableFor="Locations"
      tableSubtitle="Details about the locations and scan permission."
      tableTitle="Locations"
    />
  );
};
