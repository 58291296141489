import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { controlledZoneOpacity } from 'shared/map/defaults/controlledZoneOpacity.default';

export const useStyles = makeStyles()((theme: Theme) => ({
  controlledZone: {
    background: 'none',
    border: '1px solid',
    transition: `border-color ${theme.transitions.duration.short}ms`,

    '&:focus': {
      border: `1px solid ${mapStyle.controlledZones.inactive.borderColor.primary}`,
    },
  },
  controlledZoneInactive: {
    color: mapStyle.controlledZones.inactive.textColor.primary,
    background: mapStyle.controlledZones.inactive.backgroundColor.primary,
    borderColor: mapStyle.controlledZones.inactive.borderColor.secondary,

    '&:hover': {
      borderColor: mapStyle.controlledZones.active.borderColor.primary,
    },
  },
  controlledZoneActive: {
    color: mapStyle.controlledZones.active.textColor.primary,
    background: alpha(
      mapStyle.controlledZones.active.backgroundColor.primary,
      controlledZoneOpacity.active,
    ),
    borderColor: alpha(
      mapStyle.controlledZones.active.backgroundColor.primary,
      controlledZoneOpacity.active,
    ),
  },
  controlledZoneSelectedActive: {
    color: mapStyle.controlledZones.active.textColor.primary,
    background: alpha(
      mapStyle.controlledZones.active.backgroundColor.primary,
      controlledZoneOpacity.activeSelected,
    ),
    borderColor: alpha(
      mapStyle.controlledZones.active.backgroundColor.primary,
      controlledZoneOpacity.activeSelected,
    ),
    zIndex: 1,
  },
  controlledZoneSelectedInactive: {
    borderColor: mapStyle.controlledZones.active.borderColor.primary,
    zIndex: 1,
  },
}));
