import { useNavigate, useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

import { useState } from 'react';
import {
  INVENTORY_PAGES_URLS,
  FACILITY_SETTINGS_PAGES_URLS,
  WARNING_PAGES_URLS,
  GROUND_CONTROL_PAGES_URLS,
  DELIVERY_PAGES_URLS,
} from 'common/pages';
import { LocalStore } from 'common/functions/storageFunctions';
import { Box } from 'components/common/Box';
import { FacilityActionNames } from 'store/FacilityLevelStore/facilityLevelActions';
import { GroundControlActionNames } from 'store/GroundControl/groundControlLevelActions';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import ModalBase from './ModalBase';
import FacilitiesList from '../../pages/SelectFacility/FacilitiesList';

import { getHomeRouteBasedOnPermissions } from '../../pages/SelectFacility/getHomeRouteBasedOnPermissions';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    minWidth: '200px',
    width: '500px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const ModalSelectFacility = (props: { closeModal: () => void }) => {
  const { closeModal } = props;

  const navigate = useNavigate();

  // currently loaded facility
  const systemId = useLocation().pathname.split('/')[1];

  const { classes } = useStyles();

  const { dispatchFacilityLevel } = useFacilityLevelStore();
  const { dispatchGroundControlLevel } = useGroundControlStore();
  const { dispatchMapStore } = useMapStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleFacilitySelect = async (selectedSystemId: string, isConfigured?: boolean) => {
    // If the user clicks the facility that is currently
    // loaded, we just close the modal
    if (selectedSystemId === systemId) {
      closeModal();
      return;
    }
    setIsLoading(true);

    // Note: this part is possibly a little fragile: it is assuming that the topic part
    // of the path will be in the 4th item. When splitting the first element is an empty string
    // Example: /systemId/inventory/reports  ["", 17, inventory, reports]
    const urlTokens = window.location.pathname.split('/');
    const currentPage = urlTokens[3];

    // Note: here we substitute the current systemId with the new one
    urlTokens[1] = selectedSystemId;

    let url = urlTokens.join('/');

    // Exception 1 - If the new facility selected by the user
    // is not configured, load the admin/facility-details page
    if (!isConfigured && !url.includes(FACILITY_SETTINGS_PAGES_URLS.SYSTEM_DETAILS)) {
      LocalStore.clearFacilityDataFromLocalStorage();
      url = `/${selectedSystemId}${FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS}`;
    } else if (currentPage === 'report') {
      // Exception 2 - If the user is in a report page, when loading the new facility
      // the user shall be redirected to the report list page (since a given report exists
      // at most in one facility)
      url = `/${selectedSystemId}${INVENTORY_PAGES_URLS.REPORTS}`;
    } else if (
      isConfigured &&
      (url.includes(WARNING_PAGES_URLS.NO_FACILITY) ||
        url.includes(GROUND_CONTROL_PAGES_URLS().INDEX) ||
        url.includes(DELIVERY_PAGES_URLS().INDEX))
    ) {
      // Exception 3 - If the user is in a facility which is not configured and switch
      // to a different one which is configured while being on the "no facility warning"
      // page we need to redirect him to the home of the new facility.
      url = await getHomeRouteBasedOnPermissions(selectedSystemId);
    }

    // Note 1: the switching of facilities is achieved by force loading the url for the
    // desired facility. A mechanism available in the hook useLoadFacilityLevelData will
    // realize that there is a miss match between the system id in the url and the one on local
    // storage, taking the necessary action to load the data for the newly selected facility,
    // same applies to maps
    dispatchFacilityLevel({ type: FacilityActionNames.CLEAR_FACILITY_STORE });
    dispatchGroundControlLevel({
      type: GroundControlActionNames.CLEAR_GROUND_CONTROL_STORE,
    });
    dispatchMapStore({ type: 'removeMap' });
    setIsLoading(false);
    closeModal();
    navigate(url);
  };

  return (
    <ModalBase
      opened={true}
      testId="c-select-facility-modal"
      contentClass={classes.root}
      maxWidth="lg"
      title={
        <Box>
          <Box textAlign="left" p={2} mb={1}>
            <Typography
              data-testid="c-choose-the-facility-title"
              style={{ fontWeight: 'bold' }}
              color="secondary"
              variant="h5"
            >
              Choose the facility
            </Typography>
            <Typography
              data-testid="c-click-one-of-the-item-title"
              color="secondary"
              variant="subtitle1"
            >
              Click one of the items below to select a facility
            </Typography>
          </Box>
        </Box>
      }
      handleClose={closeModal}
      actionButtons={
        <Button
          disabled={isLoading}
          data-testid="c-facility-modal-cancel-button"
          onClick={closeModal}
          variant="outlined"
          color="primary"
          fullWidth
        >
          Cancel
        </Button>
      }
    >
      <FacilitiesList
        onSelect={handleFacilitySelect}
        isListItemDisabled={isLoading}
        facilityCurrentlyLoaded={systemId}
      />
    </ModalBase>
  );
};

export default ModalSelectFacility;
