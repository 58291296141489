import * as THREE from 'three';
import { generateMaterial } from './generate-material';
import { generateSolidTexture } from '../texture-generators/generate-solid-texture';

type GenerateSolidMaterialProps = {
  opacity?: number;
  primaryColor: string;
  includeBorder?: boolean;
  borderColor?: string;
  lineWidth?: number;
} & THREE.MeshBasicMaterialParameters;

export const generateSolidMaterial = ({
  opacity = 0.8,
  primaryColor,
  includeBorder,
  borderColor,
  lineWidth,
  ...props
}: GenerateSolidMaterialProps) => {
  const texture = new THREE.Texture(
    generateSolidTexture({ primaryColor, includeBorder, lineWidth, borderColor }),
  );

  texture.minFilter = THREE.NearestFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.encoding = THREE.sRGBEncoding;
  texture.needsUpdate = true; // Texture will not render without this.

  return generateMaterial({
    map: texture,
    opacity,
    depthFunc: THREE.AlwaysDepth,
    ...props,
  });
};
