import moment from 'moment';
import { IGetTaskResponseST } from 'codegen/tasks';
import { CommissioningTask, CommissioningTaskState } from '../model/commissioningTask.model';

export const transformCommissioningTasks = (
  commissioningTaskDTOs: IGetTaskResponseST[],
): CommissioningTask[] =>
  commissioningTaskDTOs.map((task) => ({
    id: task.task_id,
    state: task.state as CommissioningTaskState,
    name: task.name,
    startedAt: task.started_at ? moment(task.started_at) : undefined,
    totalLocations: task.nr_total_locations,
    finishedLocations: task.nr_finished_locations,
    finishedAt: task.finished_at ? moment(task.finished_at) : undefined,
    createdAt: task.created_at ? moment(task.created_at) : undefined,
    updatedAt: task.updated_at ? moment(task.updated_at) : undefined,
  }));
