import { CommissioningTask } from '../../model/commissioningTask.model';

export const filterCommissioningTasksByState: (
  commissioningTasks: CommissioningTask[],
  commissioningTaskState?: string,
) => CommissioningTask[] = (commissioningTasks, commissioningTaskState) =>
  commissioningTasks.filter(
    (commissioningTask: CommissioningTask) =>
      commissioningTask.state === commissioningTaskState || commissioningTaskState === undefined,
  );
