export enum ISSUE_TYPES {
  WMS_BARCODE_VERITY_EMPTY = 'ISSUE1_W_BC_V_EMPTY',
  WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE = 'ISSUE2_W_BC_NE_V_BC',
  WMS_EMPTY_VERITY_BARCODE = 'ISSUE3_W_EMPTY_V_BC',
  WMS_EMPTY_VERITY_NOTEMPTY = 'ISSUE4_W_EMPTY_V_NOTEMPTY',
  WMS_BARCODE_DUPLICATE = 'ISSUE5_W_BC_DUPLICATE',
  INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY = 'INCONCLUSIVE1_W_BC_V_NOTEMPTY',
  INCONCLUSIVE_WMS_BARCODE_VERITY_NORESULT = 'INCONCLUSIVE2_W_BC_V_NORESULT',
  INCONCLUSIVE_WMS_EMPTY_VERITY_NORESULT = 'INCONCLUSIVE3_W_EMPTY_V_NORESULT',
  INCONCLUSIVE_WMS_INVALID = 'INCONCLUSIVE4_W_INVALID',
  C0014_DOUBLE_DEPTH = 'C0014_DOUBLE_DEPTH',
  C001C_MISSING_BC = 'C001C_MISSING_BC',
}

export enum LOCATION_ISSUE_TYPES {
  HAS_ISSUE = 'HAS_ISSUE',
  HAS_NO_ISSUE = 'HAS_NO_ISSUE',
  SYSTEM_CANT_INFER_IF_HAS_ISSUE = 'SYSTEM_CANT_INFER_IF_HAS_ISSUE',
}

export enum ISSUE_STATES {
  NEW = 'NEW',
  KNOWN = 'KNOWN',
  SNOOZED = 'SNOOZED',
  SOLVED = 'SOLVED',
  NA = 'NA',
  TOTAL = 'TOTAL',
}
export enum DISPLAY_ISSUE_TYPES_KEYS {
  ISSUE1_W_BC_V_EMPTY = 'ISSUE1_W_BC_V_EMPTY',
  ISSUE2_W_BC_NE_V_BC = 'ISSUE2_W_BC_NE_V_BC',
  ISSUE3_W_EMPTY_V_BC = 'ISSUE3_W_EMPTY_V_BC',
  ISSUE4_W_EMPTY_V_NOTEMPTY = 'ISSUE4_W_EMPTY_V_NOTEMPTY',
  ISSUE5_W_BC_DUPLICATE = 'ISSUE5_W_BC_DUPLICATE',
  INCONCLUSIVE1_W_BC_V_NOTEMPTY = 'INCONCLUSIVE1_W_BC_V_NOTEMPTY',
  INCONCLUSIVE2_W_BC_V_NORESULT = 'INCONCLUSIVE2_W_BC_V_NORESULT',
  INCONCLUSIVE3_W_EMPTY_V_NORESULT = 'INCONCLUSIVE3_W_EMPTY_V_NORESULT',
  INCONCLUSIVE4_W_INVALID = 'INCONCLUSIVE4_W_INVALID',
  INCONCLUSIVE5_V_INVALID = 'INCONCLUSIVE5_V_INVALID',
  CORRECT1_W_EMPTY_V_EMPTY = 'CORRECT1_W_EMPTY_V_EMPTY',
  CORRECT2_W_BC_EQ_V_BC = 'CORRECT2_W_BC_EQ_V_BC',
  NONE_V_DATA_OUTDATED = 'NONE_V_DATA_OUTDATED',
  NONE_SLOT_BLOCKED = 'NONE_SLOT_BLOCKED',
  MISSING_DATA = 'MISSING_DATA',
  C0014_DOUBLE_DEPTH = 'C0014_DOUBLE_DEPTH',
  C001C_MISSING_BC = 'C001C_MISSING_BC',
}

export const DISPLAY_ISSUE_TYPES = {
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE1_W_BC_V_EMPTY]: 'Empty location',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE2_W_BC_NE_V_BC]: 'Barcode mismatch',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE3_W_EMPTY_V_BC]: 'Non-empty (barcode)',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE4_W_EMPTY_V_NOTEMPTY]: 'Non-empty (no barcode)',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE5_W_BC_DUPLICATE]: 'Duplicate barcodes',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE1_W_BC_V_NOTEMPTY]: 'No barcode',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE2_W_BC_V_NORESULT]: 'No information',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE3_W_EMPTY_V_NORESULT]: 'No information',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE4_W_INVALID]: 'Invalid WMS data',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE5_V_INVALID]: 'Invalid Verity data',
  [DISPLAY_ISSUE_TYPES_KEYS.CORRECT1_W_EMPTY_V_EMPTY]: 'None',
  [DISPLAY_ISSUE_TYPES_KEYS.CORRECT2_W_BC_EQ_V_BC]: 'None',
  [DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED]: 'None',
  [DISPLAY_ISSUE_TYPES_KEYS.NONE_SLOT_BLOCKED]: 'None',
  [DISPLAY_ISSUE_TYPES_KEYS.MISSING_DATA]: 'None',
  [DISPLAY_ISSUE_TYPES_KEYS.C0014_DOUBLE_DEPTH]: 'Double depth location',
  [DISPLAY_ISSUE_TYPES_KEYS.C001C_MISSING_BC]: 'Missing Barcode',
};

export const DISPLAY_ISSUE_TYPES_MESSAGE = {
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE1_W_BC_V_EMPTY]:
    'The system found an empty location instead of a barcode',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE2_W_BC_NE_V_BC]:
    'The system found a barcode different from the one expected',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE3_W_EMPTY_V_BC]:
    'The system found a barcode instead of an empty location',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE4_W_EMPTY_V_NOTEMPTY]:
    'The system found a non-empty location with no barcode instead of an empty location',
  [DISPLAY_ISSUE_TYPES_KEYS.ISSUE5_W_BC_DUPLICATE]: 'The system found duplicate WMS barcodes',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE1_W_BC_V_NOTEMPTY]:
    'The system found a non-empty location with no barcode instead of a barcode',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE2_W_BC_V_NORESULT]:
    'The system returned inconclusive data for the location instead of a barcode',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE3_W_EMPTY_V_NORESULT]:
    'The system returned inconclusive data for the location instead of an empty location',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE4_W_INVALID]: 'The system found invalid WMS data',
  [DISPLAY_ISSUE_TYPES_KEYS.INCONCLUSIVE5_V_INVALID]: 'The system found invalid Verity data',
  [DISPLAY_ISSUE_TYPES_KEYS.CORRECT1_W_EMPTY_V_EMPTY]: 'The system found correct data',
  [DISPLAY_ISSUE_TYPES_KEYS.CORRECT2_W_BC_EQ_V_BC]: 'The system found correct data',
  [DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED]: 'None',
  [DISPLAY_ISSUE_TYPES_KEYS.NONE_SLOT_BLOCKED]: 'None',
  [DISPLAY_ISSUE_TYPES_KEYS.MISSING_DATA]: 'Missing Data',
  [DISPLAY_ISSUE_TYPES_KEYS.C0014_DOUBLE_DEPTH]:
    'The system found the barcode for the location in the back',
  [DISPLAY_ISSUE_TYPES_KEYS.C001C_MISSING_BC]: 'The system found Missing barcode but no unexpected',
};
