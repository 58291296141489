import {
  IDroneStatusST,
  IDroneStatusSTBatteryStateEnum,
  IDroneStatusSTDetailedBatteryStateEnum,
  IDroneStatusSTDroneOnlineEnum,
  IDroneStatusSTDroneStateEnum,
} from 'codegen/flight_domain';

export const makeBatteryLevelString = (batteryLevel: number) =>
  `${Math.round(batteryLevel * 100)} %`;

export const makeBatteryStateString = (batteryState: IDroneStatusSTBatteryStateEnum) => {
  switch (batteryState) {
    case IDroneStatusSTBatteryStateEnum.Charging:
      return 'Charging...';
    case IDroneStatusSTBatteryStateEnum.FullyCharged:
      return 'Fully Charged';
    case IDroneStatusSTBatteryStateEnum.NotPluggedIn:
      return 'Not Charging';
    case IDroneStatusSTBatteryStateEnum.Recovering:
      return 'Recovering...';
    case IDroneStatusSTBatteryStateEnum.Error:
      return 'Error';
    case IDroneStatusSTBatteryStateEnum.ShipMode:
      return 'Ship Mode';
    case IDroneStatusSTBatteryStateEnum.NotCharging:
      return 'Not Charging';
    case IDroneStatusSTBatteryStateEnum.Standby:
      return 'Standby';
    default:
      return 'Unknown';
  }
};

export const makeBatteryDetailedStateString = (
  detailedState?: IDroneStatusSTDetailedBatteryStateEnum,
) => {
  switch (detailedState) {
    case IDroneStatusSTDetailedBatteryStateEnum.BatteryCellsAreBeingBalanced:
      return 'Battery cells are being balanced';
    case IDroneStatusSTDetailedBatteryStateEnum.BatteryIsFullyCharged:
      return 'Battery is fully charged';
    case IDroneStatusSTDetailedBatteryStateEnum.BatteryIsInStandby:
      return 'Battery is in standby';
    case IDroneStatusSTDetailedBatteryStateEnum.BatteryIsTooColdToCharge:
      return 'Battery is too cold to charge';
    case IDroneStatusSTDetailedBatteryStateEnum.BatteryIsTooHotToCharge:
      return 'Battery is too hot to charge';
    case IDroneStatusSTDetailedBatteryStateEnum.CannotChargeColdBattery:
      return 'Cannot charge, cold battery';
    case IDroneStatusSTDetailedBatteryStateEnum.CannotChargeDisconnectedFromCharger:
      return 'Cannot charge, disconnected from charger';
    case IDroneStatusSTDetailedBatteryStateEnum.CannotChargeHotBattery:
      return 'Cannot charge, hot battery';
    case IDroneStatusSTDetailedBatteryStateEnum.CannotEscapeStateTriage:
      return 'Cannot escape state triage';
    case IDroneStatusSTDetailedBatteryStateEnum.CellVoltagesAreTooHigh:
      return 'Cell voltages are too high';
    case IDroneStatusSTDetailedBatteryStateEnum.ChargerIsNotConnected:
      return 'Not connected to charger';
    case IDroneStatusSTDetailedBatteryStateEnum.ChargingCircuitIsTooHot:
      return 'Charging circuit is too hot';
    case IDroneStatusSTDetailedBatteryStateEnum.ChargingCircuitTooHotForTooLong:
      return 'Charging circuit is too hot for too long';
    case IDroneStatusSTDetailedBatteryStateEnum.ChargingCircuitTooHotTooOften:
      return 'Charging circuit is too hot too often';
    case IDroneStatusSTDetailedBatteryStateEnum.ChargingTheBattery:
      return 'Charging the battery';
    case IDroneStatusSTDetailedBatteryStateEnum.ChargingWillCommenceShortly:
      return 'Charging will commence shortly';
    case IDroneStatusSTDetailedBatteryStateEnum.DecidingOnNextSteps:
      return 'Deciding on the next step';
    case IDroneStatusSTDetailedBatteryStateEnum.DepletedBattery:
      return 'Battery is depleted';
    case IDroneStatusSTDetailedBatteryStateEnum.DroneIsFlying:
      return 'Drone is flying';
    case IDroneStatusSTDetailedBatteryStateEnum.FatalFailureLatched:
      return 'Fatal failure latched';
    case IDroneStatusSTDetailedBatteryStateEnum.InvalidBmsData:
      return 'Invalid BMS data';
    case IDroneStatusSTDetailedBatteryStateEnum.InvalidBmsDataForTooLong:
      return 'Invalid BMS data for too long';
    case IDroneStatusSTDetailedBatteryStateEnum.IrStrobeNotOffForTooLong:
      return 'IR Strobe not off for too long';
    case IDroneStatusSTDetailedBatteryStateEnum.MaxFailedChargingStartAttemptsReached:
      return 'Max failed charging start attempts reached';
    case IDroneStatusSTDetailedBatteryStateEnum.Null:
      return 'Null';
    case IDroneStatusSTDetailedBatteryStateEnum.UnknownFunctionalRevision:
      return 'Unknown functional revision';
    case IDroneStatusSTDetailedBatteryStateEnum.UnknownState:
      return 'Unknown State';
    case IDroneStatusSTDetailedBatteryStateEnum.WaitingForIrStrobeToTurnOff:
      return 'Waiting for IR Strobe to turn off';
    default:
      return 'Null';
  }
};

export const makeWiFiStatusString = (droneOnline: IDroneStatusSTDroneOnlineEnum) => {
  switch (droneOnline) {
    case IDroneStatusSTDroneOnlineEnum.Online:
      return 'Online';
    case IDroneStatusSTDroneOnlineEnum.Offline:
      return 'Offline';
    case IDroneStatusSTDroneOnlineEnum.Stale:
      return 'Stale';
    default:
      return 'Unknown';
  }
};

export const makeDroneStateString = (droneState: IDroneStatusSTDroneStateEnum) => {
  switch (droneState) {
    case IDroneStatusSTDroneStateEnum.Flying:
      return 'Flying';
    case IDroneStatusSTDroneStateEnum.OnCharger:
      return 'On Charger';
    case IDroneStatusSTDroneStateEnum.LandedInError:
      return 'Landed after Error';
    default:
      return 'Unknown';
  }
};

export const makeTableRows = (fleet: IDroneStatusST[]) =>
  Object.values(fleet).map((drone) => ({
    droneId: drone.drone_name,
    id: drone.drone_name,
    chargerId: drone.charger_id,
    chargerPosition: drone.charger_pos_description,
    batteryLevel: makeBatteryLevelString(drone.battery_level),
    batteryState: makeBatteryStateString(drone.battery_state),
    wifiStatus: makeWiFiStatusString(drone.drone_online),
    droneState: makeDroneStateString(drone.drone_state),
  }));
