/**
 * Each service returns its version in a different flavour,
 * e.g.: udb - rc - 2.4.0 - rc2, 2.4.0 - rc3, oc - rc - 2.4.0 - 3
 * in order to make the version comparable this function extract
 * the substring made of NUMBER DOT NUMBER DOT NUMBER
 */
export const extractVersionNumber = (version: string) => {
  const regex = /(\d+)\.(\d+)\.(\d+)/;
  const versionNumber = regex.exec(version);
  return versionNumber !== null ? versionNumber[0] : null;
};
