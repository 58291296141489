import { Box, Checkbox } from '@mui/material';

type UnlockModalCheckboxProps = {
  value: boolean;
  setValue: (value: boolean) => void;
  message: string | JSX.Element;
  testId?: string;
};

export const UnlockModalCheckbox = ({
  value,
  setValue,
  message,
  testId,
}: UnlockModalCheckboxProps) => (
  <Box
    data-testid={testId}
    component="div"
    marginTop={4}
    lineHeight="1.7em"
    paddingTop="1em"
    paddingBottom="1em"
    paddingLeft="1.5em"
    paddingRight="1em"
    sx={{
      backgroundColor: 'rgb(229, 246, 253)',
    }}
    display="flex"
    flexDirection="row"
    gap={2}
  >
    <Checkbox checked={value} onChange={() => setValue(!value)} />
    <Box component="div">{message}</Box>
  </Box>
);
