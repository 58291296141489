import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';
import { COLOR_PALETTE } from 'common/colors';

export const noFlyZoneItemStyle = makeStyles()((theme: Theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.light,
    },
  },
  paperActive: {
    backgroundColor: `${alpha(COLOR_PALETTE.BLUE, 0.2)} !important`,
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    minWidth: 0,
    border: 0,
    background: 'none',
    textAlign: 'inherit',
    cursor: 'pointer',
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(1),
    alignSelf: 'stretch',

    ':focus': {
      outline: 0,
    },
  },
}));
