import { useMemo } from 'react';
import { meshRenderOrder } from 'shared/map/defaults/mesh-render-order';

export const useRenderOrder = ({
  isSelected,
  isCreating,
}: {
  isSelected: boolean;
  isCreating: boolean;
}) => {
  const renderOrder = useMemo(() => {
    if (isCreating) {
      return meshRenderOrder.creation;
    }

    if (isSelected) {
      return meshRenderOrder.highlight;
    }

    return meshRenderOrder.no_fly_zones;
  }, [isSelected, isCreating]);

  return { renderOrder };
};
