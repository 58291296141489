import TableCell from '@mui/material/TableCell';
import Highlighter from 'react-highlight-words';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import { Box } from 'components/common/Box';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
type EnhancedTableCellStringProps = EnhancedTableCellProp<any> & {
  showSearch: boolean;
  searchTerm: string;
  classes: {
    noWordBreakNoBullets: string;
    truncate: string;
  };
};

export const EnhancedTableCellString = (props: EnhancedTableCellStringProps) => {
  const { cell, row, classes, showSearch, searchTerm, onClick } = props;

  // Note: the following checks and guards have been introduced because of a number of
  // Cypress pipeline failures (see eg. https://verity-ag.atlassian.net/browse/UD-2919)
  // TODO/FIXME: verify whether the warning are logged and what is causing this component
  // to be mounted with undefined props
  // EN 2022-07-20
  if (cell === undefined) {
    console.warn(
      'EnhancedTableCellString',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  if (row === undefined) {
    console.warn(
      'EnhancedTableCellString',
      'row is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  let textToHighlight = row[cell.id] ? row[cell.id].toString() : '';

  try {
    const { format } = cell;
    if (format && isFunction(format)) {
      textToHighlight = format(textToHighlight);
    }
  } catch (error) {
    console.warn(error);
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} onClick={onClick}>
      {/* SG-2020-11-30-EXPLANATION: Apply text color to certain cells - red if location has issues, green if it doesn't */}
      <Box
        component="p"
        className={cell.truncate && classes.truncate}
        style={cell.style && row && cell.style(row)}
      >
        {/* SG-2020-11-24-EXPLANATION: Wrap cell in Highlighter component (used to highlight searched terms)
      only if cell data type is string */}
        {!isNil(row[cell.id]) && (
          <Highlighter
            highlightClassName="YourHighlightClass"
            // If showSearch is disabled then we do not highlight search terms
            searchWords={showSearch ? [searchTerm] : []}
            autoEscape={true}
            textToHighlight={textToHighlight}
          />
        )}
      </Box>
    </TableCell>
  );
};
