/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { Vector3 } from 'three';

import { mapEntries } from 'common/functions/otherFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { Floor } from 'shared/map-container/features/mesh-floor/MeshFloor';
import { OrthoCamera } from 'shared/map-container/features/map-orthographic-camera/OrthoCamera';

import { MutableState, initialCameraState } from 'shared/map-container/reducer/MutableState';
import { ORTHO_CAMERA_Z } from 'shared/map-container/defaults/orthographicCameraZ.default';
import { MapCanvas } from 'shared/map-container/features/map-canvas/MapCanvas';
import { Zoomer } from 'shared/map-container/utils/Zoomer';
import { IObstacleInformation1ST } from 'codegen/flight_domain';
import { EstimatedObstacle } from './EstimatedObstacle';

const lp = getLogPrefixForType('COMPONENT', 'EstimatedObstaclesMap');

export const wrapperId = 'estimatedObstaclesMapWrapper';

/**
 * Map of estimated obstacles
 * @returns JSX.Element
 */
export const EstimatedObstaclesMap = ({
  estimatedObstacles,
}: {
  estimatedObstacles: {
    [key: string]: IObstacleInformation1ST;
  };
}) => {
  const { mapState, dispatchMapStore: mapDispatch } = useMapStore();
  const { options } = mapState;

  // NOTE: UD-4618: Estimated obstacles can be very small and, hence, in order to
  // access them a smaller minFOV is needed
  // eno: 2023-07-09
  options.camera.orthographic.minFOV = 1;

  const worldBox = mapState?.map?.box || [0, 0, 0, 0, 0, 0];
  // eslint-disable-next-line
  const [minX, minY, _minZ, maxX, maxY, _maxZ] = worldBox;

  const mutableState = useMemo(() => {
    MutableState.destroyInstance();
    return MutableState.constructState(
      'Estimated Obstacles Map',
      {
        ...initialCameraState,
        position: new Vector3((maxX + minX) / 2, (maxY + minY) / 2, ORTHO_CAMERA_Z),
        orthographicFOV: options.camera.orthographic.maxFOV,
        currentOrthographicFOV: options.camera.orthographic.maxFOV,
      },
      { worldBox, guidePosition: new Vector3(0, 0, 0), isFloorHeld: false },
      {},
    );
  }, [mapState.map]);

  const zoomer = useMemo(
    () => new Zoomer(options.camera, mapState.canvasSize),
    [options.camera, mapState.map, mapState.canvasSize],
  );

  const camera = useMemo(
    () => (
      <OrthoCamera
        worldBounds={[minX, maxX, minY, maxY]}
        options={options.camera}
        fovAxisRatio={mapState.canvasProportion}
        zoomer={zoomer}
      />
    ),
    [options.camera, mapState.canvasProportion, mapState.map],
  );

  /**
   * Handles mouse down on the floor.
   */
  const onFloorDown = (): void => {
    const state = MutableState.getState();
    state.interaction.isFloorHeld = true;
  };

  /**
   * Handles mouse up on the floor.
   */
  const onFloorUp = (): void => {
    const state = MutableState.getState();
    state.interaction.isFloorHeld = false;
  };

  const estimatedObstaclesElements = useMemo(
    () =>
      mapEntries(estimatedObstacles, (key, obstacle) => (
        <EstimatedObstacle key={key as string} obstacleState={obstacle} options={options} />
      )),
    [estimatedObstacles],
  );

  console.debug(
    lp,
    `rendering Estimated Obstacles map with ${estimatedObstacles.length} estimated obstacles`,
  );

  return (
    <MapCanvas
      mapState={mapState}
      mapDispatch={mapDispatch}
      mutableState={mutableState}
      canvasSize={{ width: 0, height: 630 }}
      floor={
        <Floor
          mapState={mapState}
          zoomer={zoomer}
          onFloorDown={onFloorDown}
          onFloorUp={onFloorUp}
        />
      }
      mapElements={estimatedObstaclesElements}
      camera={camera}
      wrapperId={wrapperId}
    />
  );
};
