import { Tooltip } from '@mui/material';
import { ILocationDataST } from 'codegen/warehouse_status';

type ContentWasFoundAtCellProps = {
  row: ILocationDataST;
};

/**
 * Component rendering the content of a Content Was Found At Grid Cell
 * @param props
 * @returns JSX rendering of the component
 */
export const ContentWasFoundAtCell = (props: ContentWasFoundAtCellProps) => {
  const hasIssues = !!props.row.issues?.length;

  let content: JSX.Element = <span className="c-data-grid-cell c-data-grid-cell-content-at" />;

  // If there is no issue then stop here
  if (!hasIssues) return content;

  const issue = props.row.issues[0];
  // If the issue exists but is empty OR it's a string (aka it doesn't have information)
  // then stop here
  if (typeof issue.verity_slot_status_with_wms_bc === 'string') return content;
  if (!issue.verity_slot_status_with_wms_bc) return content;
  if (issue.state === 'SOLVED') return content;

  // Here is where the real trick happens
  const slotLabel = issue.verity_slot_status_with_wms_bc.slot_label;

  if (slotLabel !== issue.location) {
    content = (
      <Tooltip
        title="The expected content was found at"
        className="c-data-grid-cell c-data-grid-cell-content-at"
      >
        <span>{slotLabel}</span>
      </Tooltip>
    );
  }

  return content;
};
