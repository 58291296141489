/* tslint:disable */
/* eslint-disable */
/**
 * Map 3D
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IFacilityMap3dGetResponseST
 */
export interface IFacilityMap3dGetResponseST {
  /**
   * The presigned URL to download the requested map3d file
   * @type {string}
   * @memberof IFacilityMap3dGetResponseST
   */
  url: string;
  /**
   * The requested map name
   * @type {string}
   * @memberof IFacilityMap3dGetResponseST
   */
  name?: string;
  /**
   * The requested map version
   * @type {string}
   * @memberof IFacilityMap3dGetResponseST
   */
  version?: string;
}
/**
 *
 * @export
 * @interface IFlightDomainMap3dGetResponseST
 */
export interface IFlightDomainMap3dGetResponseST {
  /**
   * The presigned URL to download the requested map3d file
   * @type {string}
   * @memberof IFlightDomainMap3dGetResponseST
   */
  url: string;
  /**
   * The requested map name
   * @type {string}
   * @memberof IFlightDomainMap3dGetResponseST
   */
  name?: string;
  /**
   * The requested map version
   * @type {string}
   * @memberof IFlightDomainMap3dGetResponseST
   */
  version?: string;
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFlightDomainMap3dGetResponseST
   */
  flight_domain_id?: string;
}

/**
 * FacilityApi - axios parameter creator
 * @export
 */
export const FacilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Return compressed map for 3D visualization.
     * @summary Return compressed map for 3D visualization.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityMap3d: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilityMap3d', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/map3d`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return compressed map by flight domain for 3D visualization.
     * @summary Return compressed map by flight domain for 3D visualization.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainMap3d: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFlightDomainMap3d', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFlightDomainMap3d', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/map3d`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FacilityApi - functional programming interface
 * @export
 */
export const FacilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FacilityApiAxiosParamCreator(configuration);
  return {
    /**
     * Return compressed map for 3D visualization.
     * @summary Return compressed map for 3D visualization.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilityMap3d(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityMap3dGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityMap3d(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return compressed map by flight domain for 3D visualization.
     * @summary Return compressed map by flight domain for 3D visualization.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFlightDomainMap3d(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFlightDomainMap3dGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFlightDomainMap3d(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FacilityApi - factory interface
 * @export
 */
export const FacilityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FacilityApiFp(configuration);
  return {
    /**
     * Return compressed map for 3D visualization.
     * @summary Return compressed map for 3D visualization.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityMap3d(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilityMap3dGetResponseST> {
      return localVarFp
        .getFacilityMap3d(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return compressed map by flight domain for 3D visualization.
     * @summary Return compressed map by flight domain for 3D visualization.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainMap3d(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFlightDomainMap3dGetResponseST> {
      return localVarFp
        .getFlightDomainMap3d(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FacilityApi - object-oriented interface
 * @export
 * @class FacilityApi
 * @extends {BaseAPI}
 */
export class FacilityApi extends BaseAPI {
  /**
   * Return compressed map for 3D visualization.
   * @summary Return compressed map for 3D visualization.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacilityMap3d(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacilityMap3d(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return compressed map by flight domain for 3D visualization.
   * @summary Return compressed map by flight domain for 3D visualization.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFlightDomainMap3d(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FacilityApiFp(this.configuration)
      .getFlightDomainMap3d(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
