import { Box, Typography } from '@mui/material';
import { IRulesConditionsInnerST } from 'codegen/facility_settings';

export const ConditionsPhrase = ({
  conditions,
  isNotFirstRule,
}: {
  conditions: IRulesConditionsInnerST[];
  isNotFirstRule: boolean;
}) => {
  const condition = conditions[0];

  const isMultiValueField =
    condition.field === 'CUSTOMERS' || condition.field === 'EXPECTED_CONTENT';

  const IsCatchAll =
    condition.field === 'LOCATION_NAME' &&
    condition.operator?.type === 'MATCH' &&
    condition.operator.value === '.*';

  const multiValueQuantifier = isMultiValueField ? (
    <Typography variant="h6" paddingRight=".5em" fontStyle="italic" color="darkslategrey">
      {condition.quantifier} OF THE
    </Typography>
  ) : undefined;

  const negationStatement =
    condition.quantifier === 'NONE' && !isMultiValueField ? (
      <Typography variant="h6" paddingRight=".5em" fontStyle="italic" color="darkslategrey">
        NOT
      </Typography>
    ) : undefined;

  const operator = (
    <Typography variant="h6" paddingRight=".5em" fontStyle="italic" color="darkslategrey">
      {condition.operator?.type}
    </Typography>
  );

  const value = (
    <Typography variant="h5" color="darkslategrey" fontWeight="bold">
      {condition.operator?.value ? ` "${condition.operator?.value}"` : ''}
    </Typography>
  );

  if (IsCatchAll) {
    return (
      <>
        {isNotFirstRule && (
          <Box component="div" paddingTop="2em">
            <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
              FOR ALL OTHER LOCATIONS
            </Typography>
          </Box>
        )}
        {!isNotFirstRule && (
          <Box component="div" paddingTop="2em">
            <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
              FOR ALL LOCATIONS
            </Typography>
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      {isNotFirstRule && (
        <Box component="div" paddingTop="2em" paddingBottom="1em">
          <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
            OTHERWISE
          </Typography>
        </Box>
      )}
      <Box component="div" display="flex" flexDirection="row">
        <Typography fontWeight="bold" color="blue" variant="h5" paddingRight=".5em">
          IF
        </Typography>
        {multiValueQuantifier}
        <Typography fontWeight="bold" variant="h5" color="black" paddingRight=".5em">
          {condition.field}
        </Typography>{' '}
        {negationStatement}
        {operator}
        {value}
      </Box>
    </>
  );
};
