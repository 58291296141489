import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/material';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import EnhancedTable from 'components/EnhancedTable';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { LocalStore } from 'common/functions/storageFunctions';
import { SYSTEM_DETAILS_COLUMNS } from './SystemDetailsTableDefinition';
import { useRequestController } from '../../../hooks';
import { getLatestBeVersion } from './SystemDetailsFunctions';
import { ServiceVersionItem } from './SystemDetailsTypes';

const logPrefix = getLogPrefixForType('COMPONENT', 'SystemDetails', 'Home');

export const SystemDetails = () => {
  const { systemId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [serviceVersion, setServiceVersion] = useState<ServiceVersionItem[]>([]);

  const { requestController } = useRequestController('System Details');

  const timezone = LocalStore.getTimezone();

  useEffect(() => {
    console.debug(logPrefix, 'fetching services version data');
    getLatestBeVersion(requestController, systemId!, setServiceVersion, setIsLoading, timezone);
  }, [requestController, systemId, timezone]);

  return (
    <>
      <PageHeaderSection title="Administration" showMenuIcon={false} showLoadedSince={false} />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <EnhancedTable
          tableTitle="Services Version"
          tableSubtitle="Services Version Overview"
          tableFor="Services Version"
          headCells={SYSTEM_DETAILS_COLUMNS}
          rows={serviceVersion}
          showHeader={true}
          currentActiveSpinner={{
            'Loading Version': false,
          }}
          isLoading={isLoading}
        />
      </Container>
    </>
  );
};
