import { findLast } from 'lodash';
import { Tooltip } from '@mui/material';
import { ILocationDataST } from 'codegen/warehouse_status';

type ContentShouldBeAtCellCellProps = {
  row: ILocationDataST;
};

/**
 * Component rendering the content of a Content Should Be At Grid Cell
 * @param props
 * @returns JSX rendering of the component
 */
export const ContentShouldBeAtCell = (props: ContentShouldBeAtCellCellProps) => {
  const hasIssues = !!props.row.issues?.length;

  let content: JSX.Element = (
    <span className="c-data-grid-cell c-data-grid-cell-content-at">-</span>
  );

  // If there is no issue then stop here
  if (!hasIssues) return content;

  const issue = props.row.issues[0];
  // If the issue is exists but is empty OR it's a string (aka it doesn't have information)
  // then stop here
  if (typeof issue.wms_slot_status_with_verity_bc === 'string') return content;
  if (!issue.wms_slot_status_with_verity_bc) return content;

  const slotLabel = issue.wms_slot_status_with_verity_bc.slot_label;

  const clientOverride = issue.slot_status.verity_status?.user_overrides
    ? findLast(issue.slot_status.verity_status?.user_overrides, (uo) => !uo.review)
    : null;

  if (!clientOverride && slotLabel) {
    content = (
      <Tooltip
        title="Found content should be at"
        className="c-data-grid-cell c-data-grid-cell-content-at"
      >
        <span>{slotLabel}</span>
      </Tooltip>
    );
  }

  return content;
};
