import type { SwitchProps } from '@mui/material';
import { FormControlLabel, FormHelperText, Switch } from '@mui/material';
import { useField } from 'formik';

export const FormSwitch = ({
  name,
  label,
  ...rest
}: SwitchProps & { name: string; label: string }) => {
  const [field, meta] = useField({ name, type: 'checkbox' });
  return (
    <>
      <FormControlLabel control={<Switch {...field} {...rest} />} label={label} />
      {!!(meta.touched && meta.error) && <FormHelperText>{meta.error}</FormHelperText>}
    </>
  );
};
