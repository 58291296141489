export const OVERWRITE_MODES = {
  MANUAL: 'manually',
  USE_VERITY: 'original',
  USE_WMS: 'expected',
};

export interface IOverwritingItem {
  verity_correct: boolean | string;
  set_to_expected: boolean;
  barcodes: string[];
  state: string;
  comment: string;
}
