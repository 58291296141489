import React, { ChangeEvent, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';

import { SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';

import { calculateElapsedTimeMoment } from 'common/functions/dateTimeFunctions';
import {
  generateRruleFromUIComponents,
  getRecurrenceData,
  validateOccurrenceInterval,
} from 'common/functionsReportSpec';
import { pluralizeInterval, RELATIVE_DATETIME_FORMAT, WeekDayShort } from 'common/datetimeFormats';

import Spinner from 'components/common/Spinner';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import { Box } from 'components/common/Box';
import WeekdaysForm from '../WeekdaysForm';
import { getValidIntervalOptions } from '../getValidIntervalOptions';
import { userHasPermission } from '../../../../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../../../../features/permissions/permissions.model';
import { useStyles } from './recurrence.style';
import { IntervalLabel, RecurrenceProps } from './recurrence.model';

export const Recurrence = ({
  systemId,
  scheduleFormState,
  dispatch,
  timezone,
  reportSpecTriggering,
  setDays,
}: RecurrenceProps) => {
  const { classes } = useStyles();

  const {
    days,
    recurrenceState: state,
    isRecurring,
    dateFrom,
    dateUntil,
    occurrenceInterval,
    interval,
    allowedOccurrenceIntervals,
  } = scheduleFormState;

  moment.tz.setDefault(timezone);

  const { enqueueSnackbar } = useSnackbar();

  const isValidOccurrencesValues =
    occurrenceInterval > 0 &&
    moment(dateFrom).isValid() &&
    moment(dateUntil).isValid() &&
    moment(dateFrom).isBefore(dateUntil) &&
    interval;

  const { rString } = generateRruleFromUIComponents({
    days,
    setDays,
    isRecurring,
    dateFrom,
    dateUntil,
    occurrenceInterval,
    timezone,
    reportSpecTriggering,
    interval,
  });

  const nextReportMessage = () => {
    let message = moment(state.nextOccurrence).calendar(null, RELATIVE_DATETIME_FORMAT);
    if (!message.includes('today') && !message.includes('tomorrow')) {
      message = `on ${message}`;
    }
    return message;
  };

  const { hours, minutes } = calculateElapsedTimeMoment(dateFrom, dateUntil);

  const intervalOptions = getValidIntervalOptions(
    Number(hours * 60) + Number(minutes),
    userHasPermission(PERMISSION.SCHEDULE_HOURLY_REPORTS),
  );

  const selectedIntervalIndex = interval
    ? intervalOptions.findIndex((option) => option.label === interval)
    : intervalOptions.findIndex((option) => option.enabled);

  const selectedInterval: IntervalLabel = intervalOptions[selectedIntervalIndex]?.enabled
    ? intervalOptions[selectedIntervalIndex].label
    : ('' as IntervalLabel);

  const intervalSelectOptions = intervalOptions.map((inter) => ({
    key: inter.label,
    disabled: !inter.enabled,
    value: inter.label,
    label: inter.enabled
      ? capitalize(inter.label)
      : `${capitalize(inter.label)} - the dates selected do not allow this recurrence pattern`,
  }));

  const recurrenceMessage = isValidOccurrencesValues ? (
    <Box width="100%" display="flex" flexDirection="column">
      <Alert sx={{ mb: 1 }} icon={false} severity="info">
        <Typography>Occurs {state.rText}</Typography>
      </Alert>
      <Typography sx={{ pl: 2, mb: 2 }}>Next report will start {nextReportMessage()}</Typography>
      <Typography sx={{ pl: 2 }} data-testid="c-recurrence-count">
        {`Number of reports to be generated: ${state.occurrencesCount}${
          state.countCapped ? '+' : ''
        }`}
      </Typography>
    </Box>
  ) : (
    <Alert icon={false} severity="warning" sx={{ height: '100%' }}>
      <Box height="100%">
        <Typography>No report will be generated within the defined interval</Typography>
      </Box>
    </Alert>
  );

  const { valid: isValidInterval, errorMsg: intervalErrorMessage } = validateOccurrenceInterval(
    isRecurring,
    occurrenceInterval,
    allowedOccurrenceIntervals,
  );

  const handleSelectedIntervalChange = (e: SelectChangeEvent<string>) => {
    dispatch({ type: 'SET_INTERVAL', payload: e.target.value as IntervalLabel });
  };

  const handleDaysChange = (newDays: WeekDayShort[]) => {
    dispatch({ type: 'SET_DAYS', payload: newDays });
  };

  const handleOccurrenceIntervalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newOccurrenceInterval = Number(e.target.value);
    dispatch({ type: 'SET_OCCURRENCE_INTERVAL', payload: newOccurrenceInterval });
  };

  useEffect(() => {
    if (isValidOccurrencesValues) {
      getRecurrenceData(systemId!, rString, dispatch, enqueueSnackbar);
    }
  }, [systemId, rString, dispatch, isValidOccurrencesValues, enqueueSnackbar]);

  return (
    <>
      <Grid className={classes.gridItem} xs={12} md={12} item>
        <Box className={classes.formControl} width="100%" px={0}>
          <FormControl className={classes.formInput} variant="outlined">
            <CustomSelect
              testId="c-recurrence-intervals"
              id="demo-simple-select-outlined"
              label="Interval"
              value={selectedInterval}
              variant="outlined"
              onChange={handleSelectedIntervalChange}
              valueOptions={intervalSelectOptions}
              name=""
              error={false}
              errorMessage=""
              defaultValue=""
              disabled={false}
            />
          </FormControl>
          {includes(['weekly', 'monthly', 'yearly'], scheduleFormState.interval) && (
            // When the 'type' prop of TextField input is set to 'number' and it has an initial value,
            // once the entered number is deleted, the displayed value becomes 0.
            // If you then enter another number, the zero will stay unless the page is refreshed.
            // Which gives us slightly bad UI. That's why we convert value to string.
            // This is a general bug in react.
            // source: https://github.com/mui-org/material-ui/issues/8380

            <FormControl className={classes.formInput} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Every</InputLabel>
              <OutlinedInput
                data-testid="c-n-recurrence-field"
                fullWidth
                label="Every"
                value={occurrenceInterval.toString()}
                endAdornment={
                  <InputAdornment position="end">
                    {pluralizeInterval(occurrenceInterval, interval)}
                  </InputAdornment>
                }
                inputProps={{
                  min: 1,
                  max: allowedOccurrenceIntervals,
                  id: 'outlined-adornment-password',
                }}
                onChange={handleOccurrenceIntervalChange}
                type="number"
                error={!isValidInterval}
              />{' '}
              <FormHelperText error={!isValidInterval} id="filled-weight-helper-text">
                {intervalErrorMessage}
              </FormHelperText>
            </FormControl>
          )}
        </Box>

        {interval === 'weekly' && (
          <FormControl className={classes.formInput} variant="outlined">
            <WeekdaysForm days={days} setDays={handleDaysChange} />
          </FormControl>
        )}
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        height={isValidOccurrencesValues ? 150 : 50}
        sx={{ ml: 2 }}
      >
        {state.isLoading ? <Spinner /> : recurrenceMessage}
      </Box>
    </>
  );
};
