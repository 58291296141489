import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const baseCardStyle = makeStyles()((theme: Theme) => ({
  card: {
    height: '100%',
    marginBottom: theme.spacing(3),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'background-color 300ms',
  },
  variantPrimary: {
    backgroundColor: theme.palette.content.primary,
  },
  variantSecondary: {
    backgroundColor: theme.palette.content.secondary,
  },
  cardHeader: {
    position: 'relative',
  },
  cardHeaderDivider: {
    '::before': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      insetInlineStart: '24px',
      insetInlineEnd: '24px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  cardContentSection: {
    position: 'relative',
    flex: '1 1 auto',
    minHeight: 0,
  },
  cardContentSectionScrollable: {
    position: 'relative',
    flex: '1 1 auto',
    minHeight: 0,
    padding: theme.spacing(1.5),
    paddingTop: 0,
    paddingBottom: 0,
    ':last-child': {
      padding: theme.spacing(1.5),
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  emptyCard: {
    textAlign: 'center',
    margin: theme.spacing(5, 0),
    minHeight: theme.spacing(4),
  },
  cardContentList: {
    padding: 0,
  },
  uploadCardContentList: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
  subheader: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    marginRight: theme.spacing(4),
  },
  actionContainer: {
    marginRight: '0.5rem',
  },
  action: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
}));

export const reportItemStyle = makeStyles()((theme: Theme) => ({
  listItem: {
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    display: 'flex',
  },
  listItemText: {
    flex: 'auto',
  },
  primaryText: {
    lineHeight: '0.80',
  },
  secondaryText: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
    marginTop: theme.spacing(0.8),
    paddingRight: '210px',
    lineHeight: '1.3',
  },
  reportName: {},
  reportStatusSection: {
    marginBottom: theme.spacing(0.5),
    alignItems: 'baseline',
  },
  reportStatus: {
    margin: theme.spacing(0, 0.5),
  },
  reportStatusMarginRight: {
    marginRight: theme.spacing(1),
  },
  reportStatusDisabled: {
    color: theme.palette.disabled,
  },
  recurrence: {
    marginRight: 0,
  },
  progress: {
    margin: theme.spacing(0, 0.5),
  },
}));

export const simpleItemStyle = makeStyles()((theme: Theme) => ({
  listItem: {
    padding: 0,
    display: 'block',

    ':nth-of-type(n+2)': {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
    marginRight: theme.spacing(4),
  },
  fileLink: {
    wordBreak: 'break-all',
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
}));
