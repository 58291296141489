import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    listStyle: 'none',
  },
  listItemIconWrapper: {
    flex: '0 0 auto',
    transform: 'translateY(0.15em)',
  },
  listItemLabelWrapper: {
    flex: '1 1 auto',
    minWidth: 0,
  },
}));
