import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import SettingsCard from '../../SettingsCard';
import { getExternalReviewsOptions } from '../../options/getExternalReviewsOptions';
import { getReportReviewsOptions } from '../../options/getReportReviewsOptions';

export const ReviewSettings = ({
  isLoading,
  facilitySettings,
  handleValuesChange,
  errors,
}: {
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: any) => void;
  errors: any;
}) => (
  <Grid md={12} sm={12} xs={12} item>
    <SettingsCard
      title="Report reviews"
      isLoading={isLoading}
      testId="c-report-reviews-settings-card"
      items={getReportReviewsOptions(facilitySettings, handleValuesChange, errors)}
    />
    <SettingsCard
      title="External reviews"
      isLoading={isLoading}
      testId="c-external-reviews-settings-card"
      items={getExternalReviewsOptions(facilitySettings, handleValuesChange, errors)}
    />
  </Grid>
);
