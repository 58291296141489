import { memo, useRef, useState } from 'react';
import { BoxGeometry, EdgesGeometry } from 'three';
import { useGesture } from '@use-gesture/react';
import { useCursor } from '@react-three/drei';
import { MutableState } from 'shared/map-container/reducer/MutableState';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { generateSolidMaterial } from 'shared/map/utils/material-generators/generate-solid-material';
import { DroneZoneBaseMesh } from '../drone-zone-base-mesh/drone-zone-base-mesh';
import { ZoneBaseMeshSharedProps } from '../drone-zone-base-mesh/drone-zone-base-mesh.model';
import { useLabelColor } from '../drone-zone-base-mesh/hooks/use-label-color/use-label-color';
import { meshRenderOrder } from '../../defaults/mesh-render-order';
import { ControlledZone } from '../../model/controlled-zone.model';
import { bottomLeftDimensionsToSizeAndPos } from '../../../map-container/utils/creation.util';
import { validatePositionToFacilityBoundaries } from '../drone-zone-base-mesh/utils/validatePositionToFacilityBoundaries';
import { useMeshOpacity } from '../drone-zone-base-mesh/hooks/use-mesh-opacity/useMeshOpacityControlledZone';

export const ControlledZoneMesh = memo(
  ({
    droneZone,
    options,
    isLabelVisible = true,
    isMapOverlayVisible,
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    ...props
  }: Omit<ZoneBaseMeshSharedProps, 'onClick'> & {
    droneZone: ControlledZone;
    isLabelVisible?: boolean;
    isMapOverlayVisible?: boolean;
    onClick: (droneZone: ControlledZone) => void;
    onDrag?: () => void;
    onDragStart?: () => void;
    onDragEnd?: (droneZone: ControlledZone) => void;
  }) => {
    const meshRef = useRef<THREE.Mesh>(null);
    const lineSegmentRef = useRef<THREE.LineSegments>(null);

    const { cameraState } = MutableState.getState();
    const { mapState } = useMapStore();

    const [isHovering, setIsHovering] = useState(false);

    const { w, l, h, positionVector } = bottomLeftDimensionsToSizeAndPos(droneZone.sizeAndPosition);
    const { isSelected, isActive, isEditing } = droneZone;
    const { controlledZones } = options.styles;
    const { opacity } = useMeshOpacity({ isSelected, isHovering });
    const { color } = useLabelColor({ isActive, isSelected, colors: controlledZones });
    const renderOrder = isSelected ? meshRenderOrder.highlight : meshRenderOrder.zones;
    const lineSegmentGeometry = new EdgesGeometry(new BoxGeometry(w, l, h));

    const isBorderActive = (isSelected || isHovering || isEditing) && isLabelVisible;
    const isBorderVisible = !isActive || isSelected || isHovering || isEditing;

    const { minX, minY, maxX, maxY } = mapFacilityVectors(mapState.map?.box);

    const material = generateSolidMaterial({
      opacity,
      primaryColor: isActive
        ? controlledZones.active.backgroundColor.primary
        : controlledZones.inactive.backgroundColor.primary,
    });

    const fov = cameraState.currentOrthographicFOV;
    const scale = fov * 0.0032; // Magic number - required for mapping px mouse movement to the map metre position.

    const enableCursor = !!(isHovering && (!isMapOverlayVisible || isEditing));
    useCursor(enableCursor, isEditing ? 'grab' : 'pointer');

    const bind = useGesture(
      {
        onPointerOver: () => {
          setIsHovering(true);
        },

        onPointerOut: () => {
          setIsHovering(false);
        },

        onClick: ({ event }) => {
          if (isEditing) {
            return;
          }

          event.stopPropagation();

          onClick(droneZone);
        },

        onDrag: ({ offset: [offsetX, offsetY] }) => {
          if (!isEditing) {
            return;
          }

          if (meshRef.current && lineSegmentRef.current) {
            if (onDrag) {
              // Currently used to disable the floor dragging to pan the camera.
              onDrag();
            }

            const posX = offsetX * scale;
            const posY = -offsetY * scale;

            const newPositionX = validatePositionToFacilityBoundaries({
              vector: posX + positionVector.x,
              offset: droneZone.sizeAndPosition.w / 2,
              boundaryMin: minX,
              boundaryMax: maxX,
            });

            const newPositionY = validatePositionToFacilityBoundaries({
              vector: posY + positionVector.y,
              offset: droneZone.sizeAndPosition.l / 2,
              boundaryMin: minY,
              boundaryMax: maxY,
            });

            // Update Mesh position
            meshRef.current.position.x = newPositionX;
            meshRef.current.position.y = newPositionY;

            // Update lineSegments position
            lineSegmentRef.current.position.x = newPositionX;
            lineSegmentRef.current.position.y = newPositionY;
          }
        },

        onDragStart: () => {
          if (!isEditing) {
            return;
          }

          if (onDragStart) {
            onDragStart();
          }
        },

        onDragEnd: () => {
          if (!isEditing) {
            return;
          }

          if (meshRef.current && onDragEnd) {
            onDragEnd({
              ...droneZone,
              sizeAndPosition: {
                ...droneZone.sizeAndPosition,
                minX: meshRef.current.position.x - droneZone.sizeAndPosition.w / 2,
                minY: meshRef.current.position.y - droneZone.sizeAndPosition.l / 2,
              },
            });
          }
        },
      },
      {
        drag: {
          from: [0, 0],
        },
      },
    );

    return (
      <>
        {/* @ts-expect-error event types */}
        <DroneZoneBaseMesh
          ref={meshRef}
          key={droneZone.id}
          renderOrder={renderOrder}
          textColors={{ text: color }}
          label={droneZone.name}
          isLabelVisible={isLabelVisible}
          options={options}
          material={material}
          position={positionVector}
          width={w}
          length={l}
          height={h}
          {...bind()}
          {...props}
        />

        <lineSegments
          ref={lineSegmentRef}
          geometry={lineSegmentGeometry}
          renderOrder={renderOrder}
          position={positionVector}
        >
          <meshBasicMaterial
            transparent
            opacity={isBorderVisible ? 1 : 0}
            color={
              isBorderActive
                ? controlledZones.active.borderColor.primary
                : controlledZones.active.borderColor.secondary
            }
          />
        </lineSegments>
      </>
    );
  },
);
