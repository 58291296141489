import { Checkbox } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { HeadCellProp } from '../../../types/cell';

type EnhancedTableHeadCellCheckboxProps = {
  headCell: HeadCellProp;
  classes: {
    tableHeaderCell: string;
  };
  onChange: (event: any) => void;
  numSelected: number;
  allSelected: boolean;
  rowCount: number;
};

export const EnhancedTableHeadCellCheckbox = (props: EnhancedTableHeadCellCheckboxProps) => {
  const { headCell, classes, numSelected, allSelected, rowCount, onChange } = props;

  if (headCell === undefined) {
    console.debug(
      'EnhancedTableHeadCellCheckbox',
      'headCell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell
      data-testid="c-table-column-head"
      className={classes.tableHeaderCell}
      key={headCell.id}
      padding="checkbox"
    >
      <Checkbox
        indeterminate={numSelected > 0 && !allSelected}
        checked={rowCount > 0 && allSelected}
        onChange={onChange}
      />
    </TableCell>
  );
};
