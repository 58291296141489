/*
 * Action types
 */
export enum GroundControlModalsActionNames {
  RESET,
  LOCK_MODAL,
  UNLOCK_MODAL,
  EMERGENCY_LANDING_MODAL,
}

export type GroundControlModalActions =
  // We are using string as payload which in this case will be flightDomainId and it will
  // indicates modals activity state
  | { type: GroundControlModalsActionNames.LOCK_MODAL; payload?: string }
  | { type: GroundControlModalsActionNames.UNLOCK_MODAL; payload?: string }
  | { type: GroundControlModalsActionNames.EMERGENCY_LANDING_MODAL; payload?: string }
  | { type: GroundControlModalsActionNames.RESET };
