import { ThreeEvent } from '@react-three/fiber';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { Vec6ToSizeAndPos } from 'shared/map-container/utils/creation.util';
import { meshHeightOrderOffset, meshRenderOrder } from 'shared/map/defaults/mesh-render-order';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { generateSolidMaterial } from 'shared/map/utils/material-generators/generate-solid-material';

export const DroneZoneMapOverlay = ({
  worldBox,
  onPointerEnter,
  onPointerLeave,
}: {
  worldBox: Vec6;
  onPointerEnter?: (e: ThreeEvent<PointerEvent>) => void;
  onPointerLeave?: (e: ThreeEvent<PointerEvent>) => void;
}) => {
  const { w, l, x, y, z } = Vec6ToSizeAndPos(worldBox);

  const offsetZ = z + meshHeightOrderOffset.mapOverlay;

  const material = generateSolidMaterial({
    opacity: 0.7,
    primaryColor: mapStyle.floor.facility.backgroundColor,
  });

  return (
    <mesh
      position={[x, y, offsetZ]}
      material={material}
      renderOrder={meshRenderOrder.map_overlay}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onClick={(e) => e.stopPropagation()}
    >
      <planeGeometry args={[w, l]} />
    </mesh>
  );
};
