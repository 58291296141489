import { IRulesActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { barcodeStatusEnum } from '../../../components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';

export type BarcodeMatchLogic =
  | 'Verity barcodes are superset'
  | 'WMS barcodes are superset'
  | 'Set intersection not empty'
  | 'Set exact match'
  | null;

export const getStatusColorChip = (
  status: barcodeStatusEnum,
  barcodeMatchLogic: BarcodeMatchLogic = null,
  issueLogic: IRulesActionSTIssueLogicEnum | null = null,
): 'success' | 'error' | 'warning' | undefined => {
  if (status === barcodeStatusEnum.Match) {
    return 'success';
  }
  if (status === barcodeStatusEnum.Missing) {
    return getMissingColor(barcodeMatchLogic, issueLogic);
  }
  if (status === barcodeStatusEnum.Unexpected) {
    return getUnexpectedColor(barcodeMatchLogic, issueLogic);
  }

  return undefined;
};

export const getMissingColor = (
  barcodeMatchLogic: BarcodeMatchLogic,
  issueLogic: IRulesActionSTIssueLogicEnum | null = null,
) => {
  const areMissingBarcodePotential = issueLogic === 'C001C_MISSING_BC';

  if (areMissingBarcodePotential) {
    return 'warning';
  }

  switch (barcodeMatchLogic) {
    case 'Set exact match':
    case 'Verity barcodes are superset':
      return 'error';
    case 'WMS barcodes are superset':
      return 'success';
    case 'Set intersection not empty':
      return 'success';
    default:
      return 'error';
  }
};

export const getUnexpectedColor = (
  barcodeMatchLogic: BarcodeMatchLogic,
  issueLogic: IRulesActionSTIssueLogicEnum | null = null,
) => {
  const areUnexpectedErrors = issueLogic === 'C001C_MISSING_BC';

  if (areUnexpectedErrors) {
    return 'error';
  }

  switch (barcodeMatchLogic) {
    case 'Set exact match':
    case 'WMS barcodes are superset':
      return 'error';
    case 'Verity barcodes are superset':
      return 'success';
    case 'Set intersection not empty':
      return 'success';
    default:
      return 'warning';
  }
};
