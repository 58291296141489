import { useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Box } from '../Box';
import Spinner from '../Spinner';

import { ImageCarousel } from './ImageCarousel';
import { PictureCounter } from './PictureCounter';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    background: '#fff',
    boxSizing: 'border-box',
  },
  hidden: {
    visibility: 'hidden',
  },
  imageTextBox: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: theme.palette.shades.darker,
    marginBottom: 0,
    paddingLeft: theme.spacing(1),
    '& > h6': {
      color: theme.palette.shades.lightest,
      fontSize: '14px',
    },
  },
  downloadButton: {
    display: 'flex',
    color: '#EBEBEB',
    cursor: 'pointer',
  },
  spinner: {
    position: 'relative',
    minHeight: '400px',
  },
}));

export type KeyboardNavigation = 'horizontal' | 'vertical' | undefined;

export const ImageCarouselContainer = (props: {
  systemId: string;
  location: any;
  imageURLs: string[];
  isLoading: boolean;
  slotStatusVersion: any;
  isReview: any;
  imageNavigationOnTop: any;
  keyboardNavigation: KeyboardNavigation;
}) => {
  const {
    systemId,
    location,
    imageURLs,
    isLoading,
    // FIX-ME::TR::2021-08-11:: Re-instate when re-instating the download images function below
    // facilityName,
    // reportId,
    // verityDate,
    slotStatusVersion,
    isReview,
    /**
     * Flags that sets the position of the controls to navigate among multiple
     * images in the carousel.
     */
    imageNavigationOnTop,
    keyboardNavigation,
  } = props;

  // state variables
  const [activeStep, setActiveStep] = useState(0);
  const [imagesDownloading, setImagesDownloading] = useState(false);

  useEffect(() => {
    const lastImageIndex = imageURLs.length - 1;
    if (activeStep > lastImageIndex) {
      setActiveStep(lastImageIndex);
    }
  }, [activeStep, imageURLs.length]);

  const { classes } = useStyles();

  return !isLoading ? (
    <>
      <PictureCounter
        systemId={systemId!}
        imageUrls={imageURLs}
        classes={classes}
        activeStep={activeStep}
        location={location}
        imagesDownloading={imagesDownloading}
        setImagesDownloading={setImagesDownloading}
        slotStatusVersion={slotStatusVersion}
        isReview={isReview}
      />

      <ImageCarousel
        imageUrls={imageURLs}
        activeStep={activeStep}
        onStepChange={setActiveStep}
        renderTopControls={imageNavigationOnTop && imageURLs.length}
        renderBottomControls={!imageNavigationOnTop && imageURLs.length > 1}
        keyboardNavigation={keyboardNavigation}
      />
    </>
  ) : (
    <Box className={classes.spinner}>
      <Spinner />
    </Box>
  );
};
