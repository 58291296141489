/*
 * Enumerated action types for facility menu
 */
export enum FacilityMenuActionNames {
  RESET = 'RESET',
  TOGGLE_MENU_OPENED = 'TOGGLE_MENU_OPENED',
  SET_MENU_OPENED = 'SET_MENU_OPENED',
  SET_EXPANDED_ITEMS = 'SET_EXPANDED_ITEMS',
}

/**
 * Types of facility menu actions
 */
export type FacilityMenuAction =
  | { type: FacilityMenuActionNames.RESET; payload: null }
  | { type: FacilityMenuActionNames.TOGGLE_MENU_OPENED; payload: null }
  | { type: FacilityMenuActionNames.SET_MENU_OPENED; payload: boolean }
  | { type: FacilityMenuActionNames.SET_EXPANDED_ITEMS; payload: string[] };
