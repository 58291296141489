import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export const getReportPriorityIcons = (priority: number) => {
  if (priority < 0) {
    return { icon: ArrowDownwardIcon, color: 'success', label: 'Low' };
  }

  if (priority === 0) {
    return { icon: HorizontalRuleIcon, color: undefined, label: 'Normal' };
  }

  if (priority === 1) {
    return { icon: ArrowUpwardIcon, color: 'error', label: 'High' };
  }

  return { icon: KeyboardDoubleArrowUpIcon, color: 'error', label: 'High' };
};
