import { useState } from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { DATETIME_FORMAT } from 'common/datetimeFormats';
import { COLOR_PALETTE } from 'common/colors';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { LoadingSwitch } from 'components/common/LoadingSwitch';
import DroneIcon from 'components/common/FacilityMenu/Icons/DroneIcon';
import { useRequestController } from '../../../../../../../hooks';
import { ZonesListProps } from '../../../ZonesList.model';
import { ControlledZoneService } from '../../../../../../../services/ControlledZone';
import { useStyles } from './ControlledZonesListItem.styles';
import { ControlledZoneItem } from './controlledZonesListItem.model';

const LOG_PREFIX: string = getLogPrefixForType('COMPONENT', 'ControlledZonesListItem');

export const ControlledZonesListItem = ({
  droneZone,
  systemId,
  flightDomainId,
  isCoexistenceWmsIntegrationEnabled,
  onSelect,
}: Pick<
  ZonesListProps<ControlledZones, ControlledZone>,
  'onSelect' | 'systemId' | 'flightDomainId'
> &
  ControlledZoneItem) => {
  const { classes, cx } = useStyles();
  const { requestController } = useRequestController(LOG_PREFIX);

  const [isDisabled, setIsDisabled] = useState(false);

  const classNames = cx(classes.itemWrapper, {
    [classes.itemWrapperActive]: droneZone.isSelected,
  });
  const isWaitingForDrone = !!droneZone.waitingForDronesToComeHome;
  const isToggleDisabled = isDisabled || isWaitingForDrone;
  const isToggleChecked = droneZone.isActive;
  const showWmsLabel =
    isCoexistenceWmsIntegrationEnabled && droneZone?.lastModifiedBy?.toLowerCase().includes('wms');

  const handleOnSelect = () => onSelect(droneZone);

  const handleSetZoneOnChange = () => {
    const zoneId = droneZone.id;
    const interval = isToggleChecked
      ? []
      : [{ start_time: moment.utc().format(DATETIME_FORMAT), end_time: null }];
    const { signal } = requestController.reserveSlotForRequest();

    requestController.doRequest({
      request: ControlledZoneService.setControlledZoneActive,
      callbackBeforeSend: () => setIsDisabled(true),
      callbackFinally: () => setIsDisabled(false),
      requestParams: [
        {
          systemId,
          flightDomainId,
          controlledZoneId: zoneId,
          controlledZone: { enabled_interval_list: interval },
          signal,
        },
      ],
    });
  };

  return (
    <li className={classNames}>
      <button
        id={droneZone.id}
        type="button"
        aria-label={droneZone.name}
        className={classes.details}
        onClick={handleOnSelect}
      >
        <div className={classes.detailsWrapper}>
          <Typography className={classes.detailsName}>{droneZone.name}</Typography>
          {showWmsLabel && (
            <Typography variant="body2" className={classes.detailsWms}>
              Set in WMS
            </Typography>
          )}
        </div>
        {isWaitingForDrone && <DroneIcon color={COLOR_PALETTE.ORANGE} />}
      </button>

      <div className={classes.actionArea}>
        <LoadingSwitch
          aria-label={
            isToggleChecked
              ? `Deactivate drone controlled zone "${droneZone.name}"`
              : `Activate drone controlled zone "${droneZone.name}"`
          }
          isLoading={isToggleDisabled}
          isDisabled={false}
          isChecked={isToggleChecked}
          onChange={handleSetZoneOnChange}
        />
      </div>
    </li>
  );
};
