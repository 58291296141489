/* tslint:disable */
/* eslint-disable */
/**
 * Navigation Simulation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IActivateNFZConflictResponseST
 */
export interface IActivateNFZConflictResponseST {
  /**
   * reasons for which the no-fly zone could not be activated
   * @type {Array<string>}
   * @memberof IActivateNFZConflictResponseST
   */
  reasons: Array<IActivateNFZConflictResponseSTReasonsEnum>;
}

export const IActivateNFZConflictResponseSTReasonsEnum = {
  AlreadyActive: 'ALREADY_ACTIVE',
  AlreadyNonActive: 'ALREADY_NON_ACTIVE',
  SystemNotLocked: 'SYSTEM_NOT_LOCKED',
  DronesFlying: 'DRONES_FLYING',
} as const;

export type IActivateNFZConflictResponseSTReasonsEnum =
  typeof IActivateNFZConflictResponseSTReasonsEnum[keyof typeof IActivateNFZConflictResponseSTReasonsEnum];

/**
 *
 * @export
 * @interface IActivateNoFlyZoneRequestST
 */
export interface IActivateNoFlyZoneRequestST {
  /**
   * the identifier of the no-fly zone
   * @type {string}
   * @memberof IActivateNoFlyZoneRequestST
   */
  nfz_id?: string | null;
  /**
   * the identifier of the no-fly zone
   * @type {string}
   * @memberof IActivateNoFlyZoneRequestST
   */
  id?: string | null;
  /**
   * Whether the no-fly zone is active
   * @type {boolean}
   * @memberof IActivateNoFlyZoneRequestST
   */
  active?: boolean | null;
  /**
   * Whether the no-fly zone is active
   * @type {boolean}
   * @memberof IActivateNoFlyZoneRequestST
   */
  drone_allowed_in_zone?: boolean | null;
  /**
   * The user or module that triggered the no-fly zone update
   * @type {string}
   * @memberof IActivateNoFlyZoneRequestST
   */
  triggered_by?: string;
}
/**
 *
 * @export
 * @interface IBox3DST
 */
export interface IBox3DST {
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  min: IMapPointST;
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  max: IMapPointST;
}
/**
 *
 * @export
 * @interface ICreateEstimatedObstacleRequestST
 */
export interface ICreateEstimatedObstacleRequestST {
  /**
   * Discovered by drone
   * @type {string}
   * @memberof ICreateEstimatedObstacleRequestST
   */
  discovered_by_drone: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof ICreateEstimatedObstacleRequestST
   */
  geometry: IBox3DST;
  /**
   *
   * @type {string}
   * @memberof ICreateEstimatedObstacleRequestST
   */
  type?: ICreateEstimatedObstacleRequestSTTypeEnum;
}

export const ICreateEstimatedObstacleRequestSTTypeEnum = {
  EstimatedFromCollisionAvoidanceSensors: 'ESTIMATED_FROM_COLLISION_AVOIDANCE_SENSORS',
  InferredFromSpaceReservation: 'INFERRED_FROM_SPACE_RESERVATION',
  NumSystemNoFlyZoneType: 'NUM_SYSTEM_NO_FLY_ZONE_TYPE',
  Null: 'null',
} as const;

export type ICreateEstimatedObstacleRequestSTTypeEnum =
  typeof ICreateEstimatedObstacleRequestSTTypeEnum[keyof typeof ICreateEstimatedObstacleRequestSTTypeEnum];

/**
 *
 * @export
 * @interface ICreateNFZConflictResponseST
 */
export interface ICreateNFZConflictResponseST {
  /**
   * reasons for which the no-fly zone could not be created
   * @type {Array<string>}
   * @memberof ICreateNFZConflictResponseST
   */
  reasons: Array<ICreateNFZConflictResponseSTReasonsEnum>;
}

export const ICreateNFZConflictResponseSTReasonsEnum = {
  SystemNotLocked: 'SYSTEM_NOT_LOCKED',
  DronesFlying: 'DRONES_FLYING',
  AlreadyExists: 'ALREADY_EXISTS',
  InvalidGeometry: 'INVALID_GEOMETRY',
} as const;

export type ICreateNFZConflictResponseSTReasonsEnum =
  typeof ICreateNFZConflictResponseSTReasonsEnum[keyof typeof ICreateNFZConflictResponseSTReasonsEnum];

/**
 *
 * @export
 * @interface ICreateNoFlyZoneRequestST
 */
export interface ICreateNoFlyZoneRequestST {
  /**
   * Name of the no-fly zone
   * @type {string}
   * @memberof ICreateNoFlyZoneRequestST
   */
  name: string;
  /**
   * no-fly zone description
   * @type {string}
   * @memberof ICreateNoFlyZoneRequestST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof ICreateNoFlyZoneRequestST
   */
  geometry: IBox3DST;
  /**
   * The user or module that triggered the no-fly zone creation
   * @type {string}
   * @memberof ICreateNoFlyZoneRequestST
   */
  created_by: string;
}
/**
 *
 * @export
 * @interface ICreateSpaceReservationRequestST
 */
export interface ICreateSpaceReservationRequestST {
  /**
   * DroneId
   * @type {string}
   * @memberof ICreateSpaceReservationRequestST
   */
  drone_id: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof ICreateSpaceReservationRequestST
   */
  geometry: IBox3DST;
}
/**
 *
 * @export
 * @interface IDeleteNFZConflictResponseST
 */
export interface IDeleteNFZConflictResponseST {
  /**
   * reasons for which the no-fly zone could not be updated
   * @type {Array<string>}
   * @memberof IDeleteNFZConflictResponseST
   */
  reasons: Array<IDeleteNFZConflictResponseSTReasonsEnum>;
}

export const IDeleteNFZConflictResponseSTReasonsEnum = {
  SystemNotLocked: 'SYSTEM_NOT_LOCKED',
  DronesFlying: 'DRONES_FLYING',
} as const;

export type IDeleteNFZConflictResponseSTReasonsEnum =
  typeof IDeleteNFZConflictResponseSTReasonsEnum[keyof typeof IDeleteNFZConflictResponseSTReasonsEnum];

/**
 *
 * @export
 * @interface IDeleteNoFlyZoneRequestST
 */
export interface IDeleteNoFlyZoneRequestST {
  /**
   * the identifier of the no-fly zone
   * @type {string}
   * @memberof IDeleteNoFlyZoneRequestST
   */
  id?: string | null;
  /**
   * the identifier of the no-fly zone
   * @type {string}
   * @memberof IDeleteNoFlyZoneRequestST
   */
  nfz_id?: string | null;
  /**
   * The user or module that triggered the no-fly zone update
   * @type {string}
   * @memberof IDeleteNoFlyZoneRequestST
   */
  deleted_by?: string;
}
/**
 *
 * @export
 * @interface IDeleteScheduleLockConflictResponseST
 */
export interface IDeleteScheduleLockConflictResponseST {
  /**
   * reason for which the schedule lock could not be deleted.
   * @type {string}
   * @memberof IDeleteScheduleLockConflictResponseST
   */
  reason: IDeleteScheduleLockConflictResponseSTReasonEnum;
}

export const IDeleteScheduleLockConflictResponseSTReasonEnum = {
  LockNotScheduled: 'LOCK_NOT_SCHEDULED',
} as const;

export type IDeleteScheduleLockConflictResponseSTReasonEnum =
  typeof IDeleteScheduleLockConflictResponseSTReasonEnum[keyof typeof IDeleteScheduleLockConflictResponseSTReasonEnum];

/**
 * @type IDeleteScheduleLockErrorResponseST
 * @export
 */
export type IDeleteScheduleLockErrorResponseST =
  | IDeleteScheduleLockConflictResponseST
  | IErrorResponseST;

/**
 *
 * @export
 * @interface IDroneOperatingHoursST
 */
export interface IDroneOperatingHoursST {
  /**
   * The start time of the drone operating hours. Expressed in UTC time.
   * @type {string}
   * @memberof IDroneOperatingHoursST
   */
  start: string;
  /**
   * The end time of the drone operating hours. Expressed in UTC time.
   * @type {string}
   * @memberof IDroneOperatingHoursST
   */
  end: string;
}
/**
 *
 * @export
 * @interface IDroneStatusST
 */
export interface IDroneStatusST {
  /**
   * The drone name
   * @type {string}
   * @memberof IDroneStatusST
   */
  drone_name: string;
  /**
   * The drone state
   * @type {string}
   * @memberof IDroneStatusST
   */
  drone_state: IDroneStatusSTDroneStateEnum;
  /**
   * The id of the drone charger
   * @type {string}
   * @memberof IDroneStatusST
   */
  charger_id: string;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  charger_pos_description: string;
  /**
   * The drone battery level
   * @type {number}
   * @memberof IDroneStatusST
   */
  battery_level: number;
  /**
   * The drone battery level
   * @type {string}
   * @memberof IDroneStatusST
   */
  battery_state: IDroneStatusSTBatteryStateEnum;
  /**
   * The drone connection state
   * @type {string}
   * @memberof IDroneStatusST
   */
  drone_online: IDroneStatusSTDroneOnlineEnum;
  /**
   * The drone voltage
   * @type {number}
   * @memberof IDroneStatusST
   */
  battery_voltage?: number | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  last_operation_feedback?: string | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  not_ready_reason?: string | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  additional_diagnostics?: string | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  last_reliable_pose_time?: string | null;
  /**
   * The description of the drone charger position
   * @type {string}
   * @memberof IDroneStatusST
   */
  pose_state?: IDroneStatusSTPoseStateEnum;
  /**
   *
   * @type {IPositionAndYawST}
   * @memberof IDroneStatusST
   */
  position_and_yaw?: IPositionAndYawST;
  /**
   * The timestamp of the status update
   * @type {string}
   * @memberof IDroneStatusST
   */
  updated_at: string;
}

export const IDroneStatusSTDroneStateEnum = {
  OnCharger: 'ON_CHARGER',
  Flying: 'FLYING',
  LandedInError: 'LANDED_IN_ERROR',
} as const;

export type IDroneStatusSTDroneStateEnum =
  typeof IDroneStatusSTDroneStateEnum[keyof typeof IDroneStatusSTDroneStateEnum];
export const IDroneStatusSTBatteryStateEnum = {
  Charging: 'CHARGING',
  FullyCharged: 'FULLY_CHARGED',
  NotPluggedIn: 'NOT_PLUGGED_IN',
  Recovering: 'RECOVERING',
  Error: 'ERROR',
  ShipMode: 'SHIP_MODE',
} as const;

export type IDroneStatusSTBatteryStateEnum =
  typeof IDroneStatusSTBatteryStateEnum[keyof typeof IDroneStatusSTBatteryStateEnum];
export const IDroneStatusSTDroneOnlineEnum = {
  Online: 'ONLINE',
  Stale: 'STALE',
  Offline: 'OFFLINE',
} as const;

export type IDroneStatusSTDroneOnlineEnum =
  typeof IDroneStatusSTDroneOnlineEnum[keyof typeof IDroneStatusSTDroneOnlineEnum];
export const IDroneStatusSTPoseStateEnum = {
  Unkown: 'UNKOWN',
  Reliable: 'RELIABLE',
  Unreliable: 'UNRELIABLE',
  UnreliableButInferredFromCharger: 'UNRELIABLE_BUT_INFERRED_FROM_CHARGER',
} as const;

export type IDroneStatusSTPoseStateEnum =
  typeof IDroneStatusSTPoseStateEnum[keyof typeof IDroneStatusSTPoseStateEnum];

/**
 *
 * @export
 * @interface IDroneVersionST
 */
export interface IDroneVersionST {
  /**
   * The drone name
   * @type {string}
   * @memberof IDroneVersionST
   */
  drone_name: string;
  /**
   * Drone Serial Number
   * @type {string}
   * @memberof IDroneVersionST
   */
  drone_serial: string;
  /**
   * Drone SW Version
   * @type {string}
   * @memberof IDroneVersionST
   */
  version: string;
  /**
   * Last SW Update Time
   * @type {string}
   * @memberof IDroneVersionST
   */
  last_updated: string;
}
/**
 *
 * @export
 * @interface IEmergencyLandConflictResponseST
 */
export interface IEmergencyLandConflictResponseST {
  /**
   * reason for which the drones in the flight domain could not be emergency landed
   * @type {string}
   * @memberof IEmergencyLandConflictResponseST
   */
  reason: IEmergencyLandConflictResponseSTReasonEnum;
}

export const IEmergencyLandConflictResponseSTReasonEnum = {
  AlreadyEmLanded: 'ALREADY_EM_LANDED',
} as const;

export type IEmergencyLandConflictResponseSTReasonEnum =
  typeof IEmergencyLandConflictResponseSTReasonEnum[keyof typeof IEmergencyLandConflictResponseSTReasonEnum];

/**
 * @type IEmergencyLandErrorResponseST
 * @export
 */
export type IEmergencyLandErrorResponseST = IEmergencyLandConflictResponseST | IErrorResponseST;

/**
 *
 * @export
 * @interface IEmergencyLandPutRequestST
 */
export interface IEmergencyLandPutRequestST {
  /**
   * The user or module that triggered the emergency land
   * @type {string}
   * @memberof IEmergencyLandPutRequestST
   */
  triggered_by: string;
}
/**
 *
 * @export
 * @interface IEmergencyLandRequestST
 */
export interface IEmergencyLandRequestST {
  /**
   * The user or module that triggered the emergency land
   * @type {string}
   * @memberof IEmergencyLandRequestST
   */
  triggered_by: string;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IFleetStatusResponseST
 */
export interface IFleetStatusResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFleetStatusResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the fleet
   * @type {{ [key: string]: IDroneStatusST; }}
   * @memberof IFleetStatusResponseST
   */
  fleet_status: { [key: string]: IDroneStatusST };
}
/**
 *
 * @export
 * @interface IFleetStatusSummaryResponseST
 */
export interface IFleetStatusSummaryResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFleetStatusSummaryResponseST
   */
  flight_domain_id: string;
  /**
   *
   * @type {IFleetStatusSummaryST}
   * @memberof IFleetStatusSummaryResponseST
   */
  fleet_status_summary: IFleetStatusSummaryST;
}
/**
 *
 * @export
 * @interface IFleetStatusSummaryST
 */
export interface IFleetStatusSummaryST {
  /**
   * The number of drones that are flying
   * @type {number}
   * @memberof IFleetStatusSummaryST
   */
  num_drones_flying: number;
  /**
   * The number of drones that are charging
   * @type {number}
   * @memberof IFleetStatusSummaryST
   */
  num_drones_on_charger: number;
  /**
   * The number of drones that landed
   * @type {number}
   * @memberof IFleetStatusSummaryST
   */
  num_drones_landed_in_error: number;
  /**
   * The time when the information was collected. Expressed in UTC time.
   * @type {string}
   * @memberof IFleetStatusSummaryST
   */
  updated_at: string;
}
/**
 *
 * @export
 * @interface IFleetVersionResponseST
 */
export interface IFleetVersionResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFleetVersionResponseST
   */
  flight_domain_id: string;
  /**
   * The Drone Versions
   * @type {{ [key: string]: IDroneVersionST; }}
   * @memberof IFleetVersionResponseST
   */
  drone_versions: { [key: string]: IDroneVersionST };
}
/**
 * @type IFlightDomainStatusCurrentDroneOperatingHoursST
 * The current drone operating_hours. If null, there is no drone operating hours active.
 * @export
 */
export type IFlightDomainStatusCurrentDroneOperatingHoursST =
  | IDroneOperatingHoursST
  | INullableFieldST;

/**
 * @type IFlightDomainStatusNextDroneOperatingHoursST
 * The next drone operating hours.
 * @export
 */
export type IFlightDomainStatusNextDroneOperatingHoursST =
  | IDroneOperatingHoursST
  | INullableFieldST;

/**
 *
 * @export
 * @interface IFlightDomainStatusResponseST
 */
export interface IFlightDomainStatusResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IFlightDomainStatusResponseST
   */
  flight_domain_id: string;
  /**
   *
   * @type {IFlightDomainStatusST}
   * @memberof IFlightDomainStatusResponseST
   */
  flight_domain_status: IFlightDomainStatusST;
}
/**
 *
 * @export
 * @interface IFlightDomainStatusST
 */
export interface IFlightDomainStatusST {
  /**
   * The lock status
   * @type {boolean}
   * @memberof IFlightDomainStatusST
   */
  locked: boolean;
  /**
   * The time when the flight domain was last locked/unlock the last time. Expressed in UTC time.
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  last_locked_unlocked_time: string;
  /**
   * The unique identifier of the ongoing unlock session
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  unlock_session_identifier: string;
  /**
   * The reason that triggered the system lock
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  lock_reason: IFlightDomainStatusSTLockReasonEnum;
  /**
   * The lock status
   * @type {boolean}
   * @memberof IFlightDomainStatusST
   */
  can_unlock: boolean;
  /**
   * The reason for which the system cannot be unlocked
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  cannot_unlock_reason: IFlightDomainStatusSTCannotUnlockReasonEnum;
  /**
   * The entity that triggered the system lock
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  triggered_by: string | null;
  /**
   *
   * @type {IFlightDomainStatusCurrentDroneOperatingHoursST}
   * @memberof IFlightDomainStatusST
   */
  current_drone_operating_hours: IFlightDomainStatusCurrentDroneOperatingHoursST | null;
  /**
   *
   * @type {IFlightDomainStatusNextDroneOperatingHoursST}
   * @memberof IFlightDomainStatusST
   */
  next_drone_operating_hours: IFlightDomainStatusNextDroneOperatingHoursST | null;
  /**
   * The time when the information was collected. Expressed in UTC time
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  updated_at: string;
  /**
   * The next time for which a lock is scheduled. Expressed in UTC time.
   * @type {string}
   * @memberof IFlightDomainStatusST
   */
  next_scheduled_lock?: string | null;
}

export const IFlightDomainStatusSTLockReasonEnum = {
  Manual: 'MANUAL',
  EmergencyLand: 'EMERGENCY_LAND',
  DroneOperatingHoursEnd: 'DRONE_OPERATING_HOURS_END',
  FleetDecimated: 'FLEET_DECIMATED',
  SystemInitialization: 'SYSTEM_INITIALIZATION',
  ScheduledLock: 'SCHEDULED_LOCK',
  Null: 'null',
} as const;

export type IFlightDomainStatusSTLockReasonEnum =
  typeof IFlightDomainStatusSTLockReasonEnum[keyof typeof IFlightDomainStatusSTLockReasonEnum];
export const IFlightDomainStatusSTCannotUnlockReasonEnum = {
  AlreadyUnlocked: 'ALREADY_UNLOCKED',
  OutsideDroneOperatingHours: 'OUTSIDE_DRONE_OPERATING_HOURS',
  Null: 'null',
} as const;

export type IFlightDomainStatusSTCannotUnlockReasonEnum =
  typeof IFlightDomainStatusSTCannotUnlockReasonEnum[keyof typeof IFlightDomainStatusSTCannotUnlockReasonEnum];

/**
 *
 * @export
 * @interface IGetEstimatedObstaclesResponseST
 */
export interface IGetEstimatedObstaclesResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IGetEstimatedObstaclesResponseST
   */
  flight_domain_id: string;
  /**
   * The Estimated Obstacles
   * @type {{ [key: string]: IObstacleInformationST; }}
   * @memberof IGetEstimatedObstaclesResponseST
   */
  estimated_obstacles: { [key: string]: IObstacleInformationST };
}
/**
 *
 * @export
 * @interface IGetSpaceReservationsResponseST
 */
export interface IGetSpaceReservationsResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IGetSpaceReservationsResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the fleet
   * @type {{ [key: string]: ISpaceReservationInformationST; }}
   * @memberof IGetSpaceReservationsResponseST
   */
  space_reservations: { [key: string]: ISpaceReservationInformationST };
}
/**
 *
 * @export
 * @interface ILockConflictResponseST
 */
export interface ILockConflictResponseST {
  /**
   * reason for which the flight domain could not be locked
   * @type {string}
   * @memberof ILockConflictResponseST
   */
  reason: ILockConflictResponseSTReasonEnum;
}

export const ILockConflictResponseSTReasonEnum = {
  Locked: 'ALREADY_LOCKED',
  EmLanded: 'ALREADY_EM_LANDED',
} as const;

export type ILockConflictResponseSTReasonEnum =
  typeof ILockConflictResponseSTReasonEnum[keyof typeof ILockConflictResponseSTReasonEnum];

/**
 * @type ILockErrorResponseST
 * @export
 */
export type ILockErrorResponseST = IErrorResponseST | ILockConflictResponseST;

/**
 *
 * @export
 * @interface ILockPutRequestST
 */
export interface ILockPutRequestST {
  /**
   * The reason for which the is requested
   * @type {string}
   * @memberof ILockPutRequestST
   */
  lock_reason: ILockPutRequestSTLockReasonEnum;
  /**
   * The user or module that triggered the lock
   * @type {string}
   * @memberof ILockPutRequestST
   */
  triggered_by: string;
}

export const ILockPutRequestSTLockReasonEnum = {
  Manual: 'MANUAL',
  FleetDecimated: 'FLEET_DECIMATED',
} as const;

export type ILockPutRequestSTLockReasonEnum =
  typeof ILockPutRequestSTLockReasonEnum[keyof typeof ILockPutRequestSTLockReasonEnum];

/**
 *
 * @export
 * @interface ILockRequestST
 */
export interface ILockRequestST {
  /**
   * The reason for which the is requested
   * @type {string}
   * @memberof ILockRequestST
   */
  lock_reason: ILockRequestSTLockReasonEnum;
  /**
   * The user or module that triggered the lock
   * @type {string}
   * @memberof ILockRequestST
   */
  triggered_by: string;
}

export const ILockRequestSTLockReasonEnum = {
  Manual: 'MANUAL',
  FleetDecimated: 'FLEET_DECIMATED',
} as const;

export type ILockRequestSTLockReasonEnum =
  typeof ILockRequestSTLockReasonEnum[keyof typeof ILockRequestSTLockReasonEnum];

/**
 *
 * @export
 * @interface IMapPointST
 */
export interface IMapPointST {
  /**
   * The value of the X coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  x: number;
  /**
   * The value of the Y coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  y: number;
  /**
   * The value of the Z coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  z: number;
}
/**
 *
 * @export
 * @interface INoFlyZoneActivatePutRequestST
 */
export interface INoFlyZoneActivatePutRequestST {}
/**
 *
 * @export
 * @interface INoFlyZoneDeleteRequestST
 */
export interface INoFlyZoneDeleteRequestST {}
/**
 *
 * @export
 * @interface INoFlyZonePostRequestST
 */
export interface INoFlyZonePostRequestST {
  /**
   * Name of the no-fly zone
   * @type {string}
   * @memberof INoFlyZonePostRequestST
   */
  name: string;
  /**
   * no-fly zone description
   * @type {string}
   * @memberof INoFlyZonePostRequestST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof INoFlyZonePostRequestST
   */
  geometry: IBox3DST;
  /**
   * The user or module that triggered the no-fly zone creation
   * @type {string}
   * @memberof INoFlyZonePostRequestST
   */
  created_by: string;
}
/**
 *
 * @export
 * @interface INoFlyZonePropertiesPutRequestST
 */
export interface INoFlyZonePropertiesPutRequestST {}
/**
 *
 * @export
 * @interface INoFlyZonePutRequestST
 */
export interface INoFlyZonePutRequestST {}
/**
 *
 * @export
 * @interface INoFlyZoneStatus1ST
 */
export interface INoFlyZoneStatus1ST {
  /**
   * The no-fly zone name
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  name?: string;
  /**
   * The no-fly zone description
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  description?: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof INoFlyZoneStatus1ST
   */
  geometry?: IBox3DST;
  /**
   * Whether the no-fly zone is drone_allowed_in_zone
   * @type {boolean}
   * @memberof INoFlyZoneStatus1ST
   */
  drone_allowed_in_zone?: boolean;
  /**
   * The the no-fly zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  last_zone_permission_change_time?: string;
  /**
   * The user that activated or deactivated the no-fly zone the last time
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  last_zone_permission_change_by?: string;
  /**
   * Whether the no-fly zone is active TO BE REMOVED
   * @type {boolean}
   * @memberof INoFlyZoneStatus1ST
   */
  active?: boolean;
  /**
   * The the no-fly zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  last_activated_deactivated_time?: string;
  /**
   * The user that activated or deactivated the no-fly zone the last time
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  last_activated_deactivated_by?: string;
}
/**
 *
 * @export
 * @interface INoFlyZoneStatusST
 */
export interface INoFlyZoneStatusST {
  /**
   * The no-fly zone name
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  name?: string;
  /**
   * The no-fly zone description
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  description?: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof INoFlyZoneStatusST
   */
  geometry?: IBox3DST;
  /**
   * Whether the no-fly zone is drone_allowed_in_zone
   * @type {boolean}
   * @memberof INoFlyZoneStatusST
   */
  drone_allowed_in_zone?: boolean;
  /**
   * The the no-fly zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  last_zone_permission_change_time?: string;
  /**
   * The user that activated or deactivated the no-fly zone the last time
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  last_zone_permission_change_by?: string;
  /**
   * Whether the no-fly zone is active TO BE REMOVED
   * @type {boolean}
   * @memberof INoFlyZoneStatusST
   */
  active?: boolean;
  /**
   * The the no-fly zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  last_activated_deactivated_time?: string;
  /**
   * The user that activated or deactivated the no-fly zone the last time
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  last_activated_deactivated_by?: string;
}
/**
 *
 * @export
 * @interface INoFlyZonesResponseST
 */
export interface INoFlyZonesResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof INoFlyZonesResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the no-fly zones
   * @type {{ [key: string]: INoFlyZoneStatus1ST; }}
   * @memberof INoFlyZonesResponseST
   */
  no_fly_zones: { [key: string]: INoFlyZoneStatus1ST };
}
/**
 *
 * @export
 * @enum {string}
 */

export const INullableFieldST = {
  Null: 'null',
  None: 'None',
} as const;

export type INullableFieldST = typeof INullableFieldST[keyof typeof INullableFieldST];

/**
 *
 * @export
 * @interface IObstacleInformationST
 */
export interface IObstacleInformationST {
  /**
   * Discovered by drone
   * @type {string}
   * @memberof IObstacleInformationST
   */
  discovered_by_drone: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IObstacleInformationST
   */
  geometry: IBox3DST;
  /**
   * The timestamp of discovery
   * @type {string}
   * @memberof IObstacleInformationST
   */
  discovery_time: string;
  /**
   * The timestamp of time-out
   * @type {string}
   * @memberof IObstacleInformationST
   */
  times_out_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof IObstacleInformationST
   */
  type?: IObstacleInformationSTTypeEnum;
}

export const IObstacleInformationSTTypeEnum = {
  EstimatedFromCollisionAvoidanceSensors: 'ESTIMATED_FROM_COLLISION_AVOIDANCE_SENSORS',
  InferredFromSpaceReservation: 'INFERRED_FROM_SPACE_RESERVATION',
  NumSystemNoFlyZoneType: 'NUM_SYSTEM_NO_FLY_ZONE_TYPE',
  Null: 'null',
} as const;

export type IObstacleInformationSTTypeEnum =
  typeof IObstacleInformationSTTypeEnum[keyof typeof IObstacleInformationSTTypeEnum];

/**
 * PositionAndYaw
 * @export
 * @interface IPositionAndYawST
 */
export interface IPositionAndYawST {
  /**
   * The value of the X coordinate of drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  x: number;
  /**
   * The value of the Y coordinate of drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  y: number;
  /**
   * The value of the Z coordinate of drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  z: number;
  /**
   * The yaw coordinate for drone position
   * @type {number}
   * @memberof IPositionAndYawST
   */
  yaw: number;
}
/**
 *
 * @export
 * @interface IScheduleLockConflictResponseST
 */
export interface IScheduleLockConflictResponseST {
  /**
   * reasons for which the schedule lock could not be created.
   * @type {Array<string>}
   * @memberof IScheduleLockConflictResponseST
   */
  reasons: Array<IScheduleLockConflictResponseSTReasonsEnum>;
}

export const IScheduleLockConflictResponseSTReasonsEnum = {
  AlreadyScheduled: 'ALREADY_SCHEDULED',
  LockIsInThePast: 'LOCK_IS_IN_THE_PAST',
  OperatingHoursConflict: 'OPERATING_HOURS_CONFLICT',
} as const;

export type IScheduleLockConflictResponseSTReasonsEnum =
  typeof IScheduleLockConflictResponseSTReasonsEnum[keyof typeof IScheduleLockConflictResponseSTReasonsEnum];

/**
 * @type IScheduleLockErrorResponseST
 * @export
 */
export type IScheduleLockErrorResponseST = IErrorResponseST | IScheduleLockConflictResponseST;

/**
 *
 * @export
 * @interface IScheduleLockPutRequestST
 */
export interface IScheduleLockPutRequestST {
  /**
   * Time of the scheduled lock as string.
   * @type {string}
   * @memberof IScheduleLockPutRequestST
   */
  lock_at: string;
  /**
   * The user of module that schedule this lock.
   * @type {string}
   * @memberof IScheduleLockPutRequestST
   */
  scheduled_by: string;
}
/**
 *
 * @export
 * @interface IScheduleLockRequestST
 */
export interface IScheduleLockRequestST {
  /**
   * Time of the scheduled lock as string.
   * @type {string}
   * @memberof IScheduleLockRequestST
   */
  lock_at: string;
  /**
   * The user of module that schedule this lock.
   * @type {string}
   * @memberof IScheduleLockRequestST
   */
  scheduled_by: string;
}
/**
 *
 * @export
 * @interface ISpaceReservationInformationST
 */
export interface ISpaceReservationInformationST {
  /**
   * Drone ID
   * @type {string}
   * @memberof ISpaceReservationInformationST
   */
  drone_id: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof ISpaceReservationInformationST
   */
  geometry: IBox3DST;
  /**
   * The timestamp of expiry
   * @type {string}
   * @memberof ISpaceReservationInformationST
   */
  expires_at: string;
}
/**
 *
 * @export
 * @interface IStartSimulationPostRequestST
 */
export interface IStartSimulationPostRequestST {
  /**
   * Duration of the simulation in seconds
   * @type {number}
   * @memberof IStartSimulationPostRequestST
   */
  duration: number;
  /**
   * Period of the simulation loop
   * @type {number}
   * @memberof IStartSimulationPostRequestST
   */
  period?: number;
  /**
   * Number of drones taking off. If bigger that fleet size all drones will take off.
   * @type {number}
   * @memberof IStartSimulationPostRequestST
   */
  num_drones_flying: number;
  /**
   * If set to true, the simulation assumes that drones have WiFi connectivity all the time. If set to false, the simulation assumes that some drones will drop WiFi connectivity during the flight.
   * @type {boolean}
   * @memberof IStartSimulationPostRequestST
   */
  optimal: boolean;
  /**
   * Number of drones that do not have WiFi connection when optimal is set to False
   * @type {number}
   * @memberof IStartSimulationPostRequestST
   */
  sub_optimal_num_drones_no_wifi?: number;
}
/**
 *
 * @export
 * @interface IStartSimulationST
 */
export interface IStartSimulationST {
  /**
   * Duration of the simulation in seconds
   * @type {number}
   * @memberof IStartSimulationST
   */
  duration: number;
  /**
   * Period of the simulation loop
   * @type {number}
   * @memberof IStartSimulationST
   */
  period?: number;
  /**
   * Number of drones taking off. If bigger that fleet size all drones will take off.
   * @type {number}
   * @memberof IStartSimulationST
   */
  num_drones_flying: number;
  /**
   * If set to true, the simulation assumes that drones have WiFi connectivity all the time. If set to false, the simulation assumes that some drones will drop WiFi connectivity during the flight.
   * @type {boolean}
   * @memberof IStartSimulationST
   */
  optimal: boolean;
  /**
   * Number of drones that do not have WiFi connection when optimal is set to False
   * @type {number}
   * @memberof IStartSimulationST
   */
  sub_optimal_num_drones_no_wifi?: number;
}
/**
 *
 * @export
 * @interface IUnLockPutRequestST
 */
export interface IUnLockPutRequestST {
  /**
   * The user or module that triggered the unlock
   * @type {string}
   * @memberof IUnLockPutRequestST
   */
  triggered_by: string;
}
/**
 *
 * @export
 * @interface IUnLockRequestST
 */
export interface IUnLockRequestST {
  /**
   * The user or module that triggered the unlock
   * @type {string}
   * @memberof IUnLockRequestST
   */
  triggered_by: string;
}
/**
 *
 * @export
 * @interface IUnlockConflictResponseST
 */
export interface IUnlockConflictResponseST {
  /**
   * reason for which the flight domain could not be unlocked
   * @type {string}
   * @memberof IUnlockConflictResponseST
   */
  reason: IUnlockConflictResponseSTReasonEnum;
}

export const IUnlockConflictResponseSTReasonEnum = {
  AlreadyUnlocked: 'ALREADY_UNLOCKED',
  OutsideDroneOperatingHours: 'OUTSIDE_DRONE_OPERATING_HOURS',
} as const;

export type IUnlockConflictResponseSTReasonEnum =
  typeof IUnlockConflictResponseSTReasonEnum[keyof typeof IUnlockConflictResponseSTReasonEnum];

/**
 * @type IUnlockErrorResponseST
 * @export
 */
export type IUnlockErrorResponseST = IErrorResponseST | IUnlockConflictResponseST;

/**
 *
 * @export
 * @interface IUpdateNFZConflictResponseST
 */
export interface IUpdateNFZConflictResponseST {
  /**
   * reasons for which the no-fly zone could not be updated
   * @type {Array<string>}
   * @memberof IUpdateNFZConflictResponseST
   */
  reasons: Array<IUpdateNFZConflictResponseSTReasonsEnum>;
}

export const IUpdateNFZConflictResponseSTReasonsEnum = {
  AlreadyActive: 'ALREADY_ACTIVE',
  AlreadyNonActive: 'ALREADY_NON_ACTIVE',
  SystemNotLocked: 'SYSTEM_NOT_LOCKED',
  DronesFlying: 'DRONES_FLYING',
} as const;

export type IUpdateNFZConflictResponseSTReasonsEnum =
  typeof IUpdateNFZConflictResponseSTReasonsEnum[keyof typeof IUpdateNFZConflictResponseSTReasonsEnum];

/**
 *
 * @export
 * @interface IUpdateNFZPropertiesConflictResponseST
 */
export interface IUpdateNFZPropertiesConflictResponseST {
  /**
   * reasons for which the no-fly zone properties could not be updated
   * @type {Array<string>}
   * @memberof IUpdateNFZPropertiesConflictResponseST
   */
  reasons: Array<IUpdateNFZPropertiesConflictResponseSTReasonsEnum>;
}

export const IUpdateNFZPropertiesConflictResponseSTReasonsEnum = {
  SystemNotLocked: 'SYSTEM_NOT_LOCKED',
  DronesFlying: 'DRONES_FLYING',
  AlreadyExists: 'ALREADY_EXISTS',
  InvalidGeometry: 'INVALID_GEOMETRY',
} as const;

export type IUpdateNFZPropertiesConflictResponseSTReasonsEnum =
  typeof IUpdateNFZPropertiesConflictResponseSTReasonsEnum[keyof typeof IUpdateNFZPropertiesConflictResponseSTReasonsEnum];

/**
 *
 * @export
 * @interface IUpdateNoFlyZonePropertiesRequestST
 */
export interface IUpdateNoFlyZonePropertiesRequestST {
  /**
   * the identifier of the no-fly zone
   * @type {string}
   * @memberof IUpdateNoFlyZonePropertiesRequestST
   */
  id?: string | null;
  /**
   * the identifier of the no-fly zone
   * @type {string}
   * @memberof IUpdateNoFlyZonePropertiesRequestST
   */
  nfz_id?: string | null;
  /**
   * Name of the no-fly zone
   * @type {string}
   * @memberof IUpdateNoFlyZonePropertiesRequestST
   */
  name?: string;
  /**
   * no-fly zone description
   * @type {string}
   * @memberof IUpdateNoFlyZonePropertiesRequestST
   */
  description?: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof IUpdateNoFlyZonePropertiesRequestST
   */
  geometry?: IBox3DST;
  /**
   * The user or module that triggered the no-fly zone creation
   * @type {string}
   * @memberof IUpdateNoFlyZonePropertiesRequestST
   */
  modified_by?: string;
}

/**
 * EmergencyProceduresApi - axios parameter creator
 * @export
 */
export const EmergencyProceduresApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSafetyManagerSimulatorScheduleLock: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteSafetyManagerSimulatorScheduleLock', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'deleteSafetyManagerSimulatorScheduleLock',
        'flightDomainId',
        flightDomainId,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/safety-manager-simulator/schedule-lock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
     * @summary Sends a request to safety land all the drones of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorEmLand: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateSafetyManagerSimulatorEmLand', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateSafetyManagerSimulatorEmLand', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/safety-manager-simulator/em-land`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to lock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorLock: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateSafetyManagerSimulatorLock', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateSafetyManagerSimulatorLock', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/safety-manager-simulator/lock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorScheduleLock: async (
      systemIdStr: string,
      flightDomainId: string,
      iScheduleLockPutRequestST: IScheduleLockPutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateSafetyManagerSimulatorScheduleLock', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'updateSafetyManagerSimulatorScheduleLock',
        'flightDomainId',
        flightDomainId,
      );
      // verify required parameter 'iScheduleLockPutRequestST' is not null or undefined
      assertParamExists(
        'updateSafetyManagerSimulatorScheduleLock',
        'iScheduleLockPutRequestST',
        iScheduleLockPutRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/safety-manager-simulator/schedule-lock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iScheduleLockPutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to unlock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorUnlock: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateSafetyManagerSimulatorUnlock', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateSafetyManagerSimulatorUnlock', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/safety-manager-simulator/unlock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmergencyProceduresApi - functional programming interface
 * @export
 */
export const EmergencyProceduresApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmergencyProceduresApiAxiosParamCreator(configuration);
  return {
    /**
     * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSafetyManagerSimulatorScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteSafetyManagerSimulatorScheduleLock(
          systemIdStr,
          flightDomainId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
     * @summary Sends a request to safety land all the drones of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSafetyManagerSimulatorEmLand(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSafetyManagerSimulatorEmLand(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to lock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSafetyManagerSimulatorLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSafetyManagerSimulatorLock(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSafetyManagerSimulatorScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      iScheduleLockPutRequestST: IScheduleLockPutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSafetyManagerSimulatorScheduleLock(
          systemIdStr,
          flightDomainId,
          iScheduleLockPutRequestST,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to unlock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSafetyManagerSimulatorUnlock(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSafetyManagerSimulatorUnlock(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmergencyProceduresApi - factory interface
 * @export
 */
export const EmergencyProceduresApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmergencyProceduresApiFp(configuration);
  return {
    /**
     * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSafetyManagerSimulatorScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteSafetyManagerSimulatorScheduleLock(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
     * @summary Sends a request to safety land all the drones of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorEmLand(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateSafetyManagerSimulatorEmLand(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to lock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateSafetyManagerSimulatorLock(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      iScheduleLockPutRequestST: IScheduleLockPutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateSafetyManagerSimulatorScheduleLock(
          systemIdStr,
          flightDomainId,
          iScheduleLockPutRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to unlock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSafetyManagerSimulatorUnlock(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateSafetyManagerSimulatorUnlock(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmergencyProceduresApi - object-oriented interface
 * @export
 * @class EmergencyProceduresApi
 * @extends {BaseAPI}
 */
export class EmergencyProceduresApi extends BaseAPI {
  /**
   * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
   * @summary Sends a request to schedule a lock of the flight domain in the future.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public deleteSafetyManagerSimulatorScheduleLock(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .deleteSafetyManagerSimulatorScheduleLock(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
   * @summary Sends a request to safety land all the drones of the flight domain.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public updateSafetyManagerSimulatorEmLand(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .updateSafetyManagerSimulatorEmLand(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
   * @summary Sends a request to lock the flight domain.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public updateSafetyManagerSimulatorLock(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .updateSafetyManagerSimulatorLock(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
   * @summary Sends a request to schedule a lock of the flight domain in the future.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public updateSafetyManagerSimulatorScheduleLock(
    systemIdStr: string,
    flightDomainId: string,
    iScheduleLockPutRequestST: IScheduleLockPutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .updateSafetyManagerSimulatorScheduleLock(
        systemIdStr,
        flightDomainId,
        iScheduleLockPutRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
   * @summary Sends a request to unlock the flight domain.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public updateSafetyManagerSimulatorUnlock(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .updateSafetyManagerSimulatorUnlock(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FleetSimulationApi - axios parameter creator
 * @export
 */
export const FleetSimulationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns the status of the fleet.
     * @summary Returns the status of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulator: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFleetSimulator', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFleetSimulator', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the drone versions of the fleet.
     * @summary Returns the drone versions of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulatorFleetVersion: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFleetSimulatorFleetVersion', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFleetSimulatorFleetVersion', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/fleet/version`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the summary of the status of the fleet.
     * @summary Returns the summary of the status of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulatorStatusSummary: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFleetSimulatorStatusSummary', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFleetSimulatorStatusSummary', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/status-summary`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the status of the flight domain.
     * @summary Returns the status of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSafetyManagerSimulator: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getSafetyManagerSimulator', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getSafetyManagerSimulator', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/safety-manager-simulator`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Starts the flight simulation.
     * @summary Starts the flight simulation.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IStartSimulationPostRequestST} iStartSimulationPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFleetSimulator: async (
      systemIdStr: string,
      flightDomainId: string,
      iStartSimulationPostRequestST: IStartSimulationPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('postFleetSimulator', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('postFleetSimulator', 'flightDomainId', flightDomainId);
      // verify required parameter 'iStartSimulationPostRequestST' is not null or undefined
      assertParamExists(
        'postFleetSimulator',
        'iStartSimulationPostRequestST',
        iStartSimulationPostRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iStartSimulationPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FleetSimulationApi - functional programming interface
 * @export
 */
export const FleetSimulationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FleetSimulationApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns the status of the fleet.
     * @summary Returns the status of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetSimulator(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFleetStatusResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetSimulator(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the drone versions of the fleet.
     * @summary Returns the drone versions of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetSimulatorFleetVersion(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFleetVersionResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetSimulatorFleetVersion(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the summary of the status of the fleet.
     * @summary Returns the summary of the status of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetSimulatorStatusSummary(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFleetStatusSummaryResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetSimulatorStatusSummary(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the status of the flight domain.
     * @summary Returns the status of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSafetyManagerSimulator(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFlightDomainStatusResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSafetyManagerSimulator(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Starts the flight simulation.
     * @summary Starts the flight simulation.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IStartSimulationPostRequestST} iStartSimulationPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postFleetSimulator(
      systemIdStr: string,
      flightDomainId: string,
      iStartSimulationPostRequestST: IStartSimulationPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postFleetSimulator(
        systemIdStr,
        flightDomainId,
        iStartSimulationPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FleetSimulationApi - factory interface
 * @export
 */
export const FleetSimulationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FleetSimulationApiFp(configuration);
  return {
    /**
     * Returns the status of the fleet.
     * @summary Returns the status of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulator(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFleetStatusResponseST> {
      return localVarFp
        .getFleetSimulator(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the drone versions of the fleet.
     * @summary Returns the drone versions of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulatorFleetVersion(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFleetVersionResponseST> {
      return localVarFp
        .getFleetSimulatorFleetVersion(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the summary of the status of the fleet.
     * @summary Returns the summary of the status of the fleet.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulatorStatusSummary(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFleetStatusSummaryResponseST> {
      return localVarFp
        .getFleetSimulatorStatusSummary(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the status of the flight domain.
     * @summary Returns the status of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSafetyManagerSimulator(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFlightDomainStatusResponseST> {
      return localVarFp
        .getSafetyManagerSimulator(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Starts the flight simulation.
     * @summary Starts the flight simulation.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IStartSimulationPostRequestST} iStartSimulationPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFleetSimulator(
      systemIdStr: string,
      flightDomainId: string,
      iStartSimulationPostRequestST: IStartSimulationPostRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postFleetSimulator(systemIdStr, flightDomainId, iStartSimulationPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FleetSimulationApi - object-oriented interface
 * @export
 * @class FleetSimulationApi
 * @extends {BaseAPI}
 */
export class FleetSimulationApi extends BaseAPI {
  /**
   * Returns the status of the fleet.
   * @summary Returns the status of the fleet.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FleetSimulationApi
   */
  public getFleetSimulator(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FleetSimulationApiFp(this.configuration)
      .getFleetSimulator(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the drone versions of the fleet.
   * @summary Returns the drone versions of the fleet.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FleetSimulationApi
   */
  public getFleetSimulatorFleetVersion(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FleetSimulationApiFp(this.configuration)
      .getFleetSimulatorFleetVersion(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the summary of the status of the fleet.
   * @summary Returns the summary of the status of the fleet.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FleetSimulationApi
   */
  public getFleetSimulatorStatusSummary(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FleetSimulationApiFp(this.configuration)
      .getFleetSimulatorStatusSummary(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the status of the flight domain.
   * @summary Returns the status of the flight domain.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FleetSimulationApi
   */
  public getSafetyManagerSimulator(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FleetSimulationApiFp(this.configuration)
      .getSafetyManagerSimulator(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts the flight simulation.
   * @summary Starts the flight simulation.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IStartSimulationPostRequestST} iStartSimulationPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FleetSimulationApi
   */
  public postFleetSimulator(
    systemIdStr: string,
    flightDomainId: string,
    iStartSimulationPostRequestST: IStartSimulationPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return FleetSimulationApiFp(this.configuration)
      .postFleetSimulator(systemIdStr, flightDomainId, iStartSimulationPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NoFlyZoneApi - axios parameter creator
 * @export
 */
export const NoFlyZoneApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Updates the active flag of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZoneActivatePutRequestST} iNoFlyZoneActivatePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateFleetSimulatorNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZoneActivatePutRequestST: INoFlyZoneActivatePutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('activateFleetSimulatorNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('activateFleetSimulatorNoFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'iNoFlyZoneActivatePutRequestST' is not null or undefined
      assertParamExists(
        'activateFleetSimulatorNoFlyZone',
        'iNoFlyZoneActivatePutRequestST',
        iNoFlyZoneActivatePutRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/no-fly-zone/activate`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZoneActivatePutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFleetSimulatorNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createFleetSimulatorNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('createFleetSimulatorNoFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'iNoFlyZonePostRequestST' is not null or undefined
      assertParamExists(
        'createFleetSimulatorNoFlyZone',
        'iNoFlyZonePostRequestST',
        iNoFlyZonePostRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/no-fly-zone`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZonePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleted a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZoneDeleteRequestST} iNoFlyZoneDeleteRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFleetSimulatorNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZoneDeleteRequestST: INoFlyZoneDeleteRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteFleetSimulatorNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('deleteFleetSimulatorNoFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'iNoFlyZoneDeleteRequestST' is not null or undefined
      assertParamExists(
        'deleteFleetSimulatorNoFlyZone',
        'iNoFlyZoneDeleteRequestST',
        iNoFlyZoneDeleteRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/no-fly-zone`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZoneDeleteRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns the summary of the status of the no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulatorNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFleetSimulatorNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFleetSimulatorNoFlyZone', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/no-fly-zone`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the active flag of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePutRequestST} iNoFlyZonePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFleetSimulatorNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePutRequestST: INoFlyZonePutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateFleetSimulatorNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateFleetSimulatorNoFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'iNoFlyZonePutRequestST' is not null or undefined
      assertParamExists(
        'updateFleetSimulatorNoFlyZone',
        'iNoFlyZonePutRequestST',
        iNoFlyZonePutRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/no-fly-zone`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZonePutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates the properties of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePropertiesPutRequestST} iNoFlyZonePropertiesPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFleetSimulatorNoFlyZoneProperties: async (
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePropertiesPutRequestST: INoFlyZonePropertiesPutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateFleetSimulatorNoFlyZoneProperties', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'updateFleetSimulatorNoFlyZoneProperties',
        'flightDomainId',
        flightDomainId,
      );
      // verify required parameter 'iNoFlyZonePropertiesPutRequestST' is not null or undefined
      assertParamExists(
        'updateFleetSimulatorNoFlyZoneProperties',
        'iNoFlyZonePropertiesPutRequestST',
        iNoFlyZonePropertiesPutRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/fleet-simulator/no-fly-zone/properties`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZonePropertiesPutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NoFlyZoneApi - functional programming interface
 * @export
 */
export const NoFlyZoneApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NoFlyZoneApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Updates the active flag of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZoneActivatePutRequestST} iNoFlyZoneActivatePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZoneActivatePutRequestST: INoFlyZoneActivatePutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activateFleetSimulatorNoFlyZone(
        systemIdStr,
        flightDomainId,
        iNoFlyZoneActivatePutRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Creates a new no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFleetSimulatorNoFlyZone(
        systemIdStr,
        flightDomainId,
        iNoFlyZonePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deleted a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZoneDeleteRequestST} iNoFlyZoneDeleteRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZoneDeleteRequestST: INoFlyZoneDeleteRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFleetSimulatorNoFlyZone(
        systemIdStr,
        flightDomainId,
        iNoFlyZoneDeleteRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Returns the summary of the status of the no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INoFlyZonesResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetSimulatorNoFlyZone(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates the active flag of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePutRequestST} iNoFlyZonePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePutRequestST: INoFlyZonePutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFleetSimulatorNoFlyZone(
        systemIdStr,
        flightDomainId,
        iNoFlyZonePutRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Updates the properties of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePropertiesPutRequestST} iNoFlyZonePropertiesPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFleetSimulatorNoFlyZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePropertiesPutRequestST: INoFlyZonePropertiesPutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateFleetSimulatorNoFlyZoneProperties(
          systemIdStr,
          flightDomainId,
          iNoFlyZonePropertiesPutRequestST,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NoFlyZoneApi - factory interface
 * @export
 */
export const NoFlyZoneApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NoFlyZoneApiFp(configuration);
  return {
    /**
     *
     * @summary Updates the active flag of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZoneActivatePutRequestST} iNoFlyZoneActivatePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZoneActivatePutRequestST: INoFlyZoneActivatePutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .activateFleetSimulatorNoFlyZone(
          systemIdStr,
          flightDomainId,
          iNoFlyZoneActivatePutRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createFleetSimulatorNoFlyZone(
          systemIdStr,
          flightDomainId,
          iNoFlyZonePostRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleted a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZoneDeleteRequestST} iNoFlyZoneDeleteRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZoneDeleteRequestST: INoFlyZoneDeleteRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFleetSimulatorNoFlyZone(
          systemIdStr,
          flightDomainId,
          iNoFlyZoneDeleteRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns the summary of the status of the no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<INoFlyZonesResponseST> {
      return localVarFp
        .getFleetSimulatorNoFlyZone(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the active flag of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePutRequestST} iNoFlyZonePutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFleetSimulatorNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePutRequestST: INoFlyZonePutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateFleetSimulatorNoFlyZone(systemIdStr, flightDomainId, iNoFlyZonePutRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the properties of a no-fly-zone
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePropertiesPutRequestST} iNoFlyZonePropertiesPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFleetSimulatorNoFlyZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePropertiesPutRequestST: INoFlyZonePropertiesPutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateFleetSimulatorNoFlyZoneProperties(
          systemIdStr,
          flightDomainId,
          iNoFlyZonePropertiesPutRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NoFlyZoneApi - object-oriented interface
 * @export
 * @class NoFlyZoneApi
 * @extends {BaseAPI}
 */
export class NoFlyZoneApi extends BaseAPI {
  /**
   *
   * @summary Updates the active flag of a no-fly-zone
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {INoFlyZoneActivatePutRequestST} iNoFlyZoneActivatePutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZoneApi
   */
  public activateFleetSimulatorNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    iNoFlyZoneActivatePutRequestST: INoFlyZoneActivatePutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZoneApiFp(this.configuration)
      .activateFleetSimulatorNoFlyZone(
        systemIdStr,
        flightDomainId,
        iNoFlyZoneActivatePutRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new no-fly zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZoneApi
   */
  public createFleetSimulatorNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZoneApiFp(this.configuration)
      .createFleetSimulatorNoFlyZone(systemIdStr, flightDomainId, iNoFlyZonePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleted a no-fly-zone
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {INoFlyZoneDeleteRequestST} iNoFlyZoneDeleteRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZoneApi
   */
  public deleteFleetSimulatorNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    iNoFlyZoneDeleteRequestST: INoFlyZoneDeleteRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZoneApiFp(this.configuration)
      .deleteFleetSimulatorNoFlyZone(
        systemIdStr,
        flightDomainId,
        iNoFlyZoneDeleteRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns the summary of the status of the no-fly zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZoneApi
   */
  public getFleetSimulatorNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZoneApiFp(this.configuration)
      .getFleetSimulatorNoFlyZone(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the active flag of a no-fly-zone
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {INoFlyZonePutRequestST} iNoFlyZonePutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZoneApi
   */
  public updateFleetSimulatorNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    iNoFlyZonePutRequestST: INoFlyZonePutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZoneApiFp(this.configuration)
      .updateFleetSimulatorNoFlyZone(systemIdStr, flightDomainId, iNoFlyZonePutRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the properties of a no-fly-zone
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {INoFlyZonePropertiesPutRequestST} iNoFlyZonePropertiesPutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZoneApi
   */
  public updateFleetSimulatorNoFlyZoneProperties(
    systemIdStr: string,
    flightDomainId: string,
    iNoFlyZonePropertiesPutRequestST: INoFlyZonePropertiesPutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZoneApiFp(this.configuration)
      .updateFleetSimulatorNoFlyZoneProperties(
        systemIdStr,
        flightDomainId,
        iNoFlyZonePropertiesPutRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OperationsSimulatorApi - axios parameter creator
 * @export
 */
export const OperationsSimulatorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Creates a new estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {ICreateEstimatedObstacleRequestST} iCreateEstimatedObstacleRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperationsSimulatorEstimatedObstacles: async (
      systemIdStr: string,
      flightDomainId: string,
      iCreateEstimatedObstacleRequestST: ICreateEstimatedObstacleRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createOperationsSimulatorEstimatedObstacles', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'createOperationsSimulatorEstimatedObstacles',
        'flightDomainId',
        flightDomainId,
      );
      // verify required parameter 'iCreateEstimatedObstacleRequestST' is not null or undefined
      assertParamExists(
        'createOperationsSimulatorEstimatedObstacles',
        'iCreateEstimatedObstacleRequestST',
        iCreateEstimatedObstacleRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/operations-simulator/estimated-obstacles`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iCreateEstimatedObstacleRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {ICreateSpaceReservationRequestST} iCreateSpaceReservationRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperationsSimulatorSpaceReservation: async (
      systemIdStr: string,
      flightDomainId: string,
      iCreateSpaceReservationRequestST: ICreateSpaceReservationRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createOperationsSimulatorSpaceReservation', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'createOperationsSimulatorSpaceReservation',
        'flightDomainId',
        flightDomainId,
      );
      // verify required parameter 'iCreateSpaceReservationRequestST' is not null or undefined
      assertParamExists(
        'createOperationsSimulatorSpaceReservation',
        'iCreateSpaceReservationRequestST',
        iCreateSpaceReservationRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/operations-simulator/space-reservations`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iCreateSpaceReservationRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleted all estimated obstacles
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperationsSimulatorEstimatedObstacles: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteOperationsSimulatorEstimatedObstacles', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'deleteOperationsSimulatorEstimatedObstacles',
        'flightDomainId',
        flightDomainId,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/operations-simulator/estimated-obstacles`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleted all space reservations
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperationsSimulatorSpaceReservation: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteOperationsSimulatorSpaceReservation', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'deleteOperationsSimulatorSpaceReservation',
        'flightDomainId',
        flightDomainId,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/operations-simulator/space-reservations`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the simulated estimated obstacles.
     * @summary Returns the simulated estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationsSimulatorEstimatedObstacles: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getOperationsSimulatorEstimatedObstacles', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'getOperationsSimulatorEstimatedObstacles',
        'flightDomainId',
        flightDomainId,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/operations-simulator/estimated-obstacles`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the simulated Space Reservations.
     * @summary Returns the simulated Space Reservations.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationsSimulatorSpaceReservations: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getOperationsSimulatorSpaceReservations', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists(
        'getOperationsSimulatorSpaceReservations',
        'flightDomainId',
        flightDomainId,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/mockups/operations-simulator/space-reservations`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperationsSimulatorApi - functional programming interface
 * @export
 */
export const OperationsSimulatorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OperationsSimulatorApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Creates a new estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {ICreateEstimatedObstacleRequestST} iCreateEstimatedObstacleRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOperationsSimulatorEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      iCreateEstimatedObstacleRequestST: ICreateEstimatedObstacleRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOperationsSimulatorEstimatedObstacles(
          systemIdStr,
          flightDomainId,
          iCreateEstimatedObstacleRequestST,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Creates a new estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {ICreateSpaceReservationRequestST} iCreateSpaceReservationRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOperationsSimulatorSpaceReservation(
      systemIdStr: string,
      flightDomainId: string,
      iCreateSpaceReservationRequestST: ICreateSpaceReservationRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createOperationsSimulatorSpaceReservation(
          systemIdStr,
          flightDomainId,
          iCreateSpaceReservationRequestST,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deleted all estimated obstacles
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOperationsSimulatorEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOperationsSimulatorEstimatedObstacles(
          systemIdStr,
          flightDomainId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deleted all space reservations
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOperationsSimulatorSpaceReservation(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOperationsSimulatorSpaceReservation(
          systemIdStr,
          flightDomainId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the simulated estimated obstacles.
     * @summary Returns the simulated estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOperationsSimulatorEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetEstimatedObstaclesResponseST>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOperationsSimulatorEstimatedObstacles(
          systemIdStr,
          flightDomainId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the simulated Space Reservations.
     * @summary Returns the simulated Space Reservations.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOperationsSimulatorSpaceReservations(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetSpaceReservationsResponseST>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOperationsSimulatorSpaceReservations(
          systemIdStr,
          flightDomainId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OperationsSimulatorApi - factory interface
 * @export
 */
export const OperationsSimulatorApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OperationsSimulatorApiFp(configuration);
  return {
    /**
     *
     * @summary Creates a new estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {ICreateEstimatedObstacleRequestST} iCreateEstimatedObstacleRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperationsSimulatorEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      iCreateEstimatedObstacleRequestST: ICreateEstimatedObstacleRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createOperationsSimulatorEstimatedObstacles(
          systemIdStr,
          flightDomainId,
          iCreateEstimatedObstacleRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {ICreateSpaceReservationRequestST} iCreateSpaceReservationRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperationsSimulatorSpaceReservation(
      systemIdStr: string,
      flightDomainId: string,
      iCreateSpaceReservationRequestST: ICreateSpaceReservationRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createOperationsSimulatorSpaceReservation(
          systemIdStr,
          flightDomainId,
          iCreateSpaceReservationRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleted all estimated obstacles
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperationsSimulatorEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteOperationsSimulatorEstimatedObstacles(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleted all space reservations
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperationsSimulatorSpaceReservation(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteOperationsSimulatorSpaceReservation(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the simulated estimated obstacles.
     * @summary Returns the simulated estimated obstacles.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationsSimulatorEstimatedObstacles(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IGetEstimatedObstaclesResponseST> {
      return localVarFp
        .getOperationsSimulatorEstimatedObstacles(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the simulated Space Reservations.
     * @summary Returns the simulated Space Reservations.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperationsSimulatorSpaceReservations(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IGetSpaceReservationsResponseST> {
      return localVarFp
        .getOperationsSimulatorSpaceReservations(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OperationsSimulatorApi - object-oriented interface
 * @export
 * @class OperationsSimulatorApi
 * @extends {BaseAPI}
 */
export class OperationsSimulatorApi extends BaseAPI {
  /**
   *
   * @summary Creates a new estimated obstacles.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {ICreateEstimatedObstacleRequestST} iCreateEstimatedObstacleRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperationsSimulatorApi
   */
  public createOperationsSimulatorEstimatedObstacles(
    systemIdStr: string,
    flightDomainId: string,
    iCreateEstimatedObstacleRequestST: ICreateEstimatedObstacleRequestST,
    options?: AxiosRequestConfig,
  ) {
    return OperationsSimulatorApiFp(this.configuration)
      .createOperationsSimulatorEstimatedObstacles(
        systemIdStr,
        flightDomainId,
        iCreateEstimatedObstacleRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new estimated obstacles.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {ICreateSpaceReservationRequestST} iCreateSpaceReservationRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperationsSimulatorApi
   */
  public createOperationsSimulatorSpaceReservation(
    systemIdStr: string,
    flightDomainId: string,
    iCreateSpaceReservationRequestST: ICreateSpaceReservationRequestST,
    options?: AxiosRequestConfig,
  ) {
    return OperationsSimulatorApiFp(this.configuration)
      .createOperationsSimulatorSpaceReservation(
        systemIdStr,
        flightDomainId,
        iCreateSpaceReservationRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleted all estimated obstacles
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperationsSimulatorApi
   */
  public deleteOperationsSimulatorEstimatedObstacles(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return OperationsSimulatorApiFp(this.configuration)
      .deleteOperationsSimulatorEstimatedObstacles(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleted all space reservations
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperationsSimulatorApi
   */
  public deleteOperationsSimulatorSpaceReservation(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return OperationsSimulatorApiFp(this.configuration)
      .deleteOperationsSimulatorSpaceReservation(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the simulated estimated obstacles.
   * @summary Returns the simulated estimated obstacles.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperationsSimulatorApi
   */
  public getOperationsSimulatorEstimatedObstacles(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return OperationsSimulatorApiFp(this.configuration)
      .getOperationsSimulatorEstimatedObstacles(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the simulated Space Reservations.
   * @summary Returns the simulated Space Reservations.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperationsSimulatorApi
   */
  public getOperationsSimulatorSpaceReservations(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return OperationsSimulatorApiFp(this.configuration)
      .getOperationsSimulatorSpaceReservations(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
