import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LocalStore } from 'common/functions/storageFunctions';
import { useFacilityLevelStore } from '../store/FacilityLevelStore/facilityLevelStore';
import { useGroundControlStore } from '../store/GroundControl/groundControlLevelStore';

import { useRequestController } from '.';

const logPrefix = getLogPrefixForType('STORE', 'useLoadGroundControlLevelData');

export const useLoadGroundControlLevelData = () => {
  const { systemId } = useParams();
  // extract flight domain id from url
  const { flightDomainId } = useParams();
  console.debug(logPrefix, `flightDomainIdFrom >> URL: ${flightDomainId}`);
  LocalStore.setFlightDomainId(flightDomainId || '');
  const flightDomainIdFromURL = LocalStore.getFlightDomainId();
  console.debug(logPrefix, `flightDomainIdFrom >> LS: ${flightDomainIdFromURL}`);

  const mountedRef = useRef(false);

  const { stateGroundControl, refreshFlightDomainData } = useGroundControlStore();
  const { stateFacilityLevel } = useFacilityLevelStore();

  const navigate = useNavigate();

  const { requestController } = useRequestController(logPrefix);

  /**
   * Check if the flightDomain ID from the URL
   * match any ids from flightDomains list
   * @param flightDomainId
   * @returns {boolean}
   */
  const isValidFlightDomainId = useCallback(
    (flightDomainId: string | null) =>
      stateFacilityLevel.flightDomains
        .map((f) => f.flight_domain_id)
        .includes(flightDomainIdFromURL!),
    [flightDomainIdFromURL, stateFacilityLevel.flightDomains],
  );

  /**
   * Async function that removes old and gets current flight domain data
   */
  const refreshFlightDomain = useCallback(
    async (systemId: string) => {
      const lp = getLogPrefixForType('FUNCTION', 'refreshFlightDomain');
      console.debug(lp, `Getting flight domain for id: ${flightDomainIdFromURL}`);
      if (!flightDomainIdFromURL) {
        console.debug(lp, `No flight domain was found with an id: ${flightDomainIdFromURL}`);
        return;
      }

      refreshFlightDomainData(requestController, systemId, flightDomainIdFromURL!);
    },
    [flightDomainIdFromURL, refreshFlightDomainData, requestController],
  );

  useEffect(() => {
    const lp = getLogPrefixForType('HOOK', 'useEffect-@-useLoadGroundControlLevelData');
    console.debug(lp, `Running data synchronization for: ${flightDomainIdFromURL}`);
    if (mountedRef.current && !stateFacilityLevel.flightDomainsLoaded) {
      console.debug(lp, 'No flight domains loaded yet, skipping data synchronization');
      return;
    }
    mountedRef.current = true;

    if (!isValidFlightDomainId(flightDomainIdFromURL!)) {
      console.debug(
        lp,
        `FlightDomainId "${flightDomainIdFromURL}" from URL doesn't match any ids from the facilityLevel list`,
      );
      navigate('/select-facility');
      return;
    }

    if (flightDomainIdFromURL) {
      console.debug(lp, `Loading data for: ${flightDomainIdFromURL}`);
      refreshFlightDomain(systemId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flightDomainIdFromURL,
    stateFacilityLevel.flightDomainsLoaded,
    stateGroundControl.flightDomain.flight_domain_id,
  ]);

  return { flightDomainId: flightDomainIdFromURL };
};
