import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  link: {
    display: 'inline-block',
    wordBreak: 'break-all',
    overflowWrap: 'anywhere',
  },
  icon: {
    fontSize: '1.2em',
    lineHeight: 1,
    marginInlineEnd: '0.4em',
    verticalAlign: 'text-top',
  },
  fallback: {
    margin: 0,
    fontStyle: 'italic',
  },
}));
