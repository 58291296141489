import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { barcodeStatusEnum } from '../../../components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';

export const matchBarcode = (
  expectedBarcode: string,
  foundBarcode: string,
  matchBarcode: boolean,
) => {
  if (matchBarcode) {
    return barcodeStatusEnum.Match;
  }
  if (expectedBarcode === 'Empty location' && foundBarcode !== 'Empty location') {
    return barcodeStatusEnum.Unexpected;
  }
  if (foundBarcode !== 'No match' && expectedBarcode === 'No match') {
    return barcodeStatusEnum.Unexpected;
  }
  return barcodeStatusEnum.Missing;
};

export const filterOutNoMatch = (barcodes: string[]) =>
  barcodes.filter((barcode) => barcode !== 'No match');

export const matchBarcodes = ({ expected, found }: { expected?: string[]; found?: string[] }) => ({
  missing: found ? difference(expected, found) : expected || [],
  unexpected: expected ? difference(found, expected) : found || [],
  match: found && expected ? intersection(expected, found) : [],
});
