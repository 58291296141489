import { memo, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { useCursor } from '@react-three/drei';
import { bottomLeftDimensionsToSizeAndPos } from 'shared/map-container/utils/creation.util';
import { useMeshOpacity } from 'shared/map/features/drone-zone-base-mesh/hooks/use-mesh-opacity/use-mesh-opacity';
import { DroneZoneBaseMesh } from 'shared/map/features/drone-zone-base-mesh/drone-zone-base-mesh';
import { ZoneBaseMeshSharedProps } from 'shared/map/features/drone-zone-base-mesh/drone-zone-base-mesh.model';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { MutableState } from 'shared/map-container/reducer/MutableState';
import { validatePositionToFacilityBoundaries } from 'shared/map/features/drone-zone-base-mesh/utils/validatePositionToFacilityBoundaries';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { generateSolidMaterial } from 'shared/map/utils/material-generators/generate-solid-material';
import { useRenderOrder } from './hooks/useRenderOrder';

export const NoFlyZoneMesh = memo(
  ({
    droneZone,
    options,
    isMapOverlayVisible,
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    ...props
  }: Omit<ZoneBaseMeshSharedProps, 'onClick'> & {
    droneZone: NoFlyZone;
    isMapOverlayVisible: boolean;
    onClick: (droneZone: NoFlyZone) => void;
    onDrag?: () => void;
    onDragStart?: () => void;
    onDragEnd?: (droneZone: NoFlyZone) => void;
  }) => {
    const ref = useRef<THREE.Mesh>(null);

    const [isHovering, setIsHovering] = useState(false);

    const { cameraState } = MutableState.getState();
    const { mapState } = useMapStore();

    const { minX, minY, maxX, maxY } = mapFacilityVectors(mapState.map?.box);

    const fov = cameraState.currentOrthographicFOV;
    const scale = fov * 0.0032; // Magic number - required for mapping px mouse movement to the map metre position.
    const { isEditing } = droneZone;
    const { w, l, h, positionVector } = bottomLeftDimensionsToSizeAndPos(droneZone.sizeAndPosition);
    const { isSelected, isActive } = droneZone;
    const { opacity } = useMeshOpacity({ isSelected, isActive, isHovering });
    const { renderOrder } = useRenderOrder({ isSelected, isCreating: isMapOverlayVisible });

    const material = generateSolidMaterial({
      opacity,
      primaryColor: isActive
        ? mapStyle.noFlyZone.active.backgroundColor.primary
        : mapStyle.noFlyZone.inactive.backgroundColor.primary,
      includeBorder: true,
    });

    const textColor = isActive
      ? mapStyle.noFlyZone.active.textColor.primary
      : mapStyle.noFlyZone.inactive.textColor.primary;

    const enableCursor = !!(isHovering && (!isMapOverlayVisible || isEditing));
    useCursor(enableCursor, isEditing ? 'grab' : 'pointer');

    const bind = useGesture(
      {
        onPointerOver: () => {
          setIsHovering(true);
        },

        onPointerOut: () => {
          setIsHovering(false);
        },

        onClick: ({ event }) => {
          if (isEditing) {
            return;
          }

          event.stopPropagation();

          onClick(droneZone);
        },

        onDrag: ({ event, offset: [offsetX, offsetY] }) => {
          if (!isEditing) {
            return;
          }

          if (ref.current) {
            event.stopPropagation();

            if (onDrag) {
              // Currently used to disable the floor dragging to pan the camera.
              onDrag();
            }

            const posX = offsetX * scale;
            const posY = -offsetY * scale;

            ref.current.position.x = validatePositionToFacilityBoundaries({
              vector: posX + positionVector.x,
              offset: droneZone.sizeAndPosition.w / 2,
              boundaryMin: minX,
              boundaryMax: maxX,
            });

            ref.current.position.y = validatePositionToFacilityBoundaries({
              vector: posY + positionVector.y,
              offset: droneZone.sizeAndPosition.l / 2,
              boundaryMin: minY,
              boundaryMax: maxY,
            });
          }
        },

        onDragStart: () => {
          if (!isEditing) {
            return;
          }

          if (onDragStart) {
            onDragStart();
          }
        },

        onDragEnd: () => {
          if (!isEditing) {
            return;
          }

          if (ref.current && onDragEnd) {
            onDragEnd({
              ...droneZone,
              sizeAndPosition: {
                ...droneZone.sizeAndPosition,
                minX: ref.current.position.x - droneZone.sizeAndPosition.w / 2,
                minY: ref.current.position.y - droneZone.sizeAndPosition.l / 2,
              },
            });
          }
        },
      },
      {
        drag: {
          from: [0, 0],
        },
      },
    );

    return (
      // @ts-expect-error event types
      <DroneZoneBaseMesh
        ref={ref}
        renderOrder={renderOrder}
        key={droneZone.id}
        label={droneZone.name}
        options={options}
        material={material}
        position={positionVector}
        textColors={{ text: textColor }}
        width={w}
        length={l}
        height={h}
        {...bind()}
        {...props}
      />
    );
  },
);
