import moment from 'moment';
import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import { TIME_FIRST_DATETIME_FORMAT } from 'common/datetimeFormats';

export const formatTimeLocationHistory = (timestamp: string): string => {
  const parsedDate = moment(timestamp);

  const daysDifference = moment().diff(parsedDate, 'days');

  const timeDifference: string = moment(parsedDate).fromNow();

  if (daysDifference > 3) {
    return formatDateInTimezone(timestamp, TIME_FIRST_DATETIME_FORMAT);
  }

  const formattedTime = parsedDate.format('HH:mm');
  return `${formattedTime} - ${timeDifference}`;
};
