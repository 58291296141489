import { Button, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { BackDropLoader } from 'components/BackDropLoader';
import { DATETIME_FORMAT } from 'common/datetimeFormats';
import { Box } from 'components/common/Box';
import { ActionsAlert } from 'components/common/ActionsAlert';
import { LocalStore } from 'common/functions/storageFunctions';
import { SCHEDULE_LOCK_STATES } from '../../ScheduleLock';
import { useSetScheduleLock } from './api/useSetScheduleLockAPI';
import { ScheduleLockDateTimePicker } from './features/scheduleLockDateTimePicker/ScheduleLockDateTimePicker';

interface ScheduleLockCreationProps {
  onClick: (param: string) => void;
}

export const ScheduleLockCreation = ({ onClick }: ScheduleLockCreationProps) => {
  const timezone = LocalStore.getTimezone();

  const { setScheduleLock } = useSetScheduleLock();

  const [lockDateTime, setLockDateTime] = useState<Moment | null>(null);
  const [error, setError] = useState<string | null>('');
  const [errorMessage, setErrorResponse] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = () =>
    setScheduleLock({
      scheduleLock: { lock_at: moment(lockDateTime).tz(timezone).format(DATETIME_FORMAT) },
      setLoading: setIsLoading,
      setError: setErrorResponse,
    });

  return (
    <>
      <BackDropLoader isVisible={isLoading} />
      <div data-testid="c-schedule-lock-card-content">
        <Box mb={2}>
          <ActionsAlert
            isActive={!!errorMessage}
            message={errorMessage}
            handleClose={() => setErrorResponse('')}
            status="warning"
            handleClick={handleSubmit}
            handleClickLabel="Retry"
          />
        </Box>

        <ScheduleLockDateTimePicker
          onChange={setLockDateTime}
          onError={setError}
          error={error}
          lockDateTime={lockDateTime}
        />

        <Typography mt={2} color="textSecondary">
          Drones will stop flying at the time of the scheduled lock.
        </Typography>
      </div>

      <div style={{ marginTop: '24px', display: 'flex', gap: '16px' }}>
        <Button
          data-testid="c-add-schedule-lock-button"
          variant="outlined"
          color="primary"
          size="medium"
          fullWidth
          onClick={() => onClick(SCHEDULE_LOCK_STATES.empty)}
        >
          CANCEL
        </Button>

        <Button
          data-testid="c-add-schedule-lock-button"
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          disabled={!lockDateTime || !!error}
          onClick={handleSubmit}
        >
          SET
        </Button>
      </div>
    </>
  );
};
