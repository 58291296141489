/* eslint-disable react-hooks/exhaustive-deps */
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { LOD } from '../../reducer/3DmapState';
import { MutableState } from '../../reducer/MutableState';
import { FacilityManager } from '../facility-manager/FacilityManager';

const logPrefix: string = getLogPrefixForType('COMPONENT', '3D Facility');

/**
 * A null component used to expose scene to FacilityManager.
 * @param props facility manager and LOD
 * @returns null
 */
export const Facility = (props: { facilityManager: FacilityManager; lod: LOD }) => {
  const { lod, facilityManager } = props;
  const mutableState = MutableState.getState();
  const { scene } = useThree();
  const LOD = useRef(lod);

  useEffect(() => {
    // set up objects and add them to scene
    facilityManager.update3DObjects();
    facilityManager.setScene(scene);
    console.debug(`${logPrefix} Mounted - scene set`);
    return () => {
      scene.clear();
      console.debug(`${logPrefix} Unmounted - facility manager destroyed`);
    };
  }, []);

  useFrame(() => {
    if (LOD.current !== mutableState.cameraState.lod) {
      LOD.current = mutableState.cameraState.lod;
      facilityManager.updateLOD(LOD.current);
    }
  });

  return null;
};
