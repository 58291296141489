import { Vector3 } from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { NoFlyZone, NoFlyZones } from 'shared/map/model/no-fly-zone.model';
import { OpenFlyZone } from 'shared/map/model/open-fly-zone.model';
import { MapOptions } from 'shared/map-container/reducer/MapOptionsState';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { DroneZoneMapOverlay } from '../../drone-zones/features/Map/DroneZoneMapOverlay';
import { NoFlyZoneMesh } from '../../drone-zones/features/Map/components/noFlyZoneMesh/noFlyZoneMesh';
import { NoFlyZoneDrawer } from '../../drone-zones/features/Map/NoFlyZoneDrawer';

export const NoFlyZonesMapElements = ({
  noFlyZoneDrawPositionStart,
  noFlyZones,
  worldBox,
  onMapOverlayPointerEnter,
  onMapOverlayPointerLeave,
  isMapOverlayVisible,
  onNoFlyZoneDragStart,
  onNoFlyZoneDragEnd,
  onNoFlyZoneClick,
  options,
}: {
  noFlyZones: NoFlyZones;
  worldBox: Vec6;
  onNoFlyZoneDragStart: () => void;
  onNoFlyZoneDragEnd: (zone: NoFlyZone) => void;
  onNoFlyZoneClick: (zone: NoFlyZone | ControlledZone | OpenFlyZone) => void;
  options: MapOptions;
  noFlyZoneDrawPositionStart?: Vector3;
  onMapOverlayPointerEnter?: (e: ThreeEvent<PointerEvent>) => void;
  onMapOverlayPointerLeave?: (e: ThreeEvent<PointerEvent>) => void;
  isMapOverlayVisible: boolean;
}) => (
  <>
    {isMapOverlayVisible && (
      <DroneZoneMapOverlay
        worldBox={worldBox}
        onPointerEnter={onMapOverlayPointerEnter}
        onPointerLeave={onMapOverlayPointerLeave}
      />
    )}

    {Object.values(noFlyZones).map((zone) => (
      <NoFlyZoneMesh
        key={zone.id}
        droneZone={zone}
        options={options}
        onClick={() => onNoFlyZoneClick(zone)}
        onDrag={onNoFlyZoneDragStart}
        onDragEnd={onNoFlyZoneDragEnd}
        onDragStart={onNoFlyZoneDragStart}
        isMapOverlayVisible={isMapOverlayVisible}
      />
    ))}

    {noFlyZoneDrawPositionStart && (
      <NoFlyZoneDrawer
        key="nfz-creator"
        drawPositionStart={noFlyZoneDrawPositionStart}
        worldBox={worldBox}
      />
    )}
  </>
);
