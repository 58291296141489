import { ToggleAllAction } from '../controlledZoneToggleAll.model';

export const ACTION_STATE = {
  [ToggleAllAction.activate]: {
    action: 'Activation',
    verb: 'activated',
    buttonLabel: 'Activate all',
  },
  [ToggleAllAction.deactivate]: {
    action: 'Deactivation',
    verb: 'deactivated',
    buttonLabel: 'Deactivate all',
  },
};
