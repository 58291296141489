import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import RepeatIcon from '@mui/icons-material/Repeat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { REQUEST_STATES } from 'common/requestStates';
import { REPORT_STATES } from 'common/reportStates';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { Box } from 'components/common/Box';
import TooltipedIcon from 'components/common/TooltipedIcon';
import ActionsMenu, { ActionMenuOptions } from 'components/common/ActionsMenu';

import { reportItemStyle } from './styles';

interface IBaseCardReportItemProps {
  reportId: string;
  reportType: string;
  reportName: string;
  date: string;
  recurrence: boolean;
  reportStatus: string;
  completion?: number;
  totalLocations: string;
  totalIssues?: string;
  showBottomDivider: boolean;
  allowRedirect?: boolean;
  /**
   * Defines buttons to be rendered. Example:
   * {
   *    variant: "contained",
   *    label: "See all issues",
   *    clickHandler: () => updateRoute({url: "/warehouse-status", navigate, data})
   * }
   */
  actionButtons?: ActionMenuOptions[];
  recurrenceDescription: string;
  /**
   * If report is "Automatic" than instead of "date" we show label
   */
  label?: string;
}
const BaseCardReportItem = (props: IBaseCardReportItemProps) => {
  const {
    reportId,
    reportType,
    date,
    recurrence = false,
    recurrenceDescription,
    reportStatus,
    reportName,
    completion,
    totalLocations,
    totalIssues,
    label,
    showBottomDivider = false,
    allowRedirect = true,
    actionButtons,
  } = props;

  const { systemId } = useParams();

  const { classes, cx } = reportItemStyle();

  // navigate
  const navigate = useNavigate();
  return (
    <>
      <ListItem
        data-testid={`c-card-item-${reportId}`}
        className={classes.listItem}
        onClick={() => {
          if (allowRedirect) {
            navigate(`/${systemId}${INVENTORY_PAGES_URLS.REPORT}/${reportId}`);
          }
        }}
        button
      >
        <ListItemText
          className={classes.listItemText}
          disableTypography={true}
          primary={
            <Typography
              className={classes.primaryText}
              gutterBottom={false}
              color="textSecondary"
              variant="subtitle2"
            >
              {reportType}
            </Typography>
          }
          secondary={
            <>
              <Typography className={classes.secondaryText} variant="h6">
                {reportName}
              </Typography>
              <Typography variant="body2">{label || date}</Typography>
            </>
          }
        />

        <ListItemSecondaryAction
          data-testid={`c-card-item-action-${reportId}`}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Box
            className={classes.reportStatusSection}
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {recurrence && (
                <TooltipedIcon tooltip={recurrenceDescription} icon={<RepeatIcon />} />
              )}
              {reportStatus &&
                reportStatus !== REPORT_STATES.FINISHED &&
                reportStatus !== REPORT_STATES.ONGOING &&
                reportStatus !== REPORT_STATES.SCHEDULED && (
                  <Typography
                    className={cx(
                      classes.reportStatus,
                      reportStatus === REQUEST_STATES.DISABLED && classes.reportStatusDisabled,
                      actionButtons && classes.reportStatusMarginRight,
                    )}
                  >
                    {reportStatus}
                  </Typography>
                )}
            </Box>
            {!isNil(completion) && (
              <Typography className={classes.progress} variant="h4">
                {Number(100 * completion).toFixed(2)}%
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {!isNil(totalLocations) && (
              <TooltipedIcon
                tooltip="Total locations"
                icon={<LocationOnIcon />}
                text={totalLocations}
              />
            )}
            {!isNil(totalIssues) && (
              <TooltipedIcon
                tooltip="Total issues"
                icon={<ReportProblemIcon />}
                text={totalIssues}
              />
            )}
          </Box>
          {!isEmpty(actionButtons) && <ActionsMenu options={actionButtons!} />}
        </ListItemSecondaryAction>
      </ListItem>
      {showBottomDivider && <Divider />}
    </>
  );
};

export default BaseCardReportItem;
