import { Tooltip, Typography } from '@mui/material';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { matchBarcodes } from 'common/functions/barcodes/matchBarcode.util';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { BarcodeMatchLogic } from 'common/functions/barcodes/getStatusColorChip.util';
import { barcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';
import { isDataStale, getContentCellColor } from './ContentCellFunctions';
import { multipleBarcode } from './multipleBarcode.style';
import { BarcodeLine } from './BarcodeLine';

/**
 * Functional components rendering a Content (found)
 * cell in a location data grid
 * @param props
 * @returns
 */
export const ContentFoundCell = ({
  locationData,
  facilitySettings,
}: {
  locationData: ILocationDataST;
  facilitySettings: IFacilitySettingsST;
}) => {
  const hasIssues = !!locationData.issues?.length;

  const { classes } = multipleBarcode();

  const verityStatus = locationData.verity_status as IVeritySlotStatusST;
  const wmsStatus = locationData.wms_status as IWMSSlotStatusST;

  if (!verityStatus || typeof verityStatus === 'string') {
    return <span className="c-data-grid-cell">{verityStatus}</span>;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  const isStale = isDataStale(locationData);

  let tooltip: string | JSX.Element = '';
  let content: JSX.Element = <>-</>;

  const showOverrides = userOverrides?.length;

  const barcodes = showOverrides
    ? userOverrides![userOverrides!.length - 1].barcodes
    : verityBarcodes;

  const barcodeMatchLogic =
    (facilitySettings.barcode_match_logic_name as BarcodeMatchLogic) || null;

  if (!barcodes.length) {
    tooltip = DISPLAY_VERITY_STATES[verityStatus.state];
    content = (
      <Typography
        variant="body2"
        component="span"
        sx={{ color: getContentCellColor(!!wmsStatus, hasIssues, isStale), cursor: 'default' }}
      >
        {tooltip}
      </Typography>
    );
  } else {
    const { unexpected } = matchBarcodes({
      expected: wmsStatus?.barcodes,
      found: verityStatus?.barcodes,
    });
    tooltip = barcodes.join(', ');
    const list = barcodes.map((barcode) => {
      const status = unexpected.includes(barcode)
        ? barcodeStatusEnum.Unexpected
        : barcodeStatusEnum.Match;
      return (
        <BarcodeLine
          key={barcode}
          barcode={barcode}
          barcodeStatus={status}
          barcodeMatchLogic={barcodeMatchLogic}
        />
      );
    });
    content = <span className={classes.verticalCenter}>{list}</span>;
  }

  if (showOverrides) {
    const lastOverride = userOverrides![userOverrides!.length - 1];
    const overridenValue =
      lastOverride.state === 'BARCODE'
        ? lastOverride.barcodes
        : DISPLAY_VERITY_STATES[lastOverride.state];
    content = (
      <Typography
        variant="body2"
        component="span"
        sx={{ color: getContentCellColor(!!wmsStatus, hasIssues, isStale), cursor: 'default' }}
      >
        {overridenValue} *
      </Typography>
    );
  }

  if (showOverrides) {
    const lastOverride = userOverrides![userOverrides!.length - 1];

    tooltip = (
      <span>
        value overridden: <br />
        by {lastOverride.user_name} <br />
        on {lastOverride.timestamp}. <br />
        Original value:{' '}
        <b>
          {verityBarcodes.length > 0
            ? verityBarcodes.join(' ,')
            : DISPLAY_VERITY_STATES[verityStatus.state]}
        </b>
      </span>
    );
  }

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-content-found">
      {content}
    </Tooltip>
  );
};
