import { TokenManager } from 'common/tokenManager';
import { isSSOUser } from 'features/permissions/isSSOUser';
import { ROLE } from 'features/permissions/role.model';
import { rolesFromUserGroups } from 'features/permissions/roleFromUserGroups';

export const isNewSSOUser = () => {
  const tokenManager = TokenManager.getInstance();
  const userGroups = tokenManager.userGroupsFromAccessToken() || [];
  const roles = rolesFromUserGroups(userGroups);

  return roles.length === 1 && roles[0] === ROLE.UNKNOWN && isSSOUser();
};
