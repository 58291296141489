// functions
import { singleRequestHandler } from '../requestHelpers';

// store
import IssuesStore from '../../store/IssuesStore';

export const openImageInNewTab = (
  systemId: string,
  location: string,
  setImagesDownloading: (download: boolean) => any,
  slotStatusVersion: any,
  isReview: any,
  imgIndex: string | number,
) => {
  singleRequestHandler({
    request: IssuesStore.getImages,
    requestParams: [systemId, location, slotStatusVersion, isReview],
    callbackBeforeSend: () => setImagesDownloading(true),
    callbackSuccess: (r: { data: { urls: any } }) => {
      const locationImageURLs = r.data?.urls ? r.data?.urls : [];
      const imageURL = locationImageURLs[imgIndex];

      // Open image on new tab
      if (imageURL) {
        window.open(imageURL, '_blank');
      }
    },
    callbackFinally: () => setImagesDownloading(false),
    messageErrorFallback: `An error occurred while opening images for ${location}`,
  });
};
