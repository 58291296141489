import isEmpty from 'lodash/isEmpty';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box } from '../../../common/Box';
import Spinner from '../../../common/Spinner';
import TooltipedIcon from '../../../common/TooltipedIcon';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    background: '#fff',
    boxSizing: 'border-box',
  },
  hidden: {
    visibility: 'hidden',
  },
  imageTextBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& > h6': {
      fontSize: '10px',
    },
  },
  downloadButton: {
    display: 'flex',
  },
}));

export const PictureCounter = ({
  imageUrls,
  imagesDownloading,
  handleDownloadImages,
}: {
  imageUrls: string[];
  imagesDownloading: boolean;
  handleDownloadImages: () => void;
}) => {
  const { classes } = useStyles();

  if (!isEmpty(imageUrls)) {
    return (
      <Box className={classes.imageTextBox}>
        <TooltipedIcon
          tooltip="Download location images"
          icon={
            <IconButton onClick={handleDownloadImages} size="small">
              <GetAppIcon className={classes.downloadButton} fontSize="small" />
              {imagesDownloading && (
                <Spinner whiteSpinner={true} transparentBackground={true} size={30} />
              )}
            </IconButton>
          }
        />
        <Typography align="left" fontSize={14} color="#333333">
          Showing {imageUrls.length} picture(s) for this location.
        </Typography>
      </Box>
    );
  }
  return null;
};
