import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import SettingsCard from '../../SettingsCard';
import { BarcodeLengths } from '../../barcodes/BarcodeLengths';
import { getBarcodesOptions } from '../../options/getBarcodesOptions';

export const BarcodeValidation = ({
  isLoading,
  facilitySettings,
  handleValuesChange,
  errors,
  setErrors,
}: {
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: any) => void;
  errors: any;
  setErrors: React.Dispatch<any>;
}) => (
  <Grid item>
    <SettingsCard
      title="Barcodes"
      isLoading={isLoading}
      items={getBarcodesOptions(facilitySettings, handleValuesChange, errors)}
      testId="c-barcodes-settings-card"
      content={
        <BarcodeLengths
          subtitle="Barcode invalid lengths"
          errors={errors.validBarcodeLength}
          setErrors={(error: any) => setErrors({ ...errors, validBarcodeLength: error })}
          min={facilitySettings.barcode_min_length!}
          max={facilitySettings.barcode_max_length!}
          barcodeLengths={
            facilitySettings.barcode_invalid_lengths ? facilitySettings.barcode_invalid_lengths : []
          }
          onBarcodeLengthChanges={(e: any) => {
            handleValuesChange('barcode_invalid_lengths', e);
          }}
        />
      }
    />
  </Grid>
);
