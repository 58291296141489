import { useLocation, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GlobalStyles } from '@mui/material';
import { useEffect } from 'react';
import Spinner from 'components/common/Spinner';
import Copyright from 'components/common/Copyright';
import { Box } from 'components/common/Box';
import { CLIENT_PAGES_URLS } from 'common/pages';
import { TokenManager } from 'common/tokenManager';
import { SignInForm } from '../SignInForm';
import { ArrivedFromOldDomainMessage, DomainSwitchMessage } from '../SignInPageMessages';
import imgHero from '../../../assets/login_background_image.webp';
import { useSignInMutation } from '../utils/useSignInMutation';
import { signInStyles } from './styles';

const tokenManager = TokenManager.getInstance();

const SignIn = () => {
  const { classes } = signInStyles({ imgHero });
  const { formik, mutation } = useSignInMutation();

  const location = useLocation();
  const { domainSwitch, arrivedFromOldDomain } = location.state ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenManager.userHaveAccess()) {
      // - If authenticated users tries to access the Signin page
      // they will be redirected to the Select facility page.
      // - If they have access only to one facility,
      // Select Facility page will take care of further redirection
      navigate(CLIENT_PAGES_URLS.SELECT_FACILITY);
    }
  }, [navigate]);

  return mutation.isPending ? (
    <Spinner />
  ) : (
    <Grid container component="main" className={classes.grid}>
      <GlobalStyles
        styles={{
          '.App': {
            // TODO: I don't like this, open to suggestions for improvements [TC]
            padding: '0 !important',
          },
        }}
      />
      <Grid item xs={12} sm={12} md={4} component={Paper} elevation={6} square>
        <Stack alignItems="center" gap={6} className={classes.formWrapper}>
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={3}
            className={classes.formInnerWrapper}
          >
            <Typography variant="h3" component="h1" gutterBottom>
              Sign in
            </Typography>

            {(domainSwitch || arrivedFromOldDomain) && (
              <Box component="div" flexDirection="column" display="flex" gap={2}>
                {arrivedFromOldDomain && <ArrivedFromOldDomainMessage />}
                {domainSwitch && <DomainSwitchMessage />}
              </Box>
            )}

            <SignInForm
              username={formik.values.username}
              password={formik.values.password}
              handleChange={formik.handleChange}
              handleSubmit={formik.handleSubmit}
              showForgotPasswordLink={true}
            />
          </Stack>
          <Box className={classes.copyright}>
            <Copyright />
            <Typography variant="caption">
              © {new Date().getUTCFullYear()} Verity. All rights reserved.
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item sm={false} md={8} className={classes.hero} />
    </Grid>
  );
};

export default SignIn;
