import { forwardRef } from 'react';
import { ZoneBaseMeshProps, ZoneBaseMeshRef } from './drone-zone-base-mesh.model';
import { MeshNodeLabel } from '../../../map-container/features/mesh-node-label/MeshNodeLabel';

export const DroneZoneBaseCircleMesh = forwardRef(
  (
    {
      label = '',
      material,
      width,
      length,
      height,
      position,
      options,
      textColors = {},
      onClick,
      maxFontSizeInPx,
      minFontSizeInPx,
      renderOrder = 0,
      ...props
    }: ZoneBaseMeshProps,
    ref?: ZoneBaseMeshRef,
  ) => (
    <mesh
      ref={ref}
      position={position}
      renderOrder={renderOrder}
      material={material}
      onClick={onClick}
      {...props}
    >
      <circleGeometry args={[2, 64]} />

      <MeshNodeLabel
        w={width}
        l={length}
        options={options}
        minFontSizeInPx={minFontSizeInPx}
        maxFontSizeInPx={maxFontSizeInPx}
        renderOrder={renderOrder}
        textLines={[label]}
        isTextScalable={false}
        colors={{ background: 'white', border: 'white', text: 'white', ...textColors }}
      />
    </mesh>
  ),
);
