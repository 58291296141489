/* eslint-disable no-nested-ternary */
import {
  AlwaysDepth,
  BoxGeometry,
  CircleGeometry,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Vector3,
} from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { meshRenderOrder } from 'shared/map/defaults/mesh-render-order';
import { MapOptions } from '../reducer/MapOptionsState';
import { makeTexture, makeTHREEMaterial } from './3DmapFunctions';
import { MapRoot, NodeST, NodeType } from '../MapContainer.model';
import {
  BottomLeftDimensions,
  GeometryMM,
  SizeAndPosition,
} from '../../../udb/ground-control/drone-zones/model/NoFlyZoneGeometry.model';
import { mapStyle } from '../features/map-canvas/MapCanvas.style';
import { allNumbersToFixed2 } from '../../numberFormatting';

const logPrefix = getLogPrefixForType('FUNCTION', '3D facility creation');

/**
 * Will turn a '6 vector' into size and position.
 * @param vec6 [minX, minY, minZ, maxX, maxY, maxZ]
 * @returns '{ width, length, height, x, y, z }'
 */
export const Vec6ToSizeAndPos = (vec6: number[]) => {
  const w = vec6[3] - vec6[0];
  const l = vec6[4] - vec6[1];
  const h = vec6[5] - vec6[2];
  const x = vec6[0] + w / 2;
  const y = vec6[1] + l / 2;
  const z = vec6[2] + h / 2;

  return allNumbersToFixed2({
    w,
    l,
    h,
    x,
    y,
    z,
  });
};

export const geometryToBottomLeftDimensions = (geom: GeometryMM): BottomLeftDimensions => ({
  w: geom.max.x - geom.min.x,
  l: geom.max.y - geom.min.y,
  h: geom.max.z - geom.min.z,
  minX: geom.min.x,
  minY: geom.min.y,
  minZ: geom.min.z,
  maxZ: geom.max.z,
});

export const zoneCoordinatesToGeometry = (dims: BottomLeftDimensions): GeometryMM => ({
  min: allNumbersToFixed2({
    x: dims.minX,
    y: dims.minY,
    z: dims.minZ,
  }),
  max: allNumbersToFixed2({
    x: dims.minX + dims.w,
    y: dims.minY + dims.l,
    z: dims.maxZ,
  }),
});

export const bottomLeftDimensionsToGeometry = (dims: BottomLeftDimensions): GeometryMM => ({
  min: {
    x: dims.minX,
    y: dims.minY,
    z: dims.minZ,
  },
  max: {
    x: dims.minX + dims.w,
    y: dims.minY + dims.l,
    z: dims.minZ + dims.h,
  },
});

export const bottomLeftDimensionsToSizeAndPos = ({
  w,
  l,
  h,
  minX,
  minY,
  minZ,
}: BottomLeftDimensions): SizeAndPosition => {
  const offsetX = minX + w / 2;
  const offsetY = minY + l / 2;
  const offsetZ = minZ + h / 2;

  return {
    w,
    l,
    h,
    x: offsetX,
    y: offsetY,
    z: offsetZ,
    positionVector: new Vector3(offsetX, offsetY, offsetZ),
  };
};

/**
 * Searches map three for nodes of given types and
 * from them creates list of 3D objects
 * @param map to search thru
 * @param types to turn into 3D objects
 * @param options map options
 * @returns object of Object3D arrays keyed by NodeType
 */
export const mapToObjects = (
  map: MapRoot | NodeST,
  types: NodeType[],
  options: MapOptions,
): Partial<{ [key in NodeType]: Object3D[] }> => {
  const mapObjects: Partial<{ [key in NodeType]: Object3D[] }> = {};

  const runSearch = (node: NodeST) => {
    if (types.includes(node.type)) {
      const loneNode = { ...node, nodes: [] };
      if (!mapObjects[node.type]) {
        mapObjects[node.type] = [];
      }

      let newObject;
      if (node.type === 'AISLE' || node.type === 'RACK') {
        newObject = makeBox(loneNode, options);
      } else if (node.type === 'CHARGING_POD') {
        newObject = makeCharger(loneNode);
      }

      if (!newObject) {
        throw Error('Trying to create Object3D from invalid NodeType');
      }

      if (Array.isArray(newObject)) {
        mapObjects[node.type]!.unshift(...newObject);
      } else {
        mapObjects[node.type]!.push(newObject);
      }
    }

    if (node.nodes) {
      node.nodes.forEach((n) => runSearch(n));
    }
  };

  runSearch(map as NodeST);

  console.debug(
    `${logPrefix} extractTypeFromMapTree: extracted ${
      [Object.values(mapObjects)].flatMap((x) => x).length
    } nodes of type ${types}`,
  );

  return mapObjects;
};

/**
 * Creates 3D object from a map node.
 * @param node map node
 * @param options general map options
 * @param textureBuildetar for building textures
 * @returns Object3D to be added to the scene
 */
const makeBox = (node: NodeST, options: MapOptions): Mesh => {
  const { w, l, h, x, y, z } = Vec6ToSizeAndPos(node.box);
  const geometry = new BoxGeometry(w, l, h);
  const topFaceProportion = w / l;
  const { box: boxStyle } = options.styles;

  if (node.type === 'CHARGING_POD') {
    console.debug(`${logPrefix} makeBox: node is a charging pod at ${x}, ${y}, ${z}`);
  }

  const texture = makeTexture({
    includeBorder: false,
    textLines: [node.name],
    backgroundColor: boxStyle.color,
    textColor: boxStyle.fontColor,
    proportion: topFaceProportion,
    fovSpan: {
      horizontalFOV: 0,
      verticalFOV: 0,
    },
  });
  const mats = makeTHREEMaterial({ color: boxStyle.color, texture });

  const box = new Mesh(geometry, mats);
  box.position.set(x, y, z);
  box.name = node.id;
  box.userData = { node };

  return box;
};

/**
 * Creates 3D charger from a charger map node.
 * @param node node of type 'CHARGING_POD'
 * @returns Object3D to be added to the scene
 */
const makeCharger = (node: NodeST): Object3D[] => {
  const { w, l, h, x, y, z } = Vec6ToSizeAndPos(node.box);
  const geometry = new BoxGeometry(w, l, h);
  const topFaceProportion = w / l;

  const removeRegex = /([Cc]harg(ing|er))|([Pp]od)|( ?\(.*\))?/g;
  const cleanedName = node.name.replaceAll(removeRegex, '').trim();
  const textLines = cleanedName.split(/ /g);

  const texture = makeTexture({
    textLines,
    includeBorder: true,
    backgroundColor: mapStyle.charger.backgroundColor,
    textColor: mapStyle.charger.fontColor,
    proportion: topFaceProportion,
    minFontSizeInPx: 24,
    maxFontSizeInPx: 48,
    fovSpan: {
      horizontalFOV: 0,
      verticalFOV: 0,
    },
  });

  const mats = makeTHREEMaterial({
    color: mapStyle.charger.backgroundColor,
    texture,
    depthFunc: AlwaysDepth,
  });

  const box = new Mesh(geometry, mats);
  box.position.set(x, y, z);
  box.name = node.id;
  box.renderOrder = meshRenderOrder.chargers;
  box.userData = { node };

  const cGeo = new CircleGeometry(2, 64);
  const cMat = new MeshBasicMaterial({ color: mapStyle.charger.area.backgroundColor });
  const circle = new Mesh(cGeo, cMat);
  circle.position.set(x, y, z);

  return [box, circle];
};
