import { ILocationDataST } from 'codegen/warehouse_status';
import { ExclusionHistory } from 'udb/inventory/locations-management/models/ExclusionHistory';

export type LocationsState = {
  locations: { [key: string]: ILocationDataST };
  selectedIds: string[];
  uploadedFile: File | null;
  idSelectedForReset: string;
  idSelectedForDetails: string;
  exclusionHistory: ExclusionHistory;
};

export const locationsInitialState: LocationsState = {
  locations: {},
  selectedIds: [],
  idSelectedForReset: '',
  idSelectedForDetails: '',
  exclusionHistory: null,
  uploadedFile: null,
};
