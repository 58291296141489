import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ProtectedRouteProps } from './protectedRouteProps.model';

/**
 * This component is wrapper for Route component and it is used for checking access permissions before accessing the page itself
 */
export const ProtectedRoute = ({ children, isAccessible }: ProtectedRouteProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAccessible) {
      navigate('/not-found', { replace: true });
    }
  });

  return isAccessible ? children : <div />;
};
