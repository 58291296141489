import { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import clsx from 'clsx';

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// material-ui icons
import LockIcon from '@mui/icons-material/Lock';

// helpers, services & store
import { AUTH_PAGES_URLS, WARNING_PAGES_URLS } from 'common/pages';

// variables, functions, configurations
import { checkIfUserHasRolesDefined } from 'common/functions/userFunctions';
import { TokenManager } from 'common/tokenManager';

// styles

// components
import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';
import ModalBase from 'components/ModalsAndPopups/ModalBase';
import Copyright from 'components/common/Copyright';
import EnhancedBox from 'components/common/EnhancedBox';
import sharedStyles from './styles';
import { useRequestController } from '../../hooks';
import { UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';
import { FacilityMenuActionNames } from '../../store/FacilityMenuStore/facilityMenuActions';
import { useFacilityMenuStore } from '../../store/FacilityMenuStore/facilityMenuStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import AuthServices from '../../services/AuthServices';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { getHomeRouteBasedOnPermissions } from '../SelectFacility/getHomeRouteBasedOnPermissions';

const tokenManager = TokenManager.getInstance();

const SetMfaPreference = () => {
  const { classes } = sharedStyles();

  // navigate
  const navigate = useNavigate();

  // context
  const { stateUserLevel, dispatchUserLevelStore } = useUserLevelStore();
  const {
    stateFacilityLevel,
    isDataReady: isFacilityDataReady,
    isFacilityConfigured,
  } = useFacilityLevelStore();
  const { dispatchFacilityMenu } = useFacilityMenuStore();

  // state variables
  const [spinner, setSpinner] = useState(0);
  const [code, setCode] = useState('');
  const [facilityLoaded, setFacilityLoaded] = useState(false);
  const [isFacilityActive, setIsFacilityActive] = useState(null);
  const [secretCode, setSecretCode] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [mfaType, setMfaType] = useState('SOFTWARE_TOKEN_MFA');
  const [successModal, setModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [skipMfa, setSkipMfa] = useState(true);
  const [disabledMfa, setDisabledMfa] = useState(true);

  const accessToken = tokenManager.getAccessToken();
  const { systemId } = useParams();

  // component variables
  const steps = ['Select authentication type', 'How to setup', 'Connect'];
  const qrCodesecret = `otpauth://totp/${stateUserLevel.username}?secret=${secretCode}&issuer=${window.location.host}`;

  const { requestController } = useRequestController('SetMfaPreference');

  const handleAuthTypeChange = (event) => {
    console.debug('setMfaPreference: handleAuthTypeChange');
    setMfaType(event.target.value);
  };

  const handleCodeChange = useCallback((event) => {
    console.debug('setMfaPreference: handleCodeChange');
    setCode(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    console.debug('setMfaPreference: handleSubmit');
    requestController.doRequest({
      request: AuthServices.verifySoftwareToken,
      requestParams: [
        {
          access_token: accessToken,
          user_code: code,
        },
      ],
      callbackBeforeSend: () => setSpinner((repliesPending) => repliesPending + 1),
      callbackSuccess: (res) => {
        requestController.doRequest({
          request: AuthServices.setUserMfaPreference,
          requestParams: [
            {
              sms_enabled: false,
              sms_preferred: false,
              software_token_enabled: true,
              software_token_preferred: true,
              access_token: accessToken,
            },
          ],
          callbackBeforeSend: () => setSpinner((repliesPending) => repliesPending + 1),
          callbackSuccess: () => {
            if (res.data.status === 'SUCCESS') {
              setModal(true);
            }
          },
          messageErrorFallback: 'MFA could not be set.',
          callbackFinally: () => setSpinner((repliesPending) => repliesPending - 1),
        });
      },
      messageErrorFallback: 'Software Token could not be verified.',
      callbackFinally: () => setSpinner((repliesPending) => repliesPending - 1),
    });
  }, [accessToken, code, requestController]);

  const handleNext = () => {
    console.debug('setMfaPreference: handleNext');
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (mfaType === 'SOFTWARE_TOKEN_MFA' && activeStep === 1) {
      requestController.doRequest({
        request: AuthServices.getSecretCode,
        requestParams: [accessToken],
        callbackSuccess: (r) => {
          setSecretCode(r.data.secret_code);
        },
        messageErrorFallback: 'Secret Code could not be fetched.',
        callbackError: (e) => {
          setTimeout(() => {
            navigate(AUTH_PAGES_URLS.SIGNOUT);
          }, 2500);
        },
      });
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setSkipMfa(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // onClick copy to clipboard function
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (e) {
      console.error('Error => ', e);
      setCopySuccess('Failed to copy!');
    }
  };

  const ChooseType = useCallback(
    () => (
      <FormControl color="primary" variant="outlined">
        <RadioGroup value={mfaType} onChange={handleAuthTypeChange}>
          <FormControlLabel
            value="SMS (Not available)"
            control={<Radio disabled color="primary" />}
            label="SMS"
          />
          <FormControlLabel
            value="SOFTWARE_TOKEN_MFA"
            control={<Radio color="primary" />}
            label="Software Token Authentication"
          />
        </RadioGroup>
      </FormControl>
    ),
    [mfaType],
  );

  const HowToSetup = useCallback(
    () => (
      <Box>
        <Typography style={{ marginBottom: '10px' }} variant="subtitle1" color="textSecondary">
          Use an one time password authenticator on your mobile device or computer to enable
          two-factor authentication.
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {'1. Download an app for '}
          <a
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=sr&gl=US"
            target="_blank"
          >
            Android
          </a>
          {' or '}
          <a
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
          >
            iOS.
          </a>
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          2. To set up an account, you can scan the QR code in your 2-Step Verification settings. Or
          enter it manually.
        </Typography>
      </Box>
    ),
    [],
  );

  const Connecting = useCallback(
    () => (
      <Box display="flex" alignItems="center" flexDirection="column">
        {!secretCode ? (
          <Spinner />
        ) : (
          <>
            <QRCode value={qrCodesecret} />
            <Button onClick={() => copyToClipBoard(secretCode)}>Click to copy Secret Code</Button>
            <Box style={{ height: '30px' }}>{copySuccess}</Box>
          </>
        )}
        <FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            autoComplete="off"
            fullWidth
            id="code"
            label="Authentication Code"
            value={code}
            autoFocus
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
            onChange={(e) => handleCodeChange(e)}
          />
        </FormControl>
        <Link className={classes.routerLink} to={AUTH_PAGES_URLS.SIGNIN} variant="body2">
          Report a problem
        </Link>
      </Box>
    ),
    [
      secretCode,
      qrCodesecret,
      copySuccess,
      code,
      classes.routerLink,
      handleSubmit,
      handleCodeChange,
    ],
  );

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <ChooseType />;
      case 1:
        return <HowToSetup />;
      case 2:
        return <Connecting />;
      default:
        return undefined;
    }
  };

  const skipMfaInfo = {
    icon: (
      <Box className={clsx(classes.cardIcon)}>
        <LockIcon fontSize="large" color="primary" />
      </Box>
    ),
    description: 'Do you want to set up Multi-factor authentication?',
    actionButtons: [
      {
        variant: 'outlined',
        testid: 'c-skip-mfa-button-yes',
        label: 'Yes',
        action: () => setSkipMfa(false),
      },
      {
        variant: 'contained',
        testid: 'c-skip-mfa-button-no',
        class: classes.submit,
        label: 'No',
        action: async () => {
          if (facilityLoaded && !isFacilityActive) {
            navigate(`/${systemId}${WARNING_PAGES_URLS.NO_FACILITY}`);
          } else {
            dispatchFacilityMenu({
              type: FacilityMenuActionNames.SET_MENU_OPENED,
              payload: true,
            });

            const url = await getHomeRouteBasedOnPermissions(systemId);

            // SS-28-03-2022 NOTE:
            // Here we use window.open because tests failing on chrome browser
            //
            // Problem: WMS parsers doesn't populate select input options
            //
            // TODO: Need a deeper investigation on this issue
            window.open(url, '_self');
          }
        },
      },
    ],
  };

  // For IKEA we skip MFA question
  // NOTE: Later this will become part of the facility settings.
  //
  useEffect(() => {
    const userHasRolesDefined = checkIfUserHasRolesDefined(accessToken);

    if (userHasRolesDefined) {
      console.debug('setMfaPreference: userHasRolesDefined');
      dispatchUserLevelStore({ type: UserLevelActionNames.AUTH_PROCESS, payload: true });

      if (isFacilityDataReady()) {
        console.debug('setMfaPreference: facilityLoaded');
        setFacilityLoaded(true);

        setIsFacilityActive(isFacilityConfigured(systemId));

        if (!stateFacilityLevel?.facilitySettings?.enable_mfa) {
          if (!isFacilityConfigured(systemId)) {
            setDisabledMfa(false);
          } else {
            getHomeRouteBasedOnPermissions(systemId).then((url) =>
              navigate(url, { replace: true }),
            );
          }
        } else {
          setDisabledMfa(false);
        }
      }
    }
  }, [
    accessToken,
    dispatchUserLevelStore,
    isFacilityConfigured,
    isFacilityDataReady,
    navigate,
    stateFacilityLevel?.facilitySettings?.enable_mfa,
    systemId,
  ]);

  return (
    <div>
      {!disabledMfa ? (
        <Box
          width="100%"
          mt={12}
          px={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          className="c-page-content"
        >
          <Card elevation={3} className={classes.paper}>
            {!skipMfa ? (
              <>
                <Box className={classes.cardIcon}>
                  <LockIcon fontSize="large" color="primary" />
                </Box>
                <Stepper sx={{ width: '90%' }} activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Typography
                  style={{ margin: '16px 0px' }}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  <Box>{getStepContent(activeStep)}</Box>
                </Typography>
                <Box className={classes.actionButtons} display="flex">
                  <Button fullWidth onClick={handleBack} variant="outlined" color="primary">
                    {activeStep === 0 ? 'Cancel' : 'Back'}
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                    className={classes.submit}
                    disabled={activeStep === steps.length - 1 && code.length !== 6}
                  >
                    {activeStep === steps.length - 1 ? 'SUBMIT' : 'NEXT'}
                  </Button>
                </Box>
              </>
            ) : (
              <EnhancedBox
                classes={classes}
                icon={skipMfaInfo.icon}
                description={skipMfaInfo.description}
                actionButtons={skipMfaInfo.actionButtons}
              />
            )}
          </Card>

          <Box mt={5}>
            <Copyright />
          </Box>

          {spinner ? <Spinner /> : null}

          <ModalBase
            opened={successModal}
            title={
              <Box>
                <Box textAlign="left" p={2} mb={1}>
                  <Typography color="secondary" variant="h5">
                    Success
                  </Typography>
                </Box>
              </Box>
            }
            handleClose={() => navigate(AUTH_PAGES_URLS.SIGNOUT)}
            actionButtons={
              <Button
                onClick={() => {
                  navigate(AUTH_PAGES_URLS.SIGNOUT);
                }}
                variant="contained"
                fullWidth
                color="primary"
              >
                Sign In
              </Button>
            }
          >
            <Box p={3}>
              <Typography color="secondary" variant="subtitle1">
                Multi-factor Authentication is enabled. Please Sign in again.
              </Typography>
            </Box>
          </ModalBase>
        </Box>
      ) : (
        <Box className="c-page-content">
          <Spinner label="Loading facility" />
        </Box>
      )}
    </div>
  );
};

export default SetMfaPreference;
