import BaseCard from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem, {
  IBaseCardSimpleItemSectionItems,
} from 'components/BaseCard/BaseCardSimpleItem';
import { ReportSummaryData } from '../../../../reducer/report-store/ReportStore.model';

export const ReportSummaryCard = ({
  cardFor,
  reportSummaryData,
  isLoading,
}: {
  cardFor: string;
  reportSummaryData: ReportSummaryData;
  isLoading?: number;
}) => (
  <BaseCard
    cardFor={cardFor}
    showHeader
    showContent
    showActionButtons={false}
    showHeaderDivider
    isLoading={isLoading}
    title={reportSummaryData.title}
    subtitle={reportSummaryData.subtitle}
    counter={reportSummaryData.counter}
    counterSubject={reportSummaryData.counterSubject}
  >
    {reportSummaryData.sections &&
      reportSummaryData.sections.map((section) => (
        <BaseCardSimpleItem
          key={section.id}
          sectionItems={section.sectionItems as IBaseCardSimpleItemSectionItems[]}
        />
      ))}
  </BaseCard>
);
