import { forwardRef } from 'react';
import { BoxGeometry, EdgesGeometry } from 'three';
import {
  ZoneBaseMeshRef,
  ZoneBaseMeshSharedProps,
} from '../drone-zone-base-mesh/drone-zone-base-mesh.model';
import { OpenFlyZone } from '../../model/open-fly-zone.model';
import { bottomLeftDimensionsToSizeAndPos } from '../../../map-container/utils/creation.util';
import { meshRenderOrder } from '../../defaults/mesh-render-order';
import { mapStyle } from '../../../map-container/features/map-canvas/MapCanvas.style';

export const OpenFlyZoneMesh = forwardRef(
  ({ droneZone }: ZoneBaseMeshSharedProps & { droneZone: OpenFlyZone }, ref?: ZoneBaseMeshRef) => {
    const { w, l, h, positionVector } = bottomLeftDimensionsToSizeAndPos(droneZone.sizeAndPosition);

    const box = new BoxGeometry(w, l, h);
    const geometry = new EdgesGeometry(box);

    return (
      <>
        <mesh
          position={positionVector}
          geometry={box}
          renderOrder={meshRenderOrder.open_fly_zones}
          ref={ref}
        >
          <meshBasicMaterial color={mapStyle.openFlyZones.active.backgroundColor.primary} />
        </mesh>

        <lineSegments
          geometry={geometry}
          renderOrder={meshRenderOrder.open_fly_zones}
          position={positionVector}
        >
          <meshBasicMaterial color={mapStyle.openFlyZones.active.backgroundColor.primary} />
        </lineSegments>
      </>
    );
  },
);
