import { TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useState } from 'react';
import { FormInputNumberProps } from './FormInputNumber.model';
import { validateDecimalLength } from './utils/validateDecimalLength';

export const FormInputNumber = ({
  name,
  placeholder = '',
  label = '',
  size = 'medium',
  decimalPlaces = 2,
  onChange,
  onBlur,
  ...rest
}: FormInputNumberProps) => {
  const [decimalError, setDecimalError] = useState('');

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldProps['field'],
  ) => {
    setDecimalError('');

    const isDecimalCountWithinLimit = validateDecimalLength(event.target.value, decimalPlaces);

    if (!isDecimalCountWithinLimit) {
      setDecimalError(`Input values must be no more than ${decimalPlaces} decimal places`);
      return;
    }

    field.onChange(event);

    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: FieldProps['field'],
  ) => {
    setDecimalError('');

    field.onBlur(event);

    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <TextField
          name={name}
          value={field.value}
          placeholder={placeholder}
          label={label}
          onChange={(event) => handleChange(event, field)}
          onBlur={(event) => handleBlur(event, field)}
          type="number"
          size={size}
          error={!!(meta.touched && meta.error) || !!decimalError}
          helperText={meta.error || decimalError}
          {...rest}
        />
      )}
    </Field>
  );
};
