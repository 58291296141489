import React from 'react';

// material-ui core
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// material-ui icons
import GetAppIcon from '@mui/icons-material/GetApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// components
import { Box } from 'components/common/Box';
import TooltipedIcon from 'components/common/TooltipedIcon';

// codegen
import { IReportSpecificationST } from 'codegen/report_specification';

interface IReportSpecItemProps {
  handleDelete: (spec: IReportSpecificationST) => void;
  handleDownload: (spec: IReportSpecificationST) => void;
  spec: IReportSpecificationST;
}

/**
 * Functional component representing a Report Specification ITem
 * @param param0 IReportSpecItemProps props
 * @returns react component
 */
export default function ReportSpecItem({
  handleDelete,
  handleDownload,
  spec,
}: IReportSpecItemProps) {
  return (
    <Box data-testid="c-report-spec-item">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Box justifyContent="space-between" alignItems="center" display="flex">
          <Box display="flex">
            <Typography variant="subtitle1" color="textSecondary">
              {spec.report_spec_name}
            </Typography>
            <TooltipedIcon
              tooltip={spec.report_description}
              icon={<InfoOutlinedIcon fontSize="small" />}
            />
          </Box>
        </Box>
        <Box ml={1}>
          <IconButton onClick={() => handleDownload(spec)} color="secondary" size="large">
            <GetAppIcon />
          </IconButton>
          <IconButton
            data-testid="c-delete-report-spec-btn"
            onClick={() => handleDelete(spec)}
            color="error"
            size="large"
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
