import { DroneZones } from '../../../../../../model/drone-zones.model';
import { ZoneSideViewBaseZone } from '../zone-side-view-base-zone/ZoneSideViewBaseZone';
import { useStyles } from './ZoneSideViewControlledZone.styles';

export const ZoneSideViewControlledZone = ({
  zone,
  areaHeight,
  isSelected,
  isDisabled,
  onClick,
}: {
  zone: DroneZones;
  areaHeight: number;
  isSelected: boolean;
  isDisabled: boolean;
  onClick: (zone: DroneZones) => void;
}) => {
  const { classes, cx } = useStyles();

  return (
    <ZoneSideViewBaseZone
      classNames={cx(classes.controlledZone, {
        [classes.controlledZoneActive]: zone.isActive,
        [classes.controlledZoneInactive]: !zone.isActive,
        [classes.controlledZoneSelectedInactive]: isSelected && !zone.isActive,
        [classes.controlledZoneSelectedActive]: isSelected && zone.isActive,
      })}
      isSelected={isSelected}
      isDisabled={isDisabled}
      zone={zone}
      areaHeight={areaHeight}
      onClick={onClick}
    />
  );
};
