import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Card from '@mui/material/Card';
import TablePagination from '@mui/material/TablePagination';
import { getAmendingRowsData, sortRows } from 'common/Tables/tableFunctions';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import EnhancedTableHeader from './Header/EnhancedTableHeader';
import EnhancedTableToolbar from './Toolbar/EnhancedTableToolbar';
import EnhancedTableTabs from './Tabs/EnhancedTableTabs';
import { tableStyles } from './styles';
import { TableContainer } from './Table/TableContainer';
import { OrderValue } from './types/rows';
import { Search } from './Search/Search';
import { filterOrderTableRows } from './functions/filterOrderTableRows';
import { IEnhancedTableProps } from './IEnhancedTableProps';

const logPrefix = getLogPrefixForType('COMPONENT', 'EnhancedTable');

const EnhancedTable = ({
  allowSorting = true,
  changeTab,
  currentActiveSpinner,
  startingTab = 0,
  disablePagination,
  enableHoverOnRows = true,
  handleSwitchClick,
  customToolbar,
  headCells,
  headerButton,
  hideToolbar = false,
  initialOrder = '',
  initialOrderBy = '',
  isInputDisabled,
  isTableDisabled = false,
  isLoading,
  noData,
  refreshData = { refreshData: () => {} },
  reportId, // only used when parent page is Report
  rows,
  showHeader = true,
  showSearch = true,
  tableFor,
  tableSubtitle,
  tableTitle,
  tabs,
  tabStatus,
  toolbarAction,
  onRowSelect,
  onRowClick,
}: IEnhancedTableProps) => {
  const { classes } = tableStyles();

  // hooks
  const [searchParams, setSearchParams] = useSearchParams();

  // state variables
  const order = (searchParams.get('order') as OrderValue) || initialOrder;
  const orderBy = searchParams.get('orderBy') || initialOrderBy;
  const activeTab = Number(searchParams.get('activeTab')) || startingTab;
  const page = Number(searchParams.get('page')) || 0;
  const rowsPerPage = Number(searchParams.get('perPage')) || 10;
  const searchTerm = searchParams.get('searchTerm') || '';
  const sliceStart = page * rowsPerPage;
  const sliceEnd = page * rowsPerPage + rowsPerPage;

  const [selected, setSelected] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [searching, setSearching] = useState(false);

  const filteredRows = useMemo(
    () => filterOrderTableRows(rows, searchTerm, orderBy, order, headCells),
    [rows, searchTerm, orderBy, order, headCells],
  );
  const slicedRows = disablePagination
    ? filteredRows
    : filteredRows.filter(Boolean).slice(sliceStart, sliceEnd);

  const shouldDisableSearch = Boolean(isLoading || searching || selected.length || noData);
  const tabStatusValue = tabStatus ? currentActiveSpinner && currentActiveSpinner[tableFor] : false;
  const numberOfRows = filteredRows.length;
  const paginationPage = numberOfRows ? page : 0;

  // component variables
  // To enable 'All' option just add the following to the array:
  // { value: rows.length, label: `All (${rows.length})` }
  const rowsPerPageOptions = [10, 20, 50];

  const resetSelection = useCallback(() => {
    setSelected([]);
    setAllSelected(false);
    onRowSelect && onRowSelect([]);
  }, [onRowSelect]);

  const handleRequestSort = useCallback(
    /**
     * Handle a sort request (click on header)
     * @param {*} event ui event
     * @param {*} property order by property
     */
    (event: Event, property: string) => {
      const newOrder = orderBy === property && order === 'desc' ? 'asc' : 'desc';
      console.debug(
        logPrefix,
        `handleRequestSort, orderBy = ${orderBy}, order: ${order}, newOrder: ${newOrder}`,
      );

      searchParams.set('order', newOrder);
      searchParams.set('orderBy', property);
      searchParams.set('page', '0');
      setSearchParams(searchParams);
      resetSelection();
    },
    [order, orderBy, searchParams, setSearchParams, resetSelection],
  );

  const handleSelectAllClick = useCallback(
    /**
     * Handle the click event to select all rows in the table
     */
    (event: React.ChangeEvent<HTMLInputElement>, rows: any[]) => {
      console.debug(logPrefix, 'handleSelectAllClick, rows: ', rows);

      if (event.target.checked) {
        const newSelection: string[] = rows.map((row: { id: string }) => row.id);

        setSelected(newSelection);
        setAllSelected(newSelection.length !== 0);
        onRowSelect && onRowSelect(newSelection);
        return;
      }
      setAllSelected(false);
      setSelected([]);
      onRowSelect && onRowSelect([]);
    },
    [onRowSelect],
  );

  const handleTabChange = useCallback(
    /**
     * Handle the click event that changes the active tab (click on a different tab).
     */
    (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
      console.debug(logPrefix, 'handleTabChange, newValue: ', newValue);

      if (changeTab) {
        changeTab(newValue);
      }

      searchParams.set('activeTab', newValue.toString());
      searchParams.set('page', '0');
      setSearchParams(searchParams);
      resetSelection();
    },
    [changeTab, searchParams, setSearchParams, resetSelection],
  );

  const handleRowSelect = useCallback(
    /**
     * Handle the click on a checkbox cell.
     */
    (id: string) => {
      console.debug(logPrefix, 'handleSelectRow, id:', id);
      let selectedCopy = [...selected];
      const isSelected = selectedCopy.find((selectedId: string) => selectedId === id);

      if (isSelected) {
        selectedCopy = selectedCopy.filter((selectedId) => selectedId !== id);
      } else {
        selectedCopy.push(id);
      }
      setAllSelected(selectedCopy.length === slicedRows.length);
      setSelected(selectedCopy);
      onRowSelect && onRowSelect(selectedCopy);
    },
    [selected, slicedRows.length, onRowSelect],
  );

  // debounce is used to prevent setting of searchTerm with each
  // keystroke in the search bar. searchTerm change triggers filtering
  // of the table rows, so by using debounce we avoid unnecessary filtering.
  // Filtering is done only 1000ms after last keystroke is detected.
  const handleSearchTermChange = debounce((searchTerm) => {
    console.debug(logPrefix, 'handleSearchTermChange, searchTerm: ', searchTerm);
    setSearching(true);

    searchParams.set('searchTerm', searchTerm.toLowerCase());
    searchParams.set('page', '0');
    setSearchParams(searchParams);
    setSearching(false);
    resetSelection();
  }, 1000);

  const clearSearchTerm = useCallback(
    /**
     * Clear search term from input field clicking on adornment icon
     */
    () => {
      console.debug(logPrefix, 'clearSearchTerm');
      setSearching(false);
      searchParams.set('searchTerm', '');
      searchParams.set('page', '0');
      setSearchParams(searchParams);
      resetSelection();
    },
    [searchParams, setSearchParams, resetSelection],
  );

  const handleChangePage = useCallback(
    /**
     * Handle the page change event (click on pagination/navigation buttons)
     */
    (event: any, newPage: number) => {
      console.debug(logPrefix, 'handleChangePage, newPage: ', newPage);
      searchParams.set('page', newPage.toString());
      setSearchParams(searchParams);
      resetSelection();
    },
    [searchParams, setSearchParams, resetSelection],
  );

  const handleChangeRowsPerPage = useCallback(
    /**
     * Handle the change rows per page event (click on the rows per page selector)
     */
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rowsNumPerPage = parseInt(event.target.value, 10);

      searchParams.set('page', '0');
      searchParams.set('perPage', String(rowsNumPerPage));
      setSearchParams(searchParams);
      resetSelection();
    },
    [searchParams, setSearchParams, resetSelection],
  );

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const getAmendingData = useCallback(
    (row: any) => getAmendingRowsData(sortRows(rows, order, orderBy), row, reportId),
    [order, orderBy, reportId, rows],
  );

  /**
   * Reset selected items on archive/delete
   */
  useEffect(() => {
    if (!isLoading) {
      resetSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Card className={classes.paper} data-testid="c-table" elevation={3}>
      {showHeader && (
        <EnhancedTableHeader title={tableTitle} subtitle={tableSubtitle} noData={noData}>
          {headerButton}

          {showSearch && (
            <Search
              onChange={handleSearchTermChange}
              onClear={clearSearchTerm}
              defaultValue={searchTerm}
              isDisabled={shouldDisableSearch}
            />
          )}
        </EnhancedTableHeader>
      )}

      {customToolbar && <div className={classes.sectionToolbar}>{customToolbar}</div>}

      {!hideToolbar && (
        <EnhancedTableToolbar
          selectedRows={selected}
          noOfRowsFound={numberOfRows}
          searchTerm={searchTerm}
          tabStatus={tabStatusValue}
          searching={searching}
          action={toolbarAction}
        />
      )}

      {tabs?.length && (
        <EnhancedTableTabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      )}

      <TableContainer
        isDisabled={isTableDisabled}
        allSelected={allSelected}
        selected={selected}
        currentActiveSpinner={currentActiveSpinner}
        headCells={headCells}
        isLoading={isLoading}
        isSelected={isSelected}
        refreshData={refreshData}
        allowSorting={allowSorting}
        enableHoverOnRows={enableHoverOnRows}
        isInputDisabled={isInputDisabled}
        getAmendingData={getAmendingData}
        rows={slicedRows}
        rowCount={numberOfRows}
        tableFor={tableFor}
        onSwitchClick={handleSwitchClick}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        onRowSelect={handleRowSelect}
        onRowClick={onRowClick}
      />

      {/* Toolbar on the bottom of the table */}
      {!hideToolbar && (
        <EnhancedTableToolbar
          selectedRows={selected}
          noOfRowsFound={numberOfRows}
          searchTerm={searchTerm}
          tabStatus={tabStatusValue}
          searching={searching}
          action={toolbarAction}
        />
      )}

      {!disablePagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={numberOfRows}
          rowsPerPage={rowsPerPage}
          page={paginationPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Card>
  );
};

export default EnhancedTable;
