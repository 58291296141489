/* tslint:disable */
/* eslint-disable */
/**
 * Flight domain settings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IDroneOperatingHoursScheduleST
 */
export interface IDroneOperatingHoursScheduleST {
  /**
   * The list of drone operating hours
   * @type {Array<IOperatingHoursEventST>}
   * @memberof IDroneOperatingHoursScheduleST
   */
  schedule: Array<IOperatingHoursEventST>;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
}
/**
 *
 * @export
 * @interface IFlightDomainSettingsGetResponseST
 */
export interface IFlightDomainSettingsGetResponseST {
  /**
   *
   * @type {IDroneOperatingHoursScheduleST}
   * @memberof IFlightDomainSettingsGetResponseST
   */
  drone_operating_hours: IDroneOperatingHoursScheduleST;
}
/**
 *
 * @export
 * @interface IFlightDomainSettingsPostRequestST
 */
export interface IFlightDomainSettingsPostRequestST {
  /**
   *
   * @type {IDroneOperatingHoursScheduleST}
   * @memberof IFlightDomainSettingsPostRequestST
   */
  drone_operating_hours: IDroneOperatingHoursScheduleST;
}
/**
 *
 * @export
 * @interface IFlightDomainSettingsPostResponseST
 */
export interface IFlightDomainSettingsPostResponseST {
  /**
   * the success message
   * @type {string}
   * @memberof IFlightDomainSettingsPostResponseST
   */
  message: string;
}
/**
 *
 * @export
 * @interface IFlightDomainSettingsST
 */
export interface IFlightDomainSettingsST {
  /**
   *
   * @type {IDroneOperatingHoursScheduleST}
   * @memberof IFlightDomainSettingsST
   */
  drone_operating_hours: IDroneOperatingHoursScheduleST;
}
/**
 *
 * @export
 * @interface IOperatingHoursEventBoundaryST
 */
export interface IOperatingHoursEventBoundaryST {
  /**
   * The integer representation of the week-day expressed with the ISO standard (Monday is 1 and Sunday is 7)
   * @type {number}
   * @memberof IOperatingHoursEventBoundaryST
   */
  week_day: number;
  /**
   * Local time of the event boundary
   * @type {string}
   * @memberof IOperatingHoursEventBoundaryST
   */
  timepoint: string;
}
/**
 *
 * @export
 * @interface IOperatingHoursEventST
 */
export interface IOperatingHoursEventST {
  /**
   *
   * @type {IOperatingHoursEventBoundaryST}
   * @memberof IOperatingHoursEventST
   */
  start: IOperatingHoursEventBoundaryST;
  /**
   *
   * @type {IOperatingHoursEventBoundaryST}
   * @memberof IOperatingHoursEventST
   */
  end: IOperatingHoursEventBoundaryST;
}

/**
 * FlightDomainSettingsApi - axios parameter creator
 * @export
 */
export const FlightDomainSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns the settings of the flight domain
     * @summary Returns the settings of the flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainSettings: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFlightDomainSettings', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getFlightDomainSettings', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/settings`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sets the settings of the flight domain
     * @summary Sets the settings of the flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IFlightDomainSettingsPostRequestST} iFlightDomainSettingsPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setFlightDomainSettings: async (
      systemIdStr: string,
      flightDomainId: string,
      iFlightDomainSettingsPostRequestST: IFlightDomainSettingsPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('setFlightDomainSettings', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('setFlightDomainSettings', 'flightDomainId', flightDomainId);
      // verify required parameter 'iFlightDomainSettingsPostRequestST' is not null or undefined
      assertParamExists(
        'setFlightDomainSettings',
        'iFlightDomainSettingsPostRequestST',
        iFlightDomainSettingsPostRequestST,
      );
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/settings`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      // authentication VerityUserDashboard required

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iFlightDomainSettingsPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FlightDomainSettingsApi - functional programming interface
 * @export
 */
export const FlightDomainSettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FlightDomainSettingsApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns the settings of the flight domain
     * @summary Returns the settings of the flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFlightDomainSettings(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFlightDomainSettingsGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFlightDomainSettings(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Sets the settings of the flight domain
     * @summary Sets the settings of the flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IFlightDomainSettingsPostRequestST} iFlightDomainSettingsPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setFlightDomainSettings(
      systemIdStr: string,
      flightDomainId: string,
      iFlightDomainSettingsPostRequestST: IFlightDomainSettingsPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IFlightDomainSettingsPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setFlightDomainSettings(
        systemIdStr,
        flightDomainId,
        iFlightDomainSettingsPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FlightDomainSettingsApi - factory interface
 * @export
 */
export const FlightDomainSettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FlightDomainSettingsApiFp(configuration);
  return {
    /**
     * Returns the settings of the flight domain
     * @summary Returns the settings of the flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFlightDomainSettings(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IFlightDomainSettingsGetResponseST> {
      return localVarFp
        .getFlightDomainSettings(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sets the settings of the flight domain
     * @summary Sets the settings of the flight domain
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IFlightDomainSettingsPostRequestST} iFlightDomainSettingsPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setFlightDomainSettings(
      systemIdStr: string,
      flightDomainId: string,
      iFlightDomainSettingsPostRequestST: IFlightDomainSettingsPostRequestST,
      options?: any,
    ): AxiosPromise<IFlightDomainSettingsPostResponseST> {
      return localVarFp
        .setFlightDomainSettings(
          systemIdStr,
          flightDomainId,
          iFlightDomainSettingsPostRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FlightDomainSettingsApi - object-oriented interface
 * @export
 * @class FlightDomainSettingsApi
 * @extends {BaseAPI}
 */
export class FlightDomainSettingsApi extends BaseAPI {
  /**
   * Returns the settings of the flight domain
   * @summary Returns the settings of the flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainSettingsApi
   */
  public getFlightDomainSettings(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainSettingsApiFp(this.configuration)
      .getFlightDomainSettings(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sets the settings of the flight domain
   * @summary Sets the settings of the flight domain
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IFlightDomainSettingsPostRequestST} iFlightDomainSettingsPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlightDomainSettingsApi
   */
  public setFlightDomainSettings(
    systemIdStr: string,
    flightDomainId: string,
    iFlightDomainSettingsPostRequestST: IFlightDomainSettingsPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return FlightDomainSettingsApiFp(this.configuration)
      .setFlightDomainSettings(
        systemIdStr,
        flightDomainId,
        iFlightDomainSettingsPostRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
