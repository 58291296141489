import { OpenFlyZone } from 'shared/map/model/open-fly-zone.model';
import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';

export type DroneZones = ControlledZone | OpenFlyZone | NoFlyZone;

export enum DroneZoneTypes {
  noFlyZone = 'noFlyZone',
  controlledZone = 'controlledZone',
  openFlyZone = 'openFlyZone',
}

export type DroneZoneType = `${DroneZoneTypes}`;
