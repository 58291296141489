import TableCell from '@mui/material/TableCell';
import { HeadCellProp, RowProp } from '../../../types/cell';

type EnhancedTableCellCustomProps = {
  row: RowProp<any>;
  cell: HeadCellProp;
};

/**
 * Enhanced table custom cell
 * @param props EnhancedTableCellCustomProps
 * @returns component
 */
export const EnhancedTableCellCustom = ({ cell, row }: EnhancedTableCellCustomProps) => (
  <TableCell data-testid={`c-${cell.id}`} align={cell.align} key={`cell-${row.id}-${cell.id}`}>
    {row[cell.id]}
  </TableCell>
);
