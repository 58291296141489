import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';
import { CommissioningTask } from '../model/commissioningTask.model';
import { abortCommissioningTasks } from '../../../../services/TaskApiService';

export const AbortCommissioningTasksModal = (
  abortTask: CommissioningTask | undefined,
  systemId: string,
  onConfirm: () => void,
  onClose: () => void,
  refreshData: () => void,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
) => (
  <ModalConfirm
    opened={true}
    title="Abort Commissioning Task"
    message={`Are you sure you want to Abort task: ${abortTask?.name}?`}
    handleClose={onClose}
    onConfirm={() => {
      onClose();
      onConfirm();
      abortTask &&
        abortCommissioningTasks(systemId, abortTask.id).then(() => {
          refreshData();
          enqueueSnackbar(`Aborted task: ${abortTask.name}`, {
            variant: 'success',
          });
        });
    }}
    confirmLabel="Abort"
    cancelLabel="Cancel"
  />
);
