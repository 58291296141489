import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const noFlyZoneCreatorPlaceholderStyle = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    height: '100%',
    padding: theme.spacing(2),
  },
  body: {
    margin: 'auto',
    flex: '1 1 auto',
    minWidth: 0,
  },
}));
