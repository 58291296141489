import ct, { Country, CountryCode } from 'countries-and-timezones';

export const getTimezones = (country: any) => {
  const countries = getAllCountries();
  const filteredCountry = countries.find((ct: { value: any }) => ct.value === country);
  return filteredCountry?.timezones?.map((tz: any) => ({
    label: tz,
    value: tz,
  }));
};

export const getAllCountries = () => {
  const countries: Record<CountryCode, Country> = ct.getAllCountries();
  return Object.values(countries).map((ct: any) => ({
    value: ct.name,
    label: ct.id,
    timezones: ct.timezones,
  }));
};
