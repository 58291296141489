import { LocalStore } from 'common/functions/storageFunctions';
import { Configuration, WarehouseStatusApi } from 'codegen/warehouse_status';

/**
 * Init warehouse status service
 * @returns api service
 */
export const initWarehouseStatusService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new Configuration({ apiKey: idToken });
  const apiService = new WarehouseStatusApi(config);
  return apiService;
};
