import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const issueStyles = makeStyles()((theme: Theme) => ({
  errorBadge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.textPrimary.light,
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3),
    borderRadius: '5px',
    fontWeight: 'bold',
  },
  routerLink: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  disabledLink: {
    color: theme.palette.disabled,
    '&:hover': {
      cursor: 'not-allowed',
      textDecoration: 'none',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  issueOverview: {
    marginBottom: theme.spacing(3),
  },
  cardHeader: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    color: theme.palette.textPrimary.main,
  },
  lineHeight11: {
    lineHeight: 1.1,
  },
  contentFoundExpectedSet: {
    lineHeight: 1.5,
  },
  barcodeLocationInfo: {
    lineHeight: 1.3,
    marginTop: 10,
  },
  issueTableCard: {
    margin: 0,
  },
  issueSolvingStepsCard: {
    marginBottom: theme.spacing(3),
  },
  issueSolvingSteps: {
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  imageGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '200px',
    maxHeight: '800px',
    alignItems: 'center',
    width: '1000px',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  formGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& img': {
      display: 'none !important',
    },
  },
  navigationButtons: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
  },
  navigationButton: {
    margin: theme.spacing(0, 1),
    cursor: 'pointer',
  },
  actionButton: {
    flex: '1',
    margin: '8px 0',
    lineHeight: '1.3',
  },
  subtitleAndNavigation: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));
