export const tabDataCommissioning = [
  {
    icon: undefined,
    label: 'ALL TASKS',
    order: 'desc',
    orderBy: 'finishedAt',
    show: true,
  },
  {
    icon: undefined,
    label: 'UPCOMING',
    order: 'desc',
    orderBy: 'finishedAt',
    show: true,
  },
  {
    icon: undefined,
    label: 'ONGOING',
    order: 'desc',
    orderBy: 'finishedAt',
    show: true,
  },
  {
    icon: undefined,
    label: 'COMPLETED',
    order: 'desc',
    orderBy: 'finishedAt',
    show: true,
  },
  {
    icon: undefined,
    label: 'ABORTED',
    order: 'desc',
    orderBy: 'finishedAt',
    show: true,
  },
];
