import { useEffect } from 'react';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { sortDroneZonesByName } from 'shared/map/utils/sort-drone-zones-by-name/sortDroneZonesByName.util';
import { BackDropLoader } from 'components/BackDropLoader';
import { DroneZonesListProps } from './droneZonesList.model';
import { ControlledZonesListItem } from './features/controlled-zones-list-item/ControlledZonesListItem';
import { DroneZonePlaceholder } from '../drone-zone-placeholder/droneZonePlaceholder';
import { controlledZoneListStyles } from './controlledZonesList.styles';
import { zoneElementFromSelectedZone } from '../utils/zoneElementFromSelectedZone';

export const ControlledZonesList = ({
  zones = {},
  isLoading,
  onClone,
  onSelect,
  onEdit,
  onDelete,
}: DroneZonesListProps<ControlledZones, ControlledZone>) => {
  const { classes, cx } = controlledZoneListStyles();

  const focusedZoneElement = zoneElementFromSelectedZone(zones);

  useEffect(() => {
    focusedZoneElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [focusedZoneElement]);

  const sortedZones = sortDroneZonesByName(Object.values(zones));

  const isListVisible = !isLoading && sortedZones.length > 0;
  const isPlaceholderVisible = !isLoading && sortedZones.length === 0;

  return (
    <div className={classes.wrapper}>
      <BackDropLoader isVisible={isLoading} showLoader={isLoading} />

      {isListVisible && (
        <ul className={cx(classes.wrapperBody, classes.zoneListWrapper)}>
          {sortedZones.map((zone) => (
            <ControlledZonesListItem
              key={zone.id}
              droneZone={zone}
              onSelect={onSelect}
              onEdit={onEdit}
              onDelete={onDelete}
              onClone={onClone}
            />
          ))}
        </ul>
      )}

      {isPlaceholderVisible && (
        <div className={classes.wrapperBody}>
          <DroneZonePlaceholder message='There are no "controlled zones"' />
        </div>
      )}
    </div>
  );
};
