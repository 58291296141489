import { DroneZones } from '../../model/drone-zones.model';
import { bottomLeftDimensionsToSizeAndPos } from '../../../map-container/utils/creation.util';

export const calculateOverlapPercentage = (zone1: DroneZones, zone2: DroneZones): number => {
  const offset1 = bottomLeftDimensionsToSizeAndPos(zone1.sizeAndPosition);
  const offset2 = bottomLeftDimensionsToSizeAndPos(zone2.sizeAndPosition);

  const minX = Math.max(offset1.x - offset1.w / 2, offset2.x - offset2.w / 2);
  const maxX = Math.min(offset1.x + offset1.w / 2, offset2.x + offset2.w / 2);
  const minY = Math.max(offset1.y - offset1.l / 2, offset2.y - offset2.l / 2);
  const maxY = Math.min(offset1.y + offset1.l / 2, offset2.y + offset2.l / 2);

  const width = Math.max(0, maxX - minX);
  const height = Math.max(0, maxY - minY);

  const overlapVolume = width * height;
  const offset1Volume = offset1.w * offset1.l;
  const offset2Volume = offset2.w * offset2.l;

  const minVolume = Math.min(offset1Volume, offset2Volume);

  return Math.round((overlapVolume / minVolume) * 100);
};
