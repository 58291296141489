import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formHeader: {
    position: 'sticky',
    flex: '0 0 auto',
    top: 0,
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    fontWeight: 500,
    padding: theme.spacing(2),
    background: 'white',
  },
  formBody: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'auto',
    padding: theme.spacing(1, 2),
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(2),
  },
  formSection: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
  },
  formFooter: {
    display: 'flex',
    padding: theme.spacing(2),
    flex: '0 0 auto',
    position: 'sticky',
    bottom: 0,
    background: 'white',
  },
}));
