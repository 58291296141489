/* tslint:disable */
/* eslint-disable */
/**
 * Facility
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
}
/**
 *
 * @export
 * @interface IFacilityGetResponseFacilityST
 */
export interface IFacilityGetResponseFacilityST {
  /**
   * Client name
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  client: string;
  /**
   * Country name
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  country: string;
  /**
   * Creating datetime as string
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  created_at: string;
  /**
   * Latitude
   * @type {number}
   * @memberof IFacilityGetResponseFacilityST
   */
  latitude: number;
  /**
   * URL for logo image
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  logo_url: string;
  /**
   * Longitude
   * @type {number}
   * @memberof IFacilityGetResponseFacilityST
   */
  longitude: number;
  /**
   * Facility name
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  name: string;
  /**
   * Store ID
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  store_id: string;
  /**
   * System ID
   * @type {number}
   * @memberof IFacilityGetResponseFacilityST
   */
  system_id: number;
  /**
   * Timezone info
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  timezone: string;
  /**
   * Version
   * @type {number}
   * @memberof IFacilityGetResponseFacilityST
   */
  version: number;
  /**
   * Bucket key, UUID as string
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_bucket_key: string;
  /**
   * Creation time of the map
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_created_at: string;
  /**
   * Facility map file name
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_file_name: string;
  /**
   * Facility map name
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_name: string;
  /**
   * The number of nodes in the facility map
   * @type {number}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_num_nodes: number;
  /**
   * The number of slots in the facility map
   * @type {number}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_num_slots: number;
  /**
   * Key of the bucket object storing the map processing logs
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_processing_log_bucket_key: string;
  /**
   * Set to true if the map was imported correctly
   * @type {boolean}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_valid: boolean;
  /**
   * Facility map version
   * @type {string}
   * @memberof IFacilityGetResponseFacilityST
   */
  facility_map_version: string;
}
/**
 *
 * @export
 * @interface IFacilityGetResponseST
 */
export interface IFacilityGetResponseST {
  /**
   *
   * @type {IFacilityGetResponseFacilityST}
   * @memberof IFacilityGetResponseST
   */
  facility: IFacilityGetResponseFacilityST;
  /**
   * Slot labels
   * @type {Array<string>}
   * @memberof IFacilityGetResponseST
   */
  slots?: Array<string>;
}
/**
 * @type IFacilityInfoGetResponseST
 * @export
 */
export type IFacilityInfoGetResponseST = IFacilityInfoST | object;

/**
 *
 * @export
 * @interface IFacilityInfoST
 */
export interface IFacilityInfoST {
  /**
   * Client name
   * @type {string}
   * @memberof IFacilityInfoST
   */
  client: string;
  /**
   * Country name
   * @type {string}
   * @memberof IFacilityInfoST
   */
  country: string;
  /**
   * Creating datetime as string
   * @type {string}
   * @memberof IFacilityInfoST
   */
  created_at: string;
  /**
   * Latitude
   * @type {number}
   * @memberof IFacilityInfoST
   */
  latitude: number;
  /**
   * URL for logo image
   * @type {string}
   * @memberof IFacilityInfoST
   */
  logo_url: string;
  /**
   * Longitude
   * @type {number}
   * @memberof IFacilityInfoST
   */
  longitude: number;
  /**
   * Facility name
   * @type {string}
   * @memberof IFacilityInfoST
   */
  name: string;
  /**
   * Store ID
   * @type {string}
   * @memberof IFacilityInfoST
   */
  store_id: string;
  /**
   * System ID
   * @type {number}
   * @memberof IFacilityInfoST
   */
  system_id: number;
  /**
   * Timezone info
   * @type {string}
   * @memberof IFacilityInfoST
   */
  timezone: string;
  /**
   * Version
   * @type {number}
   * @memberof IFacilityInfoST
   */
  version: number;
}
/**
 *
 * @export
 * @interface IFacilityLogosGetResponseST
 */
export interface IFacilityLogosGetResponseST {
  /**
   *
   * @type {Array<string>}
   * @memberof IFacilityLogosGetResponseST
   */
  logo_urls: Array<string>;
}
/**
 * @type IFacilityMapInfoGetResponseST
 * @export
 */
export type IFacilityMapInfoGetResponseST = IFacilityMapInfoST | object;

/**
 *
 * @export
 * @interface IFacilityMapInfoST
 */
export interface IFacilityMapInfoST {
  /**
   * Bucket key, UUID as string
   * @type {string}
   * @memberof IFacilityMapInfoST
   */
  facility_map_bucket_key: string;
  /**
   * Creation time of the map
   * @type {string}
   * @memberof IFacilityMapInfoST
   */
  facility_map_created_at: string;
  /**
   * Facility map file name
   * @type {string}
   * @memberof IFacilityMapInfoST
   */
  facility_map_file_name: string;
  /**
   * Facility map name
   * @type {string}
   * @memberof IFacilityMapInfoST
   */
  facility_map_name: string;
  /**
   * The number of nodes in the facility map
   * @type {number}
   * @memberof IFacilityMapInfoST
   */
  facility_map_num_nodes: number;
  /**
   * The number of slots in the facility map
   * @type {number}
   * @memberof IFacilityMapInfoST
   */
  facility_map_num_slots: number;
  /**
   * Key of the bucket object storing the map processing logs
   * @type {string}
   * @memberof IFacilityMapInfoST
   */
  facility_map_processing_log_bucket_key: string;
  /**
   * Set to true if the map was imported correctly
   * @type {boolean}
   * @memberof IFacilityMapInfoST
   */
  facility_map_valid: boolean;
  /**
   * Facility map version
   * @type {string}
   * @memberof IFacilityMapInfoST
   */
  facility_map_version: string;
}
/**
 *
 * @export
 * @interface IFacilityPostResponseST
 */
export interface IFacilityPostResponseST {
  /**
   *
   * @type {IPresignedPostST}
   * @memberof IFacilityPostResponseST
   */
  presigned_post: IPresignedPostST;
}
/**
 *
 * @export
 * @interface IFacilityPresignedURLGetResponseST
 */
export interface IFacilityPresignedURLGetResponseST {
  /**
   * The presigned URL to download the requested file
   * @type {string}
   * @memberof IFacilityPresignedURLGetResponseST
   */
  url: string;
}
/**
 *
 * @export
 * @interface IFacilitySlotsGetResponseST
 */
export interface IFacilitySlotsGetResponseST {
  /**
   * Slot labels
   * @type {Array<string>}
   * @memberof IFacilitySlotsGetResponseST
   */
  slots: Array<string>;
}
/**
 *
 * @export
 * @interface IPresignedPostST
 */
export interface IPresignedPostST {
  /**
   * The presigned post URL
   * @type {string}
   * @memberof IPresignedPostST
   */
  url: string;
  /**
   * The presigned post fields
   * @type {object}
   * @memberof IPresignedPostST
   */
  fields: object;
}

/**
 * FacilityApi - axios parameter creator
 * @export
 */
export const FacilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Return Facility details.
     * @summary Return Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacility: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacility', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return the facility information,
     * @summary Return the facility information.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityInformation: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilityInformation', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/info`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return available logo URLs for Facility.
     * @summary Return logo URLs for Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityLogos: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilityLogos', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/logos`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return information about the current map file.
     * @summary Return information about the current map file.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityMapInfo: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilityMapInfo', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/map/info`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return presigned URLs to download the facility map file.
     * @summary Return presigned URLs to download the facility map file.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityMapPresignedUrl: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilityMapPresignedUrl', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/map/file`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return presigned URLs to download the facility processing logs.
     * @summary Return presigned URLs to download the facility processing logs.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityProcessingLog: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilityProcessingLog', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/map/processing-log`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return the list of slots available in the current version of the map
     * @summary Return the list of slots.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilitySlots: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getFacilitySlots', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility/slots`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return Facility presigned post URL.
     * @summary Return Facility presigned post URL.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFacilityPresignedUrl: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('postFacilityPresignedUrl', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/facility`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FacilityApi - functional programming interface
 * @export
 */
export const FacilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FacilityApiAxiosParamCreator(configuration);
  return {
    /**
     * Return Facility details.
     * @summary Return Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacility(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityGetResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacility(systemIdStr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return the facility information,
     * @summary Return the facility information.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilityInformation(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityInfoGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityInformation(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return available logo URLs for Facility.
     * @summary Return logo URLs for Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilityLogos(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityLogosGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityLogos(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return information about the current map file.
     * @summary Return information about the current map file.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilityMapInfo(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityMapInfoGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityMapInfo(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return presigned URLs to download the facility map file.
     * @summary Return presigned URLs to download the facility map file.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilityMapPresignedUrl(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityPresignedURLGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityMapPresignedUrl(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return presigned URLs to download the facility processing logs.
     * @summary Return presigned URLs to download the facility processing logs.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilityProcessingLog(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityPresignedURLGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilityProcessingLog(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return the list of slots available in the current version of the map
     * @summary Return the list of slots.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFacilitySlots(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilitySlotsGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFacilitySlots(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return Facility presigned post URL.
     * @summary Return Facility presigned post URL.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postFacilityPresignedUrl(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFacilityPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postFacilityPresignedUrl(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FacilityApi - factory interface
 * @export
 */
export const FacilityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FacilityApiFp(configuration);
  return {
    /**
     * Return Facility details.
     * @summary Return Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacility(systemIdStr: string, options?: any): AxiosPromise<IFacilityGetResponseST> {
      return localVarFp
        .getFacility(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return the facility information,
     * @summary Return the facility information.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityInformation(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilityInfoGetResponseST> {
      return localVarFp
        .getFacilityInformation(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return available logo URLs for Facility.
     * @summary Return logo URLs for Facility.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityLogos(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilityLogosGetResponseST> {
      return localVarFp
        .getFacilityLogos(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return information about the current map file.
     * @summary Return information about the current map file.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityMapInfo(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilityMapInfoGetResponseST> {
      return localVarFp
        .getFacilityMapInfo(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return presigned URLs to download the facility map file.
     * @summary Return presigned URLs to download the facility map file.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityMapPresignedUrl(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilityPresignedURLGetResponseST> {
      return localVarFp
        .getFacilityMapPresignedUrl(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return presigned URLs to download the facility processing logs.
     * @summary Return presigned URLs to download the facility processing logs.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilityProcessingLog(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilityPresignedURLGetResponseST> {
      return localVarFp
        .getFacilityProcessingLog(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return the list of slots available in the current version of the map
     * @summary Return the list of slots.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFacilitySlots(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilitySlotsGetResponseST> {
      return localVarFp
        .getFacilitySlots(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return Facility presigned post URL.
     * @summary Return Facility presigned post URL.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postFacilityPresignedUrl(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IFacilityPostResponseST> {
      return localVarFp
        .postFacilityPresignedUrl(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FacilityApi - object-oriented interface
 * @export
 * @class FacilityApi
 * @extends {BaseAPI}
 */
export class FacilityApi extends BaseAPI {
  /**
   * Return Facility details.
   * @summary Return Facility.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacility(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacility(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return the facility information,
   * @summary Return the facility information.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacilityInformation(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacilityInformation(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return available logo URLs for Facility.
   * @summary Return logo URLs for Facility.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacilityLogos(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacilityLogos(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return information about the current map file.
   * @summary Return information about the current map file.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacilityMapInfo(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacilityMapInfo(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return presigned URLs to download the facility map file.
   * @summary Return presigned URLs to download the facility map file.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacilityMapPresignedUrl(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacilityMapPresignedUrl(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return presigned URLs to download the facility processing logs.
   * @summary Return presigned URLs to download the facility processing logs.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacilityProcessingLog(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacilityProcessingLog(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return the list of slots available in the current version of the map
   * @summary Return the list of slots.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public getFacilitySlots(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .getFacilitySlots(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return Facility presigned post URL.
   * @summary Return Facility presigned post URL.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FacilityApi
   */
  public postFacilityPresignedUrl(systemIdStr: string, options?: AxiosRequestConfig) {
    return FacilityApiFp(this.configuration)
      .postFacilityPresignedUrl(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
