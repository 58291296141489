/*
 * Action types
 */
export enum ClientModalsActionTypes {
  RESET = 'RESET',
  CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL',
  TOGGLE_SIGNIN_MODAL = 'TOGGLE_SIGNIN_MODAL',
  SELECT_FACILITY_MODAL = 'SELECT_FACILITY_MODAL',
  TOGGLE_CONFIRM_MODAL = 'TOGGLE_CONFIRM_MODAL',
}
