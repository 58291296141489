import { Button, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { zoneValueToLocaleFixedDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { FormInput } from 'components/FormFields/FormInput';
import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { allNumbersToFixed2, toFixed2 } from 'shared/numberFormatting';
import { FormInputNumber } from 'components/FormFields/FormInputNumber/FormInputNumber';
import { DroneZoneFormProps } from './DroneZoneForm.model';
import { useStyles } from './DroneZoneForm.styles';

import { validateDroneZoneForm } from './utils/validateDroneZoneForm';
import { createControlledZone } from './utils/createControlledZone';
import { updateControlledZone } from './utils/updateControlledZone';

const DRONE_ZONE_TITLE_FROM_TYPE = {
  [DroneZoneTypes.controlledZone]: 'Controlled zone',
};

export const DroneZoneForm = ({
  flightDomainId,
  systemId,
  droneZone,
  worldBox,
  onCancel,
  onUpdate,
  onSubmit,
}: DroneZoneFormProps) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formValues = useRef<FormikProps<ControlledZone>>(null);
  const originalDroneZone = useRef(droneZone);

  const inputProps = { step: 0.5, min: 1 };
  // @ts-expect-error zone.type can include NFZ (as we use the same base for types), do we separate this for delivery and nondelivery types?
  const title = DRONE_ZONE_TITLE_FROM_TYPE[droneZone.type];

  const {
    minX: facilityMinX,
    maxX: facilityMaxX,
    minY: facilityMinY,
    maxY: facilityMaxY,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(worldBox);

  const defaultValues = {
    ...droneZone,
    sizeAndPosition: allNumbersToFixed2(droneZone.sizeAndPosition),
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let fieldName = e.target.name as keyof ControlledZone;
    let fieldValue: string | number | object = e.target.value;
    const isSizeOrPosition = fieldName.includes('size') || fieldName.includes('position');

    const droneZoneFormValues = formValues?.current?.values;

    if (isSizeOrPosition) {
      const fieldNameSplit = fieldName.split('.');
      fieldName = fieldNameSplit[0] as keyof ControlledZone;
      const keyName = fieldNameSplit[1] as keyof ControlledZone['sizeAndPosition'];

      fieldValue = {
        ...(droneZoneFormValues?.sizeAndPosition ?? {}),
        [keyName]: toFixed2(Number(fieldValue)),
      };
    }

    if (droneZoneFormValues) {
      onUpdate({ ...droneZoneFormValues, [fieldName]: fieldValue });
    }
  };

  const handleSubmit = async (droneZone: ControlledZone) => {
    const isNew = droneZone.id === NEW_DRONE_ZONE_ID;

    if (isNew) {
      await createDroneZone(droneZone);
    } else {
      await updateDroneZone(droneZone);
    }
  };

  const createDroneZone = async (droneZone: ControlledZone) => {
    let response;

    if (droneZone.type === DroneZoneTypes.controlledZone) {
      try {
        response = await createControlledZone(droneZone, systemId!, flightDomainId!);
      } catch (error) {
        const err = error as AxiosError<Error, Error>;
        const message =
          err?.response?.data?.message ||
          'Something went wrong when creating drone "Controlled zone"';

        enqueueSnackbar(message, { variant: 'error' });
      }
    }

    if (response) {
      onSubmit(droneZone);
    }
  };

  const updateDroneZone = async (droneZone: ControlledZone) => {
    let response;

    if (droneZone.type === DroneZoneTypes.controlledZone) {
      try {
        response = await updateControlledZone(droneZone, systemId!, flightDomainId!);
      } catch (error) {
        const err = error as AxiosError<Error, Error>;
        const message =
          err?.response?.data?.message ||
          'Something went wrong when updating drone "Controlled zone"';

        enqueueSnackbar(message, { variant: 'error' });
      }
    }

    if (response) {
      onSubmit(droneZone);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      innerRef={formValues}
      onSubmit={handleSubmit}
      validate={validateDroneZoneForm(worldBox)}
    >
      {({ values }) => (
        <Form noValidate className={classes.form}>
          <Typography variant="h1" className={classes.formHeader}>
            {`${title} details`}
          </Typography>

          <div className={classes.formBody}>
            <FormInput
              name="name"
              label={`${title} name`}
              required
              onChange={handleChange}
              fullWidth
            />

            <FormInput
              name="description"
              label="Description"
              multiline
              fullWidth
              onChange={handleChange}
            />

            <div className={classes.formSection}>
              <FormInputNumber
                inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                name="sizeAndPosition.minZ"
                label={`Bottom (m) - max: ${zoneValueToLocaleFixedDecimalPlaces(facilityMaxZ)}m`}
                fullWidth
                onChange={handleChange}
              />

              <FormInputNumber
                inputProps={{ ...inputProps, min: 0, max: facilityMaxZ }}
                name="sizeAndPosition.maxZ"
                label={`Top (m) - max: ${zoneValueToLocaleFixedDecimalPlaces(facilityMaxZ)}m`}
                fullWidth
                onChange={handleChange}
              />
            </div>

            <FormInputNumber
              disabled
              label="Height (m)"
              name="sizeAndPosition.h"
              helperText=""
              value={toFixed2(values.sizeAndPosition.maxZ - values.sizeAndPosition.minZ)}
              error={false}
              fullWidth
            />

            <FormInputNumber
              name="sizeAndPosition.w"
              label="Width (m)"
              inputProps={{
                ...inputProps,
                min: 0,
                max: toFixed2(facilityMaxX - values.sizeAndPosition.minX),
              }}
              fullWidth
              onChange={handleChange}
            />

            <FormInputNumber
              name="sizeAndPosition.l"
              label="Length (m)"
              inputProps={{
                ...inputProps,
                min: 0,
                max: toFixed2(facilityMaxY - values.sizeAndPosition.minY),
              }}
              fullWidth
              onChange={handleChange}
            />

            <div className={classes.formSection}>
              <FormInputNumber
                name="sizeAndPosition.minX"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinX,
                  max: toFixed2(facilityMaxX - values.sizeAndPosition.w),
                }}
                onChange={handleChange}
                label="X-Position (m)"
                fullWidth
              />

              <FormInputNumber
                name="sizeAndPosition.minY"
                inputProps={{
                  step: inputProps.step,
                  min: facilityMinY,
                  max: toFixed2(facilityMaxY - values.sizeAndPosition.l),
                }}
                fullWidth
                onChange={handleChange}
                label="Y-Position (m)"
              />
            </div>
          </div>

          <div className={classes.formFooter}>
            <Button
              variant="outlined"
              sx={{ flex: 1, mr: 1 }}
              onClick={() => onCancel(originalDroneZone.current)}
            >
              Cancel
            </Button>

            <Button variant="contained" sx={{ flex: 1 }} type="submit">
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
