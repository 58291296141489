import { findLast, isArray, isEmpty } from 'lodash';
import { GridValueGetter } from '@mui/x-data-grid-premium';
import { replaceArrayItems } from 'common/functions/arrayFunctions';
import { DISPLAY_WMS_STATES, DISPLAY_VERITY_STATES } from 'common/slotStates';
import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { getIssueType } from 'common/functions/issues/issueColorFunctions';
import { formatShortDate, formatShortDateTime } from './formatShortDate';
import {
  getExpectedBarcodes,
  getFoundBarcodes,
  getMatchingBarcodes,
  getMissingBarcodes,
  getUnknownBarcodes,
} from './barcodeFunctions';

const emptyCellString = '-';

export const exclusionStatusValueGetter: GridValueGetter<ILocationDataST, boolean> = (
  value,
  row,
): boolean => {
  const wmsStatus = row.wms_status as IWMSSlotStatusST | undefined;

  const exclusionStatusWMS = wmsStatus?.exclusion_status;
  const exclusionStatusVerity = row.slot_settings?.exclusion_status;

  if (exclusionStatusVerity === 'EXCLUDE' || exclusionStatusWMS === 'EXCLUDE') {
    return false;
  }

  return true;
};

export const expectedContentValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): string => {
  const wmsStatus = row.wms_status;

  if (!wmsStatus || typeof wmsStatus === 'string') {
    return wmsStatus || '';
  }

  let content: string = ' ';

  const { barcodes } = wmsStatus;

  const nonEmptyBarcodes = barcodes.filter((b) => !!b);

  if (nonEmptyBarcodes.length === 1) {
    const barcode = nonEmptyBarcodes[0];
    content = barcode;
  } else if (nonEmptyBarcodes.length > 1) {
    const list = nonEmptyBarcodes.map((barcode) => barcode);
    content = list.join(', ');
  } else {
    content = DISPLAY_WMS_STATES[wmsStatus.state];
  }

  return content;
};

export const wasFoundAtValueGetter: GridValueGetter<ILocationDataST> = (v, row): string => {
  const issue = row.issues && row.issues[0];
  const slotStatus: IVeritySlotStatusST | null =
    issue && issue.verity_slot_status_with_wms_bc !== 'string'
      ? (issue.verity_slot_status_with_wms_bc as IVeritySlotStatusST)
      : null;

  let value = emptyCellString;
  value = slotStatus && slotStatus.slot_label !== issue.location ? slotStatus.slot_label : value;

  return value;
};

export const wmsArticleNumberValueGetter: GridValueGetter<ILocationDataST> = (value, row): string =>
  (typeof row.wms_status !== 'string' && row.wms_status?.article_nos[0]) || emptyCellString;

export const wmsQuantityValueGetter: GridValueGetter<ILocationDataST> = (value, row) => {
  const values =
    typeof row.wms_status !== 'string' &&
    isArray(row.wms_status?.qtys) &&
    !isEmpty(row.wms_status?.qtys)
      ? replaceArrayItems(row.wms_status?.qtys, null, emptyCellString)
      : [emptyCellString];

  return values?.join(', ');
};

export const customerValueGetter: GridValueGetter<ILocationDataST> = (value, row): string =>
  (typeof row.wms_status !== 'string' &&
    row.wms_status?.customers &&
    row.wms_status?.customers[0]) ||
  emptyCellString;

export const wmsDateValueGetter: GridValueGetter<ILocationDataST, Date | null> = (value, row) => {
  const dateTime = typeof row.wms_status !== 'string' && row.wms_status?.changed_at;
  if (!dateTime) {
    return null;
  }
  return new Date(dateTime);
};

export const wmsDateGroupingValueGetter: GridValueGetter<ILocationDataST, string> = (
  value,
  row,
) => {
  const dateTimeString = typeof row.wms_status !== 'string' && row.wms_status?.changed_at;
  if (!dateTimeString) {
    return 'N/A';
  }

  const dateTime = new Date(dateTimeString);
  return formatShortDate(dateTime);
};

export const contentFoundValueGetter: GridValueGetter<ILocationDataST> = (value, row): string => {
  const verityStatus = row.verity_status as IVeritySlotStatusST;

  if (!verityStatus || typeof verityStatus === 'string') {
    return verityStatus;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  let content: string = emptyCellString;

  const showOverrides = userOverrides?.length;

  const barcodes = showOverrides
    ? userOverrides![userOverrides!.length - 1].barcodes
    : verityBarcodes;

  if (barcodes.length === 1) {
    const barcode = barcodes[0];
    content = `${barcode} ${showOverrides ? ' *' : ''}`;
  } else if (barcodes.length > 1) {
    const list = barcodes.map((barcode) => `${barcode} ${showOverrides ? ' *' : ''}`);
    content = list.join(', ');
  } else {
    content = DISPLAY_VERITY_STATES[verityStatus.state];
  }

  return content;
};

export const shouldBeAtValueGetter: GridValueGetter<ILocationDataST> = (value, row): string => {
  const issue = row.issues && row.issues[0];
  const slotStatus: IVeritySlotStatusST | null =
    issue && issue.verity_slot_status_with_wms_bc !== 'string'
      ? (issue.verity_slot_status_with_wms_bc as IVeritySlotStatusST)
      : null;

  const clientOverride =
    issue && issue.slot_status.verity_status?.user_overrides
      ? findLast(issue.slot_status.verity_status?.user_overrides, (uo) => !uo.review)
      : null;

  const outputValue =
    !clientOverride && slotStatus?.slot_label ? slotStatus?.slot_label : emptyCellString;

  return outputValue;
};

export const expectedBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const expected = getExpectedBarcodes(row);
  return expected ? expected.length : emptyCellString;
};

export const foundBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const found = getFoundBarcodes(row);
  return found ? found.length : emptyCellString;
};

export const missingBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const missing = getMissingBarcodes(row);
  return missing ? missing.length : emptyCellString;
};

export const missingBarcodesPercentageValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const missing = getMissingBarcodes(row);
  const expected = getExpectedBarcodes(row);

  return missing && expected ? missing.length / expected.length : emptyCellString;
};

export const matchingBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const matching = getMatchingBarcodes(row);
  return matching ? matching.length : emptyCellString;
};

export const matchingBarcodesPercentageValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const matching = getMatchingBarcodes(row);
  const expected = getExpectedBarcodes(row);

  return matching && expected ? matching.length / expected.length : emptyCellString;
};

export const unknownBarcodesValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const unknown = getUnknownBarcodes(row);
  return unknown ? unknown.length : emptyCellString;
};

export const unknownBarcodesPercentageValueGetter: GridValueGetter<ILocationDataST> = (
  value,
  row,
): number | '-' => {
  const unknown = getUnknownBarcodes(row);
  const found = getFoundBarcodes(row);

  return unknown && found ? unknown.length / found.length : emptyCellString;
};

export const issueValueGetter: GridValueGetter<ILocationDataST> = (value, row): string => {
  if (!row.issues || !row.issues[0]) return 'No Issue';
  const { label } = getIssueType(row.issues[0].type);
  return label;
};

export const issueStatusValueGetter: GridValueGetter<ILocationDataST> = (value, row): string =>
  (row.issues && row.issues[0]?.state) || 'NONE';

export const foundDateValueGetter: GridValueGetter<ILocationDataST, Date | null> = (value, row) => {
  const dateTime = typeof row.verity_status !== 'string' && row.verity_status?.collected_at;
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime);
};

export const foundDateGroupingValueGetter: GridValueGetter<ILocationDataST, string> = (
  value,
  row,
) => {
  const dateTimeString = typeof row.verity_status !== 'string' && row.verity_status?.collected_at;
  if (!dateTimeString) {
    return 'N/A';
  }

  const dateTime = new Date(dateTimeString);
  return formatShortDate(dateTime);
};

export const firstFoundOnValueGetter: GridValueGetter<ILocationDataST, Date | null> = (
  value,
  row,
) => {
  const dateTime = row.issues && row.issues[0]?.first_found_on;
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime);
};

export const firstFoundOnGroupingValueGetter: GridValueGetter<ILocationDataST, string> = (
  value,
  row,
): string => {
  const dateTimeString = row.issues[0]?.first_found_on;
  if (!dateTimeString) {
    return 'N/A';
  }

  const dateTime = new Date(dateTimeString);
  return formatShortDate(dateTime);
};

export const dateTimeValueFormatter = (value: Date | null) => {
  if (value) {
    return formatShortDateTime(value);
  }
  return null;
};
