import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { isWMSStateValid, hasNoOverrideAndNoMismatch, hasOverrideToWMS } from './amendFunctions';
import { OVERWRITE_MODES } from '../../AmendValues.model';

export const getOptionOverwriteWithWms = (locationData: ILocationData) => ({
  disabled:
    !isWMSStateValid(locationData) ||
    hasNoOverrideAndNoMismatch(locationData) ||
    hasOverrideToWMS(locationData),
  value: OVERWRITE_MODES.USE_WMS,
  label: `Use expected content value: ${
    locationData.wmsValue === '' ? 'Empty Location' : locationData.wmsValue
  }`,
});
