import { IFlightDomainData } from '../../../../store/GroundControl/IGroundControlStore';

/**
 * Get the current flight domain: this is necessary because the
 * "Delivery" page it does not implement a full fledge GC page
 * @param flightDomains list of all flight domains
 * @param flightDomainId ID
 * @returns current FD
 */
export const getCurrentFlightDomain = (
  flightDomains: IFlightDomainData[],
  flightDomainId: string,
) => flightDomains.filter((fd) => fd.flight_domain_id === flightDomainId)[0];
