import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  detailsProperties: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(2),
  },
  detailsPropertiesItem: {
    display: 'flex',
    gap: theme.spacing(1),
    margin: 0,
    fontSize: 14,
    paddingBlockEnd: '2px',
    borderBlockEnd: `1px dashed ${theme.palette.divider}`,

    '> span': {
      display: 'inline-block',
      minWidth: 0,
    },

    '> span:first-of-type': {
      flex: '1 0 0',
    },

    '> span:last-of-type': {
      flex: '2 0 0',
      textAlign: 'end',
    },
  },
}));
