// libraries
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

// material-ui core
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

// material-ui icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// helpers, services & store

// variables, functions, configurations
import { signIn } from 'common/functions/userFunctions';
import { singleRequestHandler } from 'common/requestHelpers';
import { AUTH_PAGES_URLS, CLIENT_PAGES_URLS } from 'common/pages';

// styles

// components
import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';
import ModalBase from 'components/ModalsAndPopups/ModalBase';
import Copyright from 'components/common/Copyright';
import InputPassword from 'components/common/CustomFormComponents/InputPassword';
import PasswordHelper from 'components/PasswordHelper/PasswordHelper';
import sharedStyles from './styles';
import { useRequestController } from '../../hooks';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import AuthStore from '../../store/AuthStore';
import { UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';

const ForgotPassword = (props) => {
  // props
  const { requiresCode } = props;
  const { classes } = sharedStyles();
  // state variables
  const [spinner, setSpinner] = useState(0);
  const [values, setValues] = useState({
    code: '',
    password: '',
    confirmPassword: '',
  });
  const [successModal, setModal] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  // snackbar hook
  const { enqueueSnackbar } = useSnackbar();

  // Context and navigate
  const { stateUserLevel, dispatchUserLevelStore } = useUserLevelStore();
  const navigate = useNavigate();
  // Client level state and navigate

  const { requestController } = useRequestController('new Password');

  const session = localStorage.getItem('session');

  const signinClicked = (username, password) => {
    localStorage.removeItem('session');
    signIn({
      username,
      password,
      setSpinner,
      dispatchUserLevelStore,
      navigate,
      enqueueSnackbar,
      requestController,
    });
  };

  const handleChange = (value, prop) => {
    setValues({ ...values, [prop]: value });
  };

  const handleResendCode = () => {
    singleRequestHandler({
      request: AuthStore.forgotPassword,
      requestParams: [stateUserLevel.username],
      dispatcher: enqueueSnackbar,
      callbackBeforeSend: () => setSpinner((repliesPending) => repliesPending + 1),
      messageSuccess: 'A new code was sent to your email address',
      callbackFinally: () => setSpinner((repliesPending) => repliesPending - 1),
    });
  };

  const handleSubmit = () => {
    setSpinner((repliesPending) => repliesPending + 1);
    if (values.password !== values.confirmPassword) {
      enqueueSnackbar('Passwords do not match', { variant: 'error' });
      setSpinner((repliesPending) => repliesPending - 1);
    } else if (requiresCode && values.code.length !== 6) {
      enqueueSnackbar('Code must have 6 digits', { variant: 'error' });
      setSpinner((repliesPending) => repliesPending - 1);
    } else if (requiresCode) {
      // ############################################
      // If the user is in process of "forgot password"
      // ############################################
      singleRequestHandler({
        request: AuthStore.confirmForgotPassword,
        requestParams: [stateUserLevel.username, values.password, values.code],
        dispatcher: enqueueSnackbar,
        callbackSuccess: () => setModal(true),
        callbackFinally: () => setSpinner((repliesPending) => repliesPending - 1),
      });
    } else {
      // ##############################################
      // If the user needs to change temporary password
      // provided by verity when new account is created
      // ##############################################
      singleRequestHandler({
        request: AuthStore.authChallenge,
        requestParams: [
          {
            challenge_name: stateUserLevel.details.challengeName,
            session,
            username: stateUserLevel.username,
            password: values.password,
          },
        ],
        dispatcher: enqueueSnackbar,
        callbackSuccess: ({ redirectRoute }) => {
          localStorage.removeItem('session');
          dispatchUserLevelStore({ type: UserLevelActionNames.CLEAR_AUTH_PROCESS_DETAILS });
          navigate(redirectRoute);
        },
        callbackError: () => {
          navigate(AUTH_PAGES_URLS.SIGNOUT);
        },
        callbackFinally: () => setSpinner((repliesPending) => repliesPending - 1),
      });
    }
  };

  useEffect(() => {
    if (!stateUserLevel.authProcess) {
      navigate(CLIENT_PAGES_URLS.SELECT_FACILITY);
    }
  }, [navigate, stateUserLevel.authProcess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className={clsx(classes.container, 'c-page-wrapper')}>
      <Paper elevation={3} className={clsx(classes.paper, 'c-page-content')}>
        <Typography className={classes.headerText} variant="h5" color="textPrimary">
          {requiresCode ? 'Enter code and new password' : 'Enter new password'}
        </Typography>
        {requiresCode && (
          <Box className={classes.actionButtons} display="flex" style={{ alignItems: 'baseline' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              style={{ flex: 1 }}
              className={classes.inputField}
              type="number"
              fullWidth
              id="code"
              value={setValues.code}
              minLength="6"
              maxLength="6"
              label="Code"
              autoFocus
              onChange={(e) => handleChange(e.target.value.toString(), 'code')}
            />
            <Button
              style={{ marginLeft: '8px' }}
              variant="outlined"
              color="primary"
              onClick={() => handleResendCode()}
            >
              Resend code
            </Button>
          </Box>
        )}
        <InputPassword
          id="password"
          variant="outlined"
          label="Password"
          required
          value={values.password}
          activeError={(bool) => setPasswordError(bool)}
          customClasses={classes.inputField}
          onChange={(e) => handleChange(e.target.value, 'password')}
        />
        <InputPassword
          id="confirm-password"
          variant="outlined"
          label="Confirm password"
          required
          value={values.confirmPassword}
          activeError={(bool) => setPasswordError(bool)}
          customClasses={classes.inputField}
          disabled={!values.password}
          confirmPassword={values.password}
          onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
        />

        <PasswordHelper />

        <Box className={classes.actionButtons} display="flex">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate(AUTH_PAGES_URLS.SIGNOUT);
            }}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              (requiresCode ? !values.code : false) ||
              !values.password ||
              !values.confirmPassword ||
              passwordError
            }
            onClick={() => handleSubmit()}
            className={classes.submit}
          >
            Submit
          </Button>
        </Box>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>

      {spinner ? <Spinner /> : null}

      <ModalBase
        opened={successModal}
        title={
          <Box>
            <Box display="flex" alignItems="center" textAlign="left" p={2} mb={1}>
              <Typography color="secondary" variant="h5">
                Success
              </Typography>
              <CheckCircleOutlineIcon className={classes.checkIconSuccess} />
            </Box>
          </Box>
        }
        handleClose={() => navigate(AUTH_PAGES_URLS.SIGNOUT)}
        actionButtons={
          <Button
            onClick={() => signinClicked(stateUserLevel.username, values.password)}
            variant="contained"
            fullWidth
            color="primary"
          >
            Sign In
          </Button>
        }
      >
        <Box p={3}>
          <Typography color="secondary" variant="subtitle1">
            Your password has been successfully changed.
          </Typography>
        </Box>
      </ModalBase>
    </Container>
  );
};

export default ForgotPassword;
