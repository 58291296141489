import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '1200px',
    padding: 1,
    maxWidth: '100%',
  },
  cardHeader: {
    padding: theme.spacing(2),
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(clamp(0px, 250px, 100%), 1fr))',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  cardFullWidth: {
    gridColumn: '1 / -1',
  },
  cardContent: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
  },
}));
