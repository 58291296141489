import { Button, Grid } from '@mui/material';
import { Box } from '../../../common/Box';
import { locationStyles } from '../styles';

export const ShareFeedbackButton = ({ isEnabled }: { isEnabled: boolean }) => {
  const { classes } = locationStyles();
  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.formWrapper}
        style={{ maxWidth: '100%', width: '100%' }}
      >
        <Button
          disabled={!isEnabled}
          className={classes.actionButton}
          variant="contained"
          color="primary"
          type="submit"
          data-testid="c-issue-feedback-submit-button"
        >
          Share Feedback
        </Button>
      </Box>
    </Grid>
  );
};
