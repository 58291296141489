import { WebSocketSubscription } from 'shared/web-socket/web-socket.model';

export type ImageSubscribersOptions = 'imageStatus';

export const imageSubscribers: Record<ImageSubscribersOptions, WebSocketSubscription> = {
  imageStatus: {
    channel: 'image/status',
    delegate: 'LocationModalImageStatus',
  },
};
