import { useReducer } from 'react';
import { reportContainerReducer } from './ReportContainerReducer';
import { reportContainerInitialState } from './ReportContainerState';

/**
 * This function is wrapper for report container useReducer, reason for this is to be easier tested
 * @returns [state, dispatch]
 */
export const useReportContainerReducer = () =>
  useReducer(reportContainerReducer, reportContainerInitialState);
