// CustomDropZone component
//
// Props:
//  - id [string]: id for element targeting
//  - onDrop [function]: On drop file function
//  - text [string]: Text inside drop area
//  - maxFiles [int]: Number of max files accepted by dropzone
//  - disabled [bool]: Disable dropzone
//  - showFiles [bool]: Show/hide added files
//  - acceptedFiles [array]: array of added files

import React, { ReactElement, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';

import Dropzone, { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({}));

/**
 * Interface for the Props of CustomDropZone
 */
interface ICustomDropZoneProp {
  id: any;
  text: string;
  maxFiles: number;
  testId?: string;
  disabled?: boolean;
  showFiles?: boolean;
  customUploader?: ReactElement;
  accept?: Accept;
  onDrop: <T extends File>(acceptedFiles: T[]) => void;
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
  onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void;
  rootClassName?: string;
  acceptedFiles?: {
    path: string;
    size: string;
  }[];
}

const CustomDropZone = ({
  id,
  text,
  maxFiles,
  disabled,
  testId,
  showFiles = true,
  accept,
  onDrop,
  onDropRejected,
  onDropAccepted,
  rootClassName,
  customUploader,
  acceptedFiles = [],
}: ICustomDropZoneProp) => {
  const sectionEle = useRef(id);

  const { cx } = useStyles();

  // NOTE: the id parameter is needed but apparently doesn't do anything
  const onDragEnter = (id: any) => {
    sectionEle.current.classList.add('drop-zone-file-hover');
  };

  // NOTE: the id parameter is needed but apparently doesn't do anything
  const onDragLeave = (id: any) => {
    sectionEle.current.classList.remove('drop-zone-file-hover');
  };

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      <strong>
        {file.path} - {file.size} bytes
      </strong>
    </li>
  ));

  const setFileNameStyle = () => {
    const dropZoneHolderEle = sectionEle.current.parentNode;
    const fileNameEle = dropZoneHolderEle.children[1] || null;
    if (fileNameEle) {
      fileNameEle.style.fontWeight = 700;
    }
  };

  const uploader = customUploader || <p className="fileDrop">{text}</p>;

  return (
    <Dropzone
      maxFiles={maxFiles}
      disabled={disabled}
      accept={accept}
      onDropRejected={onDropRejected}
      onDropAccepted={onDropAccepted}
      onDragEnter={() => onDragEnter(id)}
      onDragLeave={() => onDragLeave(id)}
      onDrop={(v) => {
        onDragLeave(id);
        if (!isEmpty(v)) {
          onDrop(v);
          setFileNameStyle();
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="drop-zone-container" id={id} ref={sectionEle}>
          <div {...getRootProps({ className: cx('dropzone', rootClassName) })}>
            <input {...getInputProps()} data-testid={testId} />
            {uploader}
          </div>
          {showFiles && (
            <aside>
              <ul>{files}</ul>
            </aside>
          )}
        </section>
      )}
    </Dropzone>
  );
};

export default CustomDropZone;
