import { useId, useState } from 'react';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useStyles } from './DroneZoneMapLegend.styles';
import { DroneZoneMapLegendItemMapped, DroneZoneMapLegendProps } from './DroneZoneMapLegend.model';
import { DroneZoneMapLegendEntries } from './features/drone-zone-map-legend-entries/DroneZoneMapLegendEntries';

export const DroneZoneMapLegend = ({ entries }: DroneZoneMapLegendProps) => {
  const { classes, cx } = useStyles();

  const menuId = useId();
  const [isOpen, setIsOpen] = useState(true);

  const entriesWithIdKey: DroneZoneMapLegendItemMapped[] = entries.flatMap((section, index) => {
    const updatedSection = section.map((entry) => ({
      id: crypto.randomUUID(),
      ...entry,
    }));

    if (index > 0) {
      return [{ id: crypto.randomUUID(), type: 'divider' }, ...updatedSection];
    }

    return updatedSection;
  });

  return (
    <div className={classes.legend}>
      <div
        id={menuId}
        className={cx(classes.legendBody, { [classes.legendBodyMinimised]: !isOpen })}
      >
        <ul
          aria-label="Map legend defining the different visual elements within the map area"
          className={classes.legendUnorderedList}
        >
          {entriesWithIdKey.map((entry) => (
            <DroneZoneMapLegendEntries key={entry.id} entry={entry} />
          ))}
        </ul>
      </div>

      <button
        aria-expanded={isOpen}
        aria-controls={menuId}
        className={classes.legendToggleButton}
        aria-label="Button to toggle visiblity of the legend"
        type="button"
        onClick={() => setIsOpen((currentIsOpen) => !currentIsOpen)}
      >
        {!isOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        {!isOpen && (
          <span aria-hidden="true" className={classes.legendToggleButtonLabel}>
            Legend
          </span>
        )}
      </button>
    </div>
  );
};
