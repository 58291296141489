import { AxiosResponse } from 'axios';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { requestQueueHandler } from 'common/requestHelpers';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { zeroTo } from 'shared/map-container/utils/3DmapFunctions';
import { ILocationsMetaDataGetResponseST } from 'codegen/warehouse_status';
import { ILocationDataST } from 'codegen/report';
import { IRequestController, ReservedSlot } from '../../hooks';
import WarehouseServices from '../../services/WarehouseServices';

const logPrefix = getLogPrefixForType('STORE', 'WHS Store');

export const WHSStore = (
  systemId: string,
  gridApi: GridApiPremium,
  requestController: IRequestController,
  slots: string[],
) => {
  const requestPageSize = NETWORK_REQUEST_SETTINGS.WAREHOUSE_PAGE_SIZE_FOR_FULL_REPORT_TAB;
  const pageCount = Math.ceil(slots && slots.length / requestPageSize);
  let loadedPages = zeroTo(pageCount).map((_) => false);

  const isEverythingLoaded = () => {
    const areLoaded = loadedPages.every((p) => p);
    console.debug(logPrefix, `are loaded: ${areLoaded}`);
    return areLoaded;
  };

  const isLoading = () => !isEverythingLoaded();

  const loadInBatches = (makeRequestObject: (fromSlot: string, reqNr: [number, number]) => any) => {
    const allRequests = [];

    for (let i = 0; i < pageCount; i += 1) {
      console.debug(
        logPrefix,
        `loadFullReportAndAmendedLocations for systemId: ${systemId}, page: ${i}, slot: ${
          i * requestPageSize
        }`,
      );
      const fromSlot = slots[i * requestPageSize];
      const networkRequest = makeRequestObject(fromSlot, [i, pageCount]);

      allRequests.push(networkRequest);
    }

    requestQueueHandler(
      allRequests,
      NETWORK_REQUEST_SETTINGS.MAX_CONCURRENT_REQUESTS,
      requestController,
    );
  };

  const loadLocationMetadata = () => {
    const lp = getLogPrefixForType('FUNCTION', 'loadLocationMetadata', logPrefix);

    const reservation: ReservedSlot = requestController.reserveSlotForRequest();
    loadedPages = loadedPages.map((_) => false);

    const networkRequest = (fromSlot: string, reqNr: [number, number]) => ({
      requestId: reservation.requestId,
      request: () =>
        requestController.doRequest({
          request: WarehouseServices.getLocationsMetadata,
          requestParams: [systemId, fromSlot, requestPageSize, reservation.signal],
          callbackSuccess: (res: AxiosResponse<ILocationsMetaDataGetResponseST>) => {
            const newBatch = Object.values(res.data.locations || {});
            const [batchNr, numBatches] = reqNr;

            console.debug(lp, `received batch ${batchNr}/${numBatches} of location metadata`);

            gridApi.updateRows(newBatch);
          },
          callbackFinally: () => {
            const [batchNr] = reqNr;
            loadedPages[batchNr] = true;
          },
          messageErrorFallback: 'Location metadata could not be fetched.',
        }),
    });

    loadInBatches(networkRequest);
  };

  const loadLocationData = () => {
    const lp = getLogPrefixForType('FUNCTION', 'loadLocationData', logPrefix);

    const reservation: ReservedSlot = requestController.reserveSlotForRequest();
    loadedPages = loadedPages.map((_) => false);

    const networkRequest = (fromSlot: string, reqNr: [number, number]) => ({
      requestId: reservation.requestId,
      request: () =>
        requestController.doRequest({
          request: WarehouseServices.getLocationsData,
          requestParams: [systemId, fromSlot, requestPageSize, reservation.signal],
          callbackSuccess: (res: AxiosResponse<{ [key: string]: ILocationDataST }>) => {
            const newBatch = Object.values(res.data || {});
            const [batchNr, numBatches] = reqNr;

            console.debug(lp, `received batch ${batchNr}/${numBatches} of location data`, newBatch);

            gridApi.updateRows(newBatch);
          },
          callbackFinally: () => {
            const [batchNr] = reqNr;
            loadedPages[batchNr] = true;
          },
          messageErrorFallback: 'Location data could not be fetched.',
        }),
    });

    loadInBatches(networkRequest);
  };

  const loadLocationsWithIssues = () => {
    const lp = getLogPrefixForType('FUNCTION', 'loadLocationsWithIssues', logPrefix);
    const reservation: ReservedSlot = requestController.reserveSlotForRequest();
    loadedPages = loadedPages.map((_) => false);

    const networkRequest = (fromSlot: string, reqNr: [number, number]) => ({
      requestId: reservation.requestId,
      request: () =>
        requestController.doRequest({
          request: WarehouseServices.getLocationsWithIssues,
          requestParams: [systemId, fromSlot, requestPageSize, true, reservation.signal],
          callbackFinally: () => {
            const [batchNr] = reqNr;
            loadedPages[batchNr] = true;
          },
          callbackSuccess: (res: AxiosResponse<{ [key: string]: ILocationDataST }>) => {
            const newBatch = Object.values(res.data || {});
            const [batchNr, numBatches] = reqNr;

            console.debug(lp, `received batch ${batchNr}/${numBatches} of location data`, newBatch);
            gridApi.updateRows(newBatch);
          },
          messageErrorFallback: 'Location data could not be fetched.',
        }),
    });

    loadInBatches(networkRequest);
  };

  const store = {
    isEverythingLoaded,
    loadLocationMetadata,
    loadLocationData,
    loadLocationsWithIssues,
    isLoading,
  };

  console.debug(logPrefix, 'created!', store);

  return store;
};
