import * as THREE from 'three';

export const generateMaterial = ({ opacity = 0.8, ...props }: THREE.MeshBasicMaterialParameters) =>
  new THREE.MeshBasicMaterial({
    depthTest: false,
    transparent: true,
    opacity,
    side: THREE.FrontSide,
    ...props,
  });
