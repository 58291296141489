import { OpenFlyZonesDTO } from 'shared/map/model/open-fly-zone.model';
import { WebSocketClient } from 'shared/web-socket/web-socket.model';
import { IGetOpenFlyZonesResponseST } from 'codegen/open_fly_zone';
import { subscribers } from './droneZonesSubscribers';

export const subscribeToOpenFlyZoneSocket = (
  flightDomainId: string,
  addOpenFlyZones: (openFlyZonesDTO: OpenFlyZonesDTO) => void,
  socket: WebSocketClient,
) => {
  socket.subscribe({
    onMessage(data: IGetOpenFlyZonesResponseST): void {
      if (flightDomainId === data.flight_domain_id) {
        addOpenFlyZones(data.open_fly_zones);
      }
    },
    channel: subscribers.openFlyZone.channel,
    delegate: subscribers.openFlyZone.delegate,
  });
};
