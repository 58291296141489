import { createSvgIcon } from '@mui/material/utils';

interface IIconProps {
  theme: any;
  active: any;
}

const FacilityDetailsIcon = ({ theme, active }: IIconProps) => {
  const Icon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0869 8.60867H11.5217C10.4599 8.60867 9.60864 9.46954 9.60864 10.5217V20.0869C9.60864 21.1391 10.4599 22 11.5217 22H21.0869C22.1391 22 22.9999 21.1391 22.9999 20.0869V10.5217C22.9999 9.46954 22.1486 8.60867 21.0869 8.60867ZM21.0869 20.0869H11.5217V10.5217H21.0869V20.0869ZM19.1739 14.3478H13.4347V12.4348H19.1739V14.3478ZM16.3043 18.1739H13.4347V16.2608H16.3043V18.1739Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5.57817L9.5841 1.91302L18.2174 5.57817V6.69562H11.5217C9.57061 6.69562 7.9606 8.15609 7.72525 10.0435H5.23056V18.1739H1V5.57817Z"
        fill="currentColor"
      />
    </svg>,
    'DetailsIcon',
  );

  return (
    <div>
      <Icon />
    </div>
  );
};

export default FacilityDetailsIcon;
