import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',

    '&::before': {
      content: '""',
      display: 'block',
      flex: '1 1 0',
      borderBlockStart: `1px solid ${theme.palette.divider}`,
    },
  },
}));
