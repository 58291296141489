import { IChangeAllControlledZonePermissionRequestST } from 'codegen/controlled_zone';
import { initControlledZoneService } from './service';

export const toggleControlledZoneActiveState = ({
  systemId,
  flightDomainId,
  payload,
  signal,
}: {
  systemId: string;
  flightDomainId: string;
  payload: IChangeAllControlledZonePermissionRequestST;
  signal?: AbortSignal;
}) =>
  initControlledZoneService().changeAllControlledZonePermission(systemId, flightDomainId, payload, {
    signal,
  });
