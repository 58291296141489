import { MutableRefObject, useRef } from 'react';
import { Vector2 } from 'three';
import { useFrame } from '@react-three/fiber';

/**
 * Calculates and keeps track of the mouse movement.
 * @returns [deltaX/frame, deltaY/frame]
 */
export const useMouseDiff = (): MutableRefObject<Vector2> => {
  const diff = useRef(new Vector2());
  const oldMousePos = useRef(new Vector2());
  useFrame(({ mouse }) => {
    const [mdx, mdy] = [mouse.x - oldMousePos.current.x, mouse.y - oldMousePos.current.y];

    oldMousePos.current = new Vector2(mouse.x, mouse.y);
    diff.current.set(mdx, mdy);
  });

  return diff;
};
