import moment from 'moment';
import { convertOperatingHoursToTimeRanges } from '../../../utils/convertOperatingHoursToTimeRanges';
import { isHourAndMinutesWithinTimeRanges } from './isHourAndMinutesWithinTimeRanges';
import { OperatingHoursRange } from '../model/operatingHours.model';
import { transformOperatingHoursRangeToDTO } from '../model/transformOperatingHours';

export const areFlightHoursAffectedByScheduleLock = ({
  flightHours,
  scheduleLock,
}: {
  flightHours: OperatingHoursRange;
  scheduleLock?: string | null;
}) => {
  if (!scheduleLock) return false;

  const scheduleLockMoment = moment(scheduleLock);

  const transformedFlightHours = [transformOperatingHoursRangeToDTO(flightHours)];
  const timeRanges = convertOperatingHoursToTimeRanges(
    transformedFlightHours,
    scheduleLockMoment.day(),
  );

  return isHourAndMinutesWithinTimeRanges(
    scheduleLockMoment.hour(),
    scheduleLockMoment.minutes(),
    timeRanges,
  );
};
