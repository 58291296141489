import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  formInput: {
    width: '100%',
    margin: '12px 0',
    '&:first-of-type': {
      marginRight: theme.spacing(1),
    },
  },
  recurrenceMessage: {
    marginTop: theme.spacing(1),
    '& p': {
      margin: theme.spacing(0),
    },
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));
