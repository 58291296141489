import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  toggleButton: {
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    transition: `background-color ${theme.transitions.duration.short}ms, color ${theme.transitions.duration.short}ms`,

    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },

    '&.Mui-selected:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.85),
    },
  },
}));
