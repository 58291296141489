import { zoneValueToLocaleFixedDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { useStyles } from './ZoneSideViewBaseZone.styles';
import { DroneZones } from '../../../../../../model/drone-zones.model';

export const ZoneSideViewBaseZone = ({
  classNames,
  zone,
  areaHeight,
  isSelected,
  isDisabled,
  onClick,
}: {
  classNames: string;
  zone: DroneZones;
  areaHeight: number;
  isSelected: boolean;
  isDisabled: boolean;
  onClick: (zone: DroneZones) => void;
}) => {
  const { classes, cx } = useStyles();

  const bottomPosition = `${(zone.sizeAndPosition.minZ / areaHeight) * 100}%`;
  const topPosition = `${100 - (zone.sizeAndPosition.maxZ / areaHeight) * 100}%`;

  const handleOnClick = () => {
    onClick(zone);
  };

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={cx(classNames, classes.zone)}
      style={{
        insetBlockStart: topPosition,
        insetBlockEnd: bottomPosition,
      }}
      onClick={handleOnClick}
    >
      <span className={classes.zoneDetailsName}>
        {`${zone.name} (${zoneValueToLocaleFixedDecimalPlaces(
          zone.sizeAndPosition.minZ,
        )} to ${zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.maxZ)})`}
      </span>

      <span className={classes.zoneDetailsHeight}>
        {`H: ${zoneValueToLocaleFixedDecimalPlaces(zone.sizeAndPosition.h)}m`}
      </span>
    </button>
  );
};
