export const generateSolidTexture = ({
  primaryColor,
  proportion = 1,
  lineWidth = 1,
  includeBorder,
  borderColor,
}: {
  primaryColor: string;
  proportion?: number;
  lineWidth?: number;
  includeBorder?: boolean;
  borderColor?: string;
}) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as unknown as CanvasRenderingContext2D;
  const baseSize = 256;
  canvas.width = proportion < 1 ? baseSize * proportion : baseSize;
  canvas.height = proportion < 1 ? baseSize : baseSize / proportion;
  context.fillStyle = primaryColor;
  context.fillRect(0, 0, canvas.width, canvas.height);

  if (includeBorder && borderColor) {
    context.lineWidth = lineWidth;
    context.strokeStyle = borderColor;
    context.strokeRect(0, 0, canvas.width, canvas.height);
  }

  return canvas;
};
