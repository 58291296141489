import { IControlledZonePostRequestST } from 'codegen/controlled_zone';
import { initControlledZoneAPI } from './api';

export const createControlledZone = ({
  systemId,
  flightDomainId,
  payload,
}: {
  systemId: string;
  flightDomainId: string;
  payload: IControlledZonePostRequestST;
}) => initControlledZoneAPI().createControlledZone(systemId, flightDomainId, payload);
