import { getLogPrefixForType } from 'common/functions/logFunctions';
import { createSuperSetGuestToken } from '../../../services/Superset/CreateSuperSetGuestToken';

export const logPrefix = getLogPrefixForType('FUNCTION', 'fetchGuestToken');

/**
 * Fetch guest token
 * @returns superset guest token
 */
export const fetchGuestToken = async () => {
  try {
    const res = await createSuperSetGuestToken();

    return res.data.guest_token;
  } catch (error) {
    console.debug(logPrefix, `Fetching guest token errored with: ${error}`);
    return '';
  }
};
