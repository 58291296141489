import { OpenFlyZone, OpenFlyZones } from 'shared/map/model/open-fly-zone.model';
import { MapOptions } from 'shared/map-container/reducer/MapOptionsState';
import { OpenFlyZoneMesh } from 'shared/map/features/open-fly-zone-mesh/open-fly-zone-mesh';

export const meshesFromOpenFlyZones: (
  openFlyZones: OpenFlyZones,
  mapOptions: MapOptions,
  onDroneZoneClick: (zone: OpenFlyZone) => void,
) => JSX.Element[] = (openFlyZones, mapOptions, onDroneZoneClick) =>
  Object.values(openFlyZones).map((openFlyZone: OpenFlyZone) => (
    <OpenFlyZoneMesh
      key={openFlyZone.id}
      droneZone={openFlyZone}
      options={mapOptions}
      onClick={() => onDroneZoneClick(openFlyZone)}
    />
  ));
