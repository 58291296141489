/* tslint:disable */
/* eslint-disable */
/**
 * Review
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
}
/**
 * @type IReviewApproveSlotStatusPutRequestInnerInnerST
 * @export
 */
export type IReviewApproveSlotStatusPutRequestInnerInnerST = number | string;

/**
 * ReviewApi - axios parameter creator
 * @export
 */
export const ReviewApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Approve slot status.
     * @summary Approve slot status.
     * @param {string} systemIdStr
     * @param {Array<Array<IReviewApproveSlotStatusPutRequestInnerInnerST>>} iReviewApproveSlotStatusPutRequestInnerInnerST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSlotStatus: async (
      systemIdStr: string,
      iReviewApproveSlotStatusPutRequestInnerInnerST: Array<
        Array<IReviewApproveSlotStatusPutRequestInnerInnerST>
      >,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('approveSlotStatus', 'systemIdStr', systemIdStr);
      // verify required parameter 'iReviewApproveSlotStatusPutRequestInnerInnerST' is not null or undefined
      assertParamExists(
        'approveSlotStatus',
        'iReviewApproveSlotStatusPutRequestInnerInnerST',
        iReviewApproveSlotStatusPutRequestInnerInnerST,
      );
      const localVarPath = `/{system_id_str}/approve-slot-status`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iReviewApproveSlotStatusPutRequestInnerInnerST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReviewApi - functional programming interface
 * @export
 */
export const ReviewApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReviewApiAxiosParamCreator(configuration);
  return {
    /**
     * Approve slot status.
     * @summary Approve slot status.
     * @param {string} systemIdStr
     * @param {Array<Array<IReviewApproveSlotStatusPutRequestInnerInnerST>>} iReviewApproveSlotStatusPutRequestInnerInnerST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveSlotStatus(
      systemIdStr: string,
      iReviewApproveSlotStatusPutRequestInnerInnerST: Array<
        Array<IReviewApproveSlotStatusPutRequestInnerInnerST>
      >,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveSlotStatus(
        systemIdStr,
        iReviewApproveSlotStatusPutRequestInnerInnerST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReviewApi - factory interface
 * @export
 */
export const ReviewApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReviewApiFp(configuration);
  return {
    /**
     * Approve slot status.
     * @summary Approve slot status.
     * @param {string} systemIdStr
     * @param {Array<Array<IReviewApproveSlotStatusPutRequestInnerInnerST>>} iReviewApproveSlotStatusPutRequestInnerInnerST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveSlotStatus(
      systemIdStr: string,
      iReviewApproveSlotStatusPutRequestInnerInnerST: Array<
        Array<IReviewApproveSlotStatusPutRequestInnerInnerST>
      >,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .approveSlotStatus(systemIdStr, iReviewApproveSlotStatusPutRequestInnerInnerST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReviewApi - object-oriented interface
 * @export
 * @class ReviewApi
 * @extends {BaseAPI}
 */
export class ReviewApi extends BaseAPI {
  /**
   * Approve slot status.
   * @summary Approve slot status.
   * @param {string} systemIdStr
   * @param {Array<Array<IReviewApproveSlotStatusPutRequestInnerInnerST>>} iReviewApproveSlotStatusPutRequestInnerInnerST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewApi
   */
  public approveSlotStatus(
    systemIdStr: string,
    iReviewApproveSlotStatusPutRequestInnerInnerST: Array<
      Array<IReviewApproveSlotStatusPutRequestInnerInnerST>
    >,
    options?: AxiosRequestConfig,
  ) {
    return ReviewApiFp(this.configuration)
      .approveSlotStatus(systemIdStr, iReviewApproveSlotStatusPutRequestInnerInnerST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
