import { Box, Checkbox } from '@mui/material';

type ExcludeLocationsModalCheckboxProps = {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  message: string | JSX.Element;
  testId?: string;
};

export const ExcludeLocationsModalCheckbox = ({
  value,
  setValue,
  message,
  testId,
}: ExcludeLocationsModalCheckboxProps) => (
  <Box
    data-testid={testId}
    component="div"
    marginTop={4}
    marginBottom={4}
    lineHeight="1.7em"
    paddingTop="1em"
    paddingBottom="1em"
    paddingLeft="1.5em"
    paddingRight="1em"
    color="rgb(102, 60, 0)"
    sx={{
      backgroundColor: 'rgb(255, 244, 229)',
    }}
    display="flex"
    flexDirection="row"
    alignItems="center"
    gap={2}
  >
    <Checkbox
      checked={value}
      onChange={() => setValue(!value)}
      color="warning"
      id="c-confirm-excluded-location-data-deletion"
    />
    <Box component="div">{message}</Box>
  </Box>
);
