/* tslint:disable */
/* eslint-disable */
/**
 * no-fly zones
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IBox3DST
 */
export interface IBox3DST {
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  min: IMapPointST;
  /**
   *
   * @type {IMapPointST}
   * @memberof IBox3DST
   */
  max: IMapPointST;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IMapPointST
 */
export interface IMapPointST {
  /**
   * The value of the X coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  x: number;
  /**
   * The value of the Y coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  y: number;
  /**
   * The value of the Z coordinate of the map point
   * @type {number}
   * @memberof IMapPointST
   */
  z: number;
}
/**
 *
 * @export
 * @interface INoFlyZoneActivateRequestST
 */
export interface INoFlyZoneActivateRequestST {
  /**
   * Whether the no-fly zone should be activated
   * @type {boolean}
   * @memberof INoFlyZoneActivateRequestST
   */
  drone_allowed_in_zone: boolean;
}
/**
 *
 * @export
 * @interface INoFlyZonePostRequestST
 */
export interface INoFlyZonePostRequestST {
  /**
   * Name of the no-fly zone
   * @type {string}
   * @memberof INoFlyZonePostRequestST
   */
  name?: string;
  /**
   * no-fly zone description
   * @type {string}
   * @memberof INoFlyZonePostRequestST
   */
  description?: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof INoFlyZonePostRequestST
   */
  geometry?: IBox3DST;
}
/**
 *
 * @export
 * @interface INoFlyZoneStatus1ST
 */
export interface INoFlyZoneStatus1ST {
  /**
   * The no-fly zone name
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  name: string;
  /**
   * The no-fly zone description
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof INoFlyZoneStatus1ST
   */
  geometry: IBox3DST;
  /**
   * Whether the no-fly zone is drone_allowed_in_zone
   * @type {boolean}
   * @memberof INoFlyZoneStatus1ST
   */
  drone_allowed_in_zone: boolean;
  /**
   * The the no-fly zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  last_zone_permission_change_time: string;
  /**
   * The user that activated or deactivated the no-fly zone the last time
   * @type {string}
   * @memberof INoFlyZoneStatus1ST
   */
  last_zone_permission_change_by: string;
}
/**
 *
 * @export
 * @interface INoFlyZoneStatusST
 */
export interface INoFlyZoneStatusST {
  /**
   * The no-fly zone name
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  name: string;
  /**
   * The no-fly zone description
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof INoFlyZoneStatusST
   */
  geometry: IBox3DST;
  /**
   * Whether the no-fly zone is drone_allowed_in_zone
   * @type {boolean}
   * @memberof INoFlyZoneStatusST
   */
  drone_allowed_in_zone: boolean;
  /**
   * The the no-fly zone was activated or deactivated the last time. Expressed in UTC.
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  last_zone_permission_change_time: string;
  /**
   * The user that activated or deactivated the no-fly zone the last time
   * @type {string}
   * @memberof INoFlyZoneStatusST
   */
  last_zone_permission_change_by: string;
}
/**
 *
 * @export
 * @interface INoFlyZoneUpdatePropertiesRequestST
 */
export interface INoFlyZoneUpdatePropertiesRequestST {
  /**
   *
   * @type {string}
   * @memberof INoFlyZoneUpdatePropertiesRequestST
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof INoFlyZoneUpdatePropertiesRequestST
   */
  description: string;
  /**
   *
   * @type {IBox3DST}
   * @memberof INoFlyZoneUpdatePropertiesRequestST
   */
  geometry: IBox3DST;
}
/**
 *
 * @export
 * @interface INoFlyZonesResponseST
 */
export interface INoFlyZonesResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof INoFlyZonesResponseST
   */
  flight_domain_id: string;
  /**
   * The status of the no-fly zones
   * @type {{ [key: string]: INoFlyZoneStatus1ST; }}
   * @memberof INoFlyZonesResponseST
   */
  no_fly_zones: { [key: string]: INoFlyZoneStatus1ST };
}

/**
 * NoFlyZonesApi - axios parameter creator
 * @export
 */
export const NoFlyZonesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a new no-fly zone.
     * @summary Create a new no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('createNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('createNoFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'iNoFlyZonePostRequestST' is not null or undefined
      assertParamExists('createNoFlyZone', 'iNoFlyZonePostRequestST', iNoFlyZonePostRequestST);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/no-fly-zone`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZonePostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete no-fly zone by id
     * @summary Delete no-fly zone by id
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('deleteNoFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteNoFlyZone', 'id', id);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/no-fly-zone/{id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the status of the flight domain no-fly zones. The query parameter fnz_ids can be used to filter the returned no-fly zones
     * @summary Returns the status of the flight domain no-fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {Array<string>} [ids] List of required no-fly zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoFlyZones: async (
      systemIdStr: string,
      flightDomainId: string,
      ids?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getNoFlyZones', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getNoFlyZones', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/no-fly-zone`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (ids) {
        localVarQueryParameter['ids'] = ids;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific no-fly zone.
     * @summary Activate/Deactivate a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setNoFlyZoneActive: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('setNoFlyZoneActive', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('setNoFlyZoneActive', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('setNoFlyZoneActive', 'id', id);
      // verify required parameter 'iNoFlyZoneActivateRequestST' is not null or undefined
      assertParamExists(
        'setNoFlyZoneActive',
        'iNoFlyZoneActivateRequestST',
        iNoFlyZoneActivateRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/no-fly-zone/{id}/activate`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
          .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZoneActivateRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific no-fly zone. This is a deprecated operation and will be removed in the future
     * @summary Legacy  activate/deactivate a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoFlyZone: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateNoFlyZone', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateNoFlyZone', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateNoFlyZone', 'id', id);
      // verify required parameter 'iNoFlyZoneActivateRequestST' is not null or undefined
      assertParamExists(
        'updateNoFlyZone',
        'iNoFlyZoneActivateRequestST',
        iNoFlyZoneActivateRequestST,
      );
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/no-fly-zone/{id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZoneActivateRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The endpoint allows the caller to update properties of a specific no-fly zone.
     * @summary Update properties of a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneUpdatePropertiesRequestST} iNoFlyZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoFlyZoneProperties: async (
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneUpdatePropertiesRequestST: INoFlyZoneUpdatePropertiesRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateNoFlyZoneProperties', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('updateNoFlyZoneProperties', 'flightDomainId', flightDomainId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateNoFlyZoneProperties', 'id', id);
      // verify required parameter 'iNoFlyZoneUpdatePropertiesRequestST' is not null or undefined
      assertParamExists(
        'updateNoFlyZoneProperties',
        'iNoFlyZoneUpdatePropertiesRequestST',
        iNoFlyZoneUpdatePropertiesRequestST,
      );
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/no-fly-zone/{id}/properties`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)))
          .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iNoFlyZoneUpdatePropertiesRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NoFlyZonesApi - functional programming interface
 * @export
 */
export const NoFlyZonesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NoFlyZonesApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a new no-fly zone.
     * @summary Create a new no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createNoFlyZone(
        systemIdStr,
        flightDomainId,
        iNoFlyZonePostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete no-fly zone by id
     * @summary Delete no-fly zone by id
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNoFlyZone(
        systemIdStr,
        flightDomainId,
        id,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the status of the flight domain no-fly zones. The query parameter fnz_ids can be used to filter the returned no-fly zones
     * @summary Returns the status of the flight domain no-fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {Array<string>} [ids] List of required no-fly zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNoFlyZones(
      systemIdStr: string,
      flightDomainId: string,
      ids?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<INoFlyZonesResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNoFlyZones(
        systemIdStr,
        flightDomainId,
        ids,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific no-fly zone.
     * @summary Activate/Deactivate a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setNoFlyZoneActive(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setNoFlyZoneActive(
        systemIdStr,
        flightDomainId,
        id,
        iNoFlyZoneActivateRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific no-fly zone. This is a deprecated operation and will be removed in the future
     * @summary Legacy  activate/deactivate a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoFlyZone(
        systemIdStr,
        flightDomainId,
        id,
        iNoFlyZoneActivateRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The endpoint allows the caller to update properties of a specific no-fly zone.
     * @summary Update properties of a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneUpdatePropertiesRequestST} iNoFlyZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNoFlyZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneUpdatePropertiesRequestST: INoFlyZoneUpdatePropertiesRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoFlyZoneProperties(
        systemIdStr,
        flightDomainId,
        id,
        iNoFlyZoneUpdatePropertiesRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NoFlyZonesApi - factory interface
 * @export
 */
export const NoFlyZonesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NoFlyZonesApiFp(configuration);
  return {
    /**
     * Create a new no-fly zone.
     * @summary Create a new no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createNoFlyZone(systemIdStr, flightDomainId, iNoFlyZonePostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete no-fly zone by id
     * @summary Delete no-fly zone by id
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteNoFlyZone(systemIdStr, flightDomainId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the status of the flight domain no-fly zones. The query parameter fnz_ids can be used to filter the returned no-fly zones
     * @summary Returns the status of the flight domain no-fly zones.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {Array<string>} [ids] List of required no-fly zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoFlyZones(
      systemIdStr: string,
      flightDomainId: string,
      ids?: Array<string>,
      options?: any,
    ): AxiosPromise<INoFlyZonesResponseST> {
      return localVarFp
        .getNoFlyZones(systemIdStr, flightDomainId, ids, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific no-fly zone.
     * @summary Activate/Deactivate a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setNoFlyZoneActive(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .setNoFlyZoneActive(systemIdStr, flightDomainId, id, iNoFlyZoneActivateRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to activate or deactivate a specific no-fly zone. This is a deprecated operation and will be removed in the future
     * @summary Legacy  activate/deactivate a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoFlyZone(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateNoFlyZone(systemIdStr, flightDomainId, id, iNoFlyZoneActivateRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The endpoint allows the caller to update properties of a specific no-fly zone.
     * @summary Update properties of a specific no-fly zone.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} id
     * @param {INoFlyZoneUpdatePropertiesRequestST} iNoFlyZoneUpdatePropertiesRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoFlyZoneProperties(
      systemIdStr: string,
      flightDomainId: string,
      id: string,
      iNoFlyZoneUpdatePropertiesRequestST: INoFlyZoneUpdatePropertiesRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateNoFlyZoneProperties(
          systemIdStr,
          flightDomainId,
          id,
          iNoFlyZoneUpdatePropertiesRequestST,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NoFlyZonesApi - object-oriented interface
 * @export
 * @class NoFlyZonesApi
 * @extends {BaseAPI}
 */
export class NoFlyZonesApi extends BaseAPI {
  /**
   * Create a new no-fly zone.
   * @summary Create a new no-fly zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {INoFlyZonePostRequestST} iNoFlyZonePostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZonesApi
   */
  public createNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    iNoFlyZonePostRequestST: INoFlyZonePostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZonesApiFp(this.configuration)
      .createNoFlyZone(systemIdStr, flightDomainId, iNoFlyZonePostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete no-fly zone by id
   * @summary Delete no-fly zone by id
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZonesApi
   */
  public deleteNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZonesApiFp(this.configuration)
      .deleteNoFlyZone(systemIdStr, flightDomainId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the status of the flight domain no-fly zones. The query parameter fnz_ids can be used to filter the returned no-fly zones
   * @summary Returns the status of the flight domain no-fly zones.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {Array<string>} [ids] List of required no-fly zones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZonesApi
   */
  public getNoFlyZones(
    systemIdStr: string,
    flightDomainId: string,
    ids?: Array<string>,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZonesApiFp(this.configuration)
      .getNoFlyZones(systemIdStr, flightDomainId, ids, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to activate or deactivate a specific no-fly zone.
   * @summary Activate/Deactivate a specific no-fly zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZonesApi
   */
  public setNoFlyZoneActive(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZonesApiFp(this.configuration)
      .setNoFlyZoneActive(systemIdStr, flightDomainId, id, iNoFlyZoneActivateRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to activate or deactivate a specific no-fly zone. This is a deprecated operation and will be removed in the future
   * @summary Legacy  activate/deactivate a specific no-fly zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {INoFlyZoneActivateRequestST} iNoFlyZoneActivateRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZonesApi
   */
  public updateNoFlyZone(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    iNoFlyZoneActivateRequestST: INoFlyZoneActivateRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZonesApiFp(this.configuration)
      .updateNoFlyZone(systemIdStr, flightDomainId, id, iNoFlyZoneActivateRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The endpoint allows the caller to update properties of a specific no-fly zone.
   * @summary Update properties of a specific no-fly zone.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} id
   * @param {INoFlyZoneUpdatePropertiesRequestST} iNoFlyZoneUpdatePropertiesRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoFlyZonesApi
   */
  public updateNoFlyZoneProperties(
    systemIdStr: string,
    flightDomainId: string,
    id: string,
    iNoFlyZoneUpdatePropertiesRequestST: INoFlyZoneUpdatePropertiesRequestST,
    options?: AxiosRequestConfig,
  ) {
    return NoFlyZonesApiFp(this.configuration)
      .updateNoFlyZoneProperties(
        systemIdStr,
        flightDomainId,
        id,
        iNoFlyZoneUpdatePropertiesRequestST,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
