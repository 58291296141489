import { OverlappingZone } from './overlapping-zone.model';
import { calculateOverlapPercentage } from './calculate-overlap-percentage';
import { findCenterPositionOfOverlappingZones } from './find-center-position-of-overlapping-zones';
import { DroneZones } from '../../model/drone-zones.model';

export const findOverlapForZones = (
  droneZones: Record<string, DroneZones>,
): Record<string, OverlappingZone> => {
  const zones = Object.values(droneZones);
  const overlappingZones: Record<string, OverlappingZone> = {};

  for (let i = 0; i < zones.length; i += 1) {
    const isDuplicate = Object.values(overlappingZones).some((zone) =>
      zone.zones.some((z) => z.id === zones[i].id),
    );

    if (!isDuplicate) {
      overlappingZones[zones[i].id] = {
        zones: [zones[i]],
        centerPosition: {
          x: 0,
          y: 0,
          z: 0,
        },
      };

      const overlappingZone = overlappingZones[zones[i].id];

      for (let j = i + 1; j < zones.length; j += 1) {
        const overlapPercentage = calculateOverlapPercentage(zones[i], zones[j]);

        if (overlapPercentage >= 50) {
          overlappingZone.zones.push(zones[j]);
        }
      }
    }
  }

  Object.keys(overlappingZones).forEach((key) => {
    if (overlappingZones[key].zones.length > 1) {
      overlappingZones[key].centerPosition = findCenterPositionOfOverlappingZones(
        overlappingZones[key].zones,
      );
    }
  });

  return overlappingZones;
};
