import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { IReportST } from 'codegen/report';

export const getIssuesSummary = (report: IReportST) => {
  const emptyInsteadOfBarcode = report.issue_count.totals[ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY];
  const foundDifferentBarcode =
    report.issue_count.totals[ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE];
  const barcodeInsteadOfEmpty = report.issue_count.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE];
  const nonEmptyNoBarcodeInsteadOfEmpty =
    report.issue_count.totals[ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY];

  const totalIssues =
    emptyInsteadOfBarcode +
    foundDifferentBarcode +
    barcodeInsteadOfEmpty +
    nonEmptyNoBarcodeInsteadOfEmpty;

  let issuesSections: any[] = [];

  if (report.request.report_spec.report_spec_name !== 'Empty location report, per area') {
    issuesSections = [
      ...issuesSections,
      {
        id: 'expected-barcode',
        title: 'Expected to find barcode and:',
        sectionItems: [
          {
            id: 'expected-barcode-found-different',
            label: 'found a different barcode',
            value: foundDifferentBarcode.toString(),
          },
          {
            id: 'expected-barcode-found-empty',
            label: 'found an empty location',
            value: emptyInsteadOfBarcode.toString(),
          },
        ],
      },
    ];
  }

  issuesSections = [
    ...issuesSections,
    {
      id: 'expected-empty',
      title: 'Expected to find empty location and:',
      sectionItems: [
        {
          id: 'expected-empty-found-barcode',
          label: 'found a barcode',
          value: barcodeInsteadOfEmpty.toString(),
        },
        {
          id: 'expected-empty-found-no-barcode',
          label: 'found non-empty location, with no barcode',
          value: nonEmptyNoBarcodeInsteadOfEmpty.toString(),
        },
      ],
    },
  ];

  let subtitle = '';
  subtitle = 'Number of WMS mismatches';

  return {
    title: 'Issues',
    counter: totalIssues.toString(),
    counterSubject: 'issues',
    subtitle,
    sections: issuesSections,
  };
};
