import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  drawer: {
    zIndex: 1199,
    position: 'unset',
    '& .MuiModal-backdrop': {
      backgroundColor: 'transparent',
    },
    '& .MuiDrawer-paper': {
      zIndex: 1199,
    },
  },
}));
