import { getLogPrefixForType } from 'common/functions/logFunctions';
import { IFilterTasksResponseST } from 'codegen/tasks';
import { getRawCommissioningTasks } from '../../../../services/TaskApiService';
import { CommissioningTask } from '../model/commissioningTask.model';
import { IRequestController, useRequestController } from '../../../../hooks';
import { transformCommissioningTasks } from '../transform/transformCommissioningTasks';
import { FilterDatesCommissioning } from '../model/commissioningDateFilter.model';

const logPrefix = getLogPrefixForType('PAGE', 'Commissioning');

/**
 * useFetchCommissioningTasks hook to fetch CommissioningTasks
 * @param systemId: string,
 * @param flightDomainId: string,
 * @return Promise<CommissioningTask[]>
 */
export const useFetchCommissioningTasks = (): {
  fetchCommissioningTask: (
    systemId: string,
    flightDomainId: string,
    filterDates: FilterDatesCommissioning,
    state?: string,
    token?: string,
  ) => Promise<CommissioningTask[]>;
} => {
  const { requestController } = useRequestController('Commissioning Tasks');

  return {
    fetchCommissioningTask: (systemId, flightDomainId, filterDates, state, token) =>
      getRawCommissioningTasksRecursive(
        requestController,
        systemId,
        flightDomainId,
        filterDates,
        state,
        token,
      ),
  };
};

export const getRawCommissioningTasksRecursive = (
  requestController: IRequestController,
  systemId: string,
  flightDomainId: string,
  filterDates: FilterDatesCommissioning,
  state?: string,
  token?: string,
  limit?: number,
): Promise<CommissioningTask[]> =>
  new Promise((resolve, reject) => {
    requestController.doRequest({
      request: getRawCommissioningTasks,
      requestParams: [systemId, flightDomainId, filterDates, state, token, limit],
      messageErrorFallback: `Failed to fetch the Tasks for the domain with id ${flightDomainId}0`,
      callbackSuccess: (res: { data: IFilterTasksResponseST }) => {
        console.debug(logPrefix, 'got tasks', res.data);

        if (res.data.page_token) {
          getRawCommissioningTasksRecursive(
            requestController,
            systemId,
            flightDomainId,
            filterDates,
            state,
            res.data.page_token,
            limit,
          ).then((resNextPage) =>
            resolve([...transformCommissioningTasks(res.data.items ?? []), ...resNextPage]),
          );
        } else {
          resolve(transformCommissioningTasks(res.data.items ?? []));
        }
      },
    });
  });
