import { ITabItemProp, reportTabs, reviewReportTabs } from 'common/Tables';
import { useSettingsStore } from 'store/Settings/settingsStore';

type SetTabs = {
  canReviewReport: boolean;
  reportNeedsReview: boolean;
  numberOfIssues: number;
  numberOfAmended: number;
  numberOfIssuesSnoozed: number;
  numberOfIssuesPotential: number;
  numberOfIssuesInconclusive: number;
  numberOfIssuesInvalid: number;
  numberOfFullReport: number;
  numberOfLocationsToReview: number;
  numberOfOtherLocations: number;
};

/**
 * This function is used for creating report table tabs
 * @param param SetTabs
 * @returns tabs
 */
export const useSetTabs = ({
  canReviewReport,
  reportNeedsReview,
  numberOfIssues,
  numberOfAmended,
  numberOfIssuesSnoozed,
  numberOfIssuesPotential,
  numberOfIssuesInconclusive,
  numberOfIssuesInvalid,
  numberOfFullReport,
  numberOfLocationsToReview,
  numberOfOtherLocations,
}: SetTabs): ITabItemProp[] => {
  const { REPORT_TABLE_TABS, REPORTS_FOR_REVIEW_TABLE_TABS } = useSettingsStore();
  let tableTabs: ITabItemProp[] = [];

  if (canReviewReport && reportNeedsReview) {
    tableTabs = reviewReportTabs({
      REPORTS_FOR_REVIEW_TABLE_TABS,
      numberOfLocationsToReview,
      numberOfOtherLocations,
    });
  } else {
    tableTabs = reportTabs({
      REPORT_TABLE_TABS,
      numberOfIssues,
      numberOfAmended,
      numberOfIssuesSnoozed,
      numberOfIssuesPotential,
      numberOfIssuesInconclusive,
      numberOfIssuesInvalid,
      numberOfFullReport,
    });
  }

  return tableTabs;
};
