import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const droneZonePlaceholderStyle = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gap: theme.spacing(6),
    position: 'relative',
    height: '100%',
  },
}));
