import { useMemo } from 'react';
import { noFlyZoneOpacity } from '../../../../defaults/noFlyZoneOpacity.default';

export const useMeshOpacity = ({
  isActive,
  isSelected,
  isHovering,
}: {
  isActive: boolean;
  isSelected: boolean;
  isHovering: boolean;
}) => {
  const opacity = useMemo(() => {
    if (isActive && !isSelected && !isHovering) {
      return noFlyZoneOpacity.active;
    }

    if (isActive && isSelected) {
      return noFlyZoneOpacity.activeSelected;
    }

    if (isActive && !isSelected && isHovering) {
      return noFlyZoneOpacity.activeHover;
    }

    if (!isActive && isSelected) {
      return noFlyZoneOpacity.inactiveSelected;
    }

    if (!isActive && !isSelected && isHovering) {
      return noFlyZoneOpacity.inactiveHover;
    }

    return noFlyZoneOpacity.inactive;
  }, [isSelected, isActive, isHovering]);

  return { opacity };
};
