import Typography from '@mui/material/Typography';
import NoConnection from 'components/common/NoConnection';
import { tableStyles } from '../styles';

type EnhancedTableHeaderProps = {
  title: string;
  subtitle: string;
  children: any;
  noData?: boolean;
};

const EnhancedTableHeader = ({ title, subtitle, children, noData }: EnhancedTableHeaderProps) => {
  const { classes } = tableStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.details}>
        <Typography className={classes.title} color="secondary" variant="h4">
          {title}
        </Typography>

        {noData ? (
          <NoConnection text="No data available." />
        ) : (
          <Typography className={classes.subheader} color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </div>

      <div className={classes.actions}>{children}</div>
    </div>
  );
};

export default EnhancedTableHeader;
