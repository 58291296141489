import { Grid, Typography, Button } from '@mui/material';

export const ThanksAndSnooze = (p: {
  isSnoozed: boolean;
  isSnoozable: boolean;
  onSnooze: () => void;
}) => {
  const snoozeButtonText = p.isSnoozed ? 'Location is snoozed' : 'Snooze Location';
  const shouldRenderButton = p.isSnoozable || (!p.isSnoozable && p.isSnoozed);
  return (
    <Grid container style={{ padding: '2em 0' }}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">
          Thank you for your feedback.
        </Typography>
      </Grid>
      {shouldRenderButton ? (
        <Grid item container xs={12} justifyContent="center" style={{ margin: '2em 0' }}>
          <Button
            disabled={p.isSnoozed}
            onClick={p.onSnooze}
            color="primary"
            variant="contained"
            data-testid="c-issue-feedback-snooze-button"
          >
            {snoozeButtonText}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
