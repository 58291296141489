import {
  gridRowTreeSelector,
  gridFilteredSortedRowIdsSelector,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { getLocationListData } from 'common/Tables/tableFunctions';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { IFacilityModalsAction } from 'store/Modals/facilityModals/IFacilityModalsActions';
import { FacilityModalsActionTypes } from 'store/Modals/types';

export const handleRowClickEvent = ({
  params,
  gridApiRef,
  dispatchFacilityModals,
  loadLocationsData,
}: {
  params: GridRowParams;
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  dispatchFacilityModals: React.Dispatch<IFacilityModalsAction>;
  loadLocationsData: () => void;
}) => {
  const tree = gridRowTreeSelector(gridApiRef);

  const filteredRows = gridFilteredSortedRowIdsSelector(gridApiRef)
    .filter((rowId) => tree[rowId].type !== 'group') // removes the grouping rows
    .map((rowId) => gridApiRef.current.getRow(rowId)) // mapsrow ids to rows
    .map((row) => getRowForFullReportTable(row.slot_label, row, row.issues)); // transform into flattened data

  const selectedRow = getRowForFullReportTable(
    params.row.slot_label,
    params.row,
    params.row.issues,
  );

  const hasRows = Object.keys(params.row).length > 0;

  hasRows &&
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
      payload: getLocationListData(filteredRows, selectedRow),
      refreshDataOnClose: {
        refreshData: loadLocationsData,
      },
      parentPage: 'WarehouseStatus',
      reportId: undefined,
      locationInfo: { tab: 'currentTabData.tableTitle', searchTerm: 'searchTerm' },
    });
};
