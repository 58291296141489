import { useState } from 'react';
import tail from 'lodash/tail';

import { Grid } from '@mui/material';
import { GenerateQrCode } from './GenerateQrCode';
import { FollowingInstructions } from './FollowingInstructions';
import { InstructionsSteps } from './InstructionsSteps';

import { connectionInstructions } from './helpers';

const DIRECTION = {
  next: 'next',
  back: 'back',
};

/**
 * Connect drone to wifi card
 * @returns component
 */
export function ConnectDroneToWiFiContainer() {
  // state
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isInstructionsStarted, setIsInstructionsStarted] = useState(false);
  const [qrCode, setQrCode] = useState('');

  // Steps for connecting drone to the WiFi
  let steps = [...connectionInstructions];
  steps = tail(connectionInstructions);

  /**
   * Handle direction
   * @param direction direction to go
   * @returns set state of active step
   */
  const handleDirection = (direction: string) => {
    if (activeStep === 0 && direction === DIRECTION.back) {
      setIsInstructionsStarted(false);
      return;
    }

    if (activeStep === 6 && direction === DIRECTION.next) {
      setActiveStep(0);
      setIsInstructionsStarted(false);
    } else {
      setActiveStep((prevState) => (direction === DIRECTION.next ? prevState + 1 : prevState - 1));
    }
  };

  const renderDroneWifiInstruction = () =>
    !isInstructionsStarted ? (
      <FollowingInstructions handleStartInstructions={setIsInstructionsStarted} qrCode={qrCode} />
    ) : (
      <InstructionsSteps
        handleDirection={handleDirection}
        isInstructionsStarted={isInstructionsStarted}
        activeStep={steps[activeStep]}
        direction={DIRECTION}
      />
    );

  return (
    <Grid mt={2} display="flex" alignItems="stretch" spacing={2} container>
      <Grid md={12} lg={6} height="100%" item>
        {renderDroneWifiInstruction()}
      </Grid>
      {/*
        QR Code Generator card
      */}
      <Grid md={12} lg={6} height="100%" item>
        <GenerateQrCode qrCode={qrCode} setQrCode={setQrCode} />
      </Grid>
    </Grid>
  );
}
