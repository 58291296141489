import { NoFlyZone } from 'shared/map/model/no-fly-zone.model';

export enum FlowState {
  LOADING = 'LOADING',
  LIST = 'LIST',
  DRAW = 'DRAW',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export type NoFlyZonePageState = {
  flowState: FlowState;
  currentlyEditingZone: null | NoFlyZone;
  currentlyDeletingZone: null | NoFlyZone;
  currentlySelectedZone: null | NoFlyZone;
  detailsZones: NoFlyZone[];
  noFlyZones: Record<string, NoFlyZone>;
};

export const noFlyZonePageDefaultState: NoFlyZonePageState = {
  flowState: FlowState.LOADING,
  noFlyZones: {},
  detailsZones: [],
  currentlyEditingZone: null,
  currentlyDeletingZone: null,
  currentlySelectedZone: null,
};
