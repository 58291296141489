import { DroneZonePageState, FlowState } from '../reducer/droneZonePage.model';

export const droneZonePageStateDefaults: DroneZonePageState = {
  flowState: FlowState.LOADING,
  controlledZones: {},
  openFlyZones: {},
  selectedZone: null,
  detailsZones: [],
  currentlyEditingZone: null,
  currentlyDeletingZone: null,
};
