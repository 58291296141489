import { AxiosPromise } from 'axios';
import {
  IExclusionRequestResponseST,
  IExclusionStatusPostRequestST,
} from 'codegen/warehouse_status/api';
import { initWarehouseStatusService } from './service';

export type UpdateLocationsDataExclusionStatus = {
  systemId: string;
  payload: IExclusionStatusPostRequestST;
  signal: AbortSignal;
};

/**
 * Update locations exclusion status
 * @param params UpdateLocationsDataExclusionStatus
 * @returns AxiosPromise<void>
 */
export const updateLocationsDataExclusionStatus = ({
  systemId,
  payload,
  signal,
}: UpdateLocationsDataExclusionStatus): AxiosPromise<IExclusionRequestResponseST> =>
  initWarehouseStatusService().updateLocationsDataExclusionStatus(systemId, payload, { signal });
