import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  ClassNameMap,
  Theme,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { ReactElement, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  accordion: {
    backgroundColor: 'transparent',
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    padding: 0,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  },
  accordionDetails: {
    padding: 0,
  },
}));

/**
 * Accordion interface props
 */
interface IAccordionProps {
  summary: ReactElement;
  details: ReactElement;
  elevation?: number;
  classNames?: ClassNameMap<'accordion' | 'accordionSummary' | 'accordionDetails'>;
}
/**
 * Accordion
 * @param param0 IAccordionProps props
 * @returns component
 */
export const Accordion = ({ summary, details, classNames, elevation = 0 }: IAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { classes: cClasses } = useStyles();
  const classes = classNames || cClasses;
  return (
    <MuiAccordion
      expanded={isExpanded}
      elevation={elevation}
      onChange={(e, expanded) => setIsExpanded(expanded)}
      disableGutters
      className={classes.accordion}
    >
      <AccordionSummary
        aria-controls="advanced-settings"
        id="advanced-settings"
        className={classes.accordionSummary}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>{details}</AccordionDetails>
    </MuiAccordion>
  );
};
