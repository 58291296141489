import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    listStyle: 'none',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    paddingInline: 0,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },

    '&:focus-within': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  wrapperSelected: {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.65)} !important`,
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    background: 'none',
    border: 0,
    font: 'inherit',
    textAlign: 'inherit',
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(0.5),
    cursor: 'pointer',
    width: '100%',

    '&:focus': {
      outline: 0,
    },
  },
  detailsName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    minWidth: 0,
    fontWeight: '600',
    paddingInline: theme.spacing(1),
  },
}));
