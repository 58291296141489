import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
  },
  wrapper: {
    '& iframe': {
      width: '100%',
      height: 'calc(100vh - 328px);',
      border: 'none',
    },
  },
  loader: {
    position: 'relative',
    minHeight: '40vh',
  },
}));
