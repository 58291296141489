import { ControlledZones } from '../model/controlled-zone.model';

export const mapSelectedAndEditedZone = ({
  zones,
  selectedZoneId,
  editedZoneId,
}: {
  zones: ControlledZones;
  selectedZoneId: string;
  editedZoneId: string;
}) =>
  Object.values(zones).reduce(
    (acc, val) => ({
      ...acc,
      [val.id]: {
        ...val,
        isSelected: val.id === selectedZoneId,
        isEditing: val.id === editedZoneId,
      },
    }),
    {},
  );
