import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Box } from 'components/common/Box';

export const BarcodeTypes = ({
  subtitle,
  onBarcodeTypeChanges,
  barcodeTypes,
}: {
  subtitle?: string;
  onBarcodeTypeChanges: any;
  barcodeTypes: string[];
}) => {
  const [newBarcodeType, setNewBarcodeType] = useState<string>('');
  const [types, setTypes] = useState<string[]>(barcodeTypes);

  const handleValue = (e: any) => {
    setNewBarcodeType(e.target.value);
  };

  const addTypes = () => {
    setNewBarcodeType('');
    onBarcodeTypeChanges([...types, newBarcodeType]);
  };

  const removeType = (type: string) => {
    const copyArr: any[] = barcodeTypes;
    onBarcodeTypeChanges(copyArr.filter((item: string) => item !== type));
  };

  useEffect(() => {
    if (barcodeTypes) {
      setTypes(barcodeTypes);
    }
  }, [barcodeTypes]);

  return (
    <>
      <Divider />

      <Box pt={1}>
        <Box p={2}>
          <Typography variant="h6" color="secondary">
            {subtitle}
          </Typography>
        </Box>

        <Box px={2} pt={1} pb={2}>
          {!isEmpty(types) ? (
            types.map((type) => (
              <Box justifyContent="space-between" alignItems="center" display="flex" key={type}>
                <Typography>{type}</Typography>
                <IconButton
                  aria-label="remove-barcode-type-button"
                  onClick={() => removeType(type)}
                  color="error"
                  size="large"
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Box>
            ))
          ) : (
            <Typography align="center" variant="subtitle1" color="textSecondary" component="p">
              No barcode type
            </Typography>
          )}
        </Box>

        <Divider />

        <Box px={2} justifyContent="space-between">
          <Box pt={3} display="flex" justifyContent="space-between">
            <TextField
              label="Enter barcode type"
              size="small"
              variant="outlined"
              fullWidth
              value={newBarcodeType}
              onChange={handleValue}
              onBlur={handleValue}
            />

            <IconButton
              aria-label="add-barcode-type-button"
              disabled={!newBarcodeType}
              onClick={addTypes}
              color="primary"
              size="large"
            >
              <AddRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
