/* tslint:disable */
/* eslint-disable */
/**
 * Report Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IReportParameter1ST
 */
export interface IReportParameter1ST {
  /**
   *
   * @type {IReportParameterUniqueNameST}
   * @memberof IReportParameter1ST
   */
  unique_name: IReportParameterUniqueNameST;
  /**
   * The name of the parameter shown to the user
   * @type {string}
   * @memberof IReportParameter1ST
   */
  user_facing_name: string;
  /**
   * Type for the parameter value
   * @type {string}
   * @memberof IReportParameter1ST
   */
  value_type: IReportParameter1STValueTypeEnum;
  /**
   * If set to false, the parameter is ignored in the query
   * @type {boolean}
   * @memberof IReportParameter1ST
   */
  enabled: boolean;
  /**
   * If set to false, the parameter is shown to the user but is not editable
   * @type {boolean}
   * @memberof IReportParameter1ST
   */
  editable: boolean;
  /**
   * If set to false, the parameter is not shown to the user
   * @type {boolean}
   * @memberof IReportParameter1ST
   */
  visible: boolean;
  /**
   * Defines how the user can select the values for this parameter
   * @type {string}
   * @memberof IReportParameter1ST
   */
  input_mode: IReportParameter1STInputModeEnum;
  /**
   * Minimum number of values for this parameter
   * @type {number}
   * @memberof IReportParameter1ST
   */
  cardinality_min: number;
  /**
   * Maximum number of values for this parameter
   * @type {number}
   * @memberof IReportParameter1ST
   */
  cardinality_max: number;
  /**
   * Default values for this parameter
   * @type {Array<string>}
   * @memberof IReportParameter1ST
   */
  default_values: Array<string>;
  /**
   *
   * @type {IReportParameterInputSettingsST}
   * @memberof IReportParameter1ST
   */
  input_settings?: IReportParameterInputSettingsST;
}

export const IReportParameter1STValueTypeEnum = {
  Integer: 'INTEGER',
  Decimal: 'DECIMAL',
  String: 'STRING',
  Date: 'DATE',
  Time: 'TIME',
  DateTime: 'DATE_TIME',
} as const;

export type IReportParameter1STValueTypeEnum =
  typeof IReportParameter1STValueTypeEnum[keyof typeof IReportParameter1STValueTypeEnum];
export const IReportParameter1STInputModeEnum = {
  FixedValue: 'FIXED_VALUE',
  CustomValues: 'CUSTOM_VALUES',
  PredefinedValues: 'PREDEFINED_VALUES',
  Automatic: 'AUTOMATIC',
} as const;

export type IReportParameter1STInputModeEnum =
  typeof IReportParameter1STInputModeEnum[keyof typeof IReportParameter1STInputModeEnum];

/**
 *
 * @export
 * @interface IReportParameterCustomValuesInputSettings1ST
 */
export interface IReportParameterCustomValuesInputSettings1ST {
  /**
   * Value lower bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettings1ST
   */
  lower_bound: number | null;
  /**
   * Value upper bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettings1ST
   */
  upper_bound: number | null;
  /**
   * A regex for cases in which something more strict than upper and lower bounds is needed
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettings1ST
   */
  allowed_values_regex: string;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterCustomValuesInputSettings1ST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettings1ST
   */
  ui_widget_label: string;
  /**
   * List of accepted values to be shown to the user as hint
   * @type {Array<string>}
   * @memberof IReportParameterCustomValuesInputSettings1ST
   */
  accepted_values: Array<string>;
}

/**
 *
 * @export
 * @interface IReportParameterCustomValuesInputSettingsST
 */
export interface IReportParameterCustomValuesInputSettingsST {
  /**
   * Value lower bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  lower_bound: number | null;
  /**
   * Value upper bound
   * @type {number}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  upper_bound: number | null;
  /**
   * A regex for cases in which something more strict than upper and lower bounds is needed
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  allowed_values_regex: string;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  ui_widget_label: string;
  /**
   * List of accepted values to be shown to the user as hint
   * @type {Array<string>}
   * @memberof IReportParameterCustomValuesInputSettingsST
   */
  accepted_values: Array<string>;
}

/**
 * @type IReportParameterInputSettingsST
 * @export
 */
export type IReportParameterInputSettingsST =
  | IReportParameterCustomValuesInputSettings1ST
  | IReportParameterPredefinedValuesInputSettings1ST;

/**
 *
 * @export
 * @interface IReportParameterPredefinedValuesInputSettings1ST
 */
export interface IReportParameterPredefinedValuesInputSettings1ST {
  /**
   *
   * @type {Array<IReportParameterPredefinedValuesInputSettingsOptionsST>}
   * @memberof IReportParameterPredefinedValuesInputSettings1ST
   */
  options: Array<IReportParameterPredefinedValuesInputSettingsOptionsST>;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterPredefinedValuesInputSettings1ST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettings1ST
   */
  ui_widget_label: string;
  /**
   *
   * @type {IReportParameterCustomValuesInputSettings1ST}
   * @memberof IReportParameterPredefinedValuesInputSettings1ST
   */
  custom_values_input_settings?: IReportParameterCustomValuesInputSettings1ST;
}

/**
 *
 * @export
 * @interface IReportParameterPredefinedValuesInputSettingsOptionsST
 */
export interface IReportParameterPredefinedValuesInputSettingsOptionsST {
  /**
   * Name for option value
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsOptionsST
   */
  label: string;
  /**
   * Value for option
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsOptionsST
   */
  value: string;
}
/**
 *
 * @export
 * @interface IReportParameterPredefinedValuesInputSettingsST
 */
export interface IReportParameterPredefinedValuesInputSettingsST {
  /**
   *
   * @type {Array<IReportParameterPredefinedValuesInputSettingsOptionsST>}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  options: Array<IReportParameterPredefinedValuesInputSettingsOptionsST>;
  /**
   *
   * @type {IUiWidgetTypeST}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  ui_widget_type: IUiWidgetTypeST;
  /**
   * The label to be displayed next to the widget
   * @type {string}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  ui_widget_label: string;
  /**
   *
   * @type {IReportParameterCustomValuesInputSettings1ST}
   * @memberof IReportParameterPredefinedValuesInputSettingsST
   */
  custom_values_input_settings?: IReportParameterCustomValuesInputSettings1ST;
}

/**
 *
 * @export
 * @interface IReportParameterST
 */
export interface IReportParameterST {
  /**
   *
   * @type {IReportParameterUniqueNameST}
   * @memberof IReportParameterST
   */
  unique_name: IReportParameterUniqueNameST;
  /**
   * The name of the parameter shown to the user
   * @type {string}
   * @memberof IReportParameterST
   */
  user_facing_name: string;
  /**
   * Type for the parameter value
   * @type {string}
   * @memberof IReportParameterST
   */
  value_type: IReportParameterSTValueTypeEnum;
  /**
   * If set to false, the parameter is ignored in the query
   * @type {boolean}
   * @memberof IReportParameterST
   */
  enabled: boolean;
  /**
   * If set to false, the parameter is shown to the user but is not editable
   * @type {boolean}
   * @memberof IReportParameterST
   */
  editable: boolean;
  /**
   * If set to false, the parameter is not shown to the user
   * @type {boolean}
   * @memberof IReportParameterST
   */
  visible: boolean;
  /**
   * Defines how the user can select the values for this parameter
   * @type {string}
   * @memberof IReportParameterST
   */
  input_mode: IReportParameterSTInputModeEnum;
  /**
   * Minimum number of values for this parameter
   * @type {number}
   * @memberof IReportParameterST
   */
  cardinality_min: number;
  /**
   * Maximum number of values for this parameter
   * @type {number}
   * @memberof IReportParameterST
   */
  cardinality_max: number;
  /**
   * Default values for this parameter
   * @type {Array<string>}
   * @memberof IReportParameterST
   */
  default_values: Array<string>;
  /**
   *
   * @type {IReportParameterInputSettingsST}
   * @memberof IReportParameterST
   */
  input_settings?: IReportParameterInputSettingsST;
}

export const IReportParameterSTValueTypeEnum = {
  Integer: 'INTEGER',
  Decimal: 'DECIMAL',
  String: 'STRING',
  Date: 'DATE',
  Time: 'TIME',
  DateTime: 'DATE_TIME',
} as const;

export type IReportParameterSTValueTypeEnum =
  typeof IReportParameterSTValueTypeEnum[keyof typeof IReportParameterSTValueTypeEnum];
export const IReportParameterSTInputModeEnum = {
  FixedValue: 'FIXED_VALUE',
  CustomValues: 'CUSTOM_VALUES',
  PredefinedValues: 'PREDEFINED_VALUES',
  Automatic: 'AUTOMATIC',
} as const;

export type IReportParameterSTInputModeEnum =
  typeof IReportParameterSTInputModeEnum[keyof typeof IReportParameterSTInputModeEnum];

/**
 * Unique name of the parameter
 * @export
 * @enum {string}
 */

export const IReportParameterUniqueNameST = {
  LocationLabel: 'LOCATION_LABEL',
  PalletNumber: 'PALLET_NUMBER',
  ArticleNumber: 'ARTICLE_NUMBER',
  Customer: 'CUSTOMER',
  WmsStatusAge: 'WMS_STATUS_AGE',
  NewIssueAge: 'NEW_ISSUE_AGE',
  LocationsPercentage: 'LOCATIONS_PERCENTAGE',
} as const;

export type IReportParameterUniqueNameST =
  typeof IReportParameterUniqueNameST[keyof typeof IReportParameterUniqueNameST];

/**
 *
 * @export
 * @interface IReportSchedulingParametersST
 */
export interface IReportSchedulingParametersST {
  /**
   * Specifies the source that triggers the inventory request for this report
   * @type {string}
   * @memberof IReportSchedulingParametersST
   */
  triggering_source: IReportSchedulingParametersSTTriggeringSourceEnum;
  /**
   * Specifies what kind of event can trigger an inventory request for this report
   * @type {Array<string>}
   * @memberof IReportSchedulingParametersST
   */
  triggering_events?: Array<IReportSchedulingParametersSTTriggeringEventsEnum>;
  /**
   * If set to true, the task can be recurrent
   * @type {boolean}
   * @memberof IReportSchedulingParametersST
   */
  allows_recurrence: boolean;
}

export const IReportSchedulingParametersSTTriggeringSourceEnum = {
  TimeBasedSchedule: 'TIME_BASED_SCHEDULE',
  ExternalEvent: 'EXTERNAL_EVENT',
} as const;

export type IReportSchedulingParametersSTTriggeringSourceEnum =
  typeof IReportSchedulingParametersSTTriggeringSourceEnum[keyof typeof IReportSchedulingParametersSTTriggeringSourceEnum];
export const IReportSchedulingParametersSTTriggeringEventsEnum = {
  None: 'NONE',
  WmsSnapshotUpload: 'WMS_SNAPSHOT_UPLOAD',
  WmsMovedLocationUpload: 'WMS_MOVED_LOCATION_UPLOAD',
  WmsStockCheckListUpload: 'WMS_STOCK_CHECK_LIST_UPLOAD',
} as const;

export type IReportSchedulingParametersSTTriggeringEventsEnum =
  typeof IReportSchedulingParametersSTTriggeringEventsEnum[keyof typeof IReportSchedulingParametersSTTriggeringEventsEnum];

/**
 *
 * @export
 * @interface IReportSpecification1ST
 */
export interface IReportSpecification1ST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  id: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecification1ST
   */
  version: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  created_at: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecification1ST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecification1ST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecification1ST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecification1ST
   */
  allowed_user_groups: Array<IReportSpecification1STAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameter1ST>}
   * @memberof IReportSpecification1ST
   */
  params: Array<IReportParameter1ST>;
}

export const IReportSpecification1STAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecification1STAllowedUserGroupsEnum =
  typeof IReportSpecification1STAllowedUserGroupsEnum[keyof typeof IReportSpecification1STAllowedUserGroupsEnum];

/**
 *
 * @export
 * @interface IReportSpecificationBase1ST
 */
export interface IReportSpecificationBase1ST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  id?: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationBase1ST
   */
  version?: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  created_at?: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBase1ST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationBase1ST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationBase1ST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationBase1ST
   */
  allowed_user_groups: Array<IReportSpecificationBase1STAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameter1ST>}
   * @memberof IReportSpecificationBase1ST
   */
  params: Array<IReportParameter1ST>;
}

export const IReportSpecificationBase1STAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationBase1STAllowedUserGroupsEnum =
  typeof IReportSpecificationBase1STAllowedUserGroupsEnum[keyof typeof IReportSpecificationBase1STAllowedUserGroupsEnum];

/**
 *
 * @export
 * @interface IReportSpecificationBaseST
 */
export interface IReportSpecificationBaseST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  id?: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationBaseST
   */
  version?: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  created_at?: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationBaseST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationBaseST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationBaseST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationBaseST
   */
  allowed_user_groups: Array<IReportSpecificationBaseSTAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameter1ST>}
   * @memberof IReportSpecificationBaseST
   */
  params: Array<IReportParameter1ST>;
}

export const IReportSpecificationBaseSTAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationBaseSTAllowedUserGroupsEnum =
  typeof IReportSpecificationBaseSTAllowedUserGroupsEnum[keyof typeof IReportSpecificationBaseSTAllowedUserGroupsEnum];

/**
 *
 * @export
 * @interface IReportSpecificationGetAllResponseST
 */
export interface IReportSpecificationGetAllResponseST {
  /**
   *
   * @type {Array<IReportSpecification1ST>}
   * @memberof IReportSpecificationGetAllResponseST
   */
  items: Array<IReportSpecification1ST>;
}
/**
 *
 * @export
 * @interface IReportSpecificationGetResponseST
 */
export interface IReportSpecificationGetResponseST {
  /**
   *
   * @type {IReportSpecification1ST}
   * @memberof IReportSpecificationGetResponseST
   */
  item: IReportSpecification1ST;
}
/**
 *
 * @export
 * @interface IReportSpecificationPostRequestST
 */
export interface IReportSpecificationPostRequestST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  id?: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationPostRequestST
   */
  version?: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  created_at?: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationPostRequestST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationPostRequestST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationPostRequestST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationPostRequestST
   */
  allowed_user_groups: Array<IReportSpecificationPostRequestSTAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameter1ST>}
   * @memberof IReportSpecificationPostRequestST
   */
  params: Array<IReportParameter1ST>;
}

export const IReportSpecificationPostRequestSTAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationPostRequestSTAllowedUserGroupsEnum =
  typeof IReportSpecificationPostRequestSTAllowedUserGroupsEnum[keyof typeof IReportSpecificationPostRequestSTAllowedUserGroupsEnum];

/**
 *
 * @export
 * @interface IReportSpecificationPostResponseST
 */
export interface IReportSpecificationPostResponseST {
  /**
   * Created report specification ID
   * @type {string}
   * @memberof IReportSpecificationPostResponseST
   */
  item_id: string;
}
/**
 *
 * @export
 * @interface IReportSpecificationST
 */
export interface IReportSpecificationST {
  /**
   * Report specification ID
   * @type {string}
   * @memberof IReportSpecificationST
   */
  id: string;
  /**
   * Report specification version
   * @type {number}
   * @memberof IReportSpecificationST
   */
  version: number;
  /**
   * Timestamp of when the report specification was created
   * @type {string}
   * @memberof IReportSpecificationST
   */
  created_at: string;
  /**
   * Name of the the report type
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_spec_name: string;
  /**
   * Default name for the inventory request generated for this report specification
   * @type {string}
   * @memberof IReportSpecificationST
   */
  request_default_name: string;
  /**
   * Description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_description: string;
  /**
   * Short description of this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_short_description: string;
  /**
   * Path of the picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_picture: string;
  /**
   * Path of the thumbnail picture for this report specification to be shown to the user
   * @type {string}
   * @memberof IReportSpecificationST
   */
  report_thumbnail_picture: string;
  /**
   *
   * @type {IReportSchedulingParametersST}
   * @memberof IReportSpecificationST
   */
  scheduling: IReportSchedulingParametersST;
  /**
   * Defines what slots should be considered expired
   * @type {number}
   * @memberof IReportSpecificationST
   */
  max_slot_status_age_sec: number;
  /**
   * Defines the list of user groups that have access to this report specification
   * @type {Array<string>}
   * @memberof IReportSpecificationST
   */
  allowed_user_groups: Array<IReportSpecificationSTAllowedUserGroupsEnum>;
  /**
   *
   * @type {Array<IReportParameter1ST>}
   * @memberof IReportSpecificationST
   */
  params: Array<IReportParameter1ST>;
}

export const IReportSpecificationSTAllowedUserGroupsEnum = {
  InventoryManager: 'InventoryManager',
  SuperAdmin: 'SuperAdmin',
  GroundControlOfficer: 'GroundControlOfficer',
  SafetyControls: 'SafetyControls',
  Analytics: 'Analytics',
  VerityUser: 'VerityUser',
  Reviewer: 'Reviewer',
  Client: 'Client',
  Verity: 'Verity',
  GroundControl: 'GroundControl',
} as const;

export type IReportSpecificationSTAllowedUserGroupsEnum =
  typeof IReportSpecificationSTAllowedUserGroupsEnum[keyof typeof IReportSpecificationSTAllowedUserGroupsEnum];

/**
 * Widget that should be used to show and select them
 * @export
 * @enum {string}
 */

export const IUiWidgetTypeST = {
  TextField: 'TEXT_FIELD',
  DropDown: 'DROP_DOWN',
  RadioBox: 'RADIO_BOX',
  CheckBox: 'CHECK_BOX',
  FileChooser: 'FILE_CHOOSER',
  DatePicker: 'DATE_PICKER',
  DatetimePicker: 'DATETIME_PICKER',
  Custom: 'CUSTOM',
} as const;

export type IUiWidgetTypeST = typeof IUiWidgetTypeST[keyof typeof IUiWidgetTypeST];

/**
 * ReportSpecificationApi - axios parameter creator
 * @export
 */
export const ReportSpecificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Remove specific report specification.
     * @summary Remove specific report specification.
     * @param {string} systemIdStr
     * @param {string} reportSpecificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReportSpecification: async (
      systemIdStr: string,
      reportSpecificationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteReportSpecification', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportSpecificationId' is not null or undefined
      assertParamExists(
        'deleteReportSpecification',
        'reportSpecificationId',
        reportSpecificationId,
      );
      const localVarPath = `/{system_id_str}/report-specification/{report_specification_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(
          `{${'report_specification_id'}}`,
          encodeURIComponent(String(reportSpecificationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return specific report specification.
     * @summary Get specific report specification.
     * @param {string} systemIdStr
     * @param {string} reportSpecificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSpecification: async (
      systemIdStr: string,
      reportSpecificationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReportSpecification', 'systemIdStr', systemIdStr);
      // verify required parameter 'reportSpecificationId' is not null or undefined
      assertParamExists('getReportSpecification', 'reportSpecificationId', reportSpecificationId);
      const localVarPath = `/{system_id_str}/report-specification/{report_specification_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(
          `{${'report_specification_id'}}`,
          encodeURIComponent(String(reportSpecificationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return all report specifications.
     * @summary Get all report specifications.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSpecifications: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getReportSpecifications', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/report-specification`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create report specification.
     * @summary Create report specification.
     * @param {string} systemIdStr
     * @param {IReportSpecificationPostRequestST} iReportSpecificationPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadReportSpecification: async (
      systemIdStr: string,
      iReportSpecificationPostRequestST: IReportSpecificationPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('uploadReportSpecification', 'systemIdStr', systemIdStr);
      // verify required parameter 'iReportSpecificationPostRequestST' is not null or undefined
      assertParamExists(
        'uploadReportSpecification',
        'iReportSpecificationPostRequestST',
        iReportSpecificationPostRequestST,
      );
      const localVarPath = `/{system_id_str}/report-specification`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iReportSpecificationPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportSpecificationApi - functional programming interface
 * @export
 */
export const ReportSpecificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportSpecificationApiAxiosParamCreator(configuration);
  return {
    /**
     * Remove specific report specification.
     * @summary Remove specific report specification.
     * @param {string} systemIdStr
     * @param {string} reportSpecificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteReportSpecification(
      systemIdStr: string,
      reportSpecificationId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportSpecification(
        systemIdStr,
        reportSpecificationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return specific report specification.
     * @summary Get specific report specification.
     * @param {string} systemIdStr
     * @param {string} reportSpecificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportSpecification(
      systemIdStr: string,
      reportSpecificationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportSpecificationGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSpecification(
        systemIdStr,
        reportSpecificationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return all report specifications.
     * @summary Get all report specifications.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReportSpecifications(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IReportSpecificationGetAllResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSpecifications(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create report specification.
     * @summary Create report specification.
     * @param {string} systemIdStr
     * @param {IReportSpecificationPostRequestST} iReportSpecificationPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadReportSpecification(
      systemIdStr: string,
      iReportSpecificationPostRequestST: IReportSpecificationPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IReportSpecificationPostResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadReportSpecification(
        systemIdStr,
        iReportSpecificationPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportSpecificationApi - factory interface
 * @export
 */
export const ReportSpecificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReportSpecificationApiFp(configuration);
  return {
    /**
     * Remove specific report specification.
     * @summary Remove specific report specification.
     * @param {string} systemIdStr
     * @param {string} reportSpecificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReportSpecification(
      systemIdStr: string,
      reportSpecificationId: string,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .deleteReportSpecification(systemIdStr, reportSpecificationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return specific report specification.
     * @summary Get specific report specification.
     * @param {string} systemIdStr
     * @param {string} reportSpecificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSpecification(
      systemIdStr: string,
      reportSpecificationId: string,
      options?: any,
    ): AxiosPromise<IReportSpecificationGetResponseST> {
      return localVarFp
        .getReportSpecification(systemIdStr, reportSpecificationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return all report specifications.
     * @summary Get all report specifications.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportSpecifications(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IReportSpecificationGetAllResponseST> {
      return localVarFp
        .getReportSpecifications(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create report specification.
     * @summary Create report specification.
     * @param {string} systemIdStr
     * @param {IReportSpecificationPostRequestST} iReportSpecificationPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadReportSpecification(
      systemIdStr: string,
      iReportSpecificationPostRequestST: IReportSpecificationPostRequestST,
      options?: any,
    ): AxiosPromise<IReportSpecificationPostResponseST> {
      return localVarFp
        .uploadReportSpecification(systemIdStr, iReportSpecificationPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReportSpecificationApi - object-oriented interface
 * @export
 * @class ReportSpecificationApi
 * @extends {BaseAPI}
 */
export class ReportSpecificationApi extends BaseAPI {
  /**
   * Remove specific report specification.
   * @summary Remove specific report specification.
   * @param {string} systemIdStr
   * @param {string} reportSpecificationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportSpecificationApi
   */
  public deleteReportSpecification(
    systemIdStr: string,
    reportSpecificationId: string,
    options?: AxiosRequestConfig,
  ) {
    return ReportSpecificationApiFp(this.configuration)
      .deleteReportSpecification(systemIdStr, reportSpecificationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return specific report specification.
   * @summary Get specific report specification.
   * @param {string} systemIdStr
   * @param {string} reportSpecificationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportSpecificationApi
   */
  public getReportSpecification(
    systemIdStr: string,
    reportSpecificationId: string,
    options?: AxiosRequestConfig,
  ) {
    return ReportSpecificationApiFp(this.configuration)
      .getReportSpecification(systemIdStr, reportSpecificationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return all report specifications.
   * @summary Get all report specifications.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportSpecificationApi
   */
  public getReportSpecifications(systemIdStr: string, options?: AxiosRequestConfig) {
    return ReportSpecificationApiFp(this.configuration)
      .getReportSpecifications(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create report specification.
   * @summary Create report specification.
   * @param {string} systemIdStr
   * @param {IReportSpecificationPostRequestST} iReportSpecificationPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportSpecificationApi
   */
  public uploadReportSpecification(
    systemIdStr: string,
    iReportSpecificationPostRequestST: IReportSpecificationPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return ReportSpecificationApiFp(this.configuration)
      .uploadReportSpecification(systemIdStr, iReportSpecificationPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
