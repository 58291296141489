import React, { useState } from 'react';

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// components
import { REQUEST_STATES } from 'common/requestStates';
import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';

// variables, functions, configurations
import { IInventoryRequestST } from 'codegen/inventory_request';
import ModalBase from './ModalBase';
import { useRequestController } from '../../hooks';
import { useFacilityModalsStore } from '../../store/Modals';
import { FacilityModalsActionTypes } from '../../store/Modals/types';
import RequestStore from '../../store/RequestStore/RequestStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

interface IEnableDisableModal {
  opened: boolean;
  requestItem: IInventoryRequestST;
}

const EnableDisableModal = (props: IEnableDisableModal) => {
  // props
  const { opened, requestItem } = props;

  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();
  const { requestController } = useRequestController('EnableDisableModal');

  const [loading, setLoading] = useState(false);

  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const toggleRequestState = (requestId: string, action: string) => {
    setLoading(true);
    switch (action) {
      case 'enable':
        requestController.doRequest({
          request: RequestStore.toggleRequestState,
          requestParams: [systemId, requestId, true],
          callbackSuccess: () => {
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.TOGGLE_REQUEST_STATE,
              payload: {},
            });
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          },
          messageSuccess: 'Report enabled!',
          messageErrorFallback: 'Failed to enable the Report.',
          callbackFinally: () => setLoading(false),
        });
        break;

      case 'disable':
        requestController.doRequest({
          request: RequestStore.toggleRequestState,
          requestParams: [systemId, requestId, false],
          callbackSuccess: () => {
            dispatchFacilityModals({
              type: FacilityModalsActionTypes.TOGGLE_REQUEST_STATE,
              payload: {},
            });
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          },
          messageSuccess: 'Report disabled!',
          messageErrorFallback: 'Failed to disable the Report.',
          callbackFinally: () => setLoading(false),
        });
        break;

      default:
        break;
    }
  };

  const closeModal = () =>
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_REQUEST_STATE,
      payload: {},
    });

  const {
    state,
    report_name: reportName,
    requesting_user_email: requestingUserEmail,
    request_id: requestId,
  } = requestItem;

  return (
    <ModalBase
      testId="c-enable-disable-modal"
      opened={opened}
      title={
        <Box>
          <Box textAlign="left" p={2} mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
              {state === REQUEST_STATES.ENABLED
                ? 'Disable scheduled report?'
                : 'Enable scheduled report?'}
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              {`${reportName} by ${requestingUserEmail || 'n.a.'}`}
            </Typography>
          </Box>
          {loading && <Spinner />}
        </Box>
      }
      handleClose={closeModal}
      actionButtons={
        <>
          {state === REQUEST_STATES.ENABLED ? (
            <Button
              onClick={() => toggleRequestState(requestId, 'disable')}
              variant="contained"
              fullWidth
              color="primary"
            >
              Disable
            </Button>
          ) : (
            <Button
              onClick={() => toggleRequestState(requestId, 'enable')}
              variant="contained"
              fullWidth
              color="primary"
            >
              Enable
            </Button>
          )}
          <Button onClick={closeModal} variant="outlined" fullWidth color="primary">
            Cancel
          </Button>
        </>
      }
    >
      <Typography style={{ margin: '16px 0px' }} align="left">
        {`Are you absolutely sure you want to
          ${state === REQUEST_STATES.ENABLED ? 'disable' : 'enable'}
          this scheduled report?`}
      </Typography>
    </ModalBase>
  );
};

export default EnableDisableModal;
