import moment, { CalendarSpec } from 'moment-timezone';
import { FLIGHT_DOMAIN_DRONE_SHIFT_FORMAT } from 'common/datetimeFormats';

interface IFormatDateTimeByTimeZone {
  text: string;
  datetime: string;
  timezone: string;
  calendarFormat?: CalendarSpec;
}

/**
 * Formats datetime with timezone and calendar format
 * @param param0
 * @returns formated datetime
 */
export const formatDateTimeByTimeZoneAndCalendarSpec = ({
  text,
  datetime,
  timezone,
  calendarFormat = FLIGHT_DOMAIN_DRONE_SHIFT_FORMAT,
}: IFormatDateTimeByTimeZone) =>
  `${text} ${moment(datetime).tz(timezone).calendar(null, calendarFormat)}`;
