import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { DroneZoneAccordion } from './features/drone-zone-accordion/DroneZoneAccordion';
import { ControlledZonesList } from './features/controlled-zones-list/ControlledZonesList';
import { DeliveryDroneZoneType } from '../../../../models/deliveryDroneZones.model';
import {
  DroneZoneCloneCommand,
  DroneZoneCreateCommand,
  DroneZoneDeleteCommand,
  DroneZoneEditCommand,
  DroneZoneSelectCommand,
} from '../../../drone-zone-command/droneZoneCommand.type';
import { droneZoneCommandFactory } from '../../../drone-zone-command/droneZoneCommandFactory';

type DroneZonesListProps = {
  visibleZones: Record<DeliveryDroneZoneType, boolean>;
  controlledZones: ControlledZones;
  isLoading: boolean;
  onDroneZoneCommand: (
    droneZoneCommand:
      | DroneZoneSelectCommand
      | DroneZoneEditCommand
      | DroneZoneDeleteCommand
      | DroneZoneCloneCommand
      | DroneZoneCreateCommand,
  ) => void;
};

export const DroneZonesList = ({
  visibleZones,
  controlledZones,
  isLoading,
  onDroneZoneCommand,
}: DroneZonesListProps) => {
  const numberOfControlledZones = Object.keys(controlledZones).length;

  return (
    <DroneZoneAccordion
      title="Controlled zones"
      accordionSummaryAriaLabel={`Controlled zones (${numberOfControlledZones})`}
      isVisible={visibleZones[DroneZoneTypes.controlledZone]}
      onTriggerCreateMode={() => onDroneZoneCommand(droneZoneCommandFactory.createCreateCommand())}
    >
      <ControlledZonesList
        onDelete={(zone: ControlledZone) =>
          onDroneZoneCommand(droneZoneCommandFactory.createDeleteCommand(zone))
        }
        onEdit={(zone: ControlledZone) =>
          onDroneZoneCommand(droneZoneCommandFactory.createEditCommand(zone))
        }
        isLoading={isLoading}
        zones={controlledZones}
        onSelect={(zone: ControlledZone) =>
          onDroneZoneCommand(droneZoneCommandFactory.createSelectCommand(zone))
        }
        onClone={(zone: ControlledZone) =>
          onDroneZoneCommand(droneZoneCommandFactory.createCloneCommand(zone))
        }
      />
    </DroneZoneAccordion>
  );
};
