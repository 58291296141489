// libraries
import { createContext, useReducer, useEffect, useContext, useCallback } from 'react';

// stores
import { tSort } from 'common/functions/otherFunctions';
import { getLogPrefixForType, removeAnsiEscapeSequences } from 'common/functions/logFunctions';
import UserStore from '../UserStore';
import WarehouseStore from '../WarehouseStore';

// functions

// the reducer that manipulates this store
import { ClientLevelReducer, initialClientLevelState } from './clientLevelReducer';
import {
  IClientLevelContext,
  IClientLevelState,
  IClientLevelStateFacility,
  noClientLevelStateFacility,
} from './IClientLevelStore';
import { ClientLevelAction, ClientLevelActionNames } from './ClientLevelActions';
import { IGetUserSystemIds } from '../../interfaces/facilityInterfaces';

// context and hooks
import { IRequestController } from '../../hooks';
import { userHasPermission } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';

/**
 * Initial state of the ClientLevelStore
 * Holds client level state and dispatch functions
 */
const initialContext: IClientLevelContext = {
  stateClientLevel: initialClientLevelState,
  dispatchClientLevel: undefined,
  asyncPopulateFacilities: (requestController: IRequestController, populateIssues: boolean) =>
    new Promise<any>((resolve, reject) => {}),
  asyncPopulateActiveIssues: (
    requestController: IRequestController,
    facilityList: IClientLevelStateFacility[],
  ) => new Promise<any>((resolve, reject) => {}),
  isDataReady: () => false,
  isDataLoading: () => true,
  asyncRefreshFacilities: (requestController: IRequestController, populateIssues: boolean) =>
    new Promise<any>((resolve, reject) => {}),
};

// store & hook to access it
const Store = createContext(initialContext);
export const useClientLevelStore = () => useContext(Store);

const logPrefix = getLogPrefixForType('STORE', 'ClientLevelStoreProvider');

// state provider
export const ClientLevelStoreProvider = ({ children }: any) => {
  const [stateClientLevel, dispatchClientLevel]: [
    IClientLevelState,
    React.Dispatch<ClientLevelAction>,
  ] = useReducer(ClientLevelReducer, initialClientLevelState);

  const isDataReady = useCallback(() => {
    const ready =
      stateClientLevel.isFacilityListPopulated && !stateClientLevel.isFacilityListLoading;
    console.debug(logPrefix, `is data ready: ${ready}`);
    return ready;
  }, [stateClientLevel.isFacilityListPopulated, stateClientLevel.isFacilityListLoading]);

  const isDataLoading = useCallback(
    () => stateClientLevel.isFacilityListLoading,
    [stateClientLevel.isFacilityListLoading],
  );

  const hasDataErrors = useCallback(
    () => stateClientLevel.failedToLoadFacilityList,
    [stateClientLevel.failedToLoadFacilityList],
  );

  const asyncPopulateActiveIssues = useCallback(
    /**
     * Retrieve active issues for a collection of facilities and update facility list.
     * @param {IRequestController} requestController cancellation controller.
     * @param {*} facilities list of facilities the active issues are retrieved for.
     */

    async (requestController: IRequestController, facilities: IClientLevelStateFacility[]) => {
      facilities.forEach((f) => {
        if (f.isConfigured) {
          const { signal } = requestController.reserveSlotForRequest();
          requestController.doRequest({
            request: WarehouseStore.getActiveIssueCount,
            requestParams: [f.id, signal],
            messageErrorFallback: `An error occurred when loading issues for ${f.name}`,
            callbackBeforeSend: () =>
              dispatchClientLevel({
                type: ClientLevelActionNames.UPDATE_FACILITY_ATTRIBUTE,
                payload: {
                  id: f.id,
                  key: 'isIssueCounterLoading',
                  value: true,
                },
              }),
            callbackFinally: () => {
              dispatchClientLevel({
                type: ClientLevelActionNames.UPDATE_FACILITY_ATTRIBUTE,
                payload: {
                  id: f.id,
                  key: 'isIssueCounterLoading',
                  value: false,
                },
              });
            },
            callbackSuccess: (r: any) => {
              // Update issue number for the facility
              dispatchClientLevel({
                type: ClientLevelActionNames.UPDATE_FACILITY_ATTRIBUTE,
                payload: {
                  id: f.id,
                  key: 'issueCounter',
                  value: r.warehouseStatus.counter,
                },
              });
            },
          });
        }
      });
    },
    [],
  );

  /**
   * Retrieve the data related with the facilities and populate facility list.
   * @param {IRequestController} requestController cancellation controller.
   * be retrieved and stored.
   */
  const asyncRefreshFacilities = useCallback(async (requestController: IRequestController) => {
    const lp = getLogPrefixForType('FUNCTION', 'refreshFacilities', logPrefix);
    const getUserFacilityReservation = requestController.reserveSlotForRequest();
    return requestController.doRequest({
      request: UserStore.getUserFacilities,
      requestParams: [getUserFacilityReservation.signal],
      messageErrorFallback: 'An error occurred when loading the list of facilities.',
      cancellableCallbacks: false,
      callbackSuccess: (userFacilities: IGetUserSystemIds[]) => {
        console.debug(lp, 'will update store');
        console.debug(
          lp,
          `request made by "${removeAnsiEscapeSequences(requestController.componentName)}"`,
        );
        // Filter out facilities that are not configured
        // in case the user is not a verity user
        const filteredFacilities = userFacilities?.filter(
          ({ facility }) => facility || userHasPermission(PERMISSION.FACILITY_MANAGEMENT),
        );

        // Augment facilities to contain active issues and map processing state
        const unsortedFacilities: IClientLevelStateFacility[] = filteredFacilities.map((f) => {
          const facility = { ...noClientLevelStateFacility };
          facility.id = f.systemId;
          facility.issueCounter = '-';
          facility.isIssueCounterLoading = false;

          // If the facility does not have a name, it is because it is not
          // yet configured.
          if (f.facility === undefined) {
            facility.name = `Facility Id: ${facility.id}`;
            facility.issueCounter = 0;
            facility.isConfigured = false;
          } else {
            facility.name = f.facility;
            facility.isConfigured = true;
          }

          return facility;
        });

        // Sort the facilities by name
        const sortedFacilities = tSort(unsortedFacilities, 'asc', 'name');

        // Update state as soon as all facility names are available and sorted
        dispatchClientLevel({
          type: ClientLevelActionNames.SET_FACILITY_LIST,
          payload: sortedFacilities,
        });
        dispatchClientLevel({
          type: ClientLevelActionNames.SET_FACILITY_LIST_POPULATED,
          payload: true,
        });
      },
    });
  }, []);

  const asyncPopulateFacilities = useCallback(
    /**
     * Retrieve the data related with the facilities and populate facility list.
     * @param {IRequestController} requestController cancellation controller.
     * @param {boolean} populateIssues flag indicating whether the active issues per facility will
     * be retrieved and stored.
     * @returns a promise which will resolve if the operation is successful.
     */
    async (requestController: IRequestController, populateIssues: boolean) => {
      const lp = getLogPrefixForType('FUNCTION', 'asyncPopulateFacilities', logPrefix);
      console.debug(lp, 'invoked');

      // Set loading state
      dispatchClientLevel({
        type: ClientLevelActionNames.SET_FACILITY_LIST_LOADING,
        payload: true,
      });

      dispatchClientLevel({
        type: ClientLevelActionNames.SET_FAILED_TO_LOAD_FACILITY_LIST,
        payload: false,
      });

      asyncRefreshFacilities(requestController)
        .catch((err) => {
          dispatchClientLevel({
            type: ClientLevelActionNames.SET_FAILED_TO_LOAD_FACILITY_LIST,
            payload: true,
          });
        })
        .finally(() => {
          dispatchClientLevel({
            type: ClientLevelActionNames.SET_FACILITY_LIST_LOADING,
            payload: false,
          });
        });
    },
    [asyncRefreshFacilities],
  );

  // We have this useEffect for debugging purposes, thank me later
  useEffect(() => {
    console.debug(logPrefix, 'Facility list changed', stateClientLevel.facilityList);
  }, [stateClientLevel.facilityList]);

  return (
    <Store.Provider
      value={{
        stateClientLevel,
        dispatchClientLevel,
        asyncPopulateFacilities,
        asyncPopulateActiveIssues,
        isDataReady,
        isDataLoading,
        hasDataErrors,
        asyncRefreshFacilities,
      }}
    >
      {children}
    </Store.Provider>
  );
};
