import { Euler, Quaternion, Vector3 } from 'three';
import { getLogPrefixForType } from 'common/functions/logFunctions';

type Tweenable = number | Vector3 | Euler;

const isNumber = (a: unknown): a is number => typeof a === 'number';
const isVector3 = (a: unknown): a is Vector3 => !!(a as Vector3).isVector3;
const isEuler = (a: unknown): a is Euler => !!(a as Euler).isEuler;

const lerpEuler = (start: Euler, end: Euler, t: number): Euler => {
  const startQuaternion = new Quaternion().setFromEuler(start);
  const endQuaternion = new Quaternion().setFromEuler(end);
  const lerpedQuaternion = new Quaternion().copy(startQuaternion).slerp(endQuaternion, t);
  return new Euler().setFromQuaternion(lerpedQuaternion);
};

const lerpVector = (v1: Vector3, v2: Vector3, t: number): Vector3 => v1.clone().lerp(v2, t);

const lerpScalar = (s1: number, s2: number, t: number): number => (1 - t) * s1 + t * s2;

const printTweenable = (x: Tweenable): string => {
  if (isNumber(x)) return x.toString();
  if (isVector3(x)) return x.toArray().toString();
  if (isEuler(x)) return x.toArray().toString();
  throw new Error('reconsider your ways');
};

/**
 * Tween between two numbers using some easing function
 */
export class Tween<T extends Tweenable> {
  private currentX: number = 0;

  private currentY: T;

  private numberOfUpdates: number = 0;

  private lerp: Function;

  private lp: string;

  /**
   * Creates a Tween
   * @param startY starting value
   * @param endY end value to be reached
   * @param maxX duration in seconds
   * @param easingFunction one of those
   * @param whenDone to be called once duration in reached
   */
  constructor(
    public readonly startY: T,
    public readonly endY: T,
    public readonly maxX: number,
    public readonly easingFunction: (n: number) => number,
    private readonly whenDone: () => void,
    public readonly label?: string,
  ) {
    this.lp = getLogPrefixForType('CLASS', `3D Tween [${label}]`);

    this.currentY = this.startY;

    let tweenType = 'unknown';

    if (isNumber(this.currentY)) {
      this.lerp = lerpScalar;
      tweenType = 'Scalar';
    } else if (isVector3(this.currentY)) {
      this.lerp = lerpVector;
      tweenType = 'Vector';
    } else if (isEuler(this.currentY)) {
      this.lerp = lerpEuler;
      tweenType = 'Euler';
    } else {
      throw new Error('now?');
    }

    console.debug(
      this.lp,
      `created ${tweenType} between ${printTweenable(this.startY)} and ${printTweenable(
        this.endY,
      )}`,
    );
  }

  public get isDone() {
    return this.currentX >= this.maxX;
  }

  private get normalizedX() {
    return this.currentX / this.maxX;
  }

  private updateCurrentX = (delta: number): boolean => {
    this.currentX += delta;

    if (this.currentX >= this.maxX) {
      console.debug(
        this.lp,
        `ENDS at ${this.currentX} X, value ${printTweenable(
          this.currentY,
        )} Y after ${this.numberOfUpdates.toString()} updates -> calling whenDone callback.`,
      );
      this.whenDone();
      return false;
    }
    return true;
  };

  public getNextValue = (delta: number): T => {
    if (!this.updateCurrentX(delta)) return this.endY;

    const easingRatio = this.easingFunction(this.normalizedX);

    this.currentY = this.lerp(this.startY, this.endY, easingRatio);

    this.numberOfUpdates += 1;
    return this.currentY;
  };
}

/**
 * Basic easing functions.
 */
export const Easing = {
  Linear: (x: number) => x,
  EaseInQuad: (x: number) => x ** 2,
  EaseOutQuad: (x: number) => 1 - (x - 1) ** 2,
  EaseInCubic: (x: number) => x ** 3,
  EaseOutCubic: (x: number) => 1 + (x - 1) ** 3,
  SineOut: (x: number) => Math.sin((x * Math.PI) / 2),
};
