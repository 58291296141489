import { DroneZoneCommandType, DroneZoneDeletedCommand } from '../droneZoneCommand.type';
import { ZoneStatusActions } from '../../../reducer/droneZonePage.actions';
import { ControlledZone } from '../../../../../../shared/map/model/controlled-zone.model';

export const createDroneZoneDeletedCommand: (zone: ControlledZone) => DroneZoneDeletedCommand = (
  zone,
) => ({
  type: DroneZoneCommandType.Deleted,
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => {
    dispatchZoneStatus({
      type: 'DELETE_DRONE_ZONE',
      payload: zone,
    });
  },
});
