import { OptionsObject, SnackbarKey, SnackbarMessage, VariantType } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import { IClientLevelStateFacility } from '../store/ClientLevelStore/IClientLevelStore';

/**
 * Interface for displayNotification function
 */
interface IDisplayNotification {
  variant: VariantType;
  text: string;
  linkText: string;
  url: string;
  target: string;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
}

/**
 * Display notification
 * @param param0 IDisplayNotification parameters
 */
export const displayNotification = ({
  variant,
  text,
  linkText = '',
  url = '',
  target = 'self',
  enqueueSnackbar,
}: IDisplayNotification) => {
  const link = (
    <span
      className="notification-link"
      role="link"
      tabIndex={0}
      onClick={() => window.open(url, target)}
    >
      {linkText}
    </span>
  );

  const message = (
    <>
      {text} {!isEmpty(url) ? link : ''}.
    </>
  );

  enqueueSnackbar(message, {
    variant,
    preventDuplicate: true,
  });
};

/**
 *
 * @param wsPayload web socket payload
 * @param facilityList List of facilities
 * @returns facility
 */
export const getFacilityFromNotification = (
  wsPayload: {
    systemId: string;
  },
  facilityList: IClientLevelStateFacility[],
) => {
  if (isEmpty(wsPayload) || isEmpty(facilityList) || !wsPayload.systemId) return undefined;

  const sysId = wsPayload.systemId;
  const facility = facilityList.find((f) => f.id.toString() === sysId.toString());

  return facility;
};
