import { useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';
import { QueryNames } from '../../../../ts-types/QueryNames';
import { getLocationMetadata } from './api/getImageMetadata';
import { BarcodeFrame } from './BarcodeFrame';
import { BarcodeLabel } from './BarcodeLabel';
import { getBarcodeListFromMetadataResponse } from './utils/getBarcodeListFromMetadataResponse';

export const BarcodeVisualization = ({
  systemId,
  resultId,
  imageURL,
}: {
  systemId: string;
  resultId: string;
  imageURL: string;
}) => {
  // Split imageURL on '/' and '?', and take the element that comes after "image" to get image GUID
  const urlParts = imageURL.split(/[/?]/);
  const imageGuid = urlParts[urlParts.indexOf('image') + 1];

  const { data: barcodes } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_LOCATION_METADATA, `${systemId}_${resultId}`],
    queryFn: () => getLocationMetadata(systemId, resultId),
    select: ({ data: resultMetadataResponse }) =>
      getBarcodeListFromMetadataResponse({ resultMetadataResponse, imageGuid }),
  });

  if (barcodes) {
    return (
      <>
        {barcodes.map((barcode) => (
          <Fragment key={crypto.randomUUID()}>
            <BarcodeFrame barcode={barcode} />
            <BarcodeLabel barcode={barcode} />
          </Fragment>
        ))}
      </>
    );
  }
  return null;
};
