import { DroneZones } from 'shared/map/model/drone-zones.model';
import { zoneCoordinatesToGeometry } from 'shared/map-container/utils/creation.util';
import { ControlledZoneAPI } from '../../../../../api/controlled-zone';

export const updateControlledZone = (
  droneZone: DroneZones,
  systemId: string,
  flightDomainId: string,
) =>
  ControlledZoneAPI.updateControlledZone(
    systemId,
    flightDomainId,
    droneZone.id,
    droneZone.name,
    droneZone.description,
    zoneCoordinatesToGeometry(droneZone.sizeAndPosition),
  );
