import { Typography } from '@mui/material';
import { valueToLocaleDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { useStyles } from './drone-zone-details-panel-option.styles';

export const DroneZoneDetailsPanelOption = ({
  name,
  minZ,
  maxZ,
  isSelected,
  onClick,
}: {
  name: string;
  minZ: number;
  maxZ: number;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const { classes, cx } = useStyles();

  return (
    <li className={cx(classes.wrapper, { [classes.wrapperSelected]: isSelected })}>
      <button
        className={classes.details}
        aria-label={`Show the details for the ${name} zone`}
        type="button"
        onClick={onClick}
      >
        <Typography component="span" className={classes.detailsName}>
          {`${name} (${valueToLocaleDecimalPlaces(minZ, 1)} to ${valueToLocaleDecimalPlaces(
            maxZ,
            1,
          )})`}
        </Typography>
      </button>
    </li>
  );
};
