import { WebSocketClient } from 'shared/web-socket/web-socket.model';
import { ImageWebsocketMessage } from './ImageWebsocketMessage.model';
import { imageSubscribers } from './subscribers';

export const subscribeToImageStatus = ({
  socket,
  onSubscribe,
}: {
  socket: WebSocketClient;
  onSubscribe: (data: ImageWebsocketMessage) => void;
}) => {
  socket.subscribe({
    onMessage(data: ImageWebsocketMessage): void {
      onSubscribe(data);
    },
    channel: imageSubscribers.imageStatus.channel,
    delegate: imageSubscribers.imageStatus.delegate,
  });
};
