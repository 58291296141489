import { makeStyles } from 'tss-react/mui';
import { SxProps, Theme } from '@mui/material';

import { Box } from 'components/common/Box';

const useStyles = makeStyles()((theme: Theme) => ({
  badge: {
    padding: theme.spacing(0.6, 1.5),
  },
  bigBadge: {
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3),
  },
}));

const Badge = ({
  bgcolor,
  className,
  color,
  bigBadge,
  children,
  sx,
}: {
  bgcolor: string;
  className?: string;
  color: string;
  bigBadge: boolean;
  children: JSX.Element;
  sx?: SxProps<Theme>;
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.badge, bigBadge && classes.bigBadge, className)}
      bgcolor={bgcolor}
      color={color}
      borderRadius="5px"
      display="inline"
      alignItems="center"
      flexDirection="column"
      sx={sx}
    >
      {children}
    </Box>
  );
};

export default Badge;
