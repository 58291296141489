import { IScheduleLockPutRequestST } from 'codegen/nav_simulation';
import {
  Configuration as EmergencyProceduresConfig,
  EmergencyProceduresApi,
} from '../../../../../../../../codegen/emergency_procedure';

interface SetScheduleLock {
  systemId: string;
  flightDomainId: string;
  scheduleLock: IScheduleLockPutRequestST;
  signal: AbortSignal;
}

const getEmergencyProcedureApiService = () => {
  const idToken = localStorage.getItem('idToken') ?? '';
  const config = new EmergencyProceduresConfig({ apiKey: idToken });
  const apiService = new EmergencyProceduresApi(config);
  return apiService;
};

export const setScheduleLock = ({ systemId, flightDomainId, scheduleLock }: SetScheduleLock) =>
  getEmergencyProcedureApiService().scheduleLock(systemId, flightDomainId, scheduleLock);
