import { LocalStore } from 'common/functions/storageFunctions';
import { Configuration, OpenFlyZonesApi } from 'codegen/open_fly_zone';

export const initOpenFlyZoneAPI = () => {
  const idToken = LocalStore.getIdToken();
  const config = new Configuration({ apiKey: idToken });
  const api = new OpenFlyZonesApi(config);

  return api;
};
