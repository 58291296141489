import React from 'react';
import { Box } from '../Box';

interface CardGridProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  columnCount?: number;
}

const CardGrid: React.FC<CardGridProps> = ({ children, columnCount = 1, ...props }) => (
  <div {...props}>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'minmax(0, 1fr)',
          sm: 'minmax(0, 1fr)',
          md: 'minmax(0, 1fr)',
          lg: `repeat(${columnCount}, minmax(0, 1fr))`,
        },
        gridAutoRows: 'min-content',
        gap: 2,
      }}
    >
      {children}
    </Box>
  </div>
);

export default CardGrid;
