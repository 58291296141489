import { DroneZoneCommandType, DroneZoneEditCommand } from '../droneZoneCommand.type';
import { ZoneStatusActions } from '../../../reducer/droneZonePage.actions';
import { ControlledZone } from '../../../../../../shared/map/model/controlled-zone.model';

export const createDroneZoneEditCommand: (zone: ControlledZone) => DroneZoneEditCommand = (
  zone,
) => ({
  type: DroneZoneCommandType.Edit,
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => {
    dispatchZoneStatus({
      type: 'EDIT_DRONE_ZONE',
      payload: zone,
    });
  },
});
