import IssuesStore from 'store/IssuesStore';
import { IRequestController } from 'hooks';
import { IOverwritingItem } from '../../AmendValues.model';

export const overwriteVerityValue = ({
  systemId,
  requestController,
  overwritingItem,
  location,
  version,
  callbackSuccess,
  callbackError,
}: {
  systemId: string;
  requestController: IRequestController;
  overwritingItem: IOverwritingItem;
  location: string;
  version: number | '-';
  callbackSuccess: () => void;
  callbackError: (e: any) => void;
}) => {
  requestController.doRequest({
    request: IssuesStore.userOverride,
    requestParams: [
      {
        systemId,
        data: overwritingItem,
        slot_label: location,
        version,
        isReview: false,
      },
    ],
    callbackSuccess: () => callbackSuccess(),
    callbackError: (e: any) => callbackError(e),
    messageSuccess: `Location ${location} was successfully modified`,
    messageErrorFallback: `Location ${location} could not be modified`,
  });
};
