import { DroneZoneCommandType, DroneZoneUpdateCommand } from '../droneZoneCommand.type';
import { ZoneStatusActions } from '../../../reducer/droneZonePage.actions';
import { ControlledZone } from '../../../../../../shared/map/model/controlled-zone.model';

export const createDroneZoneUpdateCommand: (zone: ControlledZone) => DroneZoneUpdateCommand = (
  zone: ControlledZone,
) => ({
  type: DroneZoneCommandType.Update,
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => {
    dispatchZoneStatus({
      type: 'UPDATE_DRONE_ZONE',
      payload: zone,
    });
  },
});
