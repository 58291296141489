import { getLogPrefixForType } from 'common/functions/logFunctions';
import { createSystemSpecificSuperSetGuestToken } from '../../../services/Superset/CreateSystemSpecificSuperSetGuestToken';

const logPrefix = getLogPrefixForType('FUNCTION', 'fetchSystemSpecificSuperSetGuestToken');
/**
 * Fetch system specific guest token
 * @returns superset guest token
 */

export const fetchSystemSpecificSuperSetGuestToken = async (systemId: string) => {
  try {
    const res = await createSystemSpecificSuperSetGuestToken({ systemId });

    return res.data.guest_token;
  } catch (error) {
    console.debug(logPrefix, `Fetching guest token errored with: ${error}`);
    return '';
  }
};
