import { Paper, Typography } from '@mui/material';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import Spinner from 'components/common/Spinner';
import { Box } from 'components/common/Box';
import { IVeritySlotStatusStateST } from 'codegen/location_information';
import { locationStyles } from '../styles';
import { ImageGridComponent } from './ImageGridComponent';
import { PictureCounter } from './PictureCounter';
import { getImageText } from './utils/getImageText';
import { useLocationImages } from '../../../../hooks/useLocationImages';

export const ImageGrid = ({
  systemId,
  verityState,
  locationData,
}: {
  verityState: '-' | IVeritySlotStatusStateST;
  locationData: ILocationData;
  systemId: string;
}) => {
  const { images, isLoadingImages, imageRequested, generateAndDownloadZip, isDownloadingImages } =
    useLocationImages(systemId, locationData, locationData.imageIds);
  const { classes } = locationStyles();

  return images.length ? (
    <div>
      {!isLoadingImages ? (
        <>
          <PictureCounter
            imageUrls={images}
            imagesDownloading={isDownloadingImages}
            handleDownloadImages={generateAndDownloadZip}
          />
          <ImageGridComponent
            imageUrls={images}
            resultId={locationData.rowData.slotStatus.verity_status.result_id}
            systemId={systemId}
          />
        </>
      ) : (
        <Box className={classes.spinnerLoadingImages}>
          <Spinner />
        </Box>
      )}
    </div>
  ) : (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.imageContainer}>
        <Typography
          className={classes.imageContainerText}
          color="textSecondary"
          align="center"
          variant="h6"
        >
          {getImageText(verityState, images.length, imageRequested)}
        </Typography>
      </div>
    </Paper>
  );
};
