import isEmpty from 'lodash/isEmpty';
import { getLogPrefixForType } from './functions/logFunctions';
import { isValidEmailFormat } from './isValidEmailFormat';

/**
 * Validate the password
 * @param value Password value
 * @returns Error text
 */
export const validatePassword = (value: string) => {
  // RegEx
  const number = /\d/;
  const lowerCase = /[a-z]/;
  const upperCase = /[A-Z]/;
  const specialCharacter = /.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/;

  let errorText = '';

  if (value && value.length < 10) {
    errorText = 'Minimum password length is 10 characters';
  } else if (value && !number.test(value)) {
    errorText = 'Password must contain at least one number';
  } else if (value && !lowerCase.test(value)) {
    errorText = 'Password must contain at least one lowercase letter';
  } else if (value && !upperCase.test(value)) {
    errorText = 'Password must contain at least one uppercase letter';
  } else if (value && !specialCharacter.test(value)) {
    errorText = 'Password must contain at least one special character';
  }

  return errorText;
};

/**
 * get an email error message, if any
 */
export const getInvalidEmailErrorMessage = (email: string) =>
  isValidEmailFormat(email) ? '' : 'Invalid email address';

/**
 * Verify whether the given email address is available
 * @param email email address
 * @param usersEmails list of email addresses already in use
 * @param editMode flag indicating whether the check is for an existing or new email
 * @returns nothing if successful, an error string otherwise
 */
export const isEmailAvailable = (email: string, usersEmails: any, editMode: boolean) => {
  const lp = getLogPrefixForType('FUNCTION', 'isEmailAvailable');
  let errorText = '';

  if (!email) {
    errorText = 'This field is required.';
    console.debug(lp, 'no email address.');
    return errorText;
  }
  // Check if it's valid email format
  if (email && !isValidEmailFormat(email)) {
    console.debug(lp, 'The email address is invalid', email);
    return 'Invalid email address';
  }

  // If it is a new user check that the email is not already used
  const user = usersEmails.find((user: any) => user.email === email);
  if (!editMode && email && user?.username) {
    errorText = `This email (${email}) is used by ${user?.username}`;
    console.debug(lp, errorText);
  }

  console.debug(lp, `The email address: ${email} is available`);
  return errorText;
};

// Validate phone number format
export const isPhoneValidFormat = (phoneNumber: string) => {
  // regex source: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d[\W\D\s]*?\d)(?: *x(\d+))?\s*$/g;

  let errorText = '';

  if (!phoneNumber) {
    errorText = 'This field is required.';
  } else if (phoneNumber && !phoneRegExp.test(phoneNumber)) {
    errorText = 'Invalid phone number format';
  }
  return errorText;
};

// Check if input element is empty or undefined
export const isInputEmpty = (value: any) => {
  if (isEmpty(value)) {
    return 'This field is required.';
  }
  return '';
};
