import { BottomLeftDimensions, Geometry } from '../geometry.model';

export const geometryToBottomLeftDimensions = (geom: Geometry): BottomLeftDimensions => ({
  w: geom.max.x - geom.min.x,
  l: geom.max.y - geom.min.y,
  h: geom.max.z - geom.min.z,
  minX: geom.min.x,
  minY: geom.min.y,
  minZ: geom.min.z,
  maxZ: geom.max.z,
});
