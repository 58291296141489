import React, { createContext, useReducer, useContext } from 'react';
import { FacilityMenuAction } from './facilityMenuActions';
import {
  initialFacilityMenuState,
  FacilityMenuReducer,
  IFacilityMenuState,
} from './facilityMenuReducer';

/**
 * Interface for the FacilityMenuStore context
 */
interface IFacilityMenuContext {
  /**
   * The current state of the FacilityMenuStore
   */
  stateFacilityMenu: IFacilityMenuState;
  /**
   * Dispatch function for the FacilityMenuStore
   * @param {FacilityMenuAction} action The action to dispatch
   */
  dispatchFacilityMenu?: React.Dispatch<FacilityMenuAction>;
}

/**
 * Initial(empty) context of FacilityMenuStore
 * Store state and dispatch function
 */
const initialContext: IFacilityMenuContext = {
  stateFacilityMenu: initialFacilityMenuState,
  dispatchFacilityMenu: undefined,
};

// store & hook to access it
const Store = createContext(initialContext);
export const useFacilityMenuStore = () => useContext(Store);

// state provider
export const FacilityMenuStoreProvider = ({ children }: any) => {
  const [stateFacilityMenu, dispatchFacilityMenu] = useReducer(
    FacilityMenuReducer,
    initialFacilityMenuState,
  );

  return (
    <Store.Provider
      value={{
        stateFacilityMenu,
        dispatchFacilityMenu,
      }}
    >
      {children}
    </Store.Provider>
  );
};
