import moment from 'moment/moment';
import { CommissioningTask } from '../../model/commissioningTask.model';
import { FilterDatesCommissioning } from '../../model/commissioningDateFilter.model';

export const filterCommissioningTasksByDates: (
  commissioningTasks: CommissioningTask[],
  dates: FilterDatesCommissioning,
) => CommissioningTask[] = (commissioningTasks, dates) =>
  !dates.until && !dates.from
    ? commissioningTasks
    : commissioningTasks.filter((task) =>
        dates.until === ''
          ? task.createdAt?.isAfter(dates.from)
          : task.createdAt?.isBetween(moment(dates.from), moment(dates.until)),
      );
