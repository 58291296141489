import TableCell from '@mui/material/TableCell';
import isEmpty from 'lodash/isEmpty';
import { Box } from 'components/common/Box';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
type EnhancedTableCellButtonProps = EnhancedTableCellProp<any> & {
  classes: { faded: string };
};

export const EnhancedTableCellButton = (props: EnhancedTableCellButtonProps) => {
  const { cell, row, classes, onClick } = props;
  const hasNoImages = isEmpty(row?.actions?.data?.imageIds);

  if (cell === undefined) {
    console.debug(
      'EnhancedTableCellButton',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} onClick={onClick}>
      <Box className={`${hasNoImages && classes.faded}`}>{row[cell.id]}</Box>
    </TableCell>
  );
};
