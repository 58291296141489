import { makeStyles } from 'tss-react/mui';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';

export const useStyles = makeStyles()(() => ({
  legendIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: mapStyle.countBadge.active.textColor.primary,
    backgroundColor: mapStyle.countBadge.active.backgroundColor.primary,
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    userSelect: 'none',
  },
  legendIconContent: {
    flex: '0 0 auto',
    display: 'block',
    fontSize: '9px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1,
  },
}));
