import { getLogPrefixForType } from 'common/functions/logFunctions';
import { ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum } from 'codegen/warehouse_status';
import { initWarehouseStatusService } from './service';

/**
 * Send a location info email
 * @param systemId System ID
 * @param location Location
 * @param slotStatusVersion Version of the Slot Status
 * @param wmsVersion WMS Version
 * @param parentPage parent page
 * @param reportId ID of the report
 * @param message message
 * @param linkToLocation Link in UDB to the location being reported,
 * @returns Promise which will resolve is successfully sent
 */

export const sendLocationInfoEmail = (
  systemId: string,
  location: string,
  slotStatusVersion: number,
  wmsVersion: number,
  parentPage: string,
  reportId: string,
  feedbackCategories: ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum[],
  message: string,
  linkToLocation: string,
) => {
  const params = {
    location,
    verity_slot_status_version: slotStatusVersion,
    wms_slot_status_version: wmsVersion,
    context: parentPage,
    report_id: reportId,
    feedback_categories: feedbackCategories,
    message,
    link_to_location: linkToLocation,
  };

  const lp = getLogPrefixForType('FUNCTION', 'sendLocationInfoEmail');

  // NOTE: UD-4524 let's put a warning in case context isn't defined
  if (!params.context) {
    console.warn(lp, 'context is not defined, params => ', params);
  }

  return initWarehouseStatusService().sendLocationsDataFeedback(systemId, params);
};
