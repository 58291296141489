import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { findOverlapForZones } from 'shared/map/utils/zone-overlap/find-overlap-for-zones';
import { ControlledZoneMesh } from 'shared/map/features/controlled-zone-mesh/controlled-zone-mesh';
import { MapOptions } from 'shared/map-container/reducer/MapOptionsState';

export const meshesFromControlledZones = ({
  controlledZones,
  mapOptions,
  onDroneZoneClick,
  onDroneZoneDragStart,
  onDroneZoneDragEnd,
}: {
  controlledZones: ControlledZones;
  mapOptions: MapOptions;
  onDroneZoneClick: (zone: ControlledZone) => void;
  onDroneZoneDragStart: () => void;
  onDroneZoneDragEnd: (zone: ControlledZone) => void;
}) => {
  const overlappingZones = Object.entries(findOverlapForZones(controlledZones))
    .map(([_id, overlap]) => (overlap.zones.length > 1 ? overlap.zones : []))
    .flat();

  const isZoneOverlapping = (droneZone: ControlledZone) =>
    overlappingZones.find(
      (overlappingZone: ControlledZone) => overlappingZone.id === droneZone.id,
    ) !== undefined;

  return Object.values(controlledZones).map((zone: ControlledZone) => (
    <ControlledZoneMesh
      key={zone.id}
      isLabelVisible={isZoneOverlapping(zone) ? zone.isSelected || zone.isEditing : true}
      droneZone={zone}
      options={mapOptions}
      onClick={onDroneZoneClick}
      onDrag={onDroneZoneDragStart}
      onDragStart={onDroneZoneDragStart}
      onDragEnd={onDroneZoneDragEnd}
    />
  ));
};
