import { OpenFlyZone, OpenFlyZones } from 'shared/map/model/open-fly-zone.model';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { NoFlyZone, NoFlyZones } from 'shared/map/model/no-fly-zone.model';

export const zoneElementFromSelectedZone = (zones: NoFlyZones | OpenFlyZones | ControlledZones) => {
  const focusedZone = Object.values(zones).find(
    (zone: NoFlyZone | OpenFlyZone | ControlledZone) => zone.isSelected,
  );
  const focusedZoneElement = focusedZone ? document.getElementById(focusedZone.id) : undefined;

  return focusedZoneElement;
};
