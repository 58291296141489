import { styled } from '@mui/material/styles';
import { Box } from '../../Box';
import { drawerWidth } from './Drawer';

export const ToggleWrapper = styled(Box)(({ theme, open }) => ({
  zIndex: 2000,
  bottom: '35px',
  left: 0,
  padding: '10px 12px',
  width: open ? drawerWidth - 2 : 40,
  background: '#fff',
  position: 'fixed',
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  transition: theme.transitions.create(['left', 'width', 'top'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
