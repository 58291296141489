export const extractSnapshotFileName = (snapshotId: string) => {
  // take the part after the last /
  const lastSlashIndex = snapshotId.lastIndexOf('/');
  const fullFileName = snapshotId.substring(lastSlashIndex + 1);
  // remove the extension and the file stamp at the beginning
  const firstDashIndex = fullFileName.indexOf('-');
  const lastDotIndex = fullFileName.lastIndexOf('.');
  const fileName = fullFileName.substring(
    firstDashIndex + 1,
    lastDotIndex > 0 ? lastDotIndex : fullFileName.length,
  );
  return fileName;
};
