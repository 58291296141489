import { initialGroundControlModalsState } from './groundControlModalInitialState';
import {
  GroundControlModalActions,
  GroundControlModalsActionNames,
} from './groundControlModalsActions';
import { IGroundControlModalsState } from './IGroundControlModalsStore';

export const modalsReducer = (
  state: IGroundControlModalsState,
  action: GroundControlModalActions,
): IGroundControlModalsState => {
  switch (action.type) {
    case GroundControlModalsActionNames.RESET:
      return {
        ...initialGroundControlModalsState,
      };
    case GroundControlModalsActionNames.LOCK_MODAL:
      return {
        ...state,
        flightDomainId: action.payload || '',
        lockModalOpened: Boolean(action.payload),
      };
    case GroundControlModalsActionNames.UNLOCK_MODAL:
      return {
        ...state,
        flightDomainId: action.payload || '',
        unlockModalOpened: Boolean(action.payload),
      };
    case GroundControlModalsActionNames.EMERGENCY_LANDING_MODAL:
      return {
        ...state,
        flightDomainId: action.payload || '',
        emergencyModalOpened: Boolean(action.payload),
      };

    default:
      return state;
  }
};
