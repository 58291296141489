import { Button, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { SCHEDULE_LOCK_STATES } from '../../ScheduleLock';

interface IScheduleLockEmptyStateProps {
  onClick: (param: string) => void;
}

/**
 * Schedule lock empty state
 * @param param0 IScheduleLockEmptyStateProps params
 * @returns component
 */
export const ScheduleLockEmptyState = ({ onClick }: IScheduleLockEmptyStateProps) => (
  <>
    <div data-testid="c-schedule-lock-card-content">
      <Typography color="textSecondary" align="center">
        There is no schedule lock set. To set one click on the button below.
      </Typography>
    </div>

    <div style={{ marginTop: '24px' }}>
      <Button
        startIcon={<Add />}
        data-testid="c-add-schedule-lock-button"
        variant="outlined"
        color="primary"
        size="medium"
        fullWidth
        onClick={() => onClick(SCHEDULE_LOCK_STATES.creation)}
      >
        SET SCHEDULE LOCK
      </Button>
    </div>
  </>
);
