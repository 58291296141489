import { useRef } from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { IBox3DST, ISpaceReservationInformationST } from 'codegen/flight_domain';

/**
 * Turns geometry type to position and size
 * @param geo of type IBox3DST
 * @returns position and size
 */
const geometryToPosAndSize = (geo: IBox3DST) => {
  const [dx, dy] = [geo.max.x - geo.min.x, geo.max.y - geo.min.y];
  const [x, y] = [geo.min.x + dx / 2, geo.min.y + dy / 2];
  const [width, length] = [Math.abs(dx), Math.abs(dy)];

  return { pos: new Vector3(x, y, 1), size: { width, length, height: 1 } };
};

type ReservedSpace3dProps = {
  reservedSpaceState: ISpaceReservationInformationST;
};
/**
 * Component that will show on map
 * @param props ReservedSpace3dProps
 * @returns JSX.Element
 */
export const ReservedSpace3d = ({ reservedSpaceState }: ReservedSpace3dProps) => {
  const ref = useRef<THREE.Mesh>(null!);

  const { pos, size } = geometryToPosAndSize(reservedSpaceState.geometry);
  const reservedSpaceMaterial = new THREE.MeshBasicMaterial({
    color: 'yellow',
    transparent: true,
    opacity: 0.5,
    depthTest: false,
  });
  return (
    <mesh ref={ref} position={pos} renderOrder={5} material={reservedSpaceMaterial}>
      <boxGeometry args={[size.width, size.length, 1]} />
    </mesh>
  );
};
