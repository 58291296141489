import { ControlledZonesDTO } from 'shared/map/model/controlled-zone.model';
import { WebSocketClient } from 'shared/web-socket/web-socket.model';
import { IGetControlledZonesResponseST } from 'codegen/controlled_zone';
import { subscribers } from './subscribers';

export const subscribeToControlledZoneSocket = (
  flightDomainId: string,
  onMessage: (controlledZonesDTO: ControlledZonesDTO) => void,
  socket: WebSocketClient,
) => {
  socket.subscribe({
    onMessage(data: IGetControlledZonesResponseST): void {
      if (flightDomainId === data.flight_domain_id) {
        onMessage(data.controlled_zones);
      }
    },
    channel: subscribers.controlledZone.channel,
    delegate: subscribers.controlledZone.delegate,
  });
};
