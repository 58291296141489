/* tslint:disable */
/* eslint-disable */
/**
 * Emergency Procedures
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IDeleteScheduleLock409ResponseAllOfErrorsInnerST
 */
export interface IDeleteScheduleLock409ResponseAllOfErrorsInnerST {
  /**
   *
   * @type {string}
   * @memberof IDeleteScheduleLock409ResponseAllOfErrorsInnerST
   */
  code?: IDeleteScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum;
}

export const IDeleteScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum = {
  LockNotScheduled: 'LOCK_NOT_SCHEDULED',
} as const;

export type IDeleteScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum =
  typeof IDeleteScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum[keyof typeof IDeleteScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum];

/**
 *
 * @export
 * @interface IDeleteScheduleLock409ResponseAllOfST
 */
export interface IDeleteScheduleLock409ResponseAllOfST {
  /**
   *
   * @type {Array<IDeleteScheduleLock409ResponseAllOfErrorsInnerST>}
   * @memberof IDeleteScheduleLock409ResponseAllOfST
   */
  errors?: Array<IDeleteScheduleLock409ResponseAllOfErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IDeleteScheduleLock409ResponseST
 */
export interface IDeleteScheduleLock409ResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IDeleteScheduleLock409ResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IDeleteScheduleLock409ResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IDeleteScheduleLock409ResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IDeleteScheduleLock409ResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IDeleteScheduleLock409ResponseST
   */
  report_url: string | null;
  /**
   *
   * @type {Array<IDeleteScheduleLock409ResponseAllOfErrorsInnerST>}
   * @memberof IDeleteScheduleLock409ResponseST
   */
  errors: Array<IDeleteScheduleLock409ResponseAllOfErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IGetScheduleLockResponseST
 */
export interface IGetScheduleLockResponseST {
  /**
   * Unique identifier of the flight domain
   * @type {string}
   * @memberof IGetScheduleLockResponseST
   */
  flight_domain_id: string;
  /**
   *
   * @type {IGetScheduleLockResponseScheduleLockStatusST}
   * @memberof IGetScheduleLockResponseST
   */
  schedule_lock_status: IGetScheduleLockResponseScheduleLockStatusST | null;
}
/**
 *
 * @export
 * @interface IGetScheduleLockResponseScheduleLockStatusST
 */
export interface IGetScheduleLockResponseScheduleLockStatusST {
  /**
   * Time of the scheduled lock as string.
   * @type {string}
   * @memberof IGetScheduleLockResponseScheduleLockStatusST
   */
  lock_at: string;
}
/**
 *
 * @export
 * @interface IScheduleLock409ResponseAllOfErrorsInnerST
 */
export interface IScheduleLock409ResponseAllOfErrorsInnerST {
  /**
   *
   * @type {string}
   * @memberof IScheduleLock409ResponseAllOfErrorsInnerST
   */
  code?: IScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum;
}

export const IScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum = {
  AlreadyScheduled: 'ALREADY_SCHEDULED',
  LockIsInThePast: 'LOCK_IS_IN_THE_PAST',
  OperatingHoursConflict: 'OPERATING_HOURS_CONFLICT',
} as const;

export type IScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum =
  typeof IScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum[keyof typeof IScheduleLock409ResponseAllOfErrorsInnerSTCodeEnum];

/**
 *
 * @export
 * @interface IScheduleLock409ResponseAllOfST
 */
export interface IScheduleLock409ResponseAllOfST {
  /**
   *
   * @type {Array<IScheduleLock409ResponseAllOfErrorsInnerST>}
   * @memberof IScheduleLock409ResponseAllOfST
   */
  errors?: Array<IScheduleLock409ResponseAllOfErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IScheduleLock409ResponseST
 */
export interface IScheduleLock409ResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IScheduleLock409ResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IScheduleLock409ResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IScheduleLock409ResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IScheduleLock409ResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IScheduleLock409ResponseST
   */
  report_url: string | null;
  /**
   *
   * @type {Array<IScheduleLock409ResponseAllOfErrorsInnerST>}
   * @memberof IScheduleLock409ResponseST
   */
  errors: Array<IScheduleLock409ResponseAllOfErrorsInnerST>;
}
/**
 *
 * @export
 * @interface IScheduleLock500ResponseAllOfST
 */
export interface IScheduleLock500ResponseAllOfST {
  /**
   *
   * @type {object}
   * @memberof IScheduleLock500ResponseAllOfST
   */
  errors?: object;
}
/**
 *
 * @export
 * @interface IScheduleLock500ResponseST
 */
export interface IScheduleLock500ResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IScheduleLock500ResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IScheduleLock500ResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IScheduleLock500ResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IScheduleLock500ResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IScheduleLock500ResponseST
   */
  report_url: string | null;
  /**
   *
   * @type {object}
   * @memberof IScheduleLock500ResponseST
   */
  errors: object;
}
/**
 *
 * @export
 * @interface IScheduleLockPutRequestST
 */
export interface IScheduleLockPutRequestST {
  /**
   * Time of the scheduled lock as string.
   * @type {string}
   * @memberof IScheduleLockPutRequestST
   */
  lock_at: string;
}
/**
 *
 * @export
 * @interface IUnlockPutRequestST
 */
export interface IUnlockPutRequestST {
  /**
   * Allow for drones to be unlocked at full speed in facilities using co-existence.
   * @type {boolean}
   * @memberof IUnlockPutRequestST
   */
  allow_normal_flight_in_controlled_zone: boolean;
}

/**
 * EmergencyProceduresApi - axios parameter creator
 * @export
 */
export const EmergencyProceduresApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteScheduleLock: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('deleteScheduleLock', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('deleteScheduleLock', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/emergency-procedure/schedule-lock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
     * @summary Sends a request to safety land all the drones of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyLanding: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('emergencyLanding', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('emergencyLanding', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/emergency-procedure/emergency-land`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called to get schedule lock information on a specific flight domain.
     * @summary Get scheduled lock status.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScheduleLock: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getScheduleLock', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getScheduleLock', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/emergency-procedure/schedule-lock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to lock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockFlightDomain: async (
      systemIdStr: string,
      flightDomainId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('lockFlightDomain', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('lockFlightDomain', 'flightDomainId', flightDomainId);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/emergency-procedure/lock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduleLock: async (
      systemIdStr: string,
      flightDomainId: string,
      iScheduleLockPutRequestST: IScheduleLockPutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('scheduleLock', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('scheduleLock', 'flightDomainId', flightDomainId);
      // verify required parameter 'iScheduleLockPutRequestST' is not null or undefined
      assertParamExists('scheduleLock', 'iScheduleLockPutRequestST', iScheduleLockPutRequestST);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/emergency-procedure/schedule-lock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iScheduleLockPutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to unlock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IUnlockPutRequestST} iUnlockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockFlightDomain: async (
      systemIdStr: string,
      flightDomainId: string,
      iUnlockPutRequestST: IUnlockPutRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('unlockFlightDomain', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('unlockFlightDomain', 'flightDomainId', flightDomainId);
      // verify required parameter 'iUnlockPutRequestST' is not null or undefined
      assertParamExists('unlockFlightDomain', 'iUnlockPutRequestST', iUnlockPutRequestST);
      const localVarPath =
        `/{system_id_str}/flight-domain/{flight_domain_id}/emergency-procedure/unlock`
          .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
          .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUnlockPutRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmergencyProceduresApi - functional programming interface
 * @export
 */
export const EmergencyProceduresApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmergencyProceduresApiAxiosParamCreator(configuration);
  return {
    /**
     * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduleLock(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
     * @summary Sends a request to safety land all the drones of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emergencyLanding(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.emergencyLanding(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called to get schedule lock information on a specific flight domain.
     * @summary Get scheduled lock status.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetScheduleLockResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleLock(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to lock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lockFlightDomain(
      systemIdStr: string,
      flightDomainId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lockFlightDomain(
        systemIdStr,
        flightDomainId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async scheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      iScheduleLockPutRequestST: IScheduleLockPutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleLock(
        systemIdStr,
        flightDomainId,
        iScheduleLockPutRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to unlock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IUnlockPutRequestST} iUnlockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlockFlightDomain(
      systemIdStr: string,
      flightDomainId: string,
      iUnlockPutRequestST: IUnlockPutRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlockFlightDomain(
        systemIdStr,
        flightDomainId,
        iUnlockPutRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmergencyProceduresApi - factory interface
 * @export
 */
export const EmergencyProceduresApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EmergencyProceduresApiFp(configuration);
  return {
    /**
     * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteScheduleLock(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
     * @summary Sends a request to safety land all the drones of the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emergencyLanding(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .emergencyLanding(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called to get schedule lock information on a specific flight domain.
     * @summary Get scheduled lock status.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<IGetScheduleLockResponseST> {
      return localVarFp
        .getScheduleLock(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to lock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockFlightDomain(
      systemIdStr: string,
      flightDomainId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .lockFlightDomain(systemIdStr, flightDomainId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
     * @summary Sends a request to schedule a lock of the flight domain in the future.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    scheduleLock(
      systemIdStr: string,
      flightDomainId: string,
      iScheduleLockPutRequestST: IScheduleLockPutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .scheduleLock(systemIdStr, flightDomainId, iScheduleLockPutRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
     * @summary Sends a request to unlock the flight domain.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {IUnlockPutRequestST} iUnlockPutRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockFlightDomain(
      systemIdStr: string,
      flightDomainId: string,
      iUnlockPutRequestST: IUnlockPutRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .unlockFlightDomain(systemIdStr, flightDomainId, iUnlockPutRequestST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmergencyProceduresApi - object-oriented interface
 * @export
 * @class EmergencyProceduresApi
 * @extends {BaseAPI}
 */
export class EmergencyProceduresApi extends BaseAPI {
  /**
   * This should be called when the user confirms deleting the scheduled lock for a flight domain. As a response, the scheduled lock should be cleared.
   * @summary Sends a request to schedule a lock of the flight domain in the future.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public deleteScheduleLock(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .deleteScheduleLock(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms the safety landing for a flight domain. As a response, the server must instruct the drones to land in place. The most recent lock status change time and user should also be updated.
   * @summary Sends a request to safety land all the drones of the flight domain.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public emergencyLanding(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .emergencyLanding(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called to get schedule lock information on a specific flight domain.
   * @summary Get scheduled lock status.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public getScheduleLock(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .getScheduleLock(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms the locking of a flight domain. As a response, the server must instruct the drones to return to base. The most recent lock status change time and user will also be updated.
   * @summary Sends a request to lock the flight domain.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public lockFlightDomain(
    systemIdStr: string,
    flightDomainId: string,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .lockFlightDomain(systemIdStr, flightDomainId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms creating a schedule lock for a flight domain. As a response, the server must be locked when the timeline is reached. The flight domain status should also be updated.
   * @summary Sends a request to schedule a lock of the flight domain in the future.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IScheduleLockPutRequestST} iScheduleLockPutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public scheduleLock(
    systemIdStr: string,
    flightDomainId: string,
    iScheduleLockPutRequestST: IScheduleLockPutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .scheduleLock(systemIdStr, flightDomainId, iScheduleLockPutRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This should be called when the user confirms the unlocking of a flight domain. As a response, the server must allow the drones to take off again. The most recent lock status change time and user will also be updated.
   * @summary Sends a request to unlock the flight domain.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {IUnlockPutRequestST} iUnlockPutRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmergencyProceduresApi
   */
  public unlockFlightDomain(
    systemIdStr: string,
    flightDomainId: string,
    iUnlockPutRequestST: IUnlockPutRequestST,
    options?: AxiosRequestConfig,
  ) {
    return EmergencyProceduresApiFp(this.configuration)
      .unlockFlightDomain(systemIdStr, flightDomainId, iUnlockPutRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
