import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../types';

export const getBackendOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: any) => void,
): SettingsItem[] => [
  {
    testId: 'c-task-request-results',
    type: 'switch',
    label: 'Task requests/results in CSFA mode',
    description:
      'If set to true, User Dashboard will stop communicating task result and request with control station and will switch to cloud services.',
    value: facilitySettings.enable_csfa_task_request_result,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_csfa_task_request_result', e.target.checked),
  },
  {
    testId: 'c-connect-navigation-backend',
    type: 'switch',
    label: 'Connect to Navigation Backend',
    description:
      'If ebabled, the User Dashboard connects with the navigation backend. If disabled, the User Dashboard connects to the simulator.',
    value: facilitySettings.connect_to_navigation_backend,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('connect_to_navigation_backend', e.target.checked),
  },
  {
    testId: 'c-connect-data-backend',
    type: 'switch',
    label: 'Connect to Data Backend',
    description:
      'If ebabled, the User Dashboard connects with the data backend. If disabled, the User Dashboard connects to the simulator.',
    value: facilitySettings.connect_to_data_backend,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('connect_to_data_backend', e.target.checked),
  },
  {
    testId: 'c-diagnostic-view-request',
    type: 'switch',
    label: 'Enable Diagnostic View',
    description:
      'If set to true, User Dashboard will request and display the diagnostic view images for verity users.',
    value: facilitySettings.enable_diagnostic_view_logic,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_diagnostic_view_logic', e.target.checked),
  },
  {
    testId: 'c-enable-coexistence-wms-integration',
    type: 'switch',
    label: 'Enable Coexistence WMS integration',
    description:
      'If enabled, the drone zones can be managed using the WMS integration in addition to manually',
    value: facilitySettings.enable_coexistence_wms_integration,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('enable_coexistence_wms_integration', e.target.checked),
  },
];
