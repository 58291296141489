import { createContext, useCallback, useReducer, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';

// services
import { TokenManager } from 'common/tokenManager';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { isSSOUser } from 'features/permissions/isSSOUser';
import authServices from '../../services/AuthServices';

// initial states, reducer and actions
import { UserLevelReducer } from './userLevelStoreReducer';
import { UserLevelActionNames } from './userLevelActions';
import { initialUserLevelState, initialUserStateContext } from './userLevelInitialState';

// helper, functions and variables

// custom hooks
import { useRequestController } from '../../hooks';

const tokenManager = TokenManager.getInstance();

export const Context = createContext(initialUserStateContext);

const logPrefix = getLogPrefixForType('STORE', 'AuthStore');

export const UserLevelStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [stateUserLevel, dispatchUserLevelStore] = useReducer(
    UserLevelReducer,
    initialUserLevelState,
  );

  const { requestController } = useRequestController(logPrefix);

  const accessToken = tokenManager.getAccessToken();

  /**
   * Request for getting user data and populating store
   */
  const getUserData = useCallback(
    () =>
      accessToken &&
      requestController.doRequest({
        request: authServices.getUser,
        requestParams: [accessToken],
        messageErrorFallback: 'User data can not be fetched.',
        callbackSuccess: async ({ data }) => {
          const usernameHashed = tokenManager.hashedUsernameFromAccessToken();

          // User has MFA active
          const isMfaAuth = !isEmpty(data.user_mfa_setting_list) || isSSOUser();
          const payload = {
            username: data.user_attributes.email,
            usernameHashed,
            isMfaAuth,
            isAuth: Boolean(accessToken),
          };

          dispatchUserLevelStore({
            type: UserLevelActionNames.SET_USER_DATA,
            payload,
          });

          console.debug(logPrefix, 'User data and profile successfully downloaded', payload);

          tokenManager.setUserAttributes(data.user_attributes);
        },
      }),
    [accessToken, requestController],
  );

  /**
   * Flag that indicates that userLevelStore is populated
   *
   * NOTE: in order to speed-up the login process, the verification on whether the web-socket
   * is authorized has been moved at ground control level store (see UD-3267).
   */
  const isDataReady = useCallback(() => stateUserLevel.isDataLoaded, [stateUserLevel.isDataLoaded]);

  return (
    <Context.Provider
      value={{
        stateUserLevel,
        dispatchUserLevelStore,
        getUserData,
        isDataReady,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const useUserLevelStore = () => useContext(Context);
