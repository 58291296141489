import TableCell from '@mui/material/TableCell';
import { handleActionClick } from 'common/Actions/actionHandlers';
import ActionsMenu from 'components/common/ActionsMenu';
import { RowProp, HeadCellProp } from '../../../types/cell';
import { useFacilityModalsStore } from '../../../../../store/Modals';

type EnhancedTableCellActionButtonProps = {
  row: RowProp<any>;
  cell: HeadCellProp;
  getAmendingData: any;
  refreshData: any;
};

export const EnhancedTableCellActionButton = (props: EnhancedTableCellActionButtonProps) => {
  const { cell, row, getAmendingData, refreshData } = props;
  const { dispatchFacilityModals } = useFacilityModalsStore();

  let options: any[] = [];

  row[cell.id] &&
    row[cell.id].actions.map((action: any) => {
      options = [
        ...options,
        {
          label: action.label,
          disabled: action.disabled,
          clickHandler: () => {
            const id = row.requestId || row.issueId || row.id || row.username;

            action.onClick
              ? action.onClick()
              : handleActionClick(
                  getAmendingData(row),
                  action.label,
                  dispatchFacilityModals,
                  refreshData,
                  id,
                );
          },
        },
      ];

      return action;
    });

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} key={`cell-${row.id}-${cell.id}`}>
      {row[cell.id] && <ActionsMenu options={options} />}
    </TableCell>
  );
};
