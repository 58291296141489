import { IBarcodeST, IResultMetadataResponseST } from '../../../../../codegen/location_information';

export const getBarcodeListFromMetadataResponse = ({
  resultMetadataResponse,
  imageGuid,
}: {
  resultMetadataResponse: IResultMetadataResponseST;
  imageGuid: string;
}): IBarcodeST[] | null => {
  // Find which activity group has the image with the given GUID
  const activityGroupId = resultMetadataResponse.activity_group_ids.find(
    (agid) =>
      Object.keys(
        resultMetadataResponse.activity_group_id_to_input_data[agid].act_id_to_udb_image_guid,
      ).find(
        (key) =>
          resultMetadataResponse.activity_group_id_to_input_data[agid].act_id_to_udb_image_guid[
            key
          ] === imageGuid,
      ) !== undefined,
  );
  if (activityGroupId === undefined) {
    // Image GUID not found in any activity group, we cannot continue
    return null;
  }
  const agid2imgid =
    resultMetadataResponse.activity_group_id_to_input_data[activityGroupId]
      .act_id_to_udb_image_guid;
  if (agid2imgid === undefined) {
    // Hitting this would mean that `data.activity_group_id_to_input_data`
    // has no key/property `data.activity_group_ids[activityGroupId]`, which should never happen
    return null;
  }

  const agid = Object.keys(agid2imgid).find((key) => agid2imgid[key] === imageGuid);
  if (agid === undefined) {
    // No ActivityGroupID matching the current image id, so we cannot continue. This normally happens with
    // CIG DiagnosticView images
    return null;
  }

  // Return both localized, mismatched, and recognized barcodes, in that order, so recognized gets
  // drawn last (on top), as these are probably the most relevant, and localized gets drawn first (at the bottom,
  // occluded by everything else, as those are borderline useless).
  const barcodeInfo =
    resultMetadataResponse.activity_group_id_to_input_data[activityGroupId]
      .act_group_barcode_diagnostic.activityToBarcodeInfo[agid];
  const allBarcodes = [
    // TODO: worry about displaying localizedBarcodes and mismatchedBarcodes later
    //  (they are not very useful to the user, and they can be confusing, so we probably want to ability to
    //  disable/enable them separately, if we want them at all)
    //  Uncomment the next line(s) to show localized and/or mismatched:
    // ...barcodeInfo.localizedBarcodes,
    // ...barcodeInfo.mismatchedBarcodes,
    ...barcodeInfo.recognizedBarcodes,
  ];
  return allBarcodes;
};
