import { WebSocketClient } from 'shared/web-socket/web-socket.model';
import { NoFlyZonesDTO } from 'shared/map/model/no-fly-zone.model';
import { INoFlyZonesResponseST } from 'codegen/no_fly_zone';
import { subscribers } from './droneZonesSubscribers';

export const subscribeToNoFlyZoneSocket = (
  flightDomainId: string,
  addNoFlyZones: (noFlyZonesDTO: NoFlyZonesDTO) => void,
  socket: WebSocketClient,
) => {
  socket.subscribe({
    onMessage(data: INoFlyZonesResponseST): void {
      if (flightDomainId === data.flight_domain_id) {
        addNoFlyZones(data.no_fly_zones);
      }
    },
    channel: subscribers.noFlyZone.channel,
    delegate: subscribers.noFlyZone.delegate,
  });
};
