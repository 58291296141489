/* tslint:disable */
/* eslint-disable */
/**
 * Flight Domain Tasks
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
}
/**
 *
 * @export
 * @interface IFilterTasksResponseST
 */
export interface IFilterTasksResponseST {
  /**
   *
   * @type {Array<IGetTaskResponseST>}
   * @memberof IFilterTasksResponseST
   */
  items?: Array<IGetTaskResponseST>;
  /**
   *
   * @type {string}
   * @memberof IFilterTasksResponseST
   */
  page_token?: string | null;
}
/**
 *
 * @export
 * @interface IGetTaskResponseST
 */
export interface IGetTaskResponseST {
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  task_id: string;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  state: IGetTaskResponseSTStateEnum;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  flight_domain_id: string;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  updated_at: string | null;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  started_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  created_at: string | null;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  finished_at?: string | null;
  /**
   *
   * @type {number}
   * @memberof IGetTaskResponseST
   */
  nr_total_locations: number;
  /**
   *
   * @type {number}
   * @memberof IGetTaskResponseST
   */
  nr_finished_locations: number;
  /**
   *
   * @type {string}
   * @memberof IGetTaskResponseST
   */
  type: IGetTaskResponseSTTypeEnum;
}

export const IGetTaskResponseSTStateEnum = {
  Created: 'CREATED',
  Ongoing: 'ONGOING',
  Completed: 'COMPLETED',
  Aborted: 'ABORTED',
} as const;

export type IGetTaskResponseSTStateEnum =
  typeof IGetTaskResponseSTStateEnum[keyof typeof IGetTaskResponseSTStateEnum];
export const IGetTaskResponseSTTypeEnum = {
  Inventory: 'INVENTORY',
  Raw: 'RAW',
} as const;

export type IGetTaskResponseSTTypeEnum =
  typeof IGetTaskResponseSTTypeEnum[keyof typeof IGetTaskResponseSTTypeEnum];

/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Filter tasks.
     * @summary Filter tasks.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} [type]
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {string} [state]
     * @param {string} [pageToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRawCommissioningTasks: async (
      systemIdStr: string,
      flightDomainId: string,
      type?: string,
      from?: string,
      until?: string,
      state?: string,
      pageToken?: string,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getRawCommissioningTasks', 'systemIdStr', systemIdStr);
      // verify required parameter 'flightDomainId' is not null or undefined
      assertParamExists('getRawCommissioningTasks', 'flightDomainId', flightDomainId);
      const localVarPath = `/{system_id_str}/flight-domain/{flight_domain_id}/task`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'flight_domain_id'}}`, encodeURIComponent(String(flightDomainId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (until !== undefined) {
        localVarQueryParameter['until'] =
          (until as any) instanceof Date ? (until as any).toISOString() : until;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['page_token'] = pageToken;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get details about a scheduled task for a specific flight domain.
     * @summary Get task details by task id
     * @param {string} systemIdStr
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskById: async (
      systemIdStr: string,
      taskId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getTaskById', 'systemIdStr', systemIdStr);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getTaskById', 'taskId', taskId);
      const localVarPath = `/{system_id_str}/task/{task_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'task_id'}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Abort task by task id
     * @summary Abort task by task id
     * @param {string} systemIdStr
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemIdStrTaskTaskIdAbortPut: async (
      systemIdStr: string,
      taskId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('systemIdStrTaskTaskIdAbortPut', 'systemIdStr', systemIdStr);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('systemIdStrTaskTaskIdAbortPut', 'taskId', taskId);
      const localVarPath = `/{system_id_str}/task/{task_id}/abort`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'task_id'}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration);
  return {
    /**
     * Filter tasks.
     * @summary Filter tasks.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} [type]
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {string} [state]
     * @param {string} [pageToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRawCommissioningTasks(
      systemIdStr: string,
      flightDomainId: string,
      type?: string,
      from?: string,
      until?: string,
      state?: string,
      pageToken?: string,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IFilterTasksResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRawCommissioningTasks(
        systemIdStr,
        flightDomainId,
        type,
        from,
        until,
        state,
        pageToken,
        limit,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get details about a scheduled task for a specific flight domain.
     * @summary Get task details by task id
     * @param {string} systemIdStr
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskById(
      systemIdStr: string,
      taskId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetTaskResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskById(
        systemIdStr,
        taskId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Abort task by task id
     * @summary Abort task by task id
     * @param {string} systemIdStr
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async systemIdStrTaskTaskIdAbortPut(
      systemIdStr: string,
      taskId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.systemIdStrTaskTaskIdAbortPut(
        systemIdStr,
        taskId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TasksApiFp(configuration);
  return {
    /**
     * Filter tasks.
     * @summary Filter tasks.
     * @param {string} systemIdStr
     * @param {string} flightDomainId
     * @param {string} [type]
     * @param {string} [from] Start timestamp for filter
     * @param {string} [until] End timestamp for filter
     * @param {string} [state]
     * @param {string} [pageToken]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRawCommissioningTasks(
      systemIdStr: string,
      flightDomainId: string,
      type?: string,
      from?: string,
      until?: string,
      state?: string,
      pageToken?: string,
      limit?: number,
      options?: any,
    ): AxiosPromise<IFilterTasksResponseST> {
      return localVarFp
        .getRawCommissioningTasks(
          systemIdStr,
          flightDomainId,
          type,
          from,
          until,
          state,
          pageToken,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get details about a scheduled task for a specific flight domain.
     * @summary Get task details by task id
     * @param {string} systemIdStr
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskById(
      systemIdStr: string,
      taskId: string,
      options?: any,
    ): AxiosPromise<IGetTaskResponseST> {
      return localVarFp
        .getTaskById(systemIdStr, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Abort task by task id
     * @summary Abort task by task id
     * @param {string} systemIdStr
     * @param {string} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemIdStrTaskTaskIdAbortPut(
      systemIdStr: string,
      taskId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .systemIdStrTaskTaskIdAbortPut(systemIdStr, taskId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
  /**
   * Filter tasks.
   * @summary Filter tasks.
   * @param {string} systemIdStr
   * @param {string} flightDomainId
   * @param {string} [type]
   * @param {string} [from] Start timestamp for filter
   * @param {string} [until] End timestamp for filter
   * @param {string} [state]
   * @param {string} [pageToken]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public getRawCommissioningTasks(
    systemIdStr: string,
    flightDomainId: string,
    type?: string,
    from?: string,
    until?: string,
    state?: string,
    pageToken?: string,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .getRawCommissioningTasks(
        systemIdStr,
        flightDomainId,
        type,
        from,
        until,
        state,
        pageToken,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get details about a scheduled task for a specific flight domain.
   * @summary Get task details by task id
   * @param {string} systemIdStr
   * @param {string} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public getTaskById(systemIdStr: string, taskId: string, options?: AxiosRequestConfig) {
    return TasksApiFp(this.configuration)
      .getTaskById(systemIdStr, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Abort task by task id
   * @summary Abort task by task id
   * @param {string} systemIdStr
   * @param {string} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public systemIdStrTaskTaskIdAbortPut(
    systemIdStr: string,
    taskId: string,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .systemIdStrTaskTaskIdAbortPut(systemIdStr, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
