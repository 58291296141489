// react
import React from 'react';

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// components
import { Box } from 'components/common/Box';
import Spinner from 'components/common/Spinner';
import ModalBase from './ModalBase';

interface IYesNoModalProps {
  /**
   * Flag indicating whether the modal is open
   */
  opened: boolean;
  /**
   * Title of the modal
   */
  title: React.ReactNode;
  /**
   * Subtitle of the modal
   */
  subtitle: React.ReactNode;
  /**
   * Body of the modal
   */
  bodyText: React.ReactNode;
  /**
   * Text on the "Yes" button
   */
  yesLabel: string;
  /**
   * Call-back invoked when clicking on the yes button
   */
  onYesFunction: () => void;
  /**
   * Flag indicating whether a loading is in progress
   */
  loading: boolean;
  /**
   * Text on the "No" button
   */
  noLabel: string;
  /**
   * Call-back invoked when clicking on the no button
   */
  onNoFunction: () => void;
  closeButton?: boolean;
  /**
   * Sets CTA (call to action) fill color on the yes button if true, on the no button if false
   */
  yesCTA: boolean;
}

const YesNoModal = (props: IYesNoModalProps) => {
  // props
  const {
    opened,
    title,
    subtitle,
    bodyText,
    yesLabel,
    onYesFunction,
    loading,
    noLabel,
    onNoFunction,
    closeButton,
    yesCTA, // sets CTA (call to action) fill color on the yes button if true, on the no button if false
  } = props;

  return (
    <ModalBase
      testId="c-yes-no-modal"
      closeButton={closeButton}
      opened={opened}
      title={
        <Box>
          <Box textAlign="left" p={2} mb={1}>
            <Typography
              style={{ fontWeight: 'bold' }}
              data-testid="c-yes-no-modal-title"
              color="secondary"
              variant="h5"
            >
              {title}
            </Typography>
            <Typography color="secondary" data-testid="c-yes-no-modal-subtitle" variant="subtitle1">
              {subtitle}
            </Typography>
          </Box>
        </Box>
      }
      handleClose={onNoFunction}
      actionButtons={
        <>
          <Button
            data-testid="c-modal-no-btn"
            onClick={() => onNoFunction()}
            variant={yesCTA ? 'outlined' : 'contained'}
            fullWidth
            color="primary"
          >
            {noLabel}
          </Button>
          <Button
            data-testid="c-modal-yes-btn"
            onClick={() => onYesFunction()}
            variant={yesCTA ? 'contained' : 'outlined'}
            fullWidth
            color="primary"
          >
            {yesLabel}
          </Button>
        </>
      }
    >
      <Typography
        style={{ margin: '16px 0px' }}
        data-testid="c-yes-no-modal-body-text"
        align="left"
        component="div"
      >
        {bodyText}
      </Typography>
      {loading && <Spinner />}
    </ModalBase>
  );
};

export default YesNoModal;
