import { DroneZoneCommandType, DroneZoneSelectCommand } from '../droneZoneCommand.type';
import { ZoneStatusActions } from '../../../reducer/droneZonePage.actions';
import { ControlledZone } from '../../../../../../shared/map/model/controlled-zone.model';

export const createDroneZoneSelectCommand: (
  zone: ControlledZone | null,
) => DroneZoneSelectCommand = (zone) => ({
  type: DroneZoneCommandType.Select,
  execute: (dispatchZoneStatus: React.Dispatch<ZoneStatusActions>) => {
    dispatchZoneStatus({
      type: 'SELECT_SINGLE_DRONE_ZONE',
      payload: zone,
    });
  },
});
