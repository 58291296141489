export const REPORT_SPEC_NAME = {
  AREA_SCAN: 'Area scan report',
  AUTOMATIC_CHECK_LIST: 'Automatic stock check list',
  EMPTY_LOCATION: 'Empty location report, per area',
};

export const INPUT_MODES = {
  AUTOMATIC: 'AUTOMATIC',
  FIXED_VALUE: 'FIXED_VALUE',
  CUSTOM_VALUES: 'CUSTOM_VALUES',
  PREDEFINED_VALUES: 'PREDEFINED_VALUES',
};

export enum WIDGET_TYPES {
  DROP_DOWN = 'DROP_DOWN',
  TEXT_FIELD = 'TEXT_FIELD',
}

export const TRIGGERING_SOURCE = {
  TIME_BASED_SCHEDULE: 'TIME_BASED_SCHEDULE',
  EXTERNAL_EVENT: 'EXTERNAL_EVENT',
};

export const VALUE_TYPES = {
  INTEGER: 'INTEGER',
  DECIMAL: 'DECIMAL',
  STRING: 'STRING',
  DATE: 'DATE',
  TIME: 'TIME',
  DATE_TIME: 'DATE_TIME',
};

export const PARAM_TYPES = {
  LOCATION_LABEL: 'LOCATION_LABEL',
  PALLET_NUMBER: 'PALLET_NUMBER',
  ARTICLE_NUMBER: 'ARTICLE_NUMBER',
  CUSTOMER: 'CUSTOMER',
  WMS_STATUS_AGE: 'WMS_STATUS_AGE',
  NEW_ISSUE_AGE: 'NEW_ISSUE_AGE',
  LOCATIONS_PERCENTAGE: 'LOCATIONS_PERCENTAGE',
};
