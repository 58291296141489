import { HeadCellProp } from 'components/EnhancedTable/types/cell';

// TODO: Move this into EnhancedTable
export type EnhancedTableHeader = HeadCellProp & {
  searchable: boolean;
  height: string;
};

export const commissioningTableHeaders: EnhancedTableHeader[] = [
  {
    id: 'progressIndicator',
    searchable: false,
    show: true,
    label: '',
    type: 'custom',
    align: 'left',
    height: '30px',
  },
  {
    id: 'state',
    searchable: true,
    show: true,
    label: 'Progress',
    type: 'string',
    align: 'left',
    height: '30px',
  },
  {
    id: 'task',
    searchable: true,
    show: true,
    label: 'Task',
    type: 'string',
    align: 'left',
    height: '30px',
  },
  {
    id: 'objectives',
    searchable: false,
    show: true,
    label: 'Objectives',
    type: 'string',
    align: 'left',
    height: '30px',
  },
  {
    id: 'elapsed_time',
    searchable: false,
    show: true,
    label: 'Elapsed time',
    type: 'string',
    align: 'left',
    height: '30px',
  },
  {
    id: 'started',
    searchable: false,
    show: true,
    label: 'Started',
    type: 'string',
    align: 'left',
    height: '30px',
  },
  {
    id: 'finished',
    searchable: false,
    show: true,
    label: 'Finished',
    type: 'string',
    align: 'left',
    height: '30px',
  },
  {
    id: 'actions',
    searchable: false,
    show: true,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
    height: '30px',
  },
];
