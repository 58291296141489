export const COLOR_PALETTE = {
  RED: '#D73F45',
  BLUE: '#4285F0',
  YELLOW: '#FFFF00',
  GREEN: '#0F9D58',
  ORANGE: '#E3984C',
  GREY: '#C1C1C1',
  DARK_GREY: '#A9A9A9',
  WHITE: '#FBFBFB',
  BLACK: '#3C3C3C',
};

export enum ISSUE_BADGE_COLORS_KEYS {
  NEW = 'NEW',
  KNOWN = 'KNOWN',
  SNOOZED = 'SNOOZED',
  SOLVED = 'SOLVED',
  NONE = 'NONE',
  NA = 'NA',
}

export const ISSUE_BADGE_COLORS = {
  [ISSUE_BADGE_COLORS_KEYS.NEW]: {
    background: COLOR_PALETTE.RED,
    text: COLOR_PALETTE.WHITE,
  },
  [ISSUE_BADGE_COLORS_KEYS.KNOWN]: {
    background: COLOR_PALETTE.RED,
    text: COLOR_PALETTE.WHITE,
  },
  [ISSUE_BADGE_COLORS_KEYS.SNOOZED]: {
    background: COLOR_PALETTE.BLUE,
    text: COLOR_PALETTE.WHITE,
  },
  [ISSUE_BADGE_COLORS_KEYS.SOLVED]: {
    background: COLOR_PALETTE.GREEN,
    text: COLOR_PALETTE.WHITE,
  },
  [ISSUE_BADGE_COLORS_KEYS.NONE]: {
    background: COLOR_PALETTE.GREY,
    text: COLOR_PALETTE.WHITE,
  },
  [ISSUE_BADGE_COLORS_KEYS.NA]: {
    background: COLOR_PALETTE.GREY,
    text: COLOR_PALETTE.BLACK,
  },
};

export const TEXT_COLORS = {
  LOCATIONS_WITH_ISSUES: COLOR_PALETTE.RED,
  LOCATIONS_WITH_WARNINGS: COLOR_PALETTE.ORANGE,
  LOCATIONS_WITHOUT_ISSUES: COLOR_PALETTE.GREEN,
  // For locations where the system can't know if
  // there is an issue (e.g. verity date is older than wms date)
  LOCATIONS_NEUTRAL: COLOR_PALETTE.DARK_GREY,
};

export const GROUND_CONTROL_STATUS_COLORS = {
  SAFE_COLOR: COLOR_PALETTE.BLUE,
  UNSAFE_COLOR: COLOR_PALETTE.ORANGE,
  EMERGENCY_COLOR: COLOR_PALETTE.RED,
};
