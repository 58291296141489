import React from 'react';

// material-ui core
import { AlertColor } from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

// components
import Alert from './Alert';

/**
 * Interface for the State Param of the UploadInfo function.
 */
export interface IUploadInfoStateParam {
  status: AlertColor;
  title: string;
  show: boolean;
  preventClose: boolean;
  message: string;
}

const UploadInfo = (props: {
  state: IUploadInfoStateParam;
  progress: number;
  showAlert: (msg: string) => void;
  testId: string;
  label: string;
}) => {
  // props
  const { state, progress, showAlert, testId, label } = props;

  return (
    <div>
      {progress > 0 && progress < 100 && <LinearProgress variant="determinate" value={progress} />}
      <Alert
        testId={testId}
        handleClose={() => showAlert(label)}
        title={state.title}
        message={state.message}
        status={state.status}
        isActive={state.show}
        percentage={progress}
        preventClose={state.preventClose}
      />
    </div>
  );
};

export default UploadInfo;
