import { Dispatch, SetStateAction } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';

// mui icons
import { KeyboardArrowRight } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { baseCardStyle } from 'components/BaseCard/styles';
import { connectionInstructions } from './helpers';

// styles
import { connectDroneStyles } from './styles';

/**
 * Following instructions
 * @returns component
 */
export function FollowingInstructions({
  qrCode,
  handleStartInstructions,
}: {
  qrCode: string;
  handleStartInstructions: Dispatch<SetStateAction<boolean>>;
}) {
  const { classes: droneClasses } = connectDroneStyles();
  const { classes: baseCardClasses } = baseCardStyle();

  return (
    <Card
      data-testid="c-connect-drone-to-wifi-card"
      className={droneClasses.cardWrapper}
      elevation={3}
    >
      <CardHeader
        data-testid="c-connect-drone-to-wifi-card-title"
        title="Connect drone to WiFi"
        classes={{
          title: baseCardClasses.title,
          subheader: baseCardClasses.subheader,
        }}
      />
      <Divider />
      <CardContent
        className={droneClasses.cardContentSection}
        data-testid="c-connect-drone-to-wifi-card-content"
      >
        <Typography p={2} color="textSecondary">
          There are a couple of steps that you need to perform to connect drones to WiFi. Make sure
          you are in the vicinity of the network that the drones will connect to. Click NEXT to open
          step-by-step guided instructions.
        </Typography>
        <Grid container p={2}>
          <List>
            {connectionInstructions.map((i: any, index: number) => {
              const title = `${index + 1}. ${i.title}`;

              return (
                <ListItem disableGutters key={i.id}>
                  <Grid container>
                    <Grid md={12} item>
                      <Typography color="textSecondary" component="p">
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                  {qrCode && i.indicator && <CheckCircleIcon color="success" />}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </CardContent>
      <CardActions sx={{ width: '100%' }}>
        <Button
          data-testid="c-connect-drone-to-wifi-card-next-button"
          variant="contained"
          fullWidth={true}
          size="medium"
          sx={{ margin: 1 }}
          endIcon={<KeyboardArrowRight />}
          onClick={() => handleStartInstructions(true)}
        >
          Next
        </Button>
      </CardActions>
    </Card>
  );
}
