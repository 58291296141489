import { IMinutesRange } from '../features/scheduleLockDateTimePicker/utils/getMinutesFromTimeRangesAccordingToHour';

/**
 * This function checks if currents minutes fits inside range
 * @param range
 * @param baseMinutes
 * @param currentMinutes
 * @returns boolean
 */
export const areMinutesWithinRanges = (minutesRanges: IMinutesRange[], minutes: number) =>
  minutesRanges.some(
    (range: IMinutesRange) => minutes >= range.fromMinutes && minutes <= range.toMinutes,
  );
