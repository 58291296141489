import { IFlightDomainStatusST } from 'codegen/nav_simulation';
import { isStatusBarSwitchEnabled } from '../pages/FlightDomainOverview/functions/isStatusBarSwitchEnabled';
import { StatusBarSwitch } from './status-bar-switch/StatusBarSwitch';

type LockUnlockSwitchProps = {
  flight_domain_status?: IFlightDomainStatusST | null;
  onLockSystem: () => void;
  onUnlockSystem: () => void;
  statusColor: string;
};

export const LockUnlockSwitch = ({
  flight_domain_status,
  onLockSystem,
  onUnlockSystem,
  statusColor,
}: LockUnlockSwitchProps) => {
  const isEnabled = isStatusBarSwitchEnabled(
    flight_domain_status?.can_unlock,
    flight_domain_status?.locked,
  );

  const isLocked = flight_domain_status?.locked || false;
  const isChecked = !isLocked;

  return (
    <StatusBarSwitch
      color={statusColor}
      locked={isLocked}
      checked={isChecked}
      disabled={!isEnabled}
      onChange={() => {
        if (isLocked) {
          onUnlockSystem();
        } else {
          onLockSystem();
        }
      }}
    />
  );
};
