import { createContext, useContext, useReducer } from 'react';
import { ZoneStatusActions } from './zoneStatusActions';
import { NoFlyZonePageState, noFlyZonePageDefaultState } from './noFlyZonePageDefaultState';
import { noFlyZoneStatusReducer } from './zoneStatusActionsReducer';

type NoFlyZonePage = {
  noFlyZonePageState: NoFlyZonePageState;
  dispatchNoFlyZone: React.Dispatch<ZoneStatusActions>;
};

const initialContext: NoFlyZonePage = {
  noFlyZonePageState: noFlyZonePageDefaultState,
  dispatchNoFlyZone: (_: ZoneStatusActions) => {},
};

const Context = createContext(initialContext);
export const useNoFlyZoneContext = () => useContext(Context);

export const NoFlyZonePageContext = ({ children }: any) => {
  const [droneZonePageState, dispatchDroneZone] = useReducer(
    noFlyZoneStatusReducer,
    noFlyZonePageDefaultState,
  );

  return (
    <Context.Provider
      value={{
        noFlyZonePageState: droneZonePageState,
        dispatchNoFlyZone: dispatchDroneZone,
      }}
    >
      {children}
    </Context.Provider>
  );
};
