import { MapRoot, NodeST } from '../MapContainer.model';
import { initialMapOptions, MapOptions } from './MapOptionsState';

export type LOD = 'low' | 'high';

/**
 * Keep track of what is selected
 */
type SelectionState = {
  selectedBoxes: NodeST[];
};

/**
 * State of the 3D map.
 */
export type MapState = {
  flightDomainName?: string;
  mapVersion?: string;
  selectionState: SelectionState;
  lod: LOD;
  map: MapRoot | NodeST | null;
  showOptionsModal: boolean;
  options: MapOptions;
  canvasProportion: number;
  canvasSize: [number, number];
};

/**
 * Initial state of the 3D map.
 */
export const initialMapState: MapState = {
  flightDomainName: undefined,
  mapVersion: undefined,
  selectionState: {
    selectedBoxes: [],
  },
  lod: 'low',
  map: null,
  showOptionsModal: false,
  options: initialMapOptions,
  canvasProportion: 1.75,
  canvasSize: [0, 0],
};
