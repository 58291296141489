import { DISPLAY_ISSUE_TYPES_KEYS } from 'common/issueTypesAndStates';
import { IIssue1STStateEnum } from 'codegen/report';
import { IFacilityModalsState } from './IFacilityModalsStore';

/**
 * Initial state of the facility modal store
 */
export const initialFacilityModalsState: IFacilityModalsState = {
  locationInfo: {},
  editRequestModalOpened: false,
  deleteRequestModalOpened: false,
  toggleRequestStateModalOpened: false,
  toggleSnoozeStateModalOpened: false,
  amendModalOpened: false,
  overwriteData: [
    {
      userOverride: {},
      verityState: '-',
      location: '',
      version: '-',
      verityValue: [],
      wmsState: '-',
      wmsValue: '',
      slotStatusVersion: '',
      isReview: false,
      userOverrideValue: '',
      verityAmended: '',
      originalVerityState: '-',
      originalVerityValue: '',
      showContentSetByUser: false,
      imageIds: [],
      reportId: '',
      verityDate: '',
      length: 0,
      selected: false,
      userOverrideUserName: '',
    },
  ],
  locationModalOpened: false,
  requestToEdit: {},

  abortReportModalOpened: false,
  abortReportModalData: {},
  archiveReportModalOpened: false,
  archiveReportModalData: {},
  restoreReportModalOpened: false,
  restoreReportModalData: {},
  updateReportModalOpened: false,
  updateReportModalData: {},

  resetSystemModalOpened: false,
  resetSystemData: {},

  droneErrorModalOpened: false,
  droneErrorData: {
    id: -1,
    drone_name: '-1',
    serial: '0000000',
    chargerId: '',
    chargerPosition: '',
    battery_level: '',
    battery_state: '',
    wifiStatus: '',
    drone_state: 'ON_CHARGER',
    drone_online: 'OFFLINE',
    last_operation_feedback: '',
    not_ready_reason: '',
    additional_diagnostics: '',
    updatedAt: '',
  },

  deleteReportSpecModalOpened: false,
  deleteReportSpecData: {},
  locations: [
    {
      userOverride: '',
      verityState: '-',
      location: '',
      version: '-',
      verityValue: '',
      wmsState: '-',
      wmsValue: '',
      slotStatusVersion: '',
      isReview: false,
      userOverrideValue: '',
      length: 0,
      issueType: DISPLAY_ISSUE_TYPES_KEYS.NONE_V_DATA_OUTDATED,
      id: '',
      imageIds: [],
      rowData: {
        actions: {
          actions: [],
        },
        state: IIssue1STStateEnum.New,
        contentFoundDate: '',
        matchArray: [],
        wmsDate: '',
        customer: '',
        LocationName: '',
        barcodeExpectedFoundOn: '',
        barcodeFoundShouldBeOn: '',
        contentExpected: [],
        contentFound: [],
        issueId: '',
        slotStatus: {
          verity_status: {
            result_id: '',
          },
        },
        wmsArticleNo: [],
        wmsCustomer: [],
        wmsQty: [],
      },
    },
  ],
};
