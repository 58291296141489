import { useThree, useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { Vector3, Mesh, MeshBasicMaterial, AlwaysDepth, CanvasTexture } from 'three';
import { MutableState } from '../../reducer/MutableState';
import { MapOptions } from '../../reducer/MapOptionsState';
import { MakeTextureParams, printText } from '../../utils/3DmapFunctions';
import { meshRenderOrder } from '../../../map/defaults/mesh-render-order';

type NodeLabelColors = {
  background: string;
  border: string;
  text: string;
};

const noNodeLabelColors: NodeLabelColors = {
  background: 'black',
  border: 'red',
  text: 'orange',
};

/**
 * Text label for NFZ.
 * Will change size depending on FOV.
 * @param props nfzState, dimensions, map options
 * @returns JSX.Element
 */
export const MeshNodeLabel = (props: {
  w: number;
  l: number;
  options: MapOptions;
  textLines: string[];
  colors?: NodeLabelColors;
  renderOrder?: number;
  isTextScalable?: boolean;
  maxFontSizeInPx?: number;
  minFontSizeInPx?: number;
}) => {
  const {
    w,
    l,
    options,
    textLines,
    colors: givenColors,
    renderOrder = meshRenderOrder.label,
    maxFontSizeInPx,
    minFontSizeInPx,
    isTextScalable = true,
  } = props;
  const colors = givenColors || noNodeLabelColors;
  const { cameraState } = MutableState.getState();
  const { maxFOV } = options.camera.orthographic;

  const { height: vpH, width: vpW, aspect } = useThree((s) => s.viewport);
  const ref = useRef<Mesh>(null!);

  const defaultHiResSize = 2048;
  const pos = new Vector3(0, 0, 0.001);
  const shorterSide = Math.min(w, l);
  const ssViewportDimension = w >= l ? vpH : vpW;
  const thresholdTextSize = ssViewportDimension / 8;

  const [labelStencil, fontEstate] = labelAlphaMap({
    textLines,
    proportion: w / l,
    textColor: colors.text,
    borderColor: colors.border,
    backgroundColor: colors.background,
    maxFontSizeInPx,
    minFontSizeInPx,
    fontWeight: 'bold',
    dashed: false,
    includeBorder: true,
    hiRes: true,
    defaultHiResSize,
    dimensions: {
      width: w,
      length: l,
    },
    fovSpan: {
      horizontalFOV: maxFOV * aspect,
      verticalFOV: maxFOV,
    },
  });

  const thresholdFOV = (shorterSide * ssViewportDimension * fontEstate) / thresholdTextSize;

  const getTextSizeInPx = () => {
    const fov = cameraState.currentOrthographicFOV;
    const ssAxisFOV = w >= l ? fov : fov * aspect;
    const ssPx = (shorterSide / ssAxisFOV) * ssViewportDimension;
    const textSizeInPx = ssPx * fontEstate;
    return textSizeInPx;
  };

  useFrame(() => {
    const fov = cameraState.currentOrthographicFOV;

    const textSize = getTextSizeInPx();
    const scale = textSize >= thresholdTextSize ? fov / thresholdFOV : 1;

    isTextScalable && ref.current.scale.set(scale, scale, 1);
  });

  const labelMaterial = new MeshBasicMaterial({
    alphaMap: labelStencil,
    transparent: true,
    depthFunc: AlwaysDepth,
    color: colors.text,
  });

  return (
    <mesh ref={ref} material={labelMaterial} position={pos} renderOrder={renderOrder}>
      <planeGeometry args={[w, l]} />
    </mesh>
  );
};

const labelAlphaMap = (args: MakeTextureParams) => {
  const baseSize = args.hiRes && args.defaultHiResSize ? args.defaultHiResSize : 128;
  const canvas = document.createElement('canvas');

  canvas.width = args.proportion < 1 ? baseSize * args.proportion : baseSize;
  canvas.height = args.proportion < 1 ? baseSize : baseSize / args.proportion;

  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Could not create canvas context');
  }

  context.fillStyle = 'black';
  context.fillRect(0, 0, canvas.width, canvas.height);

  const fontSize = printText({
    textLines: args.textLines,
    textColor: 'white',
    context,
    canvas,
    fontWeight: args.fontWeight,
    dimensions: args.dimensions,
    maxFontSizeInPx: args.maxFontSizeInPx,
    minFontSizeInPx: args.minFontSizeInPx,
    fovSpan: args.fovSpan,
  });
  const shorterSide = Math.min(canvas.width, canvas.height);

  const texture = new CanvasTexture(canvas);

  return [texture, fontSize / shorterSide] as const;
};
