// shared variables and configurations
import { ITableRow } from '../../interfaces';
import { TEXT_COLORS } from '../colors';
import { LOCATION_ISSUE_TYPES } from '../issueTypesAndStates';

export const styleTableCell = (row: ITableRow, cellID: string, index = -1) => {
  if (row.locationIssueType === LOCATION_ISSUE_TYPES.SYSTEM_CANT_INFER_IF_HAS_ISSUE) {
    // This "if" captures cases where the verity data is older than the wms data
    return styleTableCellWhereSystemCantInferIssue(row, cellID);
  }
  if (index !== -1 && row.matchArray) {
    // This "else if" captures cases where there is a match array (which will be used to color
    // individual barcodes and states for locations that have a list of those)
    const hasIssue = row.locationIssueType === LOCATION_ISSUE_TYPES.HAS_ISSUE;
    return styleCellForRowBasedOnIssue(row, cellID, !row.matchArray[index], hasIssue);
  }
  // All other cases will not get dedicated styles (for now)
  return {};
};

const styleCellForRowBasedOnIssue = (
  row: ITableRow,
  cellID: any,
  match: boolean,
  hasIssue: boolean,
) => {
  switch (cellID) {
    case 'verityValue':
      return styleVerityValue(row, match, hasIssue);
    case 'contentFound':
      return row.colorizeContentFound ? styleContentFound(row, match, hasIssue) : {};

    case 'userValue':
      return styleUserValue(row, match, hasIssue);

    case 'wmsValue':
    case 'contentExpected':
      if (row.colorizeContentExpected) {
        return styleBasedOnMatchAndIssue(match, hasIssue);
      }
      return {};

    case 'issue':
    case 'issueType':
      return hasIssue
        ? { color: TEXT_COLORS.LOCATIONS_WITH_ISSUES }
        : { color: TEXT_COLORS.LOCATIONS_WITHOUT_ISSUES };

    default:
      return {};
  }
};

const styleVerityValue = (row: ITableRow, match: any, hasIssue: any) => {
  if (locationHasUserOverwrite(row)) {
    // If the location has a user overwrite,
    // we don't highlight the verity value, we strike it through
    return styleFadedStrikeThrough();
  }
  return styleContentFound(row, match, hasIssue);
};

const styleContentFound = (row: any, match: any, hasIssue: any) =>
  styleBasedOnMatchAndIssue(match, hasIssue);

const styleUserValue = (row: ITableRow, match: any, hasIssue: any) => {
  // If the location does not have a user overwrite we don't
  // want to highlight the "-"
  if (!locationHasUserOverwrite(row)) {
    return {};
  }
  return styleBasedOnMatchAndIssue(match, hasIssue);
};

const styleBasedOnMatchAndIssue = (match: any, hasIssue: any) =>
  match
    ? {
        color: hasIssue ? TEXT_COLORS.LOCATIONS_WITH_ISSUES : TEXT_COLORS.LOCATIONS_WITH_WARNINGS,
      }
    : { color: TEXT_COLORS.LOCATIONS_WITHOUT_ISSUES };

const styleTableCellWhereSystemCantInferIssue = (row: ITableRow, cellID: string | string[]) => {
  if (cellID.includes('wms') || cellID === 'contentExpected') {
    // return no style for wms cells
    return {};
  }
  // fade remaining verity cells when system can't infer if an issue exists or not
  return styleFaded();
};

const styleFadedStrikeThrough = () => ({
  textDecoration: 'line-through',
  color: TEXT_COLORS.LOCATIONS_NEUTRAL,
});

const styleFaded = () => ({
  color: TEXT_COLORS.LOCATIONS_NEUTRAL,
});

const locationHasUserOverwrite = (row: ITableRow) => row.userValue && row.userValue !== '-';
