import { useSearchParams } from 'react-router-dom';
import { getAmendingRowsData, sortRows, getLocationListData } from 'common/Tables/tableFunctions';
import { REPORT_STATES } from 'common/reportStates';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import EnhancedTable from 'components/EnhancedTable';
import { filterOrderTableRows } from 'components/EnhancedTable/functions/filterOrderTableRows';
import { OrderValue } from 'components/EnhancedTable/types/rows';
import { ILocationReportData } from 'pages/WarehouseStatus/warehouseStatusFunctions';
import { useFacilityModalsStore } from 'store/Modals';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { CurrentTab } from '../utils/GetTabData';

export const ReportLocationsGrid = ({
  reportId,
  refreshData,
  reportState,
  reportNeedsReview,
  getReportData,
  tabs,
  isLoading,
  currentActiveSpinner,
  currentTabData,
  activeTab,
  reviewLocationsTab,
  changeTab,
}: {
  activeTab: number;
  reviewLocationsTab: number;
  currentTabData: CurrentTab;
  changeTab: (tabValue: number) => void;
  reportId: string;
  tabs: {
    icon?: JSX.Element;
    label: string;
    show: boolean;
  }[];
  isLoading: number | boolean;
  currentActiveSpinner: { [key: string]: boolean };
  reportState: string;
  reportNeedsReview: boolean;
  getReportData: (reportId: string, nLocationsPerRequest: number) => void;
  refreshData?: { refreshData: (() => void) | (() => void) | (() => void) };
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('searchTerm') || '';
  const orderBy = searchParams.get('orderBy') || '';
  const order = (searchParams.get('order') as OrderValue) || '';

  const { dispatchFacilityModals } = useFacilityModalsStore();

  const onRowClick = (e: KeyboardEvent, row: ILocationReportData) => {
    if (reportNeedsReview && activeTab === reviewLocationsTab) {
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.AMEND_VERITY_VALUE,
        payload: getAmendingRowsData(sortRows(currentTabData.rows, order, orderBy), row, reportId),
        refreshData: {
          refreshData: () =>
            getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB),
        },
        tableAmending: true,
      });
      searchParams.set('amendLocation', row.location);
      setSearchParams(searchParams);
    } else if (reportState !== REPORT_STATES.DELETED) {
      dispatchFacilityModals({
        type: FacilityModalsActionTypes.TOGGLE_LOCATION_MODAL,
        payload: getLocationListData(
          filterOrderTableRows(
            currentTabData.rows,
            searchTerm,
            orderBy,
            order,
            currentTabData.headCells,
          ),
          row,
        ),
        refreshDataOnClose: {
          refreshData: () =>
            getReportData(reportId, NETWORK_REQUEST_SETTINGS.REPORT_PAGE_SIZE_FOR_FULL_REPORT_TAB),
        },
        parentPage: 'Report',
        reportId,
        locationInfo: { tab: currentTabData.tableTitle, searchTerm },
      });
      searchParams.set('location', row.location);
      setSearchParams(searchParams);
    }
  };

  const { tableFor, tableTitle, tableSubtitle, headCells, rows } = currentTabData;

  return (
    <EnhancedTable
      reportId={reportId}
      tableFor={tableFor}
      tableTitle={tableTitle}
      tableSubtitle={tableSubtitle}
      headCells={headCells}
      refreshData={refreshData}
      rows={rows}
      tabs={tabs}
      tabStatus={true}
      changeTab={changeTab}
      startingTab={activeTab}
      isLoading={isLoading}
      currentActiveSpinner={currentActiveSpinner}
      onRowClick={onRowClick}
    />
  );
};
