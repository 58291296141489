import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BasicAlert, IBasicAlertProps } from './BasicAlert';
import { Box } from './Box';

interface IAcionsAlertProps extends IBasicAlertProps {
  handleClick: () => void;
  handleClickLabel: string;
  handleClose: () => void;
}

/**
 * Actions alert
 * @param props IAcionsAlertProps props
 * @returns component
 */
export const ActionsAlert = ({
  isActive,
  status,
  icon,
  title,
  message,
  handleClick,
  handleClickLabel,
  handleClose,
  testId,
  iconMapping,
}: IAcionsAlertProps) => {
  const actions = (
    <Box>
      <Button color="inherit" size="small" onClick={handleClick}>
        {handleClickLabel}
      </Button>
      <IconButton size="small" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  return (
    <BasicAlert
      action={actions}
      isActive={isActive}
      message={message}
      status={status}
      icon={icon}
      iconMapping={iconMapping}
      testId={testId}
      title={title}
    />
  );
};
