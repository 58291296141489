import { IGroundControlModalsState } from './IGroundControlModalsStore';

/**
 * Initial state of the ground control modal store
 */
export const initialGroundControlModalsState: IGroundControlModalsState = {
  lockModalOpened: false,
  unlockModalOpened: false,
  emergencyModalOpened: false,
  flightDomainId: '',
};
