import { useFrame } from '@react-three/fiber';
import { useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { inProgressDroneZone } from '../defaults/in-progress-drone-zone.defaults';
import { meshRenderOrder } from '../defaults/mesh-render-order';
import { Vec6 } from '../../map-container/MapContainer.model';
import { MutableState } from '../../map-container/reducer/MutableState';
import { DroneZoneTypes, DroneZones } from '../model/drone-zones.model';
import { isCursorInFacility } from '../../map-container/utils/3DmapFunctions';
import { buildDroneZone } from '../../../delivery/features/drone-zones/features/drone-zones-map/buildDroneZone';
import { bottomLeftDimensionsToSizeAndPos } from '../../map-container/utils/creation.util';
import { droneZoneDrawMaterial } from '../utils/drone-zone-draw-material';

export const DroneZoneDrawer = ({
  worldBox,
  drawPositionStart,
  facilityHeight = 1,
}: {
  worldBox: Vec6;
  drawPositionStart?: Vector3;
  facilityHeight: number;
}) => {
  const { interaction } = MutableState.getState();
  const { guidePosition } = interaction;

  const [newZone, setNewZone] = useState<DroneZones>({
    ...inProgressDroneZone,
  });

  useFrame(() => {
    if (!drawPositionStart) {
      return;
    }

    if (isCursorInFacility(worldBox, guidePosition)) {
      setNewZone(
        buildDroneZone(
          newZone.name,
          {
            start: drawPositionStart,
            end: new Vector3(guidePosition.x, guidePosition.y, facilityHeight),
            height: facilityHeight,
          },
          DroneZoneTypes.controlledZone,
        ),
      );
    }
  });

  const { w, l, h, x, y, z } = bottomLeftDimensionsToSizeAndPos(newZone.sizeAndPosition);

  const drawMaterial = useMemo(() => droneZoneDrawMaterial(w, l), [l, w]);

  return drawPositionStart ? (
    <mesh position={[x, y, z]} material={drawMaterial} renderOrder={meshRenderOrder.creation}>
      <boxGeometry args={[w, l, h]} />
    </mesh>
  ) : null;
};
