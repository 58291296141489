// libraries
import { useCallback, useEffect } from 'react';

// functions, stores
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { UserLevelActionNames } from '../store/UserLevelStore/userLevelActions';
import { useUserLevelStore } from '../store/UserLevelStore/userLevelStore';

/**
 * Hook that ensures that the data for the current user is loaded.
 * @param componentName Name of the component making use of the hook.
 * @returns a flag which is true if the user data has been loaded and is ready.
 */
export const useLoadUserLevelData = (componentName: string) => {
  // User level state
  const { isDataReady, getUserData, dispatchUserLevelStore } = useUserLevelStore();

  /**
   * String to prefix console logs with.
   */
  const logPrefix = getLogPrefixForType('HOOK', 'useLoadUserLevelData', componentName);

  const populateUserLevelStore = useCallback(() => {
    if (!isDataReady()) {
      console.debug(logPrefix, 'Getting user data');
      getUserData();
    }
  }, [getUserData, isDataReady, logPrefix]);

  useEffect(() => {
    populateUserLevelStore();
    // If the user was in auth process of setting password for the first time or
    // setting the mfa, after success login, we clear auth process details
    dispatchUserLevelStore({
      type: UserLevelActionNames.CLEAR_AUTH_PROCESS_DETAILS,
    });
  }, [dispatchUserLevelStore, populateUserLevelStore]);

  return { populateUserLevelStore };
};
