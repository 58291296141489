import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { hasOverrideToVerity, hasOverrideValue } from './amendFunctions';
import { OVERWRITE_MODES } from '../../AmendValues.model';

export const getOptionOverwriteWithVerity = (locationData: ILocationData) => ({
  disabled:
    !hasOverrideValue(locationData) ||
    hasOverrideToVerity(locationData) ||
    locationData.verityValue === 'Unreachable Location',
  value: OVERWRITE_MODES.USE_VERITY,
  label: `Use original verity value: ${locationData.verityValue}`,
});
