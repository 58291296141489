import { Vector3 } from 'three';
import { IBox3DST } from 'codegen/flight_domain';

export const geometryToPosAndSize = (geo: IBox3DST) => {
  const [dx, dy] = [geo.max.x - geo.min.x, geo.max.y - geo.min.y];
  const [x, y] = [geo.min.x + dx / 2, geo.min.y + dy / 2];
  const [width, length] = [Math.abs(dx), Math.abs(dy)];

  return { pos: new Vector3(x, y, 1), size: { width, length, height: 1 } };
};
