import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(3),
    width: '100%',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    margin: 0,
    textAlign: 'center',

    '::before': {
      content: '""',
      display: 'block',
      flex: '1 1 auto',
      minWidth: 0,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },

    '::after': {
      content: '""',
      display: 'block',
      flex: '1 1 auto',
      minWidth: 0,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  inlineLink: {
    color: theme.palette.textPrimary.main,
    textDecoration: 'none',

    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  form: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(4),
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(3),
  },
  footerSection: {
    textAlign: 'center',
  },
}));
