import { CommissioningTask } from '../../model/commissioningTask.model';
import { filterCommissioningTasksByDates } from './filterCommissioningTasksByDates';
import { filterCommissioningTasksByState } from './filterCommissioningTasksByState';
import { FilterDatesCommissioning } from '../../model/commissioningDateFilter.model';

export const filterCommissioningTasks = (
  commissioningTasks: CommissioningTask[],
  dates: FilterDatesCommissioning,
  activeTab?: string,
) =>
  filterCommissioningTasksByDates(
    filterCommissioningTasksByState(commissioningTasks, activeTab),
    dates,
  );
