import isArray from 'lodash/isArray';

/**
 * Helper function to replace nulls in an array with a given value
 * @param arr original array
 * @param toBeReplaced item to be replaced
 * @param replacementItem replacement item
 * @returns an array with replaced values
 */
export const replaceArrayItems = (arr: any, toBeReplaced: any, replacementItem: any) => {
  if (!isArray(arr)) {
    console.error('replaceArrayItems:: arr is not an array');
    return undefined;
  }

  const result: any[] = [];

  arr.forEach((item) => {
    if (item === toBeReplaced) {
      result.push(replacementItem);
    } else {
      result.push(item);
    }
  });

  return result;
};

/**
 * Get a match array out of 2 arrays
 * @param contentExpected array with the content expected
 * @param contentFound array with the content found
 * @returns array with the matches
 */
export const getMatchArray = (contentExpected: any[], contentFound: any[]) => {
  // Compute match array
  const matchArray: any[] = [];

  if (isArray(contentExpected) && isArray(contentFound)) {
    if (contentExpected.length >= contentFound.length) {
      contentExpected.forEach((item, index) => matchArray.push(item === contentFound[index]));
    } else {
      contentFound.forEach((item, index) => matchArray.push(item === contentExpected[index]));
    }
  } else {
    console.error('getMatchArray :: content found and/or content expected are not arrays');
  }

  return matchArray;
};
