import { DroneZoneMapLegendItemEntries } from 'shared/map/features/drone-zone-map-legend/models/DroneZoneLegend.model';

export const mapLegendPlanning: DroneZoneMapLegendItemEntries = [
  [
    {
      type: 'drone-zone',
      subType: 'controlled-zone-active',
      label: 'Controlled zone (flight permitted)',
    },
    {
      type: 'drone-zone',
      subType: 'controlled-zone-inactive',
      label: 'Controlled zone (flight not permitted)',
    },
    {
      type: 'drone-zone',
      subType: 'open-fly-zone',
      label: 'Open-fly zone',
    },
  ],
  [
    {
      type: 'drone-group',
      label: 'Overlapping controlled zones',
    },
  ],
];
