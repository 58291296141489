import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { noFlyZoneOpacity } from 'shared/map/defaults/noFlyZoneOpacity.default';

export const useStyles = makeStyles()(() => ({
  noFlyZone: {
    color: mapStyle.noFlyZone.inactive.textColor.primary,
    background: alpha(
      mapStyle.noFlyZone.inactive.backgroundColor.primary,
      noFlyZoneOpacity.inactive,
    ),
  },
  noFlyZoneActive: {
    color: mapStyle.noFlyZone.active.textColor.primary,
    background: alpha(mapStyle.noFlyZone.active.backgroundColor.primary, noFlyZoneOpacity.active),
  },
  noFlyZoneSelected: {
    color: mapStyle.noFlyZone.active.textColor.primary,
    background: alpha(
      mapStyle.noFlyZone.active.backgroundColor.primary,
      noFlyZoneOpacity.activeSelected,
    ),
    zIndex: 1,
  },
  noFlyZoneSelectedInactive: {
    color: mapStyle.noFlyZone.inactive.textColor.primary,
    background: alpha(
      mapStyle.noFlyZone.active.backgroundColor.primary,
      noFlyZoneOpacity.inactiveSelected,
    ),
    zIndex: 1,
  },
}));
