import { createContext, useContext, useReducer } from 'react';
import { ZoneStatusActions } from './zoneStatusActions';
import { ZoneStatusState, zoneStatusInitialState } from './zoneStatusInitialState';
import { zoneStatusReducer } from './zoneStatusActionsReducer';

type DroneZonePage = {
  droneZonePageState: ZoneStatusState;
  dispatchDroneZone: React.Dispatch<ZoneStatusActions>;
};

const initialContext: DroneZonePage = {
  droneZonePageState: zoneStatusInitialState,
  dispatchDroneZone: (_: ZoneStatusActions) => {},
};

const Context = createContext(initialContext);
export const useDroneZoneContext = () => useContext(Context);

export const DroneZonePageContext = ({ children }: any) => {
  const [droneZonePageState, dispatchDroneZone] = useReducer(
    zoneStatusReducer,
    zoneStatusInitialState,
  );

  return (
    <Context.Provider
      value={{
        droneZonePageState,
        dispatchDroneZone,
      }}
    >
      {children}
    </Context.Provider>
  );
};
