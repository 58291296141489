import { Link } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import { Button } from '@mui/material';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { INVENTORY_PAGES_URLS } from 'common/pages';

export const ReportScheduleButton = ({ systemId }: { systemId: string }) => {
  const isVisibleScheduleButton = userHasPermission(PERMISSION.SCHEDULED_REPORTS_MANAGEMENT);

  if (!isVisibleScheduleButton) {
    return null;
  }

  return (
    <Button
      startIcon={<EventIcon />}
      data-testid="c-schedule-report-button"
      variant="contained"
      color="primary"
      to={`/${systemId}${INVENTORY_PAGES_URLS.SCHEDULING}`}
      component={Link}
    >
      Schedule report
    </Button>
  );
};
