import { initSupersetAPIService } from './service';

interface ISystemSpecificSuperSetGuestToken {
  systemId: string;
  signal?: AbortSignal;
}

/**
 * Create system specific superset guest token
 * @param params ISystemSpecificSuperSetGuestToken
 * @returns {object} <ISuperSetGuestTokenResponseST>
 */
export const createSystemSpecificSuperSetGuestToken = ({
  systemId,
  signal,
}: ISystemSpecificSuperSetGuestToken) =>
  initSupersetAPIService().createSystemSpecificSuperSetGuestToken(systemId, { signal });
