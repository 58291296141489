import { IReportST } from 'codegen/report';
import { REPORT_STATES } from '../reportStates';

export const isReportFinishedIncomplete = (report: IReportST) => {
  const { completion } = calculateCompletion(report);
  return (
    [REPORT_STATES.FINISHED, REPORT_STATES.DELETED].includes(report.state as REPORT_STATES) &&
    completion < 100
  );
};

export const isReportFinished = (status: string) =>
  status === REPORT_STATES.FINISHED || status === REPORT_STATES.DELETED;

/**
 * FIXME https://verity-ag.atlassian.net/browse/UD-5292
 * the completion as calculated by the BE is no longer correct
 * calculation on the FE is done until the BE is updated (or the
 * calculation is removed from the BE)
 * eno 2023-11-10
 *
 * Calculate the completion of a report
 * @param report Report to be analyzed
 * @returns completed and total location, and completion rate
 */
export const calculateCompletion = (report: IReportST) => {
  const completed = report.location_data_count.SCANNED;
  const total = report.num_locations - (report.location_data_count.EXCLUDED || 0);
  const completion = +((completed / total) * 100).toFixed(1);
  return { completed, total, completion };
};
