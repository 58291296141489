import { INoFlyZonesResponseST, INoFlyZoneStatus1ST } from 'codegen/no_fly_zone';
import { IGetOpenFlyZonesResponseST, IOpenFlyZoneStatus1ST } from 'codegen/open_fly_zone';
import { IControlledZoneStatus1ST, IGetControlledZonesResponseST } from 'codegen/controlled_zone';
import { PERMISSION } from '../../../../features/permissions/permissions.model';
import { userHasPermission } from '../../../../features/permissions/userHasPermission';
import { OpenFlyZoneService } from '../../../../services/OpenFlyZone';
import { ControlledZoneService } from '../../../../services/ControlledZone';
import GroundControlServices from '../../../../services/GroundControlServices';
import { GetDroneZonesParams } from './getDroneZone.model';

export const getDroneZones = async ({
  requestController,
  flightDomainId,
  systemId,
  onSuccess,
  onBeforeSend,
  onFinally,
}: GetDroneZonesParams<{
  noFlyZones: { [key: string]: INoFlyZoneStatus1ST };
  openFlyZones: { [key: string]: IOpenFlyZoneStatus1ST };
  controlledZones: { [key: string]: IControlledZoneStatus1ST };
}>) => {
  const userHasNoFlyZonePermission = userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT);

  let noFlyZonesResponse: { data: INoFlyZonesResponseST } | undefined = {
    data: { no_fly_zones: {} } as INoFlyZonesResponseST,
  };

  await onBeforeSend();

  if (userHasNoFlyZonePermission) {
    const noFlyZonesReservation = requestController.reserveSlotForRequest();
    noFlyZonesResponse = await requestController.doRequest({
      request: GroundControlServices.getNoFlyZones,
      requestParams: [systemId, flightDomainId, noFlyZonesReservation.signal],
      messageErrorFallback: 'There was a problem fetching "No-fly zones"',
    });
  }
  const controlledZonesReservation = requestController.reserveSlotForRequest();
  const controlledZonesResponse: { data: IGetControlledZonesResponseST } | undefined =
    await requestController.doRequest({
      request: ControlledZoneService.getControlledZones,
      requestParams: [systemId, flightDomainId, controlledZonesReservation.signal],
      messageErrorFallback: 'There was a problem fetching "Controlled zones"',
    });
  const openFlyZonesReservation = requestController.reserveSlotForRequest();
  const openFlyZonesResponse: { data: IGetOpenFlyZonesResponseST } | undefined =
    await requestController.doRequest({
      request: OpenFlyZoneService.getOpenFlyZones,
      requestParams: [systemId, flightDomainId, openFlyZonesReservation.signal],
      messageErrorFallback: 'There was a problem fetching "Open-fly zones"',
    });
  if (!!noFlyZonesResponse && !!controlledZonesResponse && !!openFlyZonesResponse) {
    await onSuccess({
      noFlyZones: noFlyZonesResponse.data.no_fly_zones,
      controlledZones: controlledZonesResponse.data.controlled_zones,
      openFlyZones: openFlyZonesResponse.data.open_fly_zones,
    });
  }

  await onFinally();
};
