import { Typography } from '@mui/material';
import { useStyles } from './DroneZoneMapLegendItem.styles';

export const DroneZoneMapLegendItem = ({
  icon,
  label,
}: {
  icon: React.ReactNode;
  label: string;
}) => {
  const { classes } = useStyles();

  return (
    <li className={classes.listItem}>
      <div className={classes.listItemIconWrapper}>{icon}</div>

      <div className={classes.listItemLabelWrapper}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </div>
    </li>
  );
};
