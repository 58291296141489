import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { makeStyles } from 'tss-react/mui';

import {
  DataGridPremium,
  gridExpandedRowCountSelector,
  GridRowGroupingState,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { barcodeColumns } from './models/barcodeColumns.model';
import { transformBarcodeRows } from './utils/transformBarcodeRows.util';

const useStyles = makeStyles()((theme) => ({
  root: {
    border: 'none',
    borderRadius: theme.spacing(1),
    width: '100%',

    height: 400,
    '& .MuiDataGrid-container--top [role=row]': {
      background: 'none',
      width: '100%',
    },

    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.shades.white,
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(1),
      border: '1px #EEEEEE solid',
    },
    '.MuiDataGrid-columnHeader:focus-within,': {
      outline: 'none !important',
    },

    '& .MuiDataGrid-row': {
      borderRadius: theme.spacing(1),
      border: '1px #EEEEEE solid',
      backgroundColor: theme.palette.shades.white,
      ':hover': {
        backgroundColor: theme.palette.shades.white,
      },
    },
    '& .MuiDataGrid-cell': {
      borderTopStyle: 'none',
      background: 'none',
    },
    '.MuiDataGrid-cell:focus-within,': {
      outline: 'none !important',
    },

    '& ::after': {
      display: 'none',
    },
    '& ::-webkit-scrollbar': {
      height: 9,
      width: 5,
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: 200,
    },
    '& ::-webkit-scrollbar-track:vertical': {
      marginTop: 15,
      marginBottom: 5,
    },
    '& .MuiDataGrid-scrollbar--vertical': {
      marginRight: 8,
    },
  },
}));

const MAX_ROWS_TO_DISPLAY = 6;

export const BarcodesInformation = ({
  locationData,
  facilitySettings,
}: {
  locationData: ILocationData;
  facilitySettings: IFacilitySettingsST;
}) => {
  const { classes } = useStyles();
  const gridApiRef = useGridApiRef();
  const [autoHeight, setAutoHeight] = useState<boolean>(
    locationData.rowData.matchArray?.length < MAX_ROWS_TO_DISPLAY,
  );
  const [groupingState, setGroupingState] = useState<GridRowGroupingState | null>(null);

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    locationData,
    facilitySettings,
  );

  useEffect(() => {
    if (gridApiRef.current) {
      const groupedRows = gridExpandedRowCountSelector(gridApiRef);
      setAutoHeight(groupedRows < MAX_ROWS_TO_DISPLAY);
    }
  }, [gridApiRef, groupingState, locationData.rowData]);

  if (!locationData.rowData) {
    return null;
  }

  const handleStateChange = () => {
    if (gridApiRef.current) {
      const newGroupingState = gridApiRef.current.state.rowGrouping;
      if (newGroupingState !== groupingState) {
        setGroupingState(newGroupingState);
      }
    }
  };

  return (
    <DataGridPremium
      className={classes.root}
      apiRef={gridApiRef}
      rows={transformBarcodeRows(locationData)}
      columns={barcodeColumns({ barcodeMatchLogic, issueLogic })}
      hideFooter
      rowGroupingColumnMode="single"
      disableRowSelectionOnClick
      disableAggregation
      autoHeight={autoHeight}
      onStateChange={handleStateChange}
    />
  );
};
