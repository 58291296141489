import { zoneValueToLocaleFixedDecimalPlaces } from 'shared/map-container/utils/3DmapFunctions';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { BottomLeftDimensions, Vec6 } from 'shared/map/model/geometry.model';
import { toFixed2 } from 'shared/numberFormatting';

export const validateSizeAndPositionFitsWithinWorldBox = ({
  zoneVectors,
  facilityVectors,
  fieldKey,
}: {
  zoneVectors: BottomLeftDimensions;
  facilityVectors: Vec6;
  fieldKey: keyof BottomLeftDimensions;
}): string | null => {
  const {
    w: zoneWidth,
    l: zoneLength,
    minX: zoneMinX,
    minY: zoneMinY,
    minZ: zoneMinZ,
    maxZ: zoneMaxZ,
  } = zoneVectors;

  const {
    minX: facilityMinX,
    minY: facilityMinY,
    minZ: facilityMinZ,
    maxX: facilityMaxX,
    maxY: facilityMaxY,
    maxZ: facilityMaxZ,
  } = mapFacilityVectors(facilityVectors);

  switch (fieldKey) {
    case 'w': {
      const validationMaxX = toFixed2(zoneMinX + zoneWidth);
      const deltaX = toFixed2(validationMaxX - facilityMaxX);

      if (zoneWidth < 0) {
        return '"Width" must be a positive number';
      }

      if (validationMaxX > facilityMaxX) {
        return `"Width" of ${zoneWidth}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaX,
        )}m`;
      }

      return null;
    }
    case 'l': {
      const validationMaxY = toFixed2(zoneMinY + zoneLength);
      const deltaY = toFixed2(validationMaxY - facilityMaxY);

      if (zoneLength < 0) {
        return '"Length" must be a positive number';
      }

      if (validationMaxY > facilityMaxY) {
        return `"Length" of ${zoneLength}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaY,
        )}m`;
      }

      return null;
    }
    case 'minX': {
      const validationMaxX = toFixed2(zoneMinX + zoneWidth);
      const isBelowMin = zoneMinX < facilityMinX;
      const isAboveMax = validationMaxX > facilityMaxX;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinX = toFixed2(facilityMinX - zoneMinX);
      const deltaMaxX = toFixed2(validationMaxX - facilityMaxX);
      const validationValue = isBelowMin ? deltaMinX : deltaMaxX;

      return `"X-Position" of ${zoneMinX}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
        validationValue,
      )}m`;
    }
    case 'minY': {
      const validationMaxY = toFixed2(zoneMinY + zoneLength);
      const isBelowMin = zoneMinY < facilityMinY;
      const isAboveMax = validationMaxY > facilityMaxY;

      if (!isBelowMin && !isAboveMax) {
        return null;
      }

      const deltaMinY = toFixed2(facilityMinY - zoneMinY);
      const deltaMaxY = toFixed2(validationMaxY - facilityMaxY);
      const validationValue = isBelowMin ? deltaMinY : deltaMaxY;

      return `"Y-Position" of ${zoneMinY}m places this zone outside of the facility by ${zoneValueToLocaleFixedDecimalPlaces(
        validationValue,
      )}m`;
    }
    case 'minZ': {
      const isBelowMin = zoneMinZ < facilityMinZ;
      const isAboveMax = zoneMinZ > facilityMaxZ;
      const isAboveZoneMax = zoneMinZ > zoneMaxZ;

      if (!isAboveZoneMax && !isBelowMin && !isAboveMax) {
        return null;
      }

      if (isAboveZoneMax) {
        return '"Bottom" value cannot be higher than the "top" value';
      }

      const deltaMinZ = toFixed2(facilityMinZ - zoneMinZ);
      const deltaMaxZ = toFixed2(zoneMinZ - facilityMaxZ);

      if (isBelowMin) {
        return `"Bottom" of ${zoneMinZ}m places this zone below the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaMinZ,
        )}m`;
      }

      return `"Bottom" of ${zoneMinZ}m places this zone above the facility by ${zoneValueToLocaleFixedDecimalPlaces(
        deltaMaxZ,
      )}m`;
    }
    case 'maxZ': {
      const isBelowMin = zoneMaxZ < facilityMinZ;
      const isAboveMax = zoneMaxZ > facilityMaxZ;
      const isBelowZoneMin = zoneMaxZ < zoneMinZ;

      if (isBelowZoneMin) {
        return '"Top" value cannot be lower than the "bottom" value';
      }

      if (isAboveMax) {
        const deltaMaxZAbove = toFixed2(zoneMaxZ - facilityMaxZ);

        return `"Top" of ${zoneMaxZ}m places this zone above the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaMaxZAbove,
        )}m`;
      }

      if (isBelowMin) {
        const deltaMaxZBelow = toFixed2(facilityMinZ - zoneMaxZ);

        return `"Top" of ${zoneMaxZ}m places this zone below the facility by ${zoneValueToLocaleFixedDecimalPlaces(
          deltaMaxZBelow,
        )}m`;
      }

      return null;
    }
    default:
      return null;
  }
};
