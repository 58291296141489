import TableCell from '@mui/material/TableCell';
import Highlighter from 'react-highlight-words';
import { ISSUE_BADGE_COLORS, ISSUE_BADGE_COLORS_KEYS } from 'common/colors';
import Badge from 'components/common/Badge';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
type EnhancedTableCellBadgeProps = EnhancedTableCellProp<any> & {
  searchTerm: string;
};

export const EnhancedTableCellBadge = (props: EnhancedTableCellBadgeProps) => {
  const { cell, row, searchTerm, onClick } = props;

  if (cell === undefined) {
    console.debug(
      'EnhancedTableCellBadge',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} onClick={onClick}>
      <Badge
        bgcolor={ISSUE_BADGE_COLORS[row[cell.id] as ISSUE_BADGE_COLORS_KEYS].background}
        color={ISSUE_BADGE_COLORS[row[cell.id] as ISSUE_BADGE_COLORS_KEYS].text}
        bigBadge={false}
      >
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={[searchTerm]}
          autoEscape={true}
          textToHighlight={row[cell.id]}
        />
      </Badge>
    </TableCell>
  );
};
