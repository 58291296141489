import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import RotateRight from '@mui/icons-material/RotateRight';
import AddIcon from '@mui/icons-material/Add';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { memo } from 'react';
import { useStyles } from './MapControls.styles';
import { MapControlsProps } from './MapControls.model';

export const MapControls = memo(({ zoomer, zoomSteps }: MapControlsProps) => {
  const { classes, cx } = useStyles();

  const zoomStep = Math.round(zoomSteps / 4);

  const handleResetZoom = () => {
    zoomer.resetZoom();
  };

  const handleZoomIn = () => {
    zoomer.zoom(-zoomStep, true);
  };

  const handleZoomOut = () => {
    zoomer.zoom(zoomStep, true);
  };

  const handleRotateView = () => {
    console.warn('Feature currently not implemented');
  };

  return (
    <div className={classes.wrapper}>
      <IconButton
        className={cx(classes.button, classes.buttonHidden)}
        disabled
        aria-label="Rotate the map view 90 degrees clock-wise"
        size="small"
        onClick={handleRotateView}
      >
        <RotateRight />
      </IconButton>

      <IconButton
        className={classes.button}
        aria-label="Reset the zoom level of the map view"
        size="small"
        onClick={handleResetZoom}
      >
        <CenterFocusWeakIcon />
      </IconButton>

      <IconButton
        className={classes.button}
        aria-label="Increase the zoom level in the map view"
        size="small"
        onClick={handleZoomIn}
      >
        <AddIcon />
      </IconButton>

      <IconButton
        className={classes.button}
        aria-label="Decrease the zoom level in the map view"
        size="small"
        onClick={handleZoomOut}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
});
