import { CommissioningTaskState } from '../model/commissioningTask.model';

export enum CommissioningTaskTab {
  ALL,
  UPCOMING,
  ONGOING,
  COMPLETED,
  ABORTED,
}

export const commissioningTaskStateFromTab: (
  commissioningTaskTab: CommissioningTaskTab,
) => CommissioningTaskState | undefined = (commissioningTaskTab) => {
  const tabLabels = [
    undefined,
    CommissioningTaskState.CREATED,
    CommissioningTaskState.ONGOING,
    CommissioningTaskState.COMPLETED,
    CommissioningTaskState.ABORTED,
  ];
  return tabLabels[commissioningTaskTab];
};
