import { LocalStore } from 'common/functions/storageFunctions';
import { ClientLevelAction } from 'store/ClientLevelStore/ClientLevelActions';
import { FacilityLevelAction } from 'store/FacilityLevelStore/facilityLevelActions';
import { IClientModalsAction } from 'store/Modals/clientModals/IClientModalsActions';
import { IFacilityModalsAction } from 'store/Modals/facilityModals/IFacilityModalsActions';
import { ClientModalsActionTypes, FacilityModalsActionTypes } from 'store/Modals/types';
import { UserLevelAction, UserLevelActionNames } from 'store/UserLevelStore/userLevelActions';

export const resetStoreState = (
  dispatchFacilityLevel: React.Dispatch<FacilityLevelAction>,
  dispatchClientLevel: React.Dispatch<ClientLevelAction>,
  dispatchUserLevelStore: (action: UserLevelAction) => void,
  dispatchFacilityModals: (value: IFacilityModalsAction) => void,
  dispatchClientModals: (value: IClientModalsAction) => void,
) => {
  LocalStore.cleanStateAndLocalStorage({
    dispatchFacilityLevel,
    dispatchClientLevel,
    dispatchUserLevelStore,
  });
  dispatchFacilityModals({ type: FacilityModalsActionTypes.RESET });
  dispatchClientModals({ type: ClientModalsActionTypes.RESET });
  dispatchUserLevelStore({ type: UserLevelActionNames.SIGNOUT });
};
