import moment from 'moment/moment';
import { OperatingHoursRange } from './operatingHours.model';
import { HOURS_AND_MINUTES_FORMAT } from '../../../../../../common/datetimeFormats';

/**
 * Turns OperatingHoursEvent into human readable string.
 * @param hours OperatingHoursRange
 * @returns Day HH:mm - Day HH:mm formatted string.
 */
export const transformOperatingHoursToString = (hours: OperatingHoursRange): string => {
  const { start, end } = hours;
  const startDay = moment.weekdays(start.weekDay);
  const startTime = moment(start.timePoint).format(HOURS_AND_MINUTES_FORMAT);
  const endDay = moment.weekdays(end.weekDay);
  const endTime = moment(end.timePoint).format(HOURS_AND_MINUTES_FORMAT);

  return `${startDay} ${startTime} - ${endDay} ${endTime}`;
};
