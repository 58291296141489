import { createSvgIcon } from '@mui/material/utils';
import { Box } from 'components/common/Box';

interface IIconProps {
  isActive: boolean;
  colorActive: string;
  colorInactive: string;
}

export const DroneOnChargerIcon = ({ isActive, colorActive, colorInactive }: IIconProps) => {
  const Icon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 8.49383C6 8.73443 6.21087 8.92059 6.44961 8.89074L9.64961 8.49074C9.84978 8.46572 10 8.29556 10 8.09383V7.80006C10 7.59833 9.84978 7.42817 9.64961 7.40315L6.44961 7.00315C6.21087 6.97331 6 7.15946 6 7.40006V8.49383Z"
        fill={isActive ? colorActive : colorInactive}
      />
      <path
        d="M4 7.40006C4 7.15946 3.78913 6.97331 3.55039 7.00315L0.350386 7.40315C0.150215 7.42817 0 7.59833 0 7.80006V8.09383C0 8.29556 0.150215 8.46572 0.350386 8.49074L3.55039 8.89074C3.78913 8.92059 4 8.73443 4 8.49383V7.40006Z"
        fill={isActive ? colorActive : colorInactive}
      />
      <path
        d="M18 7.40006C18 7.15946 17.7891 6.97331 17.5504 7.00315L14.3504 7.40315C14.1502 7.42817 14 7.59833 14 7.80006V8.09383C14 8.29556 14.1502 8.46572 14.3504 8.49074L17.5504 8.89074C17.7891 8.92059 18 8.73443 18 8.49383V7.40006Z"
        fill={isActive ? colorActive : colorInactive}
      />
      <path
        d="M20.4496 8.89074C20.2109 8.92059 20 8.73443 20 8.49383V7.40006C20 7.15946 20.2109 6.97331 20.4496 7.00315L23.6496 7.40315C23.8498 7.42817 24 7.59833 24 7.80006V8.09383C24 8.29556 23.8498 8.46572 23.6496 8.49074L20.4496 8.89074Z"
        fill={isActive ? colorActive : colorInactive}
      />
      <path
        d="M4.5 9.94696C4.22386 9.94696 4 10.1708 4 10.447V11.947C3.44772 11.947 3 12.3947 3 12.947C3 13.4992 3.44772 13.947 4 13.947H6.25439C6.5051 14.3064 6.78868 14.6412 7.10076 14.947C6.0283 15.9979 5.29253 17.3912 5.07025 18.9499C4.99229 19.4967 5.4475 19.947 5.99979 19.947C6.55207 19.947 6.98936 19.4947 7.09851 18.9533C7.32309 17.8395 7.91784 16.8597 8.74889 16.1479C9.72028 16.6582 10.8263 16.947 11.9997 16.947C13.1732 16.947 14.2792 16.6582 15.2506 16.1479C16.0817 16.8597 16.6765 17.8395 16.9011 18.9533C17.0102 19.4947 17.4475 19.947 17.9998 19.947C18.5521 19.947 19.0073 19.4967 18.9293 18.9499C18.707 17.3911 17.9712 15.9979 16.8987 14.9469C17.2108 14.6411 17.4943 14.3064 17.745 13.947H20C20.5523 13.947 21 13.4992 21 12.947C21 12.3947 20.5523 11.947 20 11.947V10.447C20 10.1708 19.7761 9.94696 19.5 9.94696H18.5C18.2239 9.94696 18 10.1708 18 10.447V11.947H6V10.447C6 10.1708 5.77614 9.94696 5.5 9.94696H4.5Z"
        fill={isActive ? colorActive : colorInactive}
      />
      <path
        d="M2.3 23C2.13431 23 2 22.8657 2 22.7V18.9908C2 18.694 2.38497 18.5775 2.54961 18.8244L3.91094 20.8664C3.96658 20.9499 4.06025 21 4.16055 21H19.8394C19.9398 21 20.0334 20.9499 20.0891 20.8664L21.4504 18.8244C21.615 18.5775 22 18.694 22 18.9908V22.7C22 22.8657 21.8657 23 21.7 23H2.3Z"
        fill={isActive ? colorActive : colorInactive}
      />
    </svg>,
    'DronOnChargerIcon',
  );
  return (
    <Box>
      <Icon />
    </Box>
  );
};
