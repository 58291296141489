import { Button, Typography } from '@mui/material';
import { droneZoneCreatorStyle } from './droneZoneCreator.style';

export const DroneZoneCreator = ({ onCancel }: { onCancel: () => void }) => {
  const { classes } = droneZoneCreatorStyle();

  return (
    <div className={classes.creationPlaceholderWrapper}>
      <div className={classes.creationPlaceholderBody}>
        <Typography mb={7}>Draw a new drone zone on the map.</Typography>

        <Button variant="outlined" onClick={onCancel} color="primary" fullWidth size="medium">
          Cancel
        </Button>
      </div>
    </div>
  );
};
