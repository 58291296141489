import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { weekDayIndexTo3Letter } from 'common/weekDays';
import { HOURS_AND_MINUTES_FORMAT } from 'common/datetimeFormats';
import { Box } from 'components/common/Box';
import { useStyles } from './flightHoursItem.style';
import { OperatingHoursRange } from '../../model/operatingHours.model';

export const FlightHoursItem = ({
  hours: { start, end },
  remove,
  edit,
  isReadOnlyMode,
}: {
  hours: OperatingHoursRange;
  remove: () => void;
  edit: () => void;
  isReadOnlyMode?: boolean;
}) => {
  const { classes } = useStyles();

  const startDay = weekDayIndexTo3Letter(start.weekDay);
  const startTime = moment(start.timePoint).format(HOURS_AND_MINUTES_FORMAT);
  const endDay = weekDayIndexTo3Letter(end.weekDay);
  const endTime = moment(end.timePoint).format(HOURS_AND_MINUTES_FORMAT);

  return (
    <Box className={classes.flightHoursItem}>
      <Typography className={classes.itemText}>
        {`${startDay} ${startTime}`}
        <span className={classes.wideDash}> - </span>
        {`${endDay} ${endTime}`}
      </Typography>

      {!isReadOnlyMode && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <IconButton
            onClick={() => edit()}
            aria-label="flight-hours-edit-item"
            data-testid="c-flight-hours-edit-item"
            size="large"
          >
            <EditIcon />
          </IconButton>

          <IconButton
            onClick={() => remove()}
            aria-label="flight-hours-remove-item"
            data-testid="c-flight-hours-remove-item"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </Box>
  );
};
