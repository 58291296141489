import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { locationStyles } from '../styles';
import { AmendValuesNoOverride } from './AmendValuesNoOverride';
import { AmendValuesOverrideForm } from './override-form/AmendValuesOverrideForm';

type AmendValuesProps = {
  locationData: ILocationData;
  setSpinnerLoading: (value: React.SetStateAction<boolean>) => void;
  refreshLocationData: (locationLabel: string) => void;
  setDidUserOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
  currentLocationIndex: number;
  isExpanded?: boolean;
  toggledCallback: (isOpen: boolean) => void;
};

export const AmendValues = ({
  locationData,
  currentLocationIndex,
  setSpinnerLoading,
  refreshLocationData,
  setDidUserOverwrite,
  isExpanded,
  toggledCallback,
}: AmendValuesProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const { classes } = locationStyles();
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;
  const showAmendForm = facilitySettings.allow_user_amend || false;

  useEffect(() => {
    if (isExpanded !== undefined) {
      setIsAccordionOpen(isExpanded);
    }
  }, [isExpanded]);

  return (
    <Paper className={classes.feedbackDrawerPaper} elevation={0}>
      <Accordion
        className="customAccordion"
        expanded={isAccordionOpen}
        onChange={(event: React.SyntheticEvent, expanded: boolean) => {
          toggledCallback(!isAccordionOpen);
          setIsAccordionOpen((isOpen: boolean) => !isOpen);
        }}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            align="right"
            className={classes.accordionTitle}
            color="textSecondary"
            component="p"
          >
            Edit content found
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {showAmendForm && locationData.verityState === '-' && <AmendValuesNoOverride />}
          {showAmendForm && locationData.verityState !== '-' && (
            <AmendValuesOverrideForm
              currentLocationIndex={currentLocationIndex}
              locationData={locationData}
              setSpinnerLoading={setSpinnerLoading}
              refreshLocationData={refreshLocationData}
              setDidUserOverwrite={setDidUserOverwrite}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
