import { IChangeControlledZonePermissionRequestST } from 'codegen/controlled_zone';
import { initControlledZoneService } from './service';

export const setControlledZoneActive = ({
  systemId,
  flightDomainId,
  controlledZoneId,
  controlledZone,
  signal,
}: {
  systemId: string;
  flightDomainId: string;
  controlledZoneId: string;
  controlledZone: IChangeControlledZonePermissionRequestST;
  signal: AbortSignal;
}) =>
  initControlledZoneService().setControlledZoneActive(
    systemId,
    flightDomainId,
    controlledZoneId,
    controlledZone,
    { signal },
  );
