import { Box, Tooltip, Typography } from '@mui/material';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { DATETIME_FORMAT_WITH_WEEKDAY } from 'common/datetimeFormats';
import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import TooltipedIcon from 'components/common/TooltipedIcon';
import moment from 'moment';

export const LastWmsUpdate = ({ wmsDate }: { wmsDate: string }) => (
  <Box component="div" display="flex" flexDirection="row" gap={1} alignItems="center">
    <TooltipedIcon
      noMargin
      tooltip="Latest WMS data point for this location"
      icon={<WarehouseIcon sx={{ color: 'GrayText' }} />}
    />
    {wmsDate && wmsDate !== '-' ? (
      <Tooltip title={formatDateInTimezone(wmsDate, DATETIME_FORMAT_WITH_WEEKDAY) || ''}>
        <Box component="div" display="flex" flexDirection="row" gap={1}>
          <Typography data-testid="c-loc-mod-wms-update-label" variant="body2" color="GrayText">
            Last WMS Update
          </Typography>
          <Typography data-testid="c-loc-mod-wms-update-date" variant="body2" color="inherit">
            {moment(wmsDate).fromNow()}
          </Typography>
        </Box>
      </Tooltip>
    ) : (
      <Typography data-testid="c-loc-mod-wms-update-date" variant="body2" color="inherit">
        Never updated
      </Typography>
    )}
  </Box>
);
