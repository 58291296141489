import { userHasPermission } from '../../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../../features/permissions/permissions.model';

export const isStatusBarSwitchEnabled = (canUnlock: boolean = false, isLocked: boolean = false) => {
  const canFDGetUnlocked = canUnlock && isLocked;

  const canUserLockSystem = userHasPermission(PERMISSION.LOCK_SYSTEM) && !isLocked;
  const canUserUnlockSystem = userHasPermission(PERMISSION.UNLOCK_SYSTEM) && canFDGetUnlocked;

  return canUserLockSystem || canUserUnlockSystem;
};
