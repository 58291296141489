import { createSvgIcon } from '@mui/material/utils';

interface IIconProps {
  color: string;
  fontSize?: string;
}

export const NoAccessIcon = ({ color, fontSize }: IIconProps) => {
  const Icon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M 12.64 7.52 c 0.704 0 1.28 -0.576 1.28 -1.28 s -0.576 -1.28 -1.28 -1.28 s -1.28 0.576 -1.28 1.28 s 0.576 1.28 1.28 1.28 z M 10.272 9.696 L 8.48 18.72 h 1.344 l 1.152 -5.12 l 1.344 1.28 v 3.84 h 1.28 v -4.8 l -1.344 -1.28 l 0.384 -1.92 C 13.472 11.68 14.752 12.32 16.16 12.32 v -1.28 c -1.216 0 -2.24 -0.64 -2.752 -1.536 l -0.64 -1.024 c -0.256 -0.384 -0.64 -0.64 -1.088 -0.64 c -0.192 0 -0.32 0.064 -0.512 0.064 L 7.84 9.312 V 12.32 h 1.28 V 10.144 l 1.152 -0.448" />
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z" />
    </svg>,
    'DroneIcon',
  );
  return <Icon sx={{ fontSize }} aria-label="drone-icon" />;
};
