import { Tooltip, Typography, Theme, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  toolbarName: {
    display: 'block',
    paddingInlineStart: '.5em',
    fontSize: '13px',
    fontWeight: 500,
  },
}));

export const GridPremiumToolbarButton = ({
  name,
  tooltip,
  icon,
  disabled,
  onClick,
}: {
  name: string;
  tooltip: string;
  icon: JSX.Element;
  disabled?: boolean;
  onClick: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Tooltip title={tooltip}>
      <span>
        <Button variant="text" size="small" disabled={disabled} onClick={onClick}>
          {icon}
          <Typography component="span" className={classes.toolbarName}>
            {name}
          </Typography>
        </Button>
      </span>
    </Tooltip>
  );
};
