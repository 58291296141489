import { AlwaysDepth } from 'three';
import { mapStyle } from '../../map-container/features/map-canvas/MapCanvas.style';
import { makeTHREEMaterial, makeTexture } from '../../map-container/utils/3DmapFunctions';

export const droneZoneDrawMaterial = (width: number, length: number) => {
  const color = mapStyle.noFlyZone.creation.backgroundColor.primary;

  const texture = makeTexture({
    textLines: [],
    includeBorder: true,
    backgroundColor: color,
    proportion: width / length,
    borderColor: color,
    dashed: false,
    hiRes: true,
    fovSpan: {
      horizontalFOV: 0,
      verticalFOV: 0,
    },
  });

  const stencil = makeTexture({
    textLines: [],
    proportion: width / length,
    borderColor: 'white',
    opacity: 0.4,
    includeBorder: true,
    hiRes: true,
    dashed: false,
    fovSpan: {
      horizontalFOV: 0,
      verticalFOV: 0,
    },
  });

  return makeTHREEMaterial({
    color,
    texture,
    alphaMap: stencil,
    depthTest: false,
    depthFunc: AlwaysDepth,
  });
};
