import { Typography } from '@mui/material';
import { droneZonePlaceholderStyle } from './droneZonePlaceholder.styles';

export const DroneZonePlaceholder = ({
  children,
  message,
}: {
  children?: React.ReactNode;
  message: string;
}) => {
  const { classes } = droneZonePlaceholderStyle();

  return (
    <div className={classes.wrapper}>
      <Typography>{message}</Typography>

      {children && <div>{children}</div>}
    </div>
  );
};
