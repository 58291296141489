import { useState } from 'react';
import { Box } from 'components/common/Box';
import AlertComponent from 'components/common/Alert';
import { LocationsModal } from '../modals/LocationsModal';
import { useStyles } from './styles';
import { LocationsToolbarUploader } from './LocationsToolbarUploader';
import { EXCLUSION_STATUS_STATES } from './exclusionStatus';
import { LocationsToolbarActions } from './LocationsToolbarActions';
import { LocationsToolbarProps } from '../../models/LocationsToolbarProps';

/**
 * Locations toolbar
 * @param props LocationsToolbarProps
 * @returns JSX.Element
 */
export const LocationsToolbar = ({
  onUpdateExclusionStatus,
  numberOfSelectedRows,
  uploadedFile,
  allRows,
  hasWmsHeader,
  selectedLocationIds,
  onUploadedFile,
  onSelectedIds,
}: LocationsToolbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [exclusionStatus, setExclusionStatus] = useState('');
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');
  const { classes, cx } = useStyles();

  const toggleOpen = () => setIsOpen((prevState) => !prevState);

  /**
   * Handles exclusion action click
   * @param exclusionStatus exclusion status
   */
  const handleActionClick = (exclusionStatus: string) => {
    setExclusionStatus(exclusionStatus);
    toggleOpen();
  };

  /**
   * Clears upload
   */
  const handleClearUploads = () => {
    onUploadedFile(null);
    onSelectedIds([]);
  };

  const isFileUploaded = !!uploadedFile;
  const shouldDisableButton = !isFileUploaded && !numberOfSelectedRows;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={cx({ [classes.filesUploaded]: isFileUploaded })}
      >
        <LocationsToolbarUploader
          setUploadErrorMessage={setUploadErrorMessage}
          setSelectedIds={onSelectedIds}
          setUploadedFile={onUploadedFile}
          onClearUploads={handleClearUploads}
          shouldDisableUpload={!!numberOfSelectedRows}
          isFileUploaded={isFileUploaded}
          fileName={uploadedFile?.name}
        />

        <LocationsToolbarActions
          allRows={allRows}
          hasWmsHeader={hasWmsHeader}
          selectedLocationIds={selectedLocationIds}
          shouldDisableButton={shouldDisableButton}
          onUpdateExclusionStatus={onUpdateExclusionStatus}
          onActionClick={handleActionClick}
        />
      </Box>

      <AlertComponent
        isActive={!!uploadErrorMessage}
        message={uploadErrorMessage}
        autoClose={15}
        status="error"
        handleClose={() => setUploadErrorMessage('')}
        preventClose={false}
      />
      {isOpen && (
        <LocationsModal
          onClose={toggleOpen}
          onSubmit={onUpdateExclusionStatus}
          numOfLocations={numberOfSelectedRows}
          exclusionState={EXCLUSION_STATUS_STATES[exclusionStatus]}
        />
      )}
    </>
  );
};
