import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { IUserOverrideParams } from '../interfaces';
import { getImageService, getIssueSnoozeService, getUserOverrideService } from './services';

const getImages = (systemId: string, slotLabel: string, slotVersion: number, isReview: boolean) =>
  getImageService().getImageURL(
    systemId,
    slotLabel,
    slotVersion.toString(),
    isReview ? 'true' : 'false',
  );

const userOverride = ({ systemId, data, slot_label, version, isReview }: IUserOverrideParams) =>
  getUserOverrideService().postUserOverride(
    systemId,
    slot_label,
    version.toString(),
    data,
    isReview ? 'true' : 'false',
  );

/**
 * Snooze / Un-snooze issues
 * @param systemId System ID for the current Facility
 * @param issueId ID of the Issue
 * @param type action type (snooze/un-snooze)
 * @returns a promise which will resolve if the operation is successful
 */
const toggleSnoozeIssue = (systemId: string, issueId: string, type: ISSUE_ACTIONS) => {
  if (type === ISSUE_ACTIONS.SNOOZE) return getIssueSnoozeService().snoozeIssue(systemId, issueId);
  return getIssueSnoozeService().unsnoozeIssue(systemId, issueId);
};

const issueServices = {
  userOverride,
  toggleSnoozeIssue,
  getImages,
};

export default issueServices;
