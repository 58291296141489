import moment from 'moment';
import 'moment-timezone';
import { LocalStore } from 'common/functions/storageFunctions';
import {
  OperatingHours,
  OperatingHoursEventBoundaryST,
  OperatingHoursEventST,
  OperatingHoursRange,
} from './operatingHours.model';
import { HOURS_AND_MINUTES_FORMAT } from '../../../../../../common/datetimeFormats';

const timezone = LocalStore.getTimezone();

export const transformOperatingHoursRangeFromDTO = (
  operatingHoursDTO: OperatingHoursEventST,
): OperatingHoursRange => ({
  start: transformOperatingHoursFromDTO(operatingHoursDTO.start),
  end: transformOperatingHoursFromDTO(operatingHoursDTO.end),
});
const transformOperatingHoursFromDTO = (bound: OperatingHoursEventBoundaryST): OperatingHours => ({
  weekDay: bound.week_day,
  timePoint: moment(bound.timepoint, HOURS_AND_MINUTES_FORMAT).tz(timezone),
});
export const transformOperatingHoursRangeToDTO = (
  operatingHoursRange: OperatingHoursRange,
): OperatingHoursEventST => ({
  start: transformOperatingHoursToDTO(operatingHoursRange.start),
  end: transformOperatingHoursToDTO(operatingHoursRange.end),
});
export const transformOperatingHoursToDTO = (
  operatingHours: OperatingHours,
): OperatingHoursEventBoundaryST => ({
  week_day: operatingHours.weekDay === 0 ? 7 : operatingHours.weekDay,
  timepoint: operatingHours.timePoint
    ? operatingHours.timePoint.format(HOURS_AND_MINUTES_FORMAT)
    : '',
});
