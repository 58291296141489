import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { validBarcodeInvalidLength } from 'common/functions/otherFunctions';
import { Box } from 'components/common/Box';

export const BarcodeLengths = ({
  subtitle,
  onBarcodeLengthChanges,
  barcodeLengths,
  min,
  max,
  errors,
  setErrors,
}: {
  subtitle: string;
  onBarcodeLengthChanges: any;
  barcodeLengths: number[];
  min: number;
  max: number;
  errors: string;
  setErrors: (val: string) => void;
}) => {
  const [newBarcodeLengths, setNewBarcodeLengths] = useState<number | string>('');
  const [lengths, setLengths] = useState<number[]>(barcodeLengths);
  const inputType = 'number';

  const handleValue = (e: any) => {
    const len = parseInt(e.target.value, 10);
    if (setErrors && min && max) {
      setErrors(validBarcodeInvalidLength(len, min, max));
    }
    setNewBarcodeLengths(len);
  };

  const addLengths = () => {
    setNewBarcodeLengths('');
    onBarcodeLengthChanges([...lengths, Number(newBarcodeLengths)]);
  };

  const removeLength = (length: number) => {
    const copyArr: any[] = barcodeLengths;
    onBarcodeLengthChanges(copyArr.filter((item: number) => item !== length));
  };

  useEffect(() => {
    if (barcodeLengths) {
      setLengths(barcodeLengths);
    }
  }, [barcodeLengths]);

  return (
    <>
      <Divider />

      <Box pt={1}>
        <Box p={2}>
          <Typography variant="h6" color="secondary">
            {subtitle}
          </Typography>
        </Box>

        <Box px={2} pt={1} pb={2}>
          {!isEmpty(lengths) ? (
            lengths.map((type) => (
              <Box justifyContent="space-between" alignItems="center" display="flex" key={type}>
                <Typography>{type}</Typography>
                <IconButton
                  aria-label="remove-barcode-length-button"
                  onClick={() => removeLength(type)}
                  color="error"
                  size="large"
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Box>
            ))
          ) : (
            <Typography align="center" variant="subtitle1" color="textSecondary" component="p">
              No barcode invalid lengths
            </Typography>
          )}
        </Box>

        <Divider />

        <Box px={2} justifyContent="space-between">
          <Box pt={3} display="flex" justifyContent="space-between">
            <TextField
              label="Enter barcode invalid lengths"
              size="small"
              variant="outlined"
              fullWidth
              type={inputType}
              inputProps={{ min: 0 }}
              value={newBarcodeLengths}
              error={Boolean(newBarcodeLengths && errors)}
              helperText={newBarcodeLengths && errors}
              onChange={handleValue}
              onBlur={handleValue}
            />

            <IconButton
              disabled={!newBarcodeLengths || Boolean(errors)}
              aria-label="add-barcode-length-button"
              onClick={addLengths}
              color="primary"
              size="large"
            >
              <AddRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
