import { useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { Box } from 'components/common/Box';
import YesNoModal from 'components/ModalsAndPopups/YesNoModal';
import { EXCLUSION_STATUS } from './exclusionStatus';
import { LocationsToolbarActionsProps } from '../../models/LocationsToolbarActionsProps';

/**
 * Locations toolbar actions
 * @param props LocationsToolbarActionsProps
 * @returns JSX.Element
 */
export const LocationsToolbarActions = ({
  onActionClick,
  onUpdateExclusionStatus,
  shouldDisableButton,
  selectedLocationIds,
  hasWmsHeader,
  allRows,
}: LocationsToolbarActionsProps) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const toggleModal = () => setIsModalOpened((prevState) => !prevState);

  const locationsIdsWithOverride = allRows.filter((r) => r.udbExclusionStatus).map((r) => r.id);

  const selectedOverrides = locationsIdsWithOverride.filter((l) =>
    selectedLocationIds.some((sl) => sl === l),
  );

  const isRemoveOverridesButtonEnabled =
    selectedOverrides.length > 0 ||
    (selectedLocationIds.length === 0 && locationsIdsWithOverride.length > 0);

  const handleRemoveOverrides = () => {
    const idsToRemoveOverrides =
      selectedOverrides.length > 0 ? selectedOverrides : locationsIdsWithOverride;

    onUpdateExclusionStatus('', 'NOT_SET', idsToRemoveOverrides);
    toggleModal();
  };

  const locationsText = locationsIdsWithOverride.length === 1 ? 'location has' : 'locations have';
  const overrideLocations =
    locationsIdsWithOverride.length === 1 ? 'this location' : 'these locations';

  const bodyTextRemoveAll = (
    <>
      <Typography color="textSecondary">
        <strong>{locationsIdsWithOverride.length}</strong> {locationsText} permission override. By
        removing the override from {overrideLocations}, the permission value will be set to default.
      </Typography>
      <Typography color="textSecondary" mt={3}>
        Are you sure you want to remove all overrides?
      </Typography>
    </>
  );

  const plural = selectedOverrides.length > 1;

  const bodyTextRemoveSelected = (
    <>
      <Typography color="textSecondary">
        <strong>{locationsIdsWithOverride.length}</strong> {locationsText} permission override. Of
        those {locationsIdsWithOverride.length}, {selectedOverrides.length} {plural ? 'are' : 'is'}{' '}
        selected for change. By removing the override from{' '}
        {plural ? 'these locations' : 'this location'}, the permission value will be set to default.
      </Typography>
      <Typography color="textSecondary" mt={3}>
        Are you sure you want to remove overrides ({selectedOverrides.length} out of{' '}
        {locationsIdsWithOverride.length} total)?
      </Typography>
    </>
  );

  const bodyText = selectedOverrides.length ? bodyTextRemoveSelected : bodyTextRemoveAll;

  return (
    <>
      <Box display="flex" gap="8px">
        <Button
          variant="outlined"
          size="small"
          disabled={shouldDisableButton}
          onClick={() => onActionClick(EXCLUSION_STATUS.INCLUDE)}
        >
          Include
        </Button>

        <Button
          variant="outlined"
          size="small"
          disabled={shouldDisableButton}
          onClick={() => onActionClick(EXCLUSION_STATUS.EXCLUDE)}
        >
          Exclude
        </Button>

        {hasWmsHeader && (
          <>
            <Divider orientation="vertical" flexItem />
            <Button
              variant="outlined"
              size="small"
              disabled={!isRemoveOverridesButtonEnabled}
              onClick={toggleModal}
              sx={{ ml: 1 }}
            >
              Remove overrides
            </Button>
          </>
        )}
      </Box>

      {isModalOpened && (
        <YesNoModal
          bodyText={bodyText}
          onNoFunction={toggleModal}
          onYesFunction={handleRemoveOverrides}
          opened
          title="Remove overrides"
          subtitle={undefined}
          yesLabel="Remove"
          loading={false}
          noLabel="Cancel"
          yesCTA
        />
      )}
    </>
  );
};
