import { useRef, useState } from 'react';
import { Mesh, Vector3 } from 'three';
import { ThreeEvent, useFrame } from '@react-three/fiber';
import { useCursor } from '@react-three/drei';
import { mapStyle } from 'shared/map-container/features/map-canvas/MapCanvas.style';
import { meshRenderOrder } from 'shared/map/defaults/mesh-render-order';
import { ZoneBaseMeshSharedProps } from '../drone-zone-base-mesh/drone-zone-base-mesh.model';
import { DroneZoneBaseCircleMesh } from '../drone-zone-base-mesh/drone-zone-base-circle-mesh';
import { OverlappingZone } from '../../utils/zone-overlap/overlapping-zone.model';
import { MutableState } from '../../../map-container/reducer/MutableState';
import { generateSolidMaterial } from '../../utils/material-generators/generate-solid-material';

const DEFAULT_MAX_FONT_SIZE_IN_PX = 12;
const UPDATED_MAX_FONT_SIZE_IN_PX = 200;

export const DroneZoneGroupCountBadge = ({
  zones,
  options,
  position,
  isMapOverlayVisible,
  onClick,
  ...props
}: ZoneBaseMeshSharedProps & {
  zones: OverlappingZone['zones'];
  position: Vector3;
  isMapOverlayVisible?: boolean;
}) => {
  const ref = useRef<Mesh>(null!);
  const maxFontSizeRef = useRef(DEFAULT_MAX_FONT_SIZE_IN_PX);

  const [isHovering, setIsHovering] = useState(false);

  const { cameraState } = MutableState.getState();

  const size = 4;
  const hasMoreThanOneZone = zones.length > 1;
  const isZoneCountLabelVisible =
    hasMoreThanOneZone && zones.every((zone) => !zone.isSelected && !zone.isEditing);
  const color = isHovering
    ? options.styles.countBadge.active.backgroundColor.secondary
    : options.styles.countBadge.active.backgroundColor.primary;

  const material = generateSolidMaterial({
    opacity: 1,
    primaryColor: color,
  });

  const enableCursor = isHovering && !isMapOverlayVisible;
  useCursor(enableCursor);

  const handleClick = (evt: ThreeEvent<MouseEvent>) => {
    evt.stopPropagation();

    if (onClick) {
      onClick(evt);
    }
  };

  useFrame(() => {
    const fov = cameraState.currentOrthographicFOV;
    const scaleSize = isHovering ? 0.023 : 0.02;
    const scale = fov * scaleSize;
    const fontSize = fov < 5 ? UPDATED_MAX_FONT_SIZE_IN_PX : DEFAULT_MAX_FONT_SIZE_IN_PX;

    if (ref.current) {
      ref.current.scale.set(scale, scale, 1);
    }

    if (maxFontSizeRef.current !== fontSize) {
      maxFontSizeRef.current = fontSize;
    }
  });

  return isZoneCountLabelVisible ? (
    <DroneZoneBaseCircleMesh
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={() => setIsHovering(false)}
      ref={ref}
      label={zones.length.toString()}
      options={options}
      material={material}
      textColors={{ text: mapStyle.countBadge.active.textColor.primary }}
      position={position}
      minFontSizeInPx={10}
      maxFontSizeInPx={maxFontSizeRef.current}
      width={size}
      length={size}
      height={size}
      renderOrder={meshRenderOrder.groups}
      onClick={handleClick}
      {...props}
    />
  ) : null;
};
