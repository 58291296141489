import { initWarehouseStatusService } from './service';

/**
 * Warehouse status reset
 * @param systemId System ID (of the facility)
 * @param signal Abort Signal
 * @returns AxiosPromise<void>
 */
export const warehouseStatusReset = (systemId: string, signal: AbortSignal) =>
  initWarehouseStatusService().resetWarehouseStatus(systemId, { signal });
