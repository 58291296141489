import { DroneZoneTypes } from '../model/drone-zones.model';

export const inProgressDroneZone = {
  id: 'creation-in-progress',
  name: 'no name',
  type: DroneZoneTypes.controlledZone,
  description: '',
  sizeAndPosition: { w: 0, l: 0, h: 0, minX: 0, minY: 0, minZ: 0, maxZ: 0 },
  isSystemLocked: true,
  isActive: false,
  isSelected: false,
};
