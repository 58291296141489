import React from 'react';

// material-ui core
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// material-ui icons
import InboxIcon from '@mui/icons-material/MoveToInbox';

// components
import { Box } from 'components/common/Box';

interface IDeveloperDrawerButtonProps {
  testId?: string;
  buttonLabel: string;
  clickHandler?: React.MouseEventHandler<HTMLDivElement>;
  content?: JSX.Element;
  disabled?: boolean;
}

export const DeveloperDrawerButton = (props: IDeveloperDrawerButtonProps) => {
  const { testId, disabled, buttonLabel, clickHandler, content } = props;

  return (
    <Box display="flex" gap="8px">
      <ListItem disabled={disabled} data-testid={testId} onClick={clickHandler} button>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={buttonLabel} />
      </ListItem>
      {content}
    </Box>
  );
};
