import { LocalStore } from 'common/functions/storageFunctions';
import { SuperSetApi, Configuration } from 'codegen/superset';

/**
 * Init superset api service
 * @returns api service
 */
export const initSupersetAPIService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new Configuration({ apiKey: idToken });
  const apiService = new SuperSetApi(config);
  return apiService;
};
