import moment from 'moment/moment';
import {
  OperatingHours,
  OperatingHoursEventBoundaryST,
  OperatingHoursEventST,
  OperatingHoursRange,
} from '../model/operatingHours.model';
import {
  transformOperatingHoursRangeFromDTO,
  transformOperatingHoursRangeToDTO,
  transformOperatingHoursToDTO,
} from '../model/transformOperatingHours';
import { transformOperatingHoursToString } from '../model/transformOperatingHoursToString';

/**
 * Check if new shift overlaps with existing shifts.
 * @param schedule OperatingHoursEventST[]
 * @returns old shift new one overlaps with.
 */
export const validateHours =
  (schedule: OperatingHoursEventST[]) =>
  (
    newHours: OperatingHoursRange,
    edit?: OperatingHoursRange,
  ): [isValid: boolean, message: string] => {
    if (newHours.start.timePoint === '' || newHours.end.timePoint === '') {
      return [false, 'Please specify start and end time.'];
    }

    if (!moment(newHours.start.timePoint).isValid() || !moment(newHours.end.timePoint).isValid()) {
      return [false, 'Please enter the right hours format.'];
    }

    const newHoursST = transformOperatingHoursRangeToDTO(newHours);
    const editST = edit ? transformOperatingHoursRangeToDTO(edit) : null;

    /**
     * If we are editing a shift, we need to remove it from the schedule
     * to avoid self-overlap.
     */
    const deDupedSchedule = editST
      ? schedule.filter(
          (h) =>
            h.start.week_day !== editST.start.week_day ||
            h.end.week_day !== editST.end.week_day ||
            !h.start.timepoint.startsWith(editST.start.timepoint) ||
            !h.end.timepoint.startsWith(editST.end.timepoint),
        )
      : schedule;

    const overlap = doOverlap([...deDupedSchedule, newHoursST]);
    const otherEvent = overlap && (overlap.a === newHoursST ? overlap.b : overlap.a);
    if (otherEvent) {
      return [false, getValidationMessage(otherEvent)];
    }

    return [true, ''];
  };
export const doOverlap = (hs: OperatingHoursEventST[]) => {
  const normalizedAndSanitized = hs.reduce((acc, cur) => {
    cur = sanitizeTimepointStrings(cur);
    if (isBeforeST(cur.end, cur.start) || isSameST(cur.end, cur.start)) {
      acc.push({ start: zeroBoundary.start, end: cur.end, orig: cur });
      acc.push({ start: cur.start, end: zeroBoundary.end, orig: cur });
    } else {
      acc.push(cur);
    }
    return acc;
  }, [] as (OperatingHoursEventST & { orig?: OperatingHoursEventST })[]);

  const sorted = normalizedAndSanitized.sort((a, b) => (isBeforeST(a.start, b.start) ? -1 : 1));

  const { overlap } = sorted.reduce(
    (acc, curr) => {
      if (acc.overlap) {
        return acc;
      }
      if (!acc.previous) {
        acc.previous = curr;
        return acc;
      }
      if (isBeforeST(curr.start, acc.previous.end)) {
        acc.overlap = { a: acc.previous.orig || acc.previous, b: curr.orig || curr };
        return acc;
      }
      acc.previous = curr;
      return acc;
    },
    {
      previous: null as (OperatingHoursEventST & { orig?: OperatingHoursEventST }) | null,
      overlap: null as ValidationResult | null,
    },
  );

  return overlap;
};
/**
 * This function removes excess zeros (hh:mm:00.000000) from timepoint strings returned by BE
 * @param h OperatingHoursEventST to sanitize
 * @returns sanitized OperatingHoursEventST
 */
const sanitizeTimepointStrings = (h: OperatingHoursEventST): OperatingHoursEventST => {
  const startParts = h.start.timepoint.split(':');
  h.start.timepoint = `${startParts[0]}:${startParts[1]}`;
  const endParts = h.end.timepoint.split(':');
  h.end.timepoint = `${endParts[0]}:${endParts[1]}`;
  return h;
};
const zeroBoundary = {
  start: { week_day: 1, timepoint: '00:00' },
  end: { week_day: 7, timepoint: '24:00' },
};
const isSameST = <T extends OperatingHoursEventBoundaryST>(a: T, b: T): boolean =>
  a.week_day === b.week_day && a.timepoint === b.timepoint;
export const isBefore = <T extends OperatingHours>(a: T, b: T): boolean =>
  isBeforeST(transformOperatingHoursToDTO(a), transformOperatingHoursToDTO(b));
const isBeforeST = <T extends OperatingHoursEventBoundaryST>(a: T, b: T): boolean =>
  a.week_day < b.week_day || (a.week_day === b.week_day && a.timepoint < b.timepoint);

/**
 * Make a helpful message for user about overlapping shifts.
 * @param event shift that is overlapping.
 * @returns human readable string.
 */
export const getValidationMessage = (event: OperatingHoursEventST) => {
  const eventString = transformOperatingHoursToString(transformOperatingHoursRangeFromDTO(event));

  return `Overlap with ${eventString}`;
};

export type ValidationResult = {
  a: OperatingHoursEventST;
  b: OperatingHoursEventST;
};
