import { LocalStore } from 'common/functions/storageFunctions';
import { ControlledZonesApi, Configuration } from 'codegen/controlled_zone';

export const initControlledZoneService = () => {
  const idToken = LocalStore.getIdToken();
  const config = new Configuration({ apiKey: idToken });
  const apiService = new ControlledZonesApi(config);

  return apiService;
};
