import React, { createContext, useReducer, useContext } from 'react';
import { MapAction, mapReducer } from './3DmapReducer';
import { initialMapState, MapState } from './3DmapState';

/**
 * Interface for the MapStore context
 */
interface IMapContext {
  /**
   * The current state of the MapStore
   */
  mapState: MapState;
  /**
   * Dispatch function for the MapStore
   * @param {MapAction} action The action to dispatch
   */
  dispatchMapStore: React.Dispatch<MapAction>;
}

/**
 * Initial(empty) context of MapStore
 * Store state and dispatch function
 */
const initialContext: IMapContext = {
  mapState: initialMapState,
  dispatchMapStore: (_: MapAction) => {},
};

// store & hook to access it
const Store = createContext(initialContext);
export const useMapStore = () => useContext(Store);

// state provider
export const MapStoreProvider = ({ children }: any) => {
  const [stateMap, dispatchMapStore] = useReducer(mapReducer, initialMapState);

  return (
    <Store.Provider
      value={{
        mapState: stateMap,
        dispatchMapStore,
      }}
    >
      {children}
    </Store.Provider>
  );
};
