import { transformApiDroneZone } from 'udb/ground-control/drone-zones/model/transformApiDroneZone';
import { ZoneStatusActions } from './zoneStatusActions';
import { FlowState, NoFlyZonePageState } from './noFlyZonePageDefaultState';
import { transformIntoNoFlyZone } from '../../drone-zones/model/transformIntoNoFlyZone';
import { NEW_DRONE_ZONE_ID } from '../../../../shared/map/defaults/new-drone-zone-id';
import { deleteNoFlyZone } from '../../drone-zones/reducer/deleteNoFlyZone';
import { updateNoFlyZone } from '../../drone-zones/reducer/updateNoFlyZone';
import { notEver } from '../../../../common/functions/otherFunctions';

export const noFlyZoneStatusReducer = (
  state: NoFlyZonePageState,
  action: ZoneStatusActions,
): NoFlyZonePageState => {
  switch (action.type) {
    case 'SET_FLOW_STATE':
      return {
        ...state,
        flowState: action.payload,
      };
    case 'SET_NO_FLY_ZONES':
      return {
        ...state,
        noFlyZones: transformApiDroneZone(action.payload, transformIntoNoFlyZone),
      };
    case 'START_EDITING_NO_FLY_ZONE': {
      return {
        ...state,
        flowState: FlowState.CREATE,
        currentlySelectedZone: null,
        detailsZones: [],
        currentlyEditingZone: action.payload,
      };
    }
    case 'CANCEL_EDITING_NO_FLY_ZONE': {
      let noFlyZones;
      if (action.payload.id === NEW_DRONE_ZONE_ID) {
        noFlyZones = deleteNoFlyZone(action.payload, state.noFlyZones);
      } else {
        noFlyZones = updateNoFlyZone(action.payload, state.noFlyZones);
      }

      return {
        ...state,
        currentlyEditingZone: null,
        noFlyZones,
        flowState: FlowState.LIST,
      };
    }
    case 'SHOW_NO_FLY_ZONE_DELETION_MODAL': {
      return {
        ...state,
        currentlyDeletingZone: action.payload,
        flowState: FlowState.DELETE,
      };
    }
    case 'CLOSE_NO_FLY_ZONE_DELETION_MODAL': {
      return {
        ...state,
        currentlyDeletingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'SAVE_NO_FLY_ZONE_CHANGES': {
      return {
        ...state,
        currentlyEditingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'CONFIRM_NO_FLY_ZONE_DELETION_MODAL': {
      return {
        ...state,
        noFlyZones: deleteNoFlyZone(state.currentlyDeletingZone!, state.noFlyZones),
        detailsZones: state.detailsZones.filter((dz) => dz.id !== state.currentlyDeletingZone?.id),
        currentlyDeletingZone: null,
        flowState: FlowState.LIST,
      };
    }
    case 'UPDATE_NO_FLY_ZONE': {
      return {
        ...state,
        noFlyZones: updateNoFlyZone(action.payload, state.noFlyZones),
      };
    }
    case 'END_DRAGGING_DRONE_ZONE': {
      return {
        ...state,
        currentlyEditingZone: action.payload,
        noFlyZones: updateNoFlyZone(action.payload, state.noFlyZones),
      };
    }
    case 'DELETE_NO_FLY_ZONE': {
      return {
        ...state,
        noFlyZones: deleteNoFlyZone(action.payload, state.noFlyZones),
      };
    }

    case 'CREATION_OF_ZONE_DRAWING_START': {
      return {
        ...state,
        flowState: FlowState.DRAW,
        currentlySelectedZone: null,
        detailsZones: [],
      };
    }

    case 'SHOW_NO_FLY_ZONE_CREATION_FORM': {
      return {
        ...state,
        flowState: FlowState.CREATE,
        detailsZones: [],
        currentlyEditingZone: action.payload,
        currentlySelectedZone: action.payload,
        noFlyZones: updateNoFlyZone(action.payload, state.noFlyZones),
      };
    }

    case 'SELECT_SINGLE_NO_FLY_ZONE': {
      const isEditing = [FlowState.CREATE, FlowState.DRAW].includes(state.flowState);

      if (action.payload && !isEditing) {
        return {
          ...state,
          detailsZones: [action.payload],
          currentlySelectedZone: action.payload,
        };
      }

      return {
        ...state,
        detailsZones: [],
        currentlySelectedZone: null,
      };
    }

    case 'SELECT_SINGLE_FROM_NO_FLY_ZONE_GROUP': {
      return {
        ...state,
        currentlySelectedZone: action.payload,
      };
    }

    default:
      notEver(action);
      return { ...state };
  }
};
