import { Tooltip, Typography } from '@mui/material';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { DISPLAY_WMS_STATES } from 'common/slotStates';
import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { matchBarcodes } from 'common/functions/barcodes/matchBarcode.util';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { barcodeStatusEnum } from 'components/ModalsAndPopups/LocationModal/barcodes/models/BarcodeRow.model';
import { getContentCellColor } from './ContentCellFunctions';
import { multipleBarcode } from './multipleBarcode.style';
import { BarcodeLine } from './BarcodeLine';

const logPrefix = getLogPrefixForType('COMPONENT', 'ContentExpectedCell');

/**
 * Functional components rendering a Content (expected)
 * cell in a location data grid
 * @param props
 * @returns
 */
export const ContentExpectedCell = ({
  locationData,
  facilitySettings,
}: {
  locationData: ILocationDataST;
  facilitySettings: IFacilitySettingsST;
}) => {
  console.debug(logPrefix, locationData);

  const { classes } = multipleBarcode();

  const hasIssues = !!locationData.issues?.length;

  const verityStatus = locationData.verity_status as IVeritySlotStatusST;
  const wmsStatus = locationData.wms_status as IWMSSlotStatusST;

  if (!wmsStatus || typeof wmsStatus === 'string') {
    return <span className="c-data-grid-cell">{wmsStatus}</span>;
  }

  if (wmsStatus.state === 'INVALID') {
    return (
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: 'error.main',
          cursor: 'default',
        }}
      >
        Invalid
      </Typography>
    );
  }

  let tooltip: string | JSX.Element = '';
  let content: JSX.Element = <>-</>;

  const nonEmptyBarcodes = wmsStatus.barcodes.filter((b) => !!b);
  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    locationData,
    facilitySettings,
  );

  if (!nonEmptyBarcodes.length) {
    tooltip = DISPLAY_WMS_STATES[wmsStatus.state];
    content = (
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: getContentCellColor(!!wmsStatus, hasIssues),
          cursor: 'default',
        }}
      >
        {tooltip}
      </Typography>
    );
  } else {
    const { missing } = matchBarcodes({
      expected: wmsStatus.barcodes,
      found: verityStatus?.barcodes,
    });
    tooltip = nonEmptyBarcodes.join(', ');
    const list = nonEmptyBarcodes.map((barcode) => {
      const status = missing.includes(barcode)
        ? barcodeStatusEnum.Missing
        : barcodeStatusEnum.Match;
      return (
        <BarcodeLine
          key={barcode}
          barcode={barcode}
          barcodeStatus={status}
          barcodeMatchLogic={barcodeMatchLogic}
          issueLogic={issueLogic}
        />
      );
    });
    content = <span className={classes.verticalCenter}>{list}</span>;
  }

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-content-expected">
      {content}
    </Tooltip>
  );
};
