/**
 * This functions checks if there is active text area or input element in dom
 * @returns boolean
 */
export const isTextAreaOrInputActive = (): boolean => {
  const inputs = Array.from(document.getElementsByTagName('input'));
  const textAreas = Array.from(document.getElementsByTagName('textarea'));
  return [...inputs, ...textAreas].some((i) => i === document.activeElement);
};

// Fix firefox issue with allowing non-numeric values for Textfield type="number"
// https://github.com/mui-org/material-ui/issues/18923
export const fixNumericInputFields = () => {
  const numberElements = document.querySelectorAll('input[type="number"]');
  numberElements.forEach((el) => {
    el.addEventListener('keypress', (evt: any) => {
      if (evt.which === 8 || evt.key === '.') {
        return true;
      }
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
      return false;
    });
  });
};
