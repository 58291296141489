import { IntervalLabel } from './Recurrence/recurrence.model';

export const getValidIntervalOptions = (min: number, canScheduleHourly: boolean = true) => {
  let options: { label: IntervalLabel; enabled: boolean }[] = [];

  if (canScheduleHourly) {
    options.push({ label: 'hourly' as IntervalLabel, enabled: min >= 60 });
  }

  options = options.concat([
    { label: 'daily' as IntervalLabel, enabled: min >= 1440 },
    { label: 'weekly' as IntervalLabel, enabled: min >= 10080 },
    { label: 'monthly' as IntervalLabel, enabled: min >= 44640 },
    { label: 'yearly' as IntervalLabel, enabled: min >= 525600 },
  ]);

  return options;
};
