import { useState, useEffect, useCallback, useRef } from 'react';

interface IgetScrollYProps {
  pageYOffset: number | undefined;
  scrollTop: number | undefined;
}
interface IuseHideOnScrollProps {
  threshold: number;
  scroller?: any;
}

const getScrollY = (scroller: IgetScrollYProps) => {
  if (scroller.pageYOffset !== undefined) {
    return scroller.pageYOffset;
  }
  if (scroller.scrollTop !== undefined) {
    return scroller.scrollTop;
  }
  return (document.documentElement || document.body.parentNode || document.body).scrollTop;
};

const shouldHide = (
  scrollY: any | undefined,
  prevScrollY: any | undefined,
  threshold: any | undefined,
) => {
  if (scrollY < prevScrollY) {
    return false;
  }
  if (scrollY > prevScrollY && scrollY > (threshold != null ? threshold : 100)) {
    return true;
  }
  return false;
};

/**
 *
 * @param options
 * @returns
 */
export const useHideOnScroll = (options: IuseHideOnScrollProps) => {
  const { threshold, scroller } = options;

  const scrollRef: React.MutableRefObject<number | undefined> = useRef();
  const [hide, setHide] = useState<boolean | undefined>(false);

  const handleScroll = useCallback(() => {
    const scrollY = getScrollY(scroller || window);
    const prevScrollY = scrollRef.current;
    scrollRef.current = scrollY;

    setHide(shouldHide(scrollY, prevScrollY, threshold));
  }, [scroller, threshold]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return hide;
};
