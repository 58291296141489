import { Button, IconButton, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from 'components/common/Box';
import CustomDropZone from 'components/common/CustomDropZone';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { useStyles } from './styles';
import { LocationsToolbarUploaderProps } from '../../models/LocationsToolbarUploaderProps';

/**
 * Locations toolbar uploader
 * @param props LocationsToolbarUploaderProps
 * @returns JSX.Element
 */
export const LocationsToolbarUploader = ({
  isFileUploaded,
  fileName,
  setSelectedIds,
  setUploadedFile,
  setUploadErrorMessage,
  onClearUploads,
  shouldDisableUpload,
}: LocationsToolbarUploaderProps) => {
  const { classes } = useStyles();

  /**
   * Parses uploaded file
   * @param files files
   */
  const handleDrop = (files: File[]) => {
    const uploadedFile = files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(uploadedFile, 'UTF-8');
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      const file = e?.target?.result as string;
      const locationsIds = file
        .split('\n')
        .filter(Boolean)
        .map((i) => i.split(',')[0].trim());

      const error = setSelectedIds(locationsIds);
      if (!error) setUploadedFile(uploadedFile);
      else {
        setUploadErrorMessage('Unsuccessful upload due to unrecognized location(s)');
      }
    };
  };

  const fileNameBoxStyle = {
    padding: 0.5,
    border: 'solid 1px #EAEA92',
    borderRadius: 1,
    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.6)' },
  };

  const uploader = isFileUploaded ? (
    <Box display="flex" alignItems="center" sx={fileNameBoxStyle}>
      <DescriptionIcon sx={{ mr: 1 }} />
      <Typography>{fileName}</Typography>
      <IconButton sx={{ ml: 3, padding: 0.25 }} onClick={onClearUploads}>
        <ClearIcon />
      </IconButton>
    </Box>
  ) : (
    <CustomDropZone
      id="c-locations-toolbar-dropzone"
      testId="c-locations-toolbar-dropzone"
      accept={{ 'text/csv': ['.csv'] }}
      onDropRejected={() =>
        setUploadErrorMessage('File format not supported. Please upload .csv file.')
      }
      onDropAccepted={() => setUploadErrorMessage('')}
      disabled={shouldDisableUpload}
      maxFiles={1}
      text="Upload selection"
      rootClassName={classes.dropzone}
      showFiles={false}
      onDrop={handleDrop}
      customUploader={
        <Box display="flex" dir="row">
          <Button variant="outlined" size="small" disabled={shouldDisableUpload}>
            Upload selection
          </Button>
          <TooltipedIcon
            tooltip="Upload a csv file with a location name per row"
            icon={<InfoOutlinedIcon fontSize="medium" />}
          />
        </Box>
      }
    />
  );

  return uploader;
};
