import axios from 'axios';
import isNil from 'lodash/isNil';
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';

import { singleRequestHandler } from '../requestHelpers';

export interface IError {
  message: string;
  timestamp: string;
  user_name: string;
  trace_id: string;
  report_url: string | null;
}

export const errorNotification = (
  error: IError,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
) => {
  const idToken = localStorage.getItem('idToken');
  const messageText = error?.message || 'An unexpected error has occurred.';
  const reportUrl = idToken ? error?.report_url || null : null;
  const config = { headers: { Authorization: idToken } };

  const reportErrorRequest = (url: string | null) => {
    singleRequestHandler({
      request: axios.post,
      requestParams: [url, {}, config],
      dispatcher: enqueueSnackbar,
      messageSuccess: 'The problem was successfully reported to Verity.',
      messageErrorFallback: 'Failed to report the problem to Verity.',
    });
  };

  const reportErrorLink = (
    <span
      className="notification-link"
      role="link"
      tabIndex={0}
      onClick={() => reportErrorRequest(reportUrl)}
    >
      Click here to report this problem to Verity.
    </span>
  );

  const message = (
    <>
      {messageText}
      {!isNil(reportUrl) ? reportErrorLink : ''}
    </>
  );

  enqueueSnackbar(message, {
    variant: 'error',
    preventDuplicate: true,
  });
};
