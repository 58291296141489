import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

export enum barcodeStatusEnum {
  Match = 'Match',
  Unexpected = 'Unexpected',
  Missing = 'Missing',
}

export type BarcodeRow = {
  id: number;
  barcodeStatus: barcodeStatusEnum;
  expectedContent: string;
  contentFound: string;
  foundAt: string;
  shouldBeAt: string;
  wmsQuantity: string;
  articleNumber: string;
  customer: string;
  locationData: ILocationData;
};
