import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Spinner from 'components/common/Spinner';
import { Box } from 'components/common/Box';

export const useStyles = makeStyles()(() => ({
  issueCounterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50px',
    height: 'auto',
  },
  issueCounter: {
    fontWeight: 'bold',
  },
}));

export const IssueCounter = ({
  isLoading,
  issueCount,
}: {
  isLoading: boolean;
  issueCount: string | number;
}) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.issueCounterWrapper} position="relative" textAlign="center">
      {!isLoading ? (
        <>
          <Typography data-testid="issuesCounter" className={classes.issueCounter} variant="h5">
            {issueCount || 0}
          </Typography>
          <Typography data-testid="issuesText" className={classes.issueCounter}>
            issues
          </Typography>
        </>
      ) : (
        <Spinner transparentBackground size={30} />
      )}
    </Box>
  );
};
