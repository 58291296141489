import React, { MouseEvent } from 'react';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IconButton, styled } from '@mui/material';
import { useStyles } from './DroneZoneAccordion.styles';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    gap: theme.spacing(1),
    margin: 0,
  },
}));

export const DroneZoneAccordion = ({
  children,
  title,
  isVisible,
  isExpanded = true,
  accordionSummaryAriaLabel = '',
  onTriggerCreateMode,
  onToggleExpanded,
}: {
  title: string;
  children?: React.ReactNode;
  isExpanded?: boolean;
  isVisible: boolean;
  accordionSummaryAriaLabel?: string;
  onToggleExpanded?: (expanded: boolean) => void;
  onTriggerCreateMode?: () => void;
}) => {
  const { classes, cx } = useStyles();

  const triggerCreateMode = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();

    onTriggerCreateMode && onTriggerCreateMode();
  };

  const handleToggleExpanded = (_event: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    onToggleExpanded && onToggleExpanded(expanded);

  return (
    <Accordion
      className={cx(classes.accordion, !onToggleExpanded && classes.cursor)}
      disableGutters
      expanded={isExpanded}
      onChange={handleToggleExpanded}
    >
      <AccordionSummary
        aria-label={accordionSummaryAriaLabel}
        expandIcon={onToggleExpanded && <ExpandMoreIcon />}
      >
        <Typography className={classes.accordionSummaryTitle}>{title}</Typography>

        <div className={classes.buttonGroup}>
          {onTriggerCreateMode && (
            <IconButton
              className={classes.createButton}
              onClick={triggerCreateMode}
              size="small"
              aria-label="Create a new zone"
            >
              <ControlPointIcon />
            </IconButton>
          )}
        </div>
      </AccordionSummary>

      {children && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  );
};
