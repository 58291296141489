import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { TokenManager } from 'common/tokenManager';
import authServices from 'services/AuthServices';
import Spinner from 'components/common/Spinner';
import { QueryNames } from 'ts-types/QueryNames';
import { REDIRECT_URL } from './loginCallback.defaults';

const tokenManager = TokenManager.getInstance();

export const LoginCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || '';

  const { isPending, data } = useQuery({
    queryKey: [QueryNames.SSO_SIGN_IN, code],
    queryFn: () => authServices.ssoSignIn({ code, redirect_uri: REDIRECT_URL }),
    select: ({ data }) => data,
    enabled: !!code,
    retry: false,
  });

  useEffect(() => {
    if (!isPending && data) {
      tokenManager.setAuthData(data);
      navigate('/', { replace: true });
    }
    if (!isPending) {
      navigate('/', { replace: true });
    }
  }, [isPending, data, navigate]);

  return isPending ? <Spinner label="fetching login data..." /> : null;
};
