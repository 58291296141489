import { useMemo } from 'react';
import { controlledZoneOpacity } from 'shared/map/defaults/controlledZoneOpacity.default';

export const useMeshOpacity = ({
  isSelected,
  isHovering,
}: {
  isSelected: boolean;
  isHovering: boolean;
}) => {
  const opacity = useMemo(() => {
    if (isSelected) {
      return controlledZoneOpacity.activeSelected;
    }

    if (!isSelected && isHovering) {
      return controlledZoneOpacity.activeHover;
    }

    return controlledZoneOpacity.active;
  }, [isSelected, isHovering]);

  return { opacity };
};
