import { Typography } from '@mui/material';
import ActionsMenu from 'components/common/ActionsMenu';
import { useStyles } from './DroneZoneListItem.styles';
import { DroneZoneListItemProps } from './DroneZoneListItem.model';

export const DroneZoneListItem = ({
  id,
  name,
  options,
  isSelected,
  actionArea,
  onClick,
  customArea,
}: DroneZoneListItemProps) => {
  const { classes, cx } = useStyles();

  const classNames = cx(classes.itemWrapper, {
    [classes.itemWrapperActive]: isSelected,
  });

  return (
    <li className={classNames} aria-labelledby={`drone-zone-list-item-${id}`}>
      <button id={id} type="button" aria-label={name} className={classes.details} onClick={onClick}>
        <Typography id={`drone-zone-list-item-${id}`} className={classes.detailsName}>
          {name}
        </Typography>
        {customArea && customArea}
      </button>

      {!!actionArea && <div className={classes.actionArea}>{actionArea}</div>}
      {!!options?.length && (
        <div className={classes.optionsArea}>
          <ActionsMenu options={options} size="small" />
        </div>
      )}
    </li>
  );
};
