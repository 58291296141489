import { Reducer, useReducer } from 'react';

/**
 * This function is wrapper for useReducer, reason for this is to be easier tested
 * @returns [state, dispatch]
 */
export function useTypedReducer<State, Action>(
  reducer: Reducer<State, Action>,
  initialState: State,
) {
  return useReducer(reducer, initialState);
}
