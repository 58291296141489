import { useEffect } from 'react';
import { AppBar, Button, Paper, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box } from 'components/common/Box';
import Copyright from 'components/common/Copyright';
import { useClientModalsStore } from 'store/Modals';
import { ClientModalsActionTypes } from 'store/Modals/types';
import { AUTH_PAGES_URLS } from 'common/pages';
import { useNoRolesPageStyles } from './styles';

export const NoRolesPage = () => {
  const navigate = useNavigate();
  const { classes } = useNoRolesPageStyles();
  const { dispatchClientModals } = useClientModalsStore();

  const handleLogOut = () =>
    dispatchClientModals({
      type: ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL,
      payload: {
        title: 'Logout',
        message: 'Are you sure you want to logout?',
        onConfirm: () => navigate(AUTH_PAGES_URLS.SIGNOUT),
      },
    });

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Box className={classes.wrapper}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar} disableGutters>
          <Button variant="text" className={classes.button} onClick={handleLogOut}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Paper className={classes.paper} elevation={3}>
        <Box>
          <Typography variant="h6" align="center">
            Welcome to the Verity Dashboard!
          </Typography>
          <Typography variant="h6" align="center">
            Your account is not yet activated.
          </Typography>
        </Box>

        <Box className={classes.content}>
          <Typography>
            To activate your new user, please contact Verity Support at
            <strong> support@verity.net</strong> or your Verity Client Success Manager.
          </Typography>
        </Box>
      </Paper>
      <Copyright />
    </Box>
  );
};
