import TableCell from '@mui/material/TableCell';
import { EnhancedTableCellProp } from '../../../types/cell';

// Note: the template EnhancedTableCellProp is instantiated with type "any" for at this
// stage the input rows are not typified yet and, hence the type cannot be established
// FIXME / TODO: initialize the template with proper types when these will be available
// eno 2022/06/23
export const EnhancedTableCellIcon = (props: EnhancedTableCellProp<any>) => {
  const { cell, row, onClick } = props;

  if (cell === undefined) {
    console.debug(
      'EnhancedTableCellIcon',
      'cell is undefined: the containing component has probably been unmounted',
    );
    return null;
  }

  return (
    <TableCell data-testid={`c-${cell.id}`} align={cell.align} onClick={onClick}>
      {row[cell.id]}
    </TableCell>
  );
};
