export const validatePositionToFacilityBoundaries = ({
  vector,
  offset,
  boundaryMax,
  boundaryMin,
}: {
  vector: number;
  offset: number;
  boundaryMax: number;
  boundaryMin: number;
}) => {
  const isAboveBoundary = vector + offset > boundaryMax;
  const isBelowBoundary = vector - offset < boundaryMin;

  if (isAboveBoundary) {
    return boundaryMax - offset;
  }

  if (isBelowBoundary) {
    return boundaryMin + offset;
  }

  return vector;
};
