import { getTimeIntervalDates } from 'common/functions/dateTimeFunctions';
import { TIMEFRAME_TO_FETCH_REPORTS_FROM } from 'common/settings';
import { REPORT_STATES } from 'common/reportStates';
import reportStore from 'udb/inventory/reports/reducer/report-store/ReportStore';
import { GetFinishedReportsParams } from './getFinishedReportsParams.model';

export const getFinishedReports = ({
  requestController,
  systemId,
  setIsLoading,
  onSuccess,
}: GetFinishedReportsParams) => {
  const dates = getTimeIntervalDates(TIMEFRAME_TO_FETCH_REPORTS_FROM);
  const finishedReportsReqParams = { ...dates, states: [REPORT_STATES.FINISHED] };
  const reportsRequestReservation = requestController.reserveSlotForRequest();
  requestController.doRequest({
    request: reportStore.asyncGetReportSummariesRecursively,
    requestParams: [
      systemId,
      finishedReportsReqParams,
      requestController,
      reportsRequestReservation.requestId,
      reportsRequestReservation.signal,
      true,
    ],
    callbackBeforeSend: () => setIsLoading(true),
    callbackFinally: () => setIsLoading(false),
    messageErrorFallback: 'Something went wrong while getting the summaries of finished reports',
    callbackSuccess: onSuccess,
  });
};
