import findIndex from 'lodash/findIndex';

// variables, functions, configurations
import { TAB_NAMES } from '../settings';

/**
 * Interface for the params of the warehouseUploadsTabs function.
 */
interface IWarehouseUploadsTabsParams {
  WAREHOUSE_UPLOADS_TABLE_TABS: IWarehouseUploadsTab[];
  numberOfWMSSnapshotFiles: number;
  numberOfStockCheckFiles: number;
  numberOfMovedLocationsFiles: number;
}

interface IWarehouseUploadsTab {
  icon?: JSX.Element;
  show: boolean;
  label: string;
  orderBy: string;
  order: string;
}

type numberOfItemsInTabsKeys =
  | 'WMS SNAPSHOTS FILES'
  | 'STOCK CHECK FILES'
  | 'MOVED LOCATIONS FILES';

const getTabsAccessibleToUser = (WAREHOUSE_UPLOADS_TABLE_TABS: IWarehouseUploadsTab[]) =>
  WAREHOUSE_UPLOADS_TABLE_TABS?.filter((tab) => tab.show);

export const warehouseUploadsTabs = ({
  WAREHOUSE_UPLOADS_TABLE_TABS,
  numberOfWMSSnapshotFiles,
  numberOfStockCheckFiles,
  numberOfMovedLocationsFiles,
}: IWarehouseUploadsTabsParams) => {
  let tabs: IWarehouseUploadsTab[] = [];

  const numberOfItemsInTabs = {
    'WMS SNAPSHOTS FILES': numberOfWMSSnapshotFiles,
    'STOCK CHECK FILES': numberOfStockCheckFiles,
    'MOVED LOCATIONS FILES': numberOfMovedLocationsFiles,
  };

  getTabsAccessibleToUser(WAREHOUSE_UPLOADS_TABLE_TABS)?.forEach((tab) => {
    tabs = [
      ...tabs,
      {
        icon: tab.icon,
        label: `${tab.label} (${numberOfItemsInTabs[tab.label as numberOfItemsInTabsKeys]})`,
        orderBy: tab.orderBy,
        show: tab.show,
        order: tab.order,
      },
    ];
  });

  return tabs;
};

export const warehouseUploadsMappings = (WAREHOUSE_UPLOADS_TABLE_TABS: IWarehouseUploadsTab[]) => ({
  wmsSnapshotFilesTab: findIndex(
    getTabsAccessibleToUser(WAREHOUSE_UPLOADS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.WAREHOUSE_UPLOADS['WMS SNAPSHOTS FILES'],
  ),
  stockCheckFilesTab: findIndex(
    getTabsAccessibleToUser(WAREHOUSE_UPLOADS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.WAREHOUSE_UPLOADS['STOCK CHECK FILES'],
  ),
  movedLocationsFilesTab: findIndex(
    getTabsAccessibleToUser(WAREHOUSE_UPLOADS_TABLE_TABS),
    (tab) => tab.label === TAB_NAMES.WAREHOUSE_UPLOADS['MOVED LOCATIONS FILES'],
  ),
});
